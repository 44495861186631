import React from 'react';
import Header from "../header.js";
import axios from 'axios';
import $ from 'jquery';
import * as Sentry from "@sentry/react";

class UnassinedPopup extends React.Component {

  constructor(props) {
    super(props)
    this.state = { food_icon_array: [] };
  }



  static unassigned_popup(assigned_date) {
    const options = {
      url: window.API_URL + "v1/phase_templates/phase_property/FOOD_CATEGORIES/assigned_date/" + assigned_date,
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        if (response.data && response.data.phase_template.food_categories) {
          $('ul.food_icons').empty();
          window.food_cat_array = response.data.phase_template.food_categories;
          for (var i = 0; i < response.data.phase_template.food_categories.length; i++) {
            $('ul.food_icons').append('<li key=' + i + '  value="' + response.data.phase_template.food_categories[i].name.toLocaleLowerCase() + '_unfill"}  name=' + response.data.phase_template.food_categories[i].name.toLocaleLowerCase() + ' id=' + response.data.phase_template.food_categories[i].abbreviation + '><img name=' + response.data.phase_template.food_categories[i].name + ' src=' + response.data.phase_template.food_categories[i].unfilled_image_url + ' value="' + response.data.phase_template.food_categories[i].filled_image_url + ' id=' + response.data.phase_template.food_categories[i].name.toLocaleLowerCase() + '_unfill" class="unfill_icon cursor_pointer" /></li>');
          }
        }
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });

  }


  componentDidMount() {
    $.noConflict();
    $(document).on('click', '.assign_food', function () {
      $('.unassigned_popup').show();
      $('.unassigned_food').hide();
    });

    $(document).on('click', '.create_other', function () {
      $('.un_assign').text('');
      $('.un_assign').html('<img class="filled_icon cursor_pointer other_unfill" value="other_unfill" src=' + this.props.get_s3_path + '/OTHER/OTHER_SMALL_USED.png>');
      window.localStorage.setItem('total_pills', '<img class="filled_icon cursor_pointer other_unfill" value="other_unfill" src=' + this.props.get_s3_path + '/OTHER/OTHER_SMALL_USED.png>');
    }.bind(this));

    $(document).on('click', '.unfill_icon', function (e) {
      e.stopImmediatePropagation();
      var get_id = $(this).attr('id');
      if ($(this).parent().find('.filled_icon').length <= 11) {

        var kk = $(this).parent().attr('id');
        var pill_name = $(this).parent().attr('name').toUpperCase();
        var jj = $(this).attr("value").replace('SMALL', 'LARGE');
        $(this).parent().prepend('<img name=' + pill_name + ' class="filled_icon cursor_pointer ' + get_id + '" value=' + get_id + ' src=' + jj + '>');
        var parend_li = $(this).parent().find('.filled_icon').length;
        $('.un_assign_txt').hide();
        if ($(this).parents('.unassigned_popup').find('.' + kk + '').length > 0) {
          $(this).parents('.unassigned_popup').find('.' + kk + '').find('.current_food_count span').text(parend_li);
          if ($('.Oth').find('.comma').length > 1) {
            $('.comma:first').remove();
          }
        }
        else {

          $('.food_count_img .food_count_images').append('<span class=' + kk + '><span class="current_food_count"><span>' + parend_li + '</span></span>' + kk + '<span class="comma">,</span> </span>');


        }
        if ($('.unassigned_popup').find('.food_count_images span').length >= 1) {
          $('.selected_food_txt').remove();
          $('.food_count_img').append('<span class="selected_food_txt">selected</span>')
        }
        else {
          $('.selected_food_txt').remove();
        }
        if ($(this).parent().find('.filled_icon').length >= 12) {
          $(this).hide();
        }

      }
      if ($('.comma').length > 1) {
        $('.comma').show();
        $('.comma:last').hide();
      }
      else {
        if ($('.comma').length === 1) {
          $('.comma:first').hide();
        }
      }

    });
    $(document).on('click', '.filled_icon', function () {
      var get_value = $(this).attr('value');

      var kk = $(this).parent().attr('name');
      var pill_id = $(this).parent().attr('id');
      var parend_li = $(this).parents('.unassigned_popup').find('.' + kk + '_unfill').length;

      if (parend_li === 0) {
        $(this).parents('.unassigned_popup').find('.un_assign_txt').show();
      }
      else {
        $(this).parents('.unassigned_popup').find('.' + pill_id + '').find('.current_food_count span').text(parend_li - 1);
        if (parend_li === 1) {
          $(this).parents('.unassigned_popup').find('.' + pill_id + '').remove();
        }
      }
      $(this).remove();

      if ($('.food_count_images .current_food_count').length === 0) {
        $('.selected_food_txt').remove();
        $('.un_assign_txt').show();
      }
      if ($('#unassigned_popup').find('img[value=' + get_value + ']').length < 12) {
        $('#unassigned_popup').find('img[value=' + get_value + ']').parent().find('.unfill_icon').show();
      }
      if ($('.comma').length === 1) {
        $('.comma:first').hide();
      }
      else {
        $('.comma:last').hide();
      }
    });
    $(document).on('click', '.submit_food_icons', function (e) {
      e.stopImmediatePropagation();
      if ($('#unassigned_popup .food_icons').find('.filled_icon').length > 0) {
        $('.un_assign').empty();
        $('#unassigned_popup .food_icons').find('.filled_icon').appendTo('.un_assign');
        for (var kk = 0; kk < $('.un_assign img').length; kk++) {

          var pp = $('.un_assign').find('img:eq(' + kk + ')').attr('src').replace('LARGE', 'SMALL').replace('MEDIUM', 'SMALL');
          $('.un_assign').find('img:eq(' + kk + ')').attr('src', pp);
        }
      }
      else {
        $('.un_assign').text('Unassigned');
      }
      if ($(".food_details_page").length) {
        if (window.localStorage.getItem('consumable_type') === "Food") {
          $(".cff").show();
          localStorage.removeItem('custom_fav_food_consumable_id');
          localStorage.removeItem('custom_fav_food_consumable_type');
          localStorage.removeItem('custom_food_consumable_id');
          if ($(".custom_fav_img_icon").attr('src') === "/assets/images/fav_custom_filled.png") {
            $(".custom_fav_img_icon").attr('src', '/assets/images/fav_custom.png')
          }
        }
      }
      window.localStorage.setItem('total_pills', $('.un_assign').html());
    });

    var assigned_date = window.localStorage.getItem('assigned_date');
    const options = {
      url: window.API_URL + "v1/phase_templates/phase_property/FOOD_CATEGORIES/assigned_date/" + assigned_date,
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        if (!!response.data.phase_template) {
          this.setState({ food_icon_array: response.data.phase_template.food_categories })
        }
        window.pp = response.data;
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });

  }

  render() {
    var food_icons = this.state.food_icon_array;
    var food_icons_list = food_icons.map(function (food_imgs, i) {
      return (
        <li key={i} value={food_imgs.name.toLocaleLowerCase() + "_unfill"} name={food_imgs.name.toLocaleLowerCase()} id={food_imgs.abbreviation}><img alt="" name={food_imgs.name} src={food_imgs.unfilled_image_url} value={food_imgs.filled_image_url} id={food_imgs.name.toLocaleLowerCase() + "_unfill"} className={'unfill_icon cursor_pointer'} /></li>

      )
    });
    return (

      <div className="modal fade" id="unassigned_popup">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close fooddetails" data-dismiss="modal" aria-label="Close"><img alt="" src="/assets/images/close_grey.png" /></button>
            </div>
            <div className="modal-body">
              <div className="unassigned_popup">
                <h3><b><span className="unassigned-food-categories-ks" dangerouslySetInnerHTML={{ __html: window.keystrings['com.numi.edit_food_categories'] }}></span></b></h3>
                <p className="font15 color7 mt5 unassigned-food-categories-msg-ks" dangerouslySetInnerHTML={{ __html: window.keystrings['com.numi.edit_food_categories_description'] }}></p>
                <p className="blue_color un_assign_txt font13 mt unassigned_text"></p>
                <p className="food_count_img text-left plft10"><span className="food_count_images"></span></p>
                <ul className="food_icons">
                  {food_icons_list}
                </ul>
                <p><button className="submit_food_icons unassigned-food-categories-done-ks" data-dismiss="modal"></button></p>
              </div>
              <div className="unassigned_food width70">
                <img alt="" src="/assets/images/unassigned_other_food.png" />
                <h3 className="mt"><b><span className="unassigned-food-ks"></span></b></h3>
                <h6 className="color6 mt food-unassigned-popup-msg-ks"> </h6>
                <p className="mt assign_food"><button className="food-assign-food-category-ks"></button></p>
                <p className="mt2 create_other" data-dismiss="modal"><button className="food-create-as-other-ks"></button></p>
              </div>

            </div>
          </div>
        </div >
      </div >

    )
  }
}

export default UnassinedPopup;
