import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { Component } from "react";
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import Login from "./onboarding/login.js";
import Impersonate from "./onboarding/impersonate.js";
import Profile from "./onboarding/profile.js";
import Signup from "./onboarding/sign_up.js";
import Accountdetails from "./onboarding/account_details.js";
import ForgotPassword from "./onboarding/forgot_password.js";
import Journal from "./journal/journal.js";
import Log_Food from "./log_food/log_food.js";
import Food_details from "./journal/food_details.js";
import Create_activity from "./activity/create_activity.js";
import Log_activity from "./activity/activity.js";
import Log_task from "./activity/log_task.js";
import Activity_log from "./activity/activity_log.js";
import Connect_device from "./onboarding/connect_device.js";
import Stay_on_track from "./onboarding/stay_on_track.js";
import Myprofile from "./profile/my_profile.js";
import ChangePassword from "./profile/change_password.js";
import Measurements from "./profile/measurements.js";
import Settings from "./settings/settings.js";
import CommunicationSettings from "./settings/communication_settings.js";
import AboutNutrisystem from "./settings/about_nutrisystem.js";
import PlanSettings from "./settings/plan_settings.js";
import Recipe from "./recipe/recipe.js";
import RecipeItem from "./recipe/recipe_item.js";
import CreateFood from "./create_food/create_food.js";
import Progress from "./progress/progress.js";
import Milestone from "./progress/milestone.js";
import Welcome from "./onboarding/welcome.js";
import NsPlan from "./onboarding/ns_plan.js";
import Selectprogram from "./onboarding/select_program.js";
import EditFood from "./journal/edit_food.js";
import EditActivity from "./activity/edit_activity.js";
import EditCalculatedActivity from "./activity/edit_calculated_activity.js";
import Tour from "./tour/tour.js";
import AllSet from "./onboarding/all_set.js";
import NewsFeed from "./newsfeed/newsfeed.js";
import ResetPassword from "./onboarding/reset_password.js";
import UnsubscribeUser from "./unsubscribe/unsubscribe_user.js";
import NewSignup from "./onboarding/new_signup.js";
import NutriLink from "./onboarding/nutri_link.js";
import NutriAccount from "./onboarding/create_nutri_account.js";
import Header from "./header";
import Photos from "./photos/photos.js";
import Badges from "./photos/badges.js";
import UploadPhotos from "./photos/upload_photos.js";
import PersonalizePlanMode from './onboarding/PersonalizePlanMode.js';
import Social from './social/social.js';
import * as Sentry from "@sentry/react";


class ProtectedRoute extends Component {
  render() {
    const { component: Component, ...props } = this.props

    return (
      <Route {...props} render={props => (
        localStorage.getItem('user_access') !== null ?
          <Component {...props} /> :
          <Redirect to='/login' />
      )}
      />
    )
  }
}

class App extends Component {

  render() {
    return (
      <Router>
        <Header />
        <Switch>
          <Route exact path="/" component={Login} />
          <Route path="/login" component={Login} />
          <Route path="/impersonate" component={Impersonate} />
          <ProtectedRoute path="/profile" component={Profile} />
          <Route path="/signup" component={Signup} />
          <Route path="/account_details" component={Accountdetails} />
          <Route path="/forgot_password" component={ForgotPassword} />
          <Route path="/personalize_plan" component={PersonalizePlanMode} />
          <ProtectedRoute path="/journal" component={Journal} />
          <ProtectedRoute path="/social" component={Social} />
          <ProtectedRoute path="/log_food" component={Log_Food} />
          <ProtectedRoute path="/food_details" component={Food_details} />
          <ProtectedRoute path="/create_activity" component={Create_activity} />
          <ProtectedRoute path="/log_activity" component={Log_activity} />
          <ProtectedRoute path="/log_task" component={Log_task} />
          <ProtectedRoute path="/activity_log" component={Activity_log} />
          <ProtectedRoute path="/connect_device" component={Connect_device} />
          <ProtectedRoute path="/stay_on_track" component={Stay_on_track} />
          <ProtectedRoute path="/my_profile" component={Myprofile} />
          <ProtectedRoute path="/change_password" component={ChangePassword} />
          <ProtectedRoute path="/measurements" component={Measurements} />
          <ProtectedRoute path="/settings" strict={false} component={Settings} />
          <ProtectedRoute path="/communication_settings" component={CommunicationSettings} />
          <ProtectedRoute path="/about_nutrisystem" component={AboutNutrisystem} />
          <ProtectedRoute path="/plan_settings" component={PlanSettings} />
          <ProtectedRoute path="/recipe" component={Recipe} />
          <ProtectedRoute path="/recipe_item" component={RecipeItem} />
          <ProtectedRoute path="/create_food" component={CreateFood} />
          <ProtectedRoute path="/progress" component={Progress} />
          <ProtectedRoute path="/milestone" component={Milestone} />
          <ProtectedRoute path="/welcome" component={Welcome} />
          <ProtectedRoute path="/ns_plan" component={NsPlan} />
          <ProtectedRoute path="/select_program" component={Selectprogram} />
          <ProtectedRoute path="/edit_food" component={EditFood} />
          <ProtectedRoute path="/edit_activity" component={EditActivity} />
          <ProtectedRoute path="/edit_calculated_activity" component={EditCalculatedActivity} />
          <ProtectedRoute path="/tour" component={Tour} />
          <ProtectedRoute path="/all_set" component={AllSet} />
          <ProtectedRoute path="/newsfeed" component={NewsFeed} />
          <ProtectedRoute path="/reset_password" component={ResetPassword} />
          <Route path="/unsubscribe" component={UnsubscribeUser} />
          <Route path="/new_signup" component={NewSignup} />
          <ProtectedRoute path="/nutri_link" component={NutriLink} />
          <ProtectedRoute path="/nutri_account" component={NutriAccount} />
          <ProtectedRoute path="/photos" component={Photos} />
          <ProtectedRoute path="/badges" component={Badges} />
          <ProtectedRoute path="/upload_photos" component={UploadPhotos} />
          <ProtectedRoute component={Journal} /> {/* Navigate to "Journal" page if no route matches */}
        </Switch>
      </Router>
    );
  }
}

export default Sentry.withProfiler(App);
