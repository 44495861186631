
import React, { useEffect, useState } from 'react';
import Slider from 'react-rangeslider';
import { Modal } from 'react-bootstrap';
import { addDays, subDays, parse } from 'date-fns';
import axios from 'axios';
import moment from 'moment';
import Header from "../header.js";
import * as Sentry from "@sentry/react";


const WaterGoalSection = (props) => {
    var final_water_level = 0;
    const [waterQuestionPopup, setWaterQuestionPopup] = useState(false);
    const [waterLogPopup, setWaterLogPopup] = useState(false);
    const [waterAdditionalContent, setWaterAdditionalContent] = useState("");
    const [waterShowLessContent, setWaterShowLessContent] = useState("");
    const [showMore, setShowMore] = useState(false);
    const [water_count, setWater_count] = useState(8);
    const [waterRemove, setWaterRemove] = useState(true);
    const [waterLeftContent, setWaterLeftContent] = useState("");
    const [lastEmptyGlass, setLastEmptyGlass] = useState(true);
    const [water_ounce, setWaterOunce] = useState([]);
    const [editWaterlog, setEditWaterlog] = useState(false);
    const [showLogWaterDivs, setShowLogWaterDivs] = useState(false)
    const [waterLogPopupBody, setWaterLogPopupBody] = useState(false);
    const [waterLogPopupError, setWaterLogPopupError] = useState("");
    const [addorEditWaterLog, setAddorEditWaterLog] = useState("add");
    const [prevWaterCount, setPrevWaterCount] = useState("");
    const [accordionShow, setAccordianShow] = useState(false);
    const [accordianArrow, setAccordianArrow] = useState("/assets/images/arrow_up_grey.png")
    useEffect(() => {
        setRemainingWater();

    })
    function onHideWaterQuestionPopup() {
        setWaterQuestionPopup(false);
    }

    function onHideWaterLogPopup() {
        setWaterLogPopup(false);
        localStorage.removeItem('additional_water')
    }

    function handleShowMoreClick() {
        setWaterAdditionalContent(window.keystrings['com.numi.dynamic_water_additional_content']);
        setWaterShowLessContent(window.keystrings['com.numi.water_show_less']);
        window.localStorage.setItem('additional_water', 'true');
        setShowMore(true);
    }

    function handleShowLessClick() {
        setShowMore(false);
    }

    function waterChange(value) {
        setWater_count(parseInt(value))
    }

    function fourOunce() {
        setWater_count(4)
    }

    function eightOunce() {
        setWater_count(8)
    }

    function sixteenOunce() {
        setWater_count(16)
    }

    function thrirtytwoOunce() {
        setWater_count(32)
    }
    function fortyeightOunce() {
        setWater_count(48)
    }
    function sixtyfourOunce() {
        setWater_count(64)
    }
    function setRemainingWater() {
        var totalwater = window.localStorage.getItem('water_goal_new');
        var final_count = 0;
        window.water_ounce = [];
        var water_added = document.getElementsByClassName("water_added");
        var water_content = document.getElementsByClassName("water_content");
        for (var final_array = 0; final_array < water_added.length; final_array++) {
            window.water_ounce.push(parseInt((water_content[final_array]).innerHTML))
        }

        for (var ii = 0; ii < window.water_ounce.length; ii++) {
            final_count = parseInt(final_count) + parseInt(window.water_ounce[ii]);

        }
        var remainingwater = parseInt(totalwater) - final_count
        localStorage.setItem("left_water_goal", remainingwater)
        props.water_data.ounces && props.water_data.ounces.length > 0 ?
            setWaterLeftContent(window.localStorage.getItem('left_water_goal')) :
            setWaterLeftContent(window.localStorage.getItem('water_goal_new'));
    }

    function removeWater() {
        var assigned_date = moment(parse(window.localStorage.getItem('assigned_date'))).format('YYYY-MM-DD');
        var final_count = 0;

        setWaterLogPopup(false);

        window.editWaterlog = true;
        window.water_ounce = [];
        var water_added = document.getElementsByClassName("water_added");
        var water_content = document.getElementsByClassName("water_content");
        for (var final_array = 0; final_array < water_added.length; final_array++) {
            window.water_ounce.push(parseInt((water_content[final_array]).innerHTML))
        }

        var ind = window.water_ounce.indexOf(water_count);
        window.water_ounce.splice(ind, 1)

        for (var ii = 0; ii < window.water_ounce.length; ii++) {
            final_count = parseInt(final_count) + parseInt(window.water_ounce[ii]);

        }

        setWaterLeftContent(parseInt(window.localStorage.getItem('water_goal_new')) - parseInt(water_count));
        if (parseInt(final_count) >= (window.localStorage.getItem('water_goal_new'))) {
            setLastEmptyGlass(false);
        }
        else {
            setLastEmptyGlass(true);
        }


        var water_params = { "water": { "assigned_date": assigned_date, "ounces": window.water_ounce } }


        const options = {
            url: window.API_URL + 'v1/waters',
            method: 'POST',
            headers: Header.authorization_headers(),
            data: water_params
        };
        axios(options)
            .then(response => {
                window.localStorage.setItem('left_water_goal', parseInt(window.localStorage.getItem('water_goal_new')) - parseInt(final_count))
                props.getJournal();
            })
            .catch(error => {
                Sentry.captureException(error);
            });


    }

    function logWater() {
        var today = moment();
        var endDate = moment(localStorage.getItem('assigned_date'));
        var result = endDate.diff(today, 'days');


        var assigned_date = moment(parse(localStorage.getItem('assigned_date'))).format('YYYY-MM-DD');

        var total_water_array = [];
        var final_count = 0;
        if (result >= -90) {

            if (addorEditWaterLog === "add") {
                window.water_ounce = [];
                var water_added = document.getElementsByClassName("water_added");
                var water_content = document.getElementsByClassName("water_content");

                for (var final_array = 0; final_array < water_added.length; final_array++) {
                    window.water_ounce.push(parseInt(water_content[final_array].innerHTML))
                }

                window.water_ounce.push(water_count.toString());

                for (var ii = 0; ii < window.water_ounce.length; ii++) {
                    final_count = parseInt(final_count) + parseInt(window.water_ounce[ii]);

                }

            }
            else {
                window.water_ounce = [];
                var water_added = document.getElementsByClassName("water_added");
                var water_content = document.getElementsByClassName("water_content");

                for (var final_array = 0; final_array < water_added.length; final_array++) {
                    window.water_ounce.push(parseInt(water_content[final_array].innerHTML))
                }

                var ind = window.water_ounce.indexOf(prevWaterCount);
                window.water_ounce[ind] = water_count.toString();

                for (var ii = 0; ii < window.water_ounce.length; ii++) {
                    final_count = parseInt(final_count) + parseInt(window.water_ounce[ii]);

                }
            }

        }

        var water_params = { "water": { "assigned_date": assigned_date, "ounces": window.water_ounce } };

        const options = {
            url: window.API_URL + 'v1/waters',
            method: 'POST',
            headers: Header.authorization_headers(),
            data: water_params
        };
        axios(options)
            .then(response => {
                setWaterLogPopup(false);
                window.localStorage.setItem('left_water_goal', parseInt(window.localStorage.getItem('water_goal_new')) - parseInt(final_count))
                props.getJournal();
            })
            .catch(error => {
                if (error && error.response && error.response.data && error.response.data.error && error.response.data.error) {
                    setWaterLogPopupError(error.response.data.error);
                    setWaterLogPopupBody(true);
                    var water_added = document.getElementsByClassName("water_added");
                    var water_added_last = water_added[water_added.length - 1];
                    water_added_last.remove();
                }
                Sentry.captureException(error);
            });

    }

    return (
        <>
            <div className="meal_right_con water_section">
                <div className="meal_right_sec_gap">
                    <h5 className="pull-left width100 cursor_pointer" onClick={() => {
                        if (accordianArrow == "/assets/images/arrow_up_grey.png") {
                            setAccordianArrow("/assets/images/arrow_down_grey.png")
                        }
                        else {
                            setAccordianArrow("/assets/images/arrow_up_grey.png")
                        }
                        setAccordianShow(!accordionShow);
                    }}><span className="numi_water">{window.keystrings['com.numi.water']}</span> <span className="daily_goal daily_goal_water">Daily Goal:&nbsp;&nbsp;<span className="water_level"></span><span className="toggle_each"><span>{(window.localStorage.getItem('water_goal_new'))}</span> oz. <img alt="" src={accordianArrow} className="pull-right mt3" /></span></span></h5>
                    <span id="water_popup" data-target="#waterpopupmodel" data-toggle="modal"
                        style={{ display: localStorage.getItem("water_state") != "false" ? "block" : "none" }}><img alt="" src="/assets/images/question.png" className="mt1 water_goal_question cursor_pointer" onClick={() => { setWaterQuestionPopup(true) }} /></span>
                </div>
                <div className="toggle_water_div">
                    <div className="logged_glasses" style={{ display: accordionShow === true ? "" : "none" }}>
                        {
                            props.water_data.ounces && props.water_data.ounces.length > 0 ? (
                                props.water_data.ounces.map((water_level) => {
                                    return (
                                        <img alt="" src="/assets/images/water_logged.png" className="water_glass" width="51" height="51" />
                                    )
                                }
                                )
                            )
                                : <></>
                        }

                        {
                            window.localStorage.getItem('left_water_goal') >
                                0 || window.localStorage.getItem('left_water_goal') == undefined || window.localStorage.getItem('left_water_goal') == null ?
                                <img alt="" src="/assets/images/empty_glass.png" className="water_glass" width="51" height="51" />
                                :
                                <></>
                        }

                    </div>
                    <div className="toggle_water_sec" style={{ display: accordionShow === true ? "none" : "block" }}>
                        <div className="meal_right_sec_gap">
                            <div className="pull-left width100">
                                <div className="water_log_sec">
                                    <div className="log_water_divs">

                                        {
                                            showLogWaterDivs === true ?
                                                (
                                                    <>
                                                        <div className="empty_water water_added">
                                                            <div className="pull-left width20"><img alt="" src="/assets/images/water_logged.png" className="water_glass water_edit cursor_pointer" /></div>
                                                            <div className="pull-left ml6 width65"><span><span className="water_content">{water_count}</span> oz.</span></div>
                                                            <div className="pull-right margin13"><img alt="" src="/assets/images/arrow_right_grey.png" className="mr water_edit cursor_pointer" /></div>
                                                        </div>
                                                        {
                                                            props.water_data.ounces && props.water_data.ounces.length > 0 ? (
                                                                props.water_data.ounces.map((water_level) => {
                                                                    final_water_level = final_water_level + water_level

                                                                    return (
                                                                        <div className="empty_water water_added  cursor_pointer" onClick={() => {
                                                                            setWaterRemove(false)
                                                                            setWater_count(water_level)
                                                                            setWaterLogPopup(true)
                                                                            setPrevWaterCount(water_level)
                                                                            setAddorEditWaterLog('edit')
                                                                        }}>
                                                                            <div className="pull-left width20">
                                                                                <img alt="" src="/assets/images/water_logged.png" className="water_glass water_edit  cursor_pointer" /></div>
                                                                            <div className="pull-left  ml6 width65">
                                                                                <span>
                                                                                    <span className="water_content">{water_level}</span> oz.</span></div>
                                                                            <div className="pull-right margin13">
                                                                                <img alt="" src="/assets/images/arrow_right_grey.png" className="mr water_edit cursor_pointer" /></div>
                                                                        </div>
                                                                    )
                                                                })
                                                            ) : (<>

                                                            </>)

                                                        }
                                                    </>
                                                )
                                                :
                                                (<> {
                                                    props.water_data.ounces && props.water_data.ounces.length > 0 ? (
                                                        props.water_data.ounces.map((water_level) => {
                                                            final_water_level = final_water_level + water_level

                                                            return (
                                                                <div className="empty_water water_added  cursor_pointer" onClick={() => {
                                                                    setWaterRemove(false)
                                                                    setWater_count(water_level)
                                                                    setWaterLogPopup(true)
                                                                    setPrevWaterCount(water_level)
                                                                    setAddorEditWaterLog('edit')
                                                                }}>
                                                                    <div className="pull-left width20">
                                                                        <img alt="" src="/assets/images/water_logged.png" className="water_glass water_edit  cursor_pointer" /></div>
                                                                    <div className="pull-left  ml6 width65">
                                                                        <span>
                                                                            <span className="water_content">{water_level}</span> oz.</span></div>
                                                                    <div className="pull-right margin13">
                                                                        <img alt="" src="/assets/images/arrow_right_grey.png" className="mr water_edit cursor_pointer" /></div>
                                                                </div>
                                                            )
                                                        })
                                                    ) : (<></>)

                                                }</>)

                                        }
                                    </div>

                                    {
                                        window.localStorage.getItem('left_water_goal') > 0 ||
                                            window.localStorage.getItem('left_water_goal') == undefined ||
                                            window.localStorage.getItem('left_water_goal') == null ?
                                            (<div className="empty_water cursor_pointer last_empty_glass" data-toggle="modal" data-target="#water_log" style={{ display: lastEmptyGlass === true ? "" : "none" }} onClick={() => {
                                                setWaterRemove(true)
                                                setWaterLogPopup(true)
                                                setAddorEditWaterLog('add')
                                                setWater_count(8)
                                            }}>
                                                <div className="pull-left width20">
                                                    <img alt="" src="/assets/images/empty_glass.png" className="water_glass" width="51" height="51" />
                                                </div>
                                                <div className="pull-left margin6  ml10px width65">
                                                    <span>Add Water</span><br />
                                                    <span><span className="water_content_left">{waterLeftContent}</span> oz. left</span>
                                                </div>
                                                <div className="pull-right mt10  ml10px">
                                                    <img alt="" src="/assets/images/plus_icon.png" className="vtt" />
                                                </div>

                                            </div>)
                                            :
                                            moment(parse(window.localStorage.getItem('assigned_date'))).format('YYYY-MM-DD') != moment().format("YYYY-MM-DD") ?
                                                (<div className="empty_water cursor_pointer last_empty_glass" data-toggle="modal" data-target="#water_log" style={{ display: lastEmptyGlass === true ? "" : "none" }} onClick={() => {
                                                    setWaterRemove(true)
                                                    setWaterLogPopup(true)
                                                    setAddorEditWaterLog('add')
                                                    setWater_count(8)
                                                }}>
                                                    <div className="pull-left width20">
                                                        <img alt="" src="/assets/images/empty_glass.png" className="water_glass" width="51" height="51" />
                                                    </div>
                                                    <div className="pull-left margin6  ml10px width65">
                                                        <span>Add Water</span><br />
                                                        <span><span className="water_content_left">{waterLeftContent}</span> oz. left</span>
                                                    </div>
                                                    <div className="pull-right mt10  ml10px">
                                                        <img alt="" src="/assets/images/plus_icon.png" className="vtt" />
                                                    </div>

                                                </div>) :
                                                (
                                                    <></>
                                                )
                                    }
                                </div>
                            </div >
                            <div className="pull-left">
                                <p className="cursor_pointer font15 color6 log_water_txt" data-toggle="modal" data-target="#water_log"><img alt="" src="/assets/images/plus_icon.png" className="vtt" onClick={() => {
                                    setWaterRemove(true)
                                    setWaterLogPopup(true)
                                    setAddorEditWaterLog('add')
                                    setWater_count(8)
                                }} /> &nbsp;&nbsp;&nbsp;&nbsp; Log Additional Water</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={waterQuestionPopup} id="waterpopupmodel" onHide={() => { onHideWaterQuestionPopup() }}>
                <Modal.Header>
                    <button type="button" className="close" onClick={() => { setWaterQuestionPopup(false) }}>
                        <img alt="" src="/assets/images/close_grey_new_modal.png" />
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-body">
                        <img alt="" src="/assets/images/water_on_circle.png" className="water_glass mt0" style={{ width: '48px', height: '48px', flexShrink: '0' }} />
                        <p className="water_question_heading mt2 water-heading-text">{window.keystrings['com.numi.water_heading_text']}</p>

                        <p className="water-additional-text water_question_content" dangerouslySetInnerHTML={{ __html: window.keystrings['com.numi.dynamic_water_additional_content'] }}></p>
                        <center><button type="button" data-dismiss="modal" style={{ width: '254px', height: '45px', marginTop: '15px' }} onClick={() => { setWaterQuestionPopup(false) }}>Close</button></center>
                    </div>

                </Modal.Body>
            </Modal>

            <Modal show={waterLogPopup} id="water_log" onHide={() => { onHideWaterLogPopup() }}>
                <Modal.Header>
                    <button type="button" className="close" onClick={() => { setWaterLogPopup(false) }}>
                        <img alt="" src="/assets/images/close_grey_new_modal.png" />
                    </button>
                </Modal.Header>
                <Modal.Body>
                    {
                        waterLogPopupBody === false ? (
                            <div className="modal-body">
                                <p className="water_txt mb-2 water_question_heading">Log Water</p>


                                {window.localStorage.getItem('water_state') == 'false' ?
                                    <h6 className="mt color6">Drag to log your water intake</h6>
                                    :
                                    <div>
                                        <p className="mb-0 font18">Your Water Goal Adjusts to You</p>
                                        <div onClick={() => { handleShowMoreClick() }} style={{ display: !showMore ? 'block' : 'none' }}>
                                            <p className="water-show-more d-inline-block mr1">Show More</p>
                                            <img alt="" src="/assets/images/arrow_down_grey.png" className="cursor_pointer" />
                                        </div>

                                        <div style={{ display: showMore ? 'block' : 'none' }}>
                                            <p className="water-additional-text font16 pd10 mb0" dangerouslySetInnerHTML={{ __html: waterAdditionalContent }} />
                                            <div onClick={() => { handleShowLessClick() }}>
                                                <p className="water-show-less d-inline-block mr1">{waterShowLessContent}</p>
                                                <img alt="" src="/assets/images/arrow_up_grey.png" className="cursor_pointer" />
                                            </div>
                                        </div>

                                        <h6 className="mt color6 mb-0 font22">Drag to log your water intake</h6>
                                    </div>
                                }


                                <h6 className="dynamic_water_goal"></h6>
                                <div className="watelog_back mt10">
                                    <p className="water_log_level">{water_count}</p>
                                    <span className="colorff">oz.</span>

                                    <div className='slider'>
                                        <Slider
                                            min={1}
                                            max={64}
                                            value={water_count}
                                            onChange={(e) => { waterChange(e) }}
                                        />
                                    </div>

                                    <div className="ounce_images">
                                        <img alt="" src="/assets/images/4_ounce.png" onClick={() => { fourOunce() }} />
                                        <img alt="" src="/assets/images/8_ounce.png" onClick={() => { eightOunce(this) }} />
                                        <img alt="" src="/assets/images/16_ounce.png" className="ounce_16" onClick={() => { sixteenOunce() }} />
                                        <img alt="" src="/assets/images/32_ounce.png" className="ounce_32" onClick={() => { thrirtytwoOunce() }} />
                                        <img alt="" src="/assets/images/48_ounce.png" className="ounce_48" onClick={() => { fortyeightOunce() }} />
                                        <img alt="" src="/assets/images/64_ounce.png" className="ounce_64" onClick={() => { sixtyfourOunce() }} />
                                    </div>
                                    <p className="water-disclaimer-text mt20 water-disclaimer-text-style">{window.keystrings['com.numi.water_disclaimer']}</p>
                                </div>
                                <p className="mt10">
                                    <button className="width70 water_remove" onClick={() => { removeWater() }} style={{ display: waterRemove === true ? "none" : "inline-block" }}>Remove</button>
                                    <button className="width70 water_save" onClick={() => { logWater() }}>Save</button>
                                </p>
                            </div>
                        )
                            : (<div className='modal-body'>
                                <div className="water_error error_txt text-center font14">{waterLogPopupError}</div>
                            </div>)
                    }

                </Modal.Body>
            </Modal>

        </>

    )
}

export default WaterGoalSection;