
import React from 'react';
import { Link } from 'react-router-dom';
import Footer from "../footer.js";
import Header from "../header.js";
import Navigation from "../nav.js";
import Adspeed from "../adspeed.js";
import DatePicker from "react-datepicker";
import moment from 'moment';
import { addDays, parse } from 'date-fns';
import axios from 'axios';
import $ from 'jquery';
import * as Sentry from "@sentry/react";


class EditCalculatedActivity extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      item_name: window.localStorage.getItem('edit_cal_doable_header'), fav_calories: '', add_fav_sec: true, add_to_fav: window.keystrings['com.numi.add_to_favorites'], add_fav_off: "/assets/images/fav_off.png", remove_fav_sec: false, show_cal: false, create_activity_hdr: '', save_btn: true, update_btn: false, activity_dur: '', activity_speed: '', burn_calorie: '', activity_distance: '', edit_date: window.localStorage.getItem('assigned_date')
    };

    this.addCalculatedFavorite = this.addCalculatedFavorite.bind(this);
    this.editCalculatedActivity = this.editCalculatedActivity.bind(this);
    this.addCalculatedActivity = this.addCalculatedActivity.bind(this);
    this.UpdateCalActivity = this.UpdateCalActivity.bind(this);
    this.removeCalActivity = this.removeCalActivity.bind(this);
    this.handleChange = this.handleChange.bind(this);

  }
  calActivitycalorie(e) {
    if (e.currentTarget.value.length <= 3) {
      this.setState({ burn_calorie: e.currentTarget.value });
    }
  }

  handleChange(date) {
    this.setState({
      edit_date: date
    });

    var complete_date = moment(date).format('MMMM DD, YYYY');
    var actual_date = moment().format('MMM DD');
    var pageload_selecetd_date = moment(date).format('MMM DD');
    if (pageload_selecetd_date === actual_date) {
      $('.calc_activities_page .today_date').show();
      $('.calc_activities_page .today_date').text('Today')
    }
    else {
      $('.calc_activities_page .today_date').show();
      $('.calc_activities_page .today_date').text(complete_date)
    }
    Header.changeDate(date);

  }



  calActivityInput(e) {
    Header.startLoader();
    e.preventDefault();
    this.setState({ [e.currentTarget.id]: e.currentTarget.value });

    var activity_name = window.localStorage.getItem('activity_name');
    if (e.currentTarget.value !== "" && !isNaN(parseInt(e.currentTarget.value))) {
      const options = {
        url: window.API_URL + 'v1/calculated_activities/calculate?activity_header=' + activity_name + '&duration=' + $('#activity_dur').val() + '&distance=' + $('#activity_distance').val() + '&speed=' + $('#activity_speed').val() + '',
        method: 'GET',
        credentials: 'same-origin',
        headers: Header.setAuthHeaders(),
      };

      axios(options)
        .then(response => {
          Header.stopLoader();
          $('.duartion_error').text('');
          if (this.state.activity_dur === 0) {
            $('.edit_logit_btn').addClass('pointer_none');
            $('.edit_logit_btn button').addClass('btn disabled');
            if (response && response.data && response.data.error) {
              $('.duartion_error').text(response.data.error);
            }
            else if (response && response.status > 400) {
              $('.duartion_error').text(response.data.statusText);
            }
          } else {
            $('.duartion_error').text('');
            $('.edit_logit_btn').removeClass('pointer_none');
            $('.edit_logit_btn button').removeClass('btn disabled');
          }
          var $emptyFields = $('.calc_activities_page input[type="text"]').filter(function () {
            return $.trim(this.value) === "";
          });

          if (!$emptyFields.length) {
            $('.remove_update').removeClass('pointer_none');
            $('.remove_update button').removeClass('btn disabled');
          }
          else {
            $('.remove_update').addClass('pointer_none');
            $('.remove_update button').addClass('btn disabled');
          }
          this.setState({ activity_speed: response.data.calculated_activity.speed, activity_dur: response.data.calculated_activity.duration, burn_calorie: response.data.calculated_activity.calories, activity_distance: response.data.calculated_activity.distance });

        })
        .catch(error => {
          Header.stopLoader();
          $('.duartion_error').text('');
          if (this.state.activity_dur === 0) {
            $('.edit_logit_btn').addClass('pointer_none');
            $('.edit_logit_btn button').addClass('btn disabled');
            if (error && error.response && error.response.data && error.response.data.error) {
              $('.duartion_error').text(error.response.data.error);
            }
            else if (error && error.response && error.response.status > 400) {
              $('.duartion_error').text(error.response.statusText)
            }
          } else {
            $('.duartion_error').text('');
            $('.edit_logit_btn').removeClass('pointer_none');
            $('.edit_logit_btn button').removeClass('btn disabled');
          }
          Sentry.captureException(error);
        });


    }
    else {
      Header.stopLoader();
    }
  }


  calActivitydistance(e) {
    Header.startLoader();
    e.preventDefault();
    this.setState({ [e.currentTarget.id]: e.currentTarget.value });

    var activity_name = window.localStorage.getItem('activity_name');

    if (e.currentTarget.value !== "" && !isNaN(parseInt(e.currentTarget.value))) {
      const options = {
        url: window.API_URL + 'v1/calculated_activities/calculate?activity_header=' + activity_name + '&duration=' + $('#activity_dur').val() + '&distance=' + $('#activity_distance').val() + '&speed=' + $('#activity_speed').val() + '',
        method: 'GET',
        credentials: 'same-origin',
        headers: Header.setAuthHeaders(),
      };

      axios(options)
        .then(response => {
          Header.stopLoader();
          this.setState({ activity_speed: response.data.calculated_activity.speed, activity_dur: response.data.calculated_activity.duration, burn_calorie: response.data.calculated_activity.calories });

        })
        .catch(error => {
          Header.stopLoader();
          Header.apiErrors(error);
          Sentry.captureException(error);
        });

    }
    else {
      Header.stopLoader();
    }

    if (e.currentTarget.value === "" || e.currentTarget.value.indexOf('.', e.currentTarget.value.indexOf('.') + 1) !== -1) {
      $('.remove_update').addClass('pointer_none');
      $('.remove_update button').addClass('btn disabled');
    } else {

      $('.remove_update').removeClass('pointer_none');
      $('.remove_update button').removeClass('btn disabled');
    }
  }


  editCalculatedActivity() {

    this.setState({
      edit_icon: '',
      save_btn: false,
      update_btn: true,
    });

  }

  addCalculatedFavorite() {
    var doable_id = window.localStorage.getItem("edit_cal_doable_id");
    if (this.state.add_to_fav === "Add to Favorites") {
      this.setState({
        add_to_fav: window.keystrings['com.numi.remove_from_favorites'],
        add_fav_off: "/assets/images/fav_on.png",

      });
      window.localStorage.setItem("edit_cal_favourite", 'true');

      if (doable_id) {
        this.addCalculatedActivity();
      }

    }
    else {
      this.setState({
        add_to_fav: window.keystrings['com.numi.add_to_favorites'],
        add_fav_off: "/assets/images/fav_off.png",
      });
      window.localStorage.setItem("edit_cal_favourite", 'false');
      if (doable_id) {
        this.addCalculatedActivity();
      }
    }


  }

  addCalculatedActivity() {
    Header.startLoader();

    var favorite_activities = { "favorite_activity": { "doable_id": window.localStorage.getItem("edit_cal_doable_id"), "doable_type": "CalculatedActivity", "favorite": window.localStorage.getItem("edit_cal_favourite") } }


    //create favorited activity
    const options = {
      url: window.API_URL + 'v1/favorite_activities',
      method: 'POST',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
      data: favorite_activities
    };

    axios(options)
      .then(response => {
        Header.stopLoader();
        this.setState({ favorite_activity: response.data.favorite_activity })
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });

  }

  UpdateCalActivity() {
    var duration = this.state.activity_dur;
    var calories_burned = this.state.burn_calorie;
    var assigned_date = moment($('.calc_activities_page  .react-datepicker__input-container input').val()).format("YYYY-MM-DD");
    var edit_id = window.localStorage.getItem('edit_cal_id');
    var doable_id = window.localStorage.getItem('edit_cal_doable_id');
    var doable_type = window.localStorage.getItem("edit_cal_doable_type");
    var speed = this.state.activity_speed;
    var distance = this.state.activity_distance;

    var activity_history = { "activity_history": { doable_id: doable_id, doable_type: doable_type, duration: duration, calories: calories_burned, assigned_date: assigned_date, speed: speed, distance: distance } }
    Header.startLoader();

    const options = {
      url: window.API_URL + "v1/activity_histories/" + edit_id,
      method: 'PUT',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
      data: activity_history
    };

    axios(options)
      .then(response => {
        Header.stopLoader();
        window.localStorage.setItem('assigned_date', moment($('.calc_activities_page  .react-datepicker__input-container input').val()).format("YYYY-MM-DD"));
        this.props.history.push('/journal');
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });



  }

  removeCalActivity() {

    const options = {
      url: window.API_URL + 'v1/activity_histories/' + window.localStorage.getItem('edit_cal_id'),
      method: 'DELETE',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        Header.stopLoader();
        this.props.history.push('/journal');
      })
      .catch(error => {
        Header.stopLoader();
        this.props.history.push('/journal');
        Sentry.captureException(error);
      });

  }


  componentDidMount() {

    $('.calc_activities_page #activity_distance').keyup(function () {
      var distance_val = $('#activity_distance').val();
      if (distance_val.indexOf('.') > 0) {
        $('.calc_activities_page #activity_distance').attr('maxlength', '5')
      }
      else {
        $('.calc_activities_page #activity_distance').attr('maxlength', '4')
      }
    });

    window.localStorage.setItem('item_clicked_header', '');

    $(document).on("input", ".calc_activities_page input[type='text']:not(#activity_distance)", function (e) {
      this.value = this.value.replace(/[^0-9]/g, '');

    });

    $(document).on("input", ".calc_activities_page #activity_distance", function (e) {
      this.value = this.value.replace(/[^0-9.]/g, '');

    });

    $('.calc_activities_page input[type="text"]:not(#activity_distance)').keyup(function () {
      var $emptyFields = $('.calc_activities_page input[type="text"]:not(#activity_distance)').filter(function () {
        return $.trim(this.value) === "";
      });

      if (!$emptyFields.length) {
        $('.remove_update').removeClass('pointer_none');
        $('.remove_update button').removeClass('btn disabled');
      }
      else {
        $('.remove_update').addClass('pointer_none');
        $('.remove_update button').addClass('btn disabled');
      }
    });

    const options = {
      url: window.API_URL + "v1/activity_histories/" + window.localStorage.getItem('activity_history_id'),
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        Header.stopLoader();
        this.setState({ burn_calorie: response.data.activity_history.calories, activity_dur: response.data.activity_history.duration, assigned_date: response.data.activity_history.assigned_date, activity_speed: response.data.activity_history.speed, activity_distance: response.data.activity_history.distance });


        window.localStorage.setItem('edit_cal_id', response.data.activity_history.id);
        window.localStorage.setItem('edit_cal_doable_id', response.data.activity_history.doable_id);
        window.localStorage.setItem('edit_cal_doable_type', response.data.activity_history.doable_type);
        window.localStorage.setItem('edit_cal_doable_description', response.data.activity_history.description);
        window.localStorage.setItem('edit_cal_doable_header', response.data.activity_history.header);
        window.localStorage.setItem('edit_cal_favourite', response.data.activity_history.favorite)
        window.localStorage.setItem('edit_speed', response.data.activity_history.speed)
        window.localStorage.setItem('edit_distance', response.data.activity_history.distance)


        if (response.data.activity_history.favorite === false) {
          this.setState({
            add_to_fav: 'Add to Favorites',
            add_fav_off: "/assets/images/fav_off.png"
          })
        }
        else {
          this.setState({
            add_to_fav: 'Remove From Favorites',
            add_fav_off: "/assets/images/fav_on.png"
          })
        }
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });



    Header.changeDate(window.localStorage.getItem('assigned_date'));
    /*keystrings*/
    $('.back_to_journal_text').append(window.keystrings['com.numi.back_to_journal'])
    $('.food_details_date_text').text(window.keystrings['com.numi.food_details_date'])
    $('.activity_duration').text(window.keystrings['com.numi.duration'] + '*');
    $('.activity_calories_burned').text(window.keystrings['com.numi.calories_burned']);
    $('.create_food_update_text').text(window.keystrings['com.numi.create_food_update'])
    $('.remove_text').text(window.keystrings['com.numi.remove'])
    $('.edit_activity_checkbox_text').text(window.keystrings['com.numi.edit_activity_checkbox_text'])
    $('.distance_text').text(window.keystrings['com.numi.distance'] + '*')
    $('.speed_text').text(window.keystrings['com.numi.speed'])
    /*keystrings*/

    $("#burn_calorie").keypress(function (e) {
      //if the letter is not digit then display error and don't type anything
      if (e.which !== 8 && e.which !== 0 && (e.which < 48 || e.which > 57)) {
        //display error message
        if (e.currentTarget.value.length <= 3) {
          this.setState({ burn_calorie: e.currentTarget.value });
        }
        return false;
      }
    }.bind(this));
  }

  render() {

    return (
      <div>

        <div className="table_display">
          <div className="journal_div calc_activities_page">
            <div className="section_left pull-left">
              <Navigation />
            </div>
            <div className="section_right pull-left">
              <figure><Adspeed /></figure>
              <div className="journal_rht create_activity task_log">
                <div className="task_todo">
                  <div className="running">
                    <p className="mb">
                      <Link to="/journal" className="back_to back_to_journal_text"><img alt="" src="/assets/images/back_white_icon.png" className="vlb" /> </Link>
                    </p>
                    <h1 className="journal_hdr word_warp pull-left" id="header_activity">{this.state.item_name}<span className="font18 color5 d_block" > {this.state.burn_calorie} {this.state.burn_calorie > 0 ? 'calories burned' : ''}</span></h1>
                    <div className="pull-right  font15 color5 add_fav_div relative top10 cursor_pointer"><span className="add_fav" onClick={this.addCalculatedFavorite}><img alt="" src={this.state.add_fav_off} /> {this.state.add_to_fav}</span></div>
                    <div className="clear"></div>
                    <hr />
                    <div className="duartion_error font14 error"></div>
                    <div className="pull-left inner_sec mt2">
                      <p className="border-bottom">
                        <span className="font16 color6 text-left left_cnt distance_text"></span>
                        <span className="font16 color6 text-right right_cnt">
                          <span className="">
                            <input type="text" maxLength="4" className="duration right_input" placeholder="30" id="activity_distance" onChange={this.calActivitydistance.bind(this)} value={this.state.activity_distance} /> miles
                          </span></span>
                      </p>
                      <p className="border-bottom">
                        <span className="font16 color6 text-left left_cnt speed_text"></span>
                        <span className="font16 color6 text-right right_cnt"><input type="text" className="right_input" maxLength="3" placeholder="30" id="activity_speed" onChange={this.calActivityInput.bind(this)} value={this.state.activity_speed} /> MPH</span>
                      </p>
                      <div className="activity_date">
                        <span className="font16 color6 text-left left_cnt food_details_date_text"></span>
                        <span className="font16 color6 relative text-right right_cnt vlm"><DatePicker selected={parse(this.state.edit_date)} onChange={this.handleChange} minDate={parse(window.localStorage.getItem('profile_start_date'))} maxDate={addDays(new Date(), 90)} /></span></div>
                    </div>
                    <div className="pull-right inner_sec mt2">
                      <p className="border-bottom">
                        <span className="font16 color6 text-left left_cnt activity_duration"></span>
                        <span className="font16 color6 text-right right_cnt"><input type="text" className="duration right_input" maxLength="3" placeholder="30" id="activity_dur" onChange={this.calActivityInput.bind(this)} value={this.state.activity_dur} /> Min</span>
                      </p>
                      <p>
                        <span className="font16 color6 text-left left_cnt activity_calories_burned"></span>
                        <span className="font16 color6 text-right right_cnt"><input type="text" id='burn_calorie' className="burn_calorie" placeholder="0" onChange={this.calActivitycalorie.bind(this)} value={this.state.burn_calorie} /></span>
                      </p>
                    </div>
                    <div className="clear"></div>
                    <p className="remember_me" style={{ display: this.state.update_checkbox ? 'block' : 'none' }}>
                      <input id="activity_check" type="checkbox" checked={this.state.isChecked} onChange={this.rememberCheckbox} />
                      <label htmlFor="activity_check">
                        <span className="bullet_icon">
                          <i></i>
                        </span>
                        <span className="font15 color7 edit_activity_checkbox_text">
                        </span>
                      </label></p>
                    <p className="pull-left mr3 remove_update edit_logit_btn" onClick={this.removeCalActivity.bind(this)}><button disabled={this.state.disable} className={this.state.disable_btn + ' remove_text'}></button></p>
                    <p className="pull-left remove_update edit_logit_btn" onClick={this.UpdateCalActivity.bind(this)}><button disabled={this.state.disable} className={this.state.disable_btn + ' create_food_update_text'}></button></p>

                  </div>
                </div>
              </div>

              <Footer />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default EditCalculatedActivity;
