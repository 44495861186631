import React from 'react';
import { } from 'react-router-dom'
import Header from "../header.js";
import Footer from "../footer.js";
import { Dropdown, YearDropdown, Days } from './select_dropdown.js';
import DatePicker from "react-datepicker";
import { addDays, subDays, parse } from 'date-fns';
import axios from 'axios';
import moment from 'moment';
import { LogoutPopup } from '../journal/popup.js';
import $ from 'jquery';
import * as Sentry from "@sentry/react";

class Profile extends React.Component {
  constructor(props) {
    super(props);

    this.state = { startDate: new Date(), profile_data: "", gender_radio: '', guide_radio: 'daily_guide', success_phase_radio: 'greater_than_60', disable_btn: 'btn disabled', disable: 'disabled', error_class: '', error_txt: '', error: '', birth_month: '', birthdate: '', birth_year: '', height_feet: '', height_inch: '', current_weight: '', goal_weight: '', start_date: '', program_id: '', plan_name: '' };
    this.radio_toggle = this.radio_toggle.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validate_fileds = this.validate_fileds.bind(this);
  }

  handleInputChange(e) {
    this.setState({ [e.target.id]: e.target.value });
  }

  radio_toggle(e) {
    this.setState({
      gender_radio: e.target.id,
      guide_radio: e.target.id,
      success_phase_radio: e.target.id,
      disable_btn: "",
      disable: ""
    });
  }

  validate_fileds() {
    var $emptyFields = $('.validate_profile_input input[type="text"]').filter(function () {
      return $.trim(this.value) === "";
    });
    var month_val = document.getElementById("month_drop");
    var month_sel = month_val.options[month_val.selectedIndex].value;

    var day_val = document.getElementById("pick_date");
    var day_sel = day_val.options[day_val.selectedIndex].value;

    var year_val = document.getElementById("Year_drop");
    var year_sel = year_val.options[year_val.selectedIndex].value;
    var $emptymonth = month_sel;
    var $emptyday = day_sel;
    var $emptyyear = year_sel;


    if (!$emptyFields.length && $emptymonth !== '' && $emptymonth !== 'Month' && $emptyday !== '' && $emptyday !== 'Day' && $emptyyear !== '') {
      $('.submit_btn').removeClass('btn disabled').removeAttr('disabled');

    }
    else {
      $('.submit_btn').addClass('btn disabled').attr('disabled', true);
    }
  }

  handleChange(date) {
    this.setState({
      startDate: date
    });

    var actual_date = moment().format('MMMM DD, YYYY');
    var pageload_selecetd_date = moment(date).format('MMMM DD, YYYY');


    if (new Date(pageload_selecetd_date) >= new Date(actual_date)) {
      $('#profile_sec .today_date').text('Today');
      $('.show_start_weight').empty();
      this.validate_fileds();
    }
    else {
      $('.show_start_weight').html('<span class="left_sec">Starting Weight</span> <span class="goal_weight"><input type="text" maxLength="6" id="start_weight" /> lbs.</span>');
      this.validate_fileds();
      $('#profile_sec .today_date').text(pageload_selecetd_date);
      /*$('#start_weight').val(this.state.current_weight);*/
    }
    Header.changeDate(date);
  }
  validateEmail(e) {
    e.preventDefault();
    const formData = {};
    for (const field in this.refs) {
      formData[field] = this.refs[field].value;
    }
    if ($('#month_drop').val() === "Month") {

      this.setState({
        error_txt: window.keystrings['com.numi.profile_highlighted_field'],
        error_class: 'error_border',
        error: 'error'
      });
    }
    else {
      this.setState({
        error_txt: '',
        error_class: '',
        error: ''
      });
    }
  }


  // Create Profile api call
  submitUserProfile(e) {
    e.preventDefault();
    window.localStorage.setItem('set_month', "false");
    var first_name = window.localStorage.getItem('FirstName');
    var last_name = window.localStorage.getItem('LastName');
    var gender = window.localStorage.getItem('gender');

    var start_weight = $("#start_weight").val(); // get start weight
    var start_date = moment(parse($("#profile_sec .react-datepicker__input-container input").val())).format('YYYY-MM-DD');
    var assigned_date = moment().format('YYYY-MM-DD');
    window.months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    var month = ("0" + ($.inArray($("#month_drop option:selected").text(), window.months) + 1)).slice(-2);
    var dob = (("0" + $("#pick_date option:selected").text()).slice(-2) + '-' + month + '-' + $("#Year_drop option:selected").text());

    var profile_params = { "profile": { "first_name": first_name, "last_name": last_name, "birthdate": dob, "gender": gender, "current_program_id": 1, "status": "PROFILE_COMPLETE", "height": { "feet": this.state.height_feet, "inches": this.state.height_inch }, "weight": { "starting": start_weight, "current": this.state.current_weight, "goal": this.state.goal_weight, "assigned_date": assigned_date }, "profile_properties": [{ "key": "START_DATE", "value": start_date }] } };
    var typeMethod = "";

    if (localStorage.getItem('user_profile_id') === null || localStorage.getItem('user_profile_id') === "null" || localStorage.getItem('user_profile_id') === undefined) {
      typeMethod = "POST"
    }
    else {
      typeMethod = "PUT"
    }
    Header.startLoader(); // start loader

    const options = {
      url: window.API_URL + "v1/profile",
      method: typeMethod,
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
      data: profile_params
    };

    axios(options)
      .then(response => {
        if (response && response.data) {
          window.localStorage.setItem('profile_status', response.data.profile.status);
          window.localStorage.setItem('progres_current_weight', response.data.profile.weight.current);
          window.localStorage.setItem('progres_goal', response.data.profile.weight.goal);
          window.localStorage.setItem('plan_starting_weight', response.data.profile.weight.plan_starting);
          localStorage.setItem('nutrisystem_linked', response.data.profile.nutrisystem_profile_linked);
          response.data.profile.profile_properties.forEach(function (entry) {
            if (entry.key === "PLAN_START_DATE") {
              window.localStorage.setItem('plan_start_date', entry.value);
            } else if (entry.key === "START_DATE") {
              window.localStorage.setItem('profile_start_date', entry.value);
            }
          });
          var evt = document.createEvent("CustomEvent");
          evt.initCustomEvent('CreateNumiAccount', true, false, false);
          document.dispatchEvent(evt);
          this.props.history.push('/ns_plan');
        }
      })
      .catch(error => {

        Header.stopLoader(); // stop loader
        $('#bmi_healthy h3').text('');
        if (error && error.response && error.response.data && error.response.data.error) {
          if (error.response.data.error.indexOf('The entered weight is below your healthy weight range.') >= 0) {

            $('#bmi_healthy_low').modal('show');
            $('#bmi_healthy_low h3').text(error.response.data.error);
          } else if (error.response.data.error.indexOf('The entered weight is above your healthy weight range.') >= 0) {
            $('#bmi_healthy_high').modal('show');
            $('#bmi_healthy_high h3').text(error.response.data.error);
          }
          else {
            $('#bmi_healthy').modal('show');
            $('#bmi_healthy h3').text(error.response.data.error);
          }
        }
        else if (error && error.response && error.response.status > 400) {
          alert(error.response.statusText)
        }
        else {
          alert(error.message ? error.message : 'Something went wrong!');
        }
        Sentry.captureException(error);
      });



  }
  //api call end


  componentDidMount() {
    Header.changeDate(new Date());
    /*keystrings*/
    $('.profile_form_header_text').text(window.keystrings['com.numi.profile_form_header'])
    $('.profile_dob_text').text(window.keystrings['com.numi.profile_dob'])
    $('.profile_height_text').text(window.keystrings['com.numi.profile_height'])
    $('.profile_form_option_current_weight_text').text(window.keystrings['com.numi.profile_form_option_current_weight'])
    $('.profile_form_option_goal_weight_text').text(window.keystrings['com.numi.profile_form_option_goal_weight'])
    $('.profile_form_option_start_date_text').text(window.keystrings['com.numi.profile_form_option_start_date'])
    $('.continue_text').text(window.keystrings['com.numi.continue'])
    $('.ok_all_caps_text').text(window.keystrings['com.numi.ok_all_caps'])
    $('.journal_stage_options_text').text(window.keystrings['com.numi.journal.stage_options'])
    $('.guidance_label_text').text(window.keystrings['com.numi.guidance_label'])
    $('.guidance_button_1_text').text(window.keystrings['com.numi.guidance_button_1'])
    $('.guidance_button_2_text').text(window.keystrings['com.numi.guidance_button_2'])
    $('.guidance_info_1_text').text(window.keystrings['com.numi.guidance_info_1'])
    $('.goal_weight_label_text').text(window.keystrings['com.numi.goal_weight_label'])
    /*keystrings*/

    window.localStorage.setItem('set_month', "true");
    var show_month = [
      {
        month: 'January',
        code: '1',
      },
      {
        month: 'February',
        code: '2',
      },
      {
        month: 'March',
        code: '3',
      },
      {
        month: 'April',
        code: '4',
      },
      {
        month: 'May',
        code: '5',
      },
      {
        month: 'June',
        code: '6',
      },
      {
        month: 'July',
        code: '7',
      },
      {
        month: 'August',
        code: '8',
      },
      {
        month: 'September',
        code: '9',
      },
      {
        month: 'October',
        code: '10',
      },
      {
        month: 'November',
        code: '11',
      },
      {
        month: 'December',
        code: '12',
      }
    ];

    // Landing page based on profile status
    var profile_status = window.localStorage.getItem('profile_status');
    // var nutriSignup = window.localStorage.getItem('nutriSignup');
    var numiLegacy = window.localStorage.getItem('numi_legacy');
    if (profile_status === "ONBOARDING_COMPLETE") {
      window.localStorage.setItem('assigned_date', moment().format('YYYY-MM-DD'));
      this.props.history.push('/journal');
    }
    else if (profile_status === "PROFILE_COMPLETE" && numiLegacy === "true") {
      this.props.history.push('/welcome');
    }
    else if ((profile_status === "PROFILE_COMPLETE" || profile_status === "ONBOARDING") && numiLegacy !== "true") {
      this.props.history.push('/ns_plan');
    }
    else if (profile_status === "PLAN_COMPLETE" && numiLegacy !== "true") {
      this.props.history.push('/stay_on_track');
    }
    else if (profile_status === "PLAN_COMPLETE" && numiLegacy !== "true") {
      this.props.history.push('/stay_on_track');
    }
    // else if (profile_status == "NEW_USER" && nutriSignup == "true")
    // {
    //   this.props.history.push('/profile');
    // }
    // onboarding profile status settings
    var profileStatus = window.localStorage.getItem('profile_status');
    var numi_legacy = window.localStorage.getItem('numi_legacy');
    var profileData = JSON.parse(window.localStorage.getItem('profileData'));

    if (profileStatus && (profileStatus === "PROFILE_COMPLETE" || profileStatus === "ONBOARDING")) {
      this.props.history.push('/welcome');
      return;
    }

    if (profileStatus && profileStatus === 'PLAN_COMPLETE' && numi_legacy) {
      this.props.history.push('/welcome');
      return;
    }

    if (profileData !== null) {
      if (localStorage.getItem('profile_start_date') !== 'null') {
        this.setState({
          startDate: new Date(localStorage.getItem('profile_start_date')),
        });
      }

      var height = profileData.height;
      var weight = profileData.weight;
      if (height !== null) {
        this.setState({
          height_feet: height.feet,
          height_inch: height.inches
        });
      }
      if (weight !== null) {
        this.setState({
          current_weight: weight.current,
          goal_weight: weight.goal
        });
      }

      setTimeout(function () {
        if (profileData.birthdate !== null) {
          let dob = profileData.birthdate.split('-');
          if (parseInt(dob[2]) < 10) {
            dob[2] = dob[2].split('')[1];
          }
          if (parseInt(dob[1]) < 10) {
            dob[1] = dob[1].split('')[1];
          }

          $('#pick_date option[value="' + dob[2] + '"]').prop('selected', true);
          $('#month_drop option[value="' + dob[1] + '"]').prop('selected', true);
          $('#Year_drop option[year_txt="' + dob[0] + '"]').prop('selected', true);

          var actual_date = moment().format('MMMM DD, YYYY');
          var pageload_selecetd_date = moment(parse(localStorage.getItem('profile_start_date'))).format('MMM DD');
          if (pageload_selecetd_date === actual_date) {
            $('#profile_sec .today_date').text('Today');
            $('.show_start_weight').empty();
            this.validate_fileds();
          }
          else {
            $('.show_start_weight').html('<span class="left_sec">Starting Weight</span> <span class="goal_weight"><input type="text" maxLength="6" id="start_weight" /> lbs.</span>');

            $('#profile_sec .today_date').text(pageload_selecetd_date);

            if (profileData.weight !== null) {
              $('#start_weight').val(profileData.weight.starting);
            }

            this.validate_fileds();
          }
        }
      }.bind(this), 1000);
    }


    $('.lets_get_start').click(function () {
      var current_user = true;
      if (current_user === true) {
        $('.free_plan').hide();
        $('.ns_plan,.left_arrow,.current_plan').show();
      }
      else {
        $('.current_plan').hide();
        $('.ns_plan,.left_arrow,.free_plan').show();
      }
    });


    $('.ns_plan li').click(function () {
      $('.ns_plan').hide();
      $('.phase_option').show();
    });

    $('.ns_plan .left_arrow').click(function () {
      $('.ns_plan').hide();
    });

    $('.phase_option .left_arrow').click(function () {
      $('.phase_option').hide();
      $('.ns_plan').show();
    });

    $('.success_phase .left_arrow').click(function () {
      $('.success_phase').hide();
      $('.ns_plan').show();
    });
    $('.success_guidance .left_arrow').click(function () {
      $('.success_guidance').hide();
      $('.success_phase').show();
    });

    $('.confirm_goal_weight .left_arrow').click(function () {
      $('.confirm_goal_weight').hide();
      $('.success_guidance').show();
    });
    $('.low_blood_sugar .left_arrow').click(function () {
      $('.low_blood_sugar').hide();
      $('.confirm_goal_weight').show();
    });

    $('.phase_option label').click(function () {
      $('.phase_option label').css('opacity', '0.4');
      $(this).css('opacity', '1');
    });
    $('.success_phase label').click(function () {
      $('.success_phase label').css('opacity', '0.4');
      $(this).css('opacity', '1');
    });
    $('.success_guidance label').click(function () {
      $('.success_guidance label').css('opacity', '0.4');
      $(this).css('opacity', '1');
    });

    $('.low_blood_sugar label').click(function () {
      $('.low_blood_sugar label').css('opacity', '0.4');
      $(this).css('opacity', '1');
    });

    $('.choose_success_plan').click(function () {
      $('.ns_plan').hide();
      $('.success_phase').show();
    });

    $('.success_phase button').click(function () {
      $('.success_phase').hide();
      $('.success_guidance').show();
    });

    $('.success_guidance button').click(function () {
      $('.success_guidance').hide();
      $('.confirm_goal_weight').show();
    });
    $('.confirm_goal_weight button').click(function () {
      $('.confirm_goal_weight').hide();
      $('.low_blood_sugar').show();
    });

    $(document).on("input", ".feet  input[type='text']", function () {
      this.value = this.value.replace(/[^0-9]/g, '');
    });
    $(document).on("input", ".curr_weight  input[type='text'],.goal_weight  input[type='text']", function () {
      this.value = this.value.replace(/[^0-9.]/g, '');
    });


    $(document).on('keyup change', '.validate_profile_input input[type="text"],.validate_profile_input select', function () {
      this.validate_fileds();
    }.bind(this));

    var get_curr_year = new Date().getFullYear();
    var get_curr_month = new Date().getMonth() + 1;

    $('#month_drop').change(function () {


      if (get_curr_year - parseInt($('#Year_drop').find('option:selected').text()) === 18) {
        if ($(this).find('option:selected').attr('value') === get_curr_month) {
          var dd = $('#pick_date option:selected').val();
          $('#pick_date option:not(option:eq(0))').remove();

          var currdate = new Date();
          for (var j = 0; j < 31; j++) {
            if (currdate.getDate() > j) {

              var get_date = j + 1;
              $('#pick_date').append("<option value=" + get_date + ">" + get_date + "</option>");
            }

          }
          if (dd !== "") {
            $('#pick_date option[value=' + dd + ']').attr('selected', true);
          }
        }
        else {
          var year_val;
          var hh = $('#pick_date option:selected').val();
          $('#pick_date option:not(option:eq(0))').remove();
          if ($('#Year_drop').val() !== "Year") {
            year_val = $('#Year_drop').val();
          }
          else {
            year_val = 2000;
          }

          var numberOfDays = daysInMonth($('#month_drop option:selected').val(), year_val);
          for (var i = 1; i <= numberOfDays; i++) {
            $('#pick_date').append('<option value=' + i + '>' + i + '</option>');
          }
          if (hh !== "") {
            $('#pick_date option[value=' + hh + ']').attr('selected', true);
          }
        }







      }
      else {
        if (get_curr_year - parseInt($('#Year_drop').find('option:selected').text()) > 18) {
          var bb = $('#pick_date option:selected').val();
          $('#pick_date option:not(option:eq(0))').remove();
          var year_val1;
          if ($('#Year_drop').val() !== "Year") {
            year_val1 = $('#Year_drop').val();
          }
          else {
            year_val1 = 2000;
          }

          var numberOfDays1 = daysInMonth($('#month_drop option:selected').val(), year_val1);
          for (var ii = 1; ii <= numberOfDays1; ii++) {
            $('#pick_date').append('<option value=' + ii + '>' + ii + '</option>');
          }
          if (bb !== "") {
            $('#pick_date option[value=' + bb + ']').attr('selected', true);
          }
        }
      }


    });
    $('#Year_drop').change(function () {

      if ((get_curr_year - parseInt($(this).find('option:selected').text())) > 18) {
        var pp = $('#month_drop option').length - 1;
        for (var gg = pp; gg < 12; gg++) {
          $('#month_drop').append("<option value=" + show_month[gg].code + ">" + show_month[gg].month + "</option>");
        }
        var cc = $('#pick_date option:selected').val();
        $('#pick_date option:not(option:eq(0))').remove();
        var year_val2;
        if ($('#Year_drop').val() !== "Year") {
          year_val2 = $('#Year_drop').val();
        }
        else {
          year_val2 = 2000;
        }

        var numberOfDays2 = daysInMonth($('#month_drop option:selected').val(), year_val2);
        for (var j = 1; j <= numberOfDays2; j++) {
          $('#pick_date').append('<option value=' + j + '>' + j + '</option>');
        }
        if (cc !== "") {
          $('#pick_date option[value=' + cc + ']').attr('selected', true);
        }
      }
      else {
        if ((get_curr_year - parseInt($(this).find('option:selected').text())) === 18) {
          for (var jj = 0; jj < 13; jj++) {
            if (jj > get_curr_month) {

              $('#month_drop option[value=' + jj + ']').remove();
            }
          }
        }
      }

      var year_reminder = $(this).find('option:selected').text() % 4;
      var month_val = $('#month_drop').val();
      var date_val = $('#pick_date').val();

      if (year_reminder !== 0 && month_val === 2 && date_val === 29) {
        $('#pick_date option[value="29"]').remove();
      } else if (year_reminder === 0 && month_val === 2) {
        $('#pick_date option[value="29"]').remove();
        if (date_val === 29) {
          $('#pick_date').append('<option selected value="29">29</option>');
        } else {
          $('#pick_date').append('<option value="29">29</option>');
        }
      } else if (year_reminder !== 0 && month_val === 2) {
        $('#pick_date option[value="29"]').remove();
      }
    });


    var daysInMonth = function (month, year) {
      return new Date(year, month, 0).getDate();
    };


    $(document).on("input", ".curr_weight input,.goal_weight input", function (e) {
      this.value = this.value.replace(/[^0-9.]/g, '');
      if (this.value.length >= 4) {
        var curr_input_value;
        if (this.value.indexOf('.') < 0) {
          curr_input_value = this.value + '.';
          curr_input_value.split('');
        }
        else {
          curr_input_value = this.value;
        }

        if (this.value.length === 4) {
          if (this.value.indexOf('.') < 0) {
            this.value = curr_input_value[0] + curr_input_value[1] + curr_input_value[2] + curr_input_value[4] + curr_input_value[3];
          }
          else {
            this.value = curr_input_value;
          }
        }
        else {
          if (this.value.length === 5) {
            if (this.value.indexOf('.') < 0) {
              this.value = curr_input_value[0] + curr_input_value[1] + curr_input_value[2] + curr_input_value[5] + curr_input_value[3] + curr_input_value[4];
            }
            else {
              this.value = curr_input_value;
            }
          }
        }
      }

    });


    $('#profile_sec .react-datepicker__input-container').click(function () {
      $(this).find('input').focus();
    })


    $('#pick_date').empty();
    var final_year_val;
    if ($('#Year_drop').val() !== "Year") {
      final_year_val = $('#Year_drop').val();
    }
    else {
      final_year_val = 2000;
    }

    var numberOfDays = daysInMonth($('#month_drop option:selected').val(), final_year_val);
    var options = '<option>Day</option>';
    for (var i = 1; i <= numberOfDays; i++) {
      options += '<option value=' + i + '>' + i + '</option>';
    }
    $('#pick_date').append(options);
    $('#month_drop').prepend('<option  class="month_txt" value="">Month</option>');
    $('.month_txt').prop('selected', true);
    $('#Year_drop').prepend('<option class="year_txt" value="">Year</option>');
    $('.year_txt').prop('selected', true);
  }
  render() {
    var months = [
      {
        month: 'January',
        code: '1',
      },
      {
        month: 'February',
        code: '2',
      },
      {
        month: 'March',
        code: '3',
      },
      {
        month: 'April',
        code: '4',
      },
      {
        month: 'May',
        code: '5',
      },
      {
        month: 'June',
        code: '6',
      },
      {
        month: 'July',
        code: '7',
      },
      {
        month: 'August',
        code: '8',
      },
      {
        month: 'September',
        code: '9',
      },
      {
        month: 'October',
        code: '10',
      },
      {
        month: 'November',
        code: '11',
      },
      {
        month: 'December',
        code: '12',
      }
    ];
    var years = [];
    for (var i = 18; i <= 100; i++) {
      var previous_year = new Date().getFullYear() - i;
      years.push({ year: previous_year, code: i })
    }

    var dates = [];
    var currdate = new Date();
    for (var j = 0; j < 31; j++) {
      if (currdate.getDate() > j) {

        var get_date = j + 1;
        dates.push({ day: get_date, code: j + 1 })
      }
      Header.stopLoader();
    }



    return (
      <div className="main_container">

        <div className="forgot_password_hero profile_page">
          <div><img alt="" src="/assets/images/numi_small_logo.png" className="logo img-responsive" /></div>
          <div className="signup_sec relative" id="profile_sec">
            <div className="profile_section validate_profile_input">
              <form onSubmit={this.submitUserProfile.bind(this)}>
                <img alt="" src="/assets/images/back_white_icon.png" className="left_arrow cursor_pointer" data-target="#logout_modal" data-toggle="modal" />
                <h3 className="profile_form_header_text"> </h3>
                <p>
                  <span className="left_sec profile_dob_text"></span>
                  <span className="select_back" ref="month">
                    <Dropdown id='month_drop' bir_month={this.state.birth_month} errorClass={this.state.error_class} ref_value="month" options={months} value='1' labelField='month'
                      valueField='code' />
                  </span>

                  <span className="select_back">
                    <Days id='pick_date' curr_date={dates} value='1' datefield='day' ref_value="day" datevalue='code' />
                  </span>
                  <span className="select_back">
                    <YearDropdown id='Year_drop' total_years={years} value='1' labelField='year' ref_value="year" valueField='code' />
                  </span>
                </p>
                <p><span className="left_sec profile_height_text"></span> <span className="feet"><input type="text" ref="height" maxLength="1" id="height_feet" value={this.state.height_feet} onChange={this.handleInputChange} onKeyUp={this.handleInputChange} /> ft.</span> <span className="feet"><input type="text" maxLength="2" id="height_inch" value={this.state.height_inch} onChange={this.handleInputChange} onKeyUp={this.handleInputChange} /> in.</span></p>
                <p><span className="left_sec profile_form_option_current_weight_text"></span> <span className="curr_weight"><input type="text" maxLength="6" id="current_weight" value={this.state.current_weight} onChange={this.handleInputChange} onKeyUp={this.handleInputChange} /> lbs.</span></p>
                <p><span className="left_sec profile_form_option_goal_weight_text"></span> <span className="goal_weight"><input type="text" maxLength="6" id="goal_weight" value={this.state.goal_weight} onChange={this.handleInputChange} onKeyUp={this.handleInputChange} /> lbs.</span></p>
                <div className="relative date_pick">
                  <span className="left_sec profile_form_option_start_date_text"></span>

                  <DatePicker selected={parse(this.state.startDate)} onChange={this.handleChange} maxDate={addDays(new Date(), 29)} minDate={subDays(new Date(), 29)} />
                </div>
                <div className="clear"></div>
                <p className="mt2 show_start_weight"></p>
                <p className="text-center d_block">
                  <input type="submit" value="Continue" id="profile_sub_btn" className="submit_btn btn disabled continue_text" disabled />
                </p>
              </form>
            </div>



            <div className="modal fade log_food" id="bmi_healthy_low">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="bmi_popup">
                      <img alt="" src="/assets/images/warning_tri.png" className="warning" />
                      <h3> </h3>
                      <button data-dismiss="modal" className="ok_bmi_low ok_all_caps_text"></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>



            <div className="modal fade log_food" id="bmi_healthy_high">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="bmi_popup">
                      <img alt="" src="/assets/images/warning_tri.png" className="warning" />
                      <h3> </h3>
                      <button data-dismiss="modal" className="ok_bmi_high ok_all_caps_text"></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="modal fade log_food" id="bmi_healthy">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="bmi_popup">
                      <img alt="" src="/assets/images/warning_tri.png" className="warning" />
                      <h3> </h3>
                      <button data-dismiss="modal" className="ok_all_caps_text"></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="success_guidance">
              <img alt="" src="/assets/images/back_white_icon.png" className="left_arrow" />
              <div className="text-center">
                <h6 className="journal_stage_options_text"> </h6>
                <h2 className="guidance_label_text"> </h2>
                <p className="daily_guide">
                  <input className="" type="radio" id="daily_guide" checked={this.state.guide_radio === "daily_guide"} onChange={this.radio_toggle} />
                  <label htmlFor="daily_guide" className="guidance_button_1_text"></label>
                </p>

                <p className="meal_guide">
                  <input className="" type="radio" id="meal_guide" checked={this.state.guide_radio === "meal_guide"} onChange={this.radio_toggle} />
                  <label htmlFor="meal_guide" className="guidance_button_2_text"></label>
                </p>
                <p className="daily_hdr guidance_button_1"></p>
                <div className="daily_sub guidance_info_1_text"></div>

                <p className="daily_hdr meal_guide_p guidance_button_2_text"></p>
                <div className="daily_sub guidance_info_1_text"></div>

                <button className="continue_text"></button>
              </div>
            </div>


            <div className="confirm_goal_weight">
              <img alt="" src="/assets/images/back_white_icon.png" className="left_arrow" />
              <div className="text-center">
                <h6 className="journal_stage_options_text"> </h6>
                <h3 className="goal_weight_label_text"> </h3>
                <p>
                  <span className="left_sec profile_form_option_current_weight_text"></span> <span className="curr_weight"><input type="text" /> lbs.</span>
                </p>
                <p>
                  <span className="left_sec profile_form_option_goal_weight_text"></span> <span className="goal_weight"><input type="text" /> lbs.</span>
                </p>


                <button className="continue_text"></button>
              </div>
            </div>


          </div>
        </div>
        <Footer />
        <LogoutPopup />
      </div>

    );
  }
}


export default Profile;
