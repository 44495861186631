import React from 'react';
import Photos from './photos'


class Badges extends React.Component {

  render() {
    return (
      <div className="badges_page" id="badges_page">
        <Photos/>
      </div>
    );
  }
}
export default Badges;