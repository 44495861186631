import React from 'react';
import { Link } from 'react-router-dom'
import Footer from "../footer.js";
import Header from "../header.js";
import Navigation from "../nav.js";
import Adspeed from "../adspeed.js";
import FilterSearch from "./filter_search.js";
import FoodFilter from "./food_search.js";
import moment from 'moment';
import axios from 'axios';
import MealDropdown from "../journal/meal_dropdown.js";
import $ from 'jquery';
import * as Sentry from "@sentry/react";

class Log_Food extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      food_categories: [],
      foods_list: "",
      recent_list: [],
      classActive: "",
      sectionType: "Nutrisystem",
      fav_list: [], filter_search_param: '', food_list_images_icons_caps: {}, food_list_images: {
        "All Foods": "",
        "Nutrisystem Entree": "/assets/images/ne_tag.png",
        "PowerFuel": "/assets/images/pf_tag.png",
        "SmartCarb": "/assets/images/sc_tag.png",
        "Vegetables": "/assets/images/v_tag.png",
        "Other": "/assets/images/oth_tag.png",
        "Extras": "/assets/images/ex_tag.png",
        "Purple": "/assets/images/pl_tag.png",
        "Pink": "/assets/images/pk_tag.png",
        "White": "/assets/images/w_tag.png",
        "Orange": "/assets/images/o_tag.png",
        "Green": "/assets/images/g_tag.png",
        "Free Food": "/assets/images/ff_tag.png",
        "Nutricrush": "/assets/images/nc_tag.png",
        "Turboshake": "/assets/images/ts_tag.png",
        "Nutrisystem Snack": "/assets/images/ns_tag.png"
      },
      food_list_images_caps: {
        "All Foods": "",
        "NS_ENTREE": "/assets/images/ne_tag.png",
        "POWERFUEL": "/assets/images/pf_tag.png",
        "SMARTCARB": "/assets/images/sc_tag.png",
        "VEGETABLES": "/assets/images/v_tag.png",
        "OTHER": "/assets/images/oth_tag.png",
        "EXTRAS": "/assets/images/ex_tag.png",
        "PURPLE": "/assets/images/pl_tag.png",
        "PINK": "/assets/images/pk_tag.png",
        "WHITE": "/assets/images/w_tag.png",
        "ORANGE": "/assets/images/o_tag.png",
        "GREEN": "/assets/images/g_tag.png",
        "FREE_FOOD": "/assets/images/ff_tag.png",
        "NUTRICRUSH": "/assets/images/nc_tag.png",
        "TURBOSHAKE": "/assets/images/ts_tag.png",
        "NS_SNACK": "/assets/images/ns_tag.png",
        "NUTRIPRO": "/assets/images/np_tag.png"
      }
    };

    this.callLogFavAPI = this.callLogFavAPI.bind(this);
    this.callLogfoodConsumablesAPI = this.callLogfoodConsumablesAPI.bind(this);
    this.FavSearchFilter = this.FavSearchFilter.bind(this);
    this.RecentSearchFilter = this.RecentSearchFilter.bind(this);
    this.getRecommandation = this.getRecommandation.bind(this);
    this.multiFoodTextclick = this.multiFoodTextclick.bind(this);
    this.navigateToCreateFood = this.navigateToCreateFood.bind(this);
    this.getFoodCategoryIcons = this.getFoodCategoryIcons.bind(this);
    this.getMealOccasions = this.getMealOccasions.bind(this);
  }

  getFoodCategoryIcons() {
    Header.startLoader();
    const options = {
      url: window.API_URL + "v1/phase_templates/food_categories/list_icons",
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        if (response && response.data) {
          window.food_list_images_icons_caps = response.data;
          this.setState({ food_list_images_icons_caps: response.data });
        }
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });
  }

  navigateToCreateFood() {
    localStorage.removeItem('setEditfood');
    this.props.history.push('/create_food');
  }

  getMealOccasions() {
    var assigned_date = moment(window.localStorage.getItem('assigned_date')).format('YYYY-MM-DD');
    const options = {
      url: window.API_URL + "v1/meal_occasions?assigned_date=" + assigned_date,
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        window.meal_occasions_list = response.data;
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });
  }

  getRecommandation() {

    Header.startLoader();
    $(".serach_on_keyup").hide();
    $(".show_more").hide();
    $(".list-group").hide();
    $(".recent_list_ul").hide();
    var food_images = {
      "All Foods": "",
      "Nutrisystem Entree": "/assets/images/ne_tag.png",
      "PowerFuel": "/assets/images/pf_tag.png",
      "SmartCarb": "/assets/images/sc_tag.png",
      "Vegetables": "/assets/images/v_tag.png",
      "Other": "/assets/images/oth_tag.png",
      "Extras": "/assets/images/ex_tag.png",
      "Purple": "/assets/images/pl_tag.png",
      "Pink": "/assets/images/pk_tag.png",
      "White": "/assets/images/w_tag.png",
      "Orange": "/assets/images/o_tag.png",
      "Green": "/assets/images/g_tag.png",
      "Free Food": "/assets/images/ff_tag.png",
      "Nutricrush": "/assets/images/nc_tag.png",
      "Turboshake": "/assets/images/ts_tag.png",
      "Nutrisystem Snack": "/assets/images/ns_tag.png"
    }
    if (window.localStorage.getItem('meal_occasion_name') === '' || window.localStorage.getItem('meal_occasion_name') === null || window.localStorage.getItem('meal_occasion_name') === undefined) {
      var bucket_sequence;
      var meal_occasion_map = { "1": "BREAKFAST", "2": "BREAKFAST_SNACK", "3": "LUNCH", "4": "LUNCH_SNACK", "5": "DINNER", "6": "DINNER_SNACK" };
      var currentHour = moment().format('HH');

      if (currentHour <= 10 && currentHour >= 0) {
        bucket_sequence = '1';
      } else if (currentHour > 10 && currentHour < 12) {
        bucket_sequence = '2';
      } else if (currentHour >= 12 && currentHour < 14) {
        bucket_sequence = '3';
      } else if (currentHour >= 14 && currentHour < 17) {
        bucket_sequence = '4'
      } else if (currentHour >= 17 && currentHour < 20) {
        bucket_sequence = '5';
      } else if (currentHour >= 20 && currentHour <= 24) {
        bucket_sequence = '6';
      }
      window.localStorage.setItem("meal_occasion_id", bucket_sequence);
      window.localStorage.setItem("meal_occasion_name", meal_occasion_map[bucket_sequence]);
    }
    const options = {
      url: window.API_URL + "v1/food_recommendations?meal_occasion_name=" + window.localStorage.getItem('meal_occasion_name'),
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        var rec_name = '';
        var meal_occasions_list_array = $.map(window.meal_occasions_list, function (value, index) {
          return [value];
        });
        meal_occasions_list_array[0].forEach(function (option, i) {
          if (window.localStorage.getItem("meal_occasion_name") === option.name) {
            rec_name = option.display_name;
          }
        })
        $(".rec_text_over").text("Recommendation for " + rec_name);
        $('ul.food_options').empty();
        $('ul.fav_food_options').empty();
        $('ul.recent_food_options').empty();
        $('ul.all_food_options').empty();

        if (response.data.food_recommendations && response.data.food_recommendations.length > 0) {
          $('.multi_select .search_empty').hide();
          for (var k = 0; k < response.data.food_recommendations.length; k++) {


            if ($('[href="#recent"]').parent().hasClass('active')) {
              if (response.data.food_recommendations[k].display_in_search?.length > 0) {
                for (var j = 0; j < response.data.food_recommendations[k].display_in_search?.length; j++) {
                  $('ul.recent_food_options').append('<li class="meal_txt added_food"><span class="color6 item_click first_span cursor_pointer" id=' + 'serving_id-' +
                    response.data.results.food_recommendations[k].display_in_search[j].serving_size_id + '>' + response.data.food_recommendations[k].food_name + ' <br/> <span class="leaf_image"><img src="/assets/images/ns_food_tag.png" /></span> &nbsp; <span id=' + 'serving_id-' +
                    response.data.results.food_recommendations[k].display_in_search[j].serving_size_id + ' class="font12 color8 vlm">' + response.data.food_recommendations[k].display_in_search[j].calories + ' Cals - ' + response.data.food_recommendations[k].display_in_search[j].serving_unit + '</span><input type="hidden" class="consumable_id" value=' + response.data.food_recommendations[k].consumable_id + '/><input type="hidden" value=' + response.data.food_recommendations[k].consumable_type + ' class="consumable_type"/><img src="/assets/images/arrow_right_grey.png"  id=' + 'serving_id-' +
                    response.data.results.food_recommendations[k].display_in_search[j].serving_size_id + ' class="pull-right mt-10 multi_gray_arrow"/></span></li>');
                }
                $('ul.recent_food_options').append('<li class="meal_txt added_food"><span class="color6 item_click first_span cursor_pointer">' + response.data.food_recommendations[k].food_name + ' <br/> <span class="leaf_image"><img src="/assets/images/ns_food_tag.png" /></span> &nbsp; <span  class="font12  color8 vlm">' + response.data.food_recommendations[k].calories + ' Cals - ' + response.data.food_recommendations[k].serving_unit + '</span><input type="hidden" class="consumable_id" value=' + response.data.food_recommendations[k].consumable_id + '/><input type="hidden" value=' + response.data.food_recommendations[k].consumable_type + ' class="consumable_type"/><img src="/assets/images/arrow_right_grey.png" class="pull-right mt-10 multi_gray_arrow"/></span></li>');
              }
              else {
                $('ul.recent_food_options').append('<li class="meal_txt added_food"><span class="color6 item_click first_span cursor_pointer">' + response.data.food_recommendations[k].food_name + ' <br/> <span class="leaf_image"><img src="/assets/images/ns_food_tag.png" /></span> &nbsp; <span  class="font12  color8 vlm">' + response.data.food_recommendations[k].calories + ' Cals - ' + response.data.food_recommendations[k].serving_unit + '</span><input type="hidden" class="consumable_id" value=' + response.data.food_recommendations[k].consumable_id + '/><input type="hidden" value=' + response.data.food_recommendations[k].consumable_type + ' class="consumable_type"/><img src="/assets/images/arrow_right_grey.png"  class="pull-right mt-10 multi_gray_arrow"/></span></li>');
              }

              $('.log_food_page .meal_txt:eq(' + k + ')').css('background', 'none');
              var b = document.getElementsByClassName("recent_food_options");
              var c = b[0].lastElementChild;
              var last_element = c.getElementsByClassName('leaf_image');
              for (var pp3 = 0; pp3 < response.data.food_recommendations[k].food_categories.length; pp3++) {
                if ($.inArray(response.data.food_recommendations[k].food_categories[pp3].name, window.food_bucket_array) >= 0) {
                  $('<img class="food_list_images_icons" src=' + food_images[response.data.food_recommendations[k].food_categories[pp3].display_name] + '>').insertAfter(last_element);
                }
              }
              if (response.data.food_recommendations[k].food_source !== "Nutrisystem" && response.data.food_recommendations[k].food_source !== "leaf") {
                $('.recent_food_options li:last').find('.leaf_image').remove();
                $('.recent_food_options').addClass('recent_food_rec');
              }
            } else if ($('[href="#favorite"]').parent().hasClass('active')) {

              $('ul.fav_food_options').append('<li class="meal_txt added_food"><span class="color6 item_click first_span cursor_pointer">' + response.data.food_recommendations[k].food_name + ' <br/> <span class="leaf_image"><img src="/assets/images/ns_food_tag.png" /></span> &nbsp; <span class="font12 color8 vlm">' + response.data.food_recommendations[k].calories + ' Cals - ' + response.data.food_recommendations[k].serving_unit + '</span><input type="hidden" class="consumable_id" value=' + response.data.food_recommendations[k].consumable_id + '/><input type="hidden" value=' + response.data.food_recommendations[k].consumable_type + ' class="consumable_type"/><img src="/assets/images/arrow_right_grey.png"  class="pull-right mt-10 multi_gray_arrow"/></span></li>');

              $('.log_food_page .meal_txt:eq(' + k + ')').css('background', 'none');
              var bb = document.getElementsByClassName("fav_food_options");
              var cc = bb[0].lastElementChild;
              var last_element1 = cc.getElementsByClassName('leaf_image');
              for (var pp4 = 0; pp4 < response.data.food_recommendations[k].food_categories.length; pp4++) {
                if ($.inArray(response.data.food_recommendations[k].food_categories[pp4].name, window.food_bucket_array) >= 0) {
                  $('<img class="food_list_images_icons" src=' + food_images[response.data.food_recommendations[k].food_categories[pp4].display_name] + '>').insertAfter(last_element1);
                }
              }
              if (response.data.food_recommendations[k].food_source !== "Nutrisystem" && response.data.food_recommendations[k].food_source !== "leaf") {
                $('.fav_food_options li:last').find('.leaf_image').remove();
                $('.fav_food_options').addClass('fav_food_rec');
              }
            }
            else if ($('[href="#All"]').parent().hasClass('active')) {

              $('ul.all_food_options').append('<li class="meal_txt added_food"><span class="color6 item_click first_span cursor_pointer">' + response.data.food_recommendations[k].food_name + ' <br/> <span class="leaf_image"><img src="/assets/images/ns_food_tag.png" /></span> &nbsp; <span class="font12 color8 vlm">' + response.data.food_recommendations[k].calories + ' Cals - ' + response.data.food_recommendations[k].serving_unit + '</span><input type="hidden" class="consumable_id" value=' + response.data.food_recommendations[k].consumable_id + '/><input type="hidden" value=' + response.data.food_recommendations[k].consumable_type + ' class="consumable_type"/><img src="/assets/images/arrow_right_grey.png"  class="pull-right mt-10 multi_gray_arrow"/></span></li>');

              $('.log_food_page .meal_txt:eq(' + k + ')').css('background', 'none');
              var all_foods = document.getElementsByClassName("all_food_options");
              var all_foods_last = all_foods[0].lastElementChild;
              var last_element2 = all_foods_last.getElementsByClassName('leaf_image');
              for (var pp5 = 0; pp5 < response.data.food_recommendations[k].food_categories.length; pp5++) {
                if ($.inArray(response.data.food_recommendations[k].food_categories[pp5].name, window.food_bucket_array) >= 0) {
                  $('<img class="food_list_images_icons" src=' + food_images[response.data.food_recommendations[k].food_categories[pp5].display_name] + '>').insertAfter(last_element2);
                }
              }
              if (response.data.food_recommendations[k].food_source !== "Nutrisystem" && response.data.food_recommendations[k].food_source !== "leaf") {
                $('.all_food_options li:last').find('.leaf_image').remove();
              }
            }
            else {
              if (response.data.food_recommendations[k].display_in_search?.length > 0) {
                for (var j = 0; j < response.data.food_recommendations[k].display_in_search?.length; j++) {
                  $('ul.food_options').append('<li class="meal_txt added_food"><span class="color6 item_click first_span cursor_pointer" id=' + 'serving_id-' + response.data.results.food_recommendations[k].display_in_search[j].serving_size_id + '>' + response.data.food_recommendations[k].food_name + ' <br/> <span class="leaf_image"><img src="/assets/images/ns_food_tag.png" /></span> &nbsp; <span id=' + 'serving_id-' +
                    response.data.results.food_recommendations[k].display_in_search[j].serving_size_id + ' class="font12 color8 vlm">' + response.data.food_recommendations[k].display_in_search[j].calories + ' Cals - ' + response.data.food_recommendations[k].display_in_search[j].serving_unit + '</span><input type="hidden" class="consumable_id" value=' + response.data.food_recommendations[k].consumable_id + '/><input type="hidden" value=' + response.data.food_recommendations[k].consumable_type + ' class="consumable_type"/><img src="/assets/images/arrow_right_grey.png"  id=' + 'serving_id-' +
                    response.data.results.food_recommendations[k].display_in_search[j].serving_size_id + ' class="pull-right mt-10 multi_gray_arrow"/></span></li>');
                }
                $('ul.food_options').append('<li class="meal_txt added_food"><span class="color6 item_click first_span cursor_pointer">' + response.data.food_recommendations[k].food_name + ' <br/> <span class="leaf_image"><img src="/assets/images/ns_food_tag.png" /></span> &nbsp; <span class="font12 color8 vlm">' + response.data.food_recommendations[k].calories + ' Cals - ' + response.data.food_recommendations[k].serving_unit + '</span><input type="hidden" class="consumable_id" value=' + response.data.food_recommendations[k].consumable_id + '/><input type="hidden" value=' + response.data.food_recommendations[k].consumable_type + ' class="consumable_type"/><img src="/assets/images/arrow_right_grey.png"  class="pull-right mt-10 multi_gray_arrow"/></span></li>');
              }
              else {
                $('ul.food_options').append('<li class="meal_txt added_food"><span class="color6 item_click first_span cursor_pointer">' + response.data.food_recommendations[k].food_name + ' <br/> <span class="leaf_image"><img src="/assets/images/ns_food_tag.png" /></span> &nbsp; <span class="font12 color8 vlm">' + response.data.food_recommendations[k].calories + ' Cals - ' + response.data.food_recommendations[k].serving_unit + '</span><input type="hidden" class="consumable_id" value=' + response.data.food_recommendations[k].consumable_id + '/><input type="hidden" value=' + response.data.food_recommendations[k].consumable_type + ' class="consumable_type"/><img src="/assets/images/arrow_right_grey.png"  class="pull-right mt-10 multi_gray_arrow"/></span></li>');
              }

              $('.log_food_page .meal_txt:eq(' + k + ')').css('background', 'none');
              var food_options = document.getElementsByClassName("food_options");
              var food_options_last = food_options[0]?.lastElementChild;
              var last_element3 = food_options_last.getElementsByClassName('leaf_image');
              for (var pp6 = 0; pp6 < response.data.food_recommendations[k].food_categories.length; pp6++) {
                if ($.inArray(response.data.food_recommendations[k].food_categories[pp6].name, window.food_bucket_array) >= 0) {
                  $('<img class="food_list_images_icons" src=' + food_images[response.data.food_recommendations[k].food_categories[pp6].display_name] + '>').insertAfter(last_element3);
                }
              }
              if (response.data.food_recommendations[k].food_source !== "Nutrisystem" && response.data.food_recommendations[k].food_source !== "leaf") {
                $('.food_options li:last').find('.leaf_image').remove();
              }

            }
          }

          $('.activity_empty,.serach_on_keyup').show()
          $('.multi_txt').hide();
          setTimeout(function () {
            $('.list-group,.recent_list_ul').hide();
          }, 900)


        } else {

          $('.multi_select .multi_txt').hide();
          $('.multi_select .search_empty').show();
        }
        if (window.localStorage.getItem("meal_occasion_name") === null || window.localStorage.getItem("meal_occasion_name") === undefined) {
          $(".serach_on_keyup").hide();
        }

        $('.food_recent_list_ul').empty();
        $(".activity_empty").show();
        $(".serach_on_keyup").show();
        $(".serach_on_click").hide();
        $('.show_more,.fav_empty').hide();
        if (!$('[href="#All"]').parent().hasClass('active')) {
          if (window.localStorage.getItem('searched_term') !== '' && window.localStorage.getItem('searched_term') !== null && window.localStorage.getItem('searched_term') !== undefined && window.localStorage.getItem('food_categories') !== '' && window.localStorage.getItem('food_categories') !== null && window.localStorage.getItem('food_categories') !== undefined) {
            if ($('[href="#recent"]').parent().hasClass('active')) {
              $(".food_not_found").html('<br/> We couldn’t find "' + window.localStorage.getItem('searched_term') + '" for ' + $('.favorite_section li.active a').text());
            } else {
              $(".food_not_found").html('<br/> We couldn’t find any results');
            }
            $(".search_all").show();
            $(".search_all_text").text('Search All for "' + window.localStorage.getItem('searched_term') + '"');
          }
          else if (window.localStorage.getItem('searched_term') !== '' && window.localStorage.getItem('searched_term') !== null && window.localStorage.getItem('searched_term') !== undefined) {
            if ($('[href="#recent"]').parent().hasClass('active')) {
              $(".food_not_found").html('<br/> We couldn’t find "' + window.localStorage.getItem('searched_term') + '" for ' + $('.favorite_section li.active a').text());
            } else if ($('[href="#favorite"]').parent().hasClass('active')) {
              $(".food_not_found").html('<br/> We couldn’t find "' + window.localStorage.getItem('searched_term') + '" for ' + $('.favorite_section li.active a').text());
            } else {
              $(".food_not_found").html('<br/> We couldn’t find "' + window.localStorage.getItem('searched_term') + '" for ' + $('.favorite_section li.active a').text());
              $(".search_all").show();
              $(".search_all_text").text('Search All for "' + window.localStorage.getItem('searched_term') + '"');
            }

          } else if (window.localStorage.getItem('food_categories') !== '' && window.localStorage.getItem('food_categories') !== null && window.localStorage.getItem('food_categories') !== undefined) {
            $(".food_not_found").html('<br/> We couldn’t find any results');
            $(".search_all").hide();
          } else {
            $(".food_not_found").html('<br/> We couldn’t find any results');
            $(".search_all").hide();
          }

        } else {
          if (window.localStorage.getItem('searched_term') === '' || window.localStorage.getItem('searched_term') === null || window.localStorage.getItem('searched_term') === undefined) {
            $(".food_not_found").html('<br/> We couldn’t find any results');
          } else {
            $(".food_not_found").html('<br/> We couldn’t find "' + window.localStorage.getItem('searched_term') + '" for ' + $('.favorite_section li.active a').text());
          }
          $(".search_all").hide();
        }

        Header.stopLoader();
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });
  }

  incrementfoodPageNo() {
    window.localStorage.setItem('page_number', parseInt(window.localStorage.getItem('page_number')) + 1)
    if (window.localStorage.getItem('searched_term') !== '' && window.localStorage.getItem('searched_term') !== null && window.localStorage.getItem('searched_term') !== undefined) {
      window.localStorage.setItem('not_fire_dropdown', false);
    }
    this.callLogfoodConsumablesAPI();
  }

  multiFoodTextclick() {
    $('.multiselect_txt').hide();
    $('.select_replace').css('cursor', '');
    $('.select_occasion').text('Select Occasion');
    $('.multi_gray_arrow').hide();
    $('.multi_select_dropdown,.checkbox_select,.multi_cancel').show();

    var consumable_data_food = JSON.parse(localStorage.getItem("food_category_checked"));
    for (var i = 0; i < consumable_data_food?.length; i++) {
      for (var m = 0; m < $('.food_options .meal_txt').length; m++)
        if (consumable_data_food[i].idunq === $('.food_options').find('.meal_txt:eq(' + m + ')').find('.consumable_id_unq').val()) {
          $('.checbox_normal:eq(' + m + ')').prop("checked", true);
        }
    }

  }

  FavSearchFilter() {

    Header.startLoader();
    var search_url = window.API_URL + "v1/favorite_foods/search";
    if (window.localStorage.getItem('recent_searched_term') !== '' && window.localStorage.getItem('recent_searched_term') !== undefined && window.localStorage.getItem('recent_searched_term') !== null) {
      window.localStorage.setItem('searched_term', window.localStorage.getItem('recent_searched_term'));
      localStorage.removeItem('recent_searched_term');
    }
    if (window.localStorage.getItem('searched_term') !== '' && window.localStorage.getItem('searched_term') !== undefined && window.localStorage.getItem('searched_term') !== null) {
      search_url = search_url + "?term=" + window.localStorage.getItem('searched_term');
    }
    if (window.localStorage.getItem('food_categories') !== '' && window.localStorage.getItem('food_categories') !== undefined && window.localStorage.getItem('food_categories') !== null) {
      if (window.localStorage.getItem('searched_term') !== '' && window.localStorage.getItem('searched_term') !== undefined && window.localStorage.getItem('searched_term') !== null) {
        search_url = search_url + "&food_categories=" + window.localStorage.getItem('food_categories');
      } else {
        search_url = search_url + "?food_categories=" + window.localStorage.getItem('food_categories');
      }
    }
    if (window.localStorage.getItem('food_type') !== '' && window.localStorage.getItem('food_type') !== undefined && window.localStorage.getItem('food_type') !== null) {
      if ((window.localStorage.getItem('food_categories') !== '' && window.localStorage.getItem('food_categories') !== undefined && window.localStorage.getItem('food_categories') !== null) || (window.localStorage.getItem('searched_term') !== '' && window.localStorage.getItem('searched_term') !== undefined && window.localStorage.getItem('searched_term') !== null)) {
        search_url = search_url + "&food_type=" + window.localStorage.getItem('food_type');
      } else {
        search_url = search_url + "?food_type=" + window.localStorage.getItem('food_type');
      }
    }
    if (window.localStorage.getItem('meal_occasion') !== '' && window.localStorage.getItem('meal_occasion') !== null && window.localStorage.getItem('meal_occasion') !== undefined) {
      if ((window.localStorage.getItem('meal_occasion') === "BREAKFAST" || window.localStorage.getItem('meal_occasion') === "LUNCH" || window.localStorage.getItem('meal_occasion') === "DINNER") && window.localStorage.getItem('food_categories') === "NS_ENTREE") {
        if (window.localStorage.getItem('food_categories') !== '' && window.localStorage.getItem('food_categories') !== undefined && window.localStorage.getItem('food_categories') !== null) {
          search_url = search_url + "&meal_occasion_name=" + window.localStorage.getItem('meal_occasion');
        } else {
          search_url = search_url + "?meal_occasion_name=" + window.localStorage.getItem('meal_occasion');
        }
      }
    }
    const options = {
      url: encodeURI(search_url),
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        $('.fav_food_options').removeClass('fav_food_rec');
        if (response && response.data && response.data.favorite_foods && response.data.favorite_foods.length > 0) {
          $('.show_more').hide();
          $('.list-group').hide();
          $('ul.fav_food_options').empty();
          $(".multi_txt,.multiselect_txt").show();
          $('.multi_log,.multi_cancel,.fav_empty').hide();
          for (var k = 0; k < response.data.favorite_foods.length; k++) {
            var nn = [];
            for (var aa = 0; aa < response.data.favorite_foods[k].food_categories.length; aa++) {
              var kk = response.data.favorite_foods[k].food_categories[aa].display_name.replace(/\s/g, '&nbsp;');
              var pp = { "id": response.data.favorite_foods[k].food_categories[aa].id, "name": response.data.favorite_foods[k].food_categories[aa].name, "display_name": kk, "value": response.data.favorite_foods[k].food_categories[aa].value };
              nn.push(pp)
            }


            var best_for_your_fav = (response.data.favorite_foods[k] && response.data.favorite_foods[k].best_for_you === true ? '<img src="/assets/images/best_for.png" style="margin-right:10px;"/>' : '');
            if ((response.data.favorite_foods[k].food_source !== "Nutrisystem" && response.data.favorite_foods[k].food_source !== "leaf")) {

              if (response.data.favorite_foods[k].display_in_search?.length > 0) {
                for (var j = 0; j < response.data.favorite_foods[k].display_in_search?.length; j++) {

                  $('ul.fav_food_options').append('<li class="fav_rec_meal_txt meal_txt cursor_pointer" id=' + 'serving_id-' + response.data.results.favorite_foods[k].display_in_search[j].serving_size_id + '><span class="color6 item_click fav_section first_span cursor_pointer">' + response.data.favorite_foods[k].food_name + '<br/>' + best_for_your_fav + ' <span class="leaf_image"></span><input type="hidden" class="consumable_id" value=' + response.data.favorite_foods[k].consumable_id + '/><img src="/assets/images/arrow_right_grey.png" id=' + 'serving_id-' + response.data.results.favorite_foods[k].display_in_search[j].serving_size_id + ' class="pull-right mt-10 multi_gray_arrow"/><input type="hidden" value=' + response.data.favorite_foods[k].consumable_type + ' class="consumable_type"/><input type="hidden" value=' + response.data.favorite_foods[k].food_name + ' class="favorite_food_name"/><input type="hidden" value=' + JSON.stringify(nn) + ' class="consumable_food_cats"/><span id=' + 'serving_id-' +
                    response.data.results.favorite_foods[k].display_in_search[j].serving_size_id + ' class="font12 color8 vlm"> ' + response.data.favorite_foods[k].display_in_search[j].calories + ' Cals - ' + response.data.favorite_foods[k].display_in_search[j].serving_unit + '</span></span><div class="checkbox_select pull-right" > <input class="checbox_normal" id="multi_check_fav' + k + '" name="food_item"  type="checkbox"/> <label class="multi_check_fav" for="multi_check_fav' + k + '"><span><i></i></span></label></div></li>');
                }
                $('ul.fav_food_options').append('<li class="fav_rec_meal_txt meal_txt cursor_pointer"><span class="color6 item_click fav_section first_span cursor_pointer">' + response.data.favorite_foods[k].food_name + '<br/>' + best_for_your_fav + ' <span class="leaf_image"></span><input type="hidden" class="consumable_id" value=' + response.data.favorite_foods[k].consumable_id + '/><img src="/assets/images/arrow_right_grey.png"   class="pull-right mt-10 multi_gray_arrow"/><input type="hidden" value=' + response.data.favorite_foods[k].consumable_type + ' class="consumable_type"/><input type="hidden" value=' + response.data.favorite_foods[k].food_name + ' class="favorite_food_name"/><input type="hidden" value=' + JSON.stringify(nn) + ' class="consumable_food_cats"/><span class="font12 color8 vlm"> ' + response.data.favorite_foods[k].calories + ' Cals - ' + response.data.favorite_foods[k].serving_unit + '</span></span><div class="checkbox_select pull-right" > <input class="checbox_normal" id="multi_check_fav' + k + '" name="food_item"  type="checkbox"/> <label class="multi_check_fav" for="multi_check_fav' + k + '"><span><i></i></span></label></div></li>');
              }
              else {

                $('ul.fav_food_options').append('<li class="fav_rec_meal_txt meal_txt cursor_pointer"><span class="color6 item_click fav_section first_span cursor_pointer">' + response.data.favorite_foods[k].food_name + '<br/>' + best_for_your_fav + ' <span class="leaf_image"></span><input type="hidden" class="consumable_id" value=' + response.data.favorite_foods[k].consumable_id + '/><img src="/assets/images/arrow_right_grey.png"   class="pull-right mt-10 multi_gray_arrow"/><input type="hidden" value=' + response.data.favorite_foods[k].consumable_type + ' class="consumable_type"/><input type="hidden" value=' + response.data.favorite_foods[k].food_name + ' class="favorite_food_name"/><input type="hidden" value=' + JSON.stringify(nn) + ' class="consumable_food_cats"/><span  class="font12 color8 vlm"> ' + response.data.favorite_foods[k].calories + ' Cals - ' + response.data.favorite_foods[k].serving_unit + '</span></span><div class="checkbox_select pull-right" > <input class="checbox_normal" id="multi_check_fav' + k + '" name="food_item"  type="checkbox"/> <label class="multi_check_fav" for="multi_check_fav' + k + '"><span><i></i></span></label></div></li>');
              }

            } else {

              if (response.data.favorite_foods[k].display_in_search?.length > 0) {
                for (var j = 0; j < response.data.favorite_foods[k].display_in_search?.length; j++) {

                  $('ul.fav_food_options').append('<li class="fav_rec_meal_txt meal_txt cursor_pointer" id=' + 'serving_id-' + response.data.results.favorite_foods[k].display_in_search[j].serving_size_id + '><span class="color6 item_click fav_section first_span cursor_pointer">' + response.data.favorite_foods[k].food_name + '<br/>' + best_for_your_fav + ' <span class="leaf_image"><img src="/assets/images/ns_food_tag.png"/></span><input type="hidden" class="consumable_id" value=' + response.data.favorite_foods[k].consumable_id + '/><img src="/assets/images/arrow_right_grey.png" id=' + 'serving_id-' + response.data.results.favorite_foods[k].display_in_search[j].serving_size_id + '  class="pull-right mt-10 multi_gray_arrow"/><input type="hidden" value=' + response.data.favorite_foods[k].consumable_type + ' class="consumable_type"/><input type="hidden" value=' + response.data.favorite_foods[k].food_name + ' class="favorite_food_name"/><input type="hidden" value=' + JSON.stringify(nn) + ' class="consumable_food_cats"/><span id=' + 'serving_id-' + response.data.results.favorite_foods[k].display_in_search[j].serving_size_id + ' class="font12 color8 vlm"> ' + response.data.favorite_foods[k].display_in_search[j].calories + ' Cals - ' + response.data.favorite_foods[k].display_in_search[j].serving_unit + '</span></span><div class="checkbox_select pull-right" > <input class="checbox_normal" id="multi_check_fav' + k + '" name="food_item"  type="checkbox"/> <label class="multi_check_fav" for="multi_check_fav' + k + '"><span><i></i></span></label></div></li>');
                }
                $('ul.fav_food_options').append('<li class="fav_rec_meal_txt meal_txt cursor_pointer"><span class="color6 item_click fav_section first_span cursor_pointer">' + response.data.favorite_foods[k].food_name + '<br/>' + best_for_your_fav + ' <span class="leaf_image"><img src="/assets/images/ns_food_tag.png"/></span><input type="hidden" class="consumable_id" value=' + response.data.favorite_foods[k].consumable_id + '/><img src="/assets/images/arrow_right_grey.png"  class="pull-right mt-10 multi_gray_arrow"/><input type="hidden" value=' + response.data.favorite_foods[k].consumable_type + ' class="consumable_type"/><input type="hidden" value=' + response.data.favorite_foods[k].food_name + ' class="favorite_food_name"/><input type="hidden" value=' + JSON.stringify(nn) + ' class="consumable_food_cats"/><span class="font12 color8 vlm" > ' + response.data.favorite_foods[k].calories + ' Cals - ' + response.data.favorite_foods[k].serving_unit + '</span></span><div class="checkbox_select pull-right" > <input class="checbox_normal" id="multi_check_fav' + k + '" name="food_item"  type="checkbox"/> <label class="multi_check_fav" for="multi_check_fav' + k + '"><span><i></i></span></label></div></li>');

              }
              else {
                $('ul.fav_food_options').append('<li class="fav_rec_meal_txt meal_txt cursor_pointer"><span class="color6 item_click fav_section first_span cursor_pointer">' + response.data.favorite_foods[k].food_name + '<br/>' + best_for_your_fav + ' <span class="leaf_image"><img src="/assets/images/ns_food_tag.png"/></span><input type="hidden" class="consumable_id" value=' + response.data.favorite_foods[k].consumable_id + '/><img src="/assets/images/arrow_right_grey.png"  class="pull-right mt-10 multi_gray_arrow"/><input type="hidden" value=' + response.data.favorite_foods[k].consumable_type + ' class="consumable_type"/><input type="hidden" value=' + response.data.favorite_foods[k].food_name + ' class="favorite_food_name"/><input type="hidden" value=' + JSON.stringify(nn) + ' class="consumable_food_cats"/><span class="font12 color8 vlm" > ' + response.data.favorite_foods[k].calories + ' Cals - ' + response.data.favorite_foods[k].serving_unit + '</span></span><div class="checkbox_select pull-right" > <input class="checbox_normal" id="multi_check_fav' + k + '" name="food_item"  type="checkbox"/> <label class="multi_check_fav" for="multi_check_fav' + k + '"><span><i></i></span></label></div></li>');
              }
            }
            for (var pp1 = response.data.favorite_foods[k].pills.length - 1; pp1 >= 0; pp1--) {
              $('<img width="25" height="13" class="food_list_images_icons" src=' + response.data.favorite_foods[k].pills[pp1].url + '>').insertAfter('#favorite .leaf_image:eq(' + k + ')');
            }
            $(".serach_on_keyup").hide();
          }
          Header.stopLoader();
        } else {

          $('ul.fav_food_options').empty();
          $(".serach_on_keyup").show();
          $("multi_txt_fav, .multi_log_fav, .multi_cancel_fav").hide();
          if (window.localStorage.getItem('searched_term') !== '' && window.localStorage.getItem('searched_term') !== null && window.localStorage.getItem('searched_term') !== undefined && window.localStorage.getItem('food_categories') !== '' && window.localStorage.getItem('food_categories') !== null && window.localStorage.getItem('food_categories') !== undefined) {
            $(".food_not_found").html('<br/> We couldn’t find any results');
            $(".search_all").show();
          }
          else if (window.localStorage.getItem('searched_term') !== '' && window.localStorage.getItem('searched_term') !== null && window.localStorage.getItem('searched_term') !== undefined) {
            $(".food_not_found").html('<br/> We couldn’t find "' + window.localStorage.getItem('searched_term') + '" for Favorites');
            $(".search_all").show();
            $(".search_all_text").text('Search All for "' + window.localStorage.getItem('searched_term') + '"');
          }
          else {
            $(".food_not_found").text("We couldn’t find any results");
            $(".search_all").hide();
          }

          if ($('.fav_empty').is(':visible')) {
            $(".serach_on_keyup,.multi_txt").hide();
          }
          if ((window.localStorage.getItem('searched_term') !== '' && window.localStorage.getItem('searched_term') !== null && window.localStorage.getItem('searched_term') !== undefined) || (window.localStorage.getItem('food_categories') !== '' && window.localStorage.getItem('food_categories') !== null && window.localStorage.getItem('food_categories') !== undefined) || (window.localStorage.getItem('food_type') !== '' && window.localStorage.getItem('food_type') !== null && window.localStorage.getItem('food_type') !== undefined)) {
            $('.fav_empty').hide();
            this.getRecommandation();
          } else {

            $('.fav_empty').show();
            $(".serach_on_keyup,.multi_txt").hide();
          }
          $(".multi_txt").hide();
          Header.stopLoader();
        }
        if (!$('[href="#recent"]').parent().hasClass('active')) {
          if (window.localStorage.getItem('food_categories') !== '' && window.localStorage.getItem('food_categories') !== undefined && window.localStorage.getItem('food_categories') !== null) {
            for (var gg = 0; gg < $('.meal_names').length; gg++) {
              if (window.localStorage.getItem('meal_occasion') !== '' && window.localStorage.getItem('meal_occasion') !== undefined && window.localStorage.getItem('meal_occasion') !== null && window.localStorage.getItem('food_categories') === "NS_ENTREE") {
                if ($('.food_type_drop').find('li.meal_names:eq(' + gg + ')').find('.food_categories').val() === window.localStorage.getItem('food_categories') && $('.food_type_drop').find('li.meal_names:eq(' + gg + ')').find('.meal_occasion').val() === window.localStorage.getItem('meal_occasion')) {
                  $('.food_type_drop').find('li.meal_names').find('.fa-check').addClass('hide');
                  $('.food_type_drop').find('li.meal_names').find('a').removeClass('blue_color');
                  $('.food_type_drop').find('li.meal_names:eq(' + gg + ')').find('.fa-check').removeClass('hide');
                  $('.food_type_drop').find('li.meal_names:eq(' + gg + ')').find('a').addClass('blue_color');
                  break;
                }
              } else {
                $('.food_type_drop li').find('.fa-check').addClass('hide');
                if ($('.food_type_drop').find('li.meal_names:eq(' + gg + ')').find('.food_categories').val() === window.localStorage.getItem('food_categories')) {
                  $('.food_type_drop').find('li.meal_names').find('.fa-check').addClass('hide');
                  $('.food_type_drop').find('li.meal_names').find('a').removeClass('blue_color');
                  $('.food_type_drop').find('li.meal_names:eq(' + gg + ')').find('.fa-check').removeClass('hide');
                  $('.food_type_drop').find('li.meal_names:eq(' + gg + ')').find('a').addClass('blue_color');
                  break;
                }
              }

            }
            $('.food_type_drop').find('li.not_set').find('a').removeClass('blue_color');
            $('.food_type_drop').find('li.not_set').find('span').removeClass('fa-check');
          }

          if (window.localStorage.getItem('food_type') !== '' && window.localStorage.getItem('food_type') !== undefined && window.localStorage.getItem('food_type') !== null && window.localStorage.getItem('food_categories') === null) {
            for (var tt = 0; tt < $('.meal_names').length; tt++) {
              if ($('.food_type_drop').find('li.meal_names:eq(' + tt + ')').find('.food_type').val() === window.localStorage.getItem('food_type')) {
                $('.food_type_drop').find('li.meal_names').find('.fa-check').addClass('hide');
                $('.food_type_drop').find('li.meal_names').find('a').removeClass('blue_color');
                $('.food_type_drop').find('li.meal_names:eq(' + tt + ')').find('.fa-check').removeClass('hide');
                $('.food_type_drop').find('li.meal_names:eq(' + tt + ')').find('a').addClass('blue_color');
              }
            }
            $('.food_type_drop').find('li.not_set').find('a').removeClass('blue_color');
            $('.food_type_drop').find('li.not_set').find('span').removeClass('fa-check');
          }
        }
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });
  }


  RecentSearchFilter() {

    this.getFoodCategoryIcons();
    Header.startLoader();
    var search_url = window.API_URL + "v1/diet_histories/recents/search"
    if (window.localStorage.getItem('recent_searched_term') !== '' && window.localStorage.getItem('recent_searched_term') !== undefined && window.localStorage.getItem('recent_searched_term') !== null) {
      window.localStorage.setItem('searched_term', window.localStorage.getItem('recent_searched_term'));
      localStorage.removeItem('recent_searched_term');
    }
    if (window.localStorage.getItem('searched_term') !== '' && window.localStorage.getItem('searched_term') !== undefined && window.localStorage.getItem('searched_term') !== null) {
      search_url = search_url + "?term=" + window.localStorage.getItem('searched_term');
    }
    const options = {
      url: encodeURI(search_url),
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        Header.stopLoader();
        setTimeout(function () {
          $('.show_more').hide();
          $('.list-group').hide();
        }, 900)
        $('.recent_food_options').removeClass('recent_food_rec');
        if (response.data && response.data.diet_histories && response.data.diet_histories.length > 0) {
          $('ul.recent_food_options').empty();
          setTimeout(function () {
            if (window.localStorage.getItem('searched_term') !== '' && window.localStorage.getItem('searched_term') !== null && window.localStorage.getItem('searched_term') !== undefined) {
              $(".food_search_input").val(window.localStorage.getItem('searched_term'));
            }
            $('#recent .show_more, #recent .multi_log, #recent .multi_cancel').hide();
            if (response.data.diet_histories.length > 1) {
              $("#recent .multi_txt, #recent .multiselect_txt").show();
            }
            else {
              $("#recent .multi_txt, #recent .multiselect_txt").hide();
            }

          }, 600)
          for (var k = 0; k < response.data.diet_histories.length; k++) {
            var nn = [];
            for (var aa = 0; aa < response.data.diet_histories[k].food_categories.length; aa++) {
              var kk = response.data.diet_histories[k].food_categories[aa].display_name.replace(/\s/g, '&nbsp;');
              var pp = { "id": response.data.diet_histories[k].food_categories[aa].id, "name": response.data.diet_histories[k].food_categories[aa].name, "display_name": kk, "value": response.data.diet_histories[k].food_categories[aa].value };
              nn.push(pp)
            }

            var best_for_your_recent = (response.data.diet_histories[k] && response.data.diet_histories[k].best_for_you === true ? '<img src="/assets/images/best_for.png" style="margin-right:10px;"/>' : '');

            if ((response.data.diet_histories[k].food_source !== "Nutrisystem" && response.data.diet_histories[k].food_source !== "leaf")) {

              $('ul.recent_food_options').append('<li class="fav_rec_meal_txt meal_txt cursor_pointer"><span class="rec_text color6 item_click first_span cursor_pointer">' + response.data.diet_histories[k].food_name + '<br/>' + best_for_your_recent + ' <span class="leaf_image"></span><input type="hidden" value=' + response.data.diet_histories[k].id + ' class="dh_id"/><input type="hidden" class="consumable_id" value=' + response.data.diet_histories[k].consumable_id + '/><img src="/assets/images/arrow_right_grey.png"  class="pull-right mt-10 multi_gray_arrow"/><input type="hidden" value=' + response.data.diet_histories[k].consumable_type + ' class="consumable_type"/><input type="hidden" value=' + response.data.diet_histories[k].food_name + ' class="favorite_food_name"/><input type="hidden" value=' + JSON.stringify(nn) + ' class="consumable_food_cats"/><span class="font12 color8 vlm"> ' + response.data.diet_histories[k].calories + ' Cals - ' + response.data.diet_histories[k].serving_unit + '</span></span><div class="checkbox_select pull-right" > <input class="checbox_normal" id="multi_check_recent' + k + '" name="food_item"  type="checkbox"/> <label class="multi_check_fav"  for="multi_check_recent' + k + '"><span><i></i></span></label></div></li>');
            } else {
              $('ul.recent_food_options').append('<li class="fav_rec_meal_txt meal_txt cursor_pointer"><span class="rec_text color6 item_click first_span cursor_pointer">' + response.data.diet_histories[k].food_name + '<br/>' + best_for_your_recent + ' <span class="leaf_image"><img src="/assets/images/ns_food_tag.png"/></span><input type="hidden" class="consumable_id" value=' + response.data.diet_histories[k].consumable_id + '/><input type="hidden" value=' + response.data.diet_histories[k].id + ' class="dh_id"/><img src="/assets/images/arrow_right_grey.png"  class="pull-right mt-10 multi_gray_arrow"/><input type="hidden" value=' + response.data.diet_histories[k].consumable_type + ' class="consumable_type"/><input type="hidden" value=' + response.data.diet_histories[k].food_name + ' class="favorite_food_name"/><input type="hidden" value=' + JSON.stringify(nn) + ' class="consumable_food_cats"/><span class="font12 color8 vlm"> ' + response.data.diet_histories[k].calories + ' Cals - ' + response.data.diet_histories[k].serving_unit + '</span></span><div class="checkbox_select pull-right" > <input class="checbox_normal" id="multi_check_recent' + k + '" name="food_item"  type="checkbox"/> <label class="multi_check_fav"  for="multi_check_recent' + k + '"><span><i></i></span></label></div></li>');
            }

            for (var pp1 = response.data.diet_histories[k].count_as.length - 1; pp1 >= 0; pp1--) {
              $('<img width="25" height="13" class="food_list_images_icons" src=' + window.food_list_images_icons_caps[response.data.diet_histories[k].count_as[pp1].category] + '>').insertAfter('#recent .leaf_image:eq(' + k + ')');
            }
            $(".serach_on_keyup").hide();

          }
          $('.food_type_drop').addClass('disabled_drop');
          $('.not_avail').show();
          $('.food_type_drop li').find('.fa-check').addClass('hide');
          $('.food_type_drop li:first a').addClass('blue_color');
          $('.food_type_drop').find('li.meal_names').find('a').removeClass('blue_color');

          $('.food_type_drop li:first').find('.fa-check').removeClass('hide');
          $('.food_type_drop').find('li.not_set').find('a').addClass('blue_color');
          $('.food_type_drop').find('li.not_set').find('span').removeClass('hide');
          $('.food_type_drop').find('li.not_set').find('span').addClass('fa-check');

          $('.show_more,.recent_empty').hide();
        }
        else {
          if ($(".food_search_input").val() !== "") {
            var ss = $(".food_search_input").val().replace(/>/g, "&#60;").replace(/</g, "&#62;").replace(/[/]/g, "&#47;");
            $(".food_search_input").val(ss.replace(/&#60;/g, "").replace(/&#62;/g, "").replace(/&#47;/g, ""));
          }

          $('ul.recent_food_options').empty();
          $(".serach_on_keyup").show();
          if (window.localStorage.getItem('searched_term') !== '' && window.localStorage.getItem('searched_term') !== null && window.localStorage.getItem('searched_term') !== undefined) {
            $(".food_not_found").html('<br/> We couldn’t find "' + window.localStorage.getItem('searched_term') + '" for Recent');
            $(".search_all").show();
            $(".search_all_text").text('Search All for "' + window.localStorage.getItem('searched_term') + '"');
            $(".recent_empty").hide();
            this.getRecommandation();
          } else {
            $(".food_not_found").html('<br/> We couldn’t find any results');
            $(".serach_on_keyup, #recent .multi_txt").hide();
            $('.recent_empty').show();
          }

          $("#recent .multi_txt", '#recent .show_more').hide();
          if ($('.recent_empty').is(':visible')) {
            $(".serach_on_keyup,#recent .multi_txt").hide();
          } else {
            $("#recent .multi_txt").show();
            $(".multi_log,.multi_cancel").hide();
          }

        }
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });
  }

  incermentPageNofood() {
    window.localStorage.setItem('page_number', parseInt(window.localStorage.getItem('page_number')) + 1)
    this.callLogfoodConsumablesAPI();
  }

  callLogFavAPI() {
    Header.startLoader();
    const options = {
      url: window.API_URL + "v1/favorite_foods?assigned_date=" + window.localStorage.getItem('assigned_date') + "&limit=100",
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        $('#favorite .show_more').hide();
        Header.stopLoader();

        if ($('.fav_empty').is(':visible')) {
          $(".serach_on_keyup,.multi_txt").hide();
        } else {
          $(".multi_txt").show();
          $('.multi_log,.multi_cancel').hide();
        }
        this.setState({ fav_list: response.data.favorite_foods });
        if (response.data.favorite_foods && response.data.favorite_foods.length > 0) {
          for (var kk11 = 0; kk11 < response.data.favorite_foods.length; kk11++) {
            for (var pp11 = response.data.favorite_foods[kk11].pills.length - 1; pp11 >= 0; pp11--) {


              $('<img width="25" height="13" class="food_list_images_icons" src=' + response.data.favorite_foods[kk11].pills[pp11].url + '>').insertAfter('#favorite .leaf_image:eq(' + kk11 + ')');

            }

          }
        }
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });
  }

  callLogfoodConsumablesAPI() {
    Header.startLoader();
    $(".activity_empty").hide();
    var assigned_date = moment(window.localStorage.getItem('assigned_date')).format('YYYY-MM-DD');
    if (window.localStorage.getItem('recent_searched_term') !== '' && window.localStorage.getItem('recent_searched_term') !== undefined && window.localStorage.getItem('recent_searched_term') !== null) {
      window.localStorage.setItem('searched_term', window.localStorage.getItem('recent_searched_term'));
      localStorage.removeItem('recent_searched_term');
    }
    var food_type = window.localStorage.getItem('food_type');
    var food_categories = window.localStorage.getItem('food_categories');
    var page_number = parseInt(window.localStorage.getItem('page_number'));
    if (window.localStorage.getItem('searched_term') !== null) {
      window.localStorage.setItem('searched_term', window.localStorage.getItem('searched_term').replace(/&#60;/g, "").replace(/&#62;/g, "").replace(/&#47;/g, ""))
    }

    var term = window.localStorage.getItem('searched_term');
    var meal_occasion = window.localStorage.getItem('meal_occasion');
    var search_url = window.API_URL + "v1/consumables?assigned_date=" + assigned_date + "&page=" + page_number;
    if (window.localStorage.getItem('search_from_journal') === "true" && window.localStorage.getItem('search_from_journal') !== null && window.localStorage.getItem('search_from_journal') !== undefined) {
      search_url = window.API_URL + "v1/consumables?assigned_date=" + assigned_date + "&page=" + page_number + "&term=" + window.localStorage.getItem('searched_term');
    }
    else if (window.localStorage.getItem('not_fire_dropdown') !== true && window.localStorage.getItem('not_fire_dropdown') !== null && window.localStorage.getItem('not_fire_dropdown') !== undefined && food_categories !== '' && food_type !== '') {
      if (window.localStorage.getItem('searched_term') !== '' && window.localStorage.getItem('searched_term') !== null && window.localStorage.getItem('searched_term') !== undefined) {
        search_url = window.API_URL + "v1/consumables?food_categories=[" + food_categories + "]&assigned_date=" + assigned_date + "&page=" + page_number + "&food_type=" + food_type + "&term=" + window.localStorage.getItem('searched_term');
      } else {
        search_url = window.API_URL + "v1/consumables?food_categories=[" + food_categories + "]&assigned_date=" + assigned_date + "&page=" + page_number + "&food_type=" + food_type
      }
      localStorage.removeItem('not_fire_dropdown');
    } else {
      if (food_type !== '' && food_type !== undefined && food_type !== null) {
        search_url = search_url + "&food_type=" + food_type;
      }
      if (food_categories !== '' && food_categories !== undefined && food_categories !== null) {
        search_url = search_url + "&food_categories=" + food_categories + "";
      }
      if (term !== '' && term !== undefined && term !== null) {
        search_url = search_url + "&term=" + term;
      }
      if (meal_occasion !== '' && meal_occasion !== undefined && meal_occasion !== null) {
        if ((meal_occasion === "BREAKFAST" || meal_occasion === "LUNCH" || meal_occasion === "DINNER") && food_categories === "NS_ENTREE") {
          search_url = search_url + "&meal_occasion_name=" + meal_occasion;
        }
      }
    }
    const options = {
      url: encodeURI(search_url),
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        $(".list-group").hide();
        if (response && response.data && response.data.results && response.data.results.consumables && response.data.results.consumables.length === 0) {

          $('.multi_select .search_empty').show();
          $('.food_options').empty();
          this.getRecommandation();
          if (!$('[href="#recent"]').parent().hasClass('active')) {
            if (window.localStorage.getItem('food_categories') !== '' && window.localStorage.getItem('food_categories') !== undefined && window.localStorage.getItem('food_categories') !== null) {
              for (var gg = 0; gg < $('.meal_names').length; gg++) {

                if (window.localStorage.getItem('meal_occasion') !== '' && window.localStorage.getItem('meal_occasion') !== undefined && window.localStorage.getItem('meal_occasion') !== null && window.localStorage.getItem('food_categories') === "NS_ENTREE") {
                  if ($('.food_type_drop').find('li.meal_names:eq(' + gg + ')').find('.food_categories').val() === window.localStorage.getItem('food_categories') && $('.food_type_drop').find('li.meal_names:eq(' + gg + ')').find('.meal_occasion').val() === window.localStorage.getItem('meal_occasion')) {
                    $('.food_type_drop').find('li.meal_names').find('.fa-check').addClass('hide');
                    $('.food_type_drop').find('li.meal_names').find('a').removeClass('blue_color');
                    $('.food_type_drop').find('li.meal_names:eq(' + gg + ')').find('.fa-check').removeClass('hide');
                    $('.food_type_drop').find('li.meal_names:eq(' + gg + ')').find('a').addClass('blue_color');
                    break;
                  }
                } else {
                  $('.food_type_drop li').find('.fa-check').addClass('hide');
                  if ($('.food_type_drop').find('li.meal_names:eq(' + gg + ')').find('.food_categories').val() === window.localStorage.getItem('food_categories')) {
                    $('.food_type_drop').find('li.meal_names').find('.fa-check').addClass('hide');
                    $('.food_type_drop').find('li.meal_names').find('a').removeClass('blue_color');
                    $('.food_type_drop').find('li.meal_names:eq(' + gg + ')').find('.fa-check').removeClass('hide');
                    $('.food_type_drop').find('li.meal_names:eq(' + gg + ')').find('a').addClass('blue_color');
                    break;
                  }
                }

              }
              $('.food_type_drop').find('li.not_set').find('a').removeClass('blue_color');
              $('.food_type_drop').find('li.not_set').find('span').removeClass('fa-check');
            }

            if (window.localStorage.getItem('food_type') !== '' && window.localStorage.getItem('food_type') !== undefined && window.localStorage.getItem('food_type') !== null && window.localStorage.getItem('food_categories') == null) {
              for (var tt = 0; tt < $('.meal_names').length; tt++) {
                if ($('.food_type_drop').find('li.meal_names:eq(' + tt + ')').find('.food_type').val() === window.localStorage.getItem('food_type')) {
                  $('.food_type_drop').find('li.meal_names').find('.fa-check').addClass('hide');
                  $('.food_type_drop').find('li.meal_names').find('a').removeClass('blue_color');
                  $('.food_type_drop').find('li.meal_names:eq(' + tt + ')').find('.fa-check').removeClass('hide');
                  $('.food_type_drop').find('li.meal_names:eq(' + tt + ')').find('a').addClass('blue_color');
                }
              }
              $('.food_type_drop').find('li.not_set').find('a').removeClass('blue_color');
              $('.food_type_drop').find('li.not_set').find('span').removeClass('fa-check');
            }
          }
        }
        else {
          if (response.data && response.data.results && response.data.results.consumables) {
            if ($('[href="#Nutrisystem"]').parent().hasClass('active')) {
              setTimeout(function () {
                if (window.localStorage.getItem('searched_term') !== '' && window.localStorage.getItem('searched_term') !== null && window.localStorage.getItem('searched_term') !== undefined) {
                  $(".food_search_input").val(window.localStorage.getItem('searched_term'));
                }
              }, 600)
              Header.startLoader();
              $(".serach_on_keyup").hide();
              if (page_number === 1) {
                $('.search_empty,.multi_log,.multi_cancel').hide();
                $('.food_options').empty();
                for (var kk4 = 0; kk4 < response.data.results.consumables.length; kk4++) {
                  var nn = [];
                  for (var aa = 0; aa < response.data.results.consumables[kk4].food_categories.length; aa++) {
                    var kk = response.data.results.consumables[kk4].food_categories[aa].display_name.replace(/\s/g, '&nbsp;');
                    var pp = { "id": response.data.results.consumables[kk4].food_categories[aa].id, "name": response.data.results.consumables[kk4].food_categories[aa].name, "display_name": kk, "value": response.data.results.consumables[kk4].food_categories[aa].value };
                    nn.push(pp)
                  }
                  if (response.data.results.consumables[kk4].display_in_search?.length > 0) {
                    for (var j = 0; j < response.data.results.consumables[kk4].display_in_search?.length; j++) {

                      var best_for_you = response.data.results.consumables[kk4] && response.data.results.consumables[kk4].best_for_you === true ? '<img src="/assets/images/best_for.png" style="margin-right:10px;"/>' : '';
                      $('ul.food_options').append('<li class="meal_txt added_food"><span class="color6 item_click first_span cursor_pointer"  id=' + 'serving_id-' + response.data.results.consumables[kk4].display_in_search[j].serving_size_id + '>' + response.data.results.consumables[kk4].food_name + ' <br/>' + best_for_you + ' <img src="/assets/images/ns_food_tag.png" class="leaf_image"/> <span id=' + 'serving_id-' + response.data.results.consumables[kk4].display_in_search[j].serving_size_id + ' class="font12 color8 vlm">' + response.data.results.consumables[kk4].display_in_search[j].calories + ' Cals - ' + response.data.results.consumables[kk4].display_in_search[j].serving_unit + '</span><input type="hidden" class="consumable_id" value="' + response.data.results.consumables[kk4].consumable_id + '"/><input type="hidden" class="consumable_id_unq" value="' + response.data.results.consumables[kk4].display_in_search[j].consumable_id + '"/><input type="hidden" value=' + response.data.results.consumables[kk4].food_type + ' class="food_type_name"/><input type="hidden" value=' + JSON.stringify(nn) + ' class="consumable_food_cats"/><input type="hidden" value=' + response.data.results.consumables[kk4].consumable_type + ' class="consumable_type"/><img src="/assets/images/arrow_right_grey.png" id=' + 'serving_id-' + response.data.results.consumables[kk4].display_in_search[j].serving_size_id + ' class="pull-right mt-10 multi_gray_arrow"/></span><div class="checkbox_select pull-right" > <input class="checbox_normal" id="multi_checkbox' + page_number + kk4 + '" name="food_item"  type="checkbox"/> <label for="multi_checkbox' + page_number + kk4 + '"><span><i></i></span></label>    </div></li>');
                      var b = document.getElementsByClassName("food_options");
                      if (b[0] !== null && b[0] !== undefined && b[0] !== '') {
                        var c = b[0].lastElementChild;
                        var last_element = c.getElementsByClassName('leaf_image');
                        for (var pp4 = response.data.results.consumables[kk4].pills.length - 1; pp4 >= 0; pp4--) {

                          if (response.data.results.consumables[kk4].pills[pp4].category != "OTHER") {
                            $('<img width="25" height="13" class="food_list_images_icons" src=' + response.data.results.consumables[kk4].pills[pp4].url + '>').insertAfter(last_element);
                          }
                        }
                        if (response.data.results.consumables[kk4].food_source !== "Nutrisystem" && response.data.results.consumables[kk4].food_source !== "leaf") {
                          $('.food_options li:last').find('.leaf_image').remove();
                        }
                      }
                    }
                    var best_for_you = response.data.results.consumables[kk4] && response.data.results.consumables[kk4].best_for_you === true ? '<img src="/assets/images/best_for.png" style="margin-right:10px;"/>' : '';
                    $('ul.food_options').append('<li class="meal_txt added_food"><span class="color6 item_click first_span cursor_pointer">' + response.data.results.consumables[kk4].food_name + ' <br/>' + best_for_you + ' <img src="/assets/images/ns_food_tag.png" class="leaf_image"/> <span class="font12 color8 vlm">' + response.data.results.consumables[kk4].calories + ' Cals - ' + response.data.results.consumables[kk4].serving_unit + '</span><input type="hidden" class="consumable_id" value="' + response.data.results.consumables[kk4].consumable_id + '"/><input type="hidden" class="consumable_id_unq" value="' + response.data.results.consumables[kk4].consumable_id + '"/><input type="hidden" value=' + response.data.results.consumables[kk4].food_type + ' class="food_type_name"/><input type="hidden" value=' + JSON.stringify(nn) + ' class="consumable_food_cats"/><input type="hidden" value=' + response.data.results.consumables[kk4].consumable_type + ' class="consumable_type"/><img src="/assets/images/arrow_right_grey.png" class="pull-right mt-10 multi_gray_arrow"/></span><div class="checkbox_select pull-right" > <input class="checbox_normal" id="multi_checkbox' + page_number + kk4 + response.data.results.consumables[kk4].consumable_id + '" name="food_item"  type="checkbox"/> <label for="multi_checkbox' + page_number + kk4 + response.data.results.consumables[kk4].consumable_id + '"><span><i></i></span></label>    </div></li>');
                    var b = document.getElementsByClassName("food_options");
                    if (b[0] !== null && b[0] !== undefined && b[0] !== '') {
                      var c = b[0].lastElementChild;
                      var last_element = c.getElementsByClassName('leaf_image');
                      for (var pp4 = response.data.results.consumables[kk4].pills.length - 1; pp4 >= 0; pp4--) {

                        if (response.data.results.consumables[kk4].pills[pp4].category != "OTHER") {
                          $('<img width="25" height="13" class="food_list_images_icons" src=' + response.data.results.consumables[kk4].pills[pp4].url + '>').insertAfter(last_element);
                        }
                      }
                      if (response.data.results.consumables[kk4].food_source !== "Nutrisystem" && response.data.results.consumables[kk4].food_source !== "leaf") {
                        $('.food_options li:last').find('.leaf_image').remove();
                      }
                    }
                  }
                  else {
                    var best_for_you = response.data.results.consumables[kk4] && response.data.results.consumables[kk4].best_for_you === true ? '<img src="/assets/images/best_for.png" style="margin-right:10px;"/>' : '';
                    $('ul.food_options').append('<li class="meal_txt added_food"><span class="color6 item_click first_span cursor_pointer">' + response.data.results.consumables[kk4].food_name + ' <br/>' + best_for_you + ' <img src="/assets/images/ns_food_tag.png" class="leaf_image"/> <span class="font12 color8 vlm">' + response.data.results.consumables[kk4].calories + ' Cals - ' + response.data.results.consumables[kk4].serving_unit + '</span><input type="hidden" class="consumable_id" value="' + response.data.results.consumables[kk4].consumable_id + '"/><input type="hidden" class="consumable_id_unq" value="' + response.data.results.consumables[kk4].consumable_id + '"/><input type="hidden" value=' + response.data.results.consumables[kk4].food_type + ' class="food_type_name"/><input type="hidden" value=' + JSON.stringify(nn) + ' class="consumable_food_cats"/><input type="hidden" value=' + response.data.results.consumables[kk4].consumable_type + ' class="consumable_type"/><img src="/assets/images/arrow_right_grey.png"  class="pull-right mt-10 multi_gray_arrow"/></span><div class="checkbox_select pull-right" > <input class="checbox_normal" id="multi_checkbox' + page_number + kk4 + '" name="food_item"  type="checkbox"/> <label for="multi_checkbox' + page_number + kk4 + '"><span><i></i></span></label>    </div></li>');
                    var b = document.getElementsByClassName("food_options");
                    if (b[0] !== null && b[0] !== undefined && b[0] !== '') {
                      var c = b[0].lastElementChild;
                      var last_element = c.getElementsByClassName('leaf_image');
                      for (var pp4 = response.data.results.consumables[kk4].pills.length - 1; pp4 >= 0; pp4--) {

                        if (response.data.results.consumables[kk4].pills[pp4].category != "OTHER") {
                          $('<img width="25" height="13" class="food_list_images_icons" src=' + response.data.results.consumables[kk4].pills[pp4].url + '>').insertAfter(last_element);
                        }
                      }
                      if (response.data.results.consumables[kk4].food_source !== "Nutrisystem" && response.data.results.consumables[kk4].food_source !== "leaf") {
                        $('.food_options li:last').find('.leaf_image').remove();
                      }
                    }
                  }
                }
                if (response.data.results.consumables.length >= 20) {
                  $('.multi_txt,.show_more,.multiselect_txt').show();
                  $('.multi_select .search_empty,.multi_cancel,.multi_log').hide();
                  if ((page_number * 20) === response.data.results.total_count) {
                    $('.show_more').hide();
                  }
                } else if (response.data.results.consumables.length < 20) {
                  $('.multi_txt,.multiselect_txt').show();
                  $('.multi_select .search_empty,.show_more,.multi_cancel,.multi_log').hide();
                }
              } else {
                setTimeout(function () {
                  if (response.data.results.consumables.length >= 20) {
                    if ((page_number * 20) === response.data.results.total_count) {
                      $('.show_more').hide();
                    } else {
                      $('.show_more').show();
                    }
                  } else if (response.data.results.consumables.length < 20) {
                    $('.show_more').hide();
                  }
                }, 400);
                $('.search_empty').hide();
                setTimeout(function () {
                  if (window.localStorage.getItem('searched_term') !== '' && window.localStorage.getItem('searched_term') !== null && window.localStorage.getItem('searched_term') !== undefined) {
                    $(".food_search_input").val(window.localStorage.getItem('searched_term'));
                  }
                }, 600)
                for (var k = 0; k < response.data.results.consumables.length; k++) {
                  var nn4 = [];
                  for (var aa2 = 0; aa2 < response.data.results.consumables[k].food_categories.length; aa2++) {
                    var kk2 = response.data.results.consumables[k].food_categories[aa2].display_name.replace(/\s/g, '&nbsp;');
                    var pp3 = { "id": response.data.results.consumables[k].food_categories[aa2].id, "name": response.data.results.consumables[k].food_categories[aa2].name, "display_name": kk2, "value": response.data.results.consumables[k].food_categories[aa2].value };
                    nn4.push(pp3)
                  }
                  var best_for_you_page = response.data.results.consumables[k] && response.data.results.consumables[k].best_for_you === true ? '<img src="/assets/images/best_for.png" style="margin-right:10px;"/>' : '';
                  if (response.data.results.consumables[k].display_in_search?.length > 0) {
                    for (var j = 0; j < response.data.results.consumables[k].display_in_search?.length; j++) {

                      $('ul.food_options').append('<li class="meal_txt added_food"><span class="color6 item_click first_span cursor_pointer" id=' + 'serving_id-' +
                        response.data.results.consumables[k].display_in_search[j].serving_size_id + '>' + response.data.results.consumables[k].food_name + ' <br/>' + best_for_you_page + ' <img src="/assets/images/ns_food_tag.png" class="leaf_image"/> <span  id=' + 'serving_id-' +
                        response.data.results.consumables[k].display_in_search[j].serving_size_id + ' class="font12 color8 vlm">' + response.data.results.consumables[k].display_in_search[j].calories + ' Cals - ' + response.data.results.consumables[k].display_in_search[j].serving_unit + '</span><input type="hidden" class="consumable_id" value="' + response.data.results.consumables[k].consumable_id + '"/><input type="hidden" class="consumable_id_unq" value="' + response.data.results.consumables[k].display_in_search[j].consumable_id + '"/><input type="hidden" value=' + response.data.results.consumables[k].food_type + ' class="food_type_name"/><input type="hidden" value=' + response.data.results.consumables[k].consumable_type + ' class="consumable_type"/><input type="hidden" value=' + JSON.stringify(nn4) + ' class="consumable_food_cats"/><img src="/assets/images/arrow_right_grey.png" id=' + 'serving_id-' +
                        response.data.results.consumables[k].display_in_search[j].serving_size_id + '  class="pull-right mt-10 multi_gray_arrow"/></span><div class="checkbox_select pull-right" > <input class="checbox_normal" id="multi_check_food' + page_number + k + '" name="food_item"  type="checkbox"/> <label for="multi_check_food' + page_number + k + '"><span><i></i></span></label>    </div></li>');
                    }
                    $('ul.food_options').append('<li class="meal_txt added_food"><span class="color6 item_click first_span cursor_pointer">' + response.data.results.consumables[k].food_name + ' <br/>' + best_for_you_page + ' <img src="/assets/images/ns_food_tag.png" class="leaf_image"/> <span class="font12 color8 vlm">' + response.data.results.consumables[k].calories + ' Cals - ' + response.data.results.consumables[k].serving_unit + '</span><input type="hidden" class="consumable_id" value="' + response.data.results.consumables[k].consumable_id + '"/><input type="hidden" class="consumable_id_unq" value="' + response.data.results.consumables[k].consumable_id + '"/><input type="hidden" value=' + response.data.results.consumables[k].food_type + ' class="food_type_name"/><input type="hidden" value=' + response.data.results.consumables[k].consumable_type + ' class="consumable_type"/><input type="hidden" value=' + JSON.stringify(nn4) + ' class="consumable_food_cats"/><img src="/assets/images/arrow_right_grey.png"  class="pull-right mt-10 multi_gray_arrow"/></span><div class="checkbox_select pull-right" > <input class="checbox_normal" id="multi_check_food' + page_number + k + response.data.results.consumables[k].consumable_id + '" name="food_item"  type="checkbox"/> <label for="multi_check_food' + page_number + k + response.data.results.consumables[k].consumable_id + '"><span><i></i></span></label>    </div></li>');
                  }
                  else {
                    $('ul.food_options').append('<li class="meal_txt added_food"><span class="color6 item_click first_span cursor_pointer">' + response.data.results.consumables[k].food_name + ' <br/>' + best_for_you_page + ' <img src="/assets/images/ns_food_tag.png" class="leaf_image"/> <span class="font12 color8 vlm">' + response.data.results.consumables[k].calories + ' Cals - ' + response.data.results.consumables[k].serving_unit + '</span><input type="hidden" class="consumable_id" value="' + response.data.results.consumables[k].consumable_id + '"/><input type="hidden" class="consumable_id_unq" value="' + response.data.results.consumables[k].consumable_id + '"/><input type="hidden" value=' + response.data.results.consumables[k].food_type + ' class="food_type_name"/><input type="hidden" value=' + response.data.results.consumables[k].consumable_type + ' class="consumable_type"/><input type="hidden" value=' + JSON.stringify(nn4) + ' class="consumable_food_cats"/><img src="/assets/images/arrow_right_grey.png" class="pull-right mt-10 multi_gray_arrow"/></span><div class="checkbox_select pull-right" > <input class="checbox_normal" id="multi_check_food' + page_number + k + '" name="food_item"  type="checkbox"/> <label for="multi_check_food' + page_number + k + '"><span><i></i></span></label>    </div></li>');
                  }
                  var bb2 = document.getElementsByClassName("food_options");
                  if (bb2[0] !== null && bb2[0] !== undefined && bb2[0] !== '') {
                    var cc2 = bb2[0].lastElementChild;
                    var last_element2 = cc2.getElementsByClassName('leaf_image');
                    for (var pp5 = 0; pp5 < response.data.results.consumables[k].pills.length; pp5++) {
                      if (response.data.results.consumables[k].pills[pp5].category != "OTHER") {
                        $('<img class="food_list_images_icons" width="25" height="13" src=' + response.data.results.consumables[k].pills[pp5].url + '>').insertAfter(last_element2);
                      }

                    }
                    if (response.data.results.consumables[k].food_source !== "Nutrisystem" && response.data.results.consumables[k].food_source !== "leaf") {
                      $('.food_options li:last').find('.leaf_image').remove();
                    }
                  }

                }
                if (!$('.multiselect_txt').is(':visible')) {
                  $('.multi_gray_arrow').hide();
                  $('.multi_select_dropdown,.checkbox_select,.multi_cancel').show();
                }
                else {
                  $('.multi_gray_arrow,.multiselect_txt').show();
                  $('.multi_select_dropdown,.checkbox_select').hide();
                }
              }
            }
            else {
              //code for all foods tab start//
              setTimeout(function () {
                if (window.localStorage.getItem('searched_term') !== '' && window.localStorage.getItem('searched_term') !== null && window.localStorage.getItem('searched_term') !== undefined) {
                  $(".food_search_input").val(window.localStorage.getItem('searched_term'));
                }
              }, 600)
              Header.startLoader();
              $(".serach_on_keyup").hide();
              if (page_number === 1) {
                $('.search_empty,.multi_log,.multi_cancel').hide();
                $('.all_food_options').empty();
                for (var k4 = 0; k4 < response.data.results.consumables.length; k4++) {
                  var nn5 = [];
                  for (var aa3 = 0; aa3 < response.data.results.consumables[k4].food_categories.length; aa3++) {
                    var kk3 = response.data.results.consumables[k4].food_categories[aa3].display_name.replace(/\s/g, '&nbsp;');
                    var p4 = { "id": response.data.results.consumables[k4].food_categories[aa3].id, "name": response.data.results.consumables[k4].food_categories[aa3].name, "display_name": kk3, "value": response.data.results.consumables[k4].food_categories[aa3].value };
                    nn5.push(p4)
                  }
                  var best_for_you_all = response.data.results.consumables[k4] && response.data.results.consumables[k4].best_for_you === true ? '<img src="/assets/images/best_for.png" style="margin-right:10px;"/>' : '';

                  if (response.data.results.consumables[k4].display_in_search?.length > 0) {
                    for (var j = 0; j < response.data.results.consumables[k4].display_in_search?.length; j++) {

                      $('ul.all_food_options').append('<li class="meal_txt added_food"><span class="color6 item_click first_span cursor_pointer" id=' + 'serving_id-' + response.data.results.consumables[k4].display_in_search[j].serving_size_id + '>' + response.data.results.consumables[k4].food_name + ' <br/>' + best_for_you_all + ' <img src="/assets/images/ns_food_tag.png" class="leaf_image"/> <span id=' + 'serving_id-' + response.data.results.consumables[k4].display_in_search[j].serving_size_id + ' class="font12 color8 vlm">' + response.data.results.consumables[k4].display_in_search[j].calories + ' Cals - ' + response.data.results.consumables[k4].display_in_search[j].serving_unit + '</span><input type="hidden" class="consumable_id" value="' + response.data.results.consumables[k4].consumable_id + '"/><input type="hidden" class="consumable_id_unq" value="' + response.data.results.consumables[k4].display_in_search[j].consumable_id + '"/><input type="hidden" value=' + response.data.results.consumables[k4].food_type + ' class="food_type_name"/><input type="hidden" value=' + JSON.stringify(nn5) + ' class="consumable_food_cats"/><input type="hidden" value=' + response.data.results.consumables[k4].consumable_type + ' class="consumable_type"/><img src="/assets/images/arrow_right_grey.png"  id=' + 'serving_id-' + response.data.results.consumables[k4].display_in_search[j].serving_size_id + ' class="pull-right mt-10 multi_gray_arrow"/></span><div class="checkbox_select pull-right" > <input class="checbox_normal" id="multi_check' + page_number + k4 + '" name="food_item"  type="checkbox"/> <label for="multi_check' + page_number + k4 + '"><span><i></i></span></label>    </div></li>');
                    }
                    $('ul.all_food_options').append('<li class="meal_txt added_food"><span class="color6 item_click first_span cursor_pointer">' + response.data.results.consumables[k4].food_name + ' <br/>' + best_for_you_all + ' <img src="/assets/images/ns_food_tag.png" class="leaf_image"/> <span class="font12 color8 vlm">' + response.data.results.consumables[k4].calories + ' Cals - ' + response.data.results.consumables[k4].serving_unit + '</span><input type="hidden" class="consumable_id" value="' + response.data.results.consumables[k4].consumable_id + '"/><input type="hidden" class="consumable_id_unq" value="' + response.data.results.consumables[k4].consumable_id + '"/><input type="hidden" value=' + response.data.results.consumables[k4].food_type + ' class="food_type_name"/><input type="hidden" value=' + JSON.stringify(nn5) + ' class="consumable_food_cats"/><input type="hidden" value=' + response.data.results.consumables[k4].consumable_type + ' class="consumable_type"/><img src="/assets/images/arrow_right_grey.png"  class="pull-right mt-10 multi_gray_arrow"/></span><div class="checkbox_select pull-right" > <input class="checbox_normal" id="multi_check' + page_number + k4 + response.data.results.consumables[k4].consumable_id + '" name="food_item"  type="checkbox"/> <label for="multi_check' + page_number + k4 + response.data.results.consumables[k4].consumable_id + '"><span><i></i></span></label>    </div></li>');
                  }
                  else {
                    $('ul.all_food_options').append('<li class="meal_txt added_food"><span class="color6 item_click first_span cursor_pointer">' + response.data.results.consumables[k4].food_name + ' <br/>' + best_for_you_all + ' <img src="/assets/images/ns_food_tag.png" class="leaf_image"/> <span class="font12 color8 vlm">' + response.data.results.consumables[k4].calories + ' Cals - ' + response.data.results.consumables[k4].serving_unit + '</span><input type="hidden" class="consumable_id" value="' + response.data.results.consumables[k4].consumable_id + '"/><input type="hidden" class="consumable_id_unq" value="' + response.data.results.consumables[k4].consumable_id + '"/><input type="hidden" value=' + response.data.results.consumables[k4].food_type + ' class="food_type_name"/><input type="hidden" value=' + JSON.stringify(nn5) + ' class="consumable_food_cats"/><input type="hidden" value=' + response.data.results.consumables[k4].consumable_type + ' class="consumable_type"/><img src="/assets/images/arrow_right_grey.png"  class="pull-right mt-10 multi_gray_arrow"/></span><div class="checkbox_select pull-right" > <input class="checbox_normal" id="multi_check' + page_number + k4 + '" name="food_item"  type="checkbox"/> <label for="multi_check' + page_number + k4 + '"><span><i></i></span></label>    </div></li>');
                  }
                  var bb3 = document.getElementsByClassName("all_food_options");
                  if (bb3[0] !== null && bb3[0] !== undefined && bb3[0] !== '') {
                    var cc3 = bb3[0].lastElementChild;
                    var last_element3 = cc3.getElementsByClassName('leaf_image');
                    for (var pp6 = response.data.results.consumables[k4].pills.length - 1; pp6 >= 0; pp6--) {
                      if (response.data.results.consumables[k4].pills[pp6].category != "OTHER") {
                        $('<img width="25" height="13" class="food_list_images_icons" src=' + response.data.results.consumables[k4].pills[pp6].url + '>').insertAfter(last_element3);
                      }
                    }
                    if (response.data.results.consumables[k4].food_source !== "Nutrisystem" && response.data.results.consumables[k4].food_source !== "leaf") {
                      $('.all_food_options li:last').find('.leaf_image').remove();
                    }
                  }
                }
                if (response.data.results.consumables.length >= 20) {
                  $('.multi_txt,.show_more,.multiselect_txt').show();
                  $('.multi_select .search_empty,.multi_cancel,.multi_log').hide();
                  if ((page_number * 20) === response.data.results.total_count) {
                    $('.show_more').hide();
                  }
                } else if (response.data.results.consumables.length < 20) {
                  $('.multi_txt,.multiselect_txt').show();
                  $('.multi_select .search_empty,.show_more,.multi_cancel,.multi_log').hide();
                }
              } else {
                setTimeout(function () {
                  if (response.data.results.consumables.length >= 20) {
                    if ((page_number * 20) === response.data.results.total_count) {
                      $('.show_more').hide();
                    } else {
                      $('.show_more').show();
                    }
                  } else if (response.data.results.consumables.length < 20) {
                    $('.show_more').hide();
                  }
                }, 400);
                $('.search_empty').hide();
                setTimeout(function () {
                  if (window.localStorage.getItem('searched_term') !== '' && window.localStorage.getItem('searched_term') !== null && window.localStorage.getItem('searched_term') !== undefined) {
                    $(".food_search_input").val(window.localStorage.getItem('searched_term'));
                  }
                }, 600)
                for (var k6 = 0; k6 < response.data.results.consumables.length; k6++) {
                  var nn6 = [];
                  for (var aa4 = 0; aa4 < response.data.results.consumables[k6].food_categories.length; aa4++) {
                    var jj1 = response.data.results.consumables[k6].food_categories[aa4].display_name.replace(/\s/g, '&nbsp;');
                    var p5 = { "id": response.data.results.consumables[k6].food_categories[aa4].id, "name": response.data.results.consumables[k6].food_categories[aa4].name, "display_name": jj1, "value": response.data.results.consumables[k6].food_categories[aa4].value };
                    nn6.push(p5)
                  }
                  if (response.data.results.consumables[k6].display_in_search?.length > 0) {
                    for (var j = 0; j < response.data.results.consumables[k6].display_in_search?.length; j++) {

                      var best_for_you_all_page = response.data.results.consumables[k6] && response.data.results.consumables[k6].best_for_you === true ? '<img src="/assets/images/best_for.png" style="margin-right:10px;"/>' : '';
                      $('ul.all_food_options').append('<li class="meal_txt added_food"><span class="color6 item_click first_span cursor_pointer"  id=' + 'serving_id-' + response.data.results.consumables[k6].display_in_search[j].serving_size_id + '>' + response.data.results.consumables[k6].food_name + ' <br/>' + best_for_you_all_page + ' <img src="/assets/images/ns_food_tag.png" class="leaf_image"/> <span id=' + 'serving_id-' + response.data.results.consumables[k6].display_in_search[j].serving_size_id + ' class="font12 color8 vlm">' + response.data.results.consumables[k6].display_in_search[j].calories + ' Cals - ' + response.data.results.consumables[k6].display_in_search[j].serving_unit + '</span><input type="hidden" class="consumable_id" value="' + response.data.results.consumables[k6].consumable_id + '"/><input type="hidden" class="consumable_id_unq" value="' + response.data.results.consumables[k6].display_in_search[j].consumable_id + '"/><input type="hidden" value=' + response.data.results.consumables[k6].food_type + ' class="food_type_name"/><input type="hidden" value=' + response.data.results.consumables[k6].consumable_type + ' class="consumable_type"/><input type="hidden" value=' + JSON.stringify(nn6) + ' class="consumable_food_cats"/><img src="/assets/images/arrow_right_grey.png" id=' + 'serving_id-' + response.data.results.consumables[k6].display_in_search[j].serving_size_id + ' class="pull-right mt-10 multi_gray_arrow"/></span><div class="checkbox_select pull-right" > <input class="checbox_normal" id="multi' + page_number + k6 + '" name="food_item"  type="checkbox"/> <label for="multi' + page_number + k6 + '"><span><i></i></span></label>    </div></li>');
                    }

                    var best_for_you_all_page = response.data.results.consumables[k6] && response.data.results.consumables[k6].best_for_you === true ? '<img src="/assets/images/best_for.png" style="margin-right:10px;"/>' : '';
                    $('ul.all_food_options').append('<li class="meal_txt added_food"><span class="color6 item_click first_span cursor_pointer">' + response.data.results.consumables[k6].food_name + ' <br/>' + best_for_you_all_page + ' <img src="/assets/images/ns_food_tag.png" class="leaf_image"/> <span class="font12 color8 vlm">' + response.data.results.consumables[k6].calories + ' Cals - ' + response.data.results.consumables[k6].serving_unit + '</span><input type="hidden" class="consumable_id" value="' + response.data.results.consumables[k6].consumable_id + '"/><input type="hidden" class="consumable_id_unq" value="' + response.data.results.consumables[k6].consumable_id + '"/><input type="hidden" value=' + response.data.results.consumables[k6].food_type + ' class="food_type_name"/><input type="hidden" value=' + response.data.results.consumables[k6].consumable_type + ' class="consumable_type"/><input type="hidden" value=' + JSON.stringify(nn6) + ' class="consumable_food_cats"/><img src="/assets/images/arrow_right_grey.png"  class="pull-right mt-10 multi_gray_arrow"/></span><div class="checkbox_select pull-right" > <input class="checbox_normal" id="multi' + page_number + k6 + response.data.results.consumables[k6].consumable_id + '" name="food_item"  type="checkbox"/> <label for="multi' + page_number + k6 + response.data.results.consumables[k6].consumable_id + '"><span><i></i></span></label>    </div></li>');
                  }
                  else {
                    var best_for_you_all_page = response.data.results.consumables[k6] && response.data.results.consumables[k6].best_for_you === true ? '<img src="/assets/images/best_for.png" style="margin-right:10px;"/>' : '';
                    $('ul.all_food_options').append('<li class="meal_txt added_food"><span class="color6 item_click first_span cursor_pointer">' + response.data.results.consumables[k6].food_name + ' <br/>' + best_for_you_all_page + ' <img src="/assets/images/ns_food_tag.png" class="leaf_image"/> <span class="font12 color8 vlm">' + response.data.results.consumables[k6].calories + ' Cals - ' + response.data.results.consumables[k6].serving_unit + '</span><input type="hidden" class="consumable_id" value="' + response.data.results.consumables[k6].consumable_id + '"/><input type="hidden" class="consumable_id_unq" value="' + response.data.results.consumables[k6].consumable_id + '"/><input type="hidden" value=' + response.data.results.consumables[k6].food_type + ' class="food_type_name"/><input type="hidden" value=' + response.data.results.consumables[k6].consumable_type + ' class="consumable_type"/><input type="hidden" value=' + JSON.stringify(nn6) + ' class="consumable_food_cats"/><img src="/assets/images/arrow_right_grey.png"  class="pull-right mt-10 multi_gray_arrow"/></span><div class="checkbox_select pull-right" > <input class="checbox_normal" id="multi' + page_number + k6 + '" name="food_item"  type="checkbox"/> <label for="multi' + page_number + k6 + '"><span><i></i></span></label>    </div></li>');
                  }
                  var bb4 = document.getElementsByClassName("all_food_options");
                  if (bb4[0] !== null && bb4[0] !== undefined && bb4[0] !== '') {
                    var cc4 = bb4[0].lastElementChild;
                    var last_element4 = cc4.getElementsByClassName('leaf_image');
                    for (var pp7 = 0; pp7 < response.data.results.consumables[k6].pills.length; pp7++) {
                      $('<img class="food_list_images_icons" width="25" height="13" src=' + response.data.results.consumables[k6].pills[pp7].url + '>').insertAfter(last_element4);
                    }
                    if (response.data.results.consumables[k6].food_source !== "Nutrisystem" && response.data.results.consumables[k6].food_source !== "leaf") {
                      $('.all_food_options li:last').find('.leaf_image').remove();
                    }
                  }
                }
                if (!$('.multiselect_txt').is(':visible')) {
                  $('.multi_gray_arrow').hide();
                  $('.multi_select_dropdown,.checkbox_select,.multi_cancel').show();
                }
                else {
                  $('.multi_gray_arrow,.multiselect_txt').show();
                  $('.multi_select_dropdown,.checkbox_select').hide();
                }
              }
              //code for all foods tab end//
            }
            if (!$('[href="#recent"]').parent().hasClass('active')) {
              if (window.localStorage.getItem('food_categories') !== '' && window.localStorage.getItem('food_categories') !== undefined && window.localStorage.getItem('food_categories') !== null) {
                for (var gg2 = 0; gg2 < $('.meal_names').length; gg2++) {
                  if (window.localStorage.getItem('meal_occasion') !== '' && window.localStorage.getItem('meal_occasion') !== undefined && window.localStorage.getItem('meal_occasion') !== null && window.localStorage.getItem('food_categories') === "NS_ENTREE") {
                    if ($('.food_type_drop').find('li.meal_names:eq(' + gg2 + ')').find('.food_categories').val() === window.localStorage.getItem('food_categories') && $('.food_type_drop').find('li.meal_names:eq(' + gg2 + ')').find('.meal_occasion').val() === window.localStorage.getItem('meal_occasion')) {
                      $('.food_type_drop').find('li.meal_names').find('.fa-check').addClass('hide');
                      $('.food_type_drop').find('li.meal_names').find('a').removeClass('blue_color');
                      $('.food_type_drop').find('li.meal_names:eq(' + gg2 + ')').find('.fa-check').removeClass('hide');
                      $('.food_type_drop').find('li.meal_names:eq(' + gg2 + ')').find('a').addClass('blue_color');
                      break;
                    }
                  } else {
                    $('.food_type_drop li').find('.fa-check').addClass('hide');
                    if ($('.food_type_drop').find('li.meal_names:eq(' + gg2 + ')').find('.food_categories').val() === window.localStorage.getItem('food_categories')) {
                      $('.food_type_drop').find('li.meal_names').find('.fa-check').addClass('hide');
                      $('.food_type_drop').find('li.meal_names').find('a').removeClass('blue_color');
                      $('.food_type_drop').find('li.meal_names:eq(' + gg2 + ')').find('.fa-check').removeClass('hide');
                      $('.food_type_drop').find('li.meal_names:eq(' + gg2 + ')').find('a').addClass('blue_color');
                      break;
                    }
                  }

                }
                $('.food_type_drop').find('li.not_set').find('a').removeClass('blue_color');
                $('.food_type_drop').find('li.not_set').find('span').removeClass('fa-check');
              }

              if (window.localStorage.getItem('food_type') !== '' && window.localStorage.getItem('food_type') !== undefined && window.localStorage.getItem('food_type') !== null && window.localStorage.getItem('food_categories') === null) {
                for (var gg1 = 0; gg1 < $('.meal_names').length; gg1++) {
                  if ($('.food_type_drop').find('li.meal_names:eq(' + gg1 + ')').find('.food_type').val() === window.localStorage.getItem('food_type')) {
                    $('.food_type_drop').find('li.meal_names').find('.fa-check').addClass('hide');
                    $('.food_type_drop').find('li.meal_names').find('a').removeClass('blue_color');
                    $('.food_type_drop').find('li.meal_names:eq(' + gg1 + ')').find('.fa-check').removeClass('hide');
                    $('.food_type_drop').find('li.meal_names:eq(' + gg1 + ')').find('a').addClass('blue_color');
                  }
                }
                $('.food_type_drop').find('li.not_set').find('a').removeClass('blue_color');
                $('.food_type_drop').find('li.not_set').find('span').removeClass('fa-check');
              }
            }
            if (localStorage.getItem("food_category_checked") != null) {
              this.multiFoodTextclick();
              if ($(".checbox_normal:checkbox:checked").length < 1) {
                $('.select_meal_drop').addClass('disabled_drop');
                $('.multi_log').hide();
              } else {
                $('.multi_log').show();
                $('.select_meal_drop').removeClass('disabled_drop');

              }
            }
            Header.stopLoader();
          }
        }
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });
  }

  componentDidMount() {
    this.callLogfoodConsumablesAPI();
    window.meal_occasions_list = '';
    window.rec_food_name = '';
    this.getMealOccasions();

    if (window.localStorage.getItem('from_plus') === 'true' || window.localStorage.getItem('from_createfood') === 'true') {
      $('.food_options .select_dropdown_foodtype .food_type_drop').show();
      localStorage.removeItem('from_plus');
      localStorage.removeItem('from_createfood');
    }
    localStorage.removeItem('new_assigned_date');
    localStorage.removeItem('journal_search');
    localStorage.removeItem('back_to_recipe');
    localStorage.removeItem('custom_food_consumable_id');
    localStorage.removeItem('custom_fav_food_consumable_id');
    localStorage.removeItem('custom_fav_food_consumable_type');

    $('.food-multiple-select-ks').text(window.keystrings['com.numi.recipe.multiple_select']);
    $('.food-multi-log-ks').text(window.keystrings['com.numi.recipe.multi_log_txt']);
    $('.food-multi-cancel-ks').html(window.keystrings['com.numi.recipe.multi_cancel']);

    if ($(".checkbox_normal:checkbox:checked").length < 1) {
      localStorage.removeItem("food_category_checked");
    }

    var consumable_data_food = [];

    $(document).on('change', '.tab-pane.active .checbox_normal', function (e) {

      e.stopImmediatePropagation();
      if ($(".checbox_normal:checkbox:checked").length < 1) {
        $('.select_meal_drop').addClass('disabled_drop');
        $('.multi_log').hide();
      }
      else {
        $('.multi_log').show();
        $('.select_meal_drop').removeClass('disabled_drop');

      }
      if ($(this).is(':checked')) {
        var k = { 'id': $(this).parents('.meal_txt').find('.consumable_id').val(), 'type': $(this).parents('.meal_txt').find('.consumable_type').val(), 'food_categories': $(this).parents('.meal_txt').find('.consumable_food_cats').val(), 'idunq': $(this).parents('.meal_txt').find('.consumable_id_unq').val(), }
        consumable_data_food.push(k);
      }
      else {

        for (var i = 0; i < consumable_data_food.length; i++) {
          if (consumable_data_food[i].idunq === $(this).parents('.meal_txt').find('.consumable_id_unq').val()) {
            consumable_data_food.splice(i, 1);
            break;
          }
        }

      }
      if (consumable_data_food?.length < 1) {
        localStorage.removeItem("food_category_checked");
      } else {
        localStorage.setItem("food_category_checked", JSON.stringify(consumable_data_food));
      }

    });


    $(document).on('click', '.search_all', function (e) {
      e.stopImmediatePropagation();
      $('.food_type_drop').find('li.meal_names').find('.fa-check').addClass('hide');
      $('.food_type_drop').find('li.meal_names').find('a').removeClass('blue_color');
      $('.food_type_drop').find('li.not_set').find('a').addClass('blue_color');
      $('.food_type_drop').find('li.not_set').find('span').removeClass('hide');
      $('.food_type_drop').find('li.not_set').find('span').addClass('fa-check');
      localStorage.removeItem('food_categories');
      localStorage.removeItem('food_type');
      localStorage.removeItem('meal_occasion');
      window.localStorage.setItem('page_number', 1);
      $('[href="#All"]').onClick();
    });

    $(document).on('click', '.multi_cancel', function (e) {
      e.stopImmediatePropagation();
      $(this).hide();
      $('.food_options .meal_txt .checbox_normal:checked').each(function () {
        $(this).prop('checked', false)
      });
      $('.recent_food_options .meal_txt .checbox_normal:checked').each(function () {
        $(this).prop('checked', false)
      });
      $('.fav_food_options .meal_txt .checbox_normal:checked').each(function () {
        $(this).prop('checked', false)
      });
      $('.all_food_options .meal_txt .checbox_normal:checked').each(function () {
        $(this).prop('checked', false)
      });
      $(".multi_log").hide();
      $('.multi_txt span,.disabled_drop,.multi_txt a').css('cursor', '');
      $('.multi_gray_arrow,.multiselect_txt').show();
      $('.multi_select_dropdown,.checkbox_select').hide();

      consumable_data_food = JSON.parse(localStorage.getItem("food_category_checked"));
      for (var i = 0; i < consumable_data_food?.length; i++) {
        for (var m = 0; m < $('.food_options').length; m++)
          if (consumable_data_food[i].idunq === $('.food_options').find('.meal_txt:eq(' + m + ')').find('.consumable_id_unq').val()) {
            $('.checbox_normal:eq(' + m + ')').prop("checked", false);
          }
      }
      localStorage.removeItem("food_category_checked")
      consumable_data_food = [];
    });

    $(document).on('click', '.multi_log', function (e) {
      consumable_data_food = JSON.parse(localStorage.getItem("food_category_checked"));
      e.stopImmediatePropagation();
      Header.startLoader();
      if (window.localStorage.getItem('meal_occasion_id') === '' || window.localStorage.getItem('meal_occasion_id') === null || window.localStorage.getItem('meal_occasion_id') === undefined) {
        var bucket_sequence;
        var currentHour = moment().format('HH');

        if (currentHour <= 10 && currentHour >= 0) {
          bucket_sequence = '1';
        } else if (currentHour > 10 && currentHour < 12) {
          bucket_sequence = '2';
        } else if (currentHour >= 12 && currentHour < 14) {
          bucket_sequence = '3';
        } else if (currentHour >= 14 && currentHour < 17) {
          bucket_sequence = '4'
        } else if (currentHour >= 17 && currentHour < 20) {
          bucket_sequence = '5';
        } else if (currentHour >= 20 && currentHour <= 24) {
          bucket_sequence = '6';
        }
        window.localStorage.setItem("meal_occasion_id", bucket_sequence);
      }
      var data = { "diet_histories": [] }
      var api = window.API_URL + "v1/diet_histories";
      var method = "POST";

      if ($('[href="#All"]').parent().hasClass('active')) {
        for (var i = 0; i < $('.all_food_options .checbox_normal:checkbox:checked').length; i++) {
          var consumable_value;
          if (window.localStorage.getItem('default_meal_occasion_id') != null && window.localStorage.getItem('default_meal_occasion_id') != 'null' && window.localStorage.getItem('default_meal_occasion_id') != 'undefined' && window.localStorage.getItem('default_meal_occasion_id') != undefined) {
            if (i === 0) {
              consumable_value = {
                "consumable_id": consumable_data_food[i].id,
                "consumable_type": consumable_data_food[i].type,
                "assigned_date": window.localStorage.getItem('assigned_date'),
                "meal_occasion_id": window.localStorage.getItem("meal_occasion_id"),
                "food_categories": $.parseJSON(consumable_data_food[i].food_categories),
                "quick_log_id": window.localStorage.getItem('quicklog_id'),
                "default_meal_occasion_id": window.localStorage.getItem('default_meal_occasion_id')
              }
            }
            else {
              consumable_value = {
                "consumable_id": consumable_data_food[i].id,
                "consumable_type": consumable_data_food[i].type,
                "assigned_date": window.localStorage.getItem('assigned_date'),
                "meal_occasion_id": window.localStorage.getItem("meal_occasion_id"),
                "food_categories": $.parseJSON(consumable_data_food[i].food_categories),

              }
            }
          } else {
            if (i === 0) {
              consumable_value = {
                "consumable_id": consumable_data_food[i].id,
                "consumable_type": consumable_data_food[i].type,
                "assigned_date": window.localStorage.getItem('assigned_date'),
                "meal_occasion_id": window.localStorage.getItem("meal_occasion_id"),
                "food_categories": $.parseJSON(consumable_data_food[i].food_categories),
                "quick_log_id": window.localStorage.getItem('quicklog_id')
              }
            }
            else {
              consumable_value = {
                "consumable_id": consumable_data_food[i].id,
                "consumable_type": consumable_data_food[i].type,
                "assigned_date": window.localStorage.getItem('assigned_date'),
                "meal_occasion_id": window.localStorage.getItem("meal_occasion_id"),
                "food_categories": $.parseJSON(consumable_data_food[i].food_categories)
              }
            }
          }

          data.diet_histories.push(consumable_value);
        }
      }
      else if ($('[href="#recent"]').parent().hasClass('active')) {
        for (var j = 0; j < $('.recent_food_options .checbox_normal:checkbox:checked').length; j++) {

          if (j === 0) {
            consumable_value = {
              "consumable_id": consumable_data_food[j].id,
              "consumable_type": consumable_data_food[j].type,
              "assigned_date": window.localStorage.getItem('assigned_date'),
              "meal_occasion_id": window.localStorage.getItem("meal_occasion_id"),
              "food_categories": $.parseJSON(consumable_data_food[j].food_categories),
              "quick_log_id": window.localStorage.getItem('quicklog_id')
            }
          }
          else {
            consumable_value = {
              "consumable_id": consumable_data_food[j].id,
              "consumable_type": consumable_data_food[j].type,
              "assigned_date": window.localStorage.getItem('assigned_date'),
              "meal_occasion_id": window.localStorage.getItem("meal_occasion_id"),
              "food_categories": $.parseJSON(consumable_data_food[j].food_categories)
            }
          }
          data.diet_histories.push(consumable_value);
        }
      } else if ($('[href="#favorite"]').parent().hasClass('active')) {
        for (var k = 0; k < $('.fav_food_options .checbox_normal:checkbox:checked').length; k++) {

          if (k === 0) {
            consumable_value = {
              "consumable_id": consumable_data_food[k].id,
              "consumable_type": consumable_data_food[k].type,
              "assigned_date": window.localStorage.getItem('assigned_date'),
              "meal_occasion_id": window.localStorage.getItem("meal_occasion_id"),
              "food_categories": $.parseJSON(consumable_data_food[k].food_categories),
              "quick_log_id": window.localStorage.getItem('quicklog_id')
            }
          }
          else {
            consumable_value = {
              "consumable_id": consumable_data_food[k].id,
              "consumable_type": consumable_data_food[k].type,
              "assigned_date": window.localStorage.getItem('assigned_date'),
              "meal_occasion_id": window.localStorage.getItem("meal_occasion_id"),
              "food_categories": $.parseJSON(consumable_data_food[k].food_categories)
            }
          }
          data.diet_histories.push(consumable_value);
        }
      } else {
        for (var m = 0; m < consumable_data_food.length; m++) {
          if (window.localStorage.getItem('default_meal_occasion_id') != null && window.localStorage.getItem('default_meal_occasion_id') != 'null' && window.localStorage.getItem('default_meal_occasion_id') != 'undefined' && window.localStorage.getItem('default_meal_occasion_id') != undefined) {
            if (m === 0) {
              consumable_value = {
                "consumable_id": consumable_data_food[m].id,
                "consumable_type": consumable_data_food[m].type,
                "assigned_date": window.localStorage.getItem('assigned_date'),
                "meal_occasion_id": window.localStorage.getItem("meal_occasion_id"),
                "food_categories": $.parseJSON(consumable_data_food[m].food_categories),
                "quick_log_id": window.localStorage.getItem('quicklog_id'),
                "default_meal_occasion_id": window.localStorage.getItem('default_meal_occasion_id'),
              }
            }
            else {
              consumable_value = {
                "consumable_id": consumable_data_food[m].id,
                "consumable_type": consumable_data_food[m].type,
                "assigned_date": window.localStorage.getItem('assigned_date'),
                "meal_occasion_id": window.localStorage.getItem("meal_occasion_id"),
                "food_categories": $.parseJSON(consumable_data_food[m].food_categories),

              }
            }
          } else {
            if (m === 0) {
              consumable_value = {
                "consumable_id": consumable_data_food[m].id,
                "consumable_type": consumable_data_food[m].type,
                "assigned_date": window.localStorage.getItem('assigned_date'),
                "meal_occasion_id": window.localStorage.getItem("meal_occasion_id"),
                "food_categories": $.parseJSON(consumable_data_food[m].food_categories),
                "quick_log_id": window.localStorage.getItem('quicklog_id'),
              }
            }
            else {
              consumable_value = {
                "consumable_id": consumable_data_food[m].id,
                "consumable_type": consumable_data_food[m].type,
                "assigned_date": window.localStorage.getItem('assigned_date'),
                "meal_occasion_id": window.localStorage.getItem("meal_occasion_id"),
                "food_categories": $.parseJSON(consumable_data_food[m].food_categories)
              }
            }
          }
          data.diet_histories.push(consumable_value);
        }
      }
      const options = {
        url: api,
        method: method,
        credentials: 'same-origin',
        headers: Header.setAuthHeaders(),
        data: data
      };

      axios(options)
        .then(response => {
          consumable_data_food = [];
          Header.stopLoader();
          localStorage.removeItem('quicklog_id');
          localStorage.removeItem("food_category_checked");
          this.props.history.push('/journal');
        })
        .catch(error => {
          Header.stopLoader();
          Header.apiErrors(error);
          Sentry.captureException(error);
        });

    }.bind(this));
    if (window.localStorage.getItem('log_food_from') !== '' && window.localStorage.getItem('log_food_from') !== undefined && window.localStorage.getItem('log_food_from') !== null) {
      if (window.localStorage.getItem('searched_term') !== '' && window.localStorage.getItem('searched_term') !== undefined && window.localStorage.getItem('searched_term') !== null) {
        $(".food_search_input").val(window.localStorage.getItem('searched_term'));
      }
      if (window.localStorage.getItem('log_food_from') === "Recent") {
        this.RecentSearchFilter();
        this.setState({
          sectionType: "recent"
        }, () => {
          this.favorite_sectionClick()
        })
      } else if (window.localStorage.getItem('log_food_from') === "Favorite") {
        this.FavSearchFilter();
        this.setState({
          sectionType: "favorite"
        }, () => {
          this.favorite_sectionClick()
        })
        if (window.localStorage.getItem('food_categories') === '') {
          $('.food_type_drop').find('li.not_set').find('a').removeClass('blue_color');
          $('.food_type_drop').find('li.not_set').find('span').removeClass('fa-check');
        }
      } else if (window.localStorage.getItem('log_food_from') === "All") {
        this.setState({
          sectionType: "all"
        }, () => {
          this.favorite_sectionClick()
        })
      } else {

        this.setState({
          sectionType: "Nutrisystem"
        }, () => {
          this.favorite_sectionClick()
        })
      }
      if (window.localStorage.getItem('searched_term') !== '' && window.localStorage.getItem('searched_term') !== undefined && window.localStorage.getItem('searched_term') !== null) {
        $(".food_search_input").val(window.localStorage.getItem('searched_term'));
      }
      localStorage.removeItem('log_food_from');
    }
    else if ((window.localStorage.getItem('search_from_header') === "true" && window.localStorage.getItem('search_from_header') !== null && window.localStorage.getItem('search_from_header') !== undefined) || (window.localStorage.getItem('food_categories') !== '' && window.localStorage.getItem('food_categories') !== null && window.localStorage.getItem('food_categories') !== undefined)) {
      if ($('[href="#recent"]').parent().hasClass('active')) {
        this.RecentSearchFilter();
      } else if ($('[href="#favorite"]').parent().hasClass('active')) {
        this.FavSearchFilter();
      } else if ($('[href="#All"]').parent().hasClass('active')) {
        this.callLogfoodConsumablesAPI();
      } else {
        this.callLogfoodConsumablesAPI();
      }
      if (window.localStorage.getItem('searched_term') !== '' && window.localStorage.getItem('searched_term') !== undefined && window.localStorage.getItem('searched_term') !== null) {
        $(".food_search_input").val(window.localStorage.getItem('searched_term'));
      }
      localStorage.removeItem('search_from_header');
    }

    window.localStorage.setItem('page_number', 1);
    localStorage.removeItem('recents_food_details');
    localStorage.removeItem('searched');
    localStorage.removeItem('item_name');
    window.localStorage.setItem('item_name', 'Food Search');
    this.callLogFavAPI();

    $(document).on('click', '.back_to', function () {
      window.localStorage.setItem('page_number', 1);
    });



    $(document).on('click', '.multi_cancel', function (e) {
      e.stopImmediatePropagation();
      $(this).hide();
      $('.food_options .meal_txt .checbox_normal:checked').each(function () {
        $(this).prop('checked', false)
      });
      $(".multi_log").hide();
      $('.multi_txt span,.disabled_drop,.multi_txt a').css('cursor', '');
      $('.multi_gray_arrow,.multiselect_txt').show();
      $('.multi_select_dropdown,.checkbox_select').hide();
    });

    $(document).on('click', '.meal_txt .item_click,.multiselect_page .tab-pane .meal_txt', function (e) {
      if (e.currentTarget.classList.contains('fav_section')) {
        localStorage.setItem('create_custom', 'true');
      }
      e.stopImmediatePropagation();
      var bucket_sequence;
      var currentHour = moment().format('HH');

      if (currentHour <= 10 && currentHour >= 0) {
        bucket_sequence = '1';
      } else if (currentHour > 10 && currentHour < 12) {
        bucket_sequence = '2';
      } else if (currentHour >= 12 && currentHour < 14) {
        bucket_sequence = '3';
      } else if (currentHour >= 14 && currentHour < 17) {
        bucket_sequence = '4'
      } else if (currentHour >= 17 && currentHour < 20) {
        bucket_sequence = '5';
      } else if (currentHour >= 20 && currentHour <= 24) {
        bucket_sequence = '6';
      }
      if (window.localStorage.getItem("meal_occasion_id") === "") {
        window.localStorage.setItem("meal_occasion_id", bucket_sequence);

      }

      if (e.currentTarget.classList.contains('rec_text')) {
        window.localStorage.setItem('recents_food_details', true);
        localStorage.removeItem('dh_id');
        window.localStorage.setItem('dh_id', e.currentTarget.getElementsByClassName('dh_id')[0].value);
      }
      if (e.currentTarget.getElementsByClassName('favorite_food_name')[0]) {
        window.localStorage.setItem('FavoriteFoodName', e.currentTarget.getElementsByClassName('favorite_food_name')[0].value); /* for gtm */
      }

      var evt = document.createEvent("CustomEvent");
      evt.initCustomEvent('TapFavoriteFood', true, false, false);
      document.dispatchEvent(evt);
      window.localStorage.setItem('consumable_id', e.currentTarget.getElementsByClassName('consumable_id')[0].value);
      window.localStorage.setItem('consumable_type', e.currentTarget.getElementsByClassName('consumable_type')[0].value);
      if (e.currentTarget.getElementsByClassName('food_type_name')[0]) {
        window.localStorage.setItem('foodType', e.currentTarget.getElementsByClassName('food_type_name')[0].value);
      }
      if (window.localStorage.getItem('consumable_type') === 'CustomFood') {
        window.localStorage.setItem('foodType', 'Custom');
      }
      localStorage.removeItem('from_createfood');
      if ($('[href="#recent"]').parent().hasClass('active')) {
        window.localStorage.setItem("log_food_from", "Recent");
      } else if ($('[href="#favorite"]').parent().hasClass('active')) {
        window.localStorage.setItem("log_food_from", "Favorite");
      } else if ($('[href="#All"]').parent().hasClass('active')) {
        window.localStorage.setItem("log_food_from", "All");
      } else {
        window.localStorage.setItem("log_food_from", "Consumable");
      }
      var id = e.target.id.split("-")[1]
      window.localStorage.setItem("food_serving_size_id", id)
      this.props.history.push('/food_details');
    }.bind(this));




    $(document).on('click', '.fav_rec_meal_txt .item_click', function (e) {
      e.stopImmediatePropagation();
      localStorage.setItem('create_custom', 'true');
      if (e.currentTarget.classList.contains('rec_text')) {
        window.localStorage.setItem('recents_food_details', true);
        localStorage.removeItem('dh_id');
        window.localStorage.setItem('dh_id', e.currentTarget.getElementsByClassName('dh_id')[0].value);
      }
      window.localStorage.setItem('consumable_id', e.currentTarget.getElementsByClassName('consumable_id')[0].value);
      window.localStorage.setItem('consumable_type', e.currentTarget.getElementsByClassName('consumable_type')[0].value);
      if (e.currentTarget.getElementsByClassName('favorite_food_name')[0]) {
        window.localStorage.setItem('FavoriteFoodName', e.currentTarget.getElementsByClassName('favorite_food_name')[0].value); /* for gtm */
      }
      var evt = document.createEvent("CustomEvent");
      evt.initCustomEvent('TapFavoriteFood', true, false, false);
      document.dispatchEvent(evt);
      if ($('[href="#recent"]').parent().hasClass('active')) {
        window.localStorage.setItem("log_food_from", "Recent");
      } else if ($('[href="#favorite"]').parent().hasClass('active')) {
        window.localStorage.setItem("log_food_from", "Favorite");
      } else if ($('[href="#All"]').parent().hasClass('active')) {
        window.localStorage.setItem("log_food_from", "All");
      } else {
        window.localStorage.setItem("log_food_from", "Consumable");
      }
      this.props.history.push('/food_details');
    }.bind(this));

    Header.startLoader();
    var assigned_date = moment(window.localStorage.getItem('assigned_date')).format('YYYY-MM-DD');
    const options = {
      url: window.API_URL + "v1/phase_templates/phase_property/FOOD_CATEGORIES/assigned_date/" + assigned_date,
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        Header.stopLoader();
        $(".show_more").hide();
        var all_food_data = { "display_name": "All Foods", name: "All Foods" }
        if (response && response.data && response.data.phase_template && response.data.phase_template.food_categories) {
          response.data.phase_template.food_categories.unshift(all_food_data)
          this.setState({ food_categories: response.data.phase_template.food_categories })
        }
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });


    $('.select_dropdown li a').click(function () {
      $('.select_dropdown li a').removeClass('selected');
      $(this).addClass('selected');
      $(this).parents('.select_dropdown ').find('.dropdown_list span').text($(this).text());
    });

    setTimeout(function () {
      var category = document.URL.split("#")[1]
      if (category !== undefined) {
        $("#" + category).onClick();
      }
    }, 2500)

    // /* Key Strings*/
    // $('.food-log-food-title-ks').text(window.keystrings['com.numi.log_food_title']);

    // $('.back_to span').text(window.keystrings['com.numi.back_to_journal']);
    // //$('#create-diet_history input').attr('value',"Log It")
    // $('.food-favorites-ks').text(window.keystrings['com.numi.recipes_favorites']);
    // $('.food-recents-ks').text(window.keystrings['com.numi.recents_title'])

    // /* End KeyStrings*/

  }


  favorite_sectionClick = () => {



    if ($('[href="#recent"]').parent().hasClass('active')) {
      $('div.tab-pane').removeClass('active')
      $('#recent.tab-pane').addClass('active')

      $('.food_type_drop').addClass('disabled_drop');
      $('.not_avail').show();
      $('.food_type_drop li').find('.fa-check').addClass('hide');
      $('.food_type_drop li:first a').addClass('blue_color');
      $('.food_type_drop').find('li.meal_names').find('a').removeClass('blue_color');

      $('.food_type_drop li:first').find('.fa-check').removeClass('hide');
      $('.food_type_drop').find('li.not_set').find('a').addClass('blue_color');
      $('.food_type_drop').find('li.not_set').find('span').removeClass('hide');
      $('.food_type_drop').find('li.not_set').find('span').addClass('fa-check');
      this.RecentSearchFilter();
      $('.show_more,.multi_cancel,.multi_log').hide();
    }
    else if ($('[href="#favorite"]').parent().hasClass('active')) {
      $('div.tab-pane').removeClass('active')
      $('#favorite.tab-pane').addClass('active')

      $('.meal_section_right').show();
      $('.not_avail').hide();
      $('.food_type_drop').removeClass('disabled_drop');
      $('.food_type_drop li:first a').removeClass('blue_color');
      if ((window.localStorage.getItem('food_categories') === '' || window.localStorage.getItem('food_categories') === null) && window.localStorage.getItem('food_categories') === undefined) {
        $('.food_type_drop').find('li.not_set').find('a').addClass('blue_color');
        $('.food_type_drop').find('li.not_set').find('span').addClass('fa-check');
      }
      this.FavSearchFilter();
    } else if ($('[href="#All"]').parent().hasClass('active')) {
      $('div.tab-pane').removeClass('active')
      $('#All.tab-pane').addClass('active')

      $('.food_type_drop').find('li.meal_names').find('.fa-check').addClass('hide');
      $('.food_type_drop').find('li.meal_names').find('a').removeClass('blue_color');
      $('.food_type_drop').find('li.not_set').find('a').addClass('blue_color');
      $('.food_type_drop').find('li.not_set').find('span').removeClass('hide');
      $('.food_type_drop').find('li.not_set').find('span').addClass('fa-check');
      localStorage.removeItem('food_categories');
      localStorage.removeItem('food_type');
      localStorage.removeItem('meal_occasion');
      window.localStorage.setItem('page_number', 1);
      this.callLogfoodConsumablesAPI();
      $('.not_avail').hide();
      $('.food_type_drop').removeClass('disabled_drop');
      $('.food_type_drop li:first a').removeClass('blue_color');
      $('.meal_section_right').show();
      if ((window.localStorage.getItem('food_categories') === '' || window.localStorage.getItem('food_categories') === null) && window.localStorage.getItem('food_categories') === undefined) {
        $('.food_type_drop').find('li.not_set').find('a').addClass('blue_color');
        $('.food_type_drop').find('li.not_set').find('span').addClass('fa-check');
      }
    } else {
      this.callLogfoodConsumablesAPI();
      $('div.tab-pane').removeClass('active')
      $('#Nutrisystem.tab-pane').addClass('active')

      $('.not_avail').hide();
      $('.food_type_drop').removeClass('disabled_drop');
      $('.food_type_drop li:first a').removeClass('blue_color');
      $('.meal_section_right').show();
      if ((window.localStorage.getItem('food_categories') === '' || window.localStorage.getItem('food_categories') === null) && window.localStorage.getItem('food_categories') === undefined) {
        $('.food_type_drop').find('li.not_set').find('a').addClass('blue_color');
        $('.food_type_drop').find('li.not_set').find('span').addClass('fa-check');
      }
    }
    if ((window.localStorage.getItem('searched_term') !== '' && window.localStorage.getItem('searched_term') !== undefined) && window.localStorage.getItem('searched_term') !== null) {
      $(".food_search_input").val(window.localStorage.getItem('searched_term'));
    }
  };

  render() {



    if (this.state.fav_list !== undefined && this.state.fav_list.length > 0) {
      $('.fav_empty').hide();
      var fav_list_options = this.state.fav_list.map(function (option, i) {
        var nn = [];
        for (var aa = 0; aa < option.food_categories.length; aa++) {
          var kk = option.food_categories[aa].display_name.replace(/\s/g, '&nbsp;');
          var pp = { "id": option.food_categories[aa].id, "name": option.food_categories[aa].name, "display_name": kk, "value": option.food_categories[aa].value };
          nn.push(pp)
        }
        if (option.food_source !== "Nutrisystem" && option.food_source !== "leaf") {
          return (
            <li className="fav_rec_meal_txt meal_txt cursor_pointer" key={i}><span className="color6 item_click first_span cursor_pointer"> {option.food_name} <br /> <span className="leaf_image"></span><input type="hidden" className="consumable_id" value={option.consumable_id} /><input type="hidden" value={option.consumable_type} className="consumable_type" /><input type="hidden" value={option.food_name} className="favorite_food_name" /><img alt="" src="/assets/images/arrow_right_grey.png" className="pull-right mt-10 multi_gray_arrow" />
              <input type="hidden" value={JSON.stringify(nn)} className="consumable_food_cats" /><span className="font12 color8 vlm"> {option.calories} Cals</span></span><div className="checkbox_select pull-right" > <input className="checbox_normal" id={"multi_check_fav" + kk} name="food_item" type="checkbox" /> <label className="multi_check_fav" htmlFor={"multi_check_fav" + kk}><span><i></i></span></label></div></li>

          )
        } else {
          return (
            <li className="fav_rec_meal_txt meal_txt cursor_pointer" key={i}> <span className="color6 item_click first_span cursor_pointer">{option.food_name} <br /> <span className="leaf_image"><img alt="" src="/assets/images/ns_food_tag.png" /></span> <input type="hidden" className="consumable_id" value={option.consumable_id} /><input type="hidden" value={option.consumable_type} className="consumable_type" /><input type="hidden" value={option.food_name} className="favorite_food_name" /><img alt="" src="/assets/images/arrow_right_grey.png" className="pull-right mt-10 multi_gray_arrow" />
              <input type="hidden" value={JSON.stringify(nn)} className="consumable_food_cats" /><span className="font12 color8 vlm"> {option.calories} Cals</span></span><div className="checkbox_select pull-right" > <input className="checbox_normal" id={"multi_check_fav" + kk} name="food_item" type="checkbox" /> <label className="multi_check_fav" htmlFor={"multi_check_fav" + kk}><span><i></i></span></label></div></li>
          )
        }
      });
    }
    else {
      $('.fav_empty').show();
    }

    return (
      <div>
        <div className="table_display">
          <div className="journal_div journal log_food_page">
            <div className="section_left pull-left">
              <Navigation />
            </div>
            <div className="section_right pull-left">
              <figure><Adspeed /></figure>
              <div className="journal_rht">
                <p className="mb">
                  <Link to="/journal" className="back_to"><img alt="" src="/assets/images/back_white_icon.png" className="vlb" /> <span className="food-back-to-journal"> </span></Link>
                </p>
                <h1 className="journal_hdr pull-left food-log-food-title-ks"> </h1>
                <div className="pull-right mt10"></div>
                <div className="clear"></div>
                <hr />
                <FoodFilter callParentLogFood={this.callLogfoodConsumablesAPI} callParentFavFood={this.FavSearchFilter} callParentRecentFood={this.RecentSearchFilter} />
                <div className="pull-right">
                  <button className="create_food_btn" onClick={() => this.navigateToCreateFood()}><img alt="" src="/assets/images/square_plus.png" width="15" height="15" /><span className="food-create-food-button-ks"></span></button>
                </div>
                <div className="clear"></div>
                <div className="panel-group meal_section_left pull-left text-center">
                  <ul className="favorite_section">
                    <li className={this.state.sectionType == "All" ? "active" : ''}><a href="#All" data-toggle="tab" aria-expanded="false" className="log-food-all-ks"
                      onClick={() => {
                        this.setState({
                          sectionType: "All"
                        }, () => {
                          this.favorite_sectionClick()
                        })
                      }}
                    > </a></li>
                    <li class={this.state.sectionType == "recent" ? "active" : ''}><a href="#recent" data-toggle="tab" aria-expanded="false" className="food-recents-ks"
                      onClick={() => {
                        this.setState({
                          sectionType: "recent"
                        }, () => {
                          this.favorite_sectionClick()
                        })
                      }}
                    > </a></li>
                    <li class={this.state.sectionType == "favorite" ? "active" : ''}><a href="#favorite" data-toggle="tab" aria-expanded="true" className="food-favorites-ks"
                      onClick={() => {
                        this.setState({
                          sectionType: "favorite"
                        }, () => {
                          this.favorite_sectionClick()
                        })
                      }}
                    > </a>
                    </li>
                    <li class={this.state.sectionType == "Nutrisystem" ? "active" : ''}><a href="#Nutrisystem" data-toggle="tab" aria-expanded="true" className="nutrisystem-title-ks"
                      onClick={() => {
                        this.setState({
                          sectionType: "Nutrisystem"
                        }, () => {
                          this.favorite_sectionClick()
                        })
                      }}
                    > </a>
                    </li>
                  </ul>
                  <div className="tab-content text-center">
                    <div className="tab-pane" id="All">
                      <div className="activity_empty">
                        <div className="text-center serach_on_keyup">
                          <img alt="" src="/assets/images/search_empty_allfoods.png" />
                          <p className="color7 font16 food_not_found"><br />We couldn’t find <b></b></p>
                          <p className="font12 color_98 text-left mt20 rec_text_over">Recommendation for </p>
                        </div>
                        <div className="text-center serach_on_click">
                          <img alt="" src="/assets/images/search_empty_ne.png" />
                          <p className="color7 font16 food_click_not_found"><br />We couldn’t find <b></b> for Nutrisystem Entrées</p>
                          <p className="font12 color_98 text-left mt20">17 Results from other food categories</p>
                        </div>
                      </div>
                      <div className="clear"></div>
                      <div className="">
                        <div className="multi_txt font14">
                          <span className="pull-right multiselect_txt food-multiple-select-ks" onClick={this.multiFoodTextclick}></span>
                          <span className="pull-right multi_select_dropdown">
                            <span className="disabled_drop select_meal_drop"><MealDropdown /></span>
                            <span className="mt multi_log food-multi-log-ks"></span>
                            <span className="mt multi_cancel food-multi-cancel-ks"></span>
                          </span>
                          <div className="clear"></div>
                        </div>
                      </div>
                      <ul className="all_food_options">
                      </ul>
                      <button className="show_more font15" onClick={this.incermentPageNofood.bind(this)}>Show More</button>
                    </div>
                    <div className="tab-pane active" id="Nutrisystem">
                      <div className="activity_empty">
                        <div className="text-center serach_on_keyup">
                          <img alt="" src="/assets/images/search_empty_allfoods.png" />
                          <p className="color7 font16 food_not_found"><br />We couldn’t find <b></b></p>
                          <p className="search_all blue_color"><span className="search_all_text">Search All for </span><b></b></p>
                          <p className="font12 color_98 text-left mt20 rec_text_over">Recommendation for </p>
                        </div>
                        <div className="text-center serach_on_click">
                          <img alt="" src="/assets/images/search_empty_ne.png" />
                          <p className="color7 font16 food_click_not_found"><br />We couldn’t find <b></b></p>
                          <p className="font12 color_98 text-left mt20">17 Results from other food categories</p>
                        </div>
                      </div>
                      <div className="clear"></div>
                      <div className="">
                        <div className="multi_txt font14">
                          <span className="pull-right multiselect_txt food-multiple-select-ks" onClick={this.multiFoodTextclick}></span>
                          <span className="pull-right multi_select_dropdown">
                            <span className="mt multi_log food-multi-log-ks"></span>
                            <span className="mt multi_cancel food-multi-cancel-ks"></span>
                          </span>
                          <div className="clear"></div>
                        </div>
                      </div>
                      <ul className="food_options">
                        <FilterSearch callParentLogFood={this.callLogfoodConsumablesAPI} callParentFavFood={this.FavSearchFilter} callParentRecentFood={this.RecentSearchFilter} />
                      </ul>
                      <button className="show_more font15" onClick={this.incermentPageNofood.bind(this)}>Show More</button>
                    </div>
                    <div className="tab-pane" id="favorite">
                      <div className="fav_empty">
                        <img alt="" src="/assets/images/fav_empty.png" />
                        <p className="fav_hdr food-favorite-your-meal-ks"></p>
                        <p className="fav_small_txt food-favorite-msg-ks"></p>
                      </div>
                      <div className="text-center serach_on_keyup">
                        <img alt="" src="/assets/images/search_empty_allfoods.png" />
                        <p className="color7 font16 food_not_found"><br />We couldn’t find <b></b></p>
                        <p className="search_all blue_color"><span className="search_all_text">Search All for </span><b></b></p>
                        <p className="font12 color_98 text-left plft rec_text_over">Recommendation for </p>
                      </div>
                      <div className="clear"></div>
                      <div className="">
                        <div className="multi_txt font14">
                          <span className="pull-right multiselect_txt food-multiple-select-ks" onClick={this.multiFoodTextclick}></span>
                          <span className="pull-right multi_select_dropdown">
                            <span className="mt multi_log food-multi-log-ks"></span>
                            <span className="mt multi_cancel food-multi-cancel-ks"></span>
                          </span>
                          <div className="clear"></div>
                        </div>
                        <ul className="fav_food_options">
                          {fav_list_options}
                        </ul>
                      </div>
                    </div>
                    <div className="tab-pane" id="recent">
                      <div className="recent_empty">
                        <img alt="" src="/assets/images/recent_icon.png" />
                        <p className="fav_hdr">Start Logging Meals</p>
                        <p className="fav_small_txt food-recent-msg-ks"></p>
                      </div>
                      <div className="text-center serach_on_keyup">
                        <img alt="" src="/assets/images/search_empty_allfoods.png" />
                        <p className="color7 font16 food_not_found"><br />We couldn’t find <b></b></p>
                        <p className="search_all blue_color"><span className="search_all_text">Search All for </span><b></b></p>
                        <p className="font12 color_98 text-left plft rec_text_over">Recommendation for </p>
                      </div>
                      <div className="clear"></div>
                      <div className="">
                        <div className="multi_txt font14">
                          <span className="pull-right multiselect_txt food-multiple-select-ks" onClick={this.multiFoodTextclick}></span>
                          <span className="pull-right multi_select_dropdown">
                            <span className="mt multi_log food-multi-log-ks"></span>
                            <span className="mt multi_cancel food-multi-cancel-ks"></span>
                          </span>
                          <div className="clear"></div>
                        </div>
                      </div>
                      <ul className="recent_food_options">
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="meal_section_right right_container pull-right">
                  <FilterSearch callParentLogFood={this.callLogfoodConsumablesAPI} callParentFavFood={this.FavSearchFilter} callParentRecentFood={this.RecentSearchFilter} />
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div >
    )
  }
}





export default Log_Food;
