import React, { useState, useRef } from 'react'
import { useEffect } from 'react';
import Header from "../header.js";
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { HelpQuestions } from '../journal/popup.js';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Modal } from 'react-bootstrap';
import { parse } from 'date-fns';
import moment from 'moment';
import { Tab, Tabs } from 'react-bootstrap';
import $ from 'jquery';
import * as Sentry from "@sentry/react";
import SmartWeekCheatDay from '../cheat_days/smart_week_cheat_days_setup.js';

export const PersonalizePlanMode = (props) => {
  const history = useHistory();
  const helpRef = useRef("");
  const [introScreen, toggleIntroScreen] = useState(false);
  const [moreDetailsModal, setMoreDetailsModal] = useState(false);
  const [quiz, showQuiz] = useState(false);

  const [quizQuestions, showQuestion] = useState([]);
  window.activityArray = [];

  useEffect(() => {
    /*key strings*/
    $('.smart_week_setup_txt').text(window.keystrings['com.numi.settings.options.smart_week_setup']);
    $('.set_cheat_days_txt').text(window.keystrings['com.numi.settings.options.set_cheat_days']);
    $('.smart_week_choose_week_type_txt').text(window.keystrings['com.numi.settings.options.smart_week_choose_week_type']);
    /*key strings*/



    if (localStorage.getItem('set_true') === 'true' || window.location.href.indexOf('/progress') > -1 || window.location.href.indexOf('/journal') > -1) {

      callSmartQuestions();
      showQuiz(true);
      toggleIntroScreen(false);

      localStorage.setItem('set_true', 'false');
    }
    else {
      callSmartQuestions();
      showQuiz(false);
      toggleIntroScreen(false);
    }
    $('.thumbnail-slider-wrap').css('opacity', 1);





    document.querySelectorAll('.log_your_progress').forEach(function (txt) {
      txt.innerHTML = window.keystrings['com.numi.remember_log_your_progress'];
    });

    // let help_popup = document.getElementById("help_plan");

    // let detail_popup = document.getElementById("details_popup");
    // document.body.appendChild(help_popup);
    //document.body.appendChild(detail_popup);
    let help_cipd = document.getElementById("help_cipd");
    document.body.appendChild(help_cipd);

    $(document).on('click', "span[data-target='#details_popup']", function () {
      localStorage.setItem('smartAdaptEventLabel', 'More Details - SmartAdapt')
      var evt = document.createEvent("CustomEvent");
      evt.initCustomEvent('SmartAdaptTapEvents', true, false, false);
      document.dispatchEvent(evt);
    });
    return function cleanup() {
      if (localStorage.getItem('set_true') === 'true') {
        showQuiz(false);
        toggleIntroScreen(true);
      }
      else {
        showQuiz(false);
        toggleIntroScreen(true)
      }
      localStorage.setItem('set_true', 'false');
    }
  }, []);

  const callSmartQuestions = () => {
    var questions_url;
    if (window.location.href.indexOf('/progress') > -1 || window.location.href.indexOf('/journal') > -1) {
      questions_url = window.API_URL + "v1/nutrisystem/activity_questions/personalized";
      localStorage.setItem('set_true', 'false');
    }
    else {
      questions_url = window.API_URL + "v1/nutrisystem/activity_questions/smart_adapt"
    }
    const options = {
      url: questions_url,
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        if (response && response.data) {
          Header.stopLoader(); // stop loader
          showQuestion(response.data.nutrisystem_activity_questions);
          googleAnalyticsForQuestions();
        }
      })
      .catch(error => {
        Header.stopLoader(); // stop loader
        Header.apiErrors(error);
        Sentry.captureException(error);
      });
  }
  const toggleIntro = () => {
    if (!introScreen) {
      var evt = document.createEvent("CustomEvent");
      evt.initCustomEvent('PersonalPlanAwaits', true, false, false);
      document.dispatchEvent(evt);
    }
    else {
      localStorage.setItem('smartAdaptEventLabel', 'Cancel - SmartAdapt');
      var evt1 = document.createEvent("CustomEvent");
      evt1.initCustomEvent('SmartAdaptTapEvents', true, false, false);
      document.dispatchEvent(evt1);
    }

    toggleIntroScreen(!introScreen)
  }
  const hideMoreDetailsPopup = () => {
    setMoreDetailsModal(false)
  }
  const startQuiz = () => {
    localStorage.setItem('smartAdaptEventLabel', 'Start Now - SmartAdapt');
    var evt = document.createEvent("CustomEvent");
    evt.initCustomEvent('SmartAdaptTapEvents', true, false, false);
    document.dispatchEvent(evt);
    callSmartQuestions();
    showQuiz(true);
    toggleIntroScreen(false)
  }
  const restartQuiz = () => {
    showQuiz(false);
    toggleIntroScreen(true)
  }

  const googleAnalyticsForQuestions = () => {
    var evt = document.createEvent("CustomEvent");
    if ($("div[type='BODY_TYPE']").hasClass('active in')) {
      evt.initCustomEvent('QuizBodyType', true, false, false);
      document.dispatchEvent(evt);
    }
    else if ($("div[type='WEIGHT_LOSS']").hasClass('active in')) {
      evt.initCustomEvent('QuizWeightLoss', true, false, false);
      document.dispatchEvent(evt);
    }
    else if ($("div[type='NEAT']").hasClass('active in')) {
      evt.initCustomEvent('QuizDailyLifestyle', true, false, false);
      document.dispatchEvent(evt);
    }
    else if ($("div[type='ACTIVITY']").hasClass('active in')) {
      evt.initCustomEvent('QuizExerciseHabits', true, false, false);
      document.dispatchEvent(evt);
    }
  }

  const removeSmartAdapt = () => {

    if (window.location.href.indexOf('/ns_plan') > -1) {
      localStorage.removeItem('smartAdapt');
      localStorage.removeItem('smart_week_setup_screen');
      props.getcallDeterminantAPI();
    }
    else {
      localStorage.setItem('smartAdapt', false);
      localStorage.setItem('transition_type_nothanks', true);
      localStorage.setItem('restart_plan_phase_id', 1);
      $('.modal').modal('hide');
      props.callTransitionDeterminant();
    }
    localStorage.setItem('smartAdaptEventLabel', 'No Thanks - SmartAdapt');
    var evt = document.createEvent("CustomEvent");
    evt.initCustomEvent('SmartAdaptTapEvents', true, false, false);
    document.dispatchEvent(evt);

  }

  const navPage = () => {

    $('.modal-content').removeClass('padding0');
    $('#personalize_div').removeClass('from_button');

    if (window.location.href.indexOf('/ns_plan') > -1) {
      history.push('/stay_on_track')
    }
    else {
      $('.modal').modal('hide');
      $('body').removeClass('modal-open');
      $('.modal-backdrop').remove();
      localStorage.removeItem('option_key');
      localStorage.removeItem('option_value');
      if (window.location.href.indexOf('/journal') > -1) {
        props.callJournalAPI();
      } else if (window.location.href.indexOf('/progress') > -1) {
        props.callProgressAPI();
      }
      else {
        history.push('/journal');
      }
    }
  }
  const showPlanList = () => {

    $('#personalize_div').hide();
    $('.category_list,.hide_arrows').show();
    var evt = document.createEvent("CustomEvent");
    evt.initCustomEvent('SelectPlanCategoryStep2', true, false, false);
    document.dispatchEvent(evt);
    if (window.location.href.indexOf('/ns_plan') > -1) {
      $('#ns_plan_main').removeClass('ns_plan_select_plan');
    }
  }

  return (
    <>
      <div id="personalize_div">
        <div className="personalize_div">
          {quiz ?
            <div className="quiz_div width93">

              <Quiz quiz_questions={quizQuestions} callSmartAdapt={props.callSmartAdapt} questionArray={window.activityArray} getDeterminant={props.callTransitionDeterminant} callDeterminant={props.getcallDeterminantAPI} callQuiz={restartQuiz} callTransition={props.callTrans} getData={props.gettransData} />
            </div> : introScreen ? <><img alt="" onClick={toggleIntro} src="/assets/images/back_white_icon.png" className="cursor_pointer page_back_arrow" /><br /><br /><div className="width77"> <img src="/assets/images/personalize_icon.svg" alt="" title="" />  <h2 className="mt15 padding0" dangerouslySetInnerHTML={{ __html: window.keystrings['com.numi.your_personal_plan_awaits_title'] }}></h2>
              <h5 className="mt15 padding0" dangerouslySetInnerHTML={{ __html: window.keystrings['com.numi.your_personal_plan_awaits_desc'] }}></h5><button onClick={startQuiz} dangerouslySetInnerHTML={{ __html: window.keystrings['com.numi.start_now'] }}></button>
              <p><span onClick={toggleIntro} className="cursor_pointer" dangerouslySetInnerHTML={{ __html: window.keystrings['com.numi.cancel'] }}></span></p></div></> : <><img alt="" onClick={showPlanList} src="/assets/images/back_white_icon.png" className="cursor_pointer page_back_arrow" /><br /><br /><div className="width77"><img src="/assets/images/personalize_icon.svg" alt="" title="" /><h2 className="mt15 padding0" dangerouslySetInnerHTML={{ __html: window.keystrings['com.numi.personalize_your_plan_title'] }}></h2>
                <h5 className="mt15 padding0" dangerouslySetInnerHTML={{ __html: window.keystrings['com.numi.personalize_your_plan_desc'] }}></h5>
                <button onClick={toggleIntro} dangerouslySetInnerHTML={{ __html: window.keystrings['com.numi.enable_numi_smartadapt'] }}></button>
                <p><span onClick={() => setMoreDetailsModal(true)} className="cursor_pointer" data-toggle="modal" dangerouslySetInnerHTML={{ __html: window.keystrings['com.numi.more_detail'] }}></span> &nbsp; &nbsp;<span className="cursor_pointer" onClick={removeSmartAdapt} dangerouslySetInnerHTML={{ __html: window.keystrings['com.numi.nothanks'] }}></span></p>

              </div></>
          }
        </div>

      </div>
      <Modal show={moreDetailsModal} id="details_popup" onHide={hideMoreDetailsPopup}>
        <Modal.Body>
          <img src="/assets/images/personalize_icon.svg" alt="" title="" />
          <h3 className="mt15" dangerouslySetInnerHTML={{ __html: window.keystrings['com.numi.smart_adapt_help_header'] }}></h3>
          <h5 className="mt15" dangerouslySetInnerHTML={{ __html: window.keystrings['com.numi.smart_adapt_help_description_web'] }}></h5>
          <p className="help_points_web" dangerouslySetInnerHTML={{ __html: window.keystrings['com.numi.smart_adapt_help_points_web'] }}></p>
          <button className="width77" onClick={hideMoreDetailsPopup}>Close</button>
        </Modal.Body>
      </Modal>
      <div className="copy-wrapper ques_buffer colorff buffer_bg">

        <div className="copy-container text-center">
          <img title="" src="/assets/images/calculation_loading.gif" className="img-responsive center-block" alt="loading" />
          <h3 className="text-uppercase mt20px calculate_result"> </h3>
          <h5 className="determine_text"> </h5>

        </div>
      </div>

      <div className="ques_calculation buffer_bg text-center colorff">

        <div className="calc_hdr"><i></i>{/*<i onClick={backPlan} className="fa fa-arrow-left colorff cursor_pointer" aria-hidden="true"></i>*/} <span className="personal_hdr"></span> <span className="cursor_pointer white_border" data-target="#help_cipd" data-toggle="modal">?</span></div>
        <div className="width80">
          <img src="/assets/images/mealplan.svg" alt="" className="plb10" /><br></br><br></br>
          <h5 className="personalized_hdr"> </h5>
          <h1 className="ptb3"><b className="final_cipd_value"></b></h1>
          <hr className="cal_divider" />
          <p className="font15 calorie_range_txt"></p>
          <button className="mt15" onClick={navPage}>Continue</button>
          <p className="font16 mt20px log_your_progress"></p>
        </div>
      </div>

      <div className="smart_week_setup_option_new" style={{ display: "none" }}>
        <SmartWeekCheatDay />
      </div>
      <HelpQuestions ref={helpRef} />
    </>
  )
}

function Details() {
  return (
    <>
      {/* <div className="" id="details_popup">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body width90">
              <img src="/assets/images/personalize_icon.svg" alt="" title="" />
              <h3 className="mt15" dangerouslySetInnerHTML={{ __html: window.keystrings['com.numi.smart_adapt_help_header'] }}></h3>
              <h5 className="mt15" dangerouslySetInnerHTML={{ __html: window.keystrings['com.numi.smart_adapt_help_description_web'] }}></h5>
              <p className="help_points_web" dangerouslySetInnerHTML={{ __html: window.keystrings['com.numi.smart_adapt_help_points_web'] }}></p>
              <button className="width77" onClick={() => {
                $('#details_popup').hide();
              }} >Close</button>
            </div>
          </div>
        </div>
      </div> */}

    </>
  )
}

function Quiz(props) {
  const [finish_quiz, finishQuiz] = useState(false);
  const [isChecked, setstate] = useState(false);


  const selectWeight = (e) => {
    const parent = e.currentTarget.parentElement;

    Array.prototype.forEach.call(parent.children, item => {
      item.classList.remove('active');

    });
    e.currentTarget.classList.add('active');

  }


  const clickNext = (e, id) => {



    document.querySelector('.quiz_back').style.display = 'block';
    document.querySelector('.quiz_back').setAttribute('id', id);
    var evt = document.createEvent("CustomEvent");

    if (document.querySelector("div[type='BODY_TYPE']") && document.querySelector("div[type='BODY_TYPE']").classList.contains('active')) {
      evt.initCustomEvent('QuizDailyLifestyle', true, false, false);
      localStorage.setItem('smartAdaptEventLabel', 'Next - Quiz - Body Type');
    }
    else if (document.querySelector("div[type='WEIGHT_LOSS']") && document.querySelector("div[type='WEIGHT_LOSS']").classList.contains('active')) {
      evt.initCustomEvent('QuizBodyType', true, false, false);
      localStorage.setItem('smartAdaptEventLabel', 'Next - Quiz - How Much Weight');
    }
    else if (document.querySelector("div[type='NEAT']") && document.querySelector("div[type='NEAT']").classList.contains('active')) {
      evt.initCustomEvent('QuizExerciseHabits', true, false, false);
      localStorage.setItem('smartAdaptEventLabel', 'Next - Quiz - Daily Lifestyle');
    }
    else if (document.querySelector("div[type='ACTIVITY']") && document.querySelector("div[type='ACTIVITY']").classList.contains('active')) {
      evt.initCustomEvent('SmartAdaptAllSet', true, false, false);
      localStorage.setItem('smartAdaptEventLabel', 'Next - Quiz - Exercise Habits');
    }
    document.dispatchEvent(evt);
    var evt1 = document.createEvent("CustomEvent");
    evt1.initCustomEvent('SmartAdaptTapEvents', true, false, false);
    document.dispatchEvent(evt1);
    if (e.target.parentElement.querySelector('.footer_check')) {
      props.questionArray.push({
        "key": e.target.getAttribute('type'),
        "value": e.target.parentElement.querySelectorAll('.questions_list.active')[0].getAttribute('code'),
      },
        {
          "key": e.target.parentElement.querySelector('.footer_check').getAttribute('get_key'),
          "value": e.target.parentElement.querySelector('.footer_check').getAttribute('value'),

        })
    }
    else {
      props.questionArray.push({
        "key": e.target.getAttribute('type'),
        "value": e.target.parentElement.querySelectorAll('.questions_list.active')[0].getAttribute('code'),
      })
    }


    var activity_params = { "nutrisystem_user_properties": props.questionArray }
    if (props.quiz_questions.length === id) {


      const options = {
        url: window.API_URL + 'v1/nutrisystem/user_properties',
        method: 'POST',
        credentials: 'same-origin',
        headers: Header.setAuthHeaders(),
        data: activity_params
      };
      axios(options)
        .then(response => {

          if (window.location.href.indexOf('/plan_settings') > -1) {
            props.getDeterminant();
          }

          if (response && response.data) {
            document.querySelector('.final_cipd_value').innerHTML = response.data.CIPD_DISPLAY;
          }
          if (window.location.href.indexOf('/progress') > -1 || window.location.href.indexOf('/journal') > -1) {
            document.querySelector('.personalized_popup').style.display = 'none';
            endQiuz();
          }


        })
        .catch(error => {
          Header.apiErrors(error);
          Sentry.captureException(error);
        });

    }
    if (document.querySelector(`a#list${id}`)) {
      document.querySelector(`a#list${id}`).click();
    }
    else {
      if (window.location.href.indexOf('/progress') > -1 || window.location.href.indexOf('/journal') > -1) {
        finishQuiz(finish_quiz)
      }
      else {
        finishQuiz(!finish_quiz)
      }

    }
  }


  const endQiuz = () => {

    localStorage.setItem('smartAdaptEventLabel', 'Continue - SmartAdapt Complete');
    var evt = document.createEvent("CustomEvent");
    evt.initCustomEvent('SmartAdaptTapEvents', true, false, false);
    document.dispatchEvent(evt);
    if (window.location.href.indexOf('/ns_plan') > -1) {
      props.callDeterminant()
    }
    else {
      var determinant;
      if (localStorage.getItem('set_button_true') === 'true') {
        determinant = localStorage.getItem('button_url_next_determinant');
        var alert_response = JSON.parse(localStorage.getItem('button_url_response'));

      }
      else {
        determinant = props.getData.transition_determinant.next_determinant;
      }

      if (determinant === null || determinant === undefined || determinant === 'null' || determinant === 'undefined') {
        localStorage.setItem('set_button_true', 'false');
        props.callSmartAdapt();
      }
      else {
        if (localStorage.getItem('set_button_true') === 'true') {
          $('.modal').modal('hide');
          localStorage.setItem('set_button_true', 'false');
          var url = localStorage.getItem('set_url');
          if (determinant !== null && determinant !== '' && determinant === 'GOAL_WEIGHT') {
            $('#alert_content').modal('hide');
            $('#set_goal_weight_popup').modal('show');
            var curr_val = window.localStorage.getItem('progres_current_weight');
            var final_curr_val;
            if (curr_val % 1 === 0) {
              final_curr_val = curr_val;
            } else {
              final_curr_val = parseFloat(curr_val).toFixed(2);
            }
            $(".set_current").val(final_curr_val);
            var goal_val = window.localStorage.getItem('progres_goal');
            var final_goal_val;
            if (goal_val % 1 === 0) {
              final_goal_val = goal_val;
            } else {
              final_goal_val = parseFloat(goal_val).toFixed(2);
            }
            $(".set_goal").val(final_goal_val);

            $('.confirm_goal_h6').text(window.keystrings['com.numi.journal.stage_options']);
            $('.confirm_goal_h3').text(window.keystrings['com.numi.goal_weight_label']);
            $('#set_goal_weight_submit').text(window.keystrings['com.numi.continue']);
            window.localStorage.setItem('transition_url_goal_weight', url);

          } else if (determinant === "LOW_BLOOD_SUGAR") {
            window.localStorage.setItem('transition_url_goal_weight', url);
            $('#alert_content').modal('hide');
            setTimeout(() => {
              $('#low_blood_sugar').modal('show');
            }, 999);
          }
          else if (determinant === "ACTIVITY_LEVEL") {
            window.localStorage.setItem('transition_url_goal_weight', url);
            $('#alert_content').modal('hide');
            setTimeout(() => {
              $('#activity_level_content').modal('show');
            }, 999);
            $('.success_phase').attr('id', determinant);
            $('#less_than_60').val(alert_response.values[0]);
            $('#equal_60').val(alert_response.values[1]);
            $('#greater_than_60').val(alert_response.values[2]);
            $('#' + determinant).find('input').each(function () {
              if (alert_response.default !== '') {
                if ($(this).attr('name') === alert_response.default) {
                  $(this).next().css('opacity', 1);
                  $(this).prop('checked', true);
                }
                else {
                  $(this).next().css('opacity', '0.4');
                  $(this).prop('checked', false);
                }
              }
            });
          }
          else if (determinant === "TURBO_TAKEOFF") {
            window.localStorage.setItem('transition_url_goal_weight', url);
            var freshstart_replace = alert_response.misc_data.is_first_week_turbo.replace('FreshStart', '');
            $('.turbo_week_key').html(freshstart_replace);
            $('.turbo_off_ends_key').text(alert_response.misc_data.turbo_takeoff_ends);
            $('#alert_content').modal('hide');
            $('.turbo_phase_option').attr('id', determinant);
            setTimeout(() => {
              $('#turbo_takeoff_content').modal('show');
            }, 1000);
          }
          else if (determinant === "CIPD_RANGE") {
            $('#activity_questions .modal-header').show();
            $('.text-green-darker').find('img').each(function () {
              if ($(this).parent().hasClass('active')) {
                $(this).attr('src', $(this).attr('active_image'));
                $(this).parent().css('opacity', 1).addClass('active');
              }
              else {
                $(this).attr('src', $(this).attr('normal_image'));
                $(this).parent().css('opacity', 0.3).removeClass('active');
              }
            });
            $('#activityGoal .row > div').hide();
            $('#activityGoal .row > div:eq(1)').show();
            $('.calculation,.buffer').hide();
            $('#activity_questions').modal('show');
            setTimeout(function () {
              $('body').addClass('modal-open');
            }, 1200);
          }
          else {
            $('#alert_content').modal('hide');

          }
        }
        else {

          $('#personalize_div').hide();
          $('#change_plan .modal-header').show();


          if (determinant === "LOW_BLOOD_SUGAR") {
            $('.confirm_goal_weight,.plan_popup,.success_phase,.turbo_phase_option').hide();
            $('.modal-body').removeClass('calender_fix');
            $('.low_blood_sugar').show();
          }

          else if (determinant === "GOAL_WEIGHT") {
            $('.confirm_goal_weight,.plan_popup,.success_phase,.turbo_phase_option,.low_blood_sugar').hide();
            $('.modal-body').removeClass('calender_fix');
            $('.confirm_goal_weight').show();
            $('.confirm_goal_weight h3').text(window.keystrings['com.numi.goal_weight_label']);
            //$('.confirm_goal_weight h6').text('Phase Options');
            $('.confirm_goal_weight h6').text(window.keystrings['com.numi.journal.stage_options']);
            $('.confirm_goal_weight button').text(window.keystrings['com.numi.continue']);
          }

          else if (determinant === "ACTIVITY_LEVEL") {
            $('.plan_popup').hide();
            $('.success_phase').show();
            $('#change_plan .success_phase').attr('id', determinant);
            $('#less_than_60').val('LESS_THAN_60_MINUTES');
            $('#equal_60').val('60_MINUTES');
            $('#greater_than_60').val('GREATER_THAN_60_MINUTES');

            $('#change_plan #' + determinant).find('input').each(function () {
              if (props.getData.transition_determinant.default !== '') {
                if ($(this).val() === props.getData.transition_determinant.default) {
                  $(this).next().css('opacity', 1);
                  $(this).prop('checked', true);
                }
                else {
                  $(this).next().css('opacity', '0.4');
                  $(this).prop('checked', false);
                }
              }
            });

          }
          else if (determinant === "TURBO_TAKEOFF") {

            $('.confirm_goal_weight,.plan_popup,.success_phase').hide();
            $('.turbo_phase_option').attr('id', determinant);
            $('.turbo_phase_option').show();
            $('.modal-body').addClass('calender_fix');
            $('.first_week_text').html(props.getData.transition_determinant.misc_data.is_first_week_turbo.replace('FreshStart', ''));
            $('.turbo_takeoff_ends_text').text(props.getData.transition_determinant.misc_data.turbo_takeoff_ends);
          }
          else if (determinant === "CIPD_RANGE") {
            setTimeout(function () {
              $('#change_plan').modal('hide');
              $('#activity_questions .modal-header').show();
              $('.text-green-darker').find('img').each(function () {
                if ($(this).parent().hasClass('active')) {
                  $(this).attr('src', $(this).attr('active_image'));
                  $(this).parent().css('opacity', 1).addClass('active');
                }
                else {
                  $(this).attr('src', $(this).attr('normal_image'));
                  $(this).parent().css('opacity', 0.3).removeClass('active');
                }
              });
              $('#activityGoal .row > div').hide();
              $('#activityGoal .row > div:eq(0)').show();
              $('.calculation,.buffer').hide();
              $('#activity_questions').modal('show');
              localStorage.removeItem('nutrisystem_stage');
            }, 800);
            setTimeout(function () {
              $('body').addClass('modal-open');
            }, 1200)
          }
        }
      }
    }
  }
  const quiz_list = props.quiz_questions;
  const toggleCheck = () => {
    setstate(!isChecked)
  }


  const mainBack = (e) => {

    var evt = document.createEvent("CustomEvent");
    if (document.querySelector("div[type='BODY_TYPE']") && document.querySelector("div[type='BODY_TYPE']").classList.contains('active')) {
      evt.initCustomEvent('QuizWeightLoss', true, false, false);
      document.dispatchEvent(evt);
    }
    else if (document.querySelector("div[type='NEAT']") && document.querySelector("div[type='NEAT']").classList.contains('active')) {
      evt.initCustomEvent('QuizBodyType', true, false, false);
      document.dispatchEvent(evt);
    }
    else if (document.querySelector("div[type='ACTIVITY']") && document.querySelector("div[type='ACTIVITY']").classList.contains('active')) {
      evt.initCustomEvent('QuizDailyLifestyle', true, false, false);
      document.dispatchEvent(evt);
    }
    if (e.target.getAttribute('id') < 1) {
      document.querySelector('.quiz_back').style.display = 'none';
      props.callQuiz();
    }
    else {

      var current_element = document.querySelector('.ques_count.active').previousElementSibling.getAttribute('type');
      document.querySelector('a#list' + (e.target.getAttribute('id') - 1)).click();
      e.target.setAttribute('id', e.target.getAttribute('id') - 1);
      if (document.querySelector('.ques_count.active').previousElementSibling.querySelector('.footer_check')) {
        var current_key = document.querySelector('.ques_count.active').previousElementSibling.querySelector('.footer_check').getAttribute('get_key');
        props.questionArray.forEach(function (element, i) {
          if (props.questionArray[i].key === current_key) {
            props.questionArray.splice(i, 1);
          }
        });
        props.questionArray.forEach(function (element, i) {
          if (props.questionArray[i].key === current_element) {
            props.questionArray.splice(i, 1);
          }
        })
      }
      else {
        props.questionArray.forEach(function (element, i) {
          if (props.questionArray[i].key === current_element) {
            props.questionArray.splice(i, 1);
          }
        })
      }

    }

    if (e.target.getAttribute('id') < 1) {
      if (window.location.href.indexOf('/progress') > -1 || window.location.href.indexOf('/journal') > -1) {
        document.querySelector('.quiz_back').style.display = 'none';
      }
      else {
        document.querySelector('.quiz_back').style.display = 'block';

      }
    }

  }




  return (
    <>

      {!finish_quiz ? <div className="quiz_section">
        <img alt="" src="/assets/images/back_white_icon.png" align="left" onClick={mainBack} className="cursor_pointer quiz_back" /><br /><br />
        <Tabs defaultActiveKey="list0" className="quiz_id">
          {
            quiz_list.map((list, _n) => <Tab eventKey={'list' + _n} title={_n + 1} className={quiz_list.length > 2 ? "ques_count quiz_" + _n + "" : 'ques_count'} type={list.key} key={_n} value={'list' + _n} id={'list' + _n} >

              {
                <div className="quiz_content">

                  <h3 className="quiz_hdr ptb10">{list.description}</h3>
                  <p className='font16 text-center ml18' style={{ display: (list.key === 'ACTIVITY') ? 'block' : 'none' }}>{window.keystrings['com.numi.cd.smartadapt.exercisehabit.note']}</p>
                  <div className="width77">
                    {list.answers.map((txt, i) =>
                      <>
                        <div key={i} code={txt.code} onClick={selectWeight} className={txt.default === true ? "questions_list active" : (txt.header ? 'questions_list' : 'questions_list quiz_flex_div')}>
                          {txt.header ? (txt.default === true ? <div><img src={txt.icon} alt="" /></div> : <div><img src={txt.icon} alt="" /></div>) : ''}<div className="text-left no_header ml13px color3 font14">{txt.header ? <p className="margin0 font16 padding0">{txt.header}</p> : ''}  {txt.body}</div>

                        </div>

                      </>
                    )}
                  </div>
                  {list.footer && list.footer.body && list.footer.checkbox ? <p className="remember_me mt20px text-center">
                    <label className="relative">
                      {
                        list.footer.default === "true" ? <input type="checkbox" className="footer_check" checked={!isChecked} get_key={list.footer.key} value={!isChecked} onChange={() => toggleCheck()} /> : <input type="checkbox" className="footer_check" checked={isChecked} get_key={list.footer.key} value={isChecked} onChange={() => toggleCheck()} />
                      }

                      <span className="checkmark"></span>
                      {list.footer.body}
                    </label>
                  </p> : <div className="font18 mt20px">{list.footer.body}</div>
                  }
                  <button type={list.key} onClick={((e) => clickNext(e, _n + 1))}>Next</button>

                </div>
              }
            </Tab>
            )
          }
        </Tabs>


      </div> : <div className="finish_quiz width77">
        <img src="/assets/images/finished.svg" alt="" title="" />
        <h3 className="mt15" dangerouslySetInnerHTML={{ __html: window.keystrings['com.numi.you_are_all_set_title'] }}></h3>
        <h5 className="mt15" dangerouslySetInnerHTML={{ __html: window.keystrings['com.numi.you_are_all_set_desc'] }}></h5>
        <button className="width77" onClick={endQiuz}>Continue</button>
      </div>
      }

    </>
  );
}


export const Carousel = (props) => {
  const history = useHistory();
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);
  const [checkPlans, setPlans] = useState(false);
  const [showChoose, choosePlans] = useState(false);
  const [plan_list, setPlanList] = useState('');
  const [toggleLogoutPopup, showLogoutPopup] = useState(false);
  const [noPlanYetModal, setnoPlanYetModal] = useState(false);
  const [planHelpModal, setPlanHelpModal] = useState(false);

  /*const [categoryName, setCatName] = useState('');*/

  useEffect(() => {

    setNav1(slider1);
    setNav2(slider2);

  }, [slider1, slider2]);
  useEffect(() => {
    if (window.location.href.indexOf('/ns_plan') > -1) {

      $('#plan_list_header').removeClass('hide_arrows');
      $('#plan_list_header').addClass('hide_arrows_ns_plan');
    }
  }, []);


  const settingsMain = {
    className: "center",
    slidesToShow: 1,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerSpacing: "60px",
    dots: true,
    initialSlide: 1,
  };


  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    slidesToShow: 1,
    speed: 500,
    centerSpacing: "60px",
    dots: true,
    swipeToSlide: true,
    focusOnSelect: true,
  };
  const settingsThumbs = {
    className: "slider center",
    dots: false,
    infinite: true,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    arrows: false,
    swipeToSlide: true,
    focusOnSelect: true,
    initialSlide: 1
  };
  const selectedPlan = (e, smartAdapt) => {
    localStorage.setItem('smartAdapt', smartAdapt);
    localStorage.setItem('restart_plan_phase_id', 1);
    if (window.location.href.indexOf('/ns_plan') > -1) {
      var key;
      var value;;
      var profile_properties_array;
      $('#ns_plan_main').addClass('ns_plan_select_plan');
      if ($('.ns_select_plan').is(':visible')) {
        key = "PLAN_KEY";
        value = e.target.getAttribute('name');
        profile_properties_array = [{ "key": key, "value": value }]
      }
      else {
        key = $('.phase_option .next_determinant').attr('id');
        if (key === undefined) {
          key = window.localStorage.getItem('set_key');
        }
        value = $('.toggle_options').find('input[type="radio"]:checked').attr('class');
        profile_properties_array = [{ "key": key, "value": value }]
        if (key === "TURBO_TAKEOFF") {
          var evt = document.createEvent("CustomEvent");
          evt.initCustomEvent('TapTurboTakeoff', true, false, false);
          document.dispatchEvent(evt);
        }
        if (key === "TURBO_TAKEOFF" && value === "true") {
          key = "END_DATE";
          value = moment(parse($('.turbo_takeoff').find('.date_pick input').val())).format('YYYY-MM-DD');
          profile_properties_array.push({ "key": key, "value": value })
        }

      }
      props.getActivityQuestions.activityApi();


      var profile_properties_params = { "profile_properties": profile_properties_array };
      Header.startLoader(); // start loader


      const options = {
        url: window.API_URL + "v1/profile_properties",
        method: 'POST',
        credentials: 'same-origin',
        headers: Header.setAuthHeaders(),
        data: profile_properties_params
      };

      axios(options)
        .then(response => {
          Header.stopLoader(); // stop loader
          var evt = document.createEvent("CustomEvent");
          evt.initCustomEvent('SmartAdaptPitch', true, false, false);
          document.dispatchEvent(evt);
          if (smartAdapt === false) {
            props.getcallDeterminantAPI()
          }
          else {
            setnoPlanYetModal(false);
            $('.category_list,.hide_arrows').hide();

            $('#personalize_div').show(); // need to call mealDeterminantAPI(); based oncondition
          }
        })
        .catch(error => {
          Header.stopLoader(); // stop loader
          Header.apiErrors(error);
          Sentry.captureException(error);
        });

    }
    else {
      localStorage.setItem('plan_id', e.target.getAttribute('id'));
      localStorage.setItem('plan_name', e.target.getAttribute('name'));
      if (localStorage.getItem('smartAdapt') === 'true') {
        setnoPlanYetModal(false);
        $('.category_list').hide();

        $('#personalize_div,.change_plan_link').show();
      }
      else {
        props.callDeterminant()
      }

    }
  }


  const showList = (e) => {
    Header.startLoader();
    $('.thumbnail-slider-wrap').find('[category_id=' + e.target.getAttribute("id") + ']').click();
    choosePlans(false);
    setPlans(true);
    if (window.location.href.indexOf('/ns_plan') > -1) {

      $('#plan_list_header').removeClass('hide_arrows');
      $('#plan_list_header').addClass('hide_arrows_ns_plan');
    }
    document.querySelector('#select_plan').style.display = 'none';
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);
    setTimeout(() => {
      $('.thumbnail-slider-wrap').css('opacity', 1);
      $('.slick-current').focus();

      Header.stopLoader();
    }, 999);
    switch (e.target.getAttribute("id")) {
      case "1":
        localStorage.setItem('smartAdaptEventLabel', 'Select - Home-Delivery Meal Plan');
        break;
      case "2":
        localStorage.setItem('smartAdaptEventLabel', 'Select - QVC and HSN Plans');
        break;
      case "3":
        localStorage.setItem('smartAdaptEventLabel', 'Select - Nutrisystem Success');
        break;
      case "4":
        localStorage.setItem('smartAdaptEventLabel', 'Select - 5 Day Weight Loss Kit');
        break;
      case "5":
        localStorage.setItem('smartAdaptEventLabel', 'Select - Body Select');
        break;
      case "6":
        localStorage.setItem('smartAdaptEventLabel', 'Select - DNA Body Blueprint');
        break;
      case "7":
        localStorage.setItem('smartAdaptEventLabel', 'Select - View All Plans');
        break;
      default:
        break;
    }
    var evt = document.createEvent("CustomEvent");
    evt.initCustomEvent('SmartAdaptTapEvents', true, false, false);
    document.dispatchEvent(evt);
  }

  const moreDetails = (e) => {
    document.querySelector('#select_plan').style.display = 'block';
    document.querySelector('#select_plan').getElementsByClassName('selected_plan')[0].setAttribute('id', e.target.getAttribute('id'));
    var get_id = e.target.getAttribute('id');
    switch (get_id) {
      case "1":
        localStorage.setItem('smartAdaptEventLabel', 'More Details - Home-Delivery Meal Plan');
        break;
      case "2":
        localStorage.setItem('smartAdaptEventLabel', 'More Details - QVC and HSN Plans');
        break;
      case "3":
        localStorage.setItem('smartAdaptEventLabel', 'More Details - Nutrisystem Success');
        break;
      case "4":
        localStorage.setItem('smartAdaptEventLabel', 'More Details - 5 Day Weight Loss Kit');
        break;
      case "5":
        localStorage.setItem('smartAdaptEventLabel', 'More Details - Body Select');
        break;
      case "6":
        localStorage.setItem('smartAdaptEventLabel', 'More Details - DNA Body Blueprint');
        break;
      case "7":
        localStorage.setItem('smartAdaptEventLabel', 'More Details - View All Plans');
        break;
      default:
        break;
    }
    var evt = document.createEvent("CustomEvent");
    evt.initCustomEvent('SmartAdaptTapEvents', true, false, false);
    document.dispatchEvent(evt);
    /*setCatName(e.target.getAttribute('name'))*/

    setPlanList(props.categoryContent.map((option, i) =>

      <>
        {(get_id === (option.id).toString()) ? <div key={i} dangerouslySetInnerHTML={{ __html: option.description }}></div> : ''}

      </>

    ))

  }

  const backToCategory = () => {
    choosePlans(true);
    setPlans(false);
    var evt = document.createEvent("CustomEvent");
    evt.initCustomEvent('SelectPlanCategoryStep1', true, false, false);
    document.dispatchEvent(evt);
  }

  const selectDefaultSlide = () => {
    $('.thumbnail-slider-wrap').find('[category_id=18').click();
  }
  const backToPrev = () => {

    if (window.location.href.indexOf('/ns_plan') > -1) {
      showLogoutPopup(true);
    }
    else {
      $('.modal').modal('hide');
    }
  }

  const closeSelectPlan = () => {
    document.querySelector('#select_plan').style.display = 'none';
  }

  const hideLogoutPopup = () => {
    showLogoutPopup(false)
  }



  const hideNoPlanYetPopup = () => {
    setnoPlanYetModal(false)
  }

  const hidePlanHelpModal = () => {
    setPlanHelpModal(false)
  }

  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    history.push('/login');
  }

  return (

    <>

      <Modal show={toggleLogoutPopup} onHide={hideLogoutPopup} id="logout_popup">
        <Modal.Header>
          <img alt="" src="/assets/images/close_grey.png" className="cursor_pointer" onClick={hideLogoutPopup} />
        </Modal.Header>
        <Modal.Body>
          <img alt="" src="/assets/images/warning_tri.png" />
          <p className="font20 color6 mt20px">You will be logged out. You can complete your profile next time you login</p>
          <p><button onClick={logout} >Ok</button><button onClick={hideLogoutPopup} >Cancel</button></p>
        </Modal.Body>
      </Modal>

      <div style={{ 'display': showChoose ? 'block' : 'none' }} className="nutri_link padding0">
        <div className="plr20 hide_arrows relative">
          <img alt="" src="/assets/images/back_white_icon.png" className="pull-left cursor_pointer" onClick={backToPrev} />
          <span data-target="#help_plan" data-toggle="modal" className="cursor_pointer gray_border">?</span>
        </div>
        <div className="slick slider-wrapper padding0">

          <div className="plan_slider">
            <h3 dangerouslySetInnerHTML={{ __html: window.keystrings['com.numi.onboarding_category_header'] }}></h3>
            <h6 dangerouslySetInnerHTML={{ __html: window.keystrings['com.numi.onboarding_category_description'] }}></h6>


          </div>


        </div>


      </div>
      <div style={{ 'display': checkPlans ? 'block' : 'block' }} className="plans_list category_list" id="select_plan_new">
        <div className="hide_arrows relative d-flex justify-content-between align-items-center" id="plan_list_header">
          <img alt="" src="/assets/images/back_black_icon.png" className="pull-left cursor_pointer" onClick={backToPrev} />
          <h3 className="category_plan_new" dangerouslySetInnerHTML={{ __html: window.keystrings['com.numi.onboarding_category_header_new'] }}></h3>
          {/* <span data-target="#help_plan" data-toggle="modal" className="cursor_pointer gray_border">?</span> */}
          <img alt="" src="/assets/images/help_black_icon.png"
            onClick={() => setPlanHelpModal(true)} className="pull-right cursor_pointer" />
        </div>
        <div className="slider-wrapper slider_wrapper_new">
          <h5 className="section_headers_new" dangerouslySetInnerHTML={{ __html: window.keystrings['com.numi.onboarding_category_header_featured_plans_new'] }}></h5>
          <div className="container">
            <div className="row category_plan_new mb18">
              {
                props.categoryContent.map((slide, i) =>

                  slide.key === 'SHORTCUTS' ?
                    slide.plans.map((featured, i) =>


                      <div className="select_btn_wrap" key={i}>
                        <button className="cursor_pointer select_btn" id={featured.id} key={i} name={featured.key}
                          // onClick={showList}
                          onClick={(e) => selectedPlan(e, featured.smart_adapt)}
                        >{featured.short_name}</button>
                      </div>


                    )
                    : ""

                )
                // selectDefaultSlide;
              }
            </div>

          </div>
          <h5 className="section_headers_new" dangerouslySetInnerHTML={{ __html: window.keystrings['com.numi.onboarding_category_header_more_plans_new'] }}></h5>
          <div className="thumbnail-slider-wrap">
            {props.categoryContent.length > 0 &&
              <Slider  {...settingsThumbs} asNavFor={nav1}
                onInit={() => document.querySelectorAll(".slick-slide")[2].click()}

                ref={slider => (setSlider2(slider))}>
                {props.categoryContent.map((slide) =>
                  // remove shortcust from ribbon
                  slide.key != 'SHORTCUTS' && slide.key != 'NO PLAN' ?
                    <div className="slick-slide" category_id={slide.id} key={slide.id}>
                      {slide.name}
                    </div> : ""
                )}
              </Slider>}
          </div>

          <div className="main_image select_plan_new">

            <Slider {...settingsMain} asNavFor={nav2} ref={slider => (setSlider1(slider))}


            >

              {props.categoryContent.map((slide, i) =>
                slide.key != 'SHORTCUTS' && slide.key != 'NO PLAN' ?
                  <div className="plans_data" key={i}>
                    {slide.plans.map((plans, i, arr) =>
                      <div className="slick-slide" key={i} name={plans.key} id={plans.id} onClick={(e) => selectedPlan(e, plans.smart_adapt)}>
                        {plans.name}
                      </div>
                    )}
                  </div> : ""
              )}
            </Slider>
          </div>
          <div className="category_footer_button_div">
            <button onClick={() => setnoPlanYetModal(true)} className="category_footer_button_new cursor_pointer" dangerouslySetInnerHTML={{ __html: window.keystrings['com.numi.onboarding_category_footer_button_new'] }}>
            </button>
          </div>
        </div>
      </div>

      <div id="select_plan">
        <div className="mt15 select_plan_details">
          {plan_list}
          <button className="width50 selected_plan  margin0" onClick={showList} data-dismiss="modal">Select</button>
          <div className="plan_close" onClick={closeSelectPlan}>Close</div>
        </div>
      </div>

      <div className="modal fade" id="help_plan">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body width90 mt15">
              <img src="/assets/images/Info_icon_teal.svg" alt="" />
              <h3 className="mt15" dangerouslySetInnerHTML={{ __html: window.keystrings['com.numi.text_need_help'] }}></h3>
              <h5 className="width77 color6" dangerouslySetInnerHTML={{ __html: window.keystrings['com.numi.nutrisystem_plan_need_help_description_web'] }}></h5>
              <button className="width77" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="help_cipd">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body width90 mt15">
              <img src="/assets/images/personalize_icon.svg" alt="" title="" />
              <h3 className="mt15" dangerouslySetInnerHTML={{ __html: window.keystrings['com.numi.smart_adapt_help_header'] }}></h3>
              <h5 className="mt15" dangerouslySetInnerHTML={{ __html: window.keystrings['com.numi.smart_adapt_help_description_web'] }}></h5><br />
              <p className="help_points_web" dangerouslySetInnerHTML={{ __html: window.keystrings['com.numi.smart_adapt_help_points_web'] }}></p>
              <button className="width77" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>

      <div className="btn-sticky-prv" id="btn-sticky-prv"></div>
      <div className="btn-sticky-nxt" id="btn-sticky-nxt"></div>



      <Modal show={planHelpModal} onHide={hidePlanHelpModal} id="help_plan_new">
        <Modal.Body>
          <img src="/assets/images/Info_icon_teal.svg" alt="" />
          <h3 className="mt15 mb20px" dangerouslySetInnerHTML={{ __html: window.keystrings['com.numi.text_need_help'] }}></h3>
          <h5 className="width77 color6" dangerouslySetInnerHTML={{ __html: window.keystrings['com.numi.nutrisystem_plan_need_help_description_web_new'] }}></h5>
          <h5 className="width77 color6" dangerouslySetInnerHTML={{ __html: window.keystrings['com.numi.nutrisystem_plan_need_help_description_web_note_new'] }}></h5>
          <button className="width77"
            onClick={hidePlanHelpModal}>Close</button>
        </Modal.Body>
      </Modal>



      {props.categoryContent.map((slide, i, arr) => {
        return (
          <>
            {arr.length - 1 === i ?
              <Modal show={noPlanYetModal} onHide={hideNoPlanYetPopup} id="no_plans_yet">
                <Modal.Body key={i}>
                  {slide.plans.map((plans, j) => {
                    return (
                      <div className="modal-content">
                        <div className="modal-body width90 mt15">
                          <img src="/assets/images/Info_icon_teal.svg" alt="" />
                          <h3 className="mt15 mb20px" dangerouslySetInnerHTML={{ __html: window.keystrings['com.numi.onboarding_category_header_no_plans_yet'] }}></h3>
                          <h5 className="width77 color6" dangerouslySetInnerHTML={{ __html: window.keystrings['com.numi.onboarding_category_text_noplan_noproblem_new'] }}></h5>
                          <h5 className="width77 color6" dangerouslySetInnerHTML={{ __html: window.keystrings['com.numi.onboarding_category_text_noplan_noproblem_note_new'] }}></h5>
                          <button className="width77 mb5"
                            onClick={hideNoPlanYetPopup}
                          >Go Back</button>
                          <button key={j} name={plans.key} id={plans.id} onClick={(e) => {
                            selectedPlan(e, plans.smart_adapt)
                            hideNoPlanYetPopup()
                          }} className="width77" data-dismiss="modal">Continue</button>
                        </div>

                      </div>

                    )
                  }
                  )}
                </Modal.Body>
              </Modal> : ""}</>
        )
      }
      )}




    </>
  );
}


export default PersonalizePlanMode;
