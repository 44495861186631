
import React from 'react';
import { Link } from 'react-router-dom'
import Footer from "../footer.js";
import Header from "../header.js";
import Navigation from "../nav.js";
import Adspeed from "../adspeed.js";
import axios from 'axios';
import $ from 'jquery';
import * as Sentry from "@sentry/react";

class CommunicationSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isChecked: true, communication: '', receive_emails: '' };
    this.rememberCheckbox = this.rememberCheckbox.bind(this);
    this.clickOk = this.clickOk.bind(this);
    this.getComunicationAPI = this.getComunicationAPI.bind(this);
  }

  getComunicationAPI() {
    const options = {
      url: window.API_URL + "v1/communication",
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        if (response && response.data) {
          this.setState({ communication: response.data })
          this.setState({ receive_emails: this.state.communication.communication.receive_emails });
          if (this.state.receive_emails === true) {
            this.setState({ isChecked: true });
          } else {
            this.setState({ isChecked: false });
          }
        }
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });
  }

  rememberCheckbox(e) {
    if (this.state.isChecked === true) {
      $('#communication_popup').modal('show');
    } else {
      this.clickOk('true')
    }
  }

  clickOk(e) {
    var communication_params = { "communication": { "receive_emails": e } };

    const options = {
      url: window.API_URL + "v1/communication",
      method: 'PUT',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
      data: communication_params
    };

    axios(options)
      .then(response => {
        Header.stopLoader();
        this.setState({ isChecked: e });
        this.getComunicationAPI();
        $('#communication_popup').modal('hide');
        $('.modal-backdrop').remove();
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });
  }

  Uncheckbox(e) {
    this.clickOk('false')
    e.stopPropagation();
  }

  componentDidMount() {
    // On load calling getComunicationAPI
    this.getComunicationAPI();
    /*keystrings*/
    $('.back_to_settings').text(window.keystrings['com.numi.setting.back_to_settings']);
    $('.com_settings_title').text(window.keystrings['com.numi.communication_settings_title']);
    $('.email_notification_text').text(window.keystrings['com.numi.communication_settings.email_notifications']);
    $('.email_notification_note_text').text(window.keystrings['com.numi.communication_settings.note'])
    $('.com_settings_are_you_sure').text(window.keystrings['com.numi.are_you_sure']);
    $('.com_settings_cancel').text(window.keystrings['com.numi.cancel']);
    $('.com_settings_turnoff').text(window.keystrings['com.numi.communication_setting.turnoff']);
    $('.com_setting_body_content').text(window.keystrings['com.numi.turn_off_communications']);
    /*keystrings*/
  }

  render() {

    return (
      <div>

        <div className="table_display">
          <div className="journal_div settings">
            <div className="section_left pull-left">
              <Navigation />
            </div>
            <div className="section_right pull-left">
              <figure><Adspeed /></figure>
              <div className="journal_rht communication">
                <p className="mb">
                  <Link to="/settings" className="back_to"><img src="/assets/images/back_white_icon.png" alt="" className="vlb" /> <span className="back_to_settings">Back to Settings</span></Link>
                </p>
                <h1 className="journal_hdr pull-left com_settings_title">Communication Settings</h1>

                <div className="clear"></div>
                <hr />
                <div className="panel-group meal_section_left pull-left text-center">
                  <div className="profile_div_sec">
                    <div className="text-left">
                      <p className="remember_me">
                        <input id="remember_check" type="checkbox" checked={this.state.isChecked} onChange={this.rememberCheckbox} />
                        <label htmlFor="remember_check">
                          <span className="bullet_icon email_notification_checkbox">
                            <i></i>
                          </span>
                          <span className="font16 color6 email_notification_text">
                          </span>
                          <br /><br />
                        </label>
                        <p className="color6 email_notification_note_text text-left"></p>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
          <CommunicationPopup checkboxUncheck={this.Uncheckbox.bind(this)} />
        </div>
      </div>
    )
  }
}

class CommunicationPopup extends React.Component {
  componentDidMount() { }
  render() {
    return (
      <div className="modal fade" id="communication_popup">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><img alt="" src="/assets/images/close_grey.png" /></button>
            </div>
            <div className="modal-body">
              <img src="/assets/images/warning_tri.png" alt="" />
              <p className="font26 color3 mt5 font700 com_settings_are_you_sure"></p>
              <h6 className="mt com_setting_body_content"> </h6>
              <p className="mt6" data-dismiss="modal" ><button className="com_settings_cancel"></button></p>
              <p><button className="turn_off com_settings_turnoff" onClick={this.props.checkboxUncheck}></button></p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default CommunicationSettings;
