import React from 'react';
import { Link } from 'react-router-dom'
import Footer from "../footer.js";
import $ from 'jquery';
class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.state = { email: '', password: '', gender_radio: '', disable_btn: 'btn disabled', disable: 'disabled', firstName: '', lastName: '' };
    this.radio_toggle = this.radio_toggle.bind(this);
  }

  radio_toggle(e) {

  }

  signUp() {
    window.localStorage.setItem('FirstName', this.state.firstName);
    window.localStorage.setItem('LastName', this.state.lastName);
    window.localStorage.setItem('gender', $('.gender_img').find('input:checked').attr('id'));

    var email = window.localStorage.getItem('current_user_email');
    var user_token = window.localStorage.getItem('user_access');
    if (window.localStorage.getItem('nutriSignup') === 'true') {
      this.props.history.push('/profile')
    }
    else {
      if (email === null || user_token === null) {
        var evt = document.createEvent("CustomEvent");
        evt.initCustomEvent('AccountDetails', true, false, false);
        document.dispatchEvent(evt);
        this.props.history.push('/account_details');
      } else {
        this.props.history.push('/profile')
      }
    }
  }

  firstNameChange(e) {
    this.setState({ firstName: e.target.value });
  }

  lastNameChange(e) {
    this.setState({ lastName: e.target.value });
  }

  componentDidMount() {
    $('.modal-backdrop,.fade_link_popup').remove();
    $('body').removeClass('modal-open');
    var profileData = JSON.parse(window.localStorage.getItem('profileData'));
    if (profileData !== null) {
      var firstName = profileData.first_name;
      var lastName = profileData.last_name;
      var gender = profileData.gender;
      this.setState({
        firstName: firstName,
        lastName: lastName
      });
      if (gender === 'female' || gender === null) {
        $('#female').click();
        $('.women span').removeClass('women_normal').addClass('women_active')
      }
      else {
        $('#male').click();
        $('.men span').removeClass('men_normal').addClass('men_active')
      }
      if (firstName === null || lastName === null || gender === null) {
        $('#sign_continue').addClass('btn disabled').attr('disabled', true);
      }
      else {
        $('#sign_continue').removeClass('btn disabled').removeAttr('disabled');

      }
    }

    $('.men').click(function () {
      handleMenClick($(this))
    })

    $('.women').click(function () {
      handleWomenClick($(this));
    });

    function handleMenClick(element) {
      if (element.find('span').hasClass('men_normal')) {
        element.find('span').removeClass('men_normal').addClass('men_active');
        element.parents('.gender_img').find('span.women_active').removeClass('women_active').addClass('women_normal');
      }
    }

    function handleWomenClick(element) {
      if (element.find('span').hasClass('women_normal')) {
        element.find('span').removeClass('women_normal').addClass('women_active');
        element.parents('.gender_img').find('span.men_active').removeClass('men_active').addClass('men_normal');
      }
    }

    $(document).on('keyup change', '.field_mandatory,[name="gender"]', function () {
      var $emptyFields = $('.field_mandatory').filter(function () {
        return $.trim(this.value) === "";
      });
      if (!$emptyFields.length && ($('#male').is(':checked') || $('#female').is(':checked'))) {
        $('#sign_continue').removeClass('btn disabled').removeAttr('disabled');
      }
      else {
        $('#sign_continue').addClass('btn disabled').attr('disabled', true);
      }
    });

    /*keystrings*/
    $('.sign_up_select_gender_title_text').text(window.keystrings['com.numi.sign_up_select_gender_title'])
    $('.continue_text').text(window.keystrings['com.numi.continue'])
    $('.hello_text').text(window.keystrings['com.numi.hello'])
    $('.setting_your_account_text').text(window.keystrings['com.numi.setting_your_account'])
    $('.profile_first_name_text').attr('placeholder', window.keystrings['com.numi.profile_first_name'])
    $('.profile_last_name_text').attr('placeholder', window.keystrings['com.numi.profile_last_name_text'])
    /*keystrings*/

  }
  render() {

    return (
      <div className="main_container">

        <div className="forgot_password_hero">
          <div><img alt="" src="/assets/images/numi_small_logo.png" className="logo img-responsive" /></div>
          <div className="signup_sec relative">
            <Link to="/new_signup"> <img alt="" src="/assets/images/back_white_icon.png" className="left_arrow" /></Link>
            <h1 className="hello_text"> </h1>
            <h5 className="hdr_txt setting_your_account_text"> </h5>
            <form onSubmit={this.signUp.bind(this)}>
              <div className='email_box'><input type="text" onChange={this.firstNameChange.bind(this)} maxLength="40" value={this.state.firstName} className="field_mandatory profile_first_name_text" placeholder="" /></div>
              <div className='email_box mt'><input type="text" className="field_mandatory profile_last_name_text" onChange={this.lastNameChange.bind(this)} maxLength="40" value={this.state.lastName} placeholder="Last Name" /></div>
              <hr />
              <h5 className="hdr_txt sign_up_select_gender_title_text"> </h5>
              <p className="gender_img">
                <span className="men">
                  <input className="" type="radio" id="male" name="gender" />
                  <label htmlFor="male"><span className="men_normal"></span></label>
                </span>
                <span className="women">
                  <input className="" type="radio" id="female" name="gender" />
                  <label htmlFor="female"><span className="women_normal"></span></label>
                </span>
              </p>
              <input type="submit" value="Continue" id="sign_continue" className="continue_text btn disabled" disabled />
            </form>
          </div>
        </div>
        <Footer />
      </div>

    );
  }
}
export default Signup;
