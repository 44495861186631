import React from 'react';
import { Link } from 'react-router-dom'
import Header from "../header.js";
import Footer from "../footer.js";
import axios from 'axios';
import moment from 'moment';
import Login from "./login.js";
import $ from 'jquery';
import * as Sentry from "@sentry/react";

class NewSignup extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showNutri: false, nutri_email: '', nutri_password: '', errorCode: '', toggleFade: false }
    this.showNutriAccount = this.showNutriAccount.bind(this);
    this.emailChange = this.emailChange.bind(this);
    this.passwordChange = this.passwordChange.bind(this);
    this.handleNutriSignupClick = this.handleNutriSignupClick.bind(this);
  }

  showNutriAccount() {
    this.setState({
      showNutri: true
    });
    $('body').append('<div class="fade_link_popup"></div>');
  }
  clickClose() {
    this.setState({
      showNutri: !this.state.showNutri
    });
    $('.fade_link_popup').remove();
  }
  togglePassword(e) {
    if (e.target.previousSibling.getAttribute('type') === 'password') {
      e.target.previousSibling.setAttribute('type', 'text');
    }
    else {
      e.target.previousSibling.setAttribute('type', 'password');
    }

  }

  emailChange(e) {
    this.setState({
      nutri_email: e.target.value
    })
  }
  passwordChange(e) {
    this.setState({
      nutri_password: e.target.value
    })
  }

  handleNutriSignupClick(e) {
    e.preventDefault();
    if (!$('#remember').is(':checked')) {
      $('.show_check_error').text(window.keystrings['com.numi.agree_terms_conditions']);
      return;
    }
    else {
      $('.show_check_error').text('');
    }
    var nutri_email = $('#nutri_email').val();
    var nutri_password = $('#nutri_password').val();
    var nutri_signup_params = { "nutrisystem": { "email": nutri_email, "password": nutri_password } };
    Header.startLoader();


    const options = {
      url: window.API_URL + "v1/nutrisystem/login.json",
      method: 'POST',
      headers: Header.authorization_headers(),
      data: nutri_signup_params
    };

    axios(options)
      .then(response => {
        Header.stopLoader();
        $('.fade_link_popup').remove();
        if (response.data.user.profile) {
          Login.createSessionVariables(response.data);
        }
        var profile_status = window.localStorage.getItem('profile_status');
        var numiLegacy = window.localStorage.getItem('numi_legacy');
        if (profile_status === null || profile_status === "NEW_USER") {
          this.props.history.push('/signup');
        }
        else if (profile_status === "ONBOARDING_COMPLETE") {
          window.localStorage.setItem('assigned_date', moment().format('YYYY-MM-DD'));
          if (window.localStorage.getItem('impersonation') === "true") {
            window.location.href = "/journal";
          }
          else {
            this.props.history.push('/journal');
          }

        }
        else if ((profile_status === "PROFILE_COMPLETE" || profile_status === "ONBOARDING") && numiLegacy === 'true') {
          this.props.history.push('/welcome');
        }
        else if (profile_status === "PROFILE_COMPLETE" || profile_status === "ONBOARDING") {
          this.props.history.push('/ns_plan');
        }
        else if (profile_status === "PLAN_COMPLETE") {
          this.props.history.push('/stay_on_track');
        }
        else {
          alert("Undifined profile status:" + profile_status);
        }
      })
      .catch(error => {
        Header.stopLoader();
        var evt = document.createEvent("CustomEvent");
        evt.initCustomEvent('LoginFailed', true, false, false);
        document.dispatchEvent(evt);
        this.setState({
          errorCode: error.response.status,
          errorMessage: error.response.data.error
        })
        $('#invalid_nutri_signup').modal('show');
        Sentry.captureException(error);
      });


  }
  fadeDiv = () => {
    this.setState({
      toggleFade: !this.state.toggleFade
    });
  }
  callSignUp = () => {
    if (!document.querySelector('#nutriAccount').classList.contains('fade_sec')) {
      this.showNutriAccount();
    }
    else {
      this.props.history.push('signup');
    }
  }

  componentDidMount() {
    window.localStorage.clear();
    $('.account_details_footer_text').append(window.keystrings['com.numi.account_details_footer']);
    $('.signup_hdr').text(window.keystrings['com.numi.sign_up']);
    $('.nutri_account_txt').text(window.keystrings['com.numi.use_nutri_account']);
    $('.numi_account_txt').text(window.keystrings['com.numi.create_numi_account']);
    $('.use_nutri_account').text(window.keystrings['com.numi.use_nutri_account_hdr']);
    $('.com.numi.signup_for_numi_btn').text(window.keystrings['com.numi.signup_for_numi_btn']);
    $('.continue_text').text(window.keystrings['com.numi.continue'])
  }
  render() {

    return (
      <div className="main_container">

        <div className="forgot_password_hero">
          <div><img alt="" src="/assets/images/numi_small_logo.png" className="logo img-responsive" /></div>
          <div className="new_signup_sec padding4 relative">
            <div style={{ 'display': this.state.showNutri ? 'none' : 'block' }}><Link to="/login"><img alt="" src="/assets/images/back_white_icon.png" className="left_arrow" /></Link></div>
            <h3 className="signup_hdr" style={{ 'display': !this.state.showNutri ? 'block' : 'none' }}> </h3>
            <div className="new_sign_sec" style={{ 'display': !this.state.showNutri ? 'block' : 'none' }}>
              <div className={!this.state.toggleFade ? "signup_in tap_nutri_account width70  font18 pt34 cursor_pointer" : 'signup_in tap_nutri_account width70  font18 pt34 cursor_pointer fade_sec'} onClick={this.state.toggleFade ? this.fadeDiv : null} id="nutriAccount">
                <img alt="" src="/assets/images/leaf.png" />  <p className="nutri_account_txt mt20px color6"></p>
              </div>
              <hr className="width70 mt6" />
              <div className={this.state.toggleFade ? "signup_in tap_numi_account width70 mt0 font18 cursor_pointer" : "signup_in tap_numi_account width70 mt0 font18 cursor_pointer fade_sec"} onClick={!this.state.toggleFade ? this.fadeDiv : null} id="numiAccount">
                <div><img alt="" src="/assets/images/journal_numi_logo.png" />  <p className="numi_account_txt color6"></p></div>
              </div>
              <button className="continue_text width70" onClick={this.callSignUp}></button>
            </div>

            <div className="nutri_account" style={{ 'display': this.state.showNutri ? 'block' : 'none' }}>
              <img src="/assets/images/close_grey.png" align="right" className="close_icon" alt="" onClick={this.clickClose.bind(this)} />
              <img src="/assets/images/nutri_logo_large.png" alt="center" />
              <form onSubmit={this.handleNutriSignupClick.bind(this)}>
                <p className="font26 color3"><b className="use_nutri_account"></b></p>
                <div><input type="text" id="nutri_email" placeholder="Nutrisystem Email" value={this.state.nutri_email} onChange={this.emailChange} /></div>
                <div className="pass_div relative"><input type="password" id="nutri_password" placeholder="Nutrisystem Password" value={this.state.nutri_password} onChange={this.passwordChange} /><i onClick={this.togglePassword.bind(this)}></i></div>
                <div className="terms remember_me account_details_footer_text">
                  <input id="remember" type="checkbox" />
                  <label htmlFor="remember">
                    <span className="bullet_icon">
                      <i></i>
                    </span>
                  </label>
                </div>
                <h6 className="show_check_error" id="error"> </h6>
                <input type="submit" className={this.state.nutri_email === '' || this.state.nutri_password === '' ? 'btn disabled signup_numi' : 'signup_numi'} disabled={this.state.nutri_email === '' || this.state.nutri_password === '' ? true : false} value="Sign in with Nutrisystem" />

              </form>
            </div>

          </div>


        </div>
        <Footer />
        <InvalidNutriSignup InvalidCode={this.state.errorCode} errorMessage={this.state.errorMessage} />
      </div>

    );
  }
}

class InvalidNutriSignup extends React.Component {
  componentDidMount() {
    $('.modal-backdrop').remove();
    $('body').removeClass('modal-open');
    $('.remove_fade').on('click', function () {
      $('.modal').modal('hide');
    });

    /*keystrings*/
    $('.try_again_caps_text').text(window.keystrings['com.numi.try_again_caps_text']);
    $('.log_in_text').text(window.keystrings['com.numi.log_in']);
    $('.forgot_password_text').text(window.keystrings['com.numi.forgot_numi_password']);
    $('.invalid_email_or_password_title').text(window.keystrings['com.numi.invalid_email_or_password_title']);
    $('.invalid_nutri_account_text').text(window.keystrings['com.numi.invalid_nutri_account_text']);
  }

  errorParagraph() {
    if (this.props.InvalidCode === 401) {
      return (
        <div>
          <p className="font26 color3 mt5 font700">{window.keystrings['com.numi.invalid_email_or_password_title']}</p>
          <p className="font16 color3">{window.keystrings['com.numi.invalid_nutri_account_text']}</p>
          <button className="close_btn tap_signup_error_close try_again_caps_text" data-dismiss="modal">{window.keystrings['com.numi.try_again_caps_text']}</button>

        </div>
      )
    }
    else {
      return (
        <div>
          <p className="font26 color3 mt5 font700">{this.props.errorMessage}</p>
          <p className="font16 color3">{window.keystrings['com.numi.link_numi_to_nutri']}</p>
          <button className="close_btn tap_signup_error_close try_again_caps_text" data-dismiss="modal">{window.keystrings['com.numi.try_again_caps_text']}</button>
          <Link to="/login"><button className="close_btn tap_signup_error_login log_in_text">{window.keystrings['com.numi.log_in']}</button></Link><br />
          <Link to="/forgot_password"><button className="close_btn tap_signup_error_password forgot_password_text">{window.keystrings['com.numi.forgot_numi_password']}</button></Link>
        </div>
      )
    }
  }

  render() {
    return (
      <div className="modal fade" id="invalid_nutri_signup">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><img alt="" src="/assets/images/close_grey.png" /></button>
            </div>
            <div className="invalidemail" style={{ display: this.props.InvalidCode === 401 ? 'block' : 'none' }}>
              <div className="modal-body width77">
                <img alt="" src="/assets/images/warning_tri.png" />
                <p className="font26 color3 mt5 font700 invalid_email_or_password_title"></p>
                <p className="font16 color6 invalid_nutri_account_text"></p>
                <button className="close_btn tap_signup_error_close try_again_caps_text" data-dismiss="modal"></button>
              </div>
            </div>
            <div className="email_exists" style={{ display: this.props.InvalidCode === 409 ? 'block' : 'none' }}>
              <div className="modal-body width77">
                <img alt="" src="/assets/images/warning_tri.png" />

                {this.errorParagraph()}

              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}

export default NewSignup;
