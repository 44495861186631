
import React from 'react';
import Header from "../header.js";
import Footer from "../footer.js";
import MealDropdown from "../journal/meal_dropdown.js";
import FoodRecipeFilter from "./recipe_search.js";
import Navigation from "../nav.js";
import Adspeed from "../adspeed.js";
import moment from 'moment';
import axios from 'axios';
import $ from 'jquery';
import * as Sentry from "@sentry/react";

class Recipe extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      recipeType: 'favorite',
      recipedrop: true, recipeList: false, showmoreBtn: false, calMin: 'No Min', calMax: 'No Max', foods: [], showCheckbox: false, showMulitxt: true, toggleCancel: false, disable_btn: 'btn disabled', disable: 'disabled', pointerNone: 'pointer_none', recent_list: [],
      fav_list: [], food_list_images: {
        "All Foods": "",
        "Nutrisystem Entree": "/assets/images/ne_tag.png",
        "PowerFuel": "/assets/images/pf_tag.png",
        "SmartCarb": "/assets/images/sc_tag.png",
        "Vegetables": "/assets/images/v_tag.png",
        "Other": "/assets/images/oth_tag.png",
        "Extras": "/assets/images/ex_tag.png",
        "Purple": "/assets/images/pl_tag.png",
        "Pink": "/assets/images/pk_tag.png",
        "White": "/assets/images/w_tag.png",
        "Orange": "/assets/images/o_tag.png",
        "Green": "/assets/images/g_tag.png",
        "Free Food": "/assets/images/ff_tag.png",
        "Nutricrush": "/assets/images/nc_tag.png",
        "Turboshake": "/assets/images/ts_tag.png",
        "Nutrisystem Snack": "/assets/images/ns_tag.png"
      },
      food_list_images_caps: {
        "All Foods": "",
        "NS_ENTREE": "/assets/images/ne_tag.png",
        "POWERFUEL": "/assets/images/pf_tag.png",
        "SMARTCARB": "/assets/images/sc_tag.png",
        "VEGETABLES": "/assets/images/v_tag.png",
        "OTHER": "/assets/images/oth_tag.png",
        "EXTRAS": "/assets/images/ex_tag.png",
        "PURPLE": "/assets/images/pl_tag.png",
        "PINK": "/assets/images/pk_tag.png",
        "WHITE": "/assets/images/w_tag.png",
        "ORANGE": "/assets/images/o_tag.png",
        "GREEN": "/assets/images/g_tag.png",
        "FREE_FOOD": "/assets/images/ff_tag.png",
        "NUTRICRUSH": "/assets/images/nc_tag.png",
        "TURBOSHAKE": "/assets/images/ts_tag.png",
        "NS_SNACK": "/assets/images/ns_tag.png"
      },
      assigned_date: window.localStorage.getItem('assigned_date'),
      recipe_groups: [],
      food_categories: [],

      food_category_selected: '',
      recipe_groups_selected: '',
      calories_min_selected: '',
      calories_max_selected: '',

    };
    this.getRecipeGroups = this.getRecipeGroups.bind(this);
    this.getFoodCategories = this.getFoodCategories.bind(this);
    this.callRecipeFavAPI = this.callRecipeFavAPI.bind(this);
    this.callRecipeRecentAPI = this.callRecipeRecentAPI.bind(this);
    this.callConsumables = this.callConsumables.bind(this);
    this.searchItem = this.searchItem.bind(this);
    this.toggleRecipeDropdown = this.toggleRecipeDropdown.bind(this);
    this.toggleRecipeList = this.toggleRecipeList.bind(this);
    this.updateCalorie = this.updateCalorie.bind(this);
    this.incerementPageNo = this.incerementPageNo.bind(this);
  }

  toggleRecipeDropdown(e) {
    this.setState({
      recipedrop: false, recipeList: true, showmoreBtn: true
    });

  }
  toggleRecipeList(e) {

    this.setState({
      recipedrop: false, recipeList: true
    });
    window.localStorage.setItem('page_number', 1);
  }

  updateCalorie(e) {

    window.localStorage.setItem('page_number', 1);
    this.setState({
      calMin: $(".cal_range_lft .checbox_normal:radio:checked").val(),
      calMax: $(".cal_range_rht .checbox_normal:radio:checked").val()

    });
    window.localStorage.setItem('calMin', $(".cal_range_lft .checbox_normal:radio:checked").val());
    window.localStorage.setItem('calMax', $(".cal_range_rht .checbox_normal:radio:checked").val());

  }
  recipeMultiTextclick() {
    $('.recipe_multiselect_txt').hide();
    $('.select_occasion').text('Select Occasion');
    $('.select_meal_drop').show();
    $('.multi_gray_arrow').hide();
    $('.recipe_multi_select_dropdown,.meal_txt_recipe .checkbox_select,.recipe_multi_cancel').show();
    if ($(".recipe_page .checbox_normal:checkbox:checked").length < 1) {
      $('.select_meal_drop').addClass('disabled_drop');
      $('.recipe_multi_log').hide();
    }
    else {
      $('.select_meal_drop').removeClass('disabled_drop');
      $('.recipe_multi_log').show();
    }
  }


  getRecipeGroups() {
    Header.startLoader();
    const options = {
      url: window.API_URL + 'v1/recipe_groups',
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        Header.stopLoader();
        if (response && response.data) {
          this.setState({ recipe_groups: response.data.recipe_groups })
        }
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });

  }

  getFoodCategories() {
    Header.startLoader();
    const options = {
      url: window.API_URL + "v1/phase_templates/phase_property/FOOD_CATEGORIES/assigned_date/" + this.state.assigned_date,
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        Header.stopLoader();
        if (response && response.data && response.data.phase_template) {
          this.setState({ food_categories: response.data.phase_template.food_categories })
        }
        else {
          this.setState({ food_categories: [] })
        }
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });
  }

  searchItem() {


  }
  callRecipeFavAPI() {


    Header.startLoader();
    const options = {
      url: window.API_URL + "v1/favorite_foods?show_recipe_only=true&limit=100&assigned_date=" + window.localStorage.getItem('assigned_date'),
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        Header.stopLoader();
        if (response && response.data) {
          this.setState({ fav_list: response.data.favorite_foods });
          for (var kk1 = 0; kk1 < response.data.favorite_foods.length; kk1++) {
            for (var pp1 = response.data.favorite_foods[kk1].pills.length - 1; pp1 >= 0; pp1--) {
              $('<img alt="" width="25" height="13" class="food_list_images_icons" src=' + response.data.favorite_foods[kk1].pills[pp1].url + '>').insertAfter('#favorite .leaf_image:eq(' + kk1 + ')');
            }

          }
        }
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });
  }

  callRecipeRecentAPI() {
    Header.startLoader();
    const options = {
      url: window.API_URL + "v1/diet_histories/recents?show_recipe_only=true&limit=50",
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        Header.stopLoader();
        if (response && response.data) {
          this.setState({ recent_list: response.data.diet_histories });
          if (!!response.data.diet_histories) {
            for (var kk2 = 0; kk2 < response.data.diet_histories.length; kk2++) {

              for (var pp2 = response.data.diet_histories[kk2].count_as.length - 1; pp2 >= 0; pp2--) {
                $('<img alt="" class="food_list_images_icons" src=' + this.state.food_list_images_caps[response.data.diet_histories[kk2].count_as[pp2].category] + '>').insertAfter('#recent .leaf_image:eq(' + kk2 + ')');
              }
            }
          }
        }
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });
  }

  incerementPageNo() {

    window.localStorage.setItem('page_number', parseInt(window.localStorage.getItem('page_number')) + 1)
    this.callConsumables();
  }

  callConsumables() {
    //var food_categories = food_category_txt;
    var assigned_date = window.localStorage.getItem('assigned_date');
    var page_number = parseInt(window.localStorage.getItem('page_number'));

    var food_category_txt = $.trim($('.foodCategorySelected').text());
    var recipe_group_txt = $.trim($('.recipe_droplist').text());

    var food_category = $('.foodCategorySelected').attr('id');
    var recipe_group = $('.recipe_droplist').attr('id');

    if ($('input[type="radio"][name="cal_min_range"]:checked ').length > 0) {
      var calorie_min = $('.cal_range_lft').find('input[type="radio"]:checked').val();

    }
    if ($('input[type="radio"][name="cal_max_range"]:checked ').length > 0) {
      var calorie_max = $('.cal_range_rht').find('input[type="radio"]:checked').val()

    }
    var search_url;
    if (food_category_txt === "All Foods Categories" && recipe_group_txt === "All Recipes" && calorie_min === 'No Min' && calorie_max === 'No Max') {
      search_url = window.API_URL + "v1/consumables?show_recipe_only=true&assigned_date=" + assigned_date + "&page=" + page_number;
    }

    else {

      if (food_category_txt !== "All Foods Categories" && recipe_group_txt !== "All Recipes" && calorie_min === 'No Min' && calorie_max === 'No Max') {

        search_url = window.API_URL + "v1/consumables?show_recipe_only=true&recipe_groups=" + recipe_group + "&food_categories=" + food_category + "&assigned_date=" + assigned_date + "&page=" + page_number;
      }
      else {
        if (food_category_txt === "All Foods Categories" && recipe_group_txt !== "All Recipes" && calorie_min === 'No Min' && calorie_max === 'No Max') {

          search_url = window.API_URL + "v1/consumables?show_recipe_only=true&recipe_groups=" + recipe_group + "&assigned_date=" + assigned_date + "&page=" + page_number;
        }
        else {
          if (food_category_txt !== "All Foods Categories" && recipe_group_txt === "All Recipes" && calorie_min === 'No Min' && calorie_max === 'No Max') {
            search_url = window.API_URL + "v1/consumables?show_recipe_only=true&food_categories=" + food_category + "&assigned_date=" + assigned_date + "&page=" + page_number;
          }
          else {
            if (food_category_txt === "All Foods Categories" && recipe_group_txt === "All Recipes" && calorie_min !== 'No Min' && calorie_max !== 'No Max') {
              search_url = window.API_URL + "v1/consumables?show_recipe_only=true&assigned_date=" + assigned_date + "&calories_min=" + calorie_min + "&calories_max=" + calorie_max + "&page=" + page_number;
            }
            else {

              if (food_category_txt === "All Foods Categories" && recipe_group_txt === "All Recipes" && calorie_min === 'No Min' && calorie_max !== 'No Max') {
                search_url = window.API_URL + "v1/consumables?show_recipe_only=true&assigned_date=" + assigned_date + "&calories_max=" + calorie_max + "&page=" + page_number;
              }
              else {
                if (food_category_txt === "All Foods Categories" && recipe_group_txt === "All Recipes" && calorie_min !== 'No Min' && calorie_max === 'No Max') {
                  search_url = window.API_URL + "v1/consumables?show_recipe_only=true&assigned_date=" + assigned_date + "&calories_min=" + calorie_min + "&page=" + page_number;
                }
                else {
                  if (food_category_txt !== "All Foods Categories" && recipe_group_txt !== "All Recipes" && calorie_min !== 'No Min' && calorie_max === 'No Max') {
                    search_url = window.API_URL + "v1/consumables?show_recipe_only=true&recipe_groups=" + recipe_group + "&food_categories=" + food_category + "&assigned_date=" + assigned_date + "&calories_min=" + calorie_min + "&page=" + page_number;
                  }
                  else {
                    if (food_category_txt !== "All Foods Categories" && recipe_group_txt !== "All Recipes" && calorie_min === 'No Min' && calorie_max !== 'No Max') {
                      search_url = window.API_URL + "v1/consumables?show_recipe_only=true&recipe_groups=" + recipe_group + "&food_categories=" + food_category + "&assigned_date=" + assigned_date + "&calories_max=" + calorie_max + "&page=" + page_number;
                    }
                    else {
                      if (food_category_txt === "All Foods Categories" && recipe_group_txt !== "All Recipes" && calorie_min !== 'No Min' && calorie_max !== 'No Max') {
                        search_url = window.API_URL + "v1/consumables?show_recipe_only=true&recipe_groups=" + recipe_group + "&assigned_date=" + assigned_date + "&calories_min=" + calorie_min + "&calories_max=" + calorie_max + "&page=" + page_number;
                      }
                      else {
                        if (food_category_txt === "All Foods Categories" && recipe_group_txt !== "All Recipes" && calorie_min !== 'No Min' && calorie_max === 'No Max') {
                          search_url = window.API_URL + "v1/consumables?show_recipe_only=true&recipe_groups=" + recipe_group + "&assigned_date=" + assigned_date + "&calories_min=" + calorie_min + "&page=" + page_number;
                        }
                        else {
                          if (food_category_txt !== "All Foods Categories" && recipe_group_txt === "All Recipes" && calorie_min !== 'No Min' && calorie_max !== 'No Max') {
                            search_url = window.API_URL + "v1/consumables?show_recipe_only=true&food_categories=" + food_category + "&assigned_date=" + assigned_date + "&calories_min=" + calorie_min + "&calories_max=" + calorie_max + "&page=" + page_number;
                          }
                          else {
                            if (food_category_txt !== "All Foods Categories" && recipe_group_txt === "All Recipes" && calorie_min !== 'No Min' && calorie_max === 'No Max') {
                              search_url = window.API_URL + "v1/consumables?show_recipe_only=true&food_categories=" + food_category + "&assigned_date=" + assigned_date + "&calories_min=" + calorie_min + "&page=" + page_number;
                            }
                            else {
                              if (food_category_txt !== "All Foods Categories" && recipe_group_txt === "All Recipes" && calorie_min === 'No Min' && calorie_max !== 'No Max') {
                                search_url = window.API_URL + "v1/consumables?show_recipe_only=true&food_categories=" + food_category + "&assigned_date=" + assigned_date + "&calories_max=" + calorie_max + "&page=" + page_number;
                              }
                              else {
                                if (food_category_txt !== "All Foods Categories" && recipe_group_txt !== "All Recipes" && calorie_min !== 'No Min' && calorie_max !== 'No Max') {
                                  search_url = window.API_URL + "v1/consumables?show_recipe_only=true&food_categories=" + food_category + "&assigned_date=" + assigned_date + "&calories_max=" + calorie_max + "&calories_min=" + calorie_min + "&page=" + page_number;
                                } else {
                                  if (food_category_txt === "All Foods Categories" && recipe_group_txt !== "All Recipes" && calorie_min === 'No Min' && calorie_max !== 'No Max') {
                                    search_url = window.API_URL + "v1/consumables?show_recipe_only=true&recipe_groups=" + recipe_group + "&assigned_date=" + assigned_date + "&calories_max=" + calorie_max + "&page=" + page_number;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    if (window.localStorage.getItem('back_to_recipe') === "true" && window.localStorage.getItem('search_url') !== null) {
      search_url = window.localStorage.getItem('search_url');
      localStorage.removeItem('back_to_recipe');
    }
    if (window.localStorage.getItem('searched_term') !== '' && window.localStorage.getItem('searched_term') !== undefined && window.localStorage.getItem('searched_term') !== null) {
      search_url = search_url + "&term=" + encodeURI(window.localStorage.getItem('searched_term'));
      setTimeout(function () {
        $(".recipe-search-placeholder-ks").val(window.localStorage.getItem('searched_term'));
      }, 800);
    }
    Header.startLoader();

    const options = {
      url: search_url,
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        Header.stopLoader();
        if (window.localStorage.getItem('page_number') <= 1) {
          $('ul.food_options').empty();
        }
        if (response.data.results.consumables.length === 0) {
          $('ul.recipe_list,ul.food_options').hide();
          $('.recipe_multi_select,.show_more,.serach_on_keyup,.activity_empty').hide();
          $('.search_empty').show();
          $('.search_empty .food_not_found b').text('');
          $('.activity_empty').hide();

          $('.first_drop .dropdown_list').html(window.localStorage.getItem('first_drop'));
          $('.second_drop .dropdown_list').html(window.localStorage.getItem('second_drop'));
          if (window.localStorage.getItem('calMin') === undefined || window.localStorage.getItem('calMin') === "undefined") {
            $('.update_cal_min').text("No Min");
          } else {
            $('.update_cal_min').text(window.localStorage.getItem('calMin'));
          }
          if (window.localStorage.getItem('calMax') === undefined || window.localStorage.getItem('calMax') === "undefined") {
            $('.update_cal_max').text("No Max");
          } else {
            $('.update_cal_max').text(window.localStorage.getItem('calMax'));
          }

          $('input[name="cal_min_range"][value="' + window.localStorage.getItem('calMin') + '"]').click();
          $('input[name="cal_max_range"][value="' + window.localStorage.getItem('calMax') + '"]').click();

        }

        else {

          $('.recipe_multi_select,.show_more').show();
          $('.search_empty,.activity_empty').hide();


          if (response.data.results.next_page === null) {
            $(".show_more").hide();
          }

          if (page_number === 1) {
            $('ul.food_options').show();
            $('.recipe_list.recipe_group_list').hide();
            var ns_food_tag;
            for (var k = 0; k < response.data.results.consumables.length; k++) {
              if (response.data.results.consumables[k].food_source === "Nutrisystem" || response.data.results.consumables[k].food_source === "leaf") {
                ns_food_tag = '<img alt="" src="/assets/images/ns_food_tag.png" class="leaf_image"> '
              } else {
                ns_food_tag = '<span class="leaf_image"></span>';
              }
              var nn = [];
              for (var aa = 0; aa < response.data.results.consumables[k].food_categories.length; aa++) {
                var kk1 = response.data.results.consumables[k].food_categories[aa].display_name.replace(' ', '&nbsp;');
                var pp = { "id": response.data.results.consumables[k].food_categories[aa].id, "name": response.data.results.consumables[k].food_categories[aa].name, "display_name": kk1, "value": response.data.results.consumables[k].food_categories[aa].value };
                nn.push(pp)
              }
              var best_for_you = response.data.results.consumables[k] && response.data.results.consumables[k].best_for_you === true ? '<img alt="" src="/assets/images/best_for.png" style="margin-right:10px;"/>' : '';
              $('ul.food_options').append('<li class="meal_txt_recipe added_food "><span class="color6 item_click  cursor_pointer first_span">' + response.data.results.consumables[k].food_name + ' <br/> ' + best_for_you + ns_food_tag + ' &nbsp; <span class="font12 color8 vlm">' + response.data.results.consumables[k].calories + ' Cals - ' + response.data.results.consumables[k].serving_unit + '</span><input type="hidden" class="consumable_id" value=' + response.data.results.consumables[k].consumable_id + '/><input type="hidden" value=' + response.data.results.consumables[k].consumable_type + ' class="consumable_type"/><input type="hidden" value=' + response.data.results.consumables[k].food_type + ' class="food_type_name"/><input type="hidden" value=' + JSON.stringify(nn) + ' class="consumable_food_cats"/><img alt="" src="/assets/images/arrow_right_grey.png"  class="pull-right mt-10 multi_gray_arrow cursor_pointer"/></span><div class="checkbox_select pull-right" > <input class="checbox_normal" id="multi_check' + page_number + k + '" name="food_item"  type="checkbox"/> <label for="multi_check' + page_number + k + '"><span><i></i></span></label>    </div></li>');

              var b = document.getElementsByClassName("food_options");
              var c = b[0].lastElementChild
              var last_element = c.getElementsByClassName('leaf_image');
              for (var pp3 = 0; pp3 < response.data.results.consumables[k].pills.length; pp3++) {
                $('<img alt="" width="25" height="13" class="food_list_images_icons" src=' + response.data.results.consumables[k].pills[pp3].url + '>').insertAfter(last_element);
              }
            }

            /* back to recipe and filter selection*/
            if (window.localStorage.getItem('first_drop') !== undefined) {
              $('.first_drop .dropdown_list').html(window.localStorage.getItem('first_drop'));
              $('.second_drop .dropdown_list').html(window.localStorage.getItem('second_drop'));
            }
            var first_drop_id = $($.parseHTML(window.localStorage.getItem('first_drop'))).attr('id')
            if (first_drop_id !== undefined) {
              $('.first_drop').find('ul').find('li span').removeClass('recipe_drop_selected')
              $('.first_drop').find('ul').find('li#' + first_drop_id + ' span').addClass('recipe_drop_selected')
            }
            var second_drop_id = $($.parseHTML(window.localStorage.getItem('second_drop'))).attr('id');
            if (second_drop_id !== undefined) {
              $('.second_drop').find('ul').find('li span').removeClass('recipe_drop_selected');
              $('.second_drop').find('ul').find('li#' + second_drop_id + ' span').addClass('recipe_drop_selected');
            }
            /*end back to recipe and filter selection*/
            if (window.localStorage.getItem('calMin') === undefined || window.localStorage.getItem('calMin') === "undefined") {
              $('.update_cal_min').text("No Min");
            } else {
              $('.update_cal_min').text(window.localStorage.getItem('calMin'));
            }
            if (window.localStorage.getItem('calMax') === undefined || window.localStorage.getItem('calMax') === "undefined") {
              $('.update_cal_max').text("No Max");
            } else {
              $('.update_cal_max').text(window.localStorage.getItem('calMax'));
            }

            $('input[name="cal_min_range"][value="' + window.localStorage.getItem('calMin') + '"]').click();
            $('input[name="cal_max_range"][value="' + window.localStorage.getItem('calMax') + '"]').click();
          }
          else {

            $('ul.food_options').show();
            $('.recipe_list.recipe_group_list').hide();
            var ns_food_tag1;
            for (var kk = 0; kk < response.data.results.consumables.length; kk++) {

              if (response.data.results.consumables[kk].food_source === "Nutrisystem" || response.data.results.consumables[kk].food_source === "leaf") {
                ns_food_tag1 = '<img alt="" src="/assets/images/ns_food_tag.png" class="leaf_image">  &nbsp; <span class="font12 color8 vlm">'
              } else {
                ns_food_tag1 = '<span class="leaf_image"></span>';
              }
              var nn1 = [];
              for (var aa1 = 0; aa1 < response.data.results.consumables[kk].food_categories.length; aa1++) {
                var jj = response.data.results.consumables[kk].food_categories[aa1].display_name.replace(' ', '&nbsp;');
                var pp1 = { "id": response.data.results.consumables[kk].food_categories[aa1].id, "name": response.data.results.consumables[kk].food_categories[aa1].name, "display_name": jj, "value": response.data.results.consumables[kk].food_categories[aa1].value };
                nn1.push(pp1)
              }

              var best_for_you_page = response.data.results.consumables[kk] && response.data.results.consumables[kk].best_for_you === true ? '<img alt="" src="/assets/images/best_for.png" style="margin-right:10px;"/>' : '';
              $('ul.food_options').append('<li class="meal_txt_recipe added_food "><span class="color6 item_click  cursor_pointer first_span">' + response.data.results.consumables[kk].food_name + ' <br/> ' + best_for_you_page + ns_food_tag1 + response.data.results.consumables[kk].calories + ' Cals - ' + response.data.results.consumables[kk].serving_unit + '</span><input type="hidden" class="consumable_id" value=' + response.data.results.consumables[kk].consumable_id + '/><input type="hidden" value=' + response.data.results.consumables[kk].consumable_type + ' class="consumable_type"/><input type="hidden" value=' + response.data.results.consumables[kk].food_type + ' class="food_type_name"/><input type="hidden" value=' + JSON.stringify(nn1) + ' class="consumable_food_cats"/><img alt="" src="/assets/images/arrow_right_grey.png"  class="pull-right mt-10 multi_gray_arrow cursor_pointer"/></span><div class="checkbox_select pull-right" > <input class="checbox_normal" id="multi_check' + page_number + kk + '" name="food_item"  type="checkbox"/> <label for="multi_check' + page_number + kk + '"><span><i></i></span></label>    </div></li>');

              var b1 = document.getElementsByClassName("food_options");
              var c1 = b1[0].lastElementChild;
              var last_element2 = c1.getElementsByClassName('leaf_image');
              for (var pp4 = 0; pp4 < response.data.results.consumables[kk].pills.length; pp4++) {
                $('<img alt="" width="25" height="13" class="food_list_images_icons" src=' + response.data.results.consumables[kk].pills[pp4].url + '>').remove();
                $('<img alt="" width="25" height="13" class="food_list_images_icons" src=' + response.data.results.consumables[kk].pills[pp4].url + '>').insertAfter(last_element2);
              }
            }

            $('.first_drop .dropdown_list').html(window.localStorage.getItem('first_drop'));
            $('.second_drop .dropdown_list').html(window.localStorage.getItem('second_drop'));

            $('.update_cal_min').text(window.localStorage.getItem('calMin'));
            $('.update_cal_max').text(window.localStorage.getItem('calMax'));
            if (window.localStorage.getItem('calMin') === undefined || window.localStorage.getItem('calMin') === "undefined") {
              $('.update_cal_min').text("No Min");

            }
            if (window.localStorage.getItem('calMax') === undefined || window.localStorage.getItem('calMax') === "undefined") {
              $('.update_cal_max').text("No Max");

            }
            $('input[name="cal_min_range"][value="' + window.localStorage.getItem('calMin') + '"]').click();
            $('input[name="cal_max_range"][value="' + window.localStorage.getItem('calMax') + '"]').click();

          }

          if (!$('.recipe_multiselect_txt').is(':visible')) {
            $('.multi_gray_arrow').hide();
            $('.recipe_multi_select_dropdown,.meal_txt_recipe .checkbox_select,.recipe_multi_cancel').show();
          } else {
            $('.multi_gray_arrow,.recipe_multiselect_txt').show();
            $('.recipe_multi_select_dropdown,.meal_txt_recipe .checkbox_select').hide();
          }
        }
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });

  }

  componentDidMount() {
    $(document).on('click', '.recipe_tab', function (e) {

      localStorage.removeItem('searched_term');
    });
    localStorage.removeItem('new_assigned_date');
    localStorage.removeItem('recents_food_details');
    localStorage.removeItem('item_name')
    window.localStorage.setItem('item_name', "Recipes")
    window.localStorage.setItem('page_number', 1);
    this.getFoodCategories();
    // load recipe group dropdown
    this.getRecipeGroups();
    this.callRecipeFavAPI();
    this.callRecipeRecentAPI();
    setTimeout(function () {
      if (window.localStorage.getItem('back_to_recipe') === "true") {
        this.callConsumables();
      }
    }.bind(this), 300);



    //this.callConsumables();
    if (this.state.calorie_min === "No Min") {
      $("input[type=radio] #calorie_min_check0").attr("checked", true)
    }

    if (this.state.calorie_max === "No Max") {
      $("input[type=radio] #calorie_max_check4").attr("checked", true)
    }


    window.consumable_date = [];
    $('.recipe_page').on('change', '.food_options.recipe_list .checbox_normal', function (e) {
      if ($(".recipe_page .checbox_normal:checkbox:checked").length < 1) {
        $('.select_meal_drop').addClass('disabled_drop');
        $('.recipe_multi_log').hide();
      }
      else {
        $('.select_meal_drop').removeClass('disabled_drop');
        $('.recipe_multi_log').show();
      }

      if ($(this).is(':checked')) {
        var k = { 'id': $(this).parents('.meal_txt_recipe').find('.consumable_id').val(), 'type': $(this).parents('.meal_txt_recipe').find('.consumable_type').val(), 'food_categories': $(this).parents('.meal_txt_recipe').find('.consumable_food_cats').val() }
        window.consumable_date.push(k);
      }
      else {
        for (var i = 0; i < window.consumable_date.length; i++) {
          if (window.consumable_date[i].id === $(this).parents('.meal_txt_recipe').find('.consumable_id').val()) {
            window.consumable_date.splice(i, 1);
            break;
          }
        }
      }
    });

    //var window.consumable_date = window.consumable_date.reverse()
    // POST API
    $(document).on('click', '.recipe_multi_log', function (e) {
      e.stopImmediatePropagation();
      Header.startLoader();
      var meal_occasion_id = window.localStorage.getItem('meal_occasion_id') || $('.select_replace').find('i').attr('id');
      var serving_size = 1;
      var data = { "diet_histories": [] }
      var api = window.API_URL + "v1/diet_histories";
      var method = "POST";
      var consumable_value = {}
      for (var i = 0; i < $('.food_options .checbox_normal:checkbox:checked').length; i++) {
        consumable_value = {
          "consumable_id": window.consumable_date[i].id,
          "consumable_type": window.consumable_date[i].type,
          "assigned_date": window.localStorage.getItem('assigned_date'),
          "meal_occasion_id": meal_occasion_id,
          "serving_size": serving_size,
          "food_categories": $.parseJSON(window.consumable_date[i].food_categories)
        }
        data.diet_histories.push(consumable_value);
      }

      const options = {
        url: api,
        method: method,
        credentials: 'same-origin',
        headers: Header.setAuthHeaders(),
        data: data
      };

      axios(options)
        .then(response => {
          Header.stopLoader();
          window.consumable_date = [];
          this.props.history.push('/journal');
        })
        .catch(error => {
          Header.stopLoader();
          Header.apiErrors(error);
          Sentry.captureException(error);
        });
    }.bind(this));


    $(document).on('click', '.recipe_multi_cancel', function (e) {
      e.stopImmediatePropagation();
      $(this).hide();
      $('.food_options .meal_txt_recipe .checbox_normal:checked').each(function () {
        $(this).prop('checked', false)
      });
      $('.recipe_multi_txt span,.disabled_drop,.multi_txt a').css('cursor', '');
      $('.multi_gray_arrow,.recipe_multiselect_txt,.select_meal_drop').show();
      $('.recipe_multi_select_dropdown,.meal_txt_recipe .checkbox_select').hide();
    });
    /*Add color to selected item in filter dropdowns  */

    $('.first_drop li:eq(0) span').addClass('recipe_drop_selected');
    $('.second_drop li:eq(0) span').addClass('recipe_drop_selected');
    $(document).on('click', '.first_drop li span,.second_drop li span', function (e) {
      $(this).parents('.first_drop').find('li span').removeClass('recipe_drop_selected');
      $(this).parents('.second_drop').find('li span').removeClass('recipe_drop_selected');
      $(this).addClass('recipe_drop_selected');
    });
    /*End Add color to selected item in filter dropdowns  */

    $(document).on('click', '.recipe .tab-pane .meal_txt_recipe,.recipe ul.food_options.recipe_list li span.item_click', function (e) {
      e.stopImmediatePropagation();
      if (e.currentTarget.classList.contains('recent_receipe_txt')) {
        window.localStorage.setItem('recents_food_details', true);
        localStorage.removeItem('dh_id');
        window.localStorage.setItem('dh_id', e.currentTarget.getElementsByClassName('dh_id')[0].value);
      }
      window.localStorage.setItem('consumable_id', e.currentTarget.getElementsByClassName('consumable_id')[0].value);
      window.localStorage.setItem('consumable_type', e.currentTarget.getElementsByClassName('consumable_type')[0].value);
      if (e.currentTarget.getElementsByClassName('food_type_name')[0]) {
        window.localStorage.setItem('foodType', e.currentTarget.getElementsByClassName('food_type_name')[0].value);
      }

      var assigned_date = window.localStorage.getItem('assigned_date');
      window.localStorage.setItem('assigned_date', moment(assigned_date).format("YYYY-MM-DD"))
      window.localStorage.setItem('searched_term', $('.food_search_input').val());

      this.props.history.push('/recipe_item');
    }.bind(this));




    if (this.state.calorie_min === "No Min") {
      $("input[type=radio] #calorie_min_check0").attr("checked", true)
    }

    if (this.state.calorie_max === "No Max") {
      $("input[type=radio] #calorie_max_check4").attr("checked", true)
    }

    let filter_view = function () {
      $('.update_cal_min').text(window.localStorage.getItem('calMin'));
      $('.update_cal_max').text(window.localStorage.getItem('calMax'));
      if (window.localStorage.getItem('calMin') === undefined || window.localStorage.getItem('calMin') === null) {
        $('.update_cal_min').text("No Min");

      }
      if (window.localStorage.getItem('calMax') === undefined || window.localStorage.getItem('calMax') === null) {
        $('.update_cal_max').text("No Max");

      }
      $('input[name="cal_min_range"][value="' + window.localStorage.getItem('calMin') + '"]').click();
      $('input[name="cal_max_range"][value="' + window.localStorage.getItem('calMax') + '"]').click();
    }
    $(document).on('click', 'button.recipe_range_close', function () {

      $('input[name="cal_min_range"][value="' + $('.update_cal_min').text() + '"]').click();
      $('input[name="cal_max_range"][value="' + $('.update_cal_max').text() + '"]').click();
    });



    $(document).on('click', '.recipe_page .select_dropdown li a,.recipe_page .select_dropdown li span,#calorie_range button.calorie_update,.recipe_page .recipe_group_list li,.recipe_page .recipe_item,#calorie_range .selected', function (e) {
      e.stopImmediatePropagation();

      $('.select_dropdown').removeClass('open');

      $('.food_search_input').val('');
      $(this).parents('.select_dropdown').find('.dropdown_list span').text($(this).text());
      $('.select_dropdown li a').removeClass('selected');
      $(this).addClass('selected');
      $(this).parents('.select_dropdown').find('.dropdown_list span').text($(this).text());
      $(this).parents('.select_dropdown').find('.dropdown_list span').attr('id', $(this).parents('li').attr('id'));
      /*onchange of filter reseting multiselect dropdown */
      $('.recipe_multi_select_dropdown,.meal_txt_recipe .checkbox_select').hide();
      $('.multi_gray_arrow,.recipe_multiselect_txt,.select_meal_drop').show();

      var bucket_sequence;
      var currentHour = moment().format('HH');

      if (currentHour <= 10 && currentHour >= 0) {
        bucket_sequence = '1';
      } else if (currentHour > 10 && currentHour < 12) {
        bucket_sequence = '2';
      } else if (currentHour >= 12 && currentHour < 14) {
        bucket_sequence = '3';
      } else if (currentHour >= 14 && currentHour < 17) {
        bucket_sequence = '4'
      } else if (currentHour >= 17 && currentHour < 20) {
        bucket_sequence = '5';
      } else if (currentHour >= 20 && currentHour <= 24) {
        bucket_sequence = '6';
      }
      var meal_occasion_id = window.localStorage.getItem('meal_occasion_id') || bucket_sequence
      $('.select_replace i').html($('.recipe_multi_select_dropdown ul.dropdown-menu ').find('i#' + meal_occasion_id).html());
      var assigned_date = window.localStorage.getItem('assigned_date')
      var page_number = parseInt(window.localStorage.getItem('page_number'));

      if ($(this).attr('value') === 'true') {
        $('.recipe_droplist').attr('id', $(this).attr('id'));
        $('.recipe_droplist').text($(this).text());
      }
      if ($('.first_drop .dropdown_list').html() !== undefined) {
        window.localStorage.setItem('first_drop', $('.first_drop .dropdown_list').html());
        window.localStorage.setItem('second_drop', $('.second_drop .dropdown_list').html());
      }
      var food_category_txt = $.trim($('.foodCategorySelected').text());
      var recipe_group_txt = $.trim($('.recipe_droplist').text());
      var food_category = $('.foodCategorySelected').attr('id');
      var recipe_group = $('.recipe_droplist').attr('id');
      if (recipe_group === undefined || recipe_group === "undefined") {
        recipe_group = ""
      }

      if ($('input[type="radio"][name="cal_min_range"]:checked ').length > 0) {
        var calorie_min = $('.cal_range_lft').find('input[type="radio"]:checked').val();


      }
      if ($('input[type="radio"][name="cal_max_range"]:checked ').length > 0) {
        var calorie_max = $('.cal_range_rht').find('input[type="radio"]:checked').val()

      }

      if (calorie_min === undefined) {
        calorie_min = ''
      }
      if (calorie_max === undefined) {
        calorie_max = ''
      }


      if (food_category === undefined || food_category === "undefined") {
        food_category = ''
      }

      if (recipe_group === undefined || recipe_group === "undefined") {
        recipe_group = ''
      }



      var search_url;
      if (food_category_txt === "All Foods Categories" && recipe_group_txt === "All Recipes" && calorie_min === 'No Min' && calorie_max === 'No Max') {
        search_url = window.API_URL + "v1/consumables?show_recipe_only=true&food_type=recipe&assigned_date=" + assigned_date + "&page=" + page_number;
      }

      else {

        if (food_category_txt !== "All Foods Categories" && recipe_group_txt !== "All Recipes" && calorie_min === 'No Min' && calorie_max === 'No Max') {

          search_url = window.API_URL + "v1/consumables?show_recipe_only=true&recipe_groups=" + recipe_group + "&food_categories=" + food_category + "&assigned_date=" + assigned_date + "&page=" + page_number;
        }
        else {
          if (food_category_txt === "All Foods Categories" && recipe_group_txt !== "All Recipes" && calorie_min === 'No Min' && calorie_max === 'No Max') {

            search_url = window.API_URL + "v1/consumables?show_recipe_only=true&recipe_groups=" + recipe_group + "&assigned_date=" + assigned_date + "&page=" + page_number;
          }
          else {
            if (food_category_txt !== "All Foods Categories" && recipe_group_txt === "All Recipes" && calorie_min === 'No Min' && calorie_max === 'No Max') {
              search_url = window.API_URL + "v1/consumables?show_recipe_only=true&food_categories=" + food_category + "&assigned_date=" + assigned_date + "&page=" + page_number;
            }
            else {
              if (food_category_txt === "All Foods Categories" && recipe_group_txt === "All Recipes" && calorie_min !== 'No Min' && calorie_max !== 'No Max') {
                search_url = window.API_URL + "v1/consumables?show_recipe_only=true&assigned_date=" + assigned_date + "&calories_min=" + calorie_min + "&calories_max=" + calorie_max + "&page=" + page_number;
              }
              else {

                if (food_category_txt === "All Foods Categories" && recipe_group_txt === "All Recipes" && calorie_min === 'No Min' && calorie_max !== 'No Max') {
                  search_url = window.API_URL + "v1/consumables?show_recipe_only=true&assigned_date=" + assigned_date + "&calories_max=" + calorie_max + "&page=" + page_number;
                }
                else {
                  if (food_category_txt === "All Foods Categories" && recipe_group_txt === "All Recipes" && calorie_min !== 'No Min' && calorie_max === 'No Max') {
                    search_url = window.API_URL + "v1/consumables?show_recipe_only=true&assigned_date=" + assigned_date + "&calories_min=" + calorie_min + "&page=" + page_number;
                  }
                  else {
                    if (food_category_txt !== "All Foods Categories" && recipe_group_txt !== "All Recipes" && calorie_min !== 'No Min' && calorie_max === 'No Max') {
                      search_url = window.API_URL + "v1/consumables?show_recipe_only=true&recipe_groups=" + recipe_group + "&food_categories=" + food_category + "&assigned_date=" + assigned_date + "&calories_min=" + calorie_min + "&page=" + page_number;
                    }
                    else {
                      if (food_category_txt !== "All Foods Categories" && recipe_group_txt !== "All Recipes" && calorie_min === 'No Min' && calorie_max !== 'No Max') {
                        search_url = window.API_URL + "v1/consumables?show_recipe_only=true&recipe_groups=" + recipe_group + "&food_categories=" + food_category + "&assigned_date=" + assigned_date + "&calories_max=" + calorie_max + "&page=" + page_number;
                      }
                      else {
                        if (food_category_txt === "All Foods Categories" && recipe_group_txt !== "All Recipes" && calorie_min !== 'No Min' && calorie_max !== 'No Max') {
                          search_url = window.API_URL + "v1/consumables?show_recipe_only=true&recipe_groups=" + recipe_group + "&assigned_date=" + assigned_date + "&calories_min=" + calorie_min + "&calories_max=" + calorie_max + "&page=" + page_number;
                        }
                        else {
                          if (food_category_txt === "All Foods Categories" && recipe_group_txt !== "All Recipes" && calorie_min !== 'No Min' && calorie_max === 'No Max') {
                            search_url = window.API_URL + "v1/consumables?show_recipe_only=true&recipe_groups=" + recipe_group + "&assigned_date=" + assigned_date + "&calories_min=" + calorie_min + "&page=" + page_number;
                          }
                          else {
                            if (food_category_txt !== "All Foods Categories" && recipe_group_txt === "All Recipes" && calorie_min !== 'No Min' && calorie_max !== 'No Max') {
                              search_url = window.API_URL + "v1/consumables?show_recipe_only=true&food_categories=" + food_category + "&assigned_date=" + assigned_date + "&calories_min=" + calorie_min + "&calories_max=" + calorie_max + "&page=" + page_number;
                            }
                            else {
                              if (food_category_txt !== "All Foods Categories" && recipe_group_txt === "All Recipes" && calorie_min !== 'No Min' && calorie_max === 'No Max') {
                                search_url = window.API_URL + "v1/consumables?show_recipe_only=true&food_categories=" + food_category + "&assigned_date=" + assigned_date + "&calories_min=" + calorie_min + "&page=" + page_number;
                              }
                              else {
                                if (food_category_txt !== "All Foods Categories" && recipe_group_txt === "All Recipes" && calorie_min === 'No Min' && calorie_max !== 'No Max') {
                                  search_url = window.API_URL + "v1/consumables?show_recipe_only=true&food_categories=" + food_category + "&assigned_date=" + assigned_date + "&calories_max=" + calorie_max + "&page=" + page_number;
                                } else {

                                  if (food_category_txt !== "All Foods Categories" && recipe_group_txt !== "All Recipes" && calorie_min !== 'No Min' && calorie_max !== 'No Max') {
                                    search_url = window.API_URL + "v1/consumables?show_recipe_only=true&recipe_groups=" + recipe_group + "&food_categories=" + food_category + "&assigned_date=" + assigned_date + "&calories_min=" + calorie_min + "&calories_max=" + calorie_max + "&page=" + page_number;
                                  } else {
                                    if (food_category_txt === "All Foods Categories" && recipe_group_txt !== "All Recipes" && calorie_min === 'No Min' && calorie_max !== 'No Max') {
                                      search_url = window.API_URL + "v1/consumables?show_recipe_only=true&recipe_groups=" + recipe_group + "&assigned_date=" + assigned_date + "&calories_max=" + calorie_max + "&page=" + page_number;
                                    }
                                  }
                                }

                              }

                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }







      localStorage.setItem('calMin', calorie_min);
      localStorage.setItem('calMax', calorie_max);

      // No need to pass params for All Rceipes and All food categories.
      window.localStorage.setItem('search_url', search_url)
      if (window.localStorage.getItem('searched_term') !== '' && window.localStorage.getItem('searched_term') !== undefined && window.localStorage.getItem('searched_term') !== null) {
        search_url = search_url + "&term=" + encodeURI(window.localStorage.getItem('searched_term'));
        setTimeout(function () {
          $(".recipe-search-placeholder-ks").val(window.localStorage.getItem('searched_term'));
        }, 800);
      }
      Header.startLoader();
      const options = {
        url: search_url,
        method: 'GET',
        credentials: 'same-origin',
        headers: Header.setAuthHeaders(),
      };

      axios(options)
        .then(response => {
          Header.stopLoader();
          if (window.localStorage.getItem('page_number') <= 1) {
            $('ul.food_options').empty();
          }

          if (response.data.results.consumables.length === 0) {
            // $('.multi_select .multi_txt,.show_more').hide();
            //  $('.search_empty').show();
            //  $('.search_empty').appendTo('ul.food_options')
            $('ul.recipe_list,ul.food_options').hide();
            $('.recipe_multi_select,.show_more,.serach_on_keyup,.activity_empty').hide();
            $('.search_empty').show();
            if (window.localStorage.getItem('searched_term') !== '' && window.localStorage.getItem('searched_term') !== undefined && window.localStorage.getItem('searched_term') !== null) {
              $('.search_empty .food_not_found b').text('"' + window.localStorage.getItem('searched_term') + '"');
            } else {
              $('.search_empty .food_not_found b').text('');
            }
            $('.activity_empty').hide();

          }

          else {
            $('.recipe_multi_select,.show_more').show();
            $('.search_empty,.activity_empty').hide();
            if (response.data.results.next_page === null) {
              $(".show_more").hide();
            }

            if (page_number === 1) {
              $('ul.food_options').show();
              $('.recipe_list.recipe_group_list').hide();
              var ns_food_tag;
              for (var k = 0; k < response.data.results.consumables.length; k++) {
                if (response.data.results.consumables[k].food_source === "Nutrisystem" || response.data.results.consumables[k].food_source === "leaf") {
                  ns_food_tag = '<img alt="" src="/assets/images/ns_food_tag.png" class="leaf_image"> '
                } else {
                  ns_food_tag = '<span class="leaf_image"></span>';
                }
                var nn = [];
                for (var aa = 0; aa < response.data.results.consumables[k].food_categories.length; aa++) {
                  var kk = response.data.results.consumables[k].food_categories[aa].display_name.replace(' ', '&nbsp;');
                  var pp = { "id": response.data.results.consumables[k].food_categories[aa].id, "name": response.data.results.consumables[k].food_categories[aa].name, "display_name": kk, "value": response.data.results.consumables[k].food_categories[aa].value };
                  nn.push(pp)
                }
                var best_for_you = response.data.results.consumables[k] && response.data.results.consumables[k].best_for_you === true ? '<img alt="" src="/assets/images/best_for.png" style="margin-right:10px;"/>' : '';

                $('ul.food_options').append('<li class="meal_txt_recipe added_food "><span class="color6 item_click  cursor_pointer first_span">' + response.data.results.consumables[k].food_name + ' <br/> ' + best_for_you + ns_food_tag + ' &nbsp; <span class="font12 color8 vlm">' + response.data.results.consumables[k].calories + ' Cals - ' + response.data.results.consumables[k].serving_unit + '</span><input type="hidden" class="consumable_id" value=' + response.data.results.consumables[k].consumable_id + '/><input type="hidden" value=' + response.data.results.consumables[k].consumable_type + ' class="consumable_type"/><input type="hidden" value=' + response.data.results.consumables[k].food_type + ' class="food_type_name"/><input type="hidden" value=' + JSON.stringify(nn) + ' class="consumable_food_cats"/><img alt="" src="/assets/images/arrow_right_grey.png"  class="pull-right mt-10 multi_gray_arrow cursor_pointer"/></span><div class="checkbox_select pull-right" > <input class="checbox_normal" id="multi_check' + page_number + k + '" name="food_item"  type="checkbox"/> <label for="multi_check' + page_number + k + '"><span><i></i></span></label>    </div></li>');

                var b = document.getElementsByClassName("food_options");
                var c = b[0].lastElementChild
                var last_element = c.getElementsByClassName('leaf_image');
                for (var pp3 = response.data.results.consumables[k].pills.length - 1; pp3 >= 0; pp3--) {
                  $('<img alt="" width="25" height="13" class="food_list_images_icons" src=' + response.data.results.consumables[k].pills[pp3].url + '>').insertAfter(last_element);
                }
              }

              filter_view()

            }
            else {

              $('ul.food_options').show();
              $('.recipe_list.recipe_group_list').hide();
              for (var hh = 0; hh < response.data.results.consumables.length; hh++) {

                var ns_food;
                if (response.data.results.consumables[hh].food_source === "Nutrisystem" || response.data.results.consumables[hh].food_source === "leaf") {
                  ns_food = '<img alt="" src="/assets/images/ns_food_tag.png" class="leaf_image">  &nbsp; <span class="font12 color8 vlm">'
                } else {
                  ns_food = '<span class="leaf_image"></span>';
                }
                var qq = [];
                for (var aa3 = 0; aa3 < response.data.results.consumables[hh].food_categories.length; aa3++) {
                  var qq1 = response.data.results.consumables[hh].food_categories[aa3].display_name.replace(' ', '&nbsp;');
                  var p = { "id": response.data.results.consumables[hh].food_categories[aa3].id, "name": response.data.results.consumables[hh].food_categories[aa3].name, "display_name": qq1, "value": response.data.results.consumables[hh].food_categories[aa3].value };
                  qq.push(p)
                }
                var best_for_you_page = response.data.results.consumables[hh] && response.data.results.consumables[hh].best_for_you === true ? '<img alt="" src="/assets/images/best_for.png" style="margin-right:10px;"/>' : '';
                $('ul.food_options').append('<li class="meal_txt_recipe added_food "><span class="color6 item_click  cursor_pointer first_span">' + response.data.results.consumables[hh].food_name + ' <br/> ' + best_for_you_page + ns_food + response.data.results.consumables[hh].calories + ' Cals - ' + response.data.results.consumables[hh].serving_unit + '</span><input type="hidden" class="consumable_id" value=' + response.data.results.consumables[hh].consumable_id + '/><input type="hidden" value=' + response.data.results.consumables[hh].consumable_type + ' class="consumable_type"/><input type="hidden" value=' + response.data.results.consumables[hh].food_type + ' class="food_type_name"/><input type="hidden" value=' + JSON.stringify(qq) + ' class="consumable_food_cats"/></span><div class="checkbox_select pull-right" > <input class="checbox_normal" id="multi_check' + page_number + hh + '" name="food_item"  type="checkbox"/> <label for="multi_check' + page_number + hh + '"><span><i></i></span></label>    </div></li>');

                var b3 = document.getElementsByClassName("food_options");
                var c3 = b3[0].lastElementChild;
                var last_element3 = c3.getElementsByClassName('leaf_image');
                for (var pp5 = 0; pp5 < response.data.results.consumables[hh].pills.length; pp5++) {
                  $('<img alt="" width="25" height="13" class="food_list_images_icons" src=' + response.data.results.consumables[hh].pills[pp5].url + '>').remove();
                  $('<img alt="" width="25" height="13" class="food_list_images_icons" src=' + response.data.results.consumables[hh].pills[pp5].url + '>').insertAfter(last_element3);
                }
              }

              filter_view()

            }

            if (!$('.recipe_multiselect_txt').is(':visible')) {
              $('.multi_gray_arrow').hide();
              $('.recipe_multi_select_dropdown,.meal_txt_recipe .checkbox_select,.recipe_multi_cancel').show();
            } else {
              $('.multi_gray_arrow,.recipe_multiselect_txt').show();
              $('.recipe_multi_select_dropdown,.meal_txt_recipe .checkbox_select').hide();
            }
          }

        })
        .catch(error => {
          Header.stopLoader();
          Header.apiErrors(error);
          Sentry.captureException(error);
        });

    });

    $(document).on('click', 'ul.recipe_group_list li.recipe_item', function () {
      $('.recipe_droplist').text($(this).text());

      $(this).addClass('recipe_drop_selected')
    });
    $(document).on('click', '.select_dropdown li a,#calorie_range button', function () {
      //$(document).on('change','.dropdown_list',function(){
      $('.food_search_input').val('');
      $('.list-group').hide();
    });

    $('.recipes-landing-page-header').text(window.keystrings['com.numi.recipe.landing_page_header']);
    $('.recipe-filter-ks').text(window.keystrings['com.numi.recipe.filter']);
    $('.recipe-favorites-ks').text(window.keystrings['com.numi.recipes_favorites']);
    $('.recipe-recents-ks').text(window.keystrings['com.numi.recipe.recent']);
    $('.recipe-favorite-your-recipe-ks').text(window.keystrings['com.numi.recipe.favorite_your_recipe']);
    $('.recipe-favorite-message-ks').text(window.keystrings['com.numi.recipe.favorite_message']);
    $('.recipe-start-logging-meals-ks').text(window.keystrings['com.numi.recipe.start_logging_meals']);
    $('.recipe-recent-message-ks').text(window.keystrings['com.numi.recipe.recent_message']);
    $('.recipe-multi-select-ks').text(window.keystrings['com.numi.recipe.multiple_select']);
    //$('.recipe-show-more-ks').text(window.keystrings['com.numi.recipe.show_more']);
    $('.recipe-multi-cancel-ks').html(window.keystrings['com.numi.recipe.multi_cancel']);
    $('.recipe-multi-log-txt-ks').text(window.keystrings['com.numi.recipe.multi_log_txt']);


    /* End key Strings*/

  }


  render() {

    $('.recipe-show-more-ks').text(window.keystrings['com.numi.recipe.show_more']);
    if (this.state.fav_list.length > 0) {
      $('.fav_empty').hide();
      var fav_list_options = this.state.fav_list.map(function (option, i) {
        if (option.food_source === "Nutrisystem" || option.food_source === "leaf") {
          return (
            <li className="meal_txt_recipe cursor_pointer" key={i}> {option.food_name} <br /> <img alt="" src="/assets/images/ns_food_tag.png" className="leaf_image" /> <input type="hidden" className="consumable_id" value={option.consumable_id} /><input type="hidden" value={option.consumable_type} className="consumable_type" /><input type="hidden" value={option.food_type} className="food_type_name" />
              <span className="font12 color8 vlm">
                {option.calories} Cals{" "}-{" "}{option.serving_unit}
              </span>
            </li>
          )
        }
        else {
          return (
            <li className="meal_txt_recipe cursor_pointer" key={i}> {option.food_name} <br /> <span className="leaf_image" /> <input type="hidden" className="consumable_id" value={option.consumable_id} /><input type="hidden" value={option.consumable_type} className="consumable_type" /><input type="hidden" value={option.food_type} className="food_type_name" />
              <span className="font12 color8 vlm">
                {option.calories} Cals{" "}-{" "}{option.serving_unit}
              </span>
            </li>
          )
        }
      });
    }
    else {
      $('.fav_empty').show();
    }

    if (!!this.state.recent_list && this.state.recent_list.length > 0) {
      $('.recent_empty').hide();
      var recent_list_options = this.state.recent_list.map(function (option, i) {
        if (option.food_source === "Nutrisystem" || option.food_source === "leaf") {
          return (
            <li className="recent_receipe_txt meal_txt_recipe cursor_pointer" key={i}> {option.food_name} <br /> <img alt="" src="/assets/images/ns_food_tag.png" className="leaf_image" /> <input type="hidden" className="consumable_id" value={option.consumable_id} /><input type="hidden" value={option.consumable_type} className="consumable_type" /><input type="hidden" value={option.food_type} className="food_type_name" /><input type="hidden" value={option.id} className="dh_id" />
              <span className="font12 color8 vlm">
                {option.calories} Cals{" "}-{" "}{option.serving_unit}
              </span>
            </li>

          )
        } else {
          return (
            <li className="recent_receipe_txt meal_txt_recipe cursor_pointer" key={i}> {option.food_name} <br />&nbsp;<span className="leaf_image" /> <input type="hidden" className="consumable_id" value={option.consumable_id} /><input type="hidden" value={option.consumable_type} className="consumable_type" /><input type="hidden" value={option.food_type} className="food_type_name" /><input type="hidden" value={option.id} className="dh_id" />
              <span className="font12 color8 vlm">
                {option.calories} Cals{" "}-{" "}{option.serving_unit}
              </span>
            </li>

          )
        }
      });
    }
    else {
      $('.recent_empty').show();
    }



    var final_foods = $.map(this.state.foods, function (value, index) {
      return [value];
    });
    if (final_foods.length > 0) {

      var food_list_options = final_foods[0].consumables.map(function (option, i) {


        return (
          <li className="meal_txt_recipe cursor_pointer" key={i}><span className="color6 item_click cursor_pointer"> {option.food_name} <br /> <img alt="" src="/assets/images/ns_food_tag.png" className="leaf_image" />&nbsp; <span className="font12 color8 vlm">{option.calories} Cals{" "}-{" "}{option.serving_unit}</span> <input type="hidden" className="consumable_id" value={option.consumable_id} /><input type="hidden" value={option.consumable_type} className="consumable_type" /><input type="hidden" value={option.food_type} className="food_type_name" /></span></li>
        )

      });
    }

    var recipe_main_list = this.state.recipe_groups.map(function (option, i) {
      return (
        <li key={i + 1} id={option.id} onClick={this.toggleRecipeList} className="meal_txt_recipe cursor_pointer recipe_item" value="true"> <span className="color6 font15 cursor_pointer">{option.description}</span></li>
      )
    }.bind(this));

    var recipe_dropdown_options = this.state.recipe_groups.map(function (option, i) {
      return (
        <li key={i + 1} id={option.id}> <span onClick={this.toggleRecipeList} className="color6 font15 cursor_pointer">{option.description}</span></li>
      )
    }.bind(this));

    var food_categories_dropdown = this.state.food_categories.map(function (option, i) {
      return (
        <li key={i + 1} id={option.name}> <span onClick={this.toggleRecipeList} className="color6 font15 cursor_pointer">{option.display_name}</span></li>
      )
    }.bind(this));



    return (
      <div>

        <div className="table_display">

          <div className="journal_div recipe recipe_page">

            <div className="section_left pull-left">
              <Navigation />
            </div>
            <div className="section_right pull-left">
              <figure><Adspeed /></figure>
              <div className="journal_rht">

                <div className="hdr_section">
                  <h1 className="journal_hdr pull-left recipes-landing-page-header"> </h1>
                  <div className="pull-right mt10 journal_search_input"><FoodRecipeFilter /></div>
                </div>


                <div className="clear"></div>
                <hr />
                <div className="row clearfix">
                  <div className="col-md-12 col-12 flex_between filter_div padding0">
                    <div className="col-md-8 col-12  flex_between list_align">
                      <div className="pull-left font15 filter_txt recipe-filter-ks"></div>
                      <div className="pull-left">


                        <div className="select_dropdown dropdown first_drop">
                          <div data-toggle="dropdown" className="dropdown_list"><span className="recipe_droplist">All Recipes</span></div>
                          <ul className="dropdown-menu recipe_group_list reciepedropdown">
                            <li onClick={this.searchItem} id="" > <span className="color6 font15 cursor_pointer" onClick={this.toggleRecipeList}>All Recipes</span></li>

                            {recipe_dropdown_options}
                          </ul>

                        </div>
                      </div>
                      <div className="pull-left">
                        <div className="select_dropdown dropdown second_drop">
                          <div data-toggle="dropdown" className="dropdown_list"><span className="foodCategorySelected">All Foods Categories</span></div>
                          <ul className="dropdown-menu reciepedropdown">
                            <li onClick={this.toggleRecipeList}><span>All Foods Categories</span></li>
                            {food_categories_dropdown}
                          </ul>

                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-12">
                      <div className="pull-right">
                        <div className="calorie_range select_dropdown dropdown">
                          <div data-toggle="modal" data-target="#calorie_range" className="dropdown_list cursor_pointer calorierange"><span className="font14">Calorie Range (<span className="update_cal_min">{this.state.calMin}</span> <span className="hyphen">-</span> <span className="update_cal_max">{this.state.calMax}</span>)</span></div>
                        </div>
                      </div>


                    </div>
                  </div>
                </div>

                {/* <div className="clear"></div> */}
                <div className="row clearfix">
                  <div className="col-md-8 col-12 color6">
                    <div className="border1">
                      <div className="recipe_multi_select">
                        <div className="recipe_multi_txt font14">
                          <span className="pull-right recipe_multiselect_txt recipe-multi-select-ks" onClick={this.recipeMultiTextclick.bind(this)}></span>
                          <span className="pull-right recipe_multi_select_dropdown">
                            <span className="disabled_drop select_meal_drop"><MealDropdown /></span>
                            <span className="mt recipe_multi_log recipe-multi-log-txt-ks"></span>
                            <span className="mt recipe_multi_cancel recipe-multi-cancel-ks"></span>
                          </span>
                          <div className="clear"></div>
                        </div>
                      </div>

                      <div className="search_empty ptb10">
                        <div className="text-center">
                          <img alt="" src="/assets/images/search_empty_allfoods.png" />
                          <p className="color7 font16 food_not_found"><br />We couldn’t find any results <b></b></p>
                        </div>

                      </div>

                      <div className="activity_empty">
                        <div className="text-center serach_on_keyup">
                          <img alt="" src="/assets/images/search_empty_allfoods.png" />
                          <p className="color7 font16 food_not_found"><br />We couldn’t find <b></b></p>
                        </div>
                        <div className="text-center serach_on_click">
                          <img alt="" src="/assets/images/search_empty_allfoods.png" />
                          <p className="color7 font16 food_not_found"><br />We couldn’t find any results <b></b></p>

                        </div>
                      </div>
                      <ul className="recipe_list recipe_group_list recipe_container" style={{ display: this.state.recipedrop ? 'block' : 'none' }}>
                        <li key="0" onClick={this.toggleRecipeList} className="meal_txt_recipe all-recipes cursor_pointer " > <span className="color6 font15 cursor_pointer">All Recipes</span></li>
                        {recipe_main_list}
                      </ul>
                      <ul className="food_options recipe_list" style={{ display: this.state.recipeList ? 'block' : 'none' }}>
                        {food_list_options}
                      </ul>
                      <button className="show_more font15 recipe-show-more-ks" onClick={this.incerementPageNo} style={{ display: this.state.showmoreBtn ? 'block' : 'none' }}></button>


                    </div>
                  </div>



                  {/*  */}
                  <div className="col-md-4 col-12 color6">
                    <div className="border1">
                      <ul className="favorite_section_recipe">
                        <li className={this.state.recipeType == 'favorite' ? "active" : ''}
                          onClick={() => {
                            this.setState({
                              recipeType: 'favorite'
                            })
                          }}

                        ><a href="#favorite" data-toggle="tab" aria-expanded="true" className='recipe-favorites-ks'> </a>
                        </li>
                        <li className={this.state.recipeType == 'recent' ? "active" : ''}
                          onClick={() => {
                            this.setState({
                              recipeType: 'recent'
                            })
                          }}
                        ><a href="#recent" data-toggle="tab" aria-expanded="false" className="recipe-recents-ks"> </a></li>
                      </ul>

                      <div className="tab-content text-center">
                        <div id="favorite"
                          className={this.state.recipeType == 'favorite' ? "tab-pane active" : 'tab-pane'}
                          onClick={() => {
                            this.setState({
                              recipeType: 'favorite'
                            })
                          }}

                        >

                          <div>
                            {fav_list_options}
                          </div>
                          <div className="fav_empty">
                            <img alt="" src="/assets/images/fav_empty.png" />
                            <p className="fav_hdr recipe-favorite-your-recipe-ks"></p>
                            <p className="fav_small_txt recipe-favorite-message-ks"> </p>
                          </div>


                        </div>
                        <div id="recent"
                          className={this.state.recipeType == 'recent' ? "tab-pane active" : 'tab-pane'}
                          onClick={() => {
                            this.setState({
                              recipeType: 'recent'
                            })
                          }}
                        >
                          {recent_list_options}
                          <div className="recent_empty">
                            <img alt="" src="/assets/images/recent_icon.png" />
                            <p className="fav_hdr recipe-start-logging-meals-ks"></p>
                            <p className="fav_small_txt recipe-recent-message-ks"></p>
                          </div>
                        </div>


                      </div>
                    </div>
                  </div>

                </div>


                <div>

                </div>

              </div>
              <Footer />
            </div>
            <CalorieRange clickUpdate={this.updateCalorie} />
          </div>
        </div>




      </div>
    )
  }
}




class CalorieRange extends React.Component {

  constructor(props) {
    super(props)
    this.state = { disable_btn: 'btn disabled', disable: 'disabled' };
    this.checkRadioLength = this.checkRadioLength.bind(this);

  }

  checkRadioLength(e) {
    if ($(".checbox_normal:radio:checked").length === 2) {
      this.setState({
        disable_btn: "",
        disable: ""
      });
    }
    else {
      this.setState({
        disable_btn: "btn disabled",
        disable: "disabled"
      });
    }

  }

  componentDidMount() {
    $('.cal_txt:eq(0),.cal_txt:eq(9)').hide();
    $('#calorie_min_check0 ,#calorie_max_check4').attr('checked', true);
  }
  render() {

    var calorie_list = [
      {
        "cal_min": "No Min",
        "cal_max": 200
      },
      {
        "cal_min": 100,
        "cal_max": 300
      },
      {
        "cal_min": 200,
        "cal_max": 400
      },
      {
        "cal_min": 300,
        "cal_max": 500
      },
      {
        "cal_min": 400,
        "cal_max": "No Max"
      }


    ];

    var calorie_min_list = calorie_list.map(function (option, i) {
      return (
        <li key={i}><div className="checkbox_select"> <input className="checbox_normal" id={"calorie_min_check" + i} value={option.cal_min} name="cal_min_range" onChange={this.checkRadioLength} type="radio" /> <label htmlFor={"calorie_min_check" + i}><span><i></i></span><span >{option.cal_min}</span><span className="cal_txt"> Cals</span></label>    </div></li>
      )
    }.bind(this));
    var calorie_max_list = calorie_list.map(function (option, i) {
      return (
        <li key={i}><div className="checkbox_select" ><input className="checbox_normal" id={"calorie_max_check" + i} value={option.cal_max} name="cal_max_range" onChange={this.checkRadioLength} type="radio" /> <label htmlFor={"calorie_max_check" + i}><span><i></i></span><span >{option.cal_max}</span><span className="cal_txt"> Cals</span></label>    </div></li>
      )
    }.bind(this));


    return (

      <div className="modal fade" id="calorie_range">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close recipe_range_close" data-dismiss="modal" aria-label="Close"><img alt="" src="/assets/images/close_grey.png" /></button>
            </div>
            <div className="modal-body">
              <p className="font26 color3 mt2 font700">Calorie Range</p>
              <div className="cal_range">
                <div className="cal_range_lft pull-left min_max_cal mt5">Min Calorie</div>
                <div className="cal_range_rht pull-left min_max_cal mt5">Max Calorie </div>
              </div>
              <div className="cal_range">
                <ul className="cal_range_lft pull-left min_max_cal mt2">
                  {calorie_min_list}
                </ul>
                <ul className="cal_range_rht pull-left min_max_cal mt2">
                  {calorie_max_list}
                </ul>
              </div>
              <p className="mt6"><button disabled={this.state.disable} className={'calorie_update ' + this.state.disable_btn} onClick={this.props.clickUpdate} data-dismiss="modal">Update</button></p>
            </div>
          </div>
        </div>
      </div>

    )
  }
}


export default Recipe;


