import React from 'react';
import { Link } from 'react-router-dom';
import Footer from "../footer.js";
import Header from "../header.js";
import DatePicker from "react-datepicker";
import Navigation from "../nav.js";
import Adspeed from "../adspeed.js";
import moment from 'moment';
import { addDays, parse } from 'date-fns';
import axios from 'axios';
import $ from 'jquery';
import * as Sentry from "@sentry/react";



// // var Config = require('Config')

class Create_activity extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			disable_btn: 'btn disabled', disable: 'disabled', add_to_fav: window.keystrings['com.numi.remove_from_favorites'], add_fav_off: "/assets/images/fav_on.png", edit_icon: '/assets/images/transparent.gif', add_fav_sec: true, remove_fav_sec: false, item_name: window.keystrings['com.numi.create_activity_title'], fav_calories: 100, show_cal: false, create_activity_hdr: window.keystrings['com.numi.create_activity_header'], save_btn: true, update_btn: false, isChecked: true, update_checkbox: false, activity: {}, logDuration: '', logCaloriesBurned: '', logAssignedDate: Date.today, description: '', duration: '30',
			calories_burned: '', fav_flag: true, startDate: window.localStorage.getItem('assigned_date')
		};

		this.addFavorite = this.addFavorite.bind(this);
		this.editActivity = this.editActivity.bind(this);
		this.rememberCheckbox = this.rememberCheckbox.bind(this);
		this.CustomActivity = this.CustomActivity.bind(this);
		this.createFavoriteActivity = this.createFavoriteActivity.bind(this);
		this.removeFavoriteActivity = this.removeFavoriteActivity.bind(this)
		this.logActivity = this.logActivity.bind(this);
		this._inputChange = this._inputChange.bind(this);
		this.updateActivity = this.updateActivity.bind(this);
		this.handleChange = this.handleChange.bind(this);
		//this.handleDurationChange = this.handleDuationChange.bind(this);
	}
	handleDurationChange(event) {
		this.setState({ duration: event.target.value });
	}
	rememberCheckbox(e) {
		this.setState({
			isChecked: !this.state.isChecked
		});
	}
	handleChange(date) {
		this.setState({
			startDate: date
		});
		var complete_date = moment(date).format('MMMM DD, YYYY');
		var actual_date = moment().format('MMM DD');
		var pageload_selecetd_date = moment(date).format('MMM DD');
		if (pageload_selecetd_date === actual_date) {
			$('.edit_activity_page .today_date').show();
			$('.edit_activity_page .today_date').text('Today')
		}
		else {
			$('.edit_activity_page .today_date').show();
			$('.edit_activity_page .today_date').text(complete_date)
		}
		Header.changeDate(date);
	}

	editActivity() {

		this.setState({
			edit_icon: '/assets/images/transparent.gif',
			add_fav_sec: true, //TODO: need to change true
			remove_fav_sec: false,
			item_name: window.keystrings['com.numi.edit_activity_title'],
			create_activity_hdr: window.keystrings['com.numi.edit_activity_header'],
			save_btn: false,
			update_btn: true,
			update_checkbox: true

		});

		if (window.localStorage.getItem('fav_flag') !== "true") {
			this.setState({
				add_fav_off: "/assets/images/fav_off.png",
				add_to_fav: window.keystrings['com.numi.add_to_favorites']

			});
		}
		else {
			this.setState({
				add_fav_off: "/assets/images/fav_on.png",
				add_to_fav: window.keystrings['com.numi.remove_from_favorites']

			});
		}

	}


	updateActivity() {
		Header.startLoader();
		var ca = { custom_activity: { description: this.state.description, duration: this.state.duration, calories: this.state.calories_burned, update_previous_entries: this.state.isChecked, favorite: window.localStorage.getItem('create_fav_flag') } };

		const options = {
			url: window.API_URL + 'v1/custom_activities/' + this.state.activity.id,
			method: 'PUT',
			credentials: 'same-origin',
			headers: Header.setAuthHeaders(),
			data: ca
		};

		axios(options)
			.then(response => {
				Header.stopLoader();
				this.setState({ activity: response.data.custom_activity, remove_fav_sec: true, add_fav_sec: false, edit_icon: "/assets/images/edit.png", item_name: this.state.description })
				if (this.state.remove_fav_sec) {

					this.createFavoriteActivity(response.data);
				}
			})
			.catch(error => {
				Header.stopLoader();
				Header.apiErrors(error);
				Sentry.captureException(error);
			});
	}


	addFavorite() {
		if (this.state.add_to_fav === "Add to Favorites") {
			this.setState({
				add_to_fav: window.keystrings['com.numi.remove_from_favorites'],
				add_fav_off: "/assets/images/fav_on.png",
				add_fav_sec: true,
				fav_flag: this.state.fav_flag
			});
			if (this.state.remove_fav_sec === true) {
				if (this.state.activity && !!this.state.activity.duration) {
					this.setState({
						edit_icon: this.state.activity.id ? '/assets/images/edit.png' : '/assets/images/transparent.gif',
					});
				}
				else {
					this.setState({
						edit_icon: '/assets/images/transparent.gif'
					});
				}

				if (this.state.activity && !!this.state.activity.duration) {
					this.submitForm()
					this.createFavoriteActivity()
				}
			}
		}
		else {
			this.setState({
				add_to_fav: window.keystrings['com.numi.add_to_favorites'],
				fav_flag: !this.state.fav_flag,
				add_fav_off: "/assets/images/fav_off.png",
				edit_icon: this.state.activity.id ? '/assets/images/edit.png' : '/assets/images/transparent.gif'
			});
			if (!!this.state.activity.duration) {
				this.removeFavoriteActivity()
			}
			if (this.state.activity && !!this.state.activity.duration) {
				this.removeFavoriteActivity()
			}
		}

		if (this.state.add_to_fav === "Remove from Favorites" && this.state.remove_fav_sec === true && this.state.item_name !== "Edit Activity") {
			this.setState({
				remove_fav_sec: true, add_fav_sec: false, show_cal: true
			});
			$('.remove_favorite').show()
			$('.add_favorite').hide()
		}

	}

	CustomActivity(custom_activity) {

		Header.startLoader();
		var ca = { custom_activity }
		const options = {
			url: window.API_URL + 'v1/custom_activities',
			method: 'POST',
			credentials: 'same-origin',
			headers: Header.setAuthHeaders(),
			data: ca
		};

		axios(options)
			.then(response => {
				Header.stopLoader();
				this.setState({ activity: response.data.custom_activity, remove_fav_sec: true, add_fav_sec: false, edit_icon: "/assets/images/edit.png", item_name: this.state.description, show_cal: true, duration: response.data.custom_activity.duration, logCaloriesBurned: response.data.custom_activity.calories })
				if (this.state.remove_fav_sec) {
					this.createFavoriteActivity(response.data);
				}
				window.localStorage.setItem('create_fav_flag', response.data.custom_activity.favorite)

			})
			.catch(error => {
				Header.stopLoader();
				Header.apiErrors(error);
				Sentry.captureException(error);
			});
	}

	createFavoriteActivity(data) {
		Header.startLoader();
		var favorite_activities = { "favorite_activity": { "doable_id": data ? data.custom_activity.id : this.state.activity.id, "doable_type": "CustomActivity", "favorite": this.state.fav_flag } }
		window.localStorage.setItem('fav_flag', this.state.fav_flag)

		//create favorited activity
		const options = {
			url: window.API_URL + 'v1/favorite_activities',
			method: 'POST',
			credentials: 'same-origin',
			headers: Header.setAuthHeaders(),
			data: favorite_activities
		};

		axios(options)
			.then(response => {
				Header.stopLoader();
				this.setState({ favorite_activity: response.data.favorite_activity })
			})
			.catch(error => {
				Header.stopLoader();
				Header.apiErrors(error);
				Sentry.captureException(error);
			});
	}

	removeFavoriteActivity() {
		var favorite_activities = { "favorite_activity": { "doable_id": this.state.activity.id, "doable_type": "CustomActivity", "favorite": false } }
		Header.startLoader();
		//create favorited activity
		const options = {
			url: window.API_URL + 'v1/favorite_activities',
			method: 'POST',
			credentials: 'same-origin',
			headers: Header.setAuthHeaders(),
			data: favorite_activities
		};

		axios(options)
			.then(response => {
				Header.stopLoader();
				this.setState({ favorite_activity: response.data.favorite_activity })
			})
			.catch(error => {
				Header.stopLoader();
				Header.apiErrors(error);
				Sentry.captureException(error);
			});

	}

	logActivity() {

		var calories_burned = this.state.logCaloriesBurned
		var assigned_date = $('.activity_date  .react-datepicker__input-container input').val().split("/");
		assigned_date = assigned_date[2] + "-" + assigned_date[0] + "-" + assigned_date[1];
		var doable_id = this.state.activity.id
		var doable_type = "CustomActivity";

		var activity_history = { "activity_history": { doable_id, doable_type, duration: this.state.duration, calories: calories_burned, assigned_date: assigned_date, favorite: this.state.fav_flag } }
		Header.startLoader();

		const options = {
			url: window.API_URL + 'v1/activity_histories',
			method: 'POST',
			credentials: 'same-origin',
			headers: Header.setAuthHeaders(),
			data: activity_history
		};

		axios(options)
			.then(response => {
				Header.stopLoader();
				Header.stopLoader();
				this.setState({ activity_history: response.data.activity_history })
				window.localStorage.setItem('assigned_date', response.data.activity_history.assigned_date);
				this.props.history.push('/journal');
			})
			.catch(error => {
				Header.stopLoader();
				Header.apiErrors(error);
				Sentry.captureException(error);
			});


	}

	submitForm() {
		this.CustomActivity({ "description": this.state.description, "duration": this.state.duration, "calories": this.state.calories_burned })
		this.setState({ add_fav_sec: false })
		this.setState({ remove_fav_sec: true })
		this.setState({ item_name: this.state.activity.description })
	}

	_inputChange(event) {
		event.preventDefault();
		this.setState({
			[event.currentTarget.id]: event.currentTarget.value
		});
	}

	editChange(event) {
		this.setState({
			duration: event.target.value
		});
	}

	changeCalories(event) {
		this.setState({
			calories_burned: event.target.value
		});
	}

	componentDidMount() {

		$('.create_activity_note').text(window.keystrings['com.numi.create_activity_note']);

		$('.activity_calories_burned').text(window.keystrings['com.numi.calories_burned'] + '*');

		$('.create_activity_subheader').text(window.keystrings['com.numi.create_activity_subheader']);

		$('.edit_activity_checkbox_text').text(window.keystrings['com.numi.edit_activity_checkbox_text']);

		$('.activity_duration').text(window.keystrings['com.numi.duration']);

		$('.activity_continue_without_logging').text(window.keystrings['com.numi.continue_without_logging']);

		$('.custom_activity_log_it').text(window.keystrings['com.numi.log_it']);
		$('.back_to_journal_text').append(window.keystrings['com.numi.back_to_journal'])
		$('.name_text').text(window.keystrings['com.numi.food.name'])
		$('.create_activity_name_placeholder_text').attr('placeholder', window.keystrings['com.numi.create_food_name_placeholder'])
		$('.create_food_save_text').text(window.keystrings['com.numi.create_food_save'])
		$('.create_food_update_text').text(window.keystrings['com.numi.create_food_update'])
		$('.food_details_date_text').text(window.keystrings['com.numi.food_details_date'])


		$(document).on("input", ".numeric", function (e) {
			this.value = this.value.replace(/[^0-9]/g, '');

		});

		$(document).on("input", ".numeric_calorie", function (e) {
			this.value = this.value.replace(/^0+|[^0-9]/g, '');

		});

		$(document).on("input", ".burn_calorie", function (e) {
			if (typeof (parseInt(this.state.calories_burned)) != 'number') {
				return;
			}
			this.setState({
				calories_burned: this.state.calories_burned.replace(/[^0-9]/g, '')
			});

		}.bind(this));

		$('.create_activity .mandatory').keyup(function () {
			var $emptyFields = $('.inner_sec  input.mandatory').filter(function () {
				return $.trim(this.value) === "";
			});

			if (!$emptyFields.length) {
				$('.create_activity button').removeClass('btn disabled').removeAttr('disabled', '');
			}
			else {
				$('.create_activity button').addClass('btn disabled').attr('disabled', 'disabled');
			}
		});



		Header.changeDate(window.localStorage.getItem('assigned_date'));



	}

	render() {

		return (

			<div>

				<div className="table_display">

					<div className="journal_div">

						<div className="section_left pull-left">
							<Navigation />
						</div>
						<div className="section_right pull-left">
							<figure><Adspeed /></figure>
							<div className="journal_rht create_activity">
								<p className="mb">
									<Link to="/journal" className="back_to back_to_journal_text"><img alt="" src="/assets/images/back_white_icon.png" className="vlb" /> </Link>
								</p>

								<h1 className="journal_hdr pull-left">{this.state.item_name}<span className="font18 color5 d_block" style={{ display: this.state.show_cal ? 'block' : 'none' }}> {this.state.calories_burned} {this.state.calories_burned > 0 ? 'calories burned' : ''}</span></h1>
								<div className="pull-right  font15 color5 add_fav_div relative top10 cursor_pointer"><img alt="" src={this.state.edit_icon} onClick={this.editActivity} /> <span className="add_fav" onClick={this.addFavorite}><img alt="" src={this.state.add_fav_off} /> {this.state.add_to_fav}</span></div>
								<div className="clear"></div>
								<hr />
								<div className="add_favorite" style={{ display: this.state.add_fav_sec ? 'block' : 'none' }}>
									<p className="font18 color6"><b>{this.state.create_activity_hdr}</b></p>
									<p className="font18 color6">Please note:<br /> <span className="create_activity_subheader"></span></p>

									<div className="pull-left inner_sec mt2">
										<p className="border-bottom">
											<span className="font16 color6 text-left left_cnt name_text"></span>
											<span className="font16 color6 text-right right_cnt"><input type="text" className="mandatory create_activity_name_placeholder_text" onChange={this._inputChange} onKeyUp={this._inputChange} placeholder="" value={this.state.description} id='description' /></span>
										</p>
										<p>
											<span className="font16 color6 text-left left_cnt activity_calories_burned"></span>
											<span className="font16 color6 text-right right_cnt"><input onChange={this._inputChange} onKeyUp={this._inputChange} maxLength="3" type="text" className="numeric_calorie mandatory calorie_validate" placeholder="0" value={this.state.calories_burned} id='calories_burned' /></span>
											<br />
											<span className="font12 mt color7 create_activity_note"></span>
										</p>
									</div>
									<div className="pull-right inner_sec mt2">
										<p>
											<span className="font16 color6 text-left left_cnt"><p className="activity_duration"></p></span>
											<span className="font16 color6 text-right right_cnt"><input onChange={this._inputChange} onKeyUp={this._inputChange} type="text" className="numeric right_input" maxLength="2" placeholder="30" id='duration' value={this.state.duration} /> Min</span>
										</p>
									</div>
									<div className="clear"></div>
									<p className="remember_me" style={{ display: this.state.update_checkbox ? 'block' : 'none' }}>
										<input id="activity_check" type="checkbox" checked={this.state.isChecked} onChange={this.rememberCheckbox} />
										<label htmlFor="activity_check">
											<span className="bullet_icon">
												<i></i>
											</span>
											<span className="font15 color7">
												<span className="edit_activity_checkbox_text"></span>
											</span>
										</label></p>
									<span onClick={this.submitForm.bind(this)} className="display_block" id="create-activity" >
										<button disabled={this.state.disable} className={this.state.disable_btn + " create_food_save_text"} style={{ display: this.state.save_btn ? 'block' : 'none' }}  ></button></span>
									<span className="display_block  mt5" onClick={this.updateActivity.bind(this)} id="update-activity" >
										<button disabled={this.state.disable} className={this.state.disable_btn + " create_food_update_text"} style={{ display: this.state.update_btn ? 'block' : 'none' }} onClick={this.updateActivity}></button></span>
								</div>

								<div className="remove_favorite edit_activity_page" style={{ display: this.state.remove_fav_sec ? 'block' : 'none' }}>

									<div className="pull-left inner_sec">
										<p className="border-bottom">
											<span className="font16 color6 text-left left_cnt"><p className="activity_duration"></p></span>
											<span className="font16 color6 text-right right_cnt"><input onChange={this.editChange.bind(this)} onKeyUp={this.editChange.bind(this)} type="text" maxLength="2" className="numeric right_input" placeholder="30" value={this.state.duration} id='log_duration' /> Min</span>
										</p>
										<div className="activity_date">
											<span className="font16 color6 text-left left_cnt food_details_date_text"></span>
											<span className="font16 relative color6 text-right right_cnt vlm"><DatePicker onChange={this.handleChange} selected={parse(this.state.startDate)} minDate={parse(window.localStorage.getItem('profile_start_date'))} maxDate={addDays(new Date(), 90)} /></span>
										</div>
									</div>
									<div className="pull-right inner_sec">
										<p>
											<span className="font16 color6 text-left left_cnt activity_calories_burned"> </span>
											<span className="font16 color6 text-right right_cnt"><input onChange={this.changeCalories.bind(this)} onKeyUp={this.changeCalories.bind(this)} type="text" className="numeric small_input_field calorie_validate" maxLength="3" placeholder="0" value={this.state.calories_burned} id='calories_burned' /></span>
										</p>
									</div>
									<div className="clear"></div>
									<p className="mt2"><button onClick={this.logActivity}><span className="custom_activity_log_it"></span></button></p>
									<p>	<button className="font18 without_log" onClick={() => this.props.history.push('/log_activity')}><span className="activity_continue_without_logging"></span></button></p>
								</div>

							</div>
							<Footer />
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Create_activity;
