import React from 'react';
import Header from "../header.js";
import Footer from "../footer.js";
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import $ from 'jquery';
import * as Sentry from "@sentry/react";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = { email: '', account_error: '', emailError: '', Error_class: '', hdr_txt: window.keystrings['com.numi.please_enter_email'], account_not_found: false, nutricustomer: false };
    this.verifyAccount = this.verifyAccount.bind(this);

    this.handleForgotEmailClick = this.handleForgotEmailClick.bind(this);
  }

  accountNotFound(e) {
    this.setState({
      account_not_found: !this.state.account_not_found, nutricustomer: !this.state.nutricustomer
    });
  }

  hideAccountNotFound = () => {
    this.setState({
      account_not_found: false
    })
  }

  verifyAccount(e) {
    this.setState({
      emailError: '',
      Error_class: '',
      hdr_txt: window.keystrings['com.numi.instruction_resetting_password']
    });
    $('#verifyAccount,.email_box').hide();
    $('#verify_ok').show();


    this.setState({
      account_not_found: false
    })
  }


  //To get the input value of email
  inputEmailChange(e) {
    this.setState({ email: e.target.value });
  }

  ClickOk(e) {
    if (window.localStorage.getItem("user_id") !== null) {
      this.props.history.push('/nutri_link');
    }
    else {
      this.props.history.push('/login');
    }
  }

  // Forgot Password api call
  handleForgotEmailClick(e) {
    var email_regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!email_regex.test($('#verify_email').val())) {
      this.setState({
        emailError: window.keystrings['com.numi.invalid_email_error'],
        Error_class: 'error_border'
      });
    }
    else {
      Header.startLoader(); // start loader

      const options = {
        url: window.API_URL + "password.json",
        method: 'POST',
        credentials: 'same-origin',
        data: {
          user: {
            email: this.state.email
          },
        }
      };

      axios(options)
        .then(response => {
          Header.stopLoader(); // stop loader
          this.verifyAccount();
        })
        .catch(error => {
          Header.stopLoader(); // stop loader
          var evt = document.createEvent("CustomEvent");
          evt.initCustomEvent('ForgotPasswordFail', true, false, false);
          document.dispatchEvent(evt);
          this.setState({
            account_not_found: true
          })
          Sentry.captureException(error);
        });
    }
  }
  componentDidMount() {
    $('.modal-backdrop').remove();
    $('body').removeClass('modal-open');
    $('.remove_fade').on('click', function () {
      $('.modal').modal('hide');
    });
    $('.fade_link_popup').remove();
    /*keystrings*/
    $('.forgot_password_text').text(window.keystrings['com.numi.forgot_password'])
    $('.email_me_text').text(window.keystrings['com.numi.email_me'])
    $('.ok_text').text(window.keystrings['com.numi.ok'])
  }

  render() {

    return (
      <div className="main_container">

        <div className="forgot_password_hero">
          <div><Link to="/login" className="forgor_pass_link"><img alt="" src="/assets/images/numi_small_logo.png" className="logo img-responsive" /></Link></div>
          <div className="forgot_sec">
            <h1 className="forgot_password_text"> </h1>
            <h6 className="hdr_txt">{this.state.hdr_txt}</h6>
            <div className='email_box'><input type="text" onChange={this.inputEmailChange.bind(this)} onKeyUp={this.inputEmailChange.bind(this)} value={this.state.email} className={this.state.Error_class} placeholder="Email" id="verify_email" /><p className="error text-left">{this.state.emailError}</p></div>

            <button id="verifyAccount" onClick={this.handleForgotEmailClick.bind(this)} onKeyUp={this.handleForgotEmailClick.bind(this)} className="email_me_text"></button>
            <button id="verify_ok" onClick={this.ClickOk.bind(this)} onKeyUp={this.ClickOk.bind(this)} className="ok_text"></button>
          </div>
        </div>
        <Footer />
        <AccountNotFound accountNotFound={this.accountNotFound} nutricustomer={this.state.nutricustomer} account_not_found={this.state.account_not_found} hideAccountNotFound={this.hideAccountNotFound} />
      </div>
    );
  }
}


class AccountNotFound extends React.Component {

  componentDidMount() {
    /*keystrings*/
    $('.forgot_password_alert_title_text').text(window.keystrings['com.numi.forgot_password_alert_title'])
    $('.forgot_password_alert_message_text').text(window.keystrings['com.numi.forgot_password_alert_message'])
    $('.im_a_nutrisystem_customer_text').text(window.keystrings['com.numi.im_a_nutrisystem_customer'])
    $('.ok_all_caps_text').text(window.keystrings['com.numi.ok_all_caps'])
    $('.sign_up_for_numi_text').text(window.keystrings['com.numi.sign_up_for_numi'])
    $('.forgot_password_text').text(window.keystrings['com.numi.forgot_password'])
    $('.account_not_found_sign_up_text').text(window.keystrings['com.numi.account_not_found_sign_up'])



    /*keystrings*/
  }

  render() {
    return (

      <>


        <Modal show={this.props.account_not_found} onHide={this.props.hideAccountNotFound} id="account_not_found">
          <Modal.Header>
            <img alt="" src="/assets/images/close_grey.png" className="cursor_pointer" onClick={this.props.hideAccountNotFound} />
          </Modal.Header>
          <Modal.Body>
            <div className="modal-body width77">
              <img alt="" src="/assets/images/warning_tri.png" />
              <p className="font26 color3 mt5 font700 no_user_found">{window.keystrings['com.sobe.unsubscribe.no_user_found']}</p>
              <p className="font16 color6 correct_log_information">{window.keystrings['com.numi.correct_log_information']}</p>
              <p><button className="buuton280 login_fail_ok try_again_caps_text" onClick={this.props.hideAccountNotFound}>{window.keystrings['com.numi.try_again_caps_text']}</button></p>
            </div>
          </Modal.Body>
        </Modal>





      </>
    )
  }
}

export default ForgotPassword;
