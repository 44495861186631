import React from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';
import Cookies from 'js-cookie';


class Footer extends React.Component {


  componentWillUnmount() {
    this.unlisten();
  }

  // Set global api url and headers for api calls.
  componentDidMount() {
    // var options = {
    //   url: `${process.env.API_URL ? process.env.API_URL : ''}v1/translations.json`,
    //   method: 'GET',
    //   credentials: 'include',
    //   // headers: Header.setAuthHeaders(),
    //   cache: true,
    // };

    // axios(options)
    //   .then(response => {

    //     // console.log("response", response);

    //     // console.log(Cookies.get('CSRF-TOKEN'))

    //   });



    this.unlisten = this.props.history.listen(location => {
      setTimeout(function () {
        if (window.localStorage.getItem('getInterval') === "true" && window.location.href.indexOf('/journal') === -1) {
          $('.adspeed_script').remove();
          window.zid = window.localStorage.getItem('zid');
          var script = document.createElement('script');
          script.classList.add('adspeed_script');
          script.src = "https://g.adspeed.net/ad.php?do=js&zids=" + window.zid + "&wd=-1&ht=-1&target=_blank";
          document.getElementsByTagName('script')[0].parentNode.appendChild(script);
          if (window.AdSpeed_jQuery) {
            window.AdSpeed_jQuery('banner', window.localStorage.getItem('zid'));
            localStorage.setItem('show_banner', $('#banner').html());
          }
        }
      }, 500)
    });
    /*keystrings*/
    if (window.keystrings) {
      $('.footer_numi_by_nutrisystem_text').text(window.keystrings['com.numi.footer.numi_by_nutrisystem'])
      $('.footer_about_text').text(window.keystrings['com.numi.footer.about'])
      $('.footer_faq_text').text(window.keystrings['com.numi.footer.faq'])
      $('.footer_privacy_policy_text').text(window.keystrings['com.numi.footer.privacy_policy'])
      $('.footer_terms_conditions_text').text(window.keystrings['com.numi.footer.terms_conditions'])
      $('.footer_body_text').text(window.keystrings['com.numi.footer.body'])
      $('.footer_body_rights_reserved_text').text(window.keystrings['com.numi.footer.body_rights_reserved'].replace('{year}', new Date().getFullYear()))
      $('.footer_body_address_text').text(window.keystrings['com.numi.footer.body_address'])
      $('.footer_faq_text').attr('href', window.keystrings['com.numi.footer.faq_link'])
      $('.footer_privacy_policy_text').attr('href', window.keystrings['com.numi.footer.privacy_policy_link'])
      $('.footer_terms_conditions_text').attr('href', window.keystrings['com.numi.footer.terms_conditions_link'])
      $('.footer_app_store_link').attr('href', window.keystrings['com.numi.footer.app_store_link'])
      $('.footer_google_play_link').attr('href', window.keystrings['com.numi.footer.google_play_link'])
      $('.footer_do_not_sell').data('href', window.keystrings['com.numi.footer.do_not_sell_web']);
    }
    /*keystrings*/
    setTimeout(() => {
      if ($('#banner').is(':empty') && (localStorage.getItem("show_banner") !== null || localStorage.getItem('show_banner') !== undefined)) {
        $('#banner').html(localStorage.getItem('show_banner'));
      }
    }, 501)
  }

  setDoNotSellHref() {
    $('.footer_do_not_sell').attr('href', $('.footer_do_not_sell').data('href') + window.location.href.split("?")[0]);
  }

  render() {
    return (
      <div className="footer-div">
        <footer className="mobile-footer">
          <div className="site_map">
            <h6 className="footer_numi_by_nutrisystem_text"> </h6>
            <div className="footer_display">
              <ul className="text-right text-lft mobilefooterlist">
                {/*<li><a href="http://leaf.nutrisystem.com/about-us/?_ga=2.37801891.1603673177.1505196626-1260569899.1500031454" target="_blank" className="footer_about_text"></a></li>*/}
                <li><a href="https://leaf.nutrisystem.com/faqs/" rel="noopener noreferrer" target="_blank" className="footer_faq_text"> </a></li>
                <li><a rel="noopener noreferrer" target="_blank" className="footer_privacy_policy_text"> </a></li>
                <li><a href="http://leaf.nutrisystem.com/terms-and-conditions/" rel="noopener noreferrer" target="_blank" className="footer_terms_conditions_text"> </a></li>
                {/* <li className="footer-li-webview"><a href="https://itunes.apple.com/app/apple-store/id826749176?pt=328600&ct=mobilegate&mt=8" rel="noopener noreferrer" className="footer_app_store_link" target="_blank"><img alt="" src="/assets/images/app_store.png" /></a></li>
              <li className="footer-li-webview"><a href="https://play.google.com/store/apps/details?id=com.nutrisystem.numi" rel="noopener noreferrer" className="footer_google_play_link" target="_blank"><img alt="" src="/assets/images/google_play.png" /></a></li> */}
                <div className="mobileview-li">
                  <li className="footer-li"><a href="https://itunes.apple.com/app/apple-store/id826749176?pt=328600&ct=mobilegate&mt=8" rel="noopener noreferrer" className="footer_app_store_link" target="_blank"><img alt="" src="/assets/images/app_store.png" /></a></li>
                  <li className="footer-li"><a href="https://play.google.com/store/apps/details?id=com.nutrisystem.numi" rel="noopener noreferrer" className="footer_google_play_link" target="_blank"><img alt="" src="/assets/images/google_play.png" /></a></li>
                </div>
              </ul>
              <ul className="text-right text-lft mobilefooterlist">
                <li className="footer-li-webview"><a href="https://itunes.apple.com/app/apple-store/id826749176?pt=328600&ct=mobilegate&mt=8" rel="noopener noreferrer" className="footer_app_store_link" target="_blank"><img alt="" src="/assets/images/app_store.png" /></a></li>
                <li className="footer-li-webview"><a href="https://play.google.com/store/apps/details?id=com.nutrisystem.numi" rel="noopener noreferrer" className="footer_google_play_link" target="_blank"><img alt="" src="/assets/images/google_play.png" /></a></li>
              </ul>
            </div>
          </div>
          <p className="footer_body_text"></p>
          {Cookies.get('CCPA') == 'true' ? <p><a rel="noopener noreferrer" target="_blank" href={this.setDoNotSellHref} className="footer_do_not_sell foot_lft_hdr" style={{ textDecoration: 'underline' }} onMouseOver={this.setDoNotSellHref}>Do Not Sell and Limit Disclosure of my Personal Information.</a></p> : ' '}
          <div className="site_map">
            <div className="col-lg-4 col-md-5 col-sm-12 px-0 mb-0">
              <p className="footer_body_rights_reserved_text"></p>
            </div>
            <div className="col-lg-8 col-md-7 col-sm-12 px-0 mb-0 text-lg-right text-left">
              <p className="footer_body_address_text"></p>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default withRouter(Footer);