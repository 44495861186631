
import React from 'react';
import Header from "../header.js";
import { Link } from 'react-router-dom';
import Footer from "../footer.js";
import { CountAsEditValidate } from '../journal/popup.js';
import Navigation from "../nav.js";
import Adspeed from "../adspeed.js";
import UnassinedPopup from "./unassigned_food.js";
import axios from 'axios';
import $ from 'jquery';
import * as Sentry from "@sentry/react";
// import $ from 'jquery';

class CreateFood extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      item_name: window.keystrings['com.numi.create_food_title'], add_fav_sec: true, add_to_fav: window.keystrings['com.numi.add_to_favorites'], remove_from_fav: window.keystrings['com.numi.remove_from_favorites'], add_fav_off: "/assets/images/fav_on.png", remove_fav_sec: false, disable_btn: 'btn disabled', disable: 'disabled', save_btn: true, update_btn: false, create_activity_hdr: window.keystrings['com.numi.create_food_header'], fav_calories: 100, show_cal: false, update_checkbox: false, isChecked: false, favorite_enabled: true, add_fav: "add_fav", serving_units: [], s3_images_path: ''
    };
    this.addFavorite = this.addFavorite.bind(this);
    this.editFood = this.editFood.bind(this);
    this.rememberCheckbox = this.rememberCheckbox.bind(this);
  }
  rememberCheckbox(e) {
    this.setState({
      isChecked: !this.state.isChecked
    });
  }

  createArrowUp() {

    var oldValue = parseFloat($("input.serving_size").val());


    if (oldValue === 9) {
      return
    }
    else {
      var newVal = parseFloat(oldValue) + 0.5;
    }

    $(".weight_validate").val(newVal);



  }

  createArrowDown() {

    var oldValue = parseFloat($("input.serving_size").val());
    // Don't allow decrementing below zero
    if (oldValue === 0.5) {
      return
    } else {
      var newVal = parseFloat(oldValue) - 0.5;
      $(".weight_validate").val(newVal);

    }
  }



  editFood() {
    if (window.localStorage.getItem('setEditfood') === "true") {

      this.setState({
        item_name: window.keystrings['com.numi.edit_food_title'],
        create_activity_hdr: window.keystrings['com.numi.edit_food_header'],
        save_btn: false,
        update_btn: true,
        add_fav: "add_fav add_fav_edit",
        isChecked: true

      });

      var edit_id = window.localStorage.getItem('consumable_id');
      var consumable_type = window.localStorage.getItem('consumable_type');
      var assigned_date = window.localStorage.getItem('assigned_date');
      const options = {
        url: window.API_URL + "v1/consumables/consumable_id/" + edit_id + "/consumable_type/" + consumable_type + "?assigned_date=" + assigned_date,
        method: 'GET',
        credentials: 'same-origin',
        headers: Header.setAuthHeaders(),
      };

      axios(options)
        .then(response => {

          if (response && response.data) {
            this.setState({ get_nutriFacts: '', custom_food: response.data, update_checkbox: true, favorite_enabled: response.data.consumable.favorite_foods, remove_from_fav: (response.data.consumable.favorite_foods === true) ? 'Remove from Favorites' : 'Add to Favorites', add_fav_off: (response.data.consumable.favorite_foods === true) ? '/assets/images/fav_on.png' : "/assets/images/fav_off.png" });
            this.refs.title.value = response.data.consumable.food_name;
            this.refs.serving_size.value = response.data.consumable.serving_size;
            this.refs.serving_size_unit.value = response.data.consumable.serving_unit;
            var serving_size_unit1 = response.data.consumable.serving_unit;
            if (this.state.serving_units.indexOf(serving_size_unit1) < 0) {
              $("#serving_size").prepend("<option value='" + serving_size_unit1 + "' selected='selected'>" + serving_size_unit1 + "</option>");
            }
            this.refs.bar_code.value = response.data.consumable.upc;
            this.refs.calories.value = response.data.consumable.calories;
            this.refs.brand.value = response.data.consumable.brand;
            /* retrieve custom food nutrients*/
            response.data.consumable.nutrients.forEach(function (item) {
              var name = item.name.toLowerCase();
              if (name !== "calories") {
                if (this.refs.nutrifacts_child.refs[name]) {
                  this.refs.nutrifacts_child.refs[name].value = item.value;
                }
              }

            }, this);
            this.setState({
              get_nutriFacts: response.data.consumable.nutrients
            });
            /* retrieve food categories*/
            $('.un_assign').text('');

            for (var i = 0; i < response.data.consumable.food_categories.length; i++) {
              var name = response.data.consumable.food_categories[i].name;
              var img_name = "/" + name + "_SMALL_USED.png";
              var img_type = name.toLowerCase() + "_unfill";
              var img_src = response.data.consumable.s3_images_path + "/";
              var count = parseFloat(response.data.consumable.food_categories[i].value).toFixed();
              for (var c = 0; c < count; c++) {
                $('.un_assign').append('<img name="' + name + '" class="filled_icon cursor_pointer ' + img_type + '" value=' + img_type + ' src=' + img_src + name + img_name + '>');
              }
            }
            var $emptyFields = $('.create_food  input.mandatory').filter(function () {
              return $.trim(this.value) === "";
            });

            if (!$emptyFields.length && $('.un_assign img').length > 0) {
              $('#create-custom-food').removeClass('btn disabled');
              $('#create-custom-food').find('button').removeClass('pointer_none');
            }
            else {
              $('#create-custom-food').addClass('btn disabled');
              $('#create-custom-food').find('button').addClass('pointer_none');
            }
          }
        })
        .catch(error => {
          Header.stopLoader();
          Header.apiErrors(error);
          Sentry.captureException(error);
        });

    }
    else {
      this.setState({
        item_name: window.keystrings['com.numi.create_food_title'],
        create_activity_hdr: window.keystrings['com.numi.create_food_header'],
        save_btn: true,
        update_btn: false,

      });
    }


  }


  addFavorite() {
    if (this.state.remove_from_fav === "Add to Favorites") {

      this.setState({
        favorite_enabled: true,
        remove_from_fav: window.keystrings['com.numi.remove_from_favorites'],
        add_fav_off: "/assets/images/fav_on.png",
      });
    }
    else {
      this.setState({
        favorite_enabled: false,
        remove_from_fav: window.keystrings['com.numi.add_to_favorites'],
        add_fav_off: "/assets/images/fav_off.png",
      });
    }
    /* in edit page, favorite food need to be updated on click of fav icon (before clicking update button itself)*/
    if (this.state.item_name === "Edit Food") {
      var fav_data = { "favorite_food": { "consumable_id": this.state.custom_food.consumable_id, "consumable_type": "CustomFood", "favorite": this.state.favorite_enabled } };
      const options = {
        url: window.API_URL + "v1/favorite_foods",
        method: 'POST',
        credentials: 'same-origin',
        headers: Header.setAuthHeaders(),
        data: fav_data
      };

      axios(options)
        .then(response => {

        })
        .catch(error => {
          Header.stopLoader();
          Header.apiErrors(error);
          Sentry.captureException(error);
        });
    }
  }


  CustomFood(custom_food, action_type) {
    var ca = { custom_food }
    var api;
    var method;
    if (action_type === "create") {
      api = window.API_URL + "v1/custom_foods";
      method = "POST";
    }
    else {
      var edit_id = window.localStorage.getItem('consumable_id');
      api = window.API_URL + "v1/custom_foods/" + edit_id;
      method = "PUT";
    }
    if ($('.un_assign .filled_icon').length > 0) {

      const options = {
        url: api,
        method: method,
        credentials: 'same-origin',
        headers: Header.setAuthHeaders(),
        data: ca
      };

      axios(options)
        .then(response => {
          if (response && response.data) {
            this.setState({ custom_food: response.data });

            localStorage.removeItem('setEditfood');
            var assigned_date = window.localStorage.getItem('assigned_date');
            if ($('#food_check')[0].checked === true) {
              /* redirect to journal page after update food with previous all entries*/

              this.props.history.push({
                pathname: '/journal',
                state: { food_id: response.data.custom_food.id }
              });
            }
            else {
              /* redirect food details page after create food*/
              window.localStorage.setItem('consumable_id', response.data.custom_food.id);
              window.localStorage.setItem('consumable_type', 'CustomFood');
              window.localStorage.setItem('assigned_date', assigned_date);
              window.localStorage.setItem('from_createfood', true);  /* for show and display of continue without logging button in food details page*/
              this.props.history.push({
                pathname: '/food_details',
                state: { food_id: response.data.custom_food.id }
              });
            }
          }
        })
        .catch(error => {
          Header.stopLoader();
          $('.serving_error').html('');
          $('.serving_error').text('');
          if (error && error.response && error.response.data && error.response.data.error && error.response.data.error.calories !== undefined && error.response.data.error.serving_size !== undefined) {
            $('.serving_error').html('<p>' + error.response.data.error.calories + '</p><p>' + error.response.data.error.serving_size + '</p>');
          }
          else if (error && error.response && error.response.status > 400) {
            $('.serving_error').text(error.response.statusText);
          }
          else {
            alert(error.message ? error.message : 'Something went wrong!');
          }
          Sentry.captureException(error);
        });

    }
    else {
      $('#unassigned_popup').modal('show');
      $('.unassigned_popup').hide();
      $('.unassigned_food').show();
      /*key string*/
      $('.unassigned-food-ks').text(window.keystrings['com.numi.food.unassigned_food']);
      $('.food-unassigned-popup-msg-ks').text(window.keystrings['com.numi.food.unassigned_msg']);
      $('.food-assign-food-category-ks').text(window.keystrings['com.numi.assign_food_category']);
      $('.food-create-as-other-ks').text(window.keystrings['com.numi.food.create_as_other']);
      $('.unassigned-food-categories-done-ks').text(window.keystrings['com.numi.done']);
      /*End Key String*/
    }
  }

  get_nutrients_info(nutrifacts) {
    var custom_food_nutrients = [];
    var name;
    for (var key in nutrifacts) {
      if (nutrifacts[key].value !== "") {
        if (key.indexOf('_') > 0) {
          var names = key.split('_');
          name = names[0].replace(names[0].charAt(0), names[0].charAt(0).toUpperCase()) + "_" + names[1].replace(names[1].charAt(0), names[1].charAt(0).toUpperCase());
        }
        else {
          name = key.replace(key.charAt(0), key.charAt(0).toUpperCase());
        }
        custom_food_nutrients.push({ "name": name, "value": nutrifacts[key].value });
      }
    }
    return custom_food_nutrients
  }

  submitCreateForm() {
    window.localStorage.setItem('create_custom', 'true');
    var action_type;
    if ($('#enable_food_update').is(':visible')) {
      action_type = "update"
    }
    else {
      action_type = "create"
    }

    var title = this.refs.title.value;
    var serving_size = this.refs.serving_size.value;
    var serving_size_unit = this.refs.serving_size_unit.value;
    var upc = this.refs.bar_code.value;
    var calories = this.refs.calories.value;
    var brand = this.refs.brand.value;
    var favorite = JSON.parse(this.refs.favorite_value.value);
    var custom_food_nutrients = this.get_nutrients_info(this.refs.nutrifacts_child.refs);
    var food_category_custom_foods = [];

    var filled_cat = this.refs.unassigned_child.state.food_icon_array.map(function (item) { return item.name.toLocaleLowerCase() + "_unfill" })

    for (var i = 0; i < filled_cat.length; i++) {
      var count = document.getElementsByClassName('un_assign')[0];
      var final_count = count.getElementsByClassName(filled_cat[i]).length;
      if (final_count > 0)
        food_category_custom_foods.push({ "name": filled_cat[i].toUpperCase().slice(0, -7), "value": final_count });
    }
    var static_food_category_custom_foods = food_category_custom_foods;
    for (var ii = 0; ii < filled_cat.length; ii++) {
      var flag = false;
      for (var jj = 0; jj < static_food_category_custom_foods.length; jj++) {
        if (filled_cat[ii].toUpperCase().slice(0, -7) === static_food_category_custom_foods[jj]["name"]) {
          flag = true;
        }
      }
      if (flag === false) {
        food_category_custom_foods.push({ "name": filled_cat[ii].toUpperCase().slice(0, -7), "value": 0 });
      }
    }



    var data = { "name": title, "serving_size": serving_size, "serving_size_unit": serving_size_unit, "upc": upc, "calories": calories, "brand": brand, "custom_food_nutrients": custom_food_nutrients, "food_category_custom_foods": food_category_custom_foods, "favorite": favorite }

    /* only for update action*/
    if (action_type === "update") {
      if ($('#food_check')[0].checked === true) {
        data["update_previous_entries"] = true;
      }
    }

    this.CustomFood(data, action_type)
  }

  componentDidMount() {

    /*keystrings*/
    $('.back_to_journal_text').append(window.keystrings['com.numi.back_to_journal'])
    $('.find_created_food_note_text').text(window.keystrings['com.numi.find_created_food_note'])
    $('.food_name_text').text(window.keystrings['com.numi.food.name'])
    $('.create_food_name_placeholder_text').attr('placeholder', window.keystrings['com.numi.create_food_name_placeholder'])
    $('.food_serving_size_text').text(window.keystrings['com.numi.food.serving_size'])
    $('.create_food_brand_text').prepend(window.keystrings['com.numi.create_food_brand'])
    $('.create_food_brand_placeholder_text').attr('placeholder', window.keystrings['com.numi.create_food_brand_placeholder'])
    $('.create_food_optional_text').text(window.keystrings['com.numi.create_food_optional'])
    $('.upc_keypad_title_text').prepend(window.keystrings['com.numi.upc_keypad_title'])
    $('.food_calories_text').text(window.keystrings['com.numi.food.calories'])
    $('.food_count_as_text').text(window.keystrings['com.numi.food.count_as'])
    $('.unassigned_text').prepend(window.keystrings['com.numi.unassigned'])
    $('.create_food_edit_text').text(window.keystrings['com.numi.create_food_edit'])
    $('.create_food_nutrition_facts_text').prepend(window.keystrings['com.numi.create_food_nutrition_facts'])
    $('.update_previous_food_entries_text').text(window.keystrings['com.numi.update_previous_food_entries'])
    $('.create_food_save_text').text(window.keystrings['com.numi.create_food_save'])
    $('.create_food_update_text').text(window.keystrings['com.numi.create_food_update'])
    $('.done_text').text(window.keystrings['com.numi.done'])
    /*keystrings*/
    this.editFood();

    $(document).on("keyup", "#nutrifacts input.text_value,.add_calories", function (e) {
      var curr_input_value;
      this.value = this.value.replace(/[^0-9.]/g, '');
      if (this.value.length >= 4) {
        if (this.value.indexOf('.') < 0) {
          curr_input_value = this.value + '.';
          curr_input_value.split('');
        }
        else {
          curr_input_value = this.value;
        }

        if (this.value.length === 4) {
          if (this.value.indexOf('.') < 0) {
            this.value = curr_input_value[0] + curr_input_value[1] + curr_input_value[2] + curr_input_value[4] + curr_input_value[3];
          }
          else {
            this.value = curr_input_value;

          }
        }
        else {
          if (this.value.length === 5) {
            if (this.value.indexOf('.') < 0) {
              this.value = curr_input_value[0] + curr_input_value[1] + curr_input_value[2] + curr_input_value[5] + curr_input_value[3] + curr_input_value[4];
            }
            else {
              this.value = curr_input_value;
            }
          }

        }
      }

    });


    $(document).on("input", ".numeric", function (e) {
      this.value = this.value.replace(/[^0-9.]/g, '');
    });

    /* Enable and disable save and update button on keyup*/

    $('.create_food .mandatory,.create_food .select_mandatory').on('keyup change', function () {
      var $emptyFields = $('.create_food  input.mandatory').filter(function () {
        return $.trim(this.value) === "";
      });

      if (!$('#enable_food_update').is(':visible')) {
        if (!$emptyFields.length) {
          $('#create-custom-food').removeClass('btn disabled');
          $('#create-custom-food').find('button').removeClass('pointer_none');
        }
        else {
          $('#create-custom-food').addClass('btn disabled');
          $('#create-custom-food').find('button').addClass('pointer_none');
        }
      }
      else {
        if (!$emptyFields.length) {
          $('#create-custom-food').removeClass('btn disabled');
          $('#create-custom-food').find('button').removeClass('pointer_none');
        }
        else {
          $('#create-custom-food').addClass('btn disabled');
          $('#create-custom-food').find('button').addClass('pointer_none');
        }
      }

    });

    /* it will bring the selected food categories in the pop up*/
    var fill_array = [];
    $(document).on("click", ".click_edit", function (e) {
      e.stopImmediatePropagation();
      if ($(".weight_validate").val() !== '1') {
        $('#count_as_validate').modal('show');
      } else {
        $('#unassigned_popup').modal('show');
      }
      $('#unassigned_popup .filled_icon').remove();
      $('.unassigned_popup').show();
      /* key strings*/
      $('.unassigned-food-categories-ks').text(window.keystrings['com.numi.edit_food_categories'])
      $('.unassigned_text').text(window.keystrings['com.numi.unassigned_text'])
      $('.unassigned-food-categories-done-ks').text(window.keystrings['com.numi.done']);
      $('.unassigned-food-categories-msg-ks').text(window.keystrings['com.numi.edit_food_categories_description']);
      /*End key string*/
      $('.unassigned_food').hide();
      fill_array = [];
      for (var i = 0; i < $('.un_assign img').length; i++) {

        if ($('.un_assign img').length) {
          fill_array.push(document.getElementsByClassName("filled_icon")[i].getAttribute("value"));
          var dd = document.getElementsByClassName(fill_array[i])[0].cloneNode(true);
          var ff = $('li[value=' + fill_array[i] + ']');
          ff.prepend(dd);
          var kk = ff.attr('id');
          var parend_li = ff.find('.filled_icon').length;
          $('.un_assign_txt').hide();
          if (kk !== undefined && $('.unassigned_popup').find('.' + kk + '').length > 0) {
            $('.unassigned_popup').find('.' + kk + '').find('.current_food_count span').text(parend_li);
          }
          else {
            if (kk !== undefined) {
              $('.food_count_img .food_count_images').append('<span class=' + kk + '><span class="current_food_count"> <span> ' + parend_li + '</span></span>' + kk + '<span class="comma">, </span> </span>');
            }
            else {
              $('#Oth img').click();
              $('.Oth').append('<span class="comma">, </span>');
            }


          }
          if ($('.unassigned_popup').find('.food_count_images span').length >= 1) {
            $('.selected_food_txt').remove();

            $('.food_count_img').append('<span class="selected_food_txt">selected</span>')

          }
          else {
            $('.selected_food_txt').remove();
          }
          var jj = $('img.' + fill_array[i] + '').attr('src').replace('SMALL', 'LARGE');
          $('#unassigned_popup').find('img.' + fill_array[i] + '').attr('src', jj);
        }
      }
      if (($('.current_food_count:last-of-type').length > 0) && ($('.current_food_count:last-of-type').indexOf(',') > 0)) {
        $('.current_food_count:last-of-type').replace(',', '');
      }

      if ($('.comma').length === 1) {
        $('.comma').hide();
      }
      else {
        $('.comma:last').hide();
      }


    });


    $(document).on("input", ".upc_code", function (e) {
      this.value = this.value.replace(/^0+|[^0-9]/g, '');
    });


    $(document).on("input", ".weight_validate", function (e) {
      e.stopImmediatePropagation();
      var kk = $(".weight_validate").val().split('');
      if (parseInt($(".weight_validate").val()) > 9 || $(".weight_validate").val() === "" || $(".weight_validate").val() === "0" || kk[1] === "0" || (kk[0] === 9 && kk[1] === ".")) {
        if ($(".weight_validate").val() === "0") {
          $('#create-custom-food').addClass('pointer_none');
          $('#create-custom-food').find('button').addClass('btn disabled');
        }
        else {
          $(".weight_validate").val('');
          $('#create-custom-food').addClass('pointer_none');
          $('#create-custom-food').find('button').addClass('btn disabled');
        }

      }
      else {
        $('#create-custom-food').removeClass('pointer_none');
        $('#create-custom-food').find('button').removeClass('btn disabled');
        if ($(".weight_validate").val().length > 4) {
          $(".weight_validate").val($(".weight_validate").val().slice(0, 4));
        }
      }

    });

    const options = {
      url: window.API_URL + 'v1/global_configs/key/CUSTOM_FOOD_SERVING_SIZE_UNITS',
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        if (response && response.data) {
          this.setState({ serving_units: response.data.global_config.value.reverse(), s3_images_path: response.data.global_config.s3_images_path })
        }
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });
    setTimeout(function () {
      window.create_pill_icons = $(".weight_validate").val();
    }, 1500);
  }
  render() {
    var select_dropdown = []
    this.state.serving_units.map(function (serving_unit, i) {
      var serving_hash = {
        "option_value": serving_unit,
        "id": serving_unit
      }
      select_dropdown.push(serving_hash);
      return select_dropdown;
    });


    var select_dropdown_list = select_dropdown.map(function (option, i) {
      return (
        <option value={option.option_value} key={i}>{option.option_value}</option>

      )
    });


    return (
      <div>

        <div className="table_display">

          <div className="journal_div journal create_food_page">

            <div className="section_left pull-left">
              <Navigation />
            </div>
            <div className="section_right pull-left">
              <figure><Adspeed /></figure>
              <div className="journal_rht create_activity">
                <div className="task_todo">
                  <div className="create_food">
                    <p className="mb">
                      <Link to="/journal" className="back_to back_to_journal_text"><img alt="" src="/assets/images/back_white_icon.png" className="vlb" /> </Link>
                    </p>
                    <h1 className="journal_hdr pull-left">{this.state.item_name}</h1>
                    <div className="pull-right  font15 color5 add_fav_div relative top10 cursor_pointer"><span className={this.state.add_fav} onClick={this.addFavorite}><img alt="" src={this.state.add_fav_off} /> {this.state.remove_from_fav}</span></div>
                    <div className="clear"></div>
                    <hr />
                    <p className="color6 font18"><b>{this.state.create_activity_hdr}</b></p>
                    <p className="color6 font18 find_created_food_note_text"></p>
                    <p className="serving_error text-center font14" id="error"></p>
                    <div className="pull-left inner_sec mt2">
                      <p className="border-bottom">
                        <span className="font16 color6 text-left left_cnt food_name_text"></span>
                        <span className="font16 color6 text-right right_cnt">
                          <input type="text" className="mandatory create_food_name_placeholder_text" maxLength="254" placeholder="" ref='title' />
                        </span>
                      </p>
                      <p className="border-bottom">

                        <span className="font16 color6 text-left left_cnt food_serving_size_text"></span>
                        <span className="font16 color6 text-right right_cnt">
                          <span className="mobile_relative show_inline">
                            <input type="number" maxLength="3" readOnly step="0.5" min="0.5" max="9" className="mandatory serving_size text-center weight_validate right_input" ref='serving_size' defaultValue="1" /><div className="mobile_number_arrows"><div className="mobile_arrows_font"><img alt="" onClick={this.createArrowUp.bind(this)} src="/assets/images/top_arrow.png" /></div><div className="mobile_arrows_font"><img alt="" src="/assets/images/bottom_arrow.png" onClick={this.createArrowDown.bind(this)} /></div></div>
                          </span>
                          <span className="select_back">
                            <select id="serving_size" className="select_mandatory" ref='serving_size_unit'>
                              {select_dropdown_list}
                            </select>
                          </span>
                        </span>
                      </p>
                      <div className="activity_date">
                        <span className="font16 color6 text-left left_cnt create_food_brand_text"> <span className="color8 font12 create_food_optional_text"></span></span>
                        <span className="font16 color6 text-right right_cnt vlm"><input type="text" className="create_food_brand_placeholder_text" placeholder="" ref='brand' /></span>
                      </div>
                      <p className="border-bottom">
                        <span className="font16 color6 text-left left_cnt upc_keypad_title_text"> <span className="color8 font12 create_food_optional_text"></span></span>
                        <span className="font16 color6 text-right right_cnt">
                          <input type="text" className="upc_code numeric" maxLength="13" placeholder="" ref='bar_code' />
                        </span>
                      </p>



                    </div>
                    <div className="pull-right inner_sec mt2">
                      <p className="border-bottom">
                        <span className="font16 color6 text-left left_cnt food_calories_text"></span>
                        <span className="font16 color6 text-right right_cnt">
                          <input type="text" className="mandatory numeric add_calories" placeholder="0" ref='calories' maxLength="6" />
                        </span>
                      </p>
                      <p className="border-bottom">
                        <span className="font16 color6 text-left left_cnt vlt food_count_as_text"></span>
                        <span className="font16 color6 text-right right_cnt">
                          <span className="text-right un_assign font14 unassigned_text"> &nbsp;&nbsp;</span><span className="text-right click_edit edit_txt font14 cursor_pointer vlt create_food_edit_text"></span>
                        </span>
                      </p>
                      <p className="border-bottom">
                        <span className="font16 color6 text-left left_cnt create_food_nutrition_facts_text"> <span className="color8 font12 create_food_optional_text"></span></span>
                        <span className="font16 color6 text-right right_cnt">
                          <span className="text-right edit_txt font14 cursor_pointer create_food_edit_text nutrifacts_popup_click" data-toggle="modal" data-target="#nutrifacts"></span>
                        </span>
                      </p>
                    </div>
                    <div className="clear"></div>
                    <p className="remember_me" style={{ display: this.state.update_checkbox ? 'block' : 'none' }}>
                      <input id="food_check" type="checkbox" checked={this.state.isChecked} onChange={this.rememberCheckbox} />
                      <label htmlFor="food_check">
                        <span className="bullet_icon">
                          <i></i>
                        </span>
                        <span className="font15 color7 update_previous_food_entries_text">

                        </span>
                      </label></p>
                    <input type="hidden" id="favorite_value" defaultValue={this.state.favorite_enabled} ref='favorite_value' />
                    <div className="clear"></div>
                    <p className={this.state.disable_btn} id="create-custom-food">
                      <button onClick={this.submitCreateForm.bind(this)} style={{ display: this.state.save_btn ? 'block' : 'none' }} id="enable_food_save" className="pointer_none create_food_save_text"></ button>
                      <button onClick={this.submitCreateForm.bind(this)} className="pointer_none create_food_update_text" id="enable_food_update" style={{ display: this.state.update_btn ? 'block' : 'none' }}></button>
                    </p>

                  </div>
                </div>


              </div>




              <Footer />

              <CountAsEditValidate />
              <UnassinedPopup ref="unassigned_child" get_s3_path={this.state.s3_images_path} />

              <Nutrifacts ref="nutrifacts_child" get_all_nutrifacts={this.state.get_nutriFacts} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}



class Nutrifacts extends React.Component {
  constructor(props) {
    super(props);
    this.state = { nutrients_response: [] }
  }

  callNutrifcats() {

    var kk = this.props.get_all_nutrifacts;
    if (this.props.get_all_nutrifacts !== undefined) {
      for (var i = 0; i < this.props.get_all_nutrifacts.length; i++) {
        window.initial_value = kk[i];
        $('.nutri_facts li span.item_name').each(function () {
          if (window.initial_value.display_name === $(this).text()) {
            $(this).next().find('input.text_value').prop('value', window.initial_value.value);
          }
        });

      }
    }


  }

  componentDidMount() {

    const options = {
      url: window.API_URL + 'v1/nutrients/details',
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        if (response && response.data) {
          this.setState({ nutrients_response: response.data.nutrients })
        }
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });


  }
  render() {

    var nutri_facts = this.state.nutrients_response.filter(function (nutrient) {
      return (nutrient.display_name !== 'Calories')
    });

    var nutri_facts_list = nutri_facts.map(function (options, i) {
      return (
        <li key={i}><span className="item_name">{options.display_name}</span> <span><input type="text" className="text-center  text_value" maxLength="6" ref={options.display_name.replace(/ /g, '_').toLowerCase()} /> &nbsp;&nbsp;{options.units}</span></li>

      );

    });
    return (

      <div className="modal fade" id="nutrifacts">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" onClick={this.callNutrifcats.bind(this)} aria-label="Close"><img alt="" src="/assets/images/close_grey.png" /></button>
            </div>
            <div className="modal-body">
              <h3><b className="create_food_nutrition_facts_text"></b></h3>
              <ul className="nutri_facts">
                {nutri_facts_list}
              </ul>
              <p><button data-dismiss="modal" className="done_text">Done</button></p>
            </div>
          </div>
        </div>
      </div>

    )
  }
}








export default CreateFood;
