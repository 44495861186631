import React from 'react';
import { Link } from 'react-router-dom';
import Footer from "../footer.js";
import Header from "../header.js";
import Navigation from "../nav.js";
import Adspeed from "../adspeed.js";
import DatePicker from "react-datepicker";

import moment from 'moment';
import axios from 'axios';
import { addDays, parse } from 'date-fns';
import $ from 'jquery';
import * as Sentry from "@sentry/react";



class Log_task extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      item_name: window.localStorage.getItem('item_clicked_header'), fav_calories: '', add_fav_sec: true, add_to_fav: window.keystrings['com.numi.add_to_favorites'], add_fav_off: "/assets/images/fav_off.png", remove_fav_sec: false, show_cal: false, create_activity_hdr: window.keystrings['com.numi.create_activity_header'], save_btn: true, update_btn: false, isChecked: true, update_checkbox: false, log_duration: window.localStorage.getItem("log_duration"), calories: window.localStorage.getItem("calories"), assigned_date: '', log_date: window.localStorage.getItem('assigned_date')
    };
    this.addFavorite = this.addFavorite.bind(this);
    //this.editActivity = this.editActivity.bind(this);
    this.rememberCheckbox = this.rememberCheckbox.bind(this);
    this.logInputChange = this.logInputChange.bind(this)
    this.logActivity = this.logActivity.bind(this)
    this.createFavoriteActivity = this.createFavoriteActivity.bind(this)
    this.removeFavoriteActivity = this.removeFavoriteActivity.bind(this)
    this.submitForm = this.submitForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  calorieChange(e) {
    if (e.currentTarget.value.length <= 3) {
      this.setState({
        calories: e.currentTarget.value
      });
    }
  }

  handleChange(date) {
    this.setState({
      log_date: date
    });

    var complete_date = moment(date).format('MMMM DD, YYYY');
    var actual_date = moment().format('MMM DD');
    var pageload_selecetd_date = moment(date).format('MMM DD');
    if (pageload_selecetd_date === actual_date) {
      $('.log_task_page .today_date').show();
      $('.log_task_page .today_date').text('Today')
    }
    else {
      $('.log_task_page .today_date').show();
      $('.log_task_page .today_date').text(complete_date)
    }
    Header.changeDate(date);
  }

  logInputChange(event) {
    event.preventDefault();
    if (event.target.value.split('')[0] !== 0) {
      window.localStorage.setItem("log_duration", event.target.value)
      this.setState({
        log_duration: window.localStorage.getItem("log_duration")
      });



      if (window.localStorage.getItem('doable_type') === "Activity" && event.target.value !== "") {
        Header.startLoader();

        const options = {
          url: window.API_URL + 'v1/doables/doable_id/' + window.localStorage.getItem('doable_id') + '/doable_type/' + window.localStorage.getItem('doable_type') + '?duration=' + window.localStorage.getItem("log_duration") + '',
          method: 'GET',
          credentials: 'same-origin',
          headers: Header.setAuthHeaders(),
        };

        axios(options)
          .then(response => {
            Header.stopLoader();
            this.setState({
              calories: response.data.doable.calories
            });
          })
          .catch(error => {
            Header.stopLoader();
            Header.apiErrors(error);
            Sentry.captureException(error);
          });


      }
    }
  }

  rememberCheckbox(e) {
    this.setState({ isChecked: !this.state.isChecked });
  }

  addFavorite() {



    var doable_id = window.localStorage.getItem("doable_id")
    if (this.state.add_to_fav === "Add to Favorites") {
      this.setState({
        add_to_fav: window.keystrings['com.numi.remove_from_favorites'],
        add_fav_off: "/assets/images/fav_on.png",

      });
      window.localStorage.setItem('search_fav', 'true');
      window.localStorage.setItem('create_fav', 'true');
      window.localStorage.setItem('recent_fav', 'true')

      if (doable_id) {
        //this.submitForm()
        this.createFavoriteActivity()
      }

    }
    else {
      this.setState({
        add_to_fav: window.keystrings['com.numi.add_to_favorites'],
        add_fav_off: "/assets/images/fav_off.png",
      });
      window.localStorage.setItem('search_fav', 'false');
      window.localStorage.setItem('create_fav', 'false');
      window.localStorage.setItem('recent_fav', 'false')
      if (doable_id) {
        this.removeFavoriteActivity()
      }
    }

    if (this.state.add_to_fav === "Remove from Favorites" && this.state.remove_fav_sec === true && this.state.item_name !== "Edit Activity") {
      this.setState({
        remove_fav_sec: true, add_fav_sec: false, show_cal: true
      });

      $('.remove_favorite').show()
      $('.add_favorite').hide()
    }

  }

  removeFavoriteActivity() {
    var favorite_activities = { "favorite_activity": { "doable_id": window.localStorage.getItem("doable_id"), "doable_type": window.localStorage.getItem("doable_type"), "favorite": false } }

    //create favorited activity
    const options = {
      url: window.API_URL + 'v1/favorite_activities',
      method: 'POST',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
      data: favorite_activities
    };

    axios(options)
      .then(response => {
        this.setState({ favorite_activity: response.data.favorite_activity })
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });

  }

  createFavoriteActivity() {
    Header.startLoader();
    var doable_id = window.localStorage.getItem("doable_id")
    var doable_type = window.localStorage.getItem("doable_type");

    var getValue = window.localStorage.getItem('fav_recent_type');
    var set_fav;
    switch (getValue) {
      case "1":
        set_fav = window.localStorage.getItem('recent_fav')
        break;
      case "2":
        set_fav = window.localStorage.getItem('create_fav')
        break;
      case "3":
        set_fav = window.localStorage.getItem('search_fav')
        break;
      default:
        set_fav = '3'

    }


    var favorite_activities = { "favorite_activity": { doable_id, doable_type, favorite: set_fav } }

    //create favorited activity
    const options = {
      url: window.API_URL + 'v1/favorite_activities',
      method: 'POST',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
      data: favorite_activities
    };

    axios(options)
      .then(response => {
        Header.stopLoader();
        this.setState({ favorite_activity: response.data.favorite_activity })
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });
  }

  logActivity() {
    var calories_burned = this.state.calories
    var assigned_date = moment($('.activity_date  .react-datepicker__input-container input').val()).format("YYYY-MM-DD");
    var doable_id = window.localStorage.getItem("doable_id");
    var doable_type = window.localStorage.getItem("doable_type");

    var activity_history = { "activity_history": { doable_id, doable_type, duration: this.state.log_duration, calories: calories_burned, assigned_date, activity_history_id: window.localStorage.getItem('quicklog_id') } }
    Header.startLoader();

    const options = {
      url: window.API_URL + 'v1/activity_histories',
      method: 'POST',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
      data: activity_history
    };

    axios(options)
      .then(response => {
        Header.stopLoader();
        this.setState({ activity_history: response.data.activity_history })
        if (this.state.activity_history) {
          window.localStorage.setItem('assigned_date', moment($('.log_task_page  .react-datepicker__input-container input').val()).format("YYYY-MM-DD"));
          this.props.history.push('/journal');
        }
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });


  }

  submitForm() {
    this.CustomActivity({ "description": this.state.description, "duration": this.state.log_duration, "calories": this.state.calories_burned })
    this.setState({ add_fav_sec: false })
    this.setState({ remove_fav_sec: true })
    this.setState({ item_name: this.state.activity.description })
  }


  componentDidMount() {
    /*keystrings*/
    $('.create_activity_calories_burned').text(window.keystrings['com.numi.calories_burned']);
    $('.create_activity_duration').text(window.keystrings['com.numi.duration']);
    $('.back_to_log_activity_text').append(window.keystrings['com.numi.back_to_log_activity'])
    $('.food_details_date_text').text(window.keystrings['com.numi.food_details_date'])
    $('.log_it_text').text(window.keystrings['com.numi.log_it'])

    Header.changeDate(window.localStorage.getItem('assigned_date'));


    var getValue = window.localStorage.getItem('fav_recent_type');
    var get_fav;
    switch (getValue) {
      case "1":
        get_fav = window.localStorage.getItem('recent_fav')
        break;
      case "2":
        get_fav = window.localStorage.getItem('create_fav')
        break;
      case "3":
        get_fav = window.localStorage.getItem('search_fav')
        break;
      default:
        get_fav = '3'

    }



    if (get_fav === "true") {
      this.setState({
        add_to_fav: window.keystrings['com.numi.remove_from_favorites'],
        add_fav_off: "/assets/images/fav_on.png",

      });
    }
    else {
      this.setState({
        add_to_fav: window.keystrings['com.numi.add_to_favorites'],
        add_fav_off: "/assets/images/fav_off.png",

      });
    }



    $(document).on("input", ".add_favorite .numeric", function (e) {

      this.value = this.value.replace(/^0+|[^0-9]/g, '');

    });

    $('.create_activity .mandatory').keyup(function () {
      var $emptyFields = $('.inner_sec  input.mandatory').filter(function () {
        return $.trim(this.value) === "";
      });

      if (!$emptyFields.length) {
        $('.avtivity_submit_btn').removeClass('btn disabled');
        $('.avtivity_submit_btn button').removeClass('pointer_none');
      }
      else {
        $('.avtivity_submit_btn').addClass('btn disabled');
        $('.avtivity_submit_btn button').addClass('pointer_none');
      }
    });
    window.localStorage.setItem('item_clicked_header', '')
    //window.localStorage.setItem("doable_id",'')


    if (window.localStorage.getItem('doable_type') === "CustomActivity") {
      $('.log_item_hdr').hide();

      $('.log_description').text(window.localStorage.getItem('category_details'));
      $('.log_description').addClass('font40 color3');
    }
    else {
      $('.log_item_hdr').show();
      $('.log_item_hdr').text(window.localStorage.getItem('category_name'))
      $('.log_description').text(window.localStorage.getItem('category_details'));
      $('.log_description').removeClass('font40 color3');
    }


    var $emptyFields = $('.inner_sec  input.mandatory').filter(function () {
      return $.trim(this.value) === "";
    });

    if (!$emptyFields.length) {
      $('.avtivity_submit_btn').removeClass('btn disabled');
      $('.avtivity_submit_btn button').removeClass('pointer_none');
    }
    else {
      $('.avtivity_submit_btn').addClass('btn disabled');
      $('.avtivity_submit_btn button').addClass('pointer_none');
    }

  }

  render() {
    return (
      <div>

        <div className="table_display">
          <div className="journal_div log_task_page">
            <div className="section_left pull-left">
              <Navigation />
            </div>
            <div className="section_right pull-left">
              <figure><Adspeed /></figure>
              <div className="journal_rht create_activity task_log">
                <p className="mb">
                  <Link to="/log_activity" className="back_to back_to_log_activity_text"><img alt="" src="/assets/images/back_white_icon.png" className="vlb" /> </Link>
                </p>
                <div className="task_todo">
                  <div className="pull-left width70">
                    <h1 className="journal_hdr word_warp_txt log_item_hdr activity_name_header"> </h1>
                    <div className="color5 d_block log_description"></div>
                    <div className="font18 color5 d_block" > {this.state.calories} {this.state.calories > 0 ? 'calories burned' : ''}</div>
                  </div>
                  <div className="pull-right  font15 color5 add_fav_div relative top10 cursor_pointer"><span className="add_fav" onClick={this.addFavorite}><img alt="" src={this.state.add_fav_off} /> {this.state.add_to_fav}</span></div>
                  <div className="clear"></div>
                  <hr />
                  <div className="add_favorite" style={{ display: this.state.add_fav_sec ? 'block' : 'none' }}>
                    <div className="pull-left inner_sec">
                      <p className="border-bottom">
                        <span className="font16 color6 text-left left_cnt"><p className="create_activity_duration"></p></span>
                        <span className="font16 color6 text-right right_cnt">
                          <input type="text" className="burn_calorie mandatory numeric" placeholder="30" maxLength="2" onChange={this.logInputChange} id="duration" value={this.state.log_duration === "undefined" ? "" : this.state.log_duration} /> Min</span>
                      </p>
                      <div className="activity_date">
                        <span className="font16 color6 text-left left_cnt food_details_date_text"></span>
                        <span className="font16 color6 text-right right_cnt vlm relative">
                          <DatePicker inputFormat="MM/DD/YYYY" id="assigned_date" selected={parse(this.state.log_date)} onChange={this.handleChange} minDate={parse(window.localStorage.getItem('profile_start_date'))} maxDate={addDays(new Date(), 90)} />

                        </span>
                      </div>
                    </div>
                    <div className="pull-right inner_sec">
                      <p>
                        <span className="font16 color6 text-left left_cnt"><p className="create_activity_calories_burned"></p></span>
                        <span className="font16 color6 text-right right_cnt"><input type="number" className="burn_calorie mandatory numeric" placeholder="0" maxLength="3" onChange={this.calorieChange.bind(this)} id="calories" value={this.state.calories === "undefined" ? "" : this.state.calories} /></span>
                      </p>
                    </div>
                    <div className="clear"></div>
                    <p className="avtivity_submit_btn mt2 padding0 btn disabled"><button className="avtivity_log_it_btn pointer_none log_it_text" onClick={this.logActivity}></button></p>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Log_task;
