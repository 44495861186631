import React from 'react';
import { Link } from 'react-router-dom';
import Footer from "../footer.js";
import Header from "../header.js";
import DatePicker from "react-datepicker";
import Navigation from "../nav.js";
import Adspeed from "../adspeed.js";
import moment from 'moment';
import { addDays, parse } from 'date-fns';
import axios from 'axios';
import $ from 'jquery';
import * as Sentry from "@sentry/react";

class Activity_log extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      item_name: window.localStorage.getItem('activity_name'), fav_calories: 200, add_fav_sec: true, add_to_fav: window.keystrings['com.numi.add_to_favorites'], add_fav_off: "/assets/images/fav_off.png", remove_fav_sec: false, show_cal: false, create_activity_hdr: window.keystrings['com.numi.create_activity_header'], save_btn: true, update_btn: false, isChecked: true, update_checkbox: false, activities_list: [], activity_dur: '', activity_speed: '', favorite_activities: [], recent_activities: [], burn_calorie: '', activity_distance: '', edit_date: window.localStorage.getItem('assigned_date')
    };

    this.addLogFavorite = this.addLogFavorite.bind(this);
    this.editActivity = this.editActivity.bind(this);
    this.rememberCheckbox = this.rememberCheckbox.bind(this);
    this.getActivities = this.getActivities.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(date) {
    this.setState({
      edit_date: date
    });

    var complete_date = moment(date).format('MMMM DD, YYYY');
    var actual_date = moment().format('MMM DD');
    var pageload_selecetd_date = moment(date).format('MMM DD');
    if (pageload_selecetd_date === actual_date) {
      $('.log_activities_page .today_date').show();
      $('.log_activities_page .today_date').text('Today')
    }
    else {
      $('.log_activities_page .today_date').show();
      $('.log_activities_page .today_date').text(complete_date)
    }
    Header.changeDate(date);
  }

  handleActivitycalorie(e) {
    if (e.currentTarget.value.length <= 3) {
      this.setState({ burn_calorie: e.currentTarget.value });
    }

  }
  handleActivityInput(e) {
    Header.startLoader();
    e.preventDefault();
    this.setState({ [e.currentTarget.id]: e.currentTarget.value });
    var activity_name = window.localStorage.getItem('activity_name');


    if (e.currentTarget.value !== "" && !isNaN(parseInt(e.currentTarget.value))) {
      const options = {
        url: window.API_URL + 'v1/calculated_activities/calculate?activity_header=' + activity_name + '&duration=' + $('#activity_dur').val() + '&distance=' + $('#activity_distance').val() + '&speed=' + parseInt($('#activity_speed').val()) + '',
        method: 'GET',
        credentials: 'same-origin',
        headers: Header.setAuthHeaders(),
      };

      axios(options)
        .then(response => {
          this.setState({ activity_speed: response.data.calculated_activity.speed, activity_dur: response.data.calculated_activity.duration, burn_calorie: response.data.calculated_activity.calories, activity_distance: response.data.calculated_activity.distance });
          $('.duartion_error').text('');
          if (this.state.activity_dur === 0) {
            $('.edit_logit_btn').addClass('pointer_none');
            $('.edit_logit_btn button').addClass('btn disabled');
            if (response && response.data && response.data.error) {
              $('.duartion_error').text(response.data.error);
            }
            else if (response && response.status > 400) {
              $('.duartion_error').text(response.data.statusText);
            }
          }
          else {
            $('.duartion_error').text('');
            $('.edit_logit_btn').removeClass('pointer_none');
            $('.edit_logit_btn button').removeClass('btn disabled');
          }
          setTimeout(function () {
            Header.stopLoader();
          }, 1000)

        })
        .catch(error => {
          Header.stopLoader();
          $('.duartion_error').text('');
          if (this.state.activity_dur === 0) {
            $('.edit_logit_btn').addClass('pointer_none');
            $('.edit_logit_btn button').addClass('btn disabled');
            if (error && error.response && error.response.data && error.response.data.error) {
              $('.duartion_error').text(error.response.data.error);
            }
            else if (error && error.response && error.response.status > 400) {
              $('.duartion_error').text(error.response.statusText)
            }
          }
          else {
            $('.duartion_error').text('');
            $('.edit_logit_btn').removeClass('pointer_none');
            $('.edit_logit_btn button').removeClass('btn disabled');
          }
          Sentry.captureException(error);
        });
    }
    else {
      Header.stopLoader();
    }
  }


  handleActivitydistance(e) {
    Header.startLoader();
    e.preventDefault();
    this.setState({ [e.currentTarget.id]: e.currentTarget.value });
    var activity_name = window.localStorage.getItem('activity_name');
    if (e.currentTarget.value.indexOf('.', e.currentTarget.value.indexOf('.') + 1) !== -1) {
      $('.edit_logit_btn').addClass('pointer_none');
      $('.edit_logit_btn button').addClass('btn disabled');
    } else {
      $('.edit_logit_btn').removeClass('pointer_none');
      $('.edit_logit_btn button').removeClass('btn disabled');
    }

    if (e.currentTarget.value !== "" && !isNaN(parseInt(e.currentTarget.value))) {

      const options = {
        url: window.API_URL + 'v1/calculated_activities/calculate?activity_header=' + activity_name + '&duration=' + $('#activity_dur').val() + '&distance=' + $('#activity_distance').val() + '&speed=' + parseInt($('#activity_speed').val()) + '',
        method: 'GET',
        credentials: 'same-origin',
        headers: Header.setAuthHeaders(),
      };

      axios(options)
        .then(response => {
          this.setState({ activity_speed: response.data.calculated_activity.speed, activity_dur: response.data.calculated_activity.duration, burn_calorie: response.data.calculated_activity.calories });
          setTimeout(function () {
            Header.stopLoader();
          }, 1000)
        })
        .catch(error => {
          Header.stopLoader();
          Header.apiErrors(error);
          Sentry.captureException(error);
        });
    }
    else {
      Header.stopLoader();
    }
  }

  rememberCheckbox(e) {
    this.setState({
      isChecked: !this.state.isChecked
    });
  }

  editActivity() {

    this.setState({
      add_to_fav: window.keystrings['com.numi.remove_from_favorites'],
      add_fav_off: "/assets/images/fav_on.png",
      edit_icon: '',
      add_fav_sec: true,
      remove_fav_sec: false,
      item_name: window.keystrings['com.numi.edit_activity_title'],
      create_activity_hdr: window.keystrings['com.numi.edit_activity_header'],
      show_cal: false,
      save_btn: false,
      update_btn: true,
      update_checkbox: true
    });
  }



  addLogFavorite() {
    var doable_id = window.localStorage.getItem("log_activity_id");
    if (this.state.add_to_fav === "Add to Favorites") {
      this.setState({
        add_to_fav: window.keystrings['com.numi.remove_from_favorites'],
        add_fav_off: "/assets/images/fav_on.png",

      });
      window.localStorage.setItem("log_activity_fav", 'true');

      if (doable_id) {
        this.addLogActivity();
      }

    }
    else {
      this.setState({
        add_to_fav: window.keystrings['com.numi.add_to_favorites'],
        add_fav_off: "/assets/images/fav_off.png",
      });
      window.localStorage.setItem("log_activity_fav", 'false');
      if (doable_id) {
        this.addLogActivity();
      }
    }


  }

  addLogActivity() {
    Header.startLoader();

    var favorite_activities = { "favorite_activity": { "doable_id": window.localStorage.getItem("log_activity_id"), "doable_type": "CalculatedActivity", "favorite": window.localStorage.getItem("log_activity_fav") } }


    //create favorited activity

    const options = {
      url: window.API_URL + "v1/favorite_activities",
      method: 'POST',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
      data: favorite_activities
    };

    axios(options)
      .then(response => {
        Header.stopLoader();
        this.setState({ favorite_activity: response.data.favorite_activity })
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });
  }



  getActivities() {

    const options = {
      url: window.API_URL + 'v1/activities',
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        this.setState({ activities_list: response.data.activities })
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });
  }

  calculatedActivity() {
    var duration = this.state.activity_dur;
    var calories_burned = this.state.burn_calorie;
    var distance = $('#activity_distance').val();
    var speed = this.state.activity_speed;
    var assigned_date = moment($('.log_activities_page  .react-datepicker__input-container input').val()).format("YYYY-MM-DD");
    var doable_id = window.localStorage.getItem("log_activity_id");
    var doable_type = "CalculatedActivity";

    var activity_history = { "activity_history": { doable_id: doable_id, doable_type: doable_type, duration: duration, calories: calories_burned, assigned_date: assigned_date, speed: speed, distance: distance, activity_history_id: window.localStorage.getItem('quicklog_id') } }
    Header.startLoader();

    const options = {
      url: window.API_URL + 'v1/activity_histories',
      method: 'POST',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
      data: activity_history
    };

    axios(options)
      .then(response => {
        Header.stopLoader();

        if (response && response.data && response.data.error) {
          $('.duartion_error').text(response.data.error);
        }
        else if (response && response.status > 400) {
          $('.duartion_error').text(response.data.statusText);
        }
        this.setState({ activity_history: response.data.activity_history })
        if (this.state.activity_history !== undefined) {
          window.localStorage.setItem('assigned_date', moment($('.log_activities_page  .react-datepicker__input-container input').val()).format("YYYY-MM-DD"));
          this.props.history.push('/journal');
        }

      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });
  }


  componentDidMount() {

    $('.activity_calorie_burned').text(window.keystrings['com.numi.calories_burned']);
    $('.activity_log_duration').text(window.keystrings['com.numi.duration'] + '*');
    $('.activity_distance').text(window.keystrings['com.numi.distance'] + '*');
    $('.activity_speed').text(window.keystrings['com.numi.speed']);
    $('.back_to_log_activity_text').append(window.keystrings['com.numi.back_to_log_activity'])
    $('.edit_activity_checkbox_text').text(window.keystrings['com.numi.edit_activity_checkbox_text'])
    $('.log_it_text').text(window.keystrings['com.numi.log_it'])

    $('.log_activities_page #activity_distance').keyup(function () {
      var distance_val = $('#activity_distance').val();
      if (distance_val.indexOf('.') > 0) {
        $('.log_activities_page  #activity_distance').attr('maxlength', '5')
      }
      else {
        $('.log_activities_page  #activity_distance').attr('maxlength', '4')
      }
    });

    var activity_name = window.localStorage.getItem('activity_name');

    Header.startLoader();

    const options = {
      url: window.API_URL + 'v1/calculated_activities/calculate?activity_header=' + activity_name + '&duration=30&distance=3',
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        Header.stopLoader();
        if (response && response.data) {
          this.setState({ activity_speed: response.data.calculated_activity.speed, activity_dur: response.data.calculated_activity.duration, burn_calorie: response.data.calculated_activity.calories, activity_distance: response.data.calculated_activity.distance });

          window.localStorage.setItem('log_activity_id', response.data.calculated_activity.id);
          window.localStorage.setItem('log_activity_fav', response.data.calculated_activity.favorite);

          if (response.data.calculated_activity.favorite === true) {
            this.setState({
              add_to_fav: 'Remove from Favorites',
              add_fav_off: "/assets/images/fav_on.png",

            });


          }
          else {
            this.setState({
              add_to_fav: 'Add to Favorites',
              add_fav_off: "/assets/images/fav_off.png",
            });

          }
        }
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });


    $(document).on("input", ".log_activities_page .inner_sec input[type='text']:not(#activity_distance)", function (e) {
      this.value = this.value.replace(/[^0-9]/g, '');

    });

    $(document).on("input", ".log_activities_page .inner_sec #activity_distance", function (e) {
      this.value = this.value.replace(/[^0-9.]/g, '');

    });

    $('.log_activities_page .mandatory').keyup(function () {
      var $emptyFields = $('.inner_sec  input.mandatory').filter(function () {
        return $.trim(this.value) === "";
      });

      if (!$emptyFields.length) {
        $('.log_activities_page button').removeClass('btn disabled').removeAttr('disabled', '');
      }
      else {
        $('.log_activities_page button').addClass('btn disabled').attr('disabled', 'disabled');
      }
    });


    Header.changeDate(window.localStorage.getItem('assigned_date'));


  }

  render() {


    return (
      <div>

        <div className="table_display">
          <div className="journal_div log_activities_page">
            <div className="section_left pull-left">
              <Navigation />
            </div>
            <div className="section_right pull-left">
              <figure><Adspeed /></figure>
              <div className="journal_rht create_activity task_log">
                <div className="task_todo">
                  <div className="running">
                    <p className="mb">
                      <Link to="/log_activity" className="back_to back_to_log_activity_text"><img alt="" src="/assets/images/back_white_icon.png" className="vlb" /> </Link>
                    </p>

                    <h1 className="journal_hdr word_warp pull-left activity_name_header" id="header_activity">{this.state.item_name}<span className="font18 color5 d_block" > {this.state.burn_calorie} calories burned</span></h1>
                    <div className="pull-right  font15 color5 add_fav_div relative top10 cursor_pointer"><span className="add_fav" onClick={this.addLogFavorite}><img alt="" src={this.state.add_fav_off} /> {this.state.add_to_fav}</span></div>
                    <div className="clear"></div>
                    <hr />
                    <p className="margin0 error font14 duartion_error"></p>
                    <div className="pull-left inner_sec mt2">
                      <p className="border-bottom">
                        <span className="font16 color6 text-left left_cnt"><p className="activity_distance"></p></span>
                        <span className="font16 color6 text-right right_cnt">
                          <span className="">
                            <input type="text" className="duration right_input" placeholder="30" id="activity_distance" onChange={this.handleActivitydistance.bind(this)} value={this.state.activity_distance} /> miles
                          </span></span>
                      </p>
                      <p className="border-bottom">
                        <span className="font16 color6 text-left left_cnt"><p className="activity_speed"></p></span>
                        <span className="font16 color6 text-right right_cnt"><input type="text" className="duration right_input" maxLength="3" placeholder="30" id="activity_speed" onChange={this.handleActivityInput.bind(this)} value={this.state.activity_speed} /> MPH</span>
                      </p>
                      <div className="activity_date">
                        <span className="font16 color6 text-left left_cnt">Date</span>
                        <span className="font16 color6 relative text-right right_cnt vlm"><DatePicker selected={parse(this.state.edit_date)} onChange={this.handleChange} minDate={parse(window.localStorage.getItem('profile_start_date'))} maxDate={addDays(new Date(), 90)} /></span></div>
                    </div>
                    <div className="pull-right inner_sec mt2">
                      <p className="border-bottom">
                        <span className="font16 color6 text-left left_cnt"><p className="activity_log_duration"></p></span>
                        <span className="font16 color6 text-right right_cnt"><input type="text" className="duration right_input" maxLength="3" placeholder="30" id="activity_dur" onChange={this.handleActivityInput.bind(this)} value={this.state.activity_dur} /> Min</span>
                      </p>
                      <p>
                        <span className="font16 color6 text-left left_cnt"><p className="activity_calorie_burned"></p></span>
                        <span className="font16 color6 text-right right_cnt"><input type="text" id='burn_calorie' maxLength="3" className="burn_calorie" placeholder="0" onChange={this.handleActivitycalorie.bind(this)} value={this.state.burn_calorie} /></span>
                      </p>
                    </div>
                    <div className="clear"></div>
                    <p className="remember_me" style={{ display: this.state.update_checkbox ? 'block' : 'none' }}>
                      <input id="activity_check" type="checkbox" checked={this.state.isChecked} onChange={this.rememberCheckbox} />
                      <label htmlFor="activity_check">
                        <span className="bullet_icon">
                          <i></i>
                        </span>
                        <span className="font15 color7 edit_activity_checkbox_text">
                        </span>
                      </label></p>
                    <p onClick={this.calculatedActivity.bind(this)} className="display_block edit_logit_btn mt"><button disabled={this.state.disable} className={this.state.disable_btn + " avtivity_log_it_btn log_it_text"}></button></p>

                  </div>
                </div>
              </div>

              <Footer />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Activity_log;
