import React from 'react';
import { Link } from 'react-router-dom';
import Footer from "../footer.js";
import Header from "../header.js";
import Navigation from "../nav.js";
import Adspeed from "../adspeed.js";
import DatePicker from "react-datepicker";
import moment from 'moment';
import { addDays, parse } from 'date-fns';
import axios from 'axios';
import $ from 'jquery';
import * as Sentry from "@sentry/react";


class EditActivity extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      item_name: window.localStorage.getItem('item_clicked_header'), fav_calories: '', add_fav_sec: true, edit_activity: false, add_to_fav: window.keystrings['com.numi.add_to_favorites'], add_fav_off: "/assets/images/fav_off.png", remove_fav_sec: false, show_cal: false, create_activity_hdr: '', save_btn: true, update_btn: false, isChecked: true, update_checkbox: false, duration: "", calories: (window.localStorage.getItem("calories")), assigned_date: '', edit_icon: "", create_activity_div: true, Editcalories: '', Editduration: '', edit_date: window.localStorage.getItem('assigned_date')
    };
    this.addFavorite = this.addFavorite.bind(this);
    this.editActivity = this.editActivity.bind(this);
    this.rememberCheckbox = this.rememberCheckbox.bind(this);
    this._inputChange = this._inputChange.bind(this);
    this._inputCalorieChange = this._inputCalorieChange.bind(this);
    this.removeFavoriteActivity = this.removeFavoriteActivity.bind(this);
    this.createFavoriteActivity = this.createFavoriteActivity.bind(this);
    this.UpdateActivity = this.UpdateActivity.bind(this);
    this.updateEditActivity = this.updateEditActivity.bind(this);
    this.removeActivity = this.removeActivity.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  _inputCalorieChange(event, value) {
    event.preventDefault();
    this.setState({
      calories: event.currentTarget.value
    });
  }
  handleChange(date) {
    this.setState({
      edit_date: date
    });
    var complete_date = moment(date).format('MMMM DD, YYYY');
    var actual_date = moment().format('MMM DD');
    var pageload_selecetd_date = moment(date).format('MMM DD');
    if (pageload_selecetd_date === actual_date) {
      $('.edit_activity .today_date').show();
      $('.edit_activity .today_date').text('Today')
    }
    else {
      $('.edit_activity .today_date').show();
      $('.edit_activity .today_date').text(complete_date)
    }
    Header.changeDate(date);
  }

  _inputChange(event, value) {
    event.preventDefault();
    this.setState({
      [event.currentTarget.id]: event.currentTarget.value
    });

    if (window.localStorage.getItem('doable_type') === "Activity" && (event.currentTarget.value.length === 1 || event.currentTarget.value.length === 2) && $('.duration').val().slice(0, 1) !== 0) {
      Header.startLoader();
      const options = {
        url: window.API_URL + 'v1/doables/doable_id/' + window.localStorage.getItem('doable_id') + '/doable_type/' + window.localStorage.getItem('doable_type') + '?duration=' + this.state.duration + '',
        method: 'GET',
        credentials: 'same-origin',
        headers: Header.setAuthHeaders(),
      };

      axios(options)
        .then(response => {
          Header.stopLoader();
          var kk = $('.duration').val();
          kk.replace(/^0+|[^0-9]/g, '');
          this.setState({
            calories: response.data.doable.calories
          });
        })
        .catch(error => {
          Header.stopLoader();
          Header.apiErrors(error);
          Sentry.captureException(error);
        });
    }
  }

  rememberCheckbox(e) {
    this.setState({ isChecked: !this.state.isChecked });
  }


  editActivity() {

    this.setState({
      edit_icon: '',
      edit_activity: true, //TODO: need to change true
      create_activity_div: false,
      create_activity_hdr: window.keystrings['com.numi.edit_activity_header'],
      save_btn: false,
      update_btn: true,
      update_checkbox: true,

    });

    $('.edit_description').text('Edit Activity');

    const options = {
      url: window.API_URL + "v1/custom_activities/" + window.localStorage.getItem("edit_doable_id"),
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        Header.stopLoader();
        $('#description').val(response.data.custom_activity.description);
        $('#edit_calories').val(response.data.custom_activity.calories);
        $('#edit_duration').val(response.data.custom_activity.duration);
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });

  }

  addFavorite() {
    var doable_id = window.localStorage.getItem("doable_id")
    if (this.state.add_to_fav === "Add to Favorites") {
      this.setState({
        add_to_fav: window.keystrings['com.numi.remove_from_favorites'],
        add_fav_off: "/assets/images/fav_on.png",

      });

      if (doable_id) {
        //this.submitForm()
        this.createFavoriteActivity()
      }

    }
    else {
      this.setState({
        add_to_fav: window.keystrings['com.numi.add_to_favorites'],
        add_fav_off: "/assets/images/fav_off.png",
      });
      if (doable_id) {
        this.removeFavoriteActivity()
      }
    }

    if (this.state.add_to_fav === "Remove from Favorites" && this.state.remove_fav_sec === true && this.state.item_name !== "Edit Activity") {
      this.setState({
        remove_fav_sec: true, add_fav_sec: false, show_cal: true
      });

      $('.remove_favorite').show()
      $('.add_favorite').hide()
    }

  }

  createFavoriteActivity() {
    Header.startLoader();

    var favorite_activities = { "favorite_activity": { "doable_id": window.localStorage.getItem("edit_doable_id"), "doable_type": window.localStorage.getItem("edit_doable_type"), "favorite": true } }


    //create favorited activity
    const options = {
      url: window.API_URL + 'v1/favorite_activities',
      method: 'POST',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
      data: favorite_activities
    };

    axios(options)
      .then(response => {
        Header.stopLoader();
        window.localStorage.setItem('edit_favourite', true)
        this.setState({ favorite_activity: response.data.favorite_activity })
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });
  }

  removeFavoriteActivity() {
    var favorite_activities = { "favorite_activity": { "doable_id": window.localStorage.getItem("edit_doable_id"), "doable_type": window.localStorage.getItem("edit_doable_type"), "favorite": false } }

    //create favorited activity
    const options = {
      url: window.API_URL + 'v1/favorite_activities',
      method: 'POST',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
      data: favorite_activities
    };

    axios(options)
      .then(response => {
        window.localStorage.setItem('edit_favourite', false)
        this.setState({ favorite_activity: response.data.favorite_activity })
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });

  }
  UpdateActivity() {
    var duration = this.state.duration;
    var calories_burned = this.state.calories;
    var assigned_date = moment($('.edit_activity  .react-datepicker__input-container input').val()).format("YYYY-MM-DD");
    var edit_id = window.localStorage.getItem('edit_id');
    var doable_id = window.localStorage.getItem('edit_doable_id');
    var doable_type = window.localStorage.getItem("edit_doable_type");

    var activity_history = { "activity_history": { doable_id: doable_id, doable_type: doable_type, duration: duration, calories: calories_burned, assigned_date: assigned_date } }
    Header.startLoader();

    const options = {
      url: window.API_URL + "v1/activity_histories/" + edit_id,
      method: 'PUT',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
      data: activity_history
    };

    axios(options)
      .then(response => {
        Header.stopLoader();
        window.localStorage.setItem('assigned_date', moment($('.edit_activity  .react-datepicker__input-container input').val()).format("YYYY-MM-DD"));
        this.props.history.push('/journal');
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });
  }


  updateEditActivity() {
    Header.startLoader();
    var ca = { custom_activity: { description: $('#description').val(), duration: $('#edit_duration').val(), calories: $('#edit_calories').val(), update_previous_entries: this.state.isChecked, favorite: window.localStorage.getItem('edit_favourite') } }

    const options = {
      url: window.API_URL + 'v1/custom_activities/' + window.localStorage.getItem('edit_doable_id'),
      method: 'PUT',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
      data: ca
    };

    axios(options)
      .then(response => {
        Header.stopLoader();
        this.setState({ activity: response.data.custom_activity, create_activity_div: true, edit_activity: false, edit_icon: "/assets/images/edit.png", calories: response.data.custom_activity.calories });
        window.localStorage.setItem('edit_doable_description', $('#description').val())
        $('.edit_description').text(window.localStorage.getItem('edit_doable_description'));
        window.localStorage.setItem('assigned_date', moment($('.edit_activity  .react-datepicker__input-container input').val()).format("YYYY-MM-DD"));
        if (this.state.remove_fav_sec) {

          this.createFavoriteActivity(response.data);
        }
        this.props.history.push('/journal');
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });

  }

  removeActivity() {

    const options = {
      url: window.API_URL + 'v1/activity_histories/' + window.localStorage.getItem('edit_id'),
      method: 'DELETE',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        Header.stopLoader();
        this.props.history.push('/journal');
      })
      .catch(error => {
        Header.stopLoader();
        this.props.history.push('/journal');
        Sentry.captureException(error);
      });
  }


  componentDidMount() {

    $('#description').val(window.localStorage.getItem('edit_doable_description'));
    $(document).on("input", ".burn_calorie", function (e) {
      if (typeof (parseInt(this.state.calories)) != 'number') {
        return;
      }
      this.setState({
        calories: e.target.value.replace(/[^0-9]/g, '')
      })

    }.bind(this));

    $(document).on("input", ".numeric", function (e) {

      this.value = this.value.replace(/^0+|[^0-9]/g, '');

    });

    $('.edit_activity_input .mandatory').keyup(function () {
      var $emptyFields = $('.edit_activity_input .mandatory').filter(function () {
        return $.trim(this.value) === "";
      });

      if (!$emptyFields.length) {
        $('.edit_activity_input .avtivity_submit_btn').removeClass('btn disabled');
        $('.edit_activity_input .avtivity_submit_btn button').removeClass('pointer_none');
      }
      else {
        $('.edit_activity_input .avtivity_submit_btn').addClass('btn disabled');
        $('.edit_activity_input .avtivity_submit_btn button').addClass('pointer_none');
      }
    });


    $('.edit_activity_create .mandatory').keyup(function () {
      var $emptyFields = $('.edit_activity_create .mandatory').filter(function () {
        return $.trim(this.value) === "";
      });

      if (!$emptyFields.length) {
        $('.edit_activity_create .avtivity_submit_btn').removeClass('btn disabled');
        $('.avtivity_submit_btn button').removeClass('pointer_none');
      }
      else {
        $('.edit_activity_create .avtivity_submit_btn').addClass('btn disabled');
        $('.edit_activity_create .avtivity_submit_btn button').addClass('pointer_none');
      }
    });

    window.localStorage.setItem('item_clicked_header', '');



    const options = {
      url: window.API_URL + "v1/activity_histories/" + window.localStorage.getItem('activity_history_id'),
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        Header.stopLoader();
        this.setState({ calories: response.data.activity_history.calories, duration: response.data.activity_history.duration, assigned_date: response.data.activity_history.assigned_date });
        window.localStorage.setItem('edit_id', response.data.activity_history.id);
        window.localStorage.setItem('edit_doable_id', response.data.activity_history.doable_id);
        window.localStorage.setItem('edit_doable_type', response.data.activity_history.doable_type);
        window.localStorage.setItem('edit_doable_description', response.data.activity_history.description);
        window.localStorage.setItem('edit_doable_header', response.data.activity_history.header);
        window.localStorage.setItem('edit_favourite', response.data.activity_history.favorite)


        if (window.localStorage.getItem('edit_doable_type') === "CustomActivity") {
          $('.edit_item_hdr').hide();

          $('.edit_description').text(window.localStorage.getItem('edit_doable_description'));
          $('.edit_description').addClass('font40 color3');
          this.setState({ edit_icon: "/assets/images/edit.png" });
        }
        else {
          $('.edit_item_hdr').show();
          $('.edit_item_hdr').text(window.localStorage.getItem('edit_doable_header'))
          $('.edit_description').text(window.localStorage.getItem('edit_doable_description'));
          $('.edit_description').removeClass('font40 color3');
          this.setState({ edit_icon: "" });
        }

        if (response.data.activity_history.favorite === false) {
          this.setState({
            add_to_fav: 'Add to Favorites',
            add_fav_off: "/assets/images/fav_off.png"
          })
        }
        else {
          this.setState({
            add_to_fav: 'Remove From Favorites',
            add_fav_off: "/assets/images/fav_on.png"
          })
        }
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });


    Header.changeDate(window.localStorage.getItem('assigned_date'));


    /*keystrings*/
    $('.back_to_journal_text').append(window.keystrings['com.numi.back_to_journal'])
    $('.food_details_date_text').text(window.keystrings['com.numi.food_details_date'])
    $('.activity_duration').text(window.keystrings['com.numi.duration']);
    $('.activity_duration_text').text(window.keystrings['com.numi.duration'] + '*');
    $('.activity_calories_burned').text(window.keystrings['com.numi.calories_burned']);
    $('.create_food_update_text').text(window.keystrings['com.numi.create_food_update'])
    $('.remove_text').text(window.keystrings['com.numi.remove'])
    $('.create_activity_note_text').text(window.keystrings['com.numi.create_activity_note'])
    $('.create_activity_name_placeholder_text').attr('placeholder', window.keystrings['com.numi.create_food_name_placeholder'])
    $('.name_text').text(window.keystrings['com.numi.food.name'])
    $('.activity_calories_burned_text').text(window.keystrings['com.numi.calories_burned'] + '*');
    $('.activity_note_text').text(window.keystrings['com.numi.activity_note'])
    $('.edit_activity_checkbox_text').text(window.keystrings['com.numi.edit_activity_checkbox_text'])
    /*keystrings*/
  }

  render() {
    return (
      <div>

        <div className="table_display">
          <div className="journal_div edit_activity">
            <div className="section_left pull-left">
              <Navigation />
            </div>
            <div className="section_right pull-left">
              <figure><Adspeed /></figure>
              <div className="journal_rht create_activity task_log">
                <p className="mb">
                  <Link to="/journal" className="back_to back_to_journal_text"><img alt="" src="/assets/images/back_white_icon.png" className="vlb" /> </Link>
                </p>
                <div className="task_todo">
                  <div className="width70 pull-left">
                    <h1 className="journal_hdr  edit_item_hdr"> </h1>
                    <div className="color5 d_block edit_description"></div><div className="font18 color5 d_block" > {this.state.calories} {this.state.calories > 0 ? 'calories burned' : ''}</div>
                  </div>
                  <div className="pull-right  font15 color5 add_fav_div relative top10 cursor_pointer"><span className="create_activity_edit_icon"><img alt="" src={this.state.edit_icon} onClick={this.editActivity} />&nbsp;</span><span className="add_fav" onClick={this.addFavorite}><img alt="" src={this.state.add_fav_off} /> {this.state.add_to_fav}</span></div>
                  <div className="clear"></div>
                  <hr />
                  <div className="edit_activity_input" style={{ display: this.state.create_activity_div ? 'block' : 'none' }}>
                    <div className="pull-left inner_sec"  >
                      <p className="border-bottom">
                        <span className="font16 color6 text-left left_cnt activity_duration"></span>
                        <span className="font16 color6 text-right right_cnt"><input type="text" className="duration mandatory right_input numeric" placeholder="30" maxLength="2" onChange={this._inputChange} onKeyUp={this._inputChange} id="duration" value={this.state.duration} /> Min</span>
                      </p>
                      <div className="activity_date">
                        <span className="font16 color6 text-left left_cnt food_details_date_text"></span>
                        <span className="font16 color6 text-right right_cnt vlm relative"><DatePicker id="assigned_date" selected={parse(this.state.edit_date)} onChange={this.handleChange}
                          minDate={parse(window.localStorage.getItem('profile_start_date'))} maxDate={addDays(new Date(), 90)} /></span>
                      </div>
                    </div>
                    <div className="pull-right inner_sec">
                      <p>
                        <span className="font16 color6 text-left left_cnt activity_calories_burned"></span>
                        <span className="font16 color6 text-right right_cnt"><input type="text" className="burn_calorie mandatory numeric" placeholder="0" maxLength="3" onChange={this._inputCalorieChange} onKeyUp={this._inputCalorieChange} id="calories" value={this.state.calories} /></span>
                      </p>
                    </div>
                    <div className="clear"></div>
                    <p className="avtivity_submit_btn mt2"><button onClick={this.UpdateActivity} className="create_food_update_text"></button> <button onClick={this.removeActivity} className="remove_text"></button></p>
                  </div>



                  <div className="add_favorite edit_activity_create" style={{ display: this.state.edit_activity ? 'block' : 'none' }}>
                    <p className="font18 color6"><b>{this.state.create_activity_hdr}</b></p>
                    <p className="font18 color6 activity_note_text"></p>

                    <div className="pull-left inner_sec mt2">
                      <p className="border-bottom">
                        <span className="font16 color6 text-left left_cnt name_text"></span>
                        <span className="font16 color6 text-right right_cnt"><input type="text" className="mandatory create_activity_name_placeholder_text" placeholder="" id='description' /></span>
                      </p>
                      <p>
                        <span className="font16 color6 text-left left_cnt activity_calories_burned_text"></span>
                        <span className="font16 color6 text-right right_cnt"><input type="text" className="mandatory numeric" placeholder="0" maxLength="3" id="edit_calories" /></span>
                        <br />
                        <span className="font12 mt color7 create_activity_note_text"></span>
                      </p>
                    </div>
                    <div className="pull-right inner_sec mt2">
                      <p>
                        <span className="font16 color6 text-left left_cnt activity_duration_text"></span>
                        <span className="font16 color6 text-right right_cnt"><input type="text" className="numeric right_input mandatory" maxLength="2" placeholder="30" id='edit_duration' /> Min</span>
                      </p>
                    </div>
                    <div className="clear"></div>
                    <p className="remember_me" style={{ display: this.state.update_checkbox ? 'block' : 'none' }}>
                      <input id="activity_check" type="checkbox" checked={this.state.isChecked} onChange={this.rememberCheckbox} />
                      <label htmlFor="activity_check">
                        <span className="bullet_icon">
                          <i></i>
                        </span>
                        <span className="font15 color7 edit_activity_checkbox_text">
                        </span>
                      </label></p>


                    <p className="avtivity_submit_btn mt2"><button onClick={this.updateEditActivity} className="create_food_update_text"></button></p>


                  </div>
                </div>
              </div>

              <Footer />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default EditActivity;
