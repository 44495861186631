import React from 'react';
import { Link } from 'react-router-dom'
import Header from "../header.js";
import Footer from "../footer.js";
import { LogoutPopup } from '../journal/popup.js';

import $ from 'jquery';

class Connect_device extends React.Component {
  bindHumanApi() {
    $('.connect-human-device').on('click',
      function (e) {
        Header.connectDevice(process.env.REACT_APP_CONNECT_SERVER_URL, Header.authorization_headers(), '/all_set');
        e.preventDefault();
      }
    )
  }

  componentDidMount() {
    this.bindHumanApi();
    /*keystrings*/
    $('.connect_your_device_text').text(window.keystrings['com.numi.connect_your_device'])
    $('.connect_device_body_text').text(window.keystrings['com.numi.connect_device_body'])
    $('.connect_fitness_device_text').text(window.keystrings['com.numi.connect_fitness_device'])
    $('.not_now_device_text').text(window.keystrings['com.numi.not_now_device'])
    /*keystrings*/
  }
  goJounal = () => {
    this.props.history.push("/journal");
  }
  render() {

    return (
      <div className="main_container">

        <div className="forgot_password_hero">
          <div><img onClick={() => this.goJounal()} alt="" src="/assets/images/numi_small_logo.png" className="logo img-responsive cursor_pointer" /></div>
          <div className="signup_sec relative" id="profile_sec">
            <div className="profile_section">
              <img alt="" src="/assets/images/back_white_icon.png" className="left_arrow cursor_pointer" data-target="#logout_modal" data-toggle="modal" />
              <div className="connect_device">
                <div className="text-center">
                  <img alt="" src="/assets/images/connect_device.png" />
                  <h3 className="text-center connect_your_device_text"> </h3>
                  <h6 className="color6 connect_device_body_text"> </h6>

                  <button className="connect-human-device connect_fitness_device_text connect-human-device-onboarding"></button>
                  <p><Link to="/all_set" className="not_now not_now_device_text not_now_connect_device"></Link></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LogoutPopup />
        <Footer />
      </div>

    );
  }
}
export default Connect_device;
