import React from 'react';
import { Link } from 'react-router-dom'
import Footer from "../footer.js";
import Header from "../header.js";
import Navigation from "../nav.js";
import Adspeed from "../adspeed.js";
import axios from 'axios';
import $ from 'jquery';
import * as Sentry from "@sentry/react";



class Settings extends React.Component {
  constructor(props) {
    super(props)

    this.setAboutValue = this.setAboutValue.bind(this);
  }
  setAboutValue(value) {
    window.localStorage.setItem('help_location', value);
  }

  static getHumanConnectStatus() {
    const options = {
      url: window.API_URL + "v1/profile_properties/key/CONNECTED_DEVICE_ENABLED",
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        if (response && response.data) {
          if (response.data['profile_property'] && response.data['profile_property']['value'] === "TRUE")
            $('.not_connect').text(window.keystrings['com.numi.settings_connected']);
          else
            $('.not_connect').text(window.keystrings['com.numi.settings_not_connected']);
        }
      })
      .catch(error => {
        Header.stopLoader();
        Sentry.captureException(error);
      });
  }

  bindHumanApi() {
    $('.connect-human-device').on('click', function (e) {
      Header.connectDevice(process.env.REACT_APP_CONNECT_SERVER_URL, Header.authorization_headers());
      e.preventDefault();
    }
    )
    setTimeout(function () { Settings.getHumanConnectStatus(); }, 500);
  }

  componentDidMount() {
    /*key string*/
    $('.settings_title').text(window.keystrings['com.numi.settings_title']);
    $('.setting_manage_order').text(window.keystrings['com.numi.manage_order_title']);
    $('.setting_connect_device').text(window.keystrings['com.numi.connect_device_settings_title']);
    $('.plan_settings_title').text(window.keystrings['com.numi.plan_settings_title']);
    $('.com_settings_title').text(window.keystrings['com.numi.communication_settings_title']);
    $('.learn_about_title').text(window.keystrings['com.numi.learn_about_title']);
    $('.ns_account_title').text(window.keystrings['com.numi.ns_account_title']);
    if (localStorage.nutrisystem_profile_linked === "true") {
      $('.link_now').text(window.keystrings['com.numi.ecom.connected']);
    }
    else {
      $('.link_now').text(window.keystrings['com.numi.link_now']);
    }
    /*key string*/
    this.bindHumanApi();
    $(".nutrisystem_account_settings").click(function () {
      if (localStorage.nutrisystem_profile_linked === "true") {
        $('#nutrisystem_unlink').modal('show');
      }
      else {
        $('#nutrisystem_link').modal('show');
        $(".manage_ns_link").show();
      }
    });
    $('#select_plan').hide();
  }

  render() {

    return (
      <div>

        <div className="table_display">

          <div className="journal_div settings">

            <div className="section_left pull-left">
              <Navigation />
            </div>
            <div className="section_right pull-left">
              <figure><Adspeed /></figure>
              <div className="journal_rht my_profile">
                <h1 className="journal_hdr pull-left settings_title"> </h1>

                <div className="clear"></div>
                <hr />
                <div className="panel-group meal_section_left pull-left text-center  settings_home">
                  <a href={window.keystrings['com.numi.manage_order_web_url']} target="_blank" rel="noopener noreferrer">
                    <div className="profile_div_sec">
                      <div className="pull-left my_profile_lft setting_manage_order"></div>
                      <div className="pull-right my_profile_rht">
                        <img alt="" src="/assets/images/arrow_right_grey.png" />
                      </div>
                    </div>
                  </a>
                  <Link className="connect-human-device connect-human-device-setting" to="#connect_device">
                    <div className="profile_div_sec">
                      <div className="pull-left my_profile_lft setting_connect_device"></div>
                      <div className="pull-right my_profile_rht">
                        <span className="not_connect font16"></span>&nbsp;&nbsp;
                        <img alt="" src="/assets/images/arrow_right_grey.png" />
                      </div>
                    </div>
                  </Link>

                  <Link to="/plan_settings">
                    <div className="profile_div_sec">
                      <div className="pull-left my_profile_lft plan_settings_title"></div>
                      <div className="pull-right my_profile_rht">
                        <img alt="" src="/assets/images/arrow_right_grey.png" />
                      </div>
                    </div>
                  </Link>

                  <Link to="/communication_settings">
                    <div className="profile_div_sec">
                      <div className="pull-left my_profile_lft com_settings_title"></div>
                      <div className="pull-right my_profile_rht">
                        <img alt="" src="/assets/images/arrow_right_grey.png" />
                      </div>
                    </div>
                  </Link>

                  <div className="profile_div_sec nutrisystem_account_settings" style={{ cursor: 'pointer' }}>
                    <div className="pull-left my_profile_lft ns_account_title"></div>
                    <div className="pull-right my_profile_rht">
                      <span className="link_now font16"></span>&nbsp;&nbsp;
                      <img alt="" src="/assets/images/arrow_right_grey.png" />
                    </div>
                  </div>

                  <Link to="/about_nutrisystem">
                    <div className="profile_div_sec">
                      <div className="pull-left my_profile_lft learn_about_title"></div>
                      <div className="pull-right my_profile_rht">
                        <img alt="" src="/assets/images/arrow_right_grey.png" onClick={this.setAboutValue('LEARN_ABOUT')} />
                      </div>
                    </div>
                  </Link>
                </div>


                <div></div>

              </div>
              <Footer />
              <NutrisystemLink />
              <NutrisystemUnlink />
            </div>

          </div>
        </div>

      </div>
    )
  }
}

class NutrisystemLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = { nutri_link_email: '', nutri_link_password: '', congrats: false, error_popup: false, errorCode: '', errorMessage: '', nutri_signup: false, first_name: '', back_button_to_error: '' };
    this.emailChange = this.emailChange.bind(this);
    this.passwordChange = this.passwordChange.bind(this);
  }

  emailChange(e) {
    this.setState({
      nutri_link_email: e.target.value
    })
  }

  passwordChange(e) {
    this.setState({
      nutri_link_password: e.target.value
    })
  }

  nameChange(e) {
    this.setState({
      first_name: e.target.value
    })
  }

  togglePassword(e) {
    if (e.target.previousSibling.getAttribute('type') === 'password') {
      e.target.previousSibling.setAttribute('type', 'text');
    }
    else {
      e.target.previousSibling.setAttribute('type', 'password');
    }
  }

  componentDidMount() {
    $('.modal-backdrop').remove();
    $('body').removeClass('modal-open');
    $('.remove_fade').on('click', function () {
      $('.modal').modal('hide');
    });

    /*keystrings*/
    $('.link_your_accounts_txt').text(window.keystrings['com.numi.link_your_accounts_txt']);
    $('.link_account_txt').text(window.keystrings['com.numi.link_account']);
    $('.nutrisystem_credentials_required_txt').html(window.keystrings['com.numi.nutrisystem_credentials_required_txt']);
    $('.dont_have_account_txt').text(window.keystrings['com.numi.ecom.splash_dont_have_account']);
    $('.forgot_link_pass').text(window.keystrings['com.numi.ecom.nutri_forgot_password']);
    $('.congratulation_link_txt').text(window.keystrings['com.numi.congratulation_link_txt']);
    $('.try_again_caps_text').text(window.keystrings['com.numi.try_again_caps_text']);
    $('.could_not_find_txt').text(window.keystrings['com.numi.could_not_find_txt']);
    $('.could_not_locate_credentials_txt').html(window.keystrings['com.numi.could_not_locate_credentials_txt']);
    $('.create_account_txt').text(window.keystrings['com.numi.ecom.create_account']);
    $('.sign_up_today_for_offers_txt').text(window.keystrings['com.numi.ecom.sign_up_today']);
    $('.profile_hdr').text(window.keystrings['com.numi.ecom.profile_info']);
    $('.ecom_create_account').text(window.keystrings['com.numi.ecom.create_account']);
    /*keystrings*/
  }

  handleLinkSubmit(e) {
    e.preventDefault();
    var nutri_email = $('#nutri_email').val();
    var nutri_password = $('#nutri_password').val();
    var nutri_signup_params = { "nutrisystem": { "email": nutri_email, "password": nutri_password } };
    Header.startLoader();
    const options = {
      url: window.API_URL + "v1/nutrisystem/link.json",
      method: 'POST',
      headers: Header.authorization_headers(),
      data: nutri_signup_params
    };
    axios(options)
      .then(response => {
        Header.stopLoader();
        this.setState({
          congrats: true,
          error_popup: false,
          nutri_signup: false
        });
        var profileData = response.data.user.profile;
        window.localStorage.setItem('nutrisystem_profile_linked', profileData.nutrisystem_profile_linked);
        window.localStorage.setItem('nutrisystem_email', profileData.nutrisystem_email);
        localStorage.setItem('nutrisystem_linked', profileData.nutrisystem_profile_linked);
        window.localStorage.setItem("gtmLinkSuccess", "Setting");
        var evt = document.createEvent("CustomEvent");
        evt.initCustomEvent('LinkSuccess', true, false, false);
        document.dispatchEvent(evt);
        $('.link_now').text(window.keystrings['com.numi.ecom.connected']);
        $("#ns_email_unlink").val(profileData.nutrisystem_email);
      })
      .catch(error => {
        Header.stopLoader();
        var evt = document.createEvent("CustomEvent");
        evt.initCustomEvent('LinkErrorSettings', true, false, false);
        document.dispatchEvent(evt);
        this.setState({
          congrats: false,
          error_popup: true,
          errorCode: error.response.status,
          errorMessage: error.response.data.error,
          back_button_to_error: true
        });
        Sentry.captureException(error);
      });
  }

  handleNutriSignup(e) {
    e.preventDefault();
    var first_name = $('#first_name').val();
    var nutri_link_email = $('#nutri_link_email').val();
    var nutri_link_password = $('#nutri_link_password').val();
    var nutri_signup_params = { "nutrisystem_profile": { "email": nutri_link_email, "password": nutri_link_password, first_name: first_name } };
    Header.startLoader();
    const options = {
      url: window.API_URL + "v1/nutrisystem/profile",
      method: 'POST',
      headers: Header.authorization_headers(),
      data: nutri_signup_params
    };
    axios(options)
      .then(response => {
        Header.stopLoader();
        this.setState({
          congrats: true,
          error_popup: false,
          nutri_signup: false,
          nutri_signup_error: false,
          first_name: ''
        });
        var nutrisystem_email = response.data.nutrisystem_profile.email;
        window.localStorage.setItem('nutrisystem_profile_linked', true);
        window.localStorage.setItem('nutrisystem_email', nutrisystem_email);
        $('.link_now').text(window.keystrings['com.numi.ecom.connected']);
        $("#ns_email_unlink").val(nutrisystem_email);
      })
      .catch(error => {
        Header.stopLoader();
        var evt = document.createEvent("CustomEvent");
        evt.initCustomEvent('LoginFailed', true, false, false);
        document.dispatchEvent(evt);
        this.setState({
          congrats: false,
          error_popup: false,
          nutri_signup: false,
          nutri_signup_error: true,
          errorMessage: error.response.data.error
        });
        Sentry.captureException(error);
      });
  }

  errorParagraph() {
    if (this.state.errorCode === 409) {
      return (
        <p className="font16 mt15 color6">{this.state.errorMessage}</p>
      )
    }
    else {
      return (
        <div>
          <p className="font25 mt3 could_not_find_txt"></p>
          <p className="font16 mt15 could_not_locate_credentials_txt could_not_locate_credentials_txt_style"></p>
        </div>
      )
    }
  }

  showLinkAccount() {
    this.setState({
      error_popup: false,
      back_button_to_error: false
    });
  }

  createLinkAccount() {
    this.setState({
      nutri_signup: true,
      nutri_signup_error: false
    });
  }

  closeLink() {
    this.setState({
      congrats: false,
      error_popup: false,
      nutri_signup: false,
      nutri_link_email: '',
      nutri_link_password: ''
    });
  }

  nutri_account() {
    this.setState({
      nutri_signup: true,
      congrats: false,
      error_popup: false,
      nutri_link_email: '',
      nutri_link_password: ''
    });
    $(".manage_ns_link").hide();
  }

  handleBackButton() {
    if (this.state.back_button_to_error) {
      this.setState({
        congrats: false,
        error_popup: true,
        nutri_signup: false
      });
    }
    else {
      this.setState({
        congrats: false,
        error_popup: false,
        nutri_signup: false
      });
    }
  }

  checkPassword() {
    var password = this.state.nutri_link_password;
    if (!password.match(/([a-zA-Z])/)) {
      $('.pass_alpha').removeClass('validate_pass');

    }
    else {
      $('.pass_alpha').addClass('validate_pass');

    }

    if (!password.match(/([0-9])/)) {
      $('.pass_number').removeClass('validate_pass');

    }
    else {
      $('.pass_number').addClass('validate_pass');

    }
    if (password.length >= 6) {
      $('.pass_length').addClass('validate_pass');
    }
    else {
      $('.pass_length').removeClass('validate_pass');
    }
    if (!password.match(/([/,", ])/)) {
      $('.pass_special_chars').addClass('validate_pass');
    }
    else {
      $('.pass_special_chars').removeClass('validate_pass');

    }
  }

  render() {
    return (
      <div className="modal fade" id="nutrisystem_link">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header relative">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeLink.bind(this)}><img alt="" src="/assets/images/close_grey.png" /></button>
            </div>

            <div className="nutrisystem_link manage_ns_link" style={{ 'display': (this.state.congrats || this.state.error_popup || this.state.nutri_signup || this.state.nutri_signup_error) ? 'none' : 'block' }}>
              <img src="/assets/images/intro_cart.png" alt="center" />&nbsp;&nbsp;&nbsp; <img src="/assets/images/arrows.png" alt="center" /> &nbsp;&nbsp;&nbsp;<img src="/assets/images/logo143x32.png" alt="center" />
              <p className="font26 mt20px color3 link_your_accounts_txt"><b></b></p>
              <p className="font16 mb5 mt5 color6 nutrisystem_credentials_required_txt"></p>
              <form onSubmit={this.handleLinkSubmit.bind(this)}>
                <div>
                  <input type="text" id="nutri_email" placeholder="Nutrisystem Email" value={this.state.nutri_link_email} onChange={this.emailChange} />
                </div>
                <div className="pass_div relative">
                  <input type="password" id="nutri_password" placeholder="Nutrisystem Password" value={this.state.nutri_link_password} onChange={this.passwordChange} />
                  <i onClick={this.togglePassword.bind(this)}></i>
                </div>
                <button className={this.state.nutri_link_email === '' || this.state.nutri_link_password === '' ? 'btn disabled mt20px ml10px' : 'mt20px ml10px'} disabled={this.state.nutri_link_email === '' || this.state.nutri_link_password === '' ? true : false} >{window.keystrings['com.numi.ecom.link']}</button>
              </form>
              <a rel="noopener noreferrer" href={window.keystrings['com.numi.ecom.forgot_password_url']} target="_blank"><p className="mt20px font12 forgot_link_pass"></p></a>
              <p className="mt20px font16 ml10px dont_have_account_txt" onClick={this.nutri_account.bind(this)} style={{ cursor: 'pointer' }}></p>
            </div>

            <div className="congrats_msg nutisystem_link" style={{ 'display': this.state.congrats ? 'block' : 'none' }}>
              <h3 className="link_account_txt link_account_xtr"> </h3>
              <img src="/assets/images/congrats.png" alt="center" />
              <p className="font26 mt10 congratulation_link_txt"></p>
              <button className="mt10" data-dismiss="modal">Continue</button>
            </div>

            <div className="nutisystem_link error_msg" style={{ 'display': this.state.error_popup ? 'block' : 'none' }}>
              <img src="/assets/images/not_in_system.png" alt="center" />
              {this.errorParagraph()}
              <button className="mt3 try_again_caps_text" onClick={this.showLinkAccount.bind(this)}></button>
              <a rel="noopener noreferrer" href={window.keystrings['com.numi.ecom.forgot_password_url']} target="_blank"><p className="mt20px font12 forgot_link_pass forgot_error"></p></a>
              <p className="mt20px font16 ml10px dont_have_account_txt" onClick={this.nutri_account.bind(this)} style={{ cursor: 'pointer' }}></p>
            </div>

            <div className="nutrisystem_link" style={{ 'display': this.state.nutri_signup ? 'block' : 'none' }}>
              <div className="text-left move_arrow" onClick={this.handleBackButton.bind(this)}><img src="/assets/images/back_white_icon.png" alt="" /></div>
              <img src="/assets/images/intro_cart.png" alt="center" />&nbsp;&nbsp;&nbsp; <img src="/assets/images/arrows.png" alt="center" /> &nbsp;&nbsp;&nbsp;<img src="/assets/images/logo143x32.png" alt="center" />
              <p className="font26 color3 ecom_create_account"><b></b></p>
              <p className="font16 mb10 color6 sign_up_today_for_offers_txt"></p>
              <form onSubmit={this.handleNutriSignup.bind(this)}>
                <div className="width89">
                  <h4 className="profile_hdr text-left ml110"> </h4>
                  <div>
                    <input type="text" id="first_name" placeholder="First Name*" maxLength="40" value={this.state.first_name} onChange={this.nameChange.bind(this)} />
                  </div>
                  <div>
                    <input type="email" id="nutri_link_email" placeholder="Email*" maxLength="100" value={this.state.nutri_link_email} onChange={this.emailChange} />
                  </div>
                  <div className="pass_div relative">
                    <input type="password" id="nutri_link_password" placeholder="Password*" maxLength="40" value={this.state.nutri_link_password} onChange={this.passwordChange} onKeyUp={this.checkPassword.bind(this)} />
                    <i onClick={this.togglePassword.bind(this)}></i>
                  </div>
                  <div className="text-left ml110 pass_validate">
                    <p className="font18">Password must contain:</p>
                    <ul className="pass_validate">
                      <li className="pass_number">At least one number (0-9)</li>
                      <li className="pass_alpha">At least one letter (a-z)</li>
                      <li className="pass_length">At least 6 characters</li>
                      <li className="pass_special_chars">No spaces, forward slashes (/) or double quote marks (")</li>
                    </ul>
                  </div>
                </div>
                <button className={this.state.nutri_link_email === '' || this.state.nutri_link_password === '' ? 'btn disabled mt20px width89' : 'mt20px width89'} disabled={this.state.nutri_link_email === '' || this.state.nutri_link_password === '' || this.state.first_name === '' ? true : false} >{window.keystrings['com.numi.ecom.create']}</button>
              </form>
            </div>

            <div className="error_msg nutisystem_link" style={{ 'display': this.state.nutri_signup_error ? 'block' : 'none' }}>
              <img src="/assets/images/congrats.png" alt="center" />
              <p className="font16 mt15 color6">{this.state.errorMessage}</p>
              <button className="mt10p try_again_caps_text" onClick={this.createLinkAccount.bind(this)}></button>
            </div>
          </div>
        </div>
      </div>

    )
  }
}

class NutrisystemUnlink extends React.Component {
  constructor(props) {
    super(props);
    this.state = { nutrisystem_email: localStorage.nutrisystem_email, confirm_unlink: false, errorMessage: '' };
  }

  componentDidMount() {
    $.noConflict();
    $('.modal-backdrop').remove();
    $('body').removeClass('modal-open');
    $('.remove_fade').on('click', function () {
      $('.modal').modal('hide');
    });

    /*keystrings*/
    $(".linked_txt").text(window.keystrings['com.numi.linked']);
    $(".numi_and_nutri_are_linked").text(window.keystrings['com.numi.numi_and_nutri_are_linked']);
    $(".ns_account_title").text(window.keystrings['com.numi.ns_account_title']);
    $(".unlink_account").text(window.keystrings['com.numi.unlink_accoount']);
    $(".unlink_confirmation_txt").html(window.keystrings['com.numi.unlink_confirmation_txt']);
    $(".confirm_numi_nutri_unlink").text(window.keystrings['com.numi.confirm_numi_nutri_unlink']);
    $(".confirm_unlink").text(window.keystrings['com.numi.confirm_unlink']);
    /*keystrings*/
  }

  confirmUnlink() {
    this.setState({
      confirmUnlink: true
    })
  }

  tryAgain() {
    this.setState({
      unlinkError: false,
      confirmUnlink: true
    })
  }

  submitUnlink() {
    Header.startLoader();
    const options = {
      url: window.API_URL + "v1/nutrisystem/delink",
      method: 'DELETE',
      headers: Header.authorization_headers(),
    };
    axios(options)
      .then(response => {
        localStorage.setItem('nutrisystem_linked', false);
        Header.stopLoader();
        var evt = document.createEvent("CustomEvent");
        evt.initCustomEvent('UnlinkSuccess', true, false, false);
        document.dispatchEvent(evt);
        $('.link_now').text(window.keystrings['com.numi.link_now']);
        localStorage.setItem('nutrisystem_profile_linked', false);
        localStorage.setItem('nutrisystem_email', null);
        this.setState({
          unlinkError: false,
          confirmUnlink: false
        })
        $(".close").trigger('click');
      })
      .catch(error => {
        Header.stopLoader();
        this.setState({
          unlinkError: true,
          confirmUnlink: false,
          errorMessage: error.response.data.error
        });
        Sentry.captureException(error);
      });
  }

  render() {
    return (
      <div className="modal fade" id="nutrisystem_unlink">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><img src="/assets/images/close_grey.png" alt="" /></button>
            </div>
            <div className="nutrisystem_link" style={{ 'display': (this.state.confirmUnlink || this.state.unlinkError) ? 'none' : 'block' }}>
              <img src="/assets/images/intro_cart.png" alt="center" />&nbsp;&nbsp;&nbsp; <img src="/assets/images/arrows.png" alt="center" /> &nbsp;&nbsp;&nbsp;<img src="/assets/images/logo143x32.png" alt="center" />
              <b><p className="font26 color3 mt5 linked_txt"></p></b>
              <p className="font16 mb5 mt5 color6 numi_and_nutri_are_linked"></p>
              <h4 className="ns_account_title mt5 text-left" style={{ marginLeft: "117px" }}> </h4>
              <input type="text" id="ns_email_unlink" value={this.state.nutrisystem_email} style={{ fontSize: '16px' }} disabled />
              <b><p className="mt20px font12 unlink_account" style={{ cursor: 'pointer' }} onClick={this.confirmUnlink.bind(this)}></p></b>
            </div>

            <div className="nutrisystem_link" style={{ 'display': this.state.confirmUnlink ? 'block' : 'none' }}>
              <img src="/assets/images/congrats.png" alt="center" />
              <b><p className="font25 color3 mt5 unlink_confirmation_txt"></p></b>
              <p className="font16 mb5 mt5 color6 confirm_numi_nutri_unlink"></p>
              <button className="mb5 confirm_unlink" onClick={this.submitUnlink.bind(this)}></button>
            </div>

            <div className="nutrisystem_link" style={{ 'display': this.state.unlinkError ? 'block' : 'none' }}>
              <img src="/assets/images/congrats.png" alt="" />
              <p className="font16 mt15 color6">{this.state.errorMessage}</p>
              <button className="mt10p try_again_caps_text" onClick={this.tryAgain.bind(this)}></button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Settings;
