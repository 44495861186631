import React from 'react';
export class Dropdown extends React.Component
 {


    render() {
      var self = this;
    var curr_month = new Date();
       var kk =curr_month.getMonth();
        var options = self.props.options.map(function(option,i) {
          
      if((parseInt(i) <= kk) && window.localStorage.getItem('set_month') === "true")
      {
            return (
                <option key={option[self.props.valueField]}  value={option[self.props.valueField]}>
                    {option[self.props.labelField]}
                </option>
            )
        }
        else
        {
            return (
                <option key={option[self.props.valueField]}  value={option[self.props.valueField]}>
                    {option[self.props.labelField]}
                </option>
            )
        }
       
            });
        
        return (
            <select id={this.props.id} className={this.props.errorClass}>
                {options}
            </select>
        )
    }

};

export class Days extends React.Component
 {
render() {
        var self = this;
        var options = self.props.curr_date.map(function(option) {
            return (
                <option key={option[self.props.datevalue]} value={option[self.props.datevalue]}>
                    {option[self.props.datefield]}
                </option>
            )
        });
        return (
            <select id={this.props.id} >

                {options}
            </select>
        )
    }
}


export class YearDropdown extends React.Component
 {
render() {
        var self = this;
        var options = self.props.total_years.map(function(option) {
            return (
                <option key={option[self.props.valueField]} value={option[self.props.valueField]} year_txt={option.year}>
                    {option[self.props.labelField]}
                </option>
            )
        });
        return (
            <select id={this.props.id} >
                {options}
            </select>
        )
    }
}

