
import React from 'react';
import { Link } from 'react-router-dom';
import Footer from "../footer.js";
import { Dropdown, YearDropdown, Days } from '../onboarding/select_dropdown.js';
import Header from "../header.js";
import Navigation from "../nav.js";
import Adspeed from "../adspeed.js";
import Avatar from "../profile/avatar.js";
import moment from 'moment'
import axios from 'axios';
import $ from 'jquery';
import * as Sentry from "@sentry/react";

class Myprofile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      display_profile_photo_in_moments: false, gender_radio: '', profile_data: '', first_name: '', last_name: '', email: window.localStorage.getItem('current_user_email'), birth_date: '', height: '', plan_start_date: '', birth_month: '', birth_year: '', height_feet: '', height_inch: '', avatar_data: '', data_uri: null, loading: false, save_changes: false, edit_changes: true, show_month: [
        {
          month: 'January',
          code: '1',
        },
        {
          month: 'February',
          code: '2',
        },
        {
          month: 'March',
          code: '3',
        },
        {
          month: 'April',
          code: '4',
        },
        {
          month: 'May',
          code: '5',
        },
        {
          month: 'June',
          code: '6',
        },
        {
          month: 'July',
          code: '7',
        },
        {
          month: 'August',
          code: '8',
        },
        {
          month: 'September',
          code: '9',
        },
        {
          month: 'October',
          code: '10',
        },
        {
          month: 'November',
          code: '11',
        },
        {
          month: 'December',
          code: '12',
        }
      ]
    };
    this.radio_toggle = this.radio_toggle.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);

  }


  handleInputChange(e) {
    this.setState({ [e.target.id]: e.target.value });
  }

  getProfile = () => {
    Header.startLoader();
    const options = {
      url: window.API_URL + "v1/profile",
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        if (response && response.data) {
          this.setState({ profile_data: response.data })
          this.setState({ display_profile_photo_in_moments: response.data.profile.display_profile_photo_in_moments })
          Header.stopLoader();
          //get profile data
          if (response.data.profile.disable_display_profile_photo_flag == null || response.data.profile.disable_display_profile_photo_flag == false) {
            $('#toggle_pic').addClass('option_disabled')
          }
          else {
            $('#toggle_pic').removeClass('option_disabled')
          }

          if (response.data.profile.display_profile_photo_in_moments == true) {
            $('.toggle_switch').removeClass('off')
            $('.toggle_switch').addClass('on')
          }
          else {
            $('.toggle_switch').removeClass('on')
            $('.toggle_switch').addClass('off')
          }
          if (this.state.profile_data) {
            var birthdate = this.state.profile_data.profile.birthdate.split('-');
            this.setState({ first_name: this.state.profile_data.profile.first_name, last_name: this.state.profile_data.profile.last_name, gender_radio: this.state.profile_data.profile.gender, birth_date: birthdate[2], birth_month: birthdate[1], birth_year: birthdate[0], height_feet: this.state.profile_data.profile.height.feet, height_inch: this.state.profile_data.profile.height.inches });
            window.months = [
              'January',
              'February',
              'March',
              'April',
              'May',
              'June',
              'July',
              'August',
              'September',
              'October',
              'November',
              'December'
            ];



            var fn = this.state.first_name;
            var gg = this.state.birth_date.split('');
            var get_birth_month = this.state.birth_month.split('');
            if (gg[0] === "0") {
              $('#pick_date').find('option[value=' + gg[1] + ']').prop('selected', true);
            }
            else {
              $('#pick_date').find('option[value=' + this.state.birth_date + ']').prop('selected', true);
            }

            if (get_birth_month[0] === "0") {
              $('#month_drop').find('option[value=' + get_birth_month[1] + ']').prop('selected', true);
            }
            else {
              $('#month_drop').find('option[value=' + this.state.birth_month + ']').prop('selected', true);
            }

            $('#Year_drop').find('option[value=' + (18 + (window.years[0].year - parseInt(this.state.birth_year))) + ']').prop('selected', true);
            this.state.profile_data.profile.profile_properties.forEach((option, i) => {
              if (option.key === "PLAN_START_DATE") {
                var plan_start_date = moment(option.value).format('MMMM DD, YYYY');
                $("p.first_name").html('<p className="font20 color6">' + fn + '</p>')
                $("p.plan_start_date").html('<p className="font14 color7 plan_start_date">' + window.keystrings['com.numi.profile_start_date'] + ' ' + plan_start_date + '</p>');
              }
            }
            );

            var get_curr_year = new Date().getFullYear();
            var get_curr_month = new Date().getMonth() + 1;
            if ((get_curr_year - parseInt($("#Year_drop").find('option:selected').text())) === 18) {

              for (var g = get_curr_month; g < 12; g++) {
                $('#month_drop option[value=' + this.state.show_month[g].code + ']').remove();
              }
            }

          }
        }
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });
  }

  submitProfile(e) {
    $('.cancel_changes').click();
    var first_name = $("#first_name").val();
    var last_name = $("#last_name").val();
    var month = ("0" + ($.inArray($("#month_drop option:selected").text(), window.months) + 1)).slice(-2);
    var dob = (("0" + $("#pick_date option:selected").text()).slice(-2) + '-' + month + '-' + $("#Year_drop option:selected").text());
    var height_feet = $("#height_feet").val();
    var height_inch = $("#height_inch").val();
    var profile_params = { "profile": { "first_name": first_name, "last_name": last_name, "birthdate": dob, "gender": this.state.gender_radio, "height": { "feet": height_feet, "inches": height_inch } } };
    Header.startLoader();

    const options = {
      url: window.API_URL + "v1/profile",
      method: 'PUT',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
      data: profile_params
    };

    //get profile share option value for moments
    axios(options)
      .then(response => {
        Header.stopLoader();
        this.getProfile();
        $('.profile_error').hide();
        window.localStorage.setItem('LastName', this.state.last_name);
        window.localStorage.setItem('FirstName', this.state.first_name);

        if (response && response.data) {
          window.localStorage.setItem('gender', response.data.profile.gender);
          if (response.data.profile.display_profile_photo_in_moments == true) {
            $('.toggle_switch').removeClass('off')
            $('.toggle_switch').addClass('on')
          }
          else {
            $('.toggle_switch').removeClass('on')
            $('.toggle_switch').addClass('off')
          }
        }

      })
      .catch(error => {
        Header.stopLoader();
        $('.profile_error').text('');
        if (error && error.response && error.response.data && error.response.data.error) {
          $('.profile_error').append(error.response.data.error);
          Sentry.captureException(error);
        }
        else if (error && error.response && error.response.status > 400) {
          $('.profile_error').append(error.response.statusText)
          Sentry.captureException(error);
        }
        $('.profile_error').show();
        Sentry.captureException(error);
      });

  }

  editChanges() {
    this.setState({
      save_changes: true, edit_changes: false
    });
    $('#email').removeAttr('readOnly');
  }
  cancelChanges() {
    this.setState({
      save_changes: false, edit_changes: true, email: window.localStorage.getItem('current_user_email')
    });
    $('#email').attr('readOnly', true);
    $('.profile_error').text('');
  }
  saveChanges() {
    var user_params = { "user": { "email": this.state.email } };
    Header.startLoader();
    const options = {
      url: window.API_URL + "v1/users/change_email",
      method: 'POST',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
      data: user_params
    };

    axios(options)
      .then(response => {
        Header.stopLoader();
        window.localStorage.setItem('current_user_email', response.data.user.email)
        Header.setAuthHeaders();
        this.setState({
          save_changes: false, edit_changes: true
        });
        $('#email').attr('readOnly', true);
        window.location.reload();
      })
      .catch(error => {
        Header.stopLoader();
        $('#email_exist').modal('show');
        if (error && error.response && error.response.data && error.response.data.error) {
          $('#email_exist .error_text').text(error.response.data.error);
        }
        else if (error && error.response && error.response.status > 400) {
          $('#email_exist .error_text').text(error.response.statusText)
        }
        /*keystrings*/
        $('.email_exsist_ok').text(window.keystrings['com.numi.ok_all_caps']);
        Sentry.captureException(error);
      });
  }

  logOut = () => {
    window.braze.disableSDK();
    localStorage.clear();
    sessionStorage.clear();
    $('.modal-backdrop').remove();
    $('body').removeClass('modal-open');
    $('.remove_fade').on('click', function () {
      $('.modal').modal('hide');
    });
    $('.fade_link_popup').remove();
    this.props.history.push('/login');
  }

  validateEmail(e) {
    var email_regex = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;
    if (!email_regex.test($('#email').val())) {
      $('#invalidemail').modal('show');
    }
    else {
      $('#invalidemail').modal('hide');
    }
  }

  radio_toggle(e) {
    this.setState({ gender_radio: e.target.id });
  }

  componentDidMount() {
    /*keystrings*/
    $('.my_profile_title').text(window.keystrings['com.numi.profile_title']);
    $('.logout_text').text(window.keystrings['com.numi.settings_logout']);
    $('.first_name_label').text(window.keystrings['com.numi.profile_first_name']);
    $('.last_name_label').text(window.keystrings['com.numi.profile_last_name']);
    $('.email_label').text(window.keystrings['com.numi.profile_email']);
    $('.edit_label').text(window.keystrings['com.numi.create_food_edit']);
    $('.save_label').text(window.keystrings['com.numi.keypad.save']);
    $('.cancel_label').text(window.keystrings['com.numi.cancel']);
    $('.password_label').text(window.keystrings['com.numi.profile_password']);
    $('.change_pwd_label').text(window.keystrings['com.numi.profile_change_password']);
    $('.gender_label').text(window.keystrings['com.numi.profile_gender']);
    $('.dob_label').text(window.keystrings['com.numi.profile_dob']);
    $('.height_label').text(window.keystrings['com.numi.profile_height']);
    $('.my_measurement_label').text(window.keystrings['com.numi.profile_measurements']);
    $('.save_key').text(window.keystrings['com.numi.save_all_caps']);
    $('.display_profile_photo_option_label').text(window.keystrings['com.numi.display_profile_photo_option']);
    $('.hide_your_profile_photo_alert_txt').text(window.keystrings['com.numi.profile_photo_alert_confirmation_title']);
    $('.hide_your_profile_photo_alert_subtxt').text(window.keystrings['com.numi.profile_photo_alert_confirmation_subtitle']);
    $('.cancel_button_txt').text(window.keystrings['com.numi.cancel']);
    $('.continue_button_txt').text(window.keystrings['com.numi.continue']);
    /*keystrings*/

    var show_month = [
      {
        month: 'January',
        code: '1',
      },
      {
        month: 'February',
        code: '2',
      },
      {
        month: 'March',
        code: '3',
      },
      {
        month: 'April',
        code: '4',
      },
      {
        month: 'May',
        code: '5',
      },
      {
        month: 'June',
        code: '6',
      },
      {
        month: 'July',
        code: '7',
      },
      {
        month: 'August',
        code: '8',
      },
      {
        month: 'September',
        code: '9',
      },
      {
        month: 'October',
        code: '10',
      },
      {
        month: 'November',
        code: '11',
      },
      {
        month: 'December',
        code: '12',
      }
    ];

    if ($('.journal_rht.my_profile').length) {
      $('.edit_profile li:first-of-type').hide();
    }
    else {
      $('.edit_profile li:first-of-type').show();
    }
    //OnLoad call get profile API and Avatar API
    this.getProfile();


    $('.men').click(function () {
      if ($(this).find('span').hasClass('p_men_normal')) {
        $(this).find('span').removeClass('p_men_normal').addClass('p_men_active');
        $(this).parents('.gender_img').find('span.p_women_active').removeClass('p_women_active').addClass('p_women_normal');
      }
    })
    $('.women').click(function () {
      if ($(this).find('span').hasClass('p_women_normal')) {
        $(this).find('span').removeClass('p_women_normal').addClass('p_women_active');
        $(this).parents('.gender_img').find('span.p_men_active').removeClass('p_men_active').addClass('p_men_normal');
      }
    });

    var get_curr_year = new Date().getFullYear();
    var get_curr_month = new Date().getMonth() + 1;

    $('#month_drop').change(function () {

      var dd = $('#pick_date option:selected').val();
      var year_val;
      if (get_curr_year - parseInt($('#Year_drop').find('option:selected').text()) === 18) {
        if ($(this).find('option:selected').attr('value') === get_curr_month) {

          $('#pick_date option:not(option:eq(0))').remove();

          var currdate = new Date();
          for (var j = 0; j < 31; j++) {
            if (currdate.getDate() > j) {

              var get_date = j + 1;
              $('#pick_date').append("<option value=" + get_date + ">" + get_date + "</option>");
            }

          }
          if (dd !== "") {
            $('#pick_date option[value=' + dd + ']').prop('selected', true);
          }
        }
        else {
          $('#pick_date option:not(option:eq(0))').remove();
          if ($('#Year_drop').val() !== "Year") {
            year_val = $('#Year_drop').val();
          }
          else {
            year_val = 2000;
          }

          var numberOfDays = daysInMonth($('#month_drop option:selected').val(), year_val);
          for (var i = 1; i <= numberOfDays; i++) {
            $('#pick_date').append('<option value=' + i + '>' + i + '</option>');
          }
          if (dd !== "") {
            $('#pick_date option[value=' + dd + ']').prop('selected', true);
          }
        }
      }
      else {
        if (get_curr_year - parseInt($('#Year_drop').find('option:selected').text()) > 18) {
          $('#pick_date option:not(option:eq(0))').remove();
          if ($('#Year_drop').val() !== "Year") {
            year_val = $('#Year_drop').val();
          }
          else {
            year_val = 2000;
          }

          var numberOfDays1 = daysInMonth($('#month_drop option:selected').val(), year_val);
          for (var jj = 1; jj <= numberOfDays1; jj++) {
            $('#pick_date').append('<option value=' + jj + '>' + jj + '</option>');
          }
          if (dd !== "") {
            $('#pick_date option[value=' + dd + ']').prop('selected', true);
          }

        }
      }


    });
    $('#Year_drop').change(function () {

      var year_val;
      if ((get_curr_year - parseInt($(this).find('option:selected').text())) > 18) {
        var pp = $('#month_drop option').length - 1;
        for (var gg = pp; gg < 12; gg++) {
          $('#month_drop').append("<option value=" + show_month[gg].code + ">" + show_month[gg].month + "</option>");
        }

        var dd = $('#pick_date option:selected').val();
        $('#pick_date option:not(option:eq(0))').remove();
        if ($('#Year_drop').val() !== "Year") {
          year_val = $('#Year_drop').val();
        }
        else {
          year_val = 2000;
        }

        var numberOfDays = daysInMonth($('#month_drop option:selected').val(), year_val);
        for (var i = 1; i <= numberOfDays; i++) {
          $('#pick_date').append('<option value=' + i + '>' + i + '</option>');
        }
        if (dd !== "") {
          $('#pick_date option[value=' + dd + ']').prop('selected', true);
        }
      }
      else {
        if ((get_curr_year - parseInt($(this).find('option:selected').text())) === 18) {
          for (var cc = 0; cc < 13; cc++) {
            if (cc > get_curr_month) {

              $('#month_drop option[value=' + cc + ']').remove();
            }
          }
          $('.month_txt').prop('selected', true);
        }
      }

      var year_reminder = $(this).find('option:selected').text() % 4;
      var month_val = $('#month_drop').val();
      var date_val = $('#pick_date').val();

      if (year_reminder !== 0 && month_val === 2 && date_val === 29) {
        $('#pick_date option[value="29"]').remove();
      } else if (year_reminder === 0 && month_val === 2) {
        $('#pick_date option[value="29"]').remove();
        if (date_val === 29) {
          $('#pick_date').append('<option selected value="29">29</option>');
        } else {
          $('#pick_date').append('<option value="29">29</option>');
        }
      } else if (year_reminder !== 0 && month_val === 2) {
        $('#pick_date option[value="29"]').remove();
      }

      var $emptyFields = $('.profile_page_sec  input[type="text"]').filter(function () {
        return $.trim(this.value) === "";
      });

      var $emptySelectFields = $('.profile_save_btn  select option:selected').attr('value');


      if (!$emptyFields.length && $emptySelectFields !== undefined) {
        $('.profile_save_p').removeClass('btn disabled');
        $('.profile_save_btn').removeClass('pointer_none');
      }
      else {
        $('.profile_save_p').addClass('btn disabled');
        $('.profile_save_btn').addClass('pointer_none');
      }


    });


    var daysInMonth = function (month, year) {
      return new Date(year, month, 0).getDate();
    };
    $('#month_drop').prepend('<option selected class="month_txt" value="">Month</option>');
    $('#Year_drop').prepend('<option selected value="">Year</option>');

    $('.profile_page_sec input[type="text"],.profile_page_sec select').on('keyup change', function () {
      var $emptyFields = $('.profile_page_sec   input[type="text"]').filter(function () {
        return $.trim(this.value) === "";
      });

      var month_val = document.getElementById("month_drop");
      var month_sel = month_val.options[month_val.selectedIndex].value;

      var day_val = document.getElementById("pick_date");
      var day_sel = day_val.options[day_val.selectedIndex].value;

      var year_val = document.getElementById("Year_drop");
      var year_sel = year_val.options[year_val.selectedIndex].value;
      var $emptymonth = month_sel;
      var $emptyday = day_sel;
      var $emptyyear = year_sel;


      if (!$emptyFields.length && $emptymonth !== '' && $emptymonth !== 'Month' && $emptyday !== '' && $emptyday !== 'Day' && $emptyyear !== '') {
        $('.profile_save_p').removeClass('btn disabled');
        $('.profile_save_btn').removeClass('pointer_none');
      }
      else {
        $('.profile_save_p').addClass('btn disabled');
        $('.profile_save_btn').addClass('pointer_none');
      }

    });
    var year_val;
    $('#pick_date').empty();
    if ($('#Year_drop').val() !== "Year") {
      year_val = $('#Year_drop').val();
    }
    else {
      year_val = 2000;
    }

    var numberOfDays = daysInMonth($('#month_drop option:selected').val(), year_val);
    var options = '<option>Day</option>';
    for (var i = 1; i <= numberOfDays; i++) {
      options += '<option value=' + i + '>' + i + '</option>';
    }
    $('#pick_date').append(options);

    $('.toggle_switch').click(function () {
      $(this).toggleClass('on').toggleClass('off');
      //$(this).prev().toggleClass('switch_prev');
      if ($(this).hasClass('on')) {
        $(this).find('input').val('true');
        $('#profile_photo_moments').modal('hide');
        var display_profile_photo_in_moments = $('#display_profile_pic_id').val() == "true" ? true : false;
        var profile_params = { "profile": { "display_profile_photo_in_moments": display_profile_photo_in_moments } }
        Header.startLoader();

        const options = {
          url: window.API_URL + "v1/profile",
          method: 'PUT',
          credentials: 'same-origin',
          headers: Header.setAuthHeaders(),
          data: profile_params
        };

        axios(options)
          .then(response => {
            Header.stopLoader();
            this.getProfile();
            $('.profile_error').hide();
            if (response && response.data) {
              if (response.data.profile.display_profile_photo_in_moments == true) {
                $('.toggle_switch').removeClass('off')
                $('.toggle_switch').addClass('on')
              }
              else {
                $('.toggle_switch').removeClass('on')
                $('.toggle_switch').addClass('off')
              }
            }

          })
          .catch(error => {
            Header.stopLoader();
            $('.profile_error').text('');
            if (error && error.response && error.response.data && error.response.data.error) {
              $('.profile_error').append(error.response.data.error);
            }
            else if (error && error.response && error.response.status > 400) {
              $('.profile_error').append(error.response.statusText)
            }
            $('.profile_error').show();
            Sentry.captureException(error);
          });
      }
      else {
        $(this).find('input').val('false');
        $('#profile_photo_moments').modal('show');
        $(this).toggleClass('on').toggleClass('off');
      }

    });

    $('#HideProfilePhotoOption').click(function () {
      $('#profile_photo_moments').modal('hide');
      //$(this).toggleClass('on').toggleClass('off');
      if ($('.toggle_switch').hasClass('on')) {
        $('.toggle_switch').removeClass('on')
        $('.toggle_switch').addClass('off')
        $(this).find('input').val('true');
        var display_profile_photo_in_moments = $('#display_profile_pic_id').val() == "true" ? true : false;
        var profile_params = { "profile": { "display_profile_photo_in_moments": display_profile_photo_in_moments } }
        Header.startLoader();

        const options = {
          url: window.API_URL + "v1/profile",
          method: 'PUT',
          credentials: 'same-origin',
          headers: Header.setAuthHeaders(),
          data: profile_params
        };

        axios(options)
          .then(response => {
            Header.stopLoader();
            this.getProfile();
            $('.profile_error').hide();
            if (response && response.data) {
              if (response.data.profile.display_profile_photo_in_moments == true) {
                $('.toggle_switch').removeClass('off')
                $('.toggle_switch').addClass('on')
              }
              else {
                $('.toggle_switch').removeClass('on')
                $('.toggle_switch').addClass('off')
              }
            }

          })
          .catch(error => {
            Header.stopLoader();
            $('.profile_error').text('');
            if (error && error.response && error.response.data && error.response.data.error) {
              $('.profile_error').append(error.response.data.error);
            }
            else if (error && error.response && error.response.status > 400) {
              $('.profile_error').append(error.response.statusText)
            }
            $('.profile_error').show();
            Sentry.captureException(error);
          });
      } else {
        $(this).find('input').val('false');
      }
    });


  }

  render() {
    var months = [
      {
        month: 'January',
        code: '1',
      },
      {
        month: 'February',
        code: '2',
      },
      {
        month: 'March',
        code: '3',
      },
      {
        month: 'April',
        code: '4',
      },
      {
        month: 'May',
        code: '5',
      },
      {
        month: 'June',
        code: '6',
      },
      {
        month: 'July',
        code: '7',
      },
      {
        month: 'August',
        code: '8',
      },
      {
        month: 'September',
        code: '9',
      },
      {
        month: 'October',
        code: '10',
      },
      {
        month: 'November',
        code: '11',
      },
      {
        month: 'December',
        code: '12',
      }
    ];

    window.years = [];
    for (var i = 18; i <= 100; i++) {
      var previous_year = new Date().getFullYear() - i;
      window.years.push({ year: previous_year, code: i })
    }

    var dates = [];
    var currdate = new Date();
    for (var j = 0; j < 31; j++) {
      if (currdate.getDate() > j) {

        var get_date = j + 1;
        dates.push({ day: get_date, code: j + 1 })
      }

    }

    var checkActiveMale = '';
    if (this.state.gender_radio === "male") {
      checkActiveMale = <span className="p_men_active"></span>
    } else {
      checkActiveMale = <span className="p_men_normal"></span>
    }

    var checkActiveFemale = '';
    if (this.state.gender_radio === "female") {
      checkActiveFemale = <span className="p_women_active"></span>
    } else {
      checkActiveFemale = <span className="p_women_normal"></span>
    }

    return (
      <div>

        <div className="table_display">

          <div className="journal_div  profile_page_sec">

            <div className="section_left pull-left">
              <Navigation />
            </div>
            <div className="section_right pull-left">
              <figure><Adspeed /></figure>
              <div className="journal_rht my_profile">

                <div className="hdr_section">
                  <h1 className="journal_hdr pull-left my_profile_title"> </h1>
                  <div className="pull-right mt10 logout" onClick={this.logOut}><span className="logout_text"></span></div>
                </div>

                <hr />
                <p className="profile_error error_txt"></p>
                <div className="row clearfix">

                  <div className="col-md-8 col-12">
                    <div className="border1">
                      <div className="flex_between">
                        <div className="pull-left my_profile_lft first_name_label"></div>
                        <div className="pull-right my_profile_rht"><input type="text" id="first_name" maxLength="40" value={this.state.first_name} onChange={this.handleInputChange} onKeyUp={this.handleInputChange} /></div>
                      </div>
                      <div className="flex_between">
                        <div className="pull-left my_profile_lft last_name_label"></div>
                        <div className="pull-right my_profile_rht"><input type="text" id="last_name" maxLength="40" value={this.state.last_name} onChange={this.handleInputChange} onKeyUp={this.handleInputChange} /></div>
                      </div>
                      <div className="flex_between">
                        <div className="pull-left my_profile_lft email_label"></div>
                        <div className="pull-right my_profile_rht"><input type="text" onChange={this.handleInputChange} maxLength="100" readOnly id="email" value={this.state.email} /><span className="edit_changes cursor_pointer ml1 edit_label" onClick={this.editChanges.bind(this)} style={{ display: (this.state.edit_changes ? 'inline-block' : 'none') }}></span><span className="save_changes_div" style={{ display: (this.state.save_changes ? 'inline-block' : 'none') }} ><span className="save_changes ml1  cursor_pointer" onClick={this.saveChanges.bind(this)} > <span className="save_label"></span> | </span><span className="cancel_changes  cursor_pointer cancel_label" onClick={this.cancelChanges.bind(this)} ></span></span></div>
                      </div>
                      <div className="flex_between">
                        <div className="pull-left my_profile_lft password_label"></div>
                        <div className="pull-right my_profile_rht"><Link to="/change_password" className="change_password font15 change_pwd_label"></Link></div>
                      </div>
                      <div className="flex_between">
                        <div className="pull-left my_profile_lft gender_label"></div>
                        <div className="pull-right my_profile_rht">
                          <p className="gender_img">
                            <span className="men">
                              <input className="" type="radio" id="male" name="gender" checked={this.state.gender_radio === 'male'} onChange={this.radio_toggle} />
                              <label htmlFor="male">{checkActiveMale}</label>
                            </span>
                            <span className="women">
                              <input className="" type="radio" id="female" name="gender" checked={this.state.gender_radio === 'female'} onChange={this.radio_toggle} />
                              <label htmlFor="female">{checkActiveFemale}</label>
                            </span>
                          </p>

                        </div>
                      </div>
                      <div className="flex_between dob_div">
                        <div className="pull-left my_profile_lft dob_label"></div>
                        <div className="pull-right my_profile_rht">

                          <span className="select_back" ref="month">
                            <Dropdown id='month_drop' errorClass={this.state.error_class} ref_value="month" options={months} value='1' labelField='month'
                              valueField='code' />
                          </span>

                          <span className="select_back">
                            <Days id='pick_date' curr_date={dates} value='1' datefield='day' ref_value="day" datevalue='code' />
                          </span>
                          <span className="select_back">
                            <YearDropdown id='Year_drop' total_years={window.years} value='1' labelField='year' ref_value="year" valueField='code' />
                          </span>
                        </div>
                      </div>
                      <div className="flex_between">
                        <div className="pull-left my_profile_lft height_label"></div>
                        <div className="pull-right my_profile_rht">
                          <span className="feet"><input type="text" maxLength="1" id="height_feet" value={this.state.height_feet} onChange={this.handleInputChange} onKeyUp={this.handleInputChange} /> ft.</span>
                          <span className="feet"><input type="text" maxLength="2" id="height_inch" value={this.state.height_inch} onChange={this.handleInputChange} onKeyUp={this.handleInputChange} /> in.</span>
                        </div> </div>
                      <div>
                        <Link to="/measurements" className="flex_between">
                          <div className="pull-left my_profile_lft my_measurement_label">
                          </div>
                          <div className="pull-right my_profile_rht">
                            <img alt="" src="/assets/images/arrow_right_grey.png" /></div>
                        </Link>

                      </div>
                      <div className="flex_between" id="toggle_pic">
                        <div className="pull-left my_profile_lft display_profile_photo_option_label"></div>
                        <div className="pull-right my_profile_rht">
                          <div className="toggle_switch round on">
                            <div className="toggle_btn"></div>
                            <span className="on"></span>
                            <span className="off"></span>
                            <input type="hidden" value={this.state.display_profile_photo_in_moments} id="display_profile_pic_id" className="display_profile_pic" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-12 color6">
                    <Avatar />
                  </div>
                  <button className="save_btn" onClick={this.submitProfile.bind(this)}>Save</button>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>

        <InvalidEmail />
        <EmailExist />
        <ProfilePhotoMoments />

      </div >
    )
  }
}


class InvalidEmail extends React.Component {
  render() {
    return (
      <div className="modal fade" id="invalidemail">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><img alt="" src="/assets/images/close_grey.png" /></button>
            </div>
            <div className="modal-body">
              <img alt="" src="/assets/images/warning_tri.png" />
              <p className="font26 color3 mt5 font700">The email address you typed is not in correct format.</p>
              <p><button>OK</button></p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

class EmailExist extends React.Component {
  render() {
    return (
      <div className="modal fade" id="email_exist">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><img alt="" src="/assets/images/close_grey.png" /></button>
            </div>
            <div className="modal-body">
              <img alt="" src="/assets/images/warning_tri.png" />
              <p className="font26 color3 pd14 mt5 font700  error_text"></p>
              <p><button data-dismiss="modal" className="email_exsist_ok"></button></p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

class ProfilePhotoMoments extends React.Component {
  render() {
    return (
      <div className="modal fade" id="profile_photo_moments">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <img alt="" src="/assets/images/close_grey_new_modal.png" />
              </button>
            </div>
            <div className="modal-body">
              <img alt="" src="/assets/images/warning_tri.png" />
              <p className="font22 color3 pd20 mt5 font700 mb-0 error_text hide_your_profile_photo_alert_txt"></p>
              <p className="font18 color3 pd20 mt1 font400 error_text hide_your_profile_photo_alert_subtxt"></p>
              <p><button className="continue_button_txt" id="HideProfilePhotoOption"></button></p>
              <p><button data-dismiss="modal" className="cancel_button_txt"></button></p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Myprofile;