import React from "react";
import { Link, withRouter } from 'react-router-dom';
import Header from "./header.js";
import moment from "moment";
import axios from 'axios';
import * as Sentry from '@sentry/react';
import { Dropdown } from 'react-bootstrap';

var $ = require("jquery");
window.jQuery = $;

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTabId: "1",
      avatar_data: "",
      image: "",
      avatar_images: []
    };

    this.setActiveTab = this.setActiveTab.bind(this);
    this.getUserAvatar = this.getUserAvatar.bind(this);
    this.openNav = this.openNav.bind(this);
    this.closeNav = this.closeNav.bind(this);
  }

  isActive(id) {
    return this.state.selectedTabId === id;
  }

  setActiveTab(selectedTabId, e) {
    var _this = this;
    var current_element = e.currentTarget;
    $('.navlist li').removeClass('active');

    setTimeout(() => {
      if (localStorage.getItem('bestClick') !== 'true') {
        if (_this.props.history.location.hash === "#CATEGORY_BEST_FOR_YOU") {
          $('.newsfeed_tab').removeClass('active');
          $('[data-category="CATEGORY_BEST_FOR_YOU"]').click();
        }
        else {
          $('.best_for_you_tab').removeClass('active');
          $('.newsfeed_tab').addClass('active');
          $('[data-category="CATEGORY_ALL"]').click();
        }
        localStorage.removeItem('bestClick');
        current_element.classList.add("active");
      }
    }, 999);

    _this.setState({ selectedTabId });
  }

  // clear all session variables on logout.
  clearUserDetails = () => {
    window.braze.disableSDK();
    localStorage.clear();
    sessionStorage.clear();
    this.props.history.push("/login");
  }

  openNav() {
    $(".sidenav").css({ width: "100%", height: "100%" });
  }

  closeNav() {
    $(".sidenav").css({ width: "0%", height: "0%" });
  }

  getUserAvatar() {
    Header.startLoader();
    const options = {
      url: window.API_URL + "v1/images/avatar",
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        Header.stopLoader();
        if (response && response.data) {
          this.setState({ avatar_data: response.data.image });
          if (this.state.avatar_data) {
            this.setState({ image: this.state.avatar_data.versions.thumb });
            $(".profile_img").html(
              '<img alt="" class="profile_pic" src="' + this.state.image + '"/>'
            );
            $(".profile_pic").css("border-radius", "50%");
            $(".profile_pic").css("width", "40px");
            $(".profile_pic").css("height", "40px");
          } else {
            $(".profile_img").html(
              '<img alt="" src="/assets/images/profile_large.png"/>'
            );
          }
        }

      })
      .catch(error => {
        Header.stopLoader();
        if (error && error.response && error.response.data && error.response.data.error) {
          $(".profile_img").html('<img alt="" class="profile_pic" src="/assets/images/profile_large.png"/>');
        }
        else if (error && error.response && error.response.status > 400) {
          $(".profile_img").html('<img alt="" class="profile_pic" src="/assets/images/profile_large.png"/>');
        }
        Sentry.captureException(error);
      });
  }
  goToProfile = () => {
    this.props.history.push('/my_profile');
  }

  componentDidMount() {

    localStorage.setItem('bestClick', true);
    $(".react-datepicker__input-container").click(function () {
      $(this)
        .find("input")
        .focus();
    });

    /* To remove the fade once modal popup is closed */
    $(".modal").on("hidden.bs.modal", function () {
      if (
        $(".modal-backdrop").length &&
        !$("#activity_level_content").is(":visible")
      ) {
        $(".modal-backdrop").remove();
      }
    });
    /* To remove the fade once modal popup is closed */



    this.getUserAvatar();



    $(document).on("click", ".recipe_tab", function (e) {
      localStorage.removeItem("meal_occasion_id");
      localStorage.removeItem("food_categories");
      localStorage.removeItem("back_to_recipe");
      localStorage.removeItem("log_food_search");
      localStorage.removeItem("journal_search");
    });

    $(document).on("click", ".journal_tab,.progress_tab,.newsfeed_tab,.settings_tab", function (e) {
      localStorage.removeItem("calMin");
      localStorage.removeItem("calMax");
    });

    $(document).on("click", ".badges", function (e) {
      window.location.reload();
    });

    $('.leaf_tab a').prop('href', window.keystrings['com.numi.header.grow_with_leaf_link']);
    $('.shop_tab a').prop('href', window.keystrings['com.numi.shop_web_url']);
    $('.schedule_coaching_tab a').prop('href', window.keystrings['com.numi.schedule_coaching_url']);
  }

  render() {
    var navlist = [
      {
        name: "Journal",
        id: "1",
        image: "/assets/images/journal.png",
        url: "/journal",
        tab: "journal_tab"
      },
      {
        name: "Social",
        id: "11",
        image: "/assets/images/Social.png",
        url: "/social",
        tab: "social_tab"
      },
      {
        name: "The Leaf",
        id: "2",
        image: "/assets/images/leaf_nav.png",
        tab: "leaf_tab",
        url: "https://leaf.nutrisystem.com/",
        target: "_blank"
      },
      {
        name: "Recipes",
        id: "3",
        image: "/assets/images/recipes.png",
        url: "/recipe",
        tab: "recipe_tab"
      },
      {
        name: "Progress",
        id: "4",
        image: "/assets/images/progress.png",
        url: "/progress",
        tab: "progress_tab"
      },
      {
        name: "News Feed",
        id: "5",
        image: "/assets/images/newsfeed.png",
        tab: "newsfeed_tab",
        url: "/newsfeed",
        new_count: ""
      },
      {
        name: "Settings",
        id: "6",
        image: "/assets/images/settings.png",
        url: "/settings",
        tab: "settings_tab"
      },
      {
        name: "Shop",
        id: "7",
        image: "/assets/images/shop.png",
        tab: "shop_tab",
        url: window.keystrings['com.numi.shop_web_url'],
        target: "_blank"
      },
      {
        name: "Photos",
        id: "8",
        image: "/assets/images/more_photos.png",
        url: "/photos",
        tab: "photos_tab",
      },
      {
        name: "Badges",
        id: "9",
        image: "/assets/images/more_badges.png",
        url: '/badges',
        tab: "badges_tab",
      },
      {
        name: "Best for you",
        id: "10",
        image: "/assets/images/best_for_you_white.png",
        url: '/newsfeed#CATEGORY_BEST_FOR_YOU',
        tab: "best_for_you_tab",
      },
      {
        name: "Schedule Coaching",
        id: "11",
        image: "/assets/images/Calendar.png",
        url: window.keystrings['com.numi.schedule_coaching_url'],
        tab: "schedule_coaching_tab",
        target: "_blank"
      }
    ];



    var plan_start_date = moment(
      window.localStorage.getItem("plan_start_date")
    ).format("MMMM DD, YYYY");

    var tabs = navlist.map(function (navobj, i) {
      return (
        <Tab
          key={i}
          content={navobj.name}
          url={navobj.url}
          target_page={navobj.target}
          activetab={navobj.tab}
          isActive={this.isActive(navobj.id)}
          news_feed_count={navobj.new_count}
          onActiveTab={this.setActiveTab.bind(this, navobj.id)}
          Imagesrc={navobj.image}
        />
      );
    }, this);
    var phase_template_key = localStorage.getItem("personalization_2021_plan");
    var display_scheduled_coaching = localStorage.getItem("display_scheduled_coaching");
    setTimeout(() => {
      if (phase_template_key === "true") {
        $('.best_for_you_tab').show()
      }
      else {
        $('.best_for_you_tab').hide()
      }
      if (display_scheduled_coaching === "true") {
        $('.schedule_coaching_tab').show()
      }
      else {
        $('.schedule_coaching_tab').hide()
      }
    }, 50)

    return (
      <div className="nav_link">

        <Link to="/journal" className="jour_numi_logo">
          <img alt="" src="/assets/images/journal_numi_logo.png" />
        </Link>


        <div className="profile_sec">
          <div className="profile_img col-md-3 col-2 p-0">
            <img alt="" src="/assets/images/profile_large.png" className="nav_profile_image" />
          </div>
          <div className="user_txt padding0 col-md-10 col-6">
            <span className="position-relative cursor_pointer">
              <Dropdown>
                <Dropdown.Toggle className="edit_profile_drop p-0" id="dropdown-custom-components">
                  {window.localStorage.getItem("FirstName")}{" "}
                  <img alt="" src="/assets/images/arrow_white_down.png" />
                  <div className="font11 plan_start_txt">
                    Plan start date: {plan_start_date}
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {this.props.match.path === '/my_profile' ? '' : <Link to="/my_profile" onClick={this.goToProfile}>Edit profile</Link>}
                  <span onClick={this.clearUserDetails} className="cursor_pointer logout">Logout</span>
                </Dropdown.Menu>
              </Dropdown>


            </span>

          </div>
          <span className="menu_font colorfff col-4" onClick={this.openNav}>☰</span>


        </div>


        {/* <div className="profile_sec">
          <div className="profile_img">
            <img alt=""  src="/assets/images/profile_large.png" />
          </div>
          <div className="user_txt dropdown"  data-toggle="dropdown">
            <span className="relative cursor_pointer">
              {window.localStorage.getItem("FirstName")}{" "}
              <img  alt="" src="/assets/images/arrow_white_down.png" />
              <br />
              <ul className="dropdown-menu edit_profile">
                <li className="to_myprofile" onClick={this.goToProfile}>
                  <Link to="/my_profile">Edit Profile</Link>
                </li>
                <li onClick={this.clearUserDetails}>
                  <span>Log Out</span>
                </li>
              </ul>
            </span>
            <span className="white_color start_date">
              Plan start date: {plan_start_date}
            </span>
          </div>
          <span className="menu_font" onClick={this.openNav}>
            &#9776; Menu
          </span>
        </div> */}
        <hr className="white_divider" />

        <div className="tabs_section mobile_nav sidenav">
          <span className="closebtn cursor_pointer" onClick={this.closeNav}>&times;</span>
          <ul className="navlist">{tabs}</ul>
        </div>
      </div>
    );
  }
}

class Tab extends React.Component {
  render() {
    return (
      <li className={this.props.activetab + " active"} onClick={this.props.onActiveTab} style={{ backgroundImage: "url(" + this.props.Imagesrc + ")" }} >
        {this.props.news_feed_count}{" "}
        <Link to={this.props.url} target={this.props.target_page}>
          {this.props.content}
        </Link>
      </li>
    );
  }
}

export default withRouter(Navigation);
