import React from 'react';
import Header from "../header.js";
import axios from 'axios';
import $ from 'jquery';
import * as Sentry from "@sentry/react";

class FilteredList extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      initialItems: [],
      items: [], show_search: false, toggle_seacrh_icon: true, close_white: false, list_group: false, search_display: false, search_icon: false, recent_search: false, seachValue: '', search_results: [], search_flag: false
    };
    this.filterList = this.filterList.bind(this);
    this.showSearch = this.showSearch.bind(this);
    this.closeWhite = this.closeWhite.bind(this);
    this.seacrhFocus = this.seacrhFocus.bind(this);
    this.performSearch = this.performSearch.bind(this);
    this.callreplacedInput = this.callreplacedInput.bind(this);

  }


  showSearch(e) {
    this.setState({
      show_search: true,
      toggle_seacrh_icon: false,
      close_white: true,

      search_display: !this.state.search_display,
      search_icon: true,
    });
  }

  callreplacedInput(change_input) {
    $('.search_input').val(change_input)

  }

  closeWhite(e) {
    e.preventDefault();
    $('.search_empty').hide();
    this.setState({
      show_search: false,
      toggle_seacrh_icon: true,
      close_white: false,
      list_group: false,
      search_display: !this.state.search_display,
      search_icon: false,
      search_flag: false
    });
    $('.activity_page .list-group').hide();
    $('.activity_page .search_input').val('');
    if ($('.show_activity_list li').length > 0) {
      $('.show_activity_list,.activity_sec').show();
      $('.show_activities').hide();
    } else {
      $('.show_activity_list,.serach_on_click').hide();
      $('.show_activities').show();
    }
  }

  seacrhFocus() {
    this.setState({ list_group: !this.state.list_group, recent_search: false });
    if ($('.list-group-item').length > 1) {
      this.setState({ recent_search: true });
    }
  }


  filterList(event) {
    this.performSearch();
    var updatedList = this.state.initialItems;
    if (updatedList) {
      updatedList = updatedList.filter(function (item) {
        return item.toLowerCase().search(
          event.target.value.toLowerCase()) !== -1;
      });
    }
    this.setState({ recent_search: true });
    this.setState({ items: updatedList });
    if (updatedList.length === 0) {



    }
    else {
      this.setState({ recent_search: true });


    }


  }

  performSearch() {
    Header.startLoader();

    const options = {
      url: window.API_URL + "v1/doables/recent_searches?per_page=5",
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        Header.stopLoader();
        response.data.recent_searches.forEach(function (item, index, object) {
          if (item === 'undefined') {
            object.splice(index, 1);
          }
        });
        this.setState({ search_results: response.data.recent_searches })
        if (this.state.search_results[0] === 'undefined') {
          $('.recent_list_ul,.list-group').hide();
        }
        else {
          $('.recent_list_ul,.list-group').show();

          var evt = document.createEvent("CustomEvent");
          evt.initCustomEvent('ActivityRecentSearch', true, false, false);
          document.dispatchEvent(evt);
        }

      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });

  }

  callDropdownAPI(activity_txt) {
    this.performSearch(activity_txt)
  }



  componentDidMount() {

    $('.activity_recent_searches').text(window.keystrings['com.numi.recent_searches']);

    $(document).keypress(function (e) {
      if (e.which === 13) {
        if ($('.search_activity .search_input').val() !== "") {
          $('.edit_search_icon').click();
          $('.recent_list_ul').hide();
        }
      }

    });

    $(document).on('click', '.activity_page .edit_search_icon,.activity_page li.recent_item,.activity_page .recent_list_ul li', function () {
      this.setState({
        list_group: false,
      });
    }.bind(this));

    var page_number = 1;
    var activity_txt;
    $(document).on('click', '.activity_page .list-group-item:not(.recent_search),.activity_page .edit_search_icon,.activity_page #show_more', function (e) {
      e.stopImmediatePropagation();
      if ($(this).hasClass('edit_search_icon')) {
        page_number = 1;
      }
      if ($(this).parents('.activity_page').find('input.search_input').val() !== "") {
        window.localStorage.setItem('search_key', $(this).parents('.activity_page').find('input.search_input').val());
        activity_txt = window.localStorage.getItem('search_key');
      }


      if ($(this).text() === "Show More") {
        activity_txt = window.localStorage.getItem('search_key');
      }

      if (page_number === 1 && $(this).hasClass('show_more')) {
        page_number++;
      }


      if (activity_txt !== '' && activity_txt !== undefined && activity_txt !== 'null') {

        const options = {
          url: window.API_URL + 'v1/doables?term=' + encodeURI(activity_txt) + "&per_page=20&page=" + page_number + "",
          method: 'GET',
          credentials: 'same-origin',
          headers: Header.setAuthHeaders(),
        };

        axios(options)
          .then(response => {
            if (response.data.results["doables"].length !== 0) {
              $('.show_activities').hide();
              $('.show_activity_list,.activity_sec').show();
              if (response.data.results.next_page === null) {
                $(".show_more").hide();
                if (!$(this).hasClass('show_more')) {

                  $('.show_activity_list ul').empty();
                }
              } else {
                $(".show_more").show();
                if (!$(this).hasClass('show_more')) {

                  $('.show_activity_list ul').empty();
                }

              }
              $('.serach_on_click').hide();
              var activity_list = response.data.results["doables"];


              $('.list-group').hide();
              for (var k = 0; k < activity_list.length; k++) {
                var list, details;
                if (activity_list[k].doable_type === 'CustomActivity') {
                  list = activity_list[k].description;
                  details = activity_list[k].description;
                }
                else {
                  list = activity_list[k].category;
                  details = activity_list[k].details;
                }
                if (activity_list[k].doable_type === "CustomActivity") {

                  if (activity_list[k].description !== null) {
                    $('<li class="meal_txt category_details cursor_pointer" search_fav=' + activity_list[k].favorite + ' dotype=' + activity_list[k].doable_type + ' id=' + activity_list[k].doable_id + '><span class="color6 font15 cursor_pointer cat_name" >' + list
                      + '</span><br/><span class="color8 font12 cat_details">' + details + '</span></li>').appendTo('.show_activity_list ul');
                    $('.serach_on_click').hide();

                  }
                  else {

                    if ($('.show_activity_list ul li').length === 0) {
                      var evt = document.createEvent("CustomEvent");
                      evt.initCustomEvent('ActivityNoResult', true, false, false);
                      document.dispatchEvent(evt);
                      $('.serach_on_click').show();
                      $('.food_click_not_found').find('b').text('"' + $('.search_input').val() + '"');
                    }
                  }
                }
                else {

                  if (activity_list[k].category != null) {
                    $('<li class="meal_txt category_details cursor_pointer" search_fav=' + activity_list[k].favorite + ' dotype=' + activity_list[k].doable_type + ' id=' + activity_list[k].doable_id + '><span class="color6 font15 cursor_pointer cat_name" >' + list
                      + '</span><br/><span class="color8 font12 cat_details">' + details + '</span></li>').appendTo('.show_activity_list ul');
                    $('.serach_on_click').hide();
                  }
                  else {
                    if ($('.show_activity_list ul li').length === 0) {
                      var evt1 = document.createEvent("CustomEvent");
                      evt1.initCustomEvent('ActivityNoResult', true, false, false);
                      document.dispatchEvent(evt1);
                      $('.serach_on_click').show();
                      $('.food_click_not_found').find('b').text('"' + $('.search_input').val() + '"');
                    }

                  }
                }
              }

            }
            else {
              var evt2 = document.createEvent("CustomEvent");
              evt2.initCustomEvent('ActivityNoResult', true, false, false);
              document.dispatchEvent(evt2);
              $('.serach_on_click').show();
              $('.food_click_not_found').find('b').text('"' + $('.search_input').val() + '"');
              $('.show_activity_list,.activity_sec,.show_more').hide();
            }

            if (response.data.results.next_page !== null) {
              page_number++;
            }
            else {
              page_number = 1;
            }
          })
          .catch(error => {
            Header.stopLoader();
            Header.apiErrors(error);
            Sentry.captureException(error);
          });

      }
      else {
        var evt = document.createEvent("CustomEvent");
        evt.initCustomEvent('ActivityNoResult', true, false, false);
        document.dispatchEvent(evt);
        $('.show_activity_list ul').empty();
        $('.serach_on_click').show();
        $('.show_more,.show_activities').hide();
      }

    })





  }

  render() {
    return (
      <div className="filter-list search_activity relative">
        <fieldset className="form-group">
          <input type="text" className="search_input" placeholder={window.keystrings['com.numi.search_activity_sports_ect']} onChange={this.filterList} onFocus={this.seacrhFocus} />

          <img alt="" src="/assets/images/search-white.png" className="edit_search_icon" />


        </fieldset>
        <DropdownList recent_items_list={this.state.search_results} replaceInput={this.callreplacedInput} list_group={this.state.list_group} recent={this.state.recent_search} />

      </div>
    );
  }
};

class DropdownList extends React.Component {
  render() {
    return (
      <div className="list-group">
        <ul ref="toggle_items" className="margin0 recent_list_ul" style={{ display: this.props.list_group ? 'block' : 'none' }}>
          <li className="list-group-item recent_search cursor_default" style={{ display: this.props.recent ? 'block' : 'none' }}><p className="activity_recent_searches"></p></li>
          {
            this.props.recent_items_list.map(function (item) {
              return (

                <li className="list-group-item" onClick={() => this.props.replaceInput(item)} data-category={item} key={item}>{item}</li>
              );
            }.bind(this))
          }
        </ul>

      </div>
    )
  }
};


export default FilteredList;