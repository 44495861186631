
import React from 'react';
import { Link } from 'react-router-dom';
import Footer from "../footer.js";
import Header from "../header.js";
import Navigation from "../nav.js";
import Adspeed from "../adspeed.js";
import axios from 'axios';
import FilteredList from "./activity_search.js";
import $ from 'jquery';
import * as Sentry from "@sentry/react";

//var Config = require('Config') need to update once env setup done.

// Temporarily added local_url and local_headers

var local_loadmore = 10
class Log_activity extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      activities_list: [], favorite_actitivities_list: [], recent_activities: [], limit: local_loadmore,
      activity: { category: '', id: '', details: '' }, onLoad: true, result_list: [], header_activity: '', item_clicked: ''
    }

    this.getActivities = this.getActivities.bind(this)
    this.getRecentActivities = this.getRecentActivities.bind(this)
    this.getFavoriteActivities = this.getFavoriteActivities.bind(this)
    this.getActivityById = this.getActivityById.bind(this)
    this.onLoadMore = this.onLoadMore.bind(this)
    this.setHeaderActivity = this.setHeaderActivity.bind(this)
  }

  setHeaderActivity(activity_name) {

    window.localStorage.setItem('activity_name', activity_name);

    this.props.history.push('/activity_log')

  }

  performSearch(search_key, load, e) {
    this.setState({ onLoad: false })
    if (this.state.searchinputValue !== '') {

      const options = {
        url: window.API_URL + 'v1/doables?term=' + encodeURI(search_key) + "&per_page=20&page=1",
        method: 'GET',
        credentials: 'same-origin',
        headers: Header.setAuthHeaders(),
      };

      axios(options)
        .then(response => {

          $('span.query-result:contains');
          $('.back_to_journal span').text(window.keystrings['com.numi.back_to_log_activity']);
          // window.localStorage.setItem('item_clicked_subheader',details)
          this.setState({ result_list: response.data.results["doables"] });
          $('.show_activity_list ul').empty();
          window.localStorage.setItem('search_key', search_key);
          window.localStorage.setItem('search_category', search_key);
          for (var k = 0; k < this.state.result_list.length; k++) {

            if (this.state.result_list[k].category !== null) {
              $('<li class="meal_txt category_details cursor_pointer" headers="' + this.state.result_list[k].header + '" search_fav="' + this.state.result_list[k].favorite + '" dotype="' + this.state.result_list[k].doable_type + '" id="' + this.state.result_list[k].doable_id + '"><span class="color6 font15 cursor_pointer query-result cat_name">' + this.state.result_list[k].category + '</span><br/><span class=" cat_details color8 font12">"' + this.state.result_list[k].details + '"</span></li>').appendTo('.show_activity_list ul');
            }
          }
          $('.show_activities').hide();
          $('.show_activity_list').show();
          if ($('.show_activity_list li').length < 20) {
            $('.show_more').hide();
          }
          else {
            $('.show_more').show();
          }

        })
        .catch(error => {
          Header.stopLoader();
          Header.apiErrors(error);
          Sentry.captureException(error);
        });
    }
    return false
  }

  getActivities() {

    const options = {
      url: window.API_URL + 'v1/activities/headers',
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        $('.show_activity_list ul').empty();
        this.setState({ activities_list: response.data.activity_headers });

        this.getRecentActivities()
        this.getFavoriteActivities()

      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });
  }

  getRecentActivities() {
    Header.startLoader();

    const options = {
      url: window.API_URL + 'v1/activity_histories/recent?limit=50',
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        Header.stopLoader();
        if (!!response.data.activity_histories && response.data.activity_histories.length > 0) {
          $('.recent_empty').hide();
          this.setState({ recent_activities_list: response.data.activity_histories });
        }
        else {
          $('.recent_empty').show();
        }

      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });

  }

  getFavoriteActivities() {

    const options = {
      url: window.API_URL + 'v1/favorite_activities?limit=50',
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        if (response.data.favorite_activities.length > 0) {
          $('.fav_empty').hide();
          this.setState({ favorite_activities_list: response.data.favorite_activities })
        }
        else {
          $('.fav_empty').show();
        }

      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });
  }

  getActivityById(id) {
    const options = {
      url: window.API_URL + 'v1/activities/' + id,
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        this.setState({ activity: response.data.activity })

      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });
  }

  backToActivity() {
    if ($('.back_to_journal span').text() === 'Back to Journal') {
      this.props.history.push('/journal');
    }
    else {
      $('.back_to_journal span').text(window.keystrings['com.numi.back_to_journal']);
      this.getActivities();
      $('.show_activities').show();
    }
  }

  componentDidMount() {

    $(document).on('click', '.activity_page .sreachinput', function () {
      window.location.reload();
    });

    $('.log_activity_title').text(window.keystrings['com.numi.log_activity_title']);
    $('.numi_running').text(window.keystrings['com.numi.running']);
    $('.numi_walking').text(window.keystrings['com.numi.walking']);
    $('.activity_favorites_title').text(window.keystrings['com.numi.activity_favorites_title']);
    $('.activity_recents_title').text(window.keystrings['com.numi.activity_recents_title']);
    $('.numi_couldnot_find').text(window.keystrings['com.numi.activity.couldnt_find']);
    $('.create_new_text').append(window.keystrings['com.numi.k_create_new'])
    $('.show_more_text').text(window.keystrings['com.numi.recipe.show_more'])
    $('.favorite_your_activity_text').text(window.keystrings['com.numi.favorite_your_activity'])
    $('.favorite_activity_message_text').text(window.keystrings['com.numi.favorite_activity_message'])
    $('.start_logging_activity_text').text(window.keystrings['com.numi.start_logging_activity'])
    $('.recent_activity_message_text').text(window.keystrings['com.numi.recent_activity_message'])


    $(document).on('click', '.category_details', function (e) {
      Header.startLoader();
      e.stopImmediatePropagation();
      window.localStorage.setItem('category_name', e.currentTarget.getElementsByClassName('cat_name')[0].innerText);
      window.localStorage.setItem('category_details', e.currentTarget.getElementsByClassName('cat_details')[0].innerText);

      window.localStorage.setItem('search_fav', e.currentTarget.getAttribute('search_fav'));
      window.localStorage.setItem('doable_id', e.currentTarget.getAttribute('id'));
      window.localStorage.setItem('doable_type', e.currentTarget.getAttribute('dotype'));
      window.localStorage.setItem('fav_recent_type', 3);

      var _this = this;

      const options = {
        url: window.API_URL + 'v1/doables/doable_id/' + window.localStorage.getItem('doable_id') + '/doable_type/' + window.localStorage.getItem('doable_type') + '?term=' + window.localStorage.getItem('search_key') || window.localStorage.getItem('category_name'),
        method: 'GET',
        credentials: 'same-origin',
        headers: Header.setAuthHeaders(),
      };

      axios(options)
        .then(response => {
          Header.stopLoader();
          window.localStorage.setItem('calories', response.data.doable.calories);
          window.localStorage.setItem('log_duration', response.data.doable.duration);
          _this.props.history.push('/log_task');
        })
        .catch(error => {
          Header.stopLoader();
          Header.apiErrors(error);
          Sentry.captureException(error);
        });
    }.bind(this));

    $(document).on('click', '.select_dropdown li', function () {
      $('.select_dropdown li').removeClass('selected');
      $(this).addClass('selected');
      $(this).parents('.select_dropdown').find('.dropdown_list span').text($(this).find('span').text());
    });

    this.getActivities()
    $(document).on('click', '.all_activity_option', function () {
      $('.show_activity_list').hide()
      $('.show_activities').show()
    })
    $(document).on('click', '.running_act', function () {
      $('h1#header_activity').text('Running')
    })
    $(document).on('click', '.walking_act', function () {
      $('h1#header_activity').text('Walking')
    })
    $(document).on('click', '.favorite-activity,.recent-activity', function (e) {
      e.stopImmediatePropagation();
      window.localStorage.setItem('doable_id', e.currentTarget.getAttribute('data-doable-id'))
      window.localStorage.setItem('doable_type', e.currentTarget.getAttribute('data-doable-type'))
      window.localStorage.setItem('category_name', e.currentTarget.getAttribute("data-desc"));
      window.localStorage.setItem('category_details', e.currentTarget.getAttribute("data-desc"));
      window.localStorage.setItem('create_fav', 'true');
      window.localStorage.setItem('recent_fav', e.currentTarget.getAttribute("data-fav"));
      window.localStorage.setItem('activity_name', e.currentTarget.getAttribute('data-desc'));
      if (e.currentTarget.classList.contains('recent-activity')) {
        window.localStorage.setItem('fav_recent_type', 1);
      }
      else {
        window.localStorage.setItem('fav_recent_type', 2);

      }
      var _this = this;
      if (e.currentTarget.getAttribute('data-doable-type') !== "CalculatedActivity" && window.localStorage.getItem('fav_recent_type') === '2') {

        const options = {
          url: window.API_URL + 'v1/doables/doable_id/' + window.localStorage.getItem('doable_id') + '/doable_type/' + window.localStorage.getItem('doable_type') + '?term=' + window.localStorage.getItem('search_key') || window.localStorage.getItem('category_name'),
          method: 'GET',
          credentials: 'same-origin',
          headers: Header.setAuthHeaders(),
        };

        axios(options)
          .then(response => {
            window.localStorage.setItem('calories', response.data.doable.calories);
            window.localStorage.setItem('log_duration', response.data.doable.duration);
            _this.props.history.push('/log_task');
          })
          .catch(error => {
            Header.stopLoader();
            Header.apiErrors(error);
            Sentry.captureException(error);
          });
      }
      else {
        if (e.currentTarget.getAttribute('data-doable-type') === "CalculatedActivity") {

          _this.props.history.push('/activity_log');
        }
        else {
          if (e.currentTarget.getElementsByClassName('log_calories').length > 0) {
            window.localStorage.setItem('calories', e.currentTarget.getElementsByClassName('log_calories')[0].value);
          }
          /* else
           {
             localStorage.removeItem('calories');
           }*/
          if (e.currentTarget.getElementsByClassName('log_duration').length > 0) {
            window.localStorage.setItem('log_duration', e.currentTarget.getElementsByClassName('log_duration')[0].value)
          }
          /* else
           {
             localStorage.removeItem('log_duration');
           }*/
          _this.props.history.push('/log_task');
        }
      }


    }.bind(this));

  }

  onLoadMore() {
    this.setState({ limit: this.state.limit + local_loadmore });
  }

  render() {


    var favorites = this.state.favorite_activities_list || [];
    var recent_list = this.state.recent_activities_list || [];

    var activity_list_options = this.state.activities_list.map(function (option, i) {
      return (
        <li className="meal_txt member-activity cursor_pointer" onClick={this.performSearch.bind(this, option, false)} data-category={option} key={i + 1}><span className="color6 font15 cursor_pointer"> {option}</span></li>
      )
    }.bind(this));

    var recent_list_options = recent_list.map(function (task_option, i) {

      if (task_option.doable_type === "Activity") {
        return (<li className="meal_txt recent-activity cursor_pointer" data-fav={task_option.favorite} key={i} data-doable-id={task_option.doable_id} data-doable-type={task_option.doable_type} data-desc={task_option.description}><span className="color6 font16" > {task_option.header}</span><br /><span className="font12 color8">{task_option.description}</span><br /><span className="font12 color8 "><span className="cals">{task_option.detail_1}</span><div className="cals">{task_option.detail_2}</div><input type="hidden" className="log_calories" value={task_option.calories} /><input type="hidden" className="log_duration" value={task_option.duration} /> </span><span></span></li>)
      } else {
        if (task_option.doable_type === "CalculatedActivity") {
          return (<li className="meal_txt recent-activity cursor_pointer" data-fav={task_option.favorite} key={i} data-doable-id={task_option.doable_id} data-doable-type={task_option.doable_type} data-desc={task_option.header}><span className="color6 font16" > {task_option.header}</span><br /><span className="font12 color8"><span className="cals">{task_option.detail_1}</span><div className="cals">{task_option.detail_2}</div><input type="hidden" className="log_calories" value={task_option.calories} /> <input type="hidden" className="log_duration" value={task_option.duration} /></span></li>)
        }
        else {
          return (<li className="meal_txt recent-activity cursor_pointer" data-fav={task_option.favorite} key={i} data-doable-id={task_option.doable_id} data-doable-type={task_option.doable_type} data-desc={task_option.description}><span className="color6 font16" > {task_option.description}</span><br /><span className="font12 color8"><span className="cals">{task_option.detail_1}</span><input type="hidden" className="log_calories" value={task_option.calories} /> <input type="hidden" className="log_duration" value={task_option.duration} /></span></li>)
        }

      }
    });

    var favorite = favorites.map(function (task_option, i) {
      if (task_option.doable_type === "Activity") {
        return (<li className="meal_txt favorite-activity cursor_pointer" key={i} data-doable-id={task_option.doable_id} data-doable-type={task_option.doable_type} data-desc={task_option.description}><span className="color6 font16" > {task_option.header}</span><br /><span className="font12 color8">{task_option.description}</span><span></span></li>)
      }
      else {
        if (task_option.doable_type === "CalculatedActivity") {
          return (<li className="meal_txt favorite-activity cursor_pointer" key={i} data-doable-id={task_option.doable_id} data-doable-type={task_option.doable_type} data-desc={task_option.header}><span className="color6 font16"> {task_option.header}</span></li>)
        }
        else {
          return (<li className="meal_txt favorite-activity cursor_pointer" key={i} data-doable-id={task_option.doable_id} data-doable-type={task_option.doable_type} data-desc={task_option.description}><span className="color6 font16"> {task_option.description}</span></li>)
        }

      }
    })

    return (
      <div>

        <div className="table_display">

          <div className="journal_div activity_page">

            <div className="section_left pull-left">
              <Navigation />
            </div>
            <div className="section_right pull-left">
              <figure><Adspeed /></figure>
              <div className="journal_rht">
                <p className="mb">
                  <span className="back_to_journal cursor_pointer" onClick={this.backToActivity.bind(this)}><img alt="" src="/assets/images/back_white_icon.png" className="vlb cursor_pointer" /> <span>Back to Journal</span></span>
                </p>
                <div className="hdr_section">
                  <h1 className="journal_hdr pull-left log_activity_title" id="header_change"> </h1>
                  <div className="pull-right mt10 relative journal_search_input">
                    <FilteredList />

                  </div>
                </div>

                <div className="clear"></div>
                <hr />
                <div className="log_food_sec logactivitybtn" >

                  <div className="pull-right">
                    <Link to="/create_activity"><button className="create_new_text"><img alt="" src="/assets/images/square_plus.png" width="15" height="15" /></button></Link>
                  </div>

                </div>
                <div className="clear"></div>
                <div className="row clearfix">
                  <div className="col-md-8 col-12">
                    <div className="panel-group  pull-left text-center logactivitysec-left">
                      <div className="search_activity_empty">

                        <div className="text-center serach_on_click">
                          <img alt="" src="/assets/images/search_empty_activity.png" />
                          <p className="color7 font16 food_click_not_found"><span className="numi_couldnot_find"></span> <b></b> </p>

                        </div>
                      </div>
                      <div className="activity_sec">

                        <div className="show_activities width100">

                          <div className="flex">
                            <div className="running_act cursor_pointer" onClick={() => this.setHeaderActivity('RUN')}>

                              <img alt="" src="/assets/images/running.png" height="32" /> <br /><br /> <p className="numi_running"></p>


                            </div>
                            <div className=" walking_act  cursor_pointer" onClick={() => this.setHeaderActivity('WALK')}>

                              <img alt="" src="/assets/images/walking.png" /> <br /><br /> <p className="numi_walking"></p>
                            </div>
                          </div>


                          <div className="clear padding0 border0"></div>
                          <ul >
                            {activity_list_options}
                          </ul>
                        </div>
                        <div className="show_activity_list width100">
                          <ul>

                          </ul>
                        </div>

                      </div>
                      <button id="show_more" className="show_more font15 show_more_text" onClick={this.onLoadMore}></button>
                    </div>
                  </div>
                  <div className="col-md-4 col-12 text-right">
                    <div className=" right_container pull-right logactivitysec-right">
                      <ul className="favorite_section">
                        <li className="active"><a href="#log_favorite" data-toggle="tab" aria-expanded="true"><p className="activity_favorites_title"></p></a>
                        </li>
                        <li className=""><a href="#log_recent" data-toggle="tab" aria-expanded="false"><p className="activity_recents_title"></p></a></li>
                      </ul>
                      <div className="tab-content text-center">
                        <div className="tab-pane active" id="log_favorite">
                          <ul>
                            {favorite}
                          </ul>
                          <div className="fav_empty">
                            <img alt="" src="/assets/images/fav_empty.png" />
                            <p className="fav_hdr favorite_your_activity_text"></p>
                            <p className="fav_small_txt favorite_activity_message_text"></p>
                          </div>
                        </div>
                        <div className="tab-pane" id="log_recent">
                          <ul>
                            {recent_list_options}
                          </ul>
                          <div className="recent_empty">
                            <img alt="" src="/assets/images/recent_icon.png" />
                            <p className="fav_hdr start_logging_activity_text"></p>
                            <p className="fav_small_txt recent_activity_message_text"></p>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>


                <div>

                </div>

              </div>
              <Footer />
            </div>

          </div>
        </div>

      </div>
    )
  }
}

export default Log_activity;
