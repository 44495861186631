import React from 'react';
import Header from "../header.js";
import moment from 'moment';
import Footer from "../footer.js";
import axios from 'axios';
import { LogoutPopup } from '../journal/popup.js';
import $ from 'jquery';
import * as Sentry from "@sentry/react";

class Stay_on_track extends React.Component {

  constructor(props) {
    super(props)

    this.stayOnTrack = this.stayOnTrack.bind(this);

  }

  stayOnTrack() {
    var receive_emails = $('.recieve_email .communication_mobile').val();
    var communication_params = { "communication": { "receive_emails": receive_emails } };
    Header.startLoader(); // start loader
    const options = {
      url: window.API_URL + "v1/communication",
      method: 'PUT',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
      data: communication_params
    };

    axios(options)
      .then(response => {
        Header.stopLoader();
        window.localStorage.setItem('assigned_date', moment().format('YYYY-MM-DD'));
        window.localStorage.setItem('profile_status', 'ONBOARDING_COMPLETE');
        this.props.history.push('/connect_device');
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });
  }

  updateProfileStatus() {
    const options = {
      url: window.API_URL + "v1/profile",
      method: "PUT",
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
      data: { "profile": { "status": "PLAN_COMPLETE" } }
    };

    axios(options)
      .then(response => {
        if (response && response.data && response.data.profile && response.data.profile.status) {
          window.localStorage.setItem('profile_status', response.data.profile.status);
        }

      })
      .catch(error => {
        Header.apiErrors(error);
        Sentry.captureException(error);
      });
  }
  goJounal = () => {
    this.props.history.push("/journal");
  }

  componentDidMount() {

    const communication_options = {
      url: window.API_URL + "v1/communication",
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(communication_options)
      .then(response => {
        if (response && response.data) {

          $('.recieve_email .communication_mobile').val(response.data.communication.receive_emails);

          if ($('.recieve_email .communication_mobile').val() == 'true') {
            $('.toggle_switch').removeClass('off')
            $('.toggle_switch').addClass('on')
          }
          else {
            $('.toggle_switch').removeClass('on')
            $('.toggle_switch').addClass('off')
          }
        }
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });

    localStorage.setItem('load', false);
    // OnboardingWizard
    Header.startLoader(); // start loader

    const options = {
      url: window.API_URL + "v1/profile",
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };
    var _this = this;
    axios(options)
      .then(response => {
        Header.stopLoader(); // stop loader
        if (response && response.data && response.data.profile) {
          if (response.data.profile.status === null) {
            _this.props.history.push('/login');
          }
          else if ((response.data.profile.status === "ONBOARDING_COMPLETE") && (window.localStorage.getItem('journal_type') !== null)) {
            window.localStorage.setItem('assigned_date', moment().format('YYYY-MM-DD'));

            _this.props.history.push('/journal');
          }
          else if ((response.data.profile.status === "ONBOARDING_COMPLETE") && (window.localStorage.getItem('journal_type') === null)) {
            this.updateProfileStatus();
          }
          else if (response.data.profile.status === "PROFILE_COMPLETE" || response.data.profile.status === "ONBOARDING") {
            _this.props.history.push('/welcome');
          }
        }
      })
      .catch(error => {
        Header.stopLoader(); // stop loader
        Header.apiErrors(error);
        Sentry.captureException(error);
      });




    $('.stay_left_arrow').click(function () {
      window.localStorage.setItem('load_plans', 'true');
      window.localStorage.setItem('stop_calldet', 'true');
    })

    $('.toggle_switch').click(function () {
      $(this).toggleClass('on').toggleClass('off');
      $(this).prev().toggleClass('switch_prev');

      if ($(this).hasClass('on')) {
        $(this).find('input').val('true')
      }
      else {
        $(this).find('input').val('false')
      }
    });

    /*keystrings*/
    $('.stay_on_track_header_text').text(window.keystrings['com.numi.stay_on_track'])
    $('.stay_on_track_desc_text').html(window.keystrings['com.numi.stay_on_track.desc_web'])
    $('.communication_settings_email_notification_text').text(window.keystrings['com.numi.communication_settings.email_notification'])
    $('.continue_text').text(window.keystrings['com.numi.continue'])
    $('.email_notification_note_text').text(window.keystrings['com.numi.communication_settings.note'])
    /*keystrings*/
  }

  render() {


    return (
      <div className="main_container">

        <div className="forgot_password_hero">
          <div><img onClick={() => this.goJounal()} alt="" src="/assets/images/numi_small_logo.png" className="logo img-responsive cursor_pointer" /></div>
          <div className="signup_sec  relative" id="profile_sec">
            <div className="profile_section">
              <img alt="" src="/assets/images/back_white_icon.png" className="left_arrow cursor_pointer" data-target="#logout_modal" data-toggle="modal" />
              <div className="width93">
                <div className="text-center stay_on_track">
                  <img alt="" src="/assets/images/stay_on_track.png" />
                  <h3 className="text-center stay_on_track_header_text"> </h3 >
                  <h6 className="color6 stay_on_track_desc_text"> </h6>


                  <div className="hor_line"></div>
                  <div className="recieve_email width77 text-left">
                    <span className="font16 color6 communication_settings_email_notification_text"></span>
                    <div className="toggle_switch round on">
                      <div className="toggle_btn"></div>
                      <span className="on"></span>
                      <span className="off"></span>
                      <input type="hidden" value="true" name="" className="communication_mobile" />
                    </div>
                  </div>

                  <button className="font18 ml15 mt10p continue_text" onClick={this.stayOnTrack}></button>
                  <br /><br /><br />
                  <span className="color6 email_notification_note_text"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LogoutPopup />
        <Footer />
      </div>

    );
  }
}

export default Stay_on_track;
