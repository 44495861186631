import React from 'react';
import {  Link } from 'react-router-dom'
import  Footer from  "../footer.js";
import $ from 'jquery';

class Welcome extends React.Component {

componentDidMount(){
	/*keystrings*/
	$('.numi_all_about_you_text').text(window.keystrings['com.numi.numi_all_about_you'])
	$('.welcome_desc_text').text(window.keystrings['com.numi.welcome_desc'])
	$('.lets_get_started_text').text(window.keystrings['com.numi.lets_get_started'])
	/*keystrings*/
}

render() {
      return (
      	<div>
	      	
				<div className="forgot_password_hero">
					<div><img alt="" src="/assets/images/numi_small_logo.png" className="logo img-responsive"/></div>
					<div className="welcome_curr_user text-center">
					  	<p><img alt="" src="/assets/images/leaf.png" /></p>
					  	<span className="numi_txt numi_all_about_you_text"></span>
					  	<p className="welcome_desc_text"></p>
					    <p><Link to="/ns_plan"> <button  className="lets_get_start lets_get_started_text"></button></Link></p>
					</div>
				</div>
			 <Footer/>
		</div>
      )
   }
}
export default Welcome;
