import React from 'react';
import Footer from "../footer.js";
import Navigation from "../nav.js";
import Adspeed from "../adspeed.js";

class Social extends React.Component {
    render() {
        return (
            <div className="social" id="social">
                <div className="table_display">
                    <div className="journal_div journal_page">
                        <div className="section_left pull-left">
                            <Navigation />
                        </div>
                        <div className="section_right pull-left">
                            <figure><Adspeed /></figure>
                            <div className="journal_rht">
                                <h1 className="journal_hdr">Social</h1>
                                <hr />
                                <div className='facebook-community-card'>
                                    <div className='community-section'>
                                        <img src="/assets/images/facebook_card_icon.svg" />
                                        <div className='community-section-label'>
                                            <h4>Official Nutrisystem Facebook Community</h4>
                                            <p>Connect with real people in a group made for goal-getters just like YOU!</p>
                                        </div>
                                    </div>
                                    <div className='community-btn'>
                                        <p><a target="_blank" href='https://www.facebook.com/groups/officialnutrisystemsupportcommunity'>Check it out</a></p>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Social;
