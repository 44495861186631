import React, { useEffect, useState } from 'react';
import { CalorieComp } from './popup.js';
import Header from "../header.js";
import * as Sentry from "@sentry/react";
import axios from 'axios';
import { useHistory } from "react-router";
import { Modal } from 'react-bootstrap';
import { addDays, parse } from 'date-fns';
import { Draggable, Droppable } from "react-beautiful-dnd";
import moment from 'moment';
import DatePicker from "react-datepicker";
import MealDropdown from "./meal_dropdown.js";
var $ = require('jquery');
window.jQuery = $;

const MealOccasionSection = (props) => {
    const [calorie_tracker, setCalorieTracker] = useState('');
    const [selectedAccordian, setSelectedAccordian] = useState([]);
    const [currMealOcassionId, setCurrMealOcsssionId] = useState("");
    const [revertFromMealOccasionPopup, setRevertFromMealOccasionPopup] = useState(false);
    const [currRevertMealOccassionId, setCurrRevertMealOccassionId] = useState(null);
    const [currRevertMealOccassionName, setCurrRevertMealOccassionName] = useState(null);
    const [currRevertFlexMealPlanName, setCurrRevertFlexMealPlanName] = useState(null);
    const [copyNotValidModal, setCopyNotValidModal] = useState(false);
    const [copyModal, setCopyModal] = useState(false);
    const [logCaloriePopup, setLogCaloriePopup] = useState(false);
    const [copyNotValidText, setCopyNotValidText] = useState('');
    const [startDate, setStartDate] = useState(addDays(window.localStorage.getItem('assigned_date'), 1));
    const [copyToDate, setCopyToDate] = useState("");
    const [occasionName, setOccassionName] = useState("");
    const [foodError, setFoodError] = useState("");
    const [copyError, setCopyError] = useState("");
    const history = useHistory();
    const [logInputValue, setLogInputValue] = useState("");
    const [resetLogFoodPopup, setResetLogFoodPopup] = useState(false);
    const [currResetQuickLogId, setCurrResetQuickLogId] = useState(null);
    const [defaultMealPopup, setDefaultPopup] = useState(false);

    let revertFromMealOccasion = [];
    let revertToMealOccasion = [];


    window.show_meal_drop_on_flex_click = false;
    window.get_curr_div_value = "";
    var gg = 0;
    var food_consumed = false;

    function onHideRevertFromMealOccasionPopup() {
        setRevertFromMealOccasionPopup(false);
    }

    function onHideCopyNotValidModal() {
        setCopyNotValidModal(false);
    }

    function onHideCopyModal() {
        setCopyModal(false);
    }

    function onHideLogCaloriePopup() {
        setLogCaloriePopup(false);
    }

    function onHideResetLogFoodPopup() {
        setResetLogFoodPopup(false);
    }

    function onHideDefaultMealPopup() {
        setDefaultPopup(false);
    }

    function setDate(d) {
        var today = moment();
        var tomorrow = moment().add(1, 'day');
        var yesterday = moment().subtract(1, 'day');
        var equal_dates = moment(d);

        if ($('.modal.fade.show').find(".today_date").length === 0) {
            $('.modal.fade.show').find('.react-datepicker__input-container').prepend('<div class="today_date">Today</div>');
        }


        if (moment(equal_dates).isSame(tomorrow, 'day'))
            $('.modal.fade.show').find('.today_date').text('Tomorrow');
        else if (moment(equal_dates).isSame(yesterday, 'day'))
            $('.modal.fade.show').find('.today_date').text('Yesterday');
        else if (moment(equal_dates).isSame(today, 'day'))
            $('.modal.fade.show').find('.today_date').text('Today');
        else
            $('.modal.fade.show').find('.today_date').text(moment(d).format('MMM DD'));

    }

    var meal_colour_code = {
        "BREAKFAST": "#308DC1",
        "AM_SNACK": "#666666",
        "LUNCH": "#FF6100",
        "PM_SNACK": "#666666",
        "DINNER": "#EB5F5F",
        "DINNER SNACK": "#666666"
    }

    function onToggleFlex(value, meal_occasion_name, ind, meal_occasion_id, flex) {
        var flex_meal_plan_name = '';
        window.localStorage.setItem('flex_meal_occasion_name', meal_occasion_name);
        const imageTreat = document.getElementsByClassName('flex_img_display')

        if (value === "Flex Meal: On") {
            var index = document.getElementsByClassName('flex1')[ind] == undefined ? ind - 1 : ind
            flex_meal_plan_name = 'FLEX_MEAL_1';
            document.getElementsByClassName('flex2')[index].style.display = "none";
            document.getElementsByClassName('flex4')[index].style.display = "none";
            document.getElementsByTagName('img')[0].style.display = "none";
        }
        else if (value === "Treat Meal: On" || value === "Cheat Meal: On") {
            var index = document.getElementsByClassName('flex1')[ind] == undefined ? ind - 1 : ind
            flex_meal_plan_name = 'CHEAT_MEAL_1';
            document.getElementsByClassName('flex1')[index].style.display = "none";
            document.getElementsByClassName('flex2')[index].style.display = "none";
            document.getElementsByTagName('img')[0].style.display = "none";
        }
        else {
            flex_meal_plan_name = 'STANDARD';
            if (value === "Flex Meal: Off") {
                var index = document.getElementsByClassName('flex1')[ind] == undefined ? ind - 1 : ind
                document.getElementsByClassName('flex1')[index].style.display = "none";
                document.getElementsByClassName('flex4')[index].style.display = "none";
                document.getElementsByTagName('img')[0].style.display = "none";
            }
            else {
                return;
            }
        }

        if (revertFromMealOccasion.indexOf(meal_occasion_id) > -1 || revertToMealOccasion.indexOf(meal_occasion_id) > -1) {
            setCurrRevertMealOccassionId(parseInt(meal_occasion_id));
            setCurrRevertMealOccassionName(meal_occasion_name)
            setCurrRevertFlexMealPlanName(flex_meal_plan_name)
            if (flex === true && value !== "Flex Meal: On") {
                setRevertFromMealOccasionPopup(true);

            } else if (flex === false && value !== "Flex Meal: Off") {
                setRevertFromMealOccasionPopup(true);
            }

        }
        else {
            Header.startLoader();
            window.show_meal_drop_on_flex_click = true;
            window.get_curr_div_value = value;


            var meal_plan_data = {
                "meal_plan_history": {
                    "assigned_date": window.localStorage.getItem('assigned_date'),
                    "meal_plan_occasion_goal_histories": [{
                        "meal_occasion_name": meal_occasion_name,
                        "flex_meal_plan_name": flex_meal_plan_name,
                        "single_day": true,
                        "user_default": false
                    }]
                }
            }

            const options = {
                url: window.API_URL + 'v1/meal_plan_histories',
                method: 'POST',
                credentials: 'same-origin',
                headers: Header.setAuthHeaders(),
                data: meal_plan_data
            };

            axios(options)
                .then(response => {
                    props.getJournal();
                })
                .catch(error => {
                    Header.stopLoader();
                    props.getJournal();
                    Sentry.captureException(error);
                });

        }
    }

    function onRevertMealOccassion() {
        var meal_revert_data = {
            "meal_occasion_id": currRevertMealOccassionId,
            "assigned_date": window.localStorage.getItem('assigned_date')
        }

        const options = {
            url: window.API_URL + 'v1/meal_plan_histories/revert_dragged_food',
            method: 'DELETE',
            credentials: 'same-origin',
            headers: Header.setAuthHeaders(),
            data: meal_revert_data
        };

        axios(options)
            .then(response => {
                setRevertFromMealOccasionPopup(false);
                var meal_plan_data = {
                    "meal_plan_history": {
                        "assigned_date": window.localStorage.getItem('assigned_date'),
                        "meal_plan_occasion_goal_histories": [{
                            "meal_occasion_name": currRevertMealOccassionName,
                            "flex_meal_plan_name": currRevertFlexMealPlanName,
                            "single_day": true,
                            "user_default": false
                        }]
                    }
                }

                const options = {
                    url: window.API_URL + 'v1/meal_plan_histories',
                    method: 'POST',
                    credentials: 'same-origin',
                    headers: Header.setAuthHeaders(),
                    data: meal_plan_data
                };

                axios(options)
                    .then(response => {
                        props.getJournal();
                    })
                    .catch(error => {
                        Header.stopLoader();
                        props.getJournal();
                        Sentry.captureException(error);
                    });
            })
            .catch(error => {
                Header.stopLoader();

                props.getJournal();
                Sentry.captureException(error);
            });
    }

    function onLogAdditionalFood(e) {
        window.localStorage.setItem('autoscroll', 'true');
        window.localStorage.setItem('scroll_id', window.pageYOffset);
        window.localStorage.setItem('meal_occasion_id', e.currentTarget.getAttribute('id'));
        window.localStorage.setItem('meal_occasion_name', e.currentTarget.getAttribute('name'));
        localStorage.removeItem('food_categories');
        localStorage.removeItem('food_type');
        window.localStorage.setItem('log_food_search', "true");
        window.localStorage.setItem('from_addintional_log', "true");
        window.localStorage.setItem('from_plus', "true");
        history.push({ pathname: "/log_food" });
    }

    function copyYesterdayMeal(get_value) {
        var cpoy_data = {
            "diet_histories": [{
                "copy_by_occasion": {
                    "from_occasion": get_value,
                    "from_date": moment(window.localStorage.getItem('assigned_date')).subtract(1, 'days').format('YYYY-MM-DD'),
                    "assigned_date": window.localStorage.getItem('assigned_date'),
                    "occasion": get_value
                }
            }]
        }

        const options = {
            url: window.API_URL + 'v1/diet_histories/copy_by_occasion',
            method: 'POST',
            credentials: 'same-origin',
            headers: Header.setAuthHeaders(),
            data: cpoy_data
        };

        axios(options)
            .then(response => {

                props.getJournal();
            })
            .catch(error => {
                Header.stopLoader();
                setCopyNotValidModal(true);
                if (error && error.response && error.response.status > 400) {
                    setCopyNotValidText(error.response.data.error);
                }
                else if (error && error.response && error.props.journalResponse && error.props.journalResponse.error) {
                    setCopyNotValidText(error.props.journalResponse.error);
                }
                Sentry.captureException(error);
            });
    }

    function handleChange(date) {
        setStartDate(date)
        var complete_date = moment(date).format('MMM DD');
        var actual_date = moment().format('MMM DD');
        var pageload_selecetd_date = moment(date).format('MMM DD');
        var kk = moment(date).format('MMM DD');
        var actual_date_format = moment(date).format('YYYY-MM-DD');
        if (pageload_selecetd_date === actual_date) {
            setCopyToDate(kk);
        }
        else {
            setCopyToDate(complete_date);
        }
        Header.changeDate(date);
        const options = {
            url: window.API_URL + "v1/phase_templates/assigned_date/" + actual_date_format,
            method: 'GET',
            credentials: 'same-origin',
            headers: Header.setAuthHeaders(),
        };

        axios(options)
            .then(response => {
                if (props.journalResponse.key !== undefined) {
                    if (props.journalResponse.key === window.localStorage.getItem('phase_template_key')) {
                        setCopyError("");
                    }
                    else {
                        setCopyError(kk + window.keystrings['com.numi.copy_food_to_different_phase_warning']);
                    }
                }
            })
            .catch(error => {
                Header.stopLoader();
                Header.apiErrors(error);
                Sentry.captureException(error);
            });
    }


    function copyMeal() {
        window.click_copy_food = true;
        var copy_meal_data = {
            "diet_histories": [{
                "copy_by_occasion": {
                    "from_occasion": window.localStorage.getItem('meal_occasion_name'),
                    "from_date": window.localStorage.getItem('assigned_date'),
                    "assigned_date": moment(startDate).format('YYYY-MM-DD'),
                    "occasion": window.localStorage.getItem('meal_occassion_name_copy_food')
                }
            }]
        };

        const options = {
            url: window.API_URL + 'v1/diet_histories/copy_by_occasion',
            method: 'POST',
            credentials: 'same-origin',
            headers: Header.setAuthHeaders(),
            data: copy_meal_data
        };

        axios(options)
            .then(response => {
                setCopyModal(false);
                window.localStorage.setItem('assigned_date', moment(startDate).format('YYYY-MM-DD'))

                props.getJournal();
            })
            .catch(error => {
                Header.stopLoader();
                if (error && error.response && error.props.journalResponse && error.props.journalResponse.error) {
                    setFoodError(<div class="food_error error_txt text-center font14">{error.props.journalResponse.error}</div>)
                }
                else if (error && error.response && error.response.status > 400) {
                    setFoodError(<div class="food_error error_txt text-center font14">{error.props.journalResponse.error}</div>)
                }
                Sentry.captureException(error);
            });

    };

    function onSaveLogFoodCategory() {
        var log_meal_data = {
            "diet_histories": [
                {
                    "calorie_tracker_quick_log": "true",
                    "consumable_type": "QuickLog",
                    "assigned_date": window.localStorage.getItem('assigned_date'),
                    "meal_occasion_id": currMealOcassionId.toString(),
                    "serving_size": 1,
                    "calories": logInputValue.toString()
                }
            ]
        }


        const options = {
            url: window.API_URL + 'v1/diet_histories',
            method: 'POST',
            credentials: 'same-origin',
            headers: Header.setAuthHeaders(),
            data: log_meal_data
        };

        axios(options)
            .then(response => {
                setLogCaloriePopup(false);

                props.getJournal();
            })
            .catch(error => {
                Header.stopLoader();
                Sentry.captureException(error);
            });
    }

    function handleQuicklogs(e, id, meal_occasion_id, serving_size, default_meal_occasion_id, food_state, consumable_type) {
        Header.startLoader();
        window.localStorage.setItem('autoscroll', 'quicklog');
        window.localStorage.setItem('scroll_id', window.pageYOffset);
        window.localStorage.setItem('meal_occasion_id', meal_occasion_id);
        var quicklog_data = {};
        if (food_state == "UNUSED") {
            if (default_meal_occasion_id == null) {
                quicklog_data = {
                    "diet_histories": [{
                        "unused_food_category_id": id.toString(),
                        "consumable_type": "QuickLog",
                        "assigned_date": moment(window.localStorage.getItem('assigned_date')).format('YYYY-MM-DD'),
                        "meal_occasion_id": meal_occasion_id.toString(),
                        "serving_size": 1
                    }]
                };
            }
            else {
                quicklog_data = {
                    "diet_histories": [
                        {
                            "unused_food_category_id": id.toString(),
                            "consumable_type": "QuickLog",
                            "assigned_date": moment(window.localStorage.getItem('assigned_date')).format('YYYY-MM-DD'),
                            "meal_occasion_id": meal_occasion_id.toString(),
                            "serving_size": serving_size,
                            "default_meal_occasion_id": default_meal_occasion_id
                        }
                    ]
                }
            }
            const options = {
                url: window.API_URL + 'v1/diet_histories',
                method: 'POST',
                credentials: 'same-origin',
                headers: Header.setAuthHeaders(),
                data: quicklog_data
            };

            axios(options)
                .then(response => {
                    Header.stopLoader();
                    // window.localStorage.setItem("autoscroll", false)
                    props.getJournal()

                })
                .catch(error => {
                    Header.stopLoader();
                    Sentry.captureException(error);
                });
        }
        else {
            if (consumable_type == "QuickLog") {
                if (localStorage.getItem('journal_type') != "CALORIE_TRACKER") {
                    const options = {
                        url: window.API_URL + `v1/diet_histories/${id}`,
                        method: 'DELETE',
                        credentials: 'same-origin',
                        headers: Header.setAuthHeaders(),
                    };

                    axios(options)
                        .then(response => {
                            Header.stopLoader();
                            // window.localStorage.setItem("autoscroll", false)
                            props.getJournal();
                        })
                        .catch(error => {
                            Header.stopLoader();
                            console.log(error);
                            Sentry.captureException(error);
                        });
                } else {
                    Header.stopLoader();
                    setCurrResetQuickLogId(id);
                    setResetLogFoodPopup(true);
                }
            }
            else {
                Header.stopLoader();
                setCurrResetQuickLogId(id);
                setResetLogFoodPopup(true);
            }
        }

    }

    function toggleSnackFlex(occasionName, cheat, meal_occasion_id) {
        if (revertFromMealOccasion.indexOf(meal_occasion_id) > -1 || revertToMealOccasion.indexOf(meal_occasion_id) > -1) {
            setRevertFromMealOccasionPopup(true);
            setCurrRevertMealOccassionId(parseInt(meal_occasion_id));
        } else {
            if (cheat == false) {

                var flex_meal_plan_name = 'CHEAT_MEAL_1';
                var meal_plan_data = {
                    "meal_plan_history": {
                        "assigned_date": window.localStorage.getItem('assigned_date'),
                        "meal_plan_occasion_goal_histories": [{
                            "meal_occasion_name": occasionName,
                            "flex_meal_plan_name": flex_meal_plan_name,
                            "single_day": true,
                            "user_default": false
                        }]
                    }
                }
                const options = {
                    url: window.API_URL + 'v1/meal_plan_histories',
                    method: 'POST',
                    credentials: 'same-origin',
                    headers: Header.setAuthHeaders(),
                    data: meal_plan_data
                };

                axios(options)
                    .then(response => {
                        this.getJournal();
                    })
                    .catch(error => {
                        Header.stopLoader();
                        props.getJournal();
                        Sentry.captureException(error);
                    });

            }
            else {
                var flex_meal_plan_name = 'CHEAT_MEAL_OFF';
                var meal_plan_data = {
                    "meal_plan_history": {
                        "assigned_date": window.localStorage.getItem('assigned_date'),
                        "meal_plan_occasion_goal_histories": [{
                            "meal_occasion_name": occasionName,
                            "flex_meal_plan_name": flex_meal_plan_name,
                            "single_day": true,
                            "user_default": false
                        }]
                    }
                }
                const options = {
                    url: window.API_URL + 'v1/meal_plan_histories',
                    method: 'POST',
                    credentials: 'same-origin',
                    headers: Header.setAuthHeaders(),
                    data: meal_plan_data
                };

                axios(options)
                    .then(response => {
                        props.getJournal();
                    })
                    .catch(error => {
                        Header.stopLoader();
                        props.getJournal();
                        Sentry.captureException(error);
                    });
            }
        }

    }


    function saveManageMeal() {
        if (revertFromMealOccasion.indexOf(currRevertMealOccassionId) > -1 || revertToMealOccasion.indexOf(currRevertMealOccassionId) > -1) {
            setDefaultPopup(false);
            setRevertFromMealOccasionPopup(true);
            setCurrRevertMealOccassionId(parseInt(currRevertMealOccassionId));
        }
        else {
            var flex_meal_plan_value = document.getElementById('multi_check1').checked == true ? "Flex Meal: Off" : "Flex Meal: On";
            var flex_meal_plan_name = '';
            if (flex_meal_plan_value === "Flex Meal: Off") {
                flex_meal_plan_name = 'STANDARD';
            }
            else {
                flex_meal_plan_name = 'FLEX_MEAL_1';
            }
            var meal_option_data = {
                "meal_plan_history": {
                    "assigned_date": window.localStorage.getItem('assigned_date'),
                    "meal_plan_occasion_goal_histories": [{
                        "meal_occasion_name": occasionName,
                        "flex_meal_plan_name": flex_meal_plan_name,
                        "single_day": false,
                        "user_default": true
                    }]
                }
            }

            const options = {
                url: window.API_URL + 'v1/meal_plan_histories',
                method: 'POST',
                credentials: 'same-origin',
                headers: Header.setAuthHeaders(),
                data: meal_option_data
            };

            axios(options)
                .then(response => {
                    setDefaultPopup(false);

                    props.getJournal();
                    Header.stopLoader();
                })
                .catch(error => {
                    Header.stopLoader();
                    setDefaultPopup(false);

                    props.getJournal();
                    Sentry.captureException(error);
                });
        }


    }

    return (
        <>

            <div>
                {
                    props.mealsectiondata?.map(function (option, i) {
                        var flex = null;
                        var cheat = null;
                        var flextoggle = null;
                        var flex_image;

                        if (option.flex_meal_options !== null) {
                            for (var fl = 0; fl < option.flex_meal_options.length; fl++) {
                                if (option.flex_meal_options[fl].selected === true) {
                                    if (option.flex_meal_options[fl].name === "STANDARD") {
                                        flex = false;
                                        cheat = false;
                                    }
                                    if (option.flex_meal_options[fl].name === "FLEX_MEAL_1") {
                                        flex = true;
                                        cheat = false;
                                    }
                                    if (option.flex_meal_options[fl].name === "CHEAT_MEAL_1") {
                                        cheat = true;
                                        flex = false;
                                    }
                                } else {
                                    if (option.flex_meal_options[fl].name === "CHEAT_MEAL_1") {
                                        cheat = false;
                                        if (flex === null) {
                                            flex = false;
                                        }
                                    }
                                }

                            }
                        }

                        if (option.show_cheat_day_slider === true) {
                            flextoggle = true;
                        }

                        if (props.journalResponse.all_meals_cheat_meal_status === true) {
                            flex_image = "/assets/images/cheat_meal_on.png"
                        } else if (cheat === true && props.journalResponse.cheat_day_status === true) {
                            flex_image = "/assets/images/cheat_meal_on.png"
                        } else {
                            if (flex === true) {
                                flex_image = "/assets/images/flex_meal_on.png"
                            }
                            else {
                                if (flex === false) {
                                    flex_image = "/assets/images/flex_meal_off.png"
                                }
                                else {
                                    flex_image = "";
                                }
                            }
                        }

                        var treat_meal_text;
                        if (window.localStorage.getItem('gender') === 'male') {
                            treat_meal_text = window.keystrings['com.numi.cd.male_treat_meal_on']
                        } else {
                            treat_meal_text = window.keystrings['com.numi.cd.female_treat_meal_on']
                        }

                        food_consumed = false;

                        return (
                            <Droppable droppableId={`droppable-${option.meal_occasion_id}-droppableallow`} type="meal_occasion">
                                {(provider, snapshot) => (
                                    <div
                                        ref={provider.innerRef}
                                        {...provider.droppableProps}
                                    >
                                        <div className={`panel panel-default ${snapshot.isDraggingOver && "dropping"}`} id={i} key={i}>
                                            <div className="panel-heading" id={i}>
                                                <h4 className="panel-title ptb1 cursor_pointer" data-toggle="collapse" data-parent="#meal_accordion" style={{ color: meal_colour_code[option.meal_occasion_name] }} onClick={() => {
                                                    if (selectedAccordian.includes(i) === true) {
                                                        var newarr = [];
                                                        var index = selectedAccordian.indexOf(i);
                                                        newarr = selectedAccordian;
                                                        newarr.splice(index, 1);
                                                        setSelectedAccordian([...newarr]);
                                                    }
                                                    else {
                                                        setSelectedAccordian([...selectedAccordian, i]);
                                                    }

                                                    var k = '#' + 'meal' + option.meal_occasion_id;
                                                    localStorage.setItem(k, 'true');
                                                }}>
                                                    <span className="meal_plan_options show_icons" >
                                                        <span className="meal_name accordion-toggle vlt cursor_pointer" data-toggle="collapse" data-parent="#meal_accordion" style={{ color: meal_colour_code[option.meal_occasion_name] }}>{option.meal_occasion_display_name || option.meal_occasion_name}</span>
                                                        <span className="meal_occasion_logos" style={{ display: selectedAccordian.includes(i) === false || selectedAccordian.length === 0 ? "none" : "" }}>
                                                            {
                                                                option.summary_displays.map((summary, j) => {

                                                                    return (
                                                                        <span className="meal_logos mt" key={j}><img alt="" width="48" height="48" src={summary.url} className="standard_img" /></span>
                                                                    )
                                                                })
                                                            }
                                                        </span>
                                                        <span className={`pull-right mt5 mr3 arrows relative ${selectedAccordian.includes(i) === false || selectedAccordian.length === 0 ? "" : "rotate"}`}>
                                                        </span>
                                                    </span>
                                                </h4>

                                            </div>
                                            {flextoggle !== true && flex !== null ?

                                                <span className="flex_text standard pull-right dropdown" name={option.meal_occasion_name} style={{ display: selectedAccordian.includes(i) === false || selectedAccordian.length === 0 ? "" : "none" }}>
                                                    <img alt="" className="flex_img_display" src={flex_image} data-toggle="dropdown" />
                                                    <img alt="" src="/assets/images/flex_meal_on.png" data-toggle="dropdown" className="flex1" />
                                                    <img alt="" src="/assets/images/flex_meal_off.png" data-toggle="dropdown" className="flex2" />
                                                    <img alt="" src="/assets/images/flex_meal_2on.png" data-toggle="dropdown" className="flex4" />
                                                    <img alt="" src="/assets/images/flex_meal_off.png"
                                                        data-toggle="dropdown" className="flex4" />
                                                    <ul className="dropdown-menu">
                                                        <li id="flexoffli"><a href="javascript:void(0)" className="off_flex" onClick={() => onToggleFlex(window.keystrings['com.numi.journal.flex_meal_off'], option.meal_occasion_name, i, option.meal_occasion_id, flex)}>{window.keystrings['com.numi.journal.flex_meal_off']}</a></li>
                                                        <li id="flexonli"><a href="javascript:void(0)" className="on_flex" onClick={() => onToggleFlex(window.keystrings['com.numi.journal.flex_meal_on'], option.meal_occasion_name, i, option.meal_occasion_id, flex)}>{window.keystrings['com.numi.journal.flex_meal_on']}</a></li>
                                                        <li id="cheatdaylineli" className="cheatdaylineli" style={{ display: props.cheatDaylineShow === true ? "block" : "none" }}><a href="javascript:void(0)" className="on_cheatday" style={{ display: props.showSmartWeek === true ? "block" : "none" }} onClick={() => onToggleFlex(treat_meal_text, option.meal_occasion_name, i, option.meal_occasion_id, flex)}>{treat_meal_text}</a></li>
                                                        <li id="managemealli"><a href="javascript:void(0)" data-toggle="modal" className="manage_meals" name={option.meal_occasion_name} onClick={() => {
                                                            setDefaultPopup(true);
                                                            setCurrRevertMealOccassionId(option.meal_occasion_id);
                                                            setOccassionName(option.meal_occasion_name,);
                                                        }}> {window.keystrings['com.numi.journal.manage_meals']}</a></li> </ul></span> :
                                                flextoggle === true && flex !== null && (props.journalResponse.cheat_day_status === true) ? (

                                                    <div className={`toggle_switch round ${option.flex_meal_options !== null && option.flex_meal_options[0].name == 'CHEAT_MEAL_1' && option.flex_meal_options[0].selected == true ? 'on' : 'off'} toggle_treat`}
                                                        onClick={() => { toggleSnackFlex(option.meal_occasion_name, option.flex_meal_options[0].selected, option.meal_occasion_id) }}
                                                        name={`${option.meal_occasion_name}`} id={`${option.meal_occasion_name}`} style={{ display: selectedAccordian.includes(i) === false || selectedAccordian.length === 0 ? "" : "none" }}><div className="toggle_btn"></div><span className="on"></span><span className="off"></span><input type="hidden" id="" value={`${cheat}`} /></div>) : ("")

                                            }


                                            <div id={'meal' + option.meal_occasion_id} value={option.meal_occasion_id} className={`panel-collapse collapse ${selectedAccordian.includes(i) === false || selectedAccordian.length === 0 ? "in" : ""}`}>
                                                <div className="panel-body" id={i}>
                                                    <div className="food_items" id={i} key={i + 1}>
                                                        {
                                                            option.food_details.map((food, index) => {
                                                                if (food_consumed === false && food.unused_food_item === undefined) {
                                                                    food_consumed = true;
                                                                }
                                                                gg++;

                                                                var food_item = food.unused_food_item || food.food_item
                                                                var custom_food = '';
                                                                var over_plan = '';
                                                                var display_name = '';
                                                                var calories = '';
                                                                var detail_display_multi = [];
                                                                var detail_display = [];
                                                                var unused = false;
                                                                var quicklog = false;
                                                                var id = null;
                                                                var unused_food_category_id = null;
                                                                var consumable_id = null;
                                                                var consumable_type = null;
                                                                var meal_occasion_id = option.meal_occasion_id;
                                                                var meal_occasion_name = option.meal_occasion_name;
                                                                var food_categories = [food_item.detail_display !== null ? food_item.detail_display.food_category : []];
                                                                if (food.unused_food_item === undefined) {
                                                                    over_plan = (food_item.over_plan === true) ? window.keystrings['com.numi.over_plan'] : "";
                                                                    if (food_item.multiple_servings === true) {
                                                                        custom_food = window.keystrings['com.numi.multiple_servings']
                                                                    }
                                                                    else {
                                                                        custom_food = food_item.food_type_display || ''
                                                                    }

                                                                    display_name = food_item.consumable_name
                                                                    calories = food_item.servings_calories
                                                                    detail_display_multi = food_item.detail_display_multi
                                                                    detail_display = food_item.detail_display
                                                                    id = food_item.id;

                                                                    if (food_item.consumable_type === "QuickLog") {
                                                                        if (props.journalResponse_type === "CALORIE_TRACKER") {
                                                                            quicklog = false;
                                                                        } else {
                                                                            quicklog = true;
                                                                        }

                                                                        consumable_id = food_item.consumable_id;
                                                                    }
                                                                    else {
                                                                        consumable_id = food_item.consumable_id;
                                                                        consumable_type = food_item.consumable_type;
                                                                    }
                                                                }
                                                                else {
                                                                    display_name = food_item.display_name
                                                                    unused = true;
                                                                    unused_food_category_id = food_item.id;
                                                                }
                                                                var archive_icon = "";
                                                                if (props.archive_view !== true) {
                                                                    if (food_item.callout_icon === "CARET") {
                                                                        archive_icon = 'caret_icon';

                                                                    }
                                                                    else {
                                                                        if (food_item.callout_icon === "PLUS") {
                                                                            archive_icon = 'plus_icon';

                                                                        }
                                                                        else {
                                                                            archive_icon = 'search_small';

                                                                        }
                                                                    }
                                                                    if (food_item.moved_from && revertFromMealOccasion.indexOf(option.meal_occasion_id) === -1) {
                                                                        revertFromMealOccasion.push(option.meal_occasion_id)
                                                                    }

                                                                    if (food_item.default_meal_occasion_id && revertToMealOccasion.indexOf(food_item.default_meal_occasion_id) === -1) {
                                                                        revertFromMealOccasion.push(food_item.default_meal_occasion_id)
                                                                    }
                                                                }
                                                                return (
                                                                    <Draggable key={"" + id} draggableId={"" + (option.meal_occasion_id + "-" + food_item.id + "-" + index + "-" + (food_item.default_meal_occasion_id ? food_item.default_meal_occasion_id : option.meal_occasion_id) + "-" + (food_item.detail_display_multi.length > 0 ? (food_item.detail_display_multi.some(detail_display_multi => detail_display_multi.state.includes("USED")) ? "USED" : "OVER") : food_item.detail_display.state) + "-" + (detail_display?.food_category == "MULTI_CATEGORY" ? "multilog" : "singlelog") + "-" + food_item.consumable_type)} index={index} isDragDisabled={props.drag_drop_status === true ? false : true} className="draggable">
                                                                        {(provider, snapshot) => (
                                                                            <div {...provider.draggableProps} value={index} ref={provider.innerRef} {...provider.dragHandleProps}>
                                                                                <div
                                                                                    className={`full_width inline_block meal_data ${snapshot.isDragging && "dragging"}`}
                                                                                    quicklog={quicklog} id={index}>
                                                                                    <img src="/assets/images/drag_drop_icon.png" className='mr5px' style={{ display: props.drag_drop_status === true ? "block" : "none" }} />
                                                                                    <div className="food_details pull-left font16 relative cursor_pointer" unused_food_category_id={unused_food_category_id} id={id} unused={unused} quicklog={quicklog} meal_occasion_id={meal_occasion_id} meal_occasion_scroll_id={meal_occasion_id + '-' + index}
                                                                                        onClick={(e) => {

                                                                                            handleQuicklogs(e,
                                                                                                food_item.id,
                                                                                                option.meal_occasion_id,
                                                                                                food_item.serving_size ? food_item.serving_size : 1,
                                                                                                food_item.default_meal_occasion_id ? food_item.default_meal_occasion_id : null,
                                                                                                food_item.detail_display.state,
                                                                                                food_item.consumable_type ? food_item.consumable_type : "")
                                                                                        }}>
                                                                                        <span className="ab_position">{window.localStorage.getItem('journal_type') == "CALORIE_TRACKER" || props.all_meals_cheat_meal_status == true ||
                                                                                            option.flex_meal_options != null && option.flex_meal_options.length > 2 && option?.flex_meal_options[2]?.name == "CHEAT_MEAL_1" && option?.flex_meal_options[2].selected == true ||
                                                                                            option.flex_meal_options !== null && option.flex_meal_options[0].name == 'CHEAT_MEAL_1' && option.flex_meal_options[0].selected == true
                                                                                            ? food_item.calories : ""}</span>
                                                                                        <img alt="" className="cursor_pointer" src={food_item.detail_display.url} width="48" height="48" />
                                                                                    </div>
                                                                                    <div className={`pull-left item_search ${archive_icon}`} meal_occasion_id={meal_occasion_id} dh_id={id} food_categories={food_categories} meal_occasion_name={option.meal_occasion_name} consumable_id={consumable_id} consumable_type={consumable_type} onClick={() => {
                                                                                        if (archive_icon === "plus_icon") {
                                                                                            window.localStorage.setItem('autoscroll', 'true');
                                                                                            window.localStorage.setItem('scroll_id', window.pageYOffset);
                                                                                            window.localStorage.setItem('meal_occasion_id', option.meal_occasion_id);
                                                                                            window.localStorage.setItem('meal_occasion_name', option.meal_occasion_name);
                                                                                            if (food_item.default_meal_occasion_id) {
                                                                                                window.localStorage.setItem('default_meal_occasion_id', food_item.default_meal_occasion_id);
                                                                                            } else {
                                                                                                window.localStorage.setItem('default_meal_occasion_id', null);
                                                                                            }
                                                                                            window.localStorage.setItem('meal_occasion', option.meal_occasion_name);
                                                                                            localStorage.removeItem('food_type');
                                                                                            window.localStorage.setItem('log_food_search', "true");
                                                                                            if (food_categories !== "CALORIE") {
                                                                                                localStorage.removeItem('searched_term');
                                                                                                window.localStorage.setItem('food_categories', food_categories);
                                                                                            }
                                                                                            else {
                                                                                                localStorage.removeItem('food_categories');
                                                                                                window.localStorage.setItem('from_all_foods', true);
                                                                                            }
                                                                                            if (window.localStorage.getItem("food_categories") === "NS_ENTREE" && (window.localStorage.getItem('meal_occasion') === "BREAKFAST" || window.localStorage.getItem('meal_occasion') === "LUNCH" || window.localStorage.getItem('meal_occasion') === "DINNER")) {
                                                                                                window.localStorage.setItem('food_type', 'nutrisystem');
                                                                                            }
                                                                                            window.localStorage.setItem('log_food_search', "true");
                                                                                            window.localStorage.setItem('from_addintional_log', "false");
                                                                                            window.localStorage.setItem('autoscroll', 'true');
                                                                                            window.localStorage.setItem('scroll_id', window.pageYOffset);
                                                                                            history.push({ pathname: "/log_food" });
                                                                                        }
                                                                                        else if (archive_icon == 'caret_icon') {
                                                                                            window.localStorage.setItem('dh_id', food_item.id);
                                                                                            window.localStorage.setItem('consumable_id', food_item.consumable_id);
                                                                                            window.localStorage.setItem('consumable_type', food_item.consumable_type);
                                                                                            window.localStorage.setItem('meal_occasion_id', option.meal_occasion_id);
                                                                                            localStorage.removeItem('food_categories');
                                                                                            if (food_item.default_meal_occasion_id) {
                                                                                                window.localStorage.setItem('default_meal_occasion_id', food_item.default_meal_occasion_id);
                                                                                            } else {
                                                                                                window.localStorage.setItem('default_meal_occasion_id', null);
                                                                                            }
                                                                                            window.localStorage.setItem('state', food_item.detail_display.state);

                                                                                            if (detail_display?.food_category == "MULTI_SERVINGS" || detail_display?.food_category == "MULTI_CATEGORY") {
                                                                                                window.localStorage.setItem('singleLog', "false")
                                                                                            }
                                                                                            else {
                                                                                                window.localStorage.setItem('singleLog', "true")
                                                                                            }
                                                                                            window.localStorage.setItem('scroll_id', window.pageYOffset);
                                                                                            window.localStorage.setItem('autoscroll', 'true');
                                                                                            history.push('/edit_food');
                                                                                        } else {
                                                                                            if (food_item.default_meal_occasion_id) {
                                                                                                window.localStorage.setItem('default_meal_occasion_id', food_item.default_meal_occasion_id);
                                                                                            } else {
                                                                                                window.localStorage.setItem('default_meal_occasion_id', null);
                                                                                            }
                                                                                        }
                                                                                    }}>
                                                                                        <div className="font12 calorie_count_small" id={index}>{custom_food}<span className="red">{over_plan}</span></div><div className="calorie_item font16" onClick={() => {
                                                                                            window.localStorage.setItem('autoscroll', 'true');
                                                                                            window.localStorage.setItem('scroll_id', window.pageYOffset);
                                                                                            window.localStorage.setItem('meal_occasion_id', option.meal_occasion_id);
                                                                                            window.localStorage.setItem('meal_occasion_name', option.meal_occasion_name);
                                                                                            localStorage.removeItem('food_categories');
                                                                                            window.localStorage.setItem('quicklog_id', food_item.consumable_id === undefined ? "" : food_item.consumable_id)
                                                                                            console.log(food_item.consumable_id);
                                                                                            localStorage.removeItem('food_type');
                                                                                            window.localStorage.setItem('log_food_search', "true");
                                                                                            window.localStorage.setItem('from_plus', "true");
                                                                                            window.localStorage.setItem('from_addintional_log', "false");
                                                                                            history.push({ pathname: "/log_food" });
                                                                                        }}><div>{display_name}</div>
                                                                                            <span>{food_item && food_item.best_for_you === true ? <img alt="" src="/assets/images/best_for.png" style="margin-right:10px;" /> : <></>}</span>

                                                                                            <div className="pill_images display_block">
                                                                                                {

                                                                                                    detail_display_multi.length !== 0 ?
                                                                                                        detail_display_multi?.map((display) => {
                                                                                                            return (
                                                                                                                display.url != null ?
                                                                                                                    <img alt="" src={display.url}></img> : ""
                                                                                                            )
                                                                                                        })
                                                                                                        :
                                                                                                        null
                                                                                                }
                                                                                                {calories}
                                                                                            </div>
                                                                                        </div>

                                                                                        <span className="pill_images display_block">{food_item.moved_from ? food_item.moved_from : ""}</span>


                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </Draggable>

                                                                )

                                                            })
                                                        }
                                                        {provider.placeholder}
                                                        {props.journalResponse_type === "CALORIE_TRACKER" || props.all_meals_cheat_meal_status == true ||
                                                            option.flex_meal_options != null && option.flex_meal_options.length > 2 && option?.flex_meal_options[2]?.name == "CHEAT_MEAL_1" && option?.flex_meal_options[2].selected == true || option.flex_meal_options !== null && option.flex_meal_options[0].name == 'CHEAT_MEAL_1' && option.flex_meal_options[0].selected == true ?

                                                            <div className="full_width inline_block meal_data_log log_calories 1" style={{ display: option.flex_meal_options.length > 2 && option?.flex_meal_options[2]?.name == "CHEAT_MEAL_1" && option?.flex_meal_options[2].selected == true || option.flex_meal_options !== null && option.flex_meal_options[0].name == 'CHEAT_MEAL_1' && option.flex_meal_options[0].selected == true ? 'block' : 'none' }} id={option.meal_occasion_id}>
                                                                <span onClick={() => {
                                                                    setLogCaloriePopup(true)
                                                                    setCurrMealOcsssionId(option.meal_occasion_id);
                                                                }}>
                                                                    <CalorieComp />
                                                                </span>
                                                                <span className="font15 meal_item_lft">{window.keystrings['com.numi.journal.log_calories']}</span>
                                                                <div className='new_plus_icon search_foods d-none' name={option.meal_occasion_name} id={option.meal_occasion_id}></div>



                                                            </div>
                                                            : ""}
                                                    </div>

                                                    <div className="full_width inline_block meal_data_log log_calories 2" style={{ display: (calorie_tracker ? 'block' : 'none') }} id={option.meal_occasion_id}>
                                                        <span onClick={() => {
                                                            setLogCaloriePopup(true)
                                                            setCurrMealOcsssionId(option.meal_occasion_id);
                                                        }}>
                                                            <CalorieComp />
                                                        </span>
                                                        {/* <span className="font15 meal_item_lft">{window.keystrings['com.numi.journal.log_calories']}</span> */}
                                                        <div className={`new_plus_icon search_foods ${window.localStorage.getItem('journal_type') === "CALORIE_TRACKER" || props.all_meals_cheat_meal_status == true ||
                                                            option.flex_meal_options != null && option.flex_meal_options.length > 2 && option?.flex_meal_options[2]?.name == "CHEAT_MEAL_1" && option?.flex_meal_options[2].selected == true || option.flex_meal_options !== null && option.flex_meal_options[0].name == 'CHEAT_MEAL_1' && option.flex_meal_options[0].selected == true ? "d-none" : ""}`} name={option.meal_occasion_name} id={option.meal_occasion_id}></div>
                                                    </div>

                                                    <div className="full_width inline_block meal_data_log log_calories 3" style={{ display: (props.displayalltreatdayption || window.localStorage.getItem('journal_type') === "CALORIE_TRACKER" ? 'block' : 'none') }} id={option.meal_occasion_id}>
                                                        <span onClick={() => {
                                                            setLogCaloriePopup(true)
                                                            setCurrMealOcsssionId(option.meal_occasion_id);
                                                        }}>
                                                            <CalorieComp />
                                                        </span><span className="font15 meal_item_lft">{window.keystrings['com.numi.journal.log_calories']}</span>
                                                    </div>

                                                    <div className="full_width inline_block meal_data_log dropdown log_food" id={i}>
                                                        <ul className="dropdown-menu journal_options journal_empty_options">
                                                            <li><span className="search_foods" id={option.meal_occasion_id} name={option.meal_occasion_name} onClick={(e) => {
                                                                onLogAdditionalFood(e)
                                                            }}>{window.keystrings['com.numi.journal.log_additional_foods']}</span></li>

                                                            <li
                                                                onClick={() => copyYesterdayMeal(option.meal_occasion_name)}
                                                            ><span className="log_yesterdays" name={option.meal_occasion_name}>{window.keystrings['com.numi.journal.log_yesterdays']}<span className="selected_txt"><p className="capitalize_text">{option.meal_occasion_display_name || option.meal_occasion_name}</p></span></span></li>
                                                            <li className="food_last_li"

                                                                style={{ display: ((localStorage.getItem('journal_type') === "CALORIE_TRACKER" && option.food_details.length === 0) || (localStorage.getItem('journal_type') !== "CALORIE_TRACKER" && option.summaries.map(function (a) { return a.consumed; }).reduce((a, b) => a + b, 0) === 0)) ? 'none' : 'block' }} onClick={() => {
                                                                    // Header.changeDate(parse(startDate));
                                                                    setCopyModal(true)

                                                                    setStartDate(addDays(window.localStorage.getItem('assigned_date'), 1))
                                                                    setDate(addDays(window.localStorage.getItem('assigned_date'), 1))
                                                                    setOccassionName(option.meal_occasion_name)
                                                                    window.localStorage.setItem("meal_occasion_name", option.meal_occasion_name)
                                                                    window.localStorage.setItem('meal_occassion_name_copy_food', option.meal_occasion_name)
                                                                    window.localStorage.setItem('meal_occasion_id', option.meal_occasion_id)
                                                                }}><span className="not_copy" data-target="#copy_food" data-toggle="modal" id={option.meal_occasion_id} name={option.meal_occasion_name} data-display_name={option.meal_occasion_display_name}>Copy this <p className="capitalize_text">{option.meal_occasion_display_name || option.meal_occasion_name}</p></span></li>
                                                        </ul>
                                                        <img alt="" src="/assets/images/plus_icon.png" className="plus_align cursor_pointer" data-toggle="dropdown" />
                                                        <span className="font15 meal_item_lft" data-toggle="dropdown"><span className="log_food_text" id={option.meal_occasion_id} name={option.meal_occasion_name} >{window.keystrings['com.numi.more_title']}</span></span>
                                                    </div>
                                                </div>
                                            </div>




                                        </div>
                                    </div>
                                )}
                            </Droppable>
                        )
                    })
                }
            </div>
            <Modal show={revertFromMealOccasionPopup} id="revertFromMealOccasionPopup" onHide={() => { onHideRevertFromMealOccasionPopup() }}>
                <Modal.Header>
                    <button type="button" className="close" onClick={() => { setRevertFromMealOccasionPopup(false) }}>
                        <img alt="" src="/assets/images/close_grey.png" />
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <img alt="" src="/assets/images/warning_tri.png" className="water_glass mt0" style={{ width: '48px', height: '48px', flexShrink: '0' }} />
                    <p className="font26 color3 mt5 font700 change_hdr change_plan_title_text">{window.keystrings['com.numi.drag_drop_flex_heads_up']}</p>

                    <p className="mt change_txt change_plan_message_text">{window.keystrings['com.numi.drag_drop_flex_popup_message']}</p>
                    <center><button type="button" data-dismiss="modal" style={{ width: '254px', height: '45px', marginTop: '15px' }} onClick={() => { onRevertMealOccassion() }}>Continue</button></center>
                    <center><button type="button" data-dismiss="modal" style={{ width: '254px', height: '45px', marginTop: '15px' }} onClick={() => { setRevertFromMealOccasionPopup(false) }}>Close</button></center>
                </Modal.Body>
            </Modal>

            <Modal show={copyNotValidModal} id="copy_not_valid_food" onHide={() => { onHideCopyNotValidModal() }}>
                <Modal.Header>
                    <button type="button" className="close" onClick={() => { setCopyNotValidModal(false) }}>
                        <img alt="" src="/assets/images/close_grey.png" />
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="copy_food_not_valid">
                        <img alt="" src="/assets/images/warning_tri.png" />

                        <p className="font26 color3 mt5 font700">NuMi</p>
                        <p className="font16 color6 copy_not_valid_error text-center font16">{copyNotValidText}</p>
                        <p><button onClick={() => { setCopyNotValidModal(false) }}>OK</button></p>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal onEntered={() => setDate(startDate)} show={copyModal} id="copy_food" onHide={() => { onHideCopyModal() }}>
                <Modal.Header>
                    <button type="button" className="close" onClick={() => { setCopyModal(false) }}>
                        <img alt="" src="/assets/images/close_grey.png" />
                    </button>
                </Modal.Header>
                <Modal.Body>
                    {foodError}
                    <div className="copy_food_valid">
                        <h3 className="modal-title">{window.keystrings['com.numi.journal.copy_meal']}</h3>
                        <p className="font15">Your <span className="capitalize_text">{occasionName}</span> from <b>{moment(window.localStorage.getItem('assigned_date')).format('MMM DD')}</b> will be copied to <b className="copy_to_date">{moment(startDate).format('MMM DD')}</b></p>
                        <div className="relative date_pick">
                            <span className="left_sec font15">Date</span>
                            <DatePicker className="CopyFoodCalender" onChange={(date) => handleChange(date)} minDate={parse(window.localStorage.getItem('profile_start_date'))} maxDate={addDays(new Date(), 90)} selected={startDate} />
                        </div>
                        <div className="relative date_pick">
                            <span className="left_sec font15">Meal</span>
                            <MealDropdown />
                        </div>
                        <p className="error copy_error font14">{copyError}</p>
                        <input type="submit" className="copy_meal copy_meal_food mt6" value="Copy Meal" onClick={() => copyMeal()} />
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={logCaloriePopup} id="calorie_modal" onHide={() => { onHideLogCaloriePopup() }}>
                <Modal.Header>
                    <button type="button" className="close" onClick={() => { setLogCaloriePopup(false) }}>
                        <img alt="" src="/assets/images/close_grey.png" />
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <h3 className="modal-title">Log Calories</h3>
                    </div>
                    <span className="font16">Calories</span>
                    <span className=""><input type="text" className="plft font15 calorie_input" value={logInputValue} onChange={(e) => {
                        setLogInputValue(e.target.value)
                    }} /></span>
                    <p>
                        <button type="button" className="mt6 log_calories_save" onClick={() => { onSaveLogFoodCategory() }}>
                            Save
                        </button>
                    </p>
                </Modal.Body>
            </Modal>


            <Modal show={resetLogFoodPopup} id="unlog_activity" onHide={() => { onHideResetLogFoodPopup() }}>
                <Modal.Header>
                    <button type="button" className="close" onClick={() => { setResetLogFoodPopup(false) }}>
                        <img alt="" src="/assets/images/close_grey.png" />
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <img alt="" src="/assets/images/warning_tri.png" />
                    <p className="font26 color3 mt5 font700 ">{window.keystrings['com.numi.journal.unlog_food']}</p>
                    <p className="font16 color6 ">{window.keystrings['com.numi.journal.unlog_food_body']}</p>
                    <p><button className="delete_acitivty" onClick={() => {
                        const options = {
                            url: window.API_URL + `v1/diet_histories/${currResetQuickLogId}`,
                            method: 'DELETE',
                            credentials: 'same-origin',
                            headers: Header.setAuthHeaders(),
                        };

                        axios(options)
                            .then(response => {

                                props.getJournal();
                                setResetLogFoodPopup(false);

                            })
                            .catch(error => {
                                Sentry.captureException(error);
                                setResetLogFoodPopup(false);
                            });
                    }}>Yes</button><button onClick={() => { setResetLogFoodPopup(false) }}>Cancel</button></p>
                </Modal.Body>
            </Modal>

            <Modal show={defaultMealPopup} id="manage_meals" onHide={() => { onHideDefaultMealPopup() }}>
                <Modal.Header>
                    <button type="button" className="close" onClick={() => { setDefaultPopup(false) }}>
                        <img alt="" src="/assets/images/close_grey.png" />
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-body  padding0">

                        <p className="font26 color3 mt5 font700">{window.keystrings['com.numi.journal.manage_meals']}</p>
                        <h6 className="mt color6 choose_category">{window.keystrings['com.numi.journal.choose_meal_plan']}<b className='gtm_meal_change'>{occasionName}</b>.</h6>
                        <ul className="flex_meal_list mt6">
                            <li className="width100 display_block">
                                <span className="pull-left flex_span width50 display_block">
                                    <span className="font16 color6 flex_meal_txt">{window.keystrings['com.numi.journal.flex_meal_off']}</span>
                                </span>
                                <span className="pull-right">
                                    <div className="radio_select mt-10 width50 display_block">
                                        <input type="radio" className="checbox_normal" value="Flex Meal: Off" id="multi_check1" name="food_item" checked />
                                        <label htmlFor="multi_check1" className="mt">
                                            <span><i></i></span>
                                        </label>
                                    </div>
                                </span>
                            </li>
                            <li className="width100 display_block">
                                <span className="pull-left flex_span width50 display_block">
                                    <span className="font16 color6">{window.keystrings['com.numi.journal.flex_meal_on']}</span>
                                </span>
                                <span className="pull-right">
                                    <div className="radio_select mt-10 width50 display_block">
                                        <input type="radio" className="checbox_normal" value="Flex Meal: On" id="multi_check2" name="food_item" />
                                        <label htmlFor="multi_check2">
                                            <span><i></i></span>
                                        </label>
                                    </div>
                                </span>
                            </li>
                        </ul>
                        <p className="ptb3 save_manage_meals pointer"><button className="margin0 btn" onClick={() => saveManageMeal()}>{window.keystrings['com.numi.create_food_save']}</button></p>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default MealOccasionSection;