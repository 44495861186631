import React from 'react';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import Header from "../header.js";
import $ from 'jquery';
import axios from 'axios';
import * as Sentry from "@sentry/react";

class SmartWeekCheatDay extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            noCheatDay: false,
            oneCheatDay: false,
            twoCheatDays: false,
            smartWeekTypeSelected: "",
            smartWeekCheatDaysSelected: "",
            cheatDays: 0,
            cheatDaysCalData: [],
            plansettings: this.props.from,
            cheatDaysSelected: [],
            smartWeekPlanSetting: "",
            openStep: this.props.openStep,
            statndardWeekType: ""
        }
    }
    componentDidMount() {
        if ((document.URL.indexOf('/ns_plan') < 0)) {
            const cheatDaysCal = {
                url: window.API_URL + "v1/nutrisystem/smart_week",
                method: 'GET',
                credentials: 'same-origin',
                headers: Header.setAuthHeaders(),
            };
            axios(cheatDaysCal)
                .then(response => {
                    if (response.data.smart_week.show_smart_week === true) {
                        this.getSmartWeekType()
                    }
                    this.setState({
                        cheatDaysSelected: response.data.smart_week.cheat_days,
                        smartWeekPlanSetting: response.data.smart_week.smartweek_type,
                        cheatDays: response.data.smart_week.allowed_number_of_cheat_days
                    })

                    if (response && response.data) {
                        if (response.data.smart_week.smartweek_type === 'steady') {
                            this.setState({
                                noCheatDay: true,
                                oneCheatDay: false,
                                twoCheatDays: false,
                                smartWeekTypeSelected: "steady",
                            });
                        } else if (response.data.smart_week.smartweek_type === '1cd') {
                            this.setState({
                                noCheatDay: false,
                                oneCheatDay: true,
                                twoCheatDays: false,
                                smartWeekTypeSelected: "1cd",
                            });
                        } if (response.data.smart_week.smartweek_type === '2cd') {
                            this.setState({
                                noCheatDay: false,
                                oneCheatDay: false,
                                twoCheatDays: true,
                                smartWeekTypeSelected: "2cd",
                            });
                        }
                        if (this.state.smartWeekPlanSetting != "steady" && this.state.smartWeekPlanSetting != null) {
                            if (response.data.smart_week.cheat_days != null) {

                                $(".week-cheat-day-card").removeClass("selected");
                                if (response.data.smart_week.cheat_days.includes("Sunday")) {
                                    $('#week-cheat-day-card-sunday').addClass("selected");
                                } if (response.data.smart_week.cheat_days.includes("Monday")) {
                                    $('#week-cheat-day-card-monday').addClass("selected");
                                } if (response.data.smart_week.cheat_days.includes("Tuesday")) {
                                    $('#week-cheat-day-card-tuesday').addClass("selected");
                                } if (response.data.smart_week.cheat_days.includes("Wednesday")) {
                                    $('#week-cheat-day-card-wednesday').addClass("selected");
                                } if (response.data.smart_week.cheat_days.includes("Thursday")) {
                                    $('#week-cheat-day-card-thursday').addClass("selected");
                                } if (response.data.smart_week.cheat_days.includes("Friday")) {
                                    $('#week-cheat-day-card-friday').addClass("selected");
                                } if (response.data.smart_week.cheat_days.includes("Saturday")) {
                                    $('#week-cheat-day-card-saturday').addClass("selected");
                                }
                            }

                            $(".week-cheat-day-card").off('click').on('click', function () {
                                var cheatdays = response.data.smart_week.allowed_number_of_cheat_days;
                                if ((cheatdays) <= $('#step2 .selected').length) {
                                    $(this).removeClass("selected");
                                    if ($(document.getElementsByClassName("week-cheat-day-card")).hasClass("selected")) {
                                        $(document.getElementById("smartWeekCheatDaySubmitBtn")).removeClass("option_disabled");
                                        $(document.getElementById("smartWeekCheatDaySaveBtn")).removeClass("option_disabled");
                                        $(document.getElementsByClassName("week-cheat-day-card")).removeClass("option_disabled");
                                    } else {
                                        if ((cheatdays) <= $('#step2 .selected').length) {
                                            $(document.getElementsByClassName("week-cheat-day-card")).addClass("option_disabled");
                                        }
                                    }
                                } else {
                                    $(this).toggleClass("selected");
                                    if ((cheatdays) >= $('#step2 .selected').length) {
                                        if ($(document.getElementsByClassName("week-cheat-day-card")).hasClass("selected")) {

                                            $(document.getElementsByClassName("week-cheat-day-card")).removeClass("option_disabled");
                                        } else {
                                            if ((cheatdays) <= $('#step2 .selected').length) {
                                                $(document.getElementsByClassName("week-cheat-day-card")).addClass("option_disabled");
                                            }
                                        }
                                    } else {
                                        $(document.getElementsByClassName("week-cheat-day-card")).removeClass("option_disabled");
                                    }

                                }

                                if ((cheatdays) == $('#step2 .selected').length) {
                                    $(document.getElementById("smartWeekCheatDaySubmitBtn")).removeClass("option_disabled");
                                    $(document.getElementById("smartWeekCheatDaySaveBtn")).removeClass("option_disabled");
                                } else {
                                    $(document.getElementById("smartWeekCheatDaySubmitBtn")).addClass("option_disabled");
                                    $(document.getElementById("smartWeekCheatDaySaveBtn")).addClass("option_disabled");
                                }
                            });
                        }
                    }
                })
                .catch(error => {
                    Header.stopLoader(); // stop loader
                    $('.progress_error').text('');
                    if (error && error.response && error.response.data && error.response.data.error) {
                        $('.progress_error').text(error.response.data.error);
                    }
                    else if (error && error.response && error.response.status > 400) {
                        $('.progress_error').text(error.response.statusText);
                    }
                    else {
                        alert(error.message ? error.message : 'Something went wrong!');
                    }
                    Sentry.captureException(error);
                });
        } else {
            $("newCalGoal").hide();
            $("#step0").show();
            $("#step1").hide();
            $("#step2").hide();
            $("#step3").hide();
        }


        if (localStorage.getItem("gender") === "male") {
            /*keystrings*/
            $('.cd_plan_your_week_txt').text(window.keystrings['com.numi.cd.male_plan_your_week']);
            $('.cd_plan_your_week_desc_txt').text(window.keystrings['com.numi.cd.male_plan_your_week_desc']);
            $('.cd_plan_your_week_settings_txt').text(window.keystrings['com.numi.cd.male_settings_can_be_changed']);
            $('.cd_all_set_txt').text(window.keystrings['com.numi.cd.all_set_male_title']);
            $('.cd_all_set_desc_txt').html(window.keystrings['com.numi.cd.all_set_male_standard_desc']);
            $('.cd_all_set_male_one_smartday_desc_txt').html(window.keystrings['com.numi.cd.all_set_male_one_smartday_desc']);
            $('.cd_all_set_male_two_smartday_desc_txt').html(window.keystrings['com.numi.cd.all_set_male_two_smartday_desc']);
            $('.cd_smartweek_intro_txt').text(window.keystrings['com.numi.smartweek_male_intro']);
            $('.cd_smartweek_intro_desc_txt').html(window.keystrings['com.numi.smartweek_male_intro_desc']);
            $('.cd_smartweek_male_intro_quicktip_txt').html(window.keystrings['com.numi.smartweek_male_intro_quicktip']);
            $('.cd_smartweek_header_txt').text(window.keystrings['com.numi.smartweek_male_header']);
            $('.cd_steady_week_type_title_txt').text(window.keystrings['com.numi.cd.male_steady_week_type_title']);
            $('.cd_smartweek_choose_your_week_txt').text(window.keystrings['com.numi.cd.male_steady_week_type_header']);
            $('.cd_steady_title_txt').text(window.keystrings['com.numi.cd.male_steady_title']);
            $('.cd_steady_type1_title_txt').text(window.keystrings['com.numi.cd.male_steady_type1']);
            $('.cd_one_cheat_day_title_txt').text(window.keystrings['com.numi.cd.male_one_cheat_day_title']);
            $('.cd_one_cheat_type1_day_title_txt').text(window.keystrings['com.numi.cd.male_one_cheat_day_type1']);
            $('.cd_one_cheat_type2_day_title_txt').text(window.keystrings['com.numi.cd.male_one_cheat_day_type2']);
            $('.cd_two_cheat_day_title_txt').text(window.keystrings['com.numi.cd.male_two_cheat_days_title']);
            $('.cd_two_cheat_type1_day_title_txt').text(window.keystrings['com.numi.cd.male_two_cheat_days_type1']);
            $('.cd_two_cheat_type2_day_title_txt').text(window.keystrings['com.numi.cd.male_two_cheat_days_title']);
            $('.cd_all_set_no_cheat_day_txt').text(window.keystrings['com.numi.cd.all_set.male_no_cheat_day']);
            $('.cd_all_set_one_cheat_day_txt').text(window.keystrings['com.numi.cd.all_set.male_one_cheat_day']);
            $('.cd_all_set_two_cheat_day_txt').text(window.keystrings['com.numi.cd.all_set.male_two_cheat_days']);
            $('.cd_smartweek_header_newCalorieGoal_txt').text(window.keystrings['com.numi.smartweek.male_new_calorie_goal']);
            $('.cd_smartweek_intro_desc_newCalorieGoal_txt').text(window.keystrings['com.numi.cd.male.new_calorie_goal_desc']);
            $('.cd_smartweek_intro_desc_newCalorieGoal_title_txt').text(window.keystrings['com.numi.cd.male.new_calorie_goal_title']);

            $('.cd_smartweek_male_no_cheat_day_txt').text(window.keystrings['com.numi.cd.all_set.male_no_cheat_day']);
            $('.cd_smartweek_male_one_cheat_day_txt').text(window.keystrings['com.numi.cd.all_set.male_one_cheat_day']);
            $('.cd_smartweek_male_two_cheat_day_txt').text(window.keystrings['com.numi.cd.all_set.male_two_cheat_days']);

            /*keystrings*/
        } else {
            /*keystrings*/
            $('.cd_plan_your_week_txt').text(window.keystrings['com.numi.cd.female_plan_your_week']);
            $('.cd_plan_your_week_desc_txt').text(window.keystrings['com.numi.cd.female_plan_your_week_desc']);
            $('.cd_plan_your_week_settings_txt').text(window.keystrings['com.numi.cd.female_settings_can_be_changed']);
            $('.cd_all_set_txt').text(window.keystrings['com.numi.cd.all_set_female_title']);
            $('.cd_all_set_desc_txt').html(window.keystrings['com.numi.cd.all_set_female_standard_desc']);
            $('.cd_all_set_female_one_smartday_desc_txt').html(window.keystrings['com.numi.cd.all_set_female_one_smartday_desc']);
            $('.cd_all_set_female_two_smartday_desc_txt').html(window.keystrings['com.numi.cd.all_set_female_two_smartday_desc']);
            $('.cd_smartweek_intro_txt').text(window.keystrings['com.numi.smartweek_female_intro']);
            $('.cd_smartweek_intro_desc_txt').html(window.keystrings['com.numi.smartweek_female_intro_desc']);
            $('.cd_smartweek_male_intro_quicktip_txt').html(window.keystrings['com.numi.smartweek_female_intro_quicktip']);
            $('.cd_smartweek_header_txt').text(window.keystrings['com.numi.smartweek_female_header']);
            $('.cd_steady_week_type_title_txt').text(window.keystrings['com.numi.cd.female_steady_week_type_title']);
            $('.cd_smartweek_choose_your_week_txt').text(window.keystrings['com.numi.cd.female_steady_week_type_header']);
            $('.cd_steady_title_txt').text(window.keystrings['com.numi.cd.female_steady_title']);
            $('.cd_steady_type1_title_txt').text(window.keystrings['com.numi.cd.female_steady_type1']);
            $('.cd_one_cheat_day_title_txt').text(window.keystrings['com.numi.cd.female_one_break_day_title']);
            $('.cd_one_cheat_type1_day_title_txt').text(window.keystrings['com.numi.cd.female_one_break_day_type1']);
            $('.cd_one_cheat_type2_day_title_txt').text(window.keystrings['com.numi.cd.female_one_break_day_type2']);
            $('.cd_two_cheat_day_title_txt').text(window.keystrings['com.numi.cd.female_two_break_days_title']);
            $('.cd_two_cheat_type1_day_title_txt').text(window.keystrings['com.numi.cd.female_two_break_days_type1']);
            $('.cd_two_cheat_type2_day_title_txt').text(window.keystrings['com.numi.cd.female_two_break_days_type2']);
            $('.cd_all_set_no_cheat_day_txt').text(window.keystrings['com.numi.cd.all_set.female_no_break_day']);
            $('.cd_all_set_one_cheat_day_txt').text(window.keystrings['com.numi.cd.all_set.female_one_break_day']);
            $('.cd_all_set_two_cheat_day_txt').text(window.keystrings['com.numi.cd.all_set_female_two_break_day_desc']);
            $('.cd_smartweek_header_newCalorieGoal_txt').text(window.keystrings['com.numi.smartweek.female_new_calorie_goal']);
            $('.cd_smartweek_intro_desc_newCalorieGoal_txt').text(window.keystrings['com.numi.cd.male.new_calorie_goal_desc']);
            $('.cd_smartweek_intro_desc_newCalorieGoal_title_txt').text(window.keystrings['com.numi.cd.male.new_calorie_goal_title']);

            $('.cd_smartweek_female_no_cheat_day_txt').text(window.keystrings['com.numi.cd.all_set.female_no_break_day']);
            $('.cd_smartweek_female_one_cheat_day_txt').text(window.keystrings['com.numi.cd.all_set.female_one_break_day']);
            $('.cd_smartweek_female_two_cheat_day_txt').text(window.keystrings['com.numi.cd.all_set.female_two_break_days']);
            /*keystrings*/
        }
    }

    setCheatDaysKey(smartWeekTypeSelected) {
        if (localStorage.getItem("gender") === "male") {
            /*keystrings*/

            if ((smartWeekTypeSelected === 'steady' ? 0 : smartWeekTypeSelected === '1cd' ? 1 : 2) === 1) {
                $('.cd_week_type_title_txt').text(window.keystrings['com.numi.cd.male_one_day_week_type_title']);

            } else {
                $('.cd_week_type_title_txt').text(window.keystrings['com.numi.cd.male_two_day_week_type_title']);
            }

            /*keystrings*/
        } else {
            /*keystrings*/

            if ((smartWeekTypeSelected === 'steady' ? 0 : smartWeekTypeSelected === '1cd' ? 1 : 2) === 1) {
                $('.cd_week_type_title_txt').text(window.keystrings['com.numi.cd.female_one_day_week_type_title']);
            } else {
                $('.cd_week_type_title_txt').text(window.keystrings['com.numi.cd.female_two_day_week_type_title']);
            }
            /*keystrings*/
        }
    }

    getSmartWeekType() {
        const cheatDaysCal = {
            url: window.API_URL + "v1/nutrisystem/smart_week_type",
            method: 'GET',
            credentials: 'same-origin',
            headers: Header.setAuthHeaders(),
        };

        axios(cheatDaysCal)
            .then(response => {
                this.setState({ cheatDaysCalData: response.data.smart_week_types })
            })
            .catch(error => {
                Header.stopLoader(); // stop loader
                $('.progress_error').text('');
                if (error && error.response && error.response.data && error.response.data.error) {
                    $('.progress_error').text(error.response.data.error);
                }
                else if (error && error.response && error.response.status > 400) {
                    $('.progress_error').text(error.response.statusText);
                }
                else {
                    alert(error.message ? error.message : 'Something went wrong!');
                }
                Sentry.captureException(error);
            });
    }

    smartWeekIntroSubmit() {
        Header.stopLoader(); // stop loader
        $("#newCalGoal").hide();
        $("#step0").hide();
        $("#step1").show();
        $("#step2").hide();
        $("#step3").hide();
        this.getSmartWeekType()

    }


    smartWeekTypeSubmit(smartWeekTypeSelected) {
        var smart_week_type_data = { "smart_week": { "smart_week_type": smartWeekTypeSelected } };
        this.callCheatDaysAPI((smartWeekTypeSelected))

        var smartWeekValue = 0;
        if (smartWeekTypeSelected === "steady") {
            smartWeekValue = 0
        }
        else if (smartWeekTypeSelected === '1cd') {
            smartWeekValue = 1
        }
        else {
            smartWeekValue = 2

        }
        this.setState({
            cheatDays: smartWeekValue
        })

        if (localStorage.getItem("gender") === "male") {
            /*keystrings*/
            $('.cd_plan_your_week_txt').text(window.keystrings['com.numi.cd.male_plan_your_week']);
            $('.cd_plan_your_week_desc_txt').text(window.keystrings['com.numi.cd.male_plan_your_week_desc']);
            $('.cd_plan_your_week_settings_txt').text(window.keystrings['com.numi.cd.male_settings_can_be_changed']);
            $('.cd_all_set_txt').text(window.keystrings['com.numi.cd.all_set_male_title']);
            $('.cd_all_set_desc_txt').html(window.keystrings['com.numi.cd.all_set_male_standard_desc']);
            $('.cd_all_set_male_one_smartday_desc_txt').html(window.keystrings['com.numi.cd.all_set_male_one_smartday_desc']);
            $('.cd_all_set_male_two_smartday_desc_txt').html(window.keystrings['com.numi.cd.all_set_male_two_smartday_desc']);
            $('.cd_smartweek_intro_txt').text(window.keystrings['com.numi.smartweek_male_intro']);
            $('.cd_smartweek_intro_desc_txt').html(window.keystrings['com.numi.smartweek_male_intro_desc']);
            $('.cd_smartweek_male_intro_quicktip_txt').html(window.keystrings['com.numi.smartweek_male_intro_quicktip']);
            $('.cd_smartweek_header_txt').text(window.keystrings['com.numi.smartweek_male_header']);
            $('.cd_steady_week_type_title_txt').text(window.keystrings['com.numi.cd.male_steady_week_type_title']);
            $('.cd_smartweek_choose_your_week_txt').text(window.keystrings['com.numi.cd.male_steady_week_type_header']);
            $('.cd_steady_title_txt').text(window.keystrings['com.numi.cd.male_steady_title']);
            $('.cd_steady_type1_title_txt').text(window.keystrings['com.numi.cd.male_steady_type1']);
            $('.cd_one_cheat_day_title_txt').text(window.keystrings['com.numi.cd.male_one_cheat_day_title']);
            $('.cd_one_cheat_type1_day_title_txt').text(window.keystrings['com.numi.cd.male_one_cheat_day_type1']);
            $('.cd_one_cheat_type2_day_title_txt').text(window.keystrings['com.numi.cd.male_one_cheat_day_type2']);
            $('.cd_two_cheat_day_title_txt').text(window.keystrings['com.numi.cd.male_two_cheat_days_title']);
            $('.cd_two_cheat_type1_day_title_txt').text(window.keystrings['com.numi.cd.male_two_cheat_days_type1']);
            $('.cd_two_day_week_type_title_txt').text(window.keystrings['com.numi.cd.male_two_day_week_type_title']);
            $('.cd_all_set_no_cheat_day_txt').text(window.keystrings['com.numi.cd.all_set.male_no_cheat_day']);
            $('.cd_all_set_one_cheat_day_txt').text(window.keystrings['com.numi.cd.all_set.male_one_cheat_day']);
            $('.cd_all_set_two_cheat_day_txt').text(window.keystrings['com.numi.cd.all_set.male_two_cheat_days']);
            $('.cd_smartweek_header_newCalorieGoal_txt').text(window.keystrings['com.numi.smartweek.male_new_calorie_goal']);
            $('.cd_smartweek_intro_desc_newCalorieGoal_txt').text(window.keystrings['com.numi.smartweek.male_new_calorie_goal_desc']);
            $('.cd_smartweek_intro_desc_newCalorieGoal_title_txt').text(window.keystrings['com.numi.cd.male.new_calorie_goal_title']);
            $('.cd_smartweek_male_no_cheat_day_txt').text(window.keystrings['com.numi.cd.all_set.male_no_cheat_day']);
            $('.cd_smartweek_male_one_cheat_day_txt').text(window.keystrings['com.numi.cd.all_set.male_one_cheat_day']);
            $('.cd_smartweek_male_two_cheat_day_txt').text(window.keystrings['com.numi.cd.all_set.male_two_cheat_days']);

            if ((smartWeekTypeSelected === 'steady' ? 0 : smartWeekTypeSelected === '1cd' ? 1 : 2) === 0) {
                $('.cheatdayfinal').text(window.keystrings['com.numi.cd.all_set.male_no_cheat_day']);
            } else if ((smartWeekTypeSelected === 'steady' ? 0 : smartWeekTypeSelected === '1cd' ? 1 : 2) === 1) {
                $('.cheatdayfinal').text(window.keystrings['com.numi.cd.all_set.male_one_cheat_day']);
            } else {
                $('.cheatdayfinal').text(window.keystrings['com.numi.cd.all_set.male_two_cheat_days']);
            }


            /*keystrings*/
        } else {
            /*keystrings*/
            $('.cd_plan_your_week_txt').text(window.keystrings['com.numi.cd.female_plan_your_week']);
            $('.cd_plan_your_week_desc_txt').text(window.keystrings['com.numi.cd.female_plan_your_week_desc']);
            $('.cd_plan_your_week_settings_txt').text(window.keystrings['com.numi.cd.female_settings_can_be_changed']);
            $('.cd_all_set_txt').text(window.keystrings['com.numi.cd.all_set_female_title']);
            $('.cd_all_set_desc_txt').html(window.keystrings['com.numi.cd.all_set_female_standard_desc']);
            $('.cd_all_set_female_one_smartday_desc_txt').html(window.keystrings['com.numi.cd.all_set_female_one_smartday_desc']);
            $('.cd_all_set_female_two_smartday_desc_txt').html(window.keystrings['com.numi.cd.all_set_female_two_smartday_desc']);
            $('.cd_smartweek_intro_txt').text(window.keystrings['com.numi.smartweek_female_intro']);
            $('.cd_smartweek_intro_desc_txt').html(window.keystrings['com.numi.smartweek_female_intro_desc']);
            $('.cd_smartweek_male_intro_quicktip_txt').html(window.keystrings['com.numi.smartweek_female_intro_quicktip']);
            $('.cd_smartweek_header_txt').text(window.keystrings['com.numi.smartweek_female_header']);
            $('.cd_steady_week_type_title_txt').text(window.keystrings['com.numi.cd.female_steady_week_type_title']);
            $('.cd_smartweek_choose_your_week_txt').text(window.keystrings['com.numi.cd.female_steady_week_type_header']);
            $('.cd_steady_title_txt').text(window.keystrings['com.numi.cd.female_steady_title']);
            $('.cd_steady_type1_title_txt').text(window.keystrings['com.numi.cd.female_steady_type1']);
            $('.cd_one_cheat_day_title_txt').text(window.keystrings['com.numi.cd.female_one_break_day_title']);
            $('.cd_one_cheat_type1_day_title_txt').text(window.keystrings['com.numi.cd.female_one_break_day_type1']);
            $('.cd_one_cheat_type2_day_title_txt').text(window.keystrings['com.numi.cd.female_one_break_day_type2']);
            $('.cd_two_cheat_day_title_txt').text(window.keystrings['com.numi.cd.female_two_break_days_title']);
            $('.cd_two_cheat_type1_day_title_txt').text(window.keystrings['com.numi.cd.female_two_break_days_type1']);
            $('.cd_two_cheat_type2_day_title_txt').text(window.keystrings['com.numi.cd.female_two_break_days_type2']);
            $('.cd_all_set_no_cheat_day_txt').text(window.keystrings['com.numi.cd.all_set.female_no_break_day']);
            $('.cd_all_set_one_cheat_day_txt').text(window.keystrings['com.numi.cd.all_set.female_one_break_day']);
            $('.cd_all_set_two_cheat_day_txt').text(window.keystrings['com.numi.cd.all_set_female_two_break_day_desc']);
            $('.cd_smartweek_header_newCalorieGoal_txt').text(window.keystrings['com.numi.smartweek.female_new_calorie_goal']);
            $('.cd_smartweek_intro_desc_newCalorieGoal_txt').text(window.keystrings['com.numi.smartweek.female_new_calorie_goal_desc']);
            $('.cd_smartweek_intro_desc_newCalorieGoal_title_txt').text(window.keystrings['com.numi.cd.female.new_calorie_goal_title']);
            $('.cd_smartweek_female_no_cheat_day_txt').text(window.keystrings['com.numi.cd.all_set.female_no_break_day']);
            $('.cd_smartweek_female_one_cheat_day_txt').text(window.keystrings['com.numi.cd.all_set.female_one_break_day']);
            $('.cd_smartweek_female_two_cheat_day_txt').text(window.keystrings['com.numi.cd.all_set.female_two_break_days']);

            if ((smartWeekTypeSelected === 'steady' ? 0 : smartWeekTypeSelected === '1cd' ? 1 : 2) === 0) {
                $('.cheatdayfinal').text(window.keystrings['com.numi.cd.all_set.female_no_break_day']);
            } else if ((smartWeekTypeSelected === 'steady' ? 0 : smartWeekTypeSelected === '1cd' ? 1 : 2) === 1) {
                $('.cheatdayfinal').text(window.keystrings['com.numi.cd.all_set.female_one_break_day']);
            } else {
                $('.cheatdayfinal').text(window.keystrings['com.numi.cd.all_set.female_two_break_days']);
            }

            /*keystrings*/
        }





        if (smartWeekTypeSelected == "steady") {
            $("#newCalGoal").hide();
            $("#step0").hide();
            $("#step1").hide();
            $("#step2").hide();
            $("#step3").show();
            $(document.getElementsByClassName("week-cheat-day-card")).removeClass("selected")

        } else {
            $("#newCalGoal").hide();
            $("#step0").hide();
            $("#step1").hide();
            $("#step2").show();
            $("#step3").hide();
        }

        if (localStorage.getItem("gender") === "male") {
            /*keystrings*/

            if ((smartWeekTypeSelected === 'steady' ? 0 : smartWeekTypeSelected === '1cd' ? 1 : 2) === 1) {
                $('.cd_week_type_title_txt').text(window.keystrings['com.numi.cd.male_one_day_week_type_title']);

            } else {
                $('.cd_week_type_title_txt').text(window.keystrings['com.numi.cd.male_two_day_week_type_title']);
            }

            /*keystrings*/
        } else {
            /*keystrings*/

            if ((smartWeekTypeSelected === 'steady' ? 0 : smartWeekTypeSelected === '1cd' ? 1 : 2) === 1) {
                $('.cd_week_type_title_txt').text(window.keystrings['com.numi.cd.female_one_day_week_type_title']);
            } else {
                $('.cd_week_type_title_txt').text(window.keystrings['com.numi.cd.female_two_day_week_type_title']);
            }
            /*keystrings*/
        }



    }

    callCheatDaysAPI(smartWeekTypeSelected) {

        if (this.state.smartWeekPlanSetting !== smartWeekTypeSelected) {
            $(document.getElementsByClassName("week-cheat-day-card")).removeClass("selected")
            $(document.getElementsByClassName("week-cheat-day-card")).removeClass("option_disabled")
            $(document.getElementById("smartWeekCheatDaySubmitBtn")).addClass("option_disabled");
            $(document.getElementById("smartWeekCheatDaySaveBtn")).addClass("option_disabled");
        } else {
            if (this.state.cheatDaysSelected != null) {

                $(".week-cheat-day-card").removeClass("selected");
                if (this.state.cheatDaysSelected.includes("Sunday")) {
                    $('#week-cheat-day-card-sunday').addClass("selected");
                } if (this.state.cheatDaysSelected.includes("Monday")) {
                    $('#week-cheat-day-card-monday').addClass("selected");
                } if (this.state.cheatDaysSelected.includes("Tuesday")) {
                    $('#week-cheat-day-card-tuesday').addClass("selected");
                } if (this.state.cheatDaysSelected.includes("Wednesday")) {
                    $('#week-cheat-day-card-wednesday').addClass("selected");
                } if (this.state.cheatDaysSelected.includes("Thursday")) {
                    $('#week-cheat-day-card-thursday').addClass("selected");
                } if (this.state.cheatDaysSelected.includes("Friday")) {
                    $('#week-cheat-day-card-friday').addClass("selected");
                } if (this.state.cheatDaysSelected.includes("Saturday")) {
                    $('#week-cheat-day-card-saturday').addClass("selected");
                }
                $(document.getElementById("smartWeekCheatDaySubmitBtn")).removeClass("option_disabled");
                $(document.getElementById("smartWeekCheatDaySaveBtn")).removeClass("option_disabled");
            }
        }

        this.setState({
            cheatDays: (smartWeekTypeSelected === 'steady' ? 0 : smartWeekTypeSelected === '1cd' ? 1 : 2)
        })

        if (localStorage.getItem("gender") === "male") {
            /*keystrings*/
            $('.cd_plan_your_week_txt').text(window.keystrings['com.numi.cd.male_plan_your_week']);
            $('.cd_plan_your_week_desc_txt').text(window.keystrings['com.numi.cd.male_plan_your_week_desc']);
            $('.cd_plan_your_week_settings_txt').text(window.keystrings['com.numi.cd.male_settings_can_be_changed']);
            $('.cd_all_set_txt').text(window.keystrings['com.numi.cd.all_set_male_title']);
            $('.cd_all_set_desc_txt').html(window.keystrings['com.numi.cd.all_set_male_standard_desc']);
            $('.cd_all_set_male_one_smartday_desc_txt').html(window.keystrings['com.numi.cd.all_set_male_one_smartday_desc']);
            $('.cd_all_set_male_two_smartday_desc_txt').html(window.keystrings['com.numi.cd.all_set_male_two_smartday_desc']);
            $('.cd_smartweek_intro_txt').text(window.keystrings['com.numi.smartweek_male_intro']);
            $('.cd_smartweek_intro_desc_txt').html(window.keystrings['com.numi.smartweek_male_intro_desc']);
            $('.cd_smartweek_male_intro_quicktip_txt').html(window.keystrings['com.numi.smartweek_male_intro_quicktip']);
            $('.cd_smartweek_header_txt').text(window.keystrings['com.numi.smartweek_male_header']);
            $('.cd_steady_week_type_title_txt').text(window.keystrings['com.numi.cd.male_steady_week_type_title']);
            $('.cd_smartweek_choose_your_week_txt').text(window.keystrings['com.numi.cd.male_steady_week_type_header']);
            $('.cd_steady_title_txt').text(window.keystrings['com.numi.cd.male_steady_title']);
            $('.cd_steady_type1_title_txt').text(window.keystrings['com.numi.cd.male_steady_type1']);
            $('.cd_one_cheat_day_title_txt').text(window.keystrings['com.numi.cd.male_one_cheat_day_title']);
            $('.cd_one_cheat_type1_day_title_txt').text(window.keystrings['com.numi.cd.male_one_cheat_day_type1']);
            $('.cd_one_cheat_type2_day_title_txt').text(window.keystrings['com.numi.cd.male_one_cheat_day_type2']);
            $('.cd_two_cheat_day_title_txt').text(window.keystrings['com.numi.cd.male_two_cheat_days_title']);
            $('.cd_two_cheat_type1_day_title_txt').text(window.keystrings['com.numi.cd.male_two_cheat_days_type1']);
            $('.cd_two_cheat_type2_day_title_txt').text(window.keystrings['com.numi.cd.male_two_cheat_days_type2']);
            $('.cd_all_set_no_cheat_day_txt').text(window.keystrings['com.numi.cd.all_set.male_no_cheat_day']);
            $('.cd_all_set_one_cheat_day_txt').text(window.keystrings['com.numi.cd.all_set.male_one_cheat_day']);
            $('.cd_all_set_two_cheat_day_txt').text(window.keystrings['com.numi.cd.all_set.male_two_cheat_days']);
            $('.cd_smartweek_header_newCalorieGoal_txt').text(window.keystrings['com.numi.smartweek.male_new_calorie_goal']);
            $('.cd_smartweek_intro_desc_newCalorieGoal_txt').text(window.keystrings['com.numi.smartweek.male_new_calorie_goal_desc']);
            $('.cd_smartweek_intro_desc_newCalorieGoal_title_txt').text(window.keystrings['com.numi.cd.male.new_calorie_goal_title']);
            $('.cd_smartweek_male_no_cheat_day_txt').text(window.keystrings['com.numi.cd.all_set.male_no_cheat_day']);
            $('.cd_smartweek_male_one_cheat_day_txt').text(window.keystrings['com.numi.cd.all_set.male_one_cheat_day']);
            $('.cd_smartweek_male_two_cheat_day_txt').text(window.keystrings['com.numi.cd.all_set.male_two_cheat_days']);

            /*keystrings*/
        } else {
            /*keystrings*/
            $('.cd_plan_your_week_txt').text(window.keystrings['com.numi.cd.female_plan_your_week']);
            $('.cd_plan_your_week_desc_txt').text(window.keystrings['com.numi.cd.female_plan_your_week_desc']);
            $('.cd_plan_your_week_settings_txt').text(window.keystrings['com.numi.cd.female_settings_can_be_changed']);
            $('.cd_all_set_txt').text(window.keystrings['com.numi.cd.all_set_female_title']);
            $('.cd_all_set_desc_txt').html(window.keystrings['com.numi.cd.all_set_female_standard_desc']);
            $('.cd_all_set_female_one_smartday_desc_txt').html(window.keystrings['com.numi.cd.all_set_female_one_smartday_desc']);
            $('.cd_all_set_female_two_smartday_desc_txt').html(window.keystrings['com.numi.cd.all_set_female_two_smartday_desc']);
            $('.cd_smartweek_intro_txt').text(window.keystrings['com.numi.smartweek_female_intro']);
            $('.cd_smartweek_intro_desc_txt').html(window.keystrings['com.numi.smartweek_female_intro_desc']);
            $('.cd_smartweek_male_intro_quicktip_txt').html(window.keystrings['com.numi.smartweek_female_intro_quicktip']);
            $('.cd_smartweek_header_txt').text(window.keystrings['com.numi.smartweek_female_header']);
            $('.cd_steady_week_type_title_txt').text(window.keystrings['com.numi.cd.female_steady_week_type_title']);
            $('.cd_smartweek_choose_your_week_txt').text(window.keystrings['com.numi.cd.female_steady_week_type_header']);
            $('.cd_steady_title_txt').text(window.keystrings['com.numi.cd.female_steady_title']);
            $('.cd_steady_type1_title_txt').text(window.keystrings['com.numi.cd.female_steady_type1']);
            $('.cd_one_cheat_day_title_txt').text(window.keystrings['com.numi.cd.female_one_break_day_title']);
            $('.cd_one_cheat_type1_day_title_txt').text(window.keystrings['com.numi.cd.female_one_break_day_type1']);
            $('.cd_one_cheat_type2_day_title_txt').text(window.keystrings['com.numi.cd.female_one_break_day_type2']);
            $('.cd_two_cheat_day_title_txt').text(window.keystrings['com.numi.cd.female_two_break_days_title']);
            $('.cd_two_cheat_type1_day_title_txt').text(window.keystrings['com.numi.cd.female_two_break_days_type1']);
            $('.cd_two_cheat_type2_day_title_txt').text(window.keystrings['com.numi.cd.female_two_break_days_type2']);
            $('.cd_all_set_no_cheat_day_txt').text(window.keystrings['com.numi.cd.all_set.female_no_break_day']);
            $('.cd_all_set_one_cheat_day_txt').text(window.keystrings['com.numi.cd.all_set.female_one_break_day']);
            $('.cd_all_set_two_cheat_day_txt').text(window.keystrings['com.numi.cd.all_set_female_two_break_day_desc']);
            $('.cd_smartweek_header_newCalorieGoal_txt').text(window.keystrings['com.numi.smartweek.female_new_calorie_goal']);
            $('.cd_smartweek_intro_desc_newCalorieGoal_txt').text(window.keystrings['com.numi.smartweek.female_new_calorie_goal_desc']);
            $('.cd_smartweek_intro_desc_newCalorieGoal_title_txt').text(window.keystrings['com.numi.cd.female.new_calorie_goal_title']);
            $('.cd_smartweek_female_no_cheat_day_txt').text(window.keystrings['com.numi.cd.all_set.female_no_break_day']);
            $('.cd_smartweek_female_one_cheat_day_txt').text(window.keystrings['com.numi.cd.all_set.female_one_break_day']);
            $('.cd_smartweek_female_two_cheat_day_txt').text(window.keystrings['com.numi.cd.all_set.female_two_break_days']);
            /*keystrings*/
        }

        $(".week-cheat-day-card").off('click').on('click', function () {
            var cheatdays = (smartWeekTypeSelected === 'steady' ? 0 : smartWeekTypeSelected === '1cd' ? 1 : 2);
            if ((cheatdays) <= $('#step2 .selected').length) {
                $(this).removeClass("selected");
                if ($(document.getElementsByClassName("week-cheat-day-card")).hasClass("selected")) {
                    $(document.getElementById("smartWeekCheatDaySubmitBtn")).removeClass("option_disabled");
                    $(document.getElementById("smartWeekCheatDaySaveBtn")).removeClass("option_disabled");
                    $(document.getElementsByClassName("week-cheat-day-card")).removeClass("option_disabled");
                } else {
                    if ((cheatdays) <= $('#step2 .selected').length) {
                        $(document.getElementsByClassName("week-cheat-day-card")).addClass("option_disabled");
                    }
                }
            } else {
                $(this).toggleClass("selected");
                if ((cheatdays) >= $('#step2 .selected').length) {
                    if ($(document.getElementsByClassName("week-cheat-day-card")).hasClass("selected")) {

                        $(document.getElementsByClassName("week-cheat-day-card")).removeClass("option_disabled");
                    } else {
                        if ((cheatdays) <= $('#step2 .selected').length) {
                            $(document.getElementsByClassName("week-cheat-day-card")).addClass("option_disabled");
                        }
                    }
                } else {
                    $(document.getElementsByClassName("week-cheat-day-card")).removeClass("option_disabled");
                }

            }

            if ((cheatdays) == $('#step2 .selected').length) {
                $(document.getElementById("smartWeekCheatDaySubmitBtn")).removeClass("option_disabled");
                $(document.getElementById("smartWeekCheatDaySaveBtn")).removeClass("option_disabled");
            } else {
                $(document.getElementById("smartWeekCheatDaySubmitBtn")).addClass("option_disabled");
                $(document.getElementById("smartWeekCheatDaySaveBtn")).addClass("option_disabled");
            }
        });
    }

    smartWeekCheatDaySubmit(from) {
        var selected_days = [];
        $("#step2 .selected").each(function () {
            $(this).children("span").each(function () {
                selected_days.push(this.innerHTML);
            });
        });
        var smart_week_cheat_day_data = { "smart_week": { "cheat_days": selected_days.join(',') } };
        var smartWeekTypeSelected = this.state.smartWeekTypeSelected
        if (localStorage.getItem("gender") === "male") {
            /*keystrings*/
            $('.cd_plan_your_week_txt').text(window.keystrings['com.numi.cd.male_plan_your_week']);
            $('.cd_plan_your_week_desc_txt').text(window.keystrings['com.numi.cd.male_plan_your_week_desc']);
            $('.cd_plan_your_week_settings_txt').text(window.keystrings['com.numi.cd.male_settings_can_be_changed']);
            $('.cd_all_set_txt').text(window.keystrings['com.numi.cd.all_set_male_title']);
            $('.cd_all_set_desc_txt').html(window.keystrings['com.numi.cd.all_set_male_standard_desc']);
            $('.cd_all_set_male_one_smartday_desc_txt').html(window.keystrings['com.numi.cd.all_set_male_one_smartday_desc']);
            $('.cd_all_set_male_two_smartday_desc_txt').html(window.keystrings['com.numi.cd.all_set_male_two_smartday_desc']);
            $('.cd_smartweek_intro_txt').text(window.keystrings['com.numi.smartweek_male_intro']);
            $('.cd_smartweek_intro_desc_txt').html(window.keystrings['com.numi.smartweek_male_intro_desc']);
            $('.cd_smartweek_male_intro_quicktip_txt').html(window.keystrings['com.numi.smartweek_male_intro_quicktip']);
            $('.cd_smartweek_header_txt').text(window.keystrings['com.numi.smartweek_male_header']);
            $('.cd_steady_week_type_title_txt').text(window.keystrings['com.numi.cd.male_steady_week_type_title']);
            $('.cd_smartweek_choose_your_week_txt').text(window.keystrings['com.numi.cd.male_steady_week_type_header']);
            $('.cd_steady_title_txt').text(window.keystrings['com.numi.cd.male_steady_title']);
            $('.cd_steady_type1_title_txt').text(window.keystrings['com.numi.cd.male_steady_type1']);
            $('.cd_one_cheat_day_title_txt').text(window.keystrings['com.numi.cd.male_one_cheat_day_title']);
            $('.cd_one_cheat_type1_day_title_txt').text(window.keystrings['com.numi.cd.male_one_cheat_day_type1']);
            $('.cd_one_cheat_type2_day_title_txt').text(window.keystrings['com.numi.cd.male_one_cheat_day_type2']);
            $('.cd_two_cheat_day_title_txt').text(window.keystrings['com.numi.cd.male_two_cheat_days_title']);
            $('.cd_two_cheat_type1_day_title_txt').text(window.keystrings['com.numi.cd.male_two_cheat_days_type1']);
            $('.cd_two_day_week_type_title_txt').text(window.keystrings['com.numi.cd.male_two_day_week_type_title']);
            $('.cd_all_set_no_cheat_day_txt').text(window.keystrings['com.numi.cd.all_set.male_no_cheat_day']);
            $('.cd_all_set_one_cheat_day_txt').text(window.keystrings['com.numi.cd.all_set.male_one_cheat_day']);
            $('.cd_all_set_two_cheat_day_txt').text(window.keystrings['com.numi.cd.all_set.male_two_cheat_days']);
            $('.cd_smartweek_header_newCalorieGoal_txt').text(window.keystrings['com.numi.smartweek.male_new_calorie_goal']);
            $('.cd_smartweek_intro_desc_newCalorieGoal_txt').text(window.keystrings['com.numi.smartweek.male_new_calorie_goal_desc']);
            $('.cd_smartweek_intro_desc_newCalorieGoal_title_txt').text(window.keystrings['com.numi.cd.male.new_calorie_goal_title']);
            $('.cd_smartweek_male_no_cheat_day_txt').text(window.keystrings['com.numi.cd.all_set.male_no_cheat_day']);
            $('.cd_smartweek_male_one_cheat_day_txt').text(window.keystrings['com.numi.cd.all_set.male_one_cheat_day']);
            $('.cd_smartweek_male_two_cheat_day_txt').text(window.keystrings['com.numi.cd.all_set.male_two_cheat_days']);

            if ((smartWeekTypeSelected === 'steady' ? 0 : smartWeekTypeSelected === '1cd' ? 1 : 2) === 0) {
                $('.cheatdayfinal').text(window.keystrings['com.numi.cd.all_set.male_no_cheat_day']);
            } else if ((smartWeekTypeSelected === 'steady' ? 0 : smartWeekTypeSelected === '1cd' ? 1 : 2) === 1) {
                $('.cheatdayfinal').text(window.keystrings['com.numi.cd.all_set.male_one_cheat_day']);
            } else {
                $('.cheatdayfinal').text(window.keystrings['com.numi.cd.all_set.male_two_cheat_days']);
            }


            /*keystrings*/
        } else {
            /*keystrings*/
            $('.cd_plan_your_week_txt').text(window.keystrings['com.numi.cd.female_plan_your_week']);
            $('.cd_plan_your_week_desc_txt').text(window.keystrings['com.numi.cd.female_plan_your_week_desc']);
            $('.cd_plan_your_week_settings_txt').text(window.keystrings['com.numi.cd.female_settings_can_be_changed']);
            $('.cd_all_set_txt').text(window.keystrings['com.numi.cd.all_set_female_title']);
            $('.cd_all_set_desc_txt').html(window.keystrings['com.numi.cd.all_set_female_standard_desc']);
            $('.cd_all_set_female_one_smartday_desc_txt').html(window.keystrings['com.numi.cd.all_set_female_one_smartday_desc']);
            $('.cd_all_set_female_two_smartday_desc_txt').html(window.keystrings['com.numi.cd.all_set_female_two_smartday_desc']);
            $('.cd_smartweek_intro_txt').text(window.keystrings['com.numi.smartweek_female_intro']);
            $('.cd_smartweek_intro_desc_txt').html(window.keystrings['com.numi.smartweek_female_intro_desc']);
            $('.cd_smartweek_male_intro_quicktip_txt').html(window.keystrings['com.numi.smartweek_female_intro_quicktip']);
            $('.cd_smartweek_header_txt').text(window.keystrings['com.numi.smartweek_female_header']);
            $('.cd_steady_week_type_title_txt').text(window.keystrings['com.numi.cd.female_steady_week_type_title']);
            $('.cd_smartweek_choose_your_week_txt').text(window.keystrings['com.numi.cd.female_steady_week_type_header']);
            $('.cd_steady_title_txt').text(window.keystrings['com.numi.cd.female_steady_title']);
            $('.cd_steady_type1_title_txt').text(window.keystrings['com.numi.cd.female_steady_type1']);
            $('.cd_one_cheat_day_title_txt').text(window.keystrings['com.numi.cd.female_one_break_day_title']);
            $('.cd_one_cheat_type1_day_title_txt').text(window.keystrings['com.numi.cd.female_one_break_day_type1']);
            $('.cd_one_cheat_type2_day_title_txt').text(window.keystrings['com.numi.cd.female_one_break_day_type2']);
            $('.cd_two_cheat_day_title_txt').text(window.keystrings['com.numi.cd.female_two_break_days_title']);
            $('.cd_two_cheat_type1_day_title_txt').text(window.keystrings['com.numi.cd.female_two_break_days_type1']);
            $('.cd_two_cheat_type2_day_title_txt').text(window.keystrings['com.numi.cd.female_two_break_days_type2']);
            $('.cd_all_set_no_cheat_day_txt').text(window.keystrings['com.numi.cd.all_set.female_no_break_day']);
            $('.cd_all_set_one_cheat_day_txt').text(window.keystrings['com.numi.cd.all_set.female_one_break_day']);
            $('.cd_all_set_two_cheat_day_txt').text(window.keystrings['com.numi.cd.all_set_female_two_break_day_desc']);
            $('.cd_smartweek_header_newCalorieGoal_txt').text(window.keystrings['com.numi.smartweek.female_new_calorie_goal']);
            $('.cd_smartweek_intro_desc_newCalorieGoal_txt').text(window.keystrings['com.numi.smartweek.female_new_calorie_goal_desc']);
            $('.cd_smartweek_intro_desc_newCalorieGoal_title_txt').text(window.keystrings['com.numi.cd.male.new_calorie_goal_title']);
            $('.cd_smartweek_female_no_cheat_day_txt').text(window.keystrings['com.numi.cd.all_set.female_no_break_day']);
            $('.cd_smartweek_female_one_cheat_day_txt').text(window.keystrings['com.numi.cd.all_set.female_one_break_day']);
            $('.cd_smartweek_female_two_cheat_day_txt').text(window.keystrings['com.numi.cd.all_set.female_two_break_days']);

            if ((smartWeekTypeSelected === 'steady' ? 0 : smartWeekTypeSelected === '1cd' ? 1 : 2) === 0) {
                $('.cheatdayfinal').text(window.keystrings['com.numi.cd.all_set.female_no_break_day']);
            } else if ((smartWeekTypeSelected === 'steady' ? 0 : smartWeekTypeSelected === '1cd' ? 1 : 2) === 1) {
                $('.cheatdayfinal').text(window.keystrings['com.numi.cd.all_set.female_one_break_day']);
            } else {
                $('.cheatdayfinal').text(window.keystrings['com.numi.cd.all_set.female_two_break_days']);
            }

            /*keystrings*/
        }


        if (from === 'plan_settings') {
            Header.startLoader(); // start loader
            const options = {
                url: window.API_URL + "v1/nutrisystem/cheat_days",
                method: 'POST',
                credentials: 'same-origin',
                headers: Header.setAuthHeaders(),
                data: smart_week_cheat_day_data
            };

            axios(options)
                .then(response => {
                    Header.stopLoader(); // stop loader
                    $("#newCalGoal").hide();
                    $("#step0").hide();
                    $("#step1").hide();
                    $("#step2").hide();
                    $("#step3").hide();
                    $('.modal').modal('hide');
                    $('body').removeClass('modal-open');
                    $('.modal-backdrop').remove();
                    const cheatDaysCal = {
                        url: window.API_URL + "v1/nutrisystem/smart_week",
                        method: 'GET',
                        credentials: 'same-origin',
                        headers: Header.setAuthHeaders(),
                    };

                    axios(cheatDaysCal)
                        .then(response => {
                            this.setState({
                                cheatDaysSelected: response.data.smart_week.cheat_days,
                                smartWeekPlanSetting: response.data.smart_week.smartweek_type,
                                cheatDays: response.data.smart_week.allowed_number_of_cheat_days === null ? 0 : response.data.smart_week.allowed_number_of_cheat_days
                            })
                        })

                })
                .catch(error => {
                    Header.stopLoader(); // stop loader
                    $('.progress_error').text('');
                    if (error && error.response && error.response.data && error.response.data.error) {
                        $('.progress_error').text(error.response.data.error);
                    }
                    else if (error && error.response && error.response.status > 400) {
                        $('.progress_error').text(error.response.statusText);
                    }
                    else {
                        alert(error.message ? error.message : 'Something went wrong!');
                    }
                    Sentry.captureException(error);
                });
        } else {

            $("#newCalGoal").hide();
            $("#step0").hide();
            $("#step1").hide();
            $("#step2").hide();
            $("#step3").show();

        }
    }

    callCipdSmartAdapt() {
        Header.startLoader();
        const options = {
            url: window.API_URL + 'v1/nutrisystem/apply_smart_adapt',
            method: 'GET',
            credentials: 'same-origin',
            headers: Header.setAuthHeaders(),
        };
        axios(options)
            .then(response => {
                Header.stopLoader();
                const optionscipd = {
                    url: window.API_URL + 'v1/nutrisystem/apply_cipd',
                    method: 'GET',
                    credentials: 'same-origin',
                    headers: Header.setAuthHeaders(),
                };
                axios(optionscipd)
                    .then(responsecipd => {
                        Header.stopLoader();
                    })
            })
    }

    callSmartAdapt = () => {
        var selected_days = [];
        $("#step2 .selected").each(function () {
            $(this).children("span").each(function () {
                selected_days.push(this.innerHTML);
            });
        });
        var smart_week_cheat_day_data = { "smart_week": { "cheat_days": selected_days.join(',') } };
        var smart_week_type_data = { "smart_week": { "smart_week_type": this.state.smartWeekTypeSelected } };
        Header.startLoader(); // start loader
        const options = {
            url: window.API_URL + "v1/nutrisystem/smart_week_type",
            method: 'POST',
            credentials: 'same-origin',
            headers: Header.setAuthHeaders(),
            data: smart_week_type_data
        };

        axios(options)
            .then(response => {
                Header.stopLoader(); // stop loader
                const options = {
                    url: window.API_URL + "v1/nutrisystem/cheat_days",
                    method: 'POST',
                    credentials: 'same-origin',
                    headers: Header.setAuthHeaders(),
                    data: smart_week_cheat_day_data
                };

                axios(options)
                    .then(response => {
                        Header.stopLoader(); // stop loader

                        if (this.state.plansettings === 'plan_settings') {
                            this.props.history.push('/journal');
                            $('.modal-backdrop').remove();
                            $('.modal').modal('hide');
                            $('body').removeClass('modal-open');
                            if (this.state.smartWeekTypeSelected === 'steady') {
                                $(".cheat_day_option").hide();
                            } else {
                                $(".cheat_day_option").show();
                            }

                        } else if ((document.URL.indexOf('/journal') > -1) || (document.URL.indexOf('/progress') > -1)) {
                            this.props.history.push('/journal');
                            $('.modal-backdrop').remove();
                            $('.modal').modal('hide');
                            $('body').removeClass('modal-open');
                        } else {
                            this.props.history.push('/stay_on_track');
                        }
                    })
                    .catch(error => {
                        Header.stopLoader(); // stop loader
                        $('.progress_error').text('');
                        if (error && error.response && error.response.data && error.response.data.error) {
                            $('.progress_error').text(error.response.data.error);
                        }
                        else if (error && error.response && error.response.status > 400) {
                            $('.progress_error').text(error.response.statusText);
                        }
                        else {
                            alert(error.message ? error.message : 'Something went wrong!');
                        }
                        Sentry.captureException(error);
                    });



            })
            .catch(error => {
                Header.stopLoader(); // stop loader
                $('.progress_error').text('');
                if (error && error.response && error.response.data && error.response.data.error) {
                    $('.progress_error').text(error.response.data.error);
                }
                else if (error && error.response && error.response.status > 400) {
                    $('.progress_error').text(error.response.statusText);
                }
                else {
                    alert(error.message ? error.message : 'Something went wrong!');
                }
                Sentry.captureException(error);
            });



    }



    render() {


        const backToPrevStep1 = () => {
            if ((document.URL.indexOf('/plan_settings') > -1) || (document.URL.indexOf('/journal') > -1)) {
                $("#newCalGoal").show();
                $("#step0").hide();
                $("#step1").hide();
                $("#step2").hide();
                $("#step3").hide();
            }
            else {
                $("#newCalGoal").hide();
                $("#step0").show();
                $("#step1").hide();
                $("#step2").hide();
                $("#step3").hide();
            }
        }
        const backToPrevStep4 = () => {
            $("#newCalGoal").hide();
            $("#step0").show();
            $("#step1").hide();
            $("#step2").hide();
            $("#step3").hide();
        }
        const backToPrevStep12 = () => {
            $("#newCalGoal").hide();
            $("#step0").hide();
            $("#step1").show();
            $("#step2").hide();
            $("#step3").hide();
            $('.modal').modal('hide');
            $('body').removeClass('modal-open');
            $('.modal-backdrop').remove();
            if (this.state.smartWeekPlanSetting === 'steady') {
                this.setState({
                    noCheatDay: true,
                    oneCheatDay: false,
                    twoCheatDays: false,
                    smartWeekTypeSelected: "steady",
                });
            } else if (this.state.smartWeekPlanSetting === '1cd') {
                this.setState({
                    noCheatDay: false,
                    oneCheatDay: true,
                    twoCheatDays: false,
                    smartWeekTypeSelected: "1cd",
                });
            } if (this.state.smartWeekPlanSetting === '2cd') {
                this.setState({
                    noCheatDay: false,
                    oneCheatDay: false,
                    twoCheatDays: true,
                    smartWeekTypeSelected: "2cd",
                });
            }
        }
        const backToPrevStep2 = () => {
            if (this.state.plansettings === 'plan_settings') {
                $(".week-cheat-day-card").removeClass("selected");
                $("#newCalGoal").hide();
                $("#step0").hide();
                $("#step1").show();
                $("#step2").hide();
                $("#step3").hide();
                $('#change_smartweek_setup').modal('show');

            } else {
                $("#newCalGoal").hide();
                $("#step0").hide();
                $("#step1").show();
                $("#step2").hide();
                $("#step3").hide();
            }
        }

        const backToPrevStep21 = () => {
            $(".week-cheat-day-card").removeClass("selected");
            $("#newCalGoal").hide();
            $("#step0").hide();
            $("#step1").hide();
            $("#step2").show();
            $("#step3").hide();
            $('.modal').modal('hide');
            $('body').removeClass('modal-open');
            $('.modal-backdrop').remove();


        }

        const backToPrevStep3 = () => {
            if (this.state.cheatDays == null || this.state.smartWeekTypeSelected === "steady") {
                $("#newCalGoal").hide();
                $("#step0").hide();
                $("#step1").show();
                $("#step2").hide();
                $("#step3").hide();
            } else {
                $("#newCalGoal").hide();
                $("#step0").hide();
                $("#step1").hide();
                $("#step2").show();
                $("#step3").hide();
            }
        }

        const cheat_day_2 = 'cd2' in this.state.cheatDaysCalData;
        const cheat_day_1 = 'cd1' in this.state.cheatDaysCalData;
        const cheat_day_steady = 'steady' in this.state.cheatDaysCalData;

        return (
            <>

                <div className="smart-week-setup" id="newCalGoal" style={{ display: "none" }}>
                    <div className="col-lg-12 d-flex justify-content-start align-items-center">
                    </div>
                    <div className="week-cheat-day-card-wrapper">

                        <div className="row">
                            <div className="col-lg-12">
                                <h6 className="smart_week_setup_txt"></h6>
                                <div className="mt10p mb5">
                                    <img src="/assets/images/smartWeek_screen_icon.png" className="smart-week-allset-icon" alt="" />
                                </div>
                                <div className="text-center">
                                    <h3 className="cd_smartweek_header_newCalorieGoal_txt"></h3>
                                    <p className="font16 color3 font-weight-bold mb0 mt-5 cd_smartweek_intro_desc_newCalorieGoal_title_txt"></p>
                                    <p className="font16 color3 cd_smartweek_intro_desc_newCalorieGoal_txt"></p>
                                </div>
                                <div className="mt6 mb5 mb"><button className="continue_text width77 margin0" onClick={() => { this.smartWeekIntroSubmit() }}></button></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="smart-week-setup" id="step0" style={{ display: "none" }}>
                    <div className="col-lg-12 d-flex justify-content-start align-items-center">
                    </div>
                    <div className="week-cheat-day-card-wrapper">

                        <div className="row">
                            <div className="col-lg-12">
                                <h6 className="smart_week_setup_txt"></h6>
                                <div className="mt10p mb5">
                                    <img src="/assets/images/smartWeek_screen_icon.png" className="smart-week-allset-icon" alt="" />
                                </div>
                                <div className="text-center">
                                    <h3 className="cd_smartweek_intro_txt"></h3>
                                    <h6 className="cd_smartweek_header_txt mb5"></h6>
                                    <p className="font16 color3 cd_smartweek_intro_desc_txt"></p>
                                    <p className="font16 cd_smartweek_male_intro_quicktip_txt text-left"></p>
                                </div>
                                <div className="mt6 mb5 mb"><button className="continue_text width77 margin0" onClick={() => { this.smartWeekIntroSubmit() }}></button></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="smart-week-setup" id="step1" style={{ display: "none" }}>
                    <div className="col-lg-12 d-flex justify-content-start align-items-center">
                        <img alt="" src="/assets/images/back_black_icon.png" id='backToPrevStep1' className="smartweek_backbtn cursor_pointer d-none" onClick={backToPrevStep1} />
                        <img alt="" src="/assets/images/back_black_icon.png" id='backToPrevStep4' className="smartweek_backbtn cursor_pointer d-none" onClick={backToPrevStep4} />
                        <img alt="" src="/assets/images/back_black_icon.png" id='backToPrevStep12' className="smartweek_backbtn cursor_pointer d-none" onClick={backToPrevStep12} />
                    </div>
                    <div className="col-lg-12">
                        <h3 className="cd_steady_week_type_title_txt"></h3>
                        <h6 className="cd_smartweek_choose_your_week_txt mb5 plr18"></h6>
                        <div className="mt2">
                            <div style={{ display: cheat_day_steady === true ? "block" : "none" }}>
                                <div className={this.state.noCheatDay == true ? "week-type-card selected" : "week-type-card"} id="steadycard" onClick={() => {
                                    this.setState({
                                        noCheatDay: true,
                                        oneCheatDay: false,
                                        twoCheatDays: false,
                                        smartWeekTypeSelected: "steady",
                                    });
                                    this.setCheatDaysKey("steady")
                                }}>
                                    <div className="week-type-card-option">
                                        <div className="week-type-days-count">
                                            <img src="/assets/images/smartWeek_type_unselected.png" className="cheat-day-type-radio-btn" alt=""
                                                style={{ display: this.state.noCheatDay == true ? "none" : "block" }}
                                            />
                                            <img src="/assets/images/smartWeek_type_selected.png" className="cheat-day-type-radio-btn" alt=""
                                                style={{ display: this.state.noCheatDay == true ? "block" : "none" }} />
                                            <input type="radio" value="steady" id="steady" style={{ display: "none" }}
                                                checked={this.state.noCheatDay == true ? true : false}
                                                onChange={() => {
                                                    this.setState({
                                                        noCheatDay: true,
                                                        oneCheatDay: false,
                                                        twoCheatDays: false,
                                                        smartWeekTypeSelected: "steady",
                                                    });
                                                    this.setCheatDaysKey("steady")
                                                }}
                                            />
                                        </div>
                                        <h6 className="week-type-name cd_steady_title_txt"></h6>
                                    </div>
                                    <div className="week-type-card-option-details">
                                        <div className="week-type-days-count">
                                            <img src="/assets/images/Standard_day_icon.png" className="standard-cheat-day-icon" alt="" />
                                            <span className="cd_steady_type1_title_txt"></span>
                                        </div>
                                        <div>
                                            <span className="week-days-cal-count">
                                                <span>
                                                    {this.state.cheatDaysCalData.steady != undefined && this.state.cheatDaysCalData.length != 0 ? this.state.cheatDaysCalData.steady.standard_days_7 : ""}
                                                </span>
                                                <span> Cals</span>
                                            </span>
                                            <span className="week-type-days-count-per-day">&nbsp;per day</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: cheat_day_1 === true ? "block" : "none" }}>
                                <div className={this.state.oneCheatDay == true ? "week-type-card selected" : "week-type-card"} id="onecdcard" onClick={() => {
                                    this.setState({
                                        noCheatDay: false,
                                        oneCheatDay: true,
                                        twoCheatDays: false,
                                        smartWeekTypeSelected: "1cd",
                                    })
                                    this.setCheatDaysKey("1cd");
                                }}>
                                    <div className="week-type-card-option">
                                        <div className="week-type-days-count">
                                            <img src="/assets/images/smartWeek_type_unselected.png" className="cheat-day-type-radio-btn" alt=""
                                                style={{ display: this.state.oneCheatDay == true ? "none" : "block" }}
                                            />
                                            <img src="/assets/images/smartWeek_type_selected.png" className="cheat-day-type-radio-btn" alt=""
                                                style={{ display: this.state.oneCheatDay == true ? "block" : "none" }} />
                                            <input type="radio" value="steady" id="steady" style={{ display: "none" }}
                                                checked={this.state.oneCheatDay == true ? true : false}
                                                onChange={() => {
                                                    this.setState({
                                                        noCheatDay: false,
                                                        oneCheatDay: true,
                                                        twoCheatDays: false,
                                                        smartWeekTypeSelected: "1cd",
                                                    })
                                                    this.setCheatDaysKey("1cd");
                                                }}
                                            />
                                        </div>
                                        <h6 className="week-type-name cd_one_cheat_day_title_txt"></h6>
                                    </div>
                                    <div className="week-type-card-option-details">
                                        <div className="week-type-days-count">
                                            <img src="/assets/images/Standard_day_icon.png" className="standard-cheat-day-icon" alt="" />
                                            <span className="cd_one_cheat_type1_day_title_txt"></span></div>
                                        <div>
                                            <span className="week-days-cal-count">
                                                <span>
                                                    {this.state.cheatDaysCalData.cd1 != undefined && this.state.cheatDaysCalData.length != 0 ? this.state.cheatDaysCalData.cd1.standard_days_6 : ""}
                                                </span>
                                                <span> Cals</span>
                                            </span>
                                            <span className="week-type-days-count-per-day">&nbsp;per day</span></div>
                                    </div>
                                    <div className="week-type-card-option-details">
                                        <div className="week-type-days-count">
                                            <img src="/assets/images/cheat_day_icon.png" className="cheat-day-icon" alt="" />
                                            <span className="cd_one_cheat_type2_day_title_txt"></span></div>
                                        <div>
                                            <span className="week-days-cal-count">
                                                <span>
                                                    {this.state.cheatDaysCalData.cd1 != undefined && this.state.cheatDaysCalData.length != 0 ? this.state.cheatDaysCalData.cd1.cheat_day_1 : ""}
                                                </span>
                                                <span> Cals</span>
                                            </span>
                                            <span className="week-type-days-count-per-day">&nbsp;per day</span></div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: cheat_day_2 === true ? "block" : "none" }}>
                                <div className={this.state.twoCheatDays == true ? "week-type-card selected" : "week-type-card"} id="twocdcard" onClick={() => {
                                    this.setState({
                                        noCheatDay: false,
                                        oneCheatDay: false,
                                        twoCheatDays: true,
                                        smartWeekTypeSelected: "2cd",
                                        smartWeektypeDays: 2
                                    })
                                    this.setCheatDaysKey("2cd");
                                }}>
                                    <div className="week-type-card-option">
                                        <div className="week-type-days-count">
                                            <img src="/assets/images/smartWeek_type_unselected.png" className="cheat-day-type-radio-btn" alt=""
                                                style={{ display: this.state.twoCheatDays == true ? "none" : "block" }}
                                            />
                                            <img src="/assets/images/smartWeek_type_selected.png" className="cheat-day-type-radio-btn" alt=""
                                                style={{ display: this.state.twoCheatDays == true ? "block" : "none" }} />
                                            <input type="radio" value="steady" id="steady" style={{ display: "none" }}
                                                checked={this.state.twoCheatDays == true ? true : false}
                                                onChange={() => {
                                                    this.setState({
                                                        noCheatDay: false,
                                                        oneCheatDay: false,
                                                        twoCheatDays: true,
                                                        smartWeekTypeSelected: "2cd",
                                                        smartWeektypeDays: 2
                                                    })
                                                    this.setCheatDaysKey("2cd");
                                                }}
                                            />
                                        </div>
                                        <h6 className="week-type-name cd_two_cheat_day_title_txt"></h6>
                                    </div>
                                    <div className="week-type-card-option-details">
                                        <div className="week-type-days-count">
                                            <img src="/assets/images/Standard_day_icon.png" className="standard-cheat-day-icon" alt="" />
                                            <span className="cd_two_cheat_type1_day_title_txt"></span></div>
                                        <div>
                                            <span className="week-days-cal-count">
                                                <span>
                                                    {this.state.cheatDaysCalData.cd2 != undefined && this.state.cheatDaysCalData.length != 0 ? this.state.cheatDaysCalData.cd2.standard_days_5 : ""}
                                                </span>
                                                <span> Cals</span>
                                            </span>
                                            <span className="week-type-days-count-per-day">&nbsp;per day</span>
                                        </div>
                                    </div>
                                    <div className="week-type-card-option-details">
                                        <div className="week-type-days-count">
                                            <img src="/assets/images/cheat_day_icon.png" className="cheat-day-icon" alt="" />
                                            <span className="cd_two_cheat_type2_day_title_txt"></span></div>
                                        <div>
                                            <span className="week-days-cal-count">
                                                <span>
                                                    {this.state.cheatDaysCalData.cd2 != undefined && this.state.cheatDaysCalData.length != 0 ? this.state.cheatDaysCalData.cd2.cheat_days_2 : ""}
                                                </span>
                                                <span> Cals</span>
                                            </span>
                                            <span className="week-type-days-count-per-day">&nbsp;per day</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt6 mb5 mb">
                            <button
                                className={this.state.noCheatDay == true ? "continue_text width77 margin0" : this.state.oneCheatDay == true ? "continue_text width77 margin0" : this.state.twoCheatDays == true ? "continue_text width77 margin0" : "continue_text width77 margin0 option_disabled"} onClick={() => {
                                    this.smartWeekTypeSubmit(this.state.smartWeekTypeSelected)
                                }}></button>
                        </div>
                    </div>
                </div>

                {/* <div className="smart-week-setup-cheat-days"> */}
                <div className="smart-week-setup" id="step2" style={{ display: "none" }}>
                    <div className="col-lg-12 d-flex justify-content-start align-items-center">
                        <img alt="" src="/assets/images/back_black_icon.png" id='backToPrevStep2' className="smartweek_backbtn cursor_pointer" onClick={backToPrevStep2} />
                        {<img alt="" src="/assets/images/back_black_icon.png" id='backToPrevStep21' className="smartweek_backbtn cursor_pointer d-none" onClick={backToPrevStep21} />}
                    </div>
                    <div className="col-lg-12">
                        <h6 className="smart_week_setup_txt"></h6>
                        <h3 className="mb1 cd_week_type_title_txt"></h3>
                        <div className="text-center">
                            <h4 className="cd_plan_your_week_txt"></h4>
                            <p className="font16 color3 cd_plan_your_week_settings_txt"></p>
                        </div>
                        <div className="mt2 mb2">
                            <div className="week-cheat-day-card-wrapper">
                                <div className="row">
                                    <div className="col-6">
                                        <div className="week-cheat-day-card" id="week-cheat-day-card-monday" >
                                            <span>Monday</span>
                                            <img src="/assets/images/cheat_day_selected.png" className="cheat-day-selected-icon" alt="" />
                                        </div>
                                        <div className="week-cheat-day-card" id="week-cheat-day-card-tuesday">
                                            <span>Tuesday</span>
                                            <img src="/assets/images/cheat_day_selected.png" className="cheat-day-selected-icon" alt="" />
                                        </div>
                                        <div className="week-cheat-day-card" id="week-cheat-day-card-wednesday">
                                            <span>Wednesday</span>
                                            <img src="/assets/images/cheat_day_selected.png" className="cheat-day-selected-icon" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="week-cheat-day-card" id="week-cheat-day-card-thursday">
                                            <span>Thursday</span>
                                            <img src="/assets/images/cheat_day_selected.png" className="cheat-day-selected-icon" alt="" />
                                        </div>
                                        <div className="week-cheat-day-card" id="week-cheat-day-card-friday">
                                            <span>Friday</span>
                                            <img src="/assets/images/cheat_day_selected.png" className="cheat-day-selected-icon" alt="" />
                                        </div>
                                        <div className="week-cheat-day-card" id="week-cheat-day-card-saturday">
                                            <span>Saturday</span>
                                            <img src="/assets/images/cheat_day_selected.png" className="cheat-day-selected-icon" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-6">
                                        <div className="week-cheat-day-card" id="week-cheat-day-card-sunday">
                                            <span>Sunday</span>
                                            <img src="/assets/images/cheat_day_selected.png" className="cheat-day-selected-icon" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="text-center">
                            <h5 className="cd_plan_your_week_txt"></h5>
                            <p className="font16 color3 cd_plan_your_week_desc_txt"></p>
                        </div>
                        <div className="mt6 mb5 mb">
                            <button
                                id="smartWeekCheatDaySubmitBtn"
                                className="continue_text width77 margin0"
                                onClick={() => { this.smartWeekCheatDaySubmit("smart_week") }}></button>
                            <button
                                id="smartWeekCheatDaySaveBtn"
                                style={{ display: "none" }}
                                className="save_text width77 margin0"
                                onClick={() => { this.smartWeekCheatDaySubmit("plan_settings") }}></button>
                        </div>
                    </div>
                </div>

                <div className="smart-week-setup" id="step3" style={{ display: "none" }}>
                    <div className="col-lg-12 d-flex justify-content-start align-items-center">
                        <img alt="" src="/assets/images/back_black_icon.png" id='smartweek_backbtn_3' className="smartweek_backbtn cursor_pointer" onClick={backToPrevStep3} />
                    </div>
                    <div className="week-cheat-day-card-wrapper">
                        <div className="row">
                            <div className="col-lg-12">
                                <h6 className="smart_week_setup_txt"></h6>
                                <div className="mt10p mb5">
                                    <img src="/assets/images/smartWeek_screen_icon.png" className="smart-week-allset-icon" alt="" />
                                </div>
                                <div className="text-center">
                                    <h3 className="cd_all_set_txt mb5"></h3>
                                    {this.state.smartWeekTypeSelected === "steady" && localStorage.getItem("gender") === "male"
                                        ? <>
                                            <p className="font16 color3 cd_all_set_desc_txt"></p>
                                            {/* <p className="font16 color3 cd_smartweek_male_no_cheat_day_txt cheatdayfinal"></p> */}
                                        </>
                                        :
                                        this.state.smartWeekTypeSelected === "1cd" && localStorage.getItem("gender") === "male"
                                            ? <>
                                                <p className="font16 color3 cd_all_set_male_one_smartday_desc_txt"></p>
                                                {/* <p className="font16 color3 cd_smartweek_male_one_cheat_day_txt cheatdayfinal"></p> */}
                                            </> :
                                            this.state.smartWeekTypeSelected === "2cd" && localStorage.getItem("gender") === "male"
                                                ? <>
                                                    <p className="font16 color3 cd_all_set_male_two_smartday_desc_txt"></p>
                                                    {/* <p className="font16 color3 cd_smartweek_male_two_cheat_day_txt cheatdayfinal"></p> */}
                                                </> :
                                                this.state.smartWeekTypeSelected === "steady" && localStorage.getItem("gender") === "female"
                                                    ? <>
                                                        <p className="font16 color3 cd_all_set_desc_txt"></p>
                                                        {/* <p className="font16 color3 cd_smartweek_female_no_cheat_day_txt cheatdayfinal"></p> */}
                                                    </> :
                                                    this.state.smartWeekTypeSelected === "1cd" && localStorage.getItem("gender") === "female"
                                                        ? <>
                                                            <p className="font16 color3 cd_all_set_female_one_smartday_desc_txt"></p>
                                                            {/* <p className="font16 color3 cd_smartweek_female_one_cheat_day_txt cheatdayfinal"></p> */}
                                                        </> :
                                                        this.state.smartWeekTypeSelected === "2cd" && localStorage.getItem("gender") === "female"
                                                            ? <>
                                                                <p className="font16 color3 cd_all_set_female_two_smartday_desc_txt"></p>
                                                                {/* <p className="font16 color3 cd_smartweek_female_two_cheat_day_txt cheatdayfinal"></p> */}
                                                            </> :
                                                            ""
                                    }
                                </div>
                                <div className="mt6 mb5 mb"><button className="continue_text width77 margin0" onClick={() => { this.callSmartAdapt() }}></button></div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withRouter(SmartWeekCheatDay);