
import React from 'react';
import Header from "../header.js";
import { Link } from 'react-router-dom'
import Footer from "../footer.js";
import EditUnassinedPopup from "./edit_unassigned.js";
import UnassinedPopup from "../create_food/unassigned_food.js";
import MealDropdown from "./meal_dropdown.js";
import DatePicker from "react-datepicker";
import { FoodHelp, CountAsEditValidate } from './popup.js';
import moment from 'moment'
import Navigation from "../nav.js";
import Adspeed from "../adspeed.js";
import { addDays, parse } from 'date-fns';
import axios from 'axios';
import $ from 'jquery';
import * as Sentry from "@sentry/react";

class EditFood extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      date: moment(),
      startDate: window.localStorage.getItem('assigned_date'),
      serving_size: 1,
      serving_size_unit: null,
      food: [],
      food_serving_size: 1,
      nutrients: [],
      favorite: true,
      add_to_fav: window.keystrings['com.numi.remove_from_favorites'],
      add_fav_off: "/assets/images/fav_on.png",
      edit_icon: '/assets/images/edit.png',
      edit_food_copy: true,
      logit: false, edit_copy_food: addDays(new Date(), 1),
      actaul_serving: '',
      s3_images_path: '',
      servings_per_container: "",
    };

  }

  editDatechange = (date) => {
    this.setState({
      startDate: date,
      startDateEditFood: false

    });

    $('.logit').show();
    $('.edit_food_copy').hide();
    Header.changeDate(date);
    window.localStorage.setItem('new_assigned_date', moment(parse(date)).format('YYYY-MM-DD'));
    this.validateAPI();
    localStorage.removeItem('new_assigned_date');
  }

  editMealChange = () => {
    var bucket_sequence;
    var currentHour = moment().format('HH');

    if (currentHour <= 10 && currentHour >= 0) {
      bucket_sequence = '1';
    } else if (currentHour > 10 && currentHour < 12) {
      bucket_sequence = '2';
    } else if (currentHour >= 12 && currentHour < 14) {
      bucket_sequence = '3';
    } else if (currentHour >= 14 && currentHour < 17) {
      bucket_sequence = '4'
    } else if (currentHour >= 17 && currentHour < 20) {
      bucket_sequence = '5';
    } else if (currentHour >= 20 && currentHour <= 24) {
      bucket_sequence = '6';
    }
    window.localStorage.setItem('meal_occasion_id', bucket_sequence);
    setTimeout(function () {
      this.refs.get_meal_data.callMealData();
    }.bind(this), 155);

  }

  showEditfoodHelp(value) {
    window.localStorage.setItem('help_location', value);
    this.refs.callHelp.callHelpAPI();
  }
  async addFavorite() {
    if (this.state.add_to_fav === "Add to Favorites") {
      await this.setState({
        favorite: true,
        add_to_fav: window.keystrings['com.numi.remove_from_favorites'],
        add_fav_off: "/assets/images/fav_on.png",

      });
    }
    else {

      await this.setState({
        favorite: false,
        add_to_fav: window.keystrings['com.numi.add_to_favorites'],
        add_fav_off: "/assets/images/fav_off.png",
      });
    }

    $('.logit').show();
    $('.edit_food_copy').hide();


    /* favorite and unfavorite */
    var food_data = { "favorite_food": { "consumable_id": this.state.food.consumable_id, "consumable_type": this.state.food.consumable_type, "favorite": this.state.favorite } };
    const options = {
      url: window.API_URL + "v1/favorite_foods",
      method: 'POST',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
      data: food_data
    };

    axios(options)
      .then(response => {

      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });

  }

  validateAPI = () => {
    Header.startLoader();
    var kk = [];
    var final_array = [];
    var calories = $(".weight_validate").val() * this.state.food.calories;


    $('.un_assign').empty();
    var assigned_date = window.localStorage.getItem('new_assigned_date');
    var consumable_id = window.localStorage.getItem('consumable_id');
    var consumable_type = window.localStorage.getItem('consumable_type');

    var diet_history_id = window.localStorage.getItem('dh_id');
    var meal_occasion_id = window.localStorage.getItem('meal_occasion_id');

    const options = {
      url: window.API_URL + "v1/diet_histories/validate?consumable_id=" + consumable_id + "&consumable_type=" + consumable_type + "&diet_history_id=" + diet_history_id + "&meal_occasion_id=" + meal_occasion_id + "&assigned_date=" + assigned_date + "&serving_size=" + $('.weight_validate').val(),
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };
    axios(options)
      .then(response => {
        Header.stopLoader();
        $('.un_assign').empty();
        $('.weight_validate').val(response.data.valid_food_details.diet_history.serving_size);
        window.localStorage.setItem('assigned_date', response.data.valid_food_details.diet_history.assigned_date);
        if (localStorage.getItem('consumable_type') === "CustomFood") {
          $(".cal_count").text(parseInt(calories) + " Calories");
        }
        else {
          $(".cal_count").text(response.data.valid_food_details.diet_history.calories + " Calories");
        }

        for (var i = 0; i < response.data.valid_food_details.diet_history.food_categories.length; i++) {
          var name = response.data.valid_food_details.diet_history.food_categories[i].name;
          var img_name = "/" + name + "_SMALL_USED.png";
          var img_type = name.toLowerCase() + "_unfill"
          var img_src = response.data.valid_food_details.s3_images_path + "/"
          var count = parseFloat(response.data.valid_food_details.diet_history.food_categories[i].value).toFixed();
          for (var c = 0; c < count; c++) {
            $('.un_assign').append('<img alt=""name=' + response.data.valid_food_details.diet_history.food_categories[i].name + '  class="filled_icon cursor_pointer ' + img_type + '" value=' + img_type + ' src=' + img_src + name + img_name + '>');
          }
          var session_count = parseFloat(response.data.valid_food_details.diet_history.food_categories[i].value).toFixed();

          for (var cc = 0; cc < session_count; cc++) {
            $('.unassigned_hidden').append('<img alt=""name=' + response.data.valid_food_details.diet_history.food_categories[i].name + '  class="filled_icon cursor_pointer ' + img_type + '" value=' + img_type + ' src=' + img_src + name + img_name + '>');
            kk.push('<img alt=""name=' + response.data.valid_food_details.diet_history.food_categories[i].name + '  class="filled_icon cursor_pointer ' + img_type + '" value=' + img_type + ' src=' + img_src + name + img_name + '>');
          }
        }

        if (response.data.valid_food_details.diet_history.food_serving_sizes !== undefined) {
          if (response.data.valid_food_details.diet_history.food_serving_sizes !== null) {
            if (response.data.valid_food_details.diet_history.food_serving_sizes.length > 0) {
              this.setState({ servings_per_container: response.data.valid_food_details.diet_history.food_serving_sizes[0].servings_per_container.toFixed(1) });
            } else {
              this.setState({ servings_per_container: "1.0" });
            }
          } else {
            this.setState({ servings_per_container: "1.0" });
          }
        }

        var final_arr_dup = [];
        $.each(kk, function (i, el) {
          if ($.inArray(el, final_array) === -1) final_array.push(el);
          final_arr_dup.push(el);
        });
        window.localStorage.setItem('total_pills', final_arr_dup);
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });


  }

  EditArrowUp = () => {

    var oldValue = parseFloat($("input.serving_size").val());
    var newVal;

    if (oldValue === 9) {
      return
    }
    else {
      if (oldValue === 0.5) {
        newVal = 2;
        $(".weight_validate").val('1');
        window.localStorage.setItem('downClick', 'true');
        this.setState({ serving_size: 1 })
      }
      else {
        newVal = parseFloat(oldValue) + 0.5;
        $(".weight_validate").val(newVal);
        this.setState({ serving_size: newVal })
      }

    }

    this.handleInputEditFoodChange();
    $('.logit').show();
    $('.edit_food_copy').hide();
  }

  EditArrowDown = () => {

    var oldValue = parseFloat($("input.serving_size").val());



    // Don't allow decrementing below zero
    if (oldValue === 0.5) {
      return
    } else {
      var newVal = parseFloat(oldValue) - 0.5;
      $(".weight_validate").val(newVal);
      this.setState({ serving_size: newVal })

    }


    this.handleInputEditFoodChange();
    $('.logit').show();
    $('.edit_food_copy').hide();
  }

  handleInputEditFoodChange = (event) => {
    var kk = [];
    var final_array = [];
    if (event !== '' && event !== undefined) {
      this.setState({ serving_size: event.target.value });
    }


    if (window.localStorage.getItem('called_validate') !== 'true') {
      window.localStorage.setItem('called_validate', 'true');
      var assigned_date = window.localStorage.getItem('assigned_date');
      var consumable_id = window.localStorage.getItem('consumable_id');
      var consumable_type = window.localStorage.getItem('consumable_type');

      const options = {
        url: window.API_URL + "v1/diet_histories/validate?consumable_id=" + consumable_id + "&consumable_type=" + consumable_type + "&assigned_date=" + assigned_date + "&serving_size=" + $(".weight_validate").val(),
        method: 'GET',
        credentials: 'same-origin',
        headers: Header.setAuthHeaders(),
      };

      axios(options)
        .then(response => {
          for (var i = 0; i < response.data.valid_food_details.diet_history.food_categories.length; i++) {
            var name = response.data.valid_food_details.diet_history.food_categories[i].name;
            var img_name = "/" + name + "_SMALL_USED.png";
            var img_type = name.toLowerCase() + "_unfill"

            var img_src = response.data.valid_food_details.s3_images_path + "/"
            var count = parseFloat(response.data.valid_food_details.diet_history.food_categories[i].value).toFixed();
            for (var c = 0; c < count; c++) {
              $('.un_assign').append('<img alt=""name=' + response.data.valid_food_details.diet_history.food_categories[i].name + '  class="filled_icon cursor_pointer ' + img_type + '" value=' + img_type + ' src=' + img_src + name + img_name + '>');
            }
            var session_count = parseFloat(response.data.valid_food_details.diet_history.food_categories[i].value).toFixed();

            for (var cc = 0; cc < session_count; cc++) {
              $('.unassigned_hidden').append('<img alt=""name=' + response.data.valid_food_details.diet_history.food_categories[i].name + '  class="filled_icon cursor_pointer ' + img_type + '" value=' + img_type + ' src=' + img_src + name + img_name + '>');
              kk.push('<img alt=""name=' + response.data.valid_food_details.diet_history.food_categories[i].name + '  class="filled_icon cursor_pointer ' + img_type + '" value=' + img_type + ' src=' + img_src + name + img_name + '>');
            }
          }

          if (response.data.valid_food_details.diet_history.food_serving_sizes !== undefined) {
            if (response.data.valid_food_details.diet_history.food_serving_sizes !== null) {
              if (response.data.valid_food_details.diet_history.food_serving_sizes.length > 0) {
                this.setState({ servings_per_container: response.data.valid_food_details.diet_history.food_serving_sizes[0].servings_per_container.toFixed(1) });
              }
              else {
                this.setState({ servings_per_container: "1.0" });
              }
            } else {
              this.setState({ servings_per_container: "1.0" });
            }
          }


          var final_arr_dup = [];
          $.each(kk, function (i, el) {
            if ($.inArray(el, final_array) === -1) final_array.push(el);
            final_arr_dup.push(el);
          });
          window.localStorage.setItem('total_pills', final_arr_dup);
        })
        .catch(error => {
          Header.stopLoader();
          Header.apiErrors(error);
          Sentry.captureException(error);
        });

    }
    else {
      var prev_val = Math.ceil(window.edit_total_pill_icons);
      var val = $(".weight_validate").val();
      var edit_pills_array = [];
      var final_pills = [];

      var total_length = $('.un_assign img').length;

      for (var dd = 0; dd < total_length; dd++) {
        edit_pills_array.push($('.un_assign').find('img:eq(' + dd + ')').attr('name'));
      }


      final_pills = edit_pills_array.filter(function (item, index, inputArray) {
        return inputArray.indexOf(item) === index;
      });

      if ($(".weight_validate").val() > 0) {
        if (val > 1 && window.localStorage.getItem('downClick') === 'true') {
          var tst = $('.un_assign').html();
          $(tst).appendTo('.un_assign');
          localStorage.removeItem('downClick')
        }
        else {
          for (var qq = 0; qq < final_pills.length; qq++) {
            var tt = $('.un_assign').find('img:eq(0)');
            var pp = $('.un_assign').find('img:eq(0)').attr('name');
            var icon_length = Math.ceil($('.un_assign').find('img[name="' + pp + '"]').length / prev_val) * val;
            $('.un_assign').find('img[name="' + pp + '"]').remove();
            for (var cc = 0; cc < icon_length; cc++) {
              if (cc < 1) {
                tt.appendTo('.un_assign');
              }
              else {
                $('.un_assign').find('img:last').clone().appendTo('.un_assign');
              }
            }
            cc = 0;

          }
        }



      }
      var gg = $(".weight_validate").val().split('');
      if (parseInt($(".weight_validate").val()) > 9 || $(".weight_validate").val() === "" || $(".weight_validate").val() === "0" || gg[1] === "0" || (gg[0] === 9 && gg[1] === ".")) {
        if ($(".weight_validate").val() === "0") {
          /*this.setState({serving_size: ''});*/
          $('.un_assign').empty();
          $('#create-diet_history').addClass('pointer_none');
          $('#create-diet_history').find('input').addClass('btn disabled');
        }
        else {
          $(".weight_validate").val('');
          this.setState({ serving_size: '' });
          $('.un_assign').empty();
          $('#create-diet_history').addClass('pointer_none');
          $('#create-diet_history').find('input').addClass('btn disabled');
        }
      }
      else {
        $('#create-diet_history').removeClass('pointer_none');
        $('#create-diet_history').find('input').removeClass('btn disabled');
        if ($(".weight_validate").val().length > 4) {
          this.setState({ serving_size: $(".weight_validate").val().slice(0, 4) });
        }
      }

      var calories;


      if (localStorage.getItem('consumable_type') !== "CustomFood") {
        calories = ($(".weight_validate").val() * this.state.food.calories) / this.state.actaul_serving;
      }
      else {
        calories = $(".weight_validate").val() * this.state.food.calories
      }

      $(".cal_count").text(parseInt(calories) + " Calories");

    }

    window.edit_total_pill_icons = $(".weight_validate").val();
  }

  withoutLogging = () => {
    this.props.history.push('/log_food')
  }


  submitFormEdit = () => {
    var serving_size = $(".weight_validate").val();
    var food_category_custom_foods = [];
    var fill_cats;
    if (window.localStorage.getItem('new_assigned_date') !== '' && window.localStorage.getItem('new_assigned_date') !== null && window.localStorage.getItem('new_assigned_date') !== undefined) {
      fill_cats = window.food_cat_array
      localStorage.removeItem('new_assigned_date');
    } else {
      fill_cats = this.refs.unassigned_child.state.food_icon_array
    }

    var filled_cat = fill_cats.map(function (item) { return item.name; })
    var count = $('.un_assign img').length;

    for (var i = 0; i < filled_cat.length; i++) {
      for (var j = 0; j < count; j++) {
        if ($('.un_assign img:eq(' + j + ')').attr('src').indexOf(filled_cat[i]) > 0) {
          $('.un_assign img:eq(' + j + ')').removeClass(filled_cat[i]);
        }
      }
    }


    for (var i = 0; i < filled_cat.length; i++) {
      window.initial_food_val = filled_cat[i];
      var count = $('.un_assign img').length;
      for (var j = 0; j < count; j++) {
        if ($('.un_assign img:eq(' + j + ')').attr('src').indexOf(filled_cat[i]) > 0) {
          $('.un_assign img:eq(' + j + ')').addClass(filled_cat[i]);
          var kk = $('.' + filled_cat[i]).length;
          var veg_foods = food_category_custom_foods.filter(function (obj) { return obj.name === window.initial_food_val });
          if (kk > 1 && veg_foods !== '') {
            veg_foods[0].value = kk + ".0";
          }
          else {
            food_category_custom_foods.push({ "name": window.initial_food_val, "value": kk + '.0' });
          }

        }
      }
    }
    if (this.state.startDateEditFood != true && this.state.startDate == window.localStorage.getItem('assigned_date') && $('.select_replace i').attr('id') != window.localStorage.getItem('meal_occasion_id')) {
      if (window.localStorage.getItem('state') != "UNUSED" && window.localStorage.getItem('singleLog') == "true") {
        var diet_data = {
          "diet_history": {
            "consumable_id": window.localStorage.getItem('consumable_id'),
            "consumable_type": window.localStorage.getItem('consumable_type'),
            "assigned_date": moment($('.edit_food_page').find('.react-datepicker__input-container input').val()).format('YYYY-MM-DD'),
            "meal_occasion_id": $('.select_replace i').attr('id'),
            "serving_size": serving_size,
            "food_categories": food_category_custom_foods,
            "default_meal_occasion_id": window.localStorage.getItem('meal_occasion_id'),
            "meal_occasion_id_from": window.localStorage.getItem('meal_occasion_id'),
            "meal_occasion_id_to": $('.select_replace i').attr('id'),
            "state": window.localStorage.getItem('state')
          }
        }
      }
      else {
        var diet_data = {
          "diet_history": {
            "consumable_id": window.localStorage.getItem('consumable_id'),
            "consumable_type": window.localStorage.getItem('consumable_type'),
            "assigned_date": moment($('.edit_food_page').find('.react-datepicker__input-container input').val()).format('YYYY-MM-DD'),
            "meal_occasion_id": $('.select_replace i').attr('id'),
            "serving_size": serving_size,
            "food_categories": food_category_custom_foods,
            "default_meal_occasion_id": window.localStorage.getItem('meal_occasion_id'),
            "meal_occasion_id_from": window.localStorage.getItem('meal_occasion_id'),
            "meal_occasion_id_to": $('.select_replace i').attr('id'),
            "state": window.localStorage.getItem('state'),
            "food_category": window.localStorage.getItem('food_category')
          }
        }
      }

    } else {
      var diet_data = {
        "diet_history": {
          "consumable_id": window.localStorage.getItem('consumable_id'),
          "consumable_type": window.localStorage.getItem('consumable_type'),
          "assigned_date": moment($('.edit_food_page').find('.react-datepicker__input-container input').val()).format('YYYY-MM-DD'),
          "meal_occasion_id": $('.select_replace i').attr('id'),
          "serving_size": serving_size,
          "food_categories": food_category_custom_foods,
        }
      }
    }


    window.localStorage.setItem('assigned_date', moment($('.edit_food_page').find('.react-datepicker__input-container input').val()).format('YYYY-MM-DD'));
    var api = window.API_URL + "v1/diet_histories/" + window.localStorage.getItem('dh_id');
    var method = "PUT";
    if ($('.un_assign .filled_icon').length > 0) {
      Header.startLoader();
      const options = {
        url: api,
        method: method,
        credentials: 'same-origin',
        headers: Header.setAuthHeaders(),
        data: diet_data
      };

      axios(options)
        .then(response => {
          Header.stopLoader();
          localStorage.removeItem('from_createfood');
          this.props.history.push('/journal');
        })
        .catch(error => {
          Header.stopLoader();
          Header.apiErrors(error);
          Sentry.captureException(error);
        });
    }
    else {
      $('#unassigned_popup').modal('show');
      $('.unassigned_popup').hide();
      $('.unassigned_food').show();
    }
  }

  setEditFoodSession = () => {
    window.localStorage.setItem('setEditfood', true);
  }

  removeFood = () => {

    var dh_id = window.localStorage.getItem('dh_id');

    const options = {
      url: window.API_URL + 'v1/diet_histories/' + dh_id,
      method: 'DELETE',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        this.props.history.push('/journal');
      })
      .catch(error => {
        this.props.history.push('/journal');
        Sentry.captureException(error);
      });



  }

  copyFoodDates = () => {
    function compare(dateTimeA, dateTimeB) {
      var momentA = moment(dateTimeA, "DD/MM/YYYY");
      var momentB = moment(dateTimeB, "DD/MM/YYYY");
      if (momentA > momentB) return 1;
      else if (momentA < momentB) return -1;
      else return 0;
    }
    $('.meal_copy_error').text('');
    var to_date = moment(window.localStorage.getItem('assigned_date')).format('DD/MM/YYYY');
    var current_date = moment().format('DD/MM/YYYY');


    if (compare(to_date, current_date) === 0) {

      $('.copy_to_date').text(moment(window.localStorage.getItem('assigned_date')).format('MMM DD'))
    }
    else {
      if (compare(to_date, current_date) < 0) {
        setTimeout(function () {
          this.setState({ edit_copy_food: moment(window.localStorage.getItem('todays_date')) });
          $('.copy_to_date').text(moment($('#edit_copy_food .react-datepicker__input-container input').val()).format('MMM DD'));
          $('#edit_copy_food .today_date').text(moment($('#edit_copy_food .react-datepicker__input-container input').val()).format('MMM DD'))
        }.bind(this), 150);
      }
      else {
        setTimeout(function () {
          this.setState({ edit_copy_food: addDays(window.localStorage.getItem('assigned_date'), 1) })
          var kk = addDays(window.localStorage.getItem('assigned_date'), 1);

          $('.copy_to_date').text(moment(kk).format('MMM DD'));
          $('#edit_copy_food .today_date').text(moment($('#edit_copy_food .react-datepicker__input-container input').val()).format('MMM DD'))

        }.bind(this), 150);
      }
    }

  }

  componentDidMount() {

    Header.changeDate(moment(window.localStorage.getItem('assigned_date')));
    $(document).on(".input", ".serving_size", function (e) {
      this.value = this.value.replace(/[^0-9.]/g, '');
    }.bind(this));

    $(document).on('change click', 'select#serving_option,.edit_food_page .meal_list_img:not(#edit_copy_food .meal_list_img),.submit_food_icons', function (e) {

      $('.logit').show();
      $('.edit_food_copy').hide();


    });

    $(document).on('keyup change', '.edit_food_page .serving_size', function () {

      $('.logit').show();
      $('.edit_food_copy').hide();

    })

    if (window.localStorage.getItem('consumable_type') === "CustomFood") {
      $('.custom_food_edit').show();
    }

    if (window.localStorage.getItem('from_createfood') === "true") {
      $('#custom_without_log .without_log').show();
      $('.back_search').hide();
    }
    $(document).on('click', '.edit_food_page .edit_food_edit_txt', function () {

      $('.logit').show();
      $('.edit_food_copy').hide();

    })

    var fill_array = [];
    $(document).on("click", ".edit_food_edit_txt", function (e) {

      if ($(".weight_validate").val() !== '1') {
        $('#count_as_validate').modal('show');
      } else {
        $('#unassigned_popup').modal('show');
      }
      $('.food_count_images').empty();
      $('#unassigned_popup .filled_icon').remove();
      $('.unassigned_popup').show();
      /* key strings*/
      $('.unassigned-food-categories-ks').text(window.keystrings['com.numi.edit_food_categories'])
      $('.unassigned_text').text(window.keystrings['com.numi.unassigned_text'])
      $('.unassigned-food-categories-done-ks').text(window.keystrings['com.numi.done']);
      $('.unassigned-food-categories-msg-ks').text(window.keystrings['com.numi.edit_food_categories_description']);
      /*End key string*/
      $('.unassigned_food').hide();
      fill_array = [];
      for (var i = 0; i < $('.un_assign img').length; i++) {

        if ($('.un_assign img').length) {
          fill_array.push(document.getElementsByClassName("filled_icon")[i].getAttribute("value"));
          var dd = document.getElementsByClassName(fill_array[i])[0].cloneNode(true);
          var ff = $('#unassigned_popup li[value=' + fill_array[i] + ']');
          ff.prepend(dd);
          var kk = ff.attr('id');
          var jj = $('img.' + fill_array[i] + '').attr('src').replace('SMALL', 'LARGE');
          $('#unassigned_popup').find('img.' + fill_array[i] + '').attr('src', jj);
          var parend_li = ff.find('.filled_icon').length;
          $('#unassigned_popup .un_assign_txt').hide();
          if ($('#unassigned_popup .unassigned_popup').find('.' + kk + '').length > 0) {
            $('#unassigned_popup .unassigned_popup').find('.' + kk + '').find('.current_food_count span').text(parend_li);
          }
          else {

            $('#unassigned_popup .food_count_img .food_count_images').append('<span class=' + kk + '><span class="current_food_count"> <span> ' + parend_li + '</span></span>' + kk + '<span class="comma">, </span> </span>');

          }
          if ($('#unassigned_popup .unassigned_popup').find('.food_count_images span').length >= 1) {
            $('.selected_food_txt').remove();
            $('#unassigned_popup .food_count_img').append('<span class="selected_food_txt">selected</span>')
          }
          else {
            $('.selected_food_txt').remove();
          }



        }
      }
      if (($('.current_food_count:last-of-type').length > 0) && ($('.current_food_count:last-of-type').indexOf(',') > 0)) {
        $('.current_food_count:last-of-type').replace(',', '');
      }

      if ($('.comma').length === 1) {
        $('.comma').hide();
      }
      else {
        $('.comma:last').hide();
      }
    });


    setTimeout(function () {
      $("#food_help").modal("hide");
    }, 500);

    var assigned_date = window.localStorage.getItem('assigned_date');
    var dh_id = window.localStorage.getItem('dh_id');
    var kk = [];
    var final_array = [];
    Header.startLoader();

    const options = {
      url: window.API_URL + "v1/diet_histories/validate?assigned_date=" + assigned_date + "&diet_history_id=" + dh_id,
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {

        window.localStorage.setItem('called_validate', 'true');
        Header.stopLoader();
        localStorage.removeItem('searched_term');
        if (response && response.data) {

          this.setState({ actaul_serving: response.data.valid_food_details.diet_history.serving_size, food: response.data.valid_food_details.diet_history, nutrient_fact_calories: parseInt(this.state.food.calories) / parseInt(this.state.food.serving_size), nutrients: response.data.valid_food_details.diet_history.nutrients, serving_size_unit: response.data.valid_food_details.diet_history.serving_unit, serving_size: response.data.valid_food_details.diet_history.serving_size, s3_images_path: response.data.valid_food_details.s3_images_path });
          window.localStorage.setItem('consumable_id', response.data.valid_food_details.diet_history.consumable_id);
          window.localStorage.setItem('consumable_type', response.data.valid_food_details.diet_history.consumable_type);

          this.setState({ favorite: response.data.valid_food_details.diet_history.favorite_foods })
          this.setState({ add_to_fav: this.state.favorite === true ? 'Remove from Favorites' : 'Add to Favorites' })
          this.setState({ add_fav_off: this.state.favorite === true ? '/assets/images/fav_on.png' : '/assets/images/fav_off.png' })
          $('.un_assign').text('');
          $(".ser_unit").html('<span className="pull-right"><span className="set_ser_size"> </span> ' + this.state.serving_size_unit + '</span>');
          for (var i = 0; i < response.data.valid_food_details.diet_history.food_categories.length; i++) {
            var name = response.data.valid_food_details.diet_history.food_categories[i].name;
            var img_name = "/" + name + "_SMALL_USED.png";
            var img_type = name.toLowerCase() + "_unfill"
            var img_src = response.data.valid_food_details.s3_images_path + "/"
            var count = parseFloat(response.data.valid_food_details.diet_history.food_categories[i].value).toFixed();
            for (var c = 0; c < count; c++) {

              $('.un_assign').append('<img alt=""name=' + response.data.valid_food_details.diet_history.food_categories[i].name + '  class="filled_icon cursor_pointer ' + img_type + '" alt="" value=' + img_type + ' src=' + img_src + name + img_name + '>');
              kk.push('<img alt="" name=' + response.data.valid_food_details.diet_history.food_categories[i].name + '  class="filled_icon cursor_pointer ' + img_type + '" value=' + img_type + ' alt=""  src=' + img_src + name + img_name + '>');
            }

          }
          if (response.data.valid_food_details.diet_history.food_serving_sizes !== undefined) {
            if (response.data.valid_food_details.diet_history.food_serving_sizes !== null) {
              if (response.data.valid_food_details.diet_history.food_serving_sizes.length > 0) {
                this.setState({ servings_per_container: response.data.valid_food_details.diet_history.food_serving_sizes[0].servings_per_container.toFixed(1) });
              } else {
                this.setState({ servings_per_container: "1.0" });
              }
            } else {
              this.setState({ servings_per_container: "1.0" });
            }
          }


          var final_arr_dup = [];
          $.each(kk, function (i, el) {
            if ($.inArray(el, final_array) === -1) final_array.push(el);
            final_arr_dup.push(el);
          });
          window.localStorage.setItem('total_pills', final_arr_dup);
          window.localStorage.setItem('selected_food_categories_api', true)
        }
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });



    /*keystrings*/
    $('.back_to_journal_text').append(window.keystrings['com.numi.back_to_journal']);
    $('.create_food_edit_text').text(window.keystrings['com.numi.create_food_edit'])
    $('.serving_size_text').text(window.keystrings['com.numi.serving_size'])
    $('.food_details_number_of_servings_text').text(window.keystrings['com.numi.food_details_number_of_servings'])
    $('.create_food_counts_as_text').prepend(window.keystrings['com.numi.create_food_counts_as'])
    $('.food_details_meal_text').text(window.keystrings['com.numi.food_details_meal'])
    $('.food_details_date_text').text(window.keystrings['com.numi.food_details_date'])
    $('.save_changes_text').text(window.keystrings['com.numi.save_changes']);
    $('.edit_copy_food_click').text(window.keystrings['com.numi.copy'])
    $('.remove_text').text(window.keystrings['com.numi.remove'])
    $('.nutrition_facts_text').text(window.keystrings['com.numi.nutrition_facts'])
    $('.serving_per_container_text').text(window.keystrings['com.numi.food.serving_per_container_new'])
    $('.amount_per_serving_text').text(window.keystrings['com.numi.amount_per_serving'])
    $('.amount_text').text(window.keystrings['com.numi.amount'])
    $('.copy_food_text').text(window.keystrings['com.numi.copy_food'])
    $('.this_food_from_text').prepend(window.keystrings['com.numi.this_food_from'])
    $('.copy_food_val').attr('value', window.keystrings['com.numi.copy_food']);
    $('.unassigned-food-ks').text(window.keystrings['com.numi.food.unassigned_food']);
    $('.food-unassigned-popup-msg-ks').text(window.keystrings['com.numi.food.unassigned_msg']);
    $('.food-assign-food-category-ks').text(window.keystrings['com.numi.assign_food_category']);
    $('.food-create-as-other-ks').text(window.keystrings['com.numi.food.create_as_other']);
    /*keystrings*/

    setTimeout(function () {
      window.edit_total_pill_icons = $(".weight_validate").val();
    }, 1500);

  }


  render() {

    var nutrient_options = this.state.nutrients.map(function (option, i) {
      if (option.display_name === "Calories") {
        $('.cal_val').text(Math.ceil(option.value))
      }
      return (
        <div className="facts_sec" key={i}><div className="pull-left">{option.display_name}</div> <div className="text-right ull-right">{option.value} {option.units}</div></div>
      )
    });


    return (
      <div>
        <div className="table_display">

          <div className="food_details_div edit_food_page">

            <div className="section_left pull-left">
              <Navigation />
            </div>
            <div className="section_right pull-left">
              <figure><Adspeed /></figure>
              <div className="journal_rht">
                <p className="mb back_search">
                  <Link to="/journal" className="back_to back_to_journal_text"><img alt="" src="/assets/images/back_white_icon.png" className="vlb" /> </Link>
                </p>


                <h1 className="journal_hdr pull-left width70">{this.state.food.food_name}<span className="font18 color5 d_block">{this.state.food.best_for_you === true ? <img alt="" src='/assets/images/best_for_big.png' style={{ marginRight: '10px' }} /> : ''}<span className="cal_count">{(this.state.food.consumable_type === "CustomFood" ? Math.round(this.state.food.calories * this.state.food.serving_size) : this.state.food.calories) + ' ' + window.keystrings['com.numi.calories']}</span> </span></h1>

                <div className="pull-right mt add_fav font15 color5 cursor_pointer" onClick={this.addFavorite.bind(this)}><img alt="" src={this.state.add_fav_off} /> {this.state.add_to_fav}</div>

                <div className="pull-right mt font15 color5 custom_food_edit"></div>

                <div className="clear"></div>
                <hr className="mt2" />

                <div className="clear"></div>
                <div className="panel-group meal_section_left food_details_ul pull-left">
                  <p className="error serving_error font14"></p>
                  <ul>
                    <li><span className="serving_size_text"></span><span className="text-right"><span className="ser_unit"></span></span></li>
                    <li><span className="food_details_number_of_servings_text"></span><span className="text-right mobile_relative"><input type="number" step="0.5" min="0.5" max="9" readOnly className="serving_size weight_validate" value={this.state.serving_size} maxLength="3" onChange={this.handleInputEditFoodChange} ref='serving_size' /><div className="mobile_number_arrows"><div className="mobile_arrows_font"><img alt="" onClick={this.EditArrowUp} src="/assets/images/top_arrow.png" /></div><div className="mobile_arrows_font"><img alt="" src="/assets/images/bottom_arrow.png" onClick={this.EditArrowDown} /></div></div></span></li>
                    <li><span className="create_food_counts_as_text"><sup><img alt="" src="/assets/images/education.png" onClick={() => this.showEditfoodHelp('FOOD_DETAILS')} className="cursor_pointer edit_food_details_help" /></sup></span><span className="text-right font14"><span className="unassigned_hidden hide"></span><span className="text-right un_assign font14">Unassigned &nbsp;&nbsp;</span><span className="text-right edit_txt edit_food_edit_txt font14 cursor_pointer vlt create_food_edit_text"></span>
                    </span></li>
                    <li><span className="food_details_meal_text"></span><span className="text-right"><MealDropdown ref="get_meal_data" /></span></li>
                    <li><span className="food_details_date_text"></span><span className="text-right relative"><DatePicker onChange={this.editDatechange} selected={parse(this.state.startDate)} minDate={parse(window.localStorage.getItem('profile_start_date'))} maxDate={addDays(new Date(), 90)} /></span></li>
                  </ul>
                  <p className="logit"><span onClick={this.submitFormEdit} id="create-diet_history" className="display_block mt5">
                    <button type="submit" value="" className="copy_save_button save_changes_text"></button>
                  </span></p>

                  <p>
                    <button type="submit" className="font18 edit_food_copy mr3  edit_copy_food_click" onClick={this.copyFoodDates} data-target="#edit_copy_food" data-toggle="modal"> </button>
                    <button type="submit" className="font18 edit_food_copy remove_food remove_text" onClick={this.removeFood}></button>
                  </p>

                </div>
                <div className="meal_section_right pull-right">

                  <div className="nutrition-facts-wrapper" style={{ display: this.state.food.food_type !== 'recipe' ? 'block' : 'none' }}>
                    <div className="nutri_facts_hdr facts_sec nutrition_facts_text"></div>
                    <div className="divider facts_sec">
                      {window.keystrings['com.numi.serving_size'] + ' ' + this.state.food.serving_unit}
                    </div>
                    <div className="ser_divider facts_sec">
                      <span className='serving_per_container_text'></span>
                      <span> {this.state.servings_per_container}</span>
                    </div>
                    <div className="facts_sec">
                      <div className="amount amount_per_serving_text"></div>
                    </div>
                    <div className="sm-divider facts_sec">
                      Calories <span className="cal_val"></span>
                    </div>
                    <div className="text-right facts_sec amount_text">

                    </div>
                    {nutrient_options}

                  </div>
                </div>
              </div>
              <Footer />
              <FoodHelp ref="callHelp" />
              <CountAsEditValidate callParentEditHandleInput={this.handleInputEditFoodChange} />

              <UnassinedPopup ref="unassigned_child" get_s3_path={this.state.s3_images_path} />
              <CopyFood get_edit_copy_food={this.state.edit_copy_food} serving_size={this.state.serving_size} s3_path={this.state.s3_images_path} {...this.props} />
            </div>

          </div>
        </div>
      </div>
    )
  }
}




class CopyFood extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      date: moment(),
      copy_date: addDays(new Date(), 1),
    }
  }
  editFoodSubmit = () => {

    Header.startLoader();
    var serving_size = $('.weight_validate').val();
    var food_category_custom_foods = [];
    var fill_cats;
    if (window.localStorage.getItem('new_assigned_date') !== '' && window.localStorage.getItem('new_assigned_date') !== null && window.localStorage.getItem('new_assigned_date') !== undefined) {
      fill_cats = window.food_cat_array
      localStorage.removeItem('new_assigned_date');
    } else {
      fill_cats = this.refs.unassigned_child.state.food_icon_array
    }
    var filled_cat = fill_cats.map(function (item) { return item.name; })
    for (var i = 0; i < filled_cat.length; i++) {
      var count = $('.un_assign img').length;
      for (var j = 0; j < count; j++) {
        window.actual_food_val = filled_cat[i];
        if ($('.un_assign img:eq(' + j + ')').attr('src').indexOf(filled_cat[i]) > 0) {
          $('.un_assign img:eq(' + j + ')').addClass(filled_cat[i]);
          var kk = $('.' + filled_cat[i]).length;
          if (kk > 1) {
            var veg_foods = food_category_custom_foods.filter(function (obj) { return obj.name === window.actual_food_val });
            veg_foods[0].value = kk + ".0";
          }
          else {
            food_category_custom_foods.push({ "name": window.actual_food_val, "value": kk + '.0' });
          }
        }
      }
    }
    var data = {
      "diet_histories": [{
        "consumable_id": window.localStorage.getItem('consumable_id'),
        "consumable_type": window.localStorage.getItem('consumable_type'),
        "serving_size": serving_size,
        "meal_occasion_id": $('#edit_copy_food .select_replace i').attr('id'),
        "assigned_date": moment($('#edit_copy_food').find('.react-datepicker__input-container input').val()).format('YYYY-MM-DD'),
        "food_categories": food_category_custom_foods
      }]
    }

    const options = {
      url: window.API_URL + 'v1/diet_histories/copy_foods?copy_food=true',
      method: 'POST',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
      data: data
    };

    axios(options)
      .then(response => {
        Header.stopLoader();
        window.localStorage.setItem('assigned_date', moment($('#edit_copy_food').find('.react-datepicker__input-container input').val()).format('YYYY-MM-DD'));
        $('.modal-backdrop').remove();
        $('body').removeClass('modal-open')
        this.props.history.push('/journal');
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
      });
  }

  handleChange = (date) => {
    this.setState({
      copy_date: date
    });
    var complete_date = moment(date).format('MMM DD');
    var actual_date = moment().format('MMM DD');
    var pageload_selecetd_date = moment(date).format('MMM DD');
    var actual_date_format = moment(date).format('YYYY-MM-DD');
    var kk;
    if (pageload_selecetd_date === actual_date) {
      kk = moment(date).format('MMM DD');
      $('#edit_copy_food  .today_date').show();
      $('#edit_copy_food  .today_date').text('Today');
      $('#edit_copy_food .copy_to_date').text(kk);
    }
    else {
      kk = moment(date).format('MMM DD');
      $('#edit_copy_food  .today_date').show();
      $('#edit_copy_food .today_date').text(complete_date);
      $('#edit_copy_food .copy_to_date').text(kk);
    }
    Header.changeDate(date);
    const options = {
      url: window.API_URL + "v1/phase_templates/assigned_date/" + actual_date_format,
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        if (response.data.key !== undefined) {
          if (response.data.key === window.localStorage.getItem('phase_template_key')) {
            $('.copy_error').text('');
          }
          else {
            $('.copy_error').text(kk + window.keystrings['com.numi.copy_food_to_different_phase_warning']);
          }
        }
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
      });
    this.copyMealChange();
  }
  copyMealChange = () => {


    setTimeout(function () {
      this.refs.copy_meal_data.callMealData();
    }.bind(this), 155);

  }


  componentDidMount() {


    function compare(dateTimeA, dateTimeB) {
      var momentA = moment(dateTimeA, "DD/MM/YYYY");
      var momentB = moment(dateTimeB, "DD/MM/YYYY");
      if (momentA > momentB) return 1;
      else if (momentA < momentB) return -1;
      else return 0;
    }
    $("#edit_copy_food").on("shown.bs.modal", function () {


      var kk = moment(window.localStorage.getItem('assigned_date')).format('DD/MM/YYYY');
      var gg = moment().format('DD/MM/YYYY');


      if (compare(kk, gg) < 0) {
        this.setState({
          copy_date: new Date()
        });
        $('#edit_copy_food .copy_to_date').text(moment(this.state.copy_date).format('MMM DD'));
        $('#edit_copy_food  .today_date').text(window.keystrings['com.numi.today']);

      }
      else {
        if (compare(kk, gg) === 0) {
          this.setState({
            copy_date: addDays(window.localStorage.getItem('assigned_date'), 1)
          });
          $('#edit_copy_food .copy_to_date').text(moment(this.state.copy_date).format('MMM DD'));
          $('#edit_copy_food  .today_date').text(moment(this.state.copy_date).format('MMM DD'));
          Header.changeDate(this.state.copy_date);
        }

        else {
          this.setState({
            copy_date: addDays(window.localStorage.getItem('assigned_date'), 1)
          });
          $('#edit_copy_food .copy_to_date').text(moment(this.state.copy_date).format('MMM DD'));
          $('#edit_copy_food  .today_date').text(moment(this.state.copy_date).format('MMM DD'));
          Header.changeDate(this.state.copy_date);
        }
      }

    }.bind(this));
    this.copyMealChange();

    $("#edit_copy_food").on("hidden.bs.modal", function () {
      this.setState({
        startDate: addDays(new Date(), 1)
      });
    }.bind(this));

  }


  render() {
    return (

      <div className="modal fade" id="edit_copy_food">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><img alt="" src="/assets/images/close_grey.png" /></button>
            </div>
            <div className="modal-body">
              <div className="copy_food_valid">
                <h3 className="modal-title copy_food_text">Copy Food</h3>
                <p className="font15 this_food_from_text"> <b>{moment(window.localStorage.getItem('assigned_date')).format('MMM DD')}</b> will be copied to <b className="copy_to_date"></b></p>
                <div className="relative date_pick">
                  <span className="left_sec font15 food_details_date_text"></span>
                  <DatePicker onChange={this.handleChange} minDate={parse(window.localStorage.getItem('profile_start_date'))} maxDate={addDays(new Date(), 90)} selected={parse(this.state.copy_date)} />
                </div>
                <div className="relative date_pick">
                  <span className="left_sec font15 food_details_meal_text"></span>
                  <MealDropdown ref="copy_meal_data" />
                </div>
                <p className="error copy_error font14"></p>
                <p className="error meal_copy_error text-center font14"></p>
                <input type="submit" onClick={this.editFoodSubmit} className="copy_meal edit_copy_meal_food mt6 copy_food_val" value="Copy Food" />
              </div>
            </div>
          </div>
          <EditUnassinedPopup ref="unassigned_child" get_s3_path={this.props.s3_path} />
        </div>
      </div>
    )
  }
}


export default EditFood;
