
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import * as Sentry from "@sentry/react";
import Header from "../header.js";
import axios from 'axios';

const ActivitySection = (props) => {
    const history = useHistory();
    const [yesterdayActivityPopup, setYesterdayActivityPopup] = useState(false);
    const [unlogActivityPopup, setUnlogActivityPopup] = useState(false);
    const [activityLogId, setActivityLogId] = useState("");
    const [logTime, setLogTime] = useState("");
    const [logTimeError, setLogTimeError] = useState("");
    const [logTimePopup, setLogTimePopup] = useState(false);
    const [assignedDate] = useState(window.localStorage.getItem('assigned_date'));
    const [yesterdayActivityPopupText, setYesterdayActivityPopupText] = useState("");

    function onHideYesterdayActivityPopup() {
        setYesterdayActivityPopup(false);
    }

    function onHideUnlogActivityPopup() {
        setUnlogActivityPopup(false);
    }

    function onHideLogTimePopup() {
        setLogTimePopup(false);
    }

    function copyYesterdayActivity() {
        var date = new Date(window.localStorage.getItem('assigned_date'));

        var to_date = (date.getFullYear()) + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
        var from_date_old = new Date(date.setDate(date.getDate() - 1));
        var from_date = (('0' + (from_date_old.getMonth() + 1)).slice(-2) + '-' + ('0' + from_date_old.getDate()).slice(-2) + '-' + from_date_old.getFullYear()).split('-');
        from_date = from_date[2] + '-' + from_date[0] + '-' + from_date[1];
        var activity_histories = { activity_history: { from_date, assigned_date: to_date } };
        const options = {
            url: window.API_URL + 'v1/activity_histories/copy_by_date',
            method: 'POST',
            credentials: 'same-origin',
            headers: Header.setAuthHeaders(),
            data: activity_histories
        };

        axios(options)
            .then(response => {
                props.getJournal();
            })
            .catch(error => {
                Header.stopLoader();
                if (error && error.response && error.response.data && error.response.data.error) {
                    setYesterdayActivityPopup(true);
                    setYesterdayActivityPopupText(error.response.data.error);
                }
                else if (error && error.response && error.response.status > 400) {
                    setYesterdayActivityPopup(true);
                    setYesterdayActivityPopupText(error.response.statusText);
                }
                Sentry.captureException(error);
            });
    }

    function logActivityTime() {
        var assigned_date = new Date(assignedDate);
        var log_time = logTime;
        var logtime_params = { "activity_history": { "assigned_date": assigned_date, "duration": log_time, "doable_type": "QuickLog", "doable_id": 17 } }
        Header.startLoader();


        const options = {
            url: window.API_URL + "v1/activity_histories",
            method: 'POST',
            headers: Header.authorization_headers(),
            data: logtime_params
        };
        axios(options)
            .then(response => {

                Header.stopLoader();
                setLogTimePopup(false);
                props.getJournal();

            })
            .catch(error => {
                Header.stopLoader();
                setLogTimeError(error.response.data.error)
                Sentry.captureException(error);
            });
    }

    function unlogActivity() {
        localStorage.removeItem('delete_quick_log');
        Header.startLoader();
        const options = {
            url: window.API_URL + 'v1/activity_histories/' + activityLogId,
            method: 'DELETE',
            credentials: 'same-origin',
            headers: Header.setAuthHeaders(),
        };

        axios(options)
            .then(response => {
                setUnlogActivityPopup(false)
                props.getJournal();
                Header.stopLoader();
            })
            .catch(error => {
                setUnlogActivityPopup(false);
                props.getJournal();
                Header.stopLoader();
                Sentry.captureException(error);
            });
    }

    return (
        <>
            <div className="meal_right_con activity_section">
                <div className="meal_right_sec_gap header cursor_pointer">
                    <h5 className="pull-left">
                        <span className="numi_activity">{window.keystrings['com.numi.activity']}</span><span className="daily_goal daily_goal_activity">&nbsp;{props.activity_goal === null || props.activity_goal === undefined ? ('')
                            : (`${window.keystrings['com.numi.journal.daily_goal']} : ${props.activity_goal} min`)}</span>
                    </h5>
                    <span className="pull-right dropdown cursor_pointer activity_active ">
                        <img alt="" src="/assets/images/plus_icon.png" data-toggle="dropdown" />
                        <ul className="dropdown-menu journal_empty_options align_indicator">
                            <li>
                                <Link to="/log_activity">
                                    <span className="log_activity_option_log_additional_activity">
                                        {window.keystrings['com.numi.log_activity_option_log_additional_activity']}
                                    </span>
                                </Link>
                            </li>
                            <li>
                                <span onClick={() => { copyYesterdayActivity() }}>
                                    <span className="log_activity_option_log_yesterday_activity">
                                        {window.keystrings['com.numi.log_activity_option_log_yesterday_activity']}
                                    </span>
                                </span>
                            </li>
                            <li>
                                <span>
                                    <span className="log_activity_option_log_copy_all_activity"></span>
                                </span>
                            </li>
                        </ul>
                    </span>
                </div>
                <div className="meal_right_sec_gap activity_details pt0 pb0">
                    {
                        props.activity_data ? (
                            props.activity_data.map((option, i) => {
                                var callout_image = ''
                                if (option.callout_icon === "PLUS") {
                                    callout_image = <img alt="" className="cursor_pointer activity_plus_icon" src="/assets/images/plus_icon.png" onClick={() => {
                                        history.push({ pathname: "/log_activity" });
                                    }} />;
                                    var delete_class = ''
                                }
                                else if (option.callout_icon === "CARET") {
                                    callout_image = <><img alt="" className="cursor_pointer activity_caret_icon" id={option.id} src="/assets/images/arrow_right_grey.png" onClick={() => {
                                        var id = option.id;
                                        window.localStorage.setItem('create_activity_doable_id', '');
                                        window.localStorage.setItem('create_activity_doable_type', '');
                                        window.localStorage.setItem('item_clicked_header', '');
                                        window.localStorage.setItem('create_activity_doable_id', option.doable_id);
                                        window.localStorage.setItem('create_activity_doable_type', option.doable_type);
                                        window.localStorage.setItem('item_clicked_header', option.header);
                                        window.localStorage.setItem('activity_history_id', id);
                                        if (option.doable_type === "Activity" || option.doable_type === "CustomActivity") {
                                            history.push('/edit_activity')
                                        }
                                        else {
                                            if (option.doable_type === "CalculatedActivity") {

                                                history.push('/edit_calculated_activity')
                                            }
                                            else {
                                                history.push('/activity_log')
                                            }
                                        }
                                        history.push({ pathname: "/edit_activity" });

                                    }} /><input type="hidden" id={`activity_caret_icon_id_ ${option.id}`} value={option.id} /><input type="hidden" id={`activity_caret_icon_doable_id_ ${option.id}`} value={option.doable_id} /><input type="hidden" className="cal_activity" id={`activity_caret_icon_doable_type_ ${option.id}`} value={option.doable_type} /><input type="hidden" id={`activity_caret_icon_title_ ${option.id}`} value={option.title} /></>;
                                    delete_class = 'not_quicklog cursor_pointer';
                                }
                                else if (option.callout_icon === "REFRESH") {
                                    callout_image = <img alt="" className="cursor_pointer refresh_activity_sync" src="/assets/images/sync.png" />;
                                    delete_class = 'not_quicklog cursor_pointer external_device'
                                }
                                else if (option.callout_icon === "SEARCH") {
                                    callout_image = <img alt="" className="cursor_pointer activity_search_icon" src="/assets/images/search_small_grey.png" quicklog_activity={option.doable_type} quicklog_id={option.id} onClick={() => {
                                        localStorage.setItem('quicklog_id', option.id);
                                        history.push('/log_activity')
                                    }} />;
                                    delete_class = 'delete_quicklog cursor_pointer'


                                }

                                if (option.read_only === true) {
                                    delete_class += ' device_read_only'
                                }
                                var icon_url = ''

                                return (

                                    <>
                                        {
                                            option.id === null ? (
                                                <div id={i} key={i} className="acitvity_div ptb10 gray_btm_line display_block width100">
                                                    <div className={`get_activity_type font15 pull-left ${delete_class}`} quicklog_activity={option.doable_type} quicklog_id={option.id} >{
                                                        <a href="javascript:void(0)" className="logtimeclear" onClick={() => { setLogTimePopup(true) }}>
                                                            <img alt="" width="48" height="48" src={option.url} />
                                                        </a>}
                                                    </div>
                                                    <div className="pull-left ml10px width65">
                                                        <div className="add_activity font14 bold color7">{option.title}</div>
                                                        <div className="font12  color8 ellipse_text">{option.detail_1}<br /></div>
                                                        <div className="font12 color7 activity_list">{option.detail_2}</div>
                                                    </div>
                                                    <div className="pull-right ml5px">{callout_image}</div></div>
                                            ) : (<div id={i} key={i} className="acitvity_div ptb10 gray_btm_line display_block width100">
                                                <div className={`get_activity_type font15 pull-left ${delete_class}`} quicklog_activity={option.doable_type} quicklog_id={option.id}>{<img alt="" width="48" className="active_activity" height="48" src={option.url}
                                                    onClick={() => { setActivityLogId(option.id); setUnlogActivityPopup(true) }} />}</div>
                                                <div className="pull-left ml10px width65"><div className="add_activity font14 bold color7">{option.title}</div><div className="font12 color8 ellipse_text">{option.detail_1}<br /></div>
                                                    <div className="font12 color7 activity_list">{option.detail_2}</div></div><div className="pull-right ml5px">{callout_image}</div>
                                            </div>
                                            )
                                        }
                                        <div className="clear"></div>

                                    </>
                                )
                            })
                        ) : (<></>)
                    }
                </div>
            </div>
            <Modal show={yesterdayActivityPopup} id="yesterday_activity" onHide={() => { onHideYesterdayActivityPopup() }}>
                <Modal.Header>
                    <button type="button" className="close" onClick={() => { setYesterdayActivityPopup(false) }}>
                        <img alt="" src="/assets/images/close_grey_new_modal.png" />
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-body width77">
                        <img alt="" src="/assets/images/warning_tri.png" />
                        <h2 className="mt color6">{yesterdayActivityPopupText}</h2>
                        {yesterdayActivityPopupText == "" ?
                            <p className="mt6">
                                <button onClick={() => { copyYesterdayActivity() }}>OK</button>
                            </p> :
                            <p className="mt6">
                                <button onClick={() => { setYesterdayActivityPopup(false) }}>OK</button>
                            </p>}
                    </div>

                </Modal.Body>
            </Modal>
            <Modal show={unlogActivityPopup} id="unlog_activity" onHide={() => { onHideUnlogActivityPopup() }}>
                <Modal.Header>
                    <button type="button" className="close" onClick={() => { setUnlogActivityPopup(false) }}>
                        <img alt="" src="/assets/images/close_grey_new_modal.png" />
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-body width77">
                        <img alt="" src="/assets/images/warning_tri.png" />
                        <h2 className="font26 color3 mt5 mb2 font700">{window.keystrings['com.numi.journal.unlog_activity']}</h2>
                        <p className="font16 color6">{window.keystrings['com.numi.journal.unlog_activity_body']}</p>
                        <p className="mt6">
                            <button className="delete_acitivty" onClick={() => { unlogActivity() }}>Yes</button>
                            <button onClick={() => { setUnlogActivityPopup(false) }}>Cancel</button>
                        </p>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={logTimePopup} id="log_time" onHide={() => { onHideLogTimePopup() }}>
                <Modal.Header>
                    <button type="button" className="close" onClick={() => { setLogTimePopup(false) }}>
                        <img alt="" src="/assets/images/close_grey_new_modal.png" />
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-body width77">
                        <p className="log_time_error error_txt">{logTimeError}</p>
                        <h2 className="font26 color3 mt5 mb2 font700">{window.keystrings['com.numi.journal.log_time']}</h2>
                        <form>
                            <p className="color7"><input type="text" value={logTime} onChange={(e) => {
                                setLogTime(e.target.value)
                            }} className="mt5 log_time_input" maxLength="3" />&nbsp;&nbsp; min</p>
                            <p className="mt5">
                                <button className="" onClick={() => { logActivityTime() }}>Save</button>
                            </p>
                        </form>

                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ActivitySection;