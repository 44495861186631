import React from 'react';
import { Link } from 'react-router-dom';
import Footer from "../footer.js";
import Header from "../header.js";
import Navigation from "../nav.js";
import Adspeed from "../adspeed.js";
import Avatar from "../profile/avatar.js";
import axios from 'axios';
import $ from 'jquery';
import * as Sentry from "@sentry/react";

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = { disable_btn: true, disable: 'disabled', new_password: '', confirm_password: '', user_name: window.localStorage.getItem('FirstName'), plan_start_date: window.localStorage.getItem('plan_start_date') };
    this.changeNewPassword = this.changeNewPassword.bind(this);
    this.confirmPassword = this.confirmPassword.bind(this);
    this.checkOldpassword = this.checkOldpassword.bind(this);
    this.validateNewPass = this.validateNewPass.bind(this);
    this.validateConfirmPass = this.validateConfirmPass.bind(this);
    this.matchPassword = this.matchPassword.bind(this);
    this.submitChangePassword = this.submitChangePassword.bind(this);
  }

  changeNewPassword(e) {
    this.setState({
      new_password: e.target.value
    });
  }

  confirmPassword(e) {
    this.setState({
      confirm_password: e.target.value,
      disable_btn: false
    });
  }

  validateNewPass() {
    window.pwd_error = '';
    var password_regex = new RegExp("^(?=.*[a-zA-Z])(?=.*[0-9])(?=\\S+$)(?=.{6,})");
    if (!password_regex.test($('.new_pass').val())) {
      $('#validate_password').modal('show');
      window.pwd_error = window.keystrings['com.numi.invalid_password_error'];
      /*keystrings*/
      $('.password_incorrect_text').text(window.keystrings['com.numi.invalid_password_error']);
      $('.password_incorrect_ok').text(window.keystrings['com.numi.ok_all_caps']);
      /*keystrings*/
    }
    else {
      $('#validate_password').modal('hide');
    }

  }

  validateConfirmPass() {
    var password_regex = new RegExp("^(?=.*[a-zA-Z])(?=.*[0-9])(?=\\S+$)(?=.{6,})");
    if (!password_regex.test($('.confirm_pass').val())) {
      $('#validate_password').modal('show');
      /*keystrings*/
      $('.password_incorrect_text').text(window.keystrings['com.numi.invalid_password_error']);
      $('.password_incorrect_ok').text(window.keystrings['com.numi.ok_all_caps']);
      /*keystrings*/
    }
    else {
      $('#validate_password').modal('hide');
    }
    this.setState({
      disable_btn: false
    })
  }

  matchPassword() {

    if (window.pwd_error === '') {
      if ($('.new_pass').val() !== $('.confirm_pass').val()) {
        $('#match_password').modal('show');
        /*keystrings*/
        $('.password_notmatch_text').text(window.keystrings['com.numi.change_password_match_error']);
        $('.password_notmatch_ok').text(window.keystrings['com.numi.ok_all_caps']);
        /*keystrings*/
      }
      else {

        $('#match_password').modal('hide');
        this.submitChangePassword();
      }
    }
    else {
      $('#validate_password').modal('show');
      /*keystrings*/
      $('.password_incorrect_text').text(window.keystrings['com.numi.invalid_password_error']);
      $('.password_incorrect_ok').text(window.keystrings['com.numi.ok_all_caps']);
      /*keystrings*/
    }
  }

  checkOldpassword() {
    var old_password = true;
    if (old_password === true) {
      $('#check_old_password').modal('show');
      /*keystrings*/
      $('.old_password_incorrect_text').text(window.keystrings['com.numi.old_password_error']);
      $('.old_password_incorrect_ok').text(window.keystrings['com.numi.ok_all_caps']);
      /*keystrings*/
    }
    else {
      $('#check_old_password').modal('hide');
    }
  }

  submitChangePassword() {

    Header.startLoader();
    var old_pwd = $("#old_password").val();
    var new_pwd = $("#new_password").val();
    var comfirm_pwd = $("#confirm_password").val();
    var user_params = { "user": { "current_password": old_pwd, "password": new_pwd, "password_confirmation": comfirm_pwd } };

    const options = {
      url: window.API_URL + "v1/users/change_password",
      method: 'POST',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
      data: user_params
    };

    axios(options)
      .then(response => {
        Header.stopLoader();
        $("#old_password").val('');
        $("#new_password").val('');
        $("#confirm_password").val('');
        window.localStorage.setItem('user_access_token', response.data.user.access_token);
        this.props.history.push('/my_profile');
      })
      .catch(error => {
        Header.stopLoader();
        $('#check_old_password').modal('show');
        /*keystrings*/
        $('.old_password_incorrect_text').text(window.keystrings['com.numi.old_password_error']);
        $('.old_password_incorrect_ok').text(window.keystrings['com.numi.ok_all_caps']);
        /*keystrings*/
        Sentry.captureException(error);
      });
  }

  componentDidMount() {
    /*keystrings*/
    $('.back_to_profile').text(window.keystrings['com.numi.profile.back_to_profile']);
    $('.change_password_title').text(window.keystrings['com.numi.change_password_title']);
    $('.old_pwd_label').text(window.keystrings['com.numi.change_password.old']);
    $('.new_pwd_label').text(window.keystrings['com.numi.new_password']);
    $('.confirm_pwd_label').text(window.keystrings['com.numi.change_password.confirm']);
    $('#change_password').text(window.keystrings['com.numi.change_password.save']);
    /*keystrings*/

    $('.change_password input[type="password"]').keyup(function () {
      var $emptyFields = $('.change_password  input[type="password"]').filter(function () {
        return $.trim(this.value) === "";
      });

      if (!$emptyFields.length) {
        $('.change_password button').removeClass('btn disabled').removeAttr('disabled', '');

      }
      else {
        $('.change_password button').addClass('btn disabled').attr('disabled', 'disabled');
      }
    });

  }

  render() {

    return (
      <div>

        <div className="table_display">
          <div className="journal_div settings">
            <div className="section_left pull-left">
              <Navigation />
            </div>
            <div className="section_right pull-left">
              <figure><Adspeed /></figure>
              <div className="journal_rht my_profile change_password">
                <p className="mb">
                  <Link to="/my_profile" className="back_to"><img alt="" src="/assets/images/back_white_icon.png" className="vlb" /> <span className="back_to_profile"></span></Link>
                </p>
                <h1 className="journal_hdr pull-left change_password_title"> </h1>
                <div className="clear"></div>
                <hr />


                <div className="row clearfix">

                  <div className="col-md-8 col-12">
                    <div className="border1">
                      <div className="flex_between">
                        <div className="pull-left my_profile_lft old_pwd_label"></div>
                        <div className="pull-right my_profile_rht"><input type="password" maxLength="40" className="font15 color5" placeholder="Enter Password" id="old_password" /><input type="text" className="height0 width0 border0 padding0" />
                        </div>
                      </div>
                      <div className="flex_between">
                        <div className="pull-left my_profile_lft new_pwd_label"></div>
                        <div className="pull-right my_profile_rht"><input type="password" maxLength="40" value={this.state.new_password} onBlur={this.validateNewPass} onChange={this.changeNewPassword} onKeyUp={this.changeNewPassword} className="font15 color5 new_pass" placeholder="Enter Password" id="new_password" />
                        </div>
                      </div>
                      <div className="flex_between">
                        <div className="pull-left my_profile_lft confirm_pwd_label"></div>
                        <div className="pull-right my_profile_rht"><input type="password" maxLength="40" value={this.state.confirm_password} onBlur={this.validateConfirmPass} onChange={this.confirmPassword} onKeyUp={this.confirmPassword} className="font15 color5 confirm_pass" placeholder="Enter Password" id="confirm_password" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-12 color6">
                    <div className="border1">
                      <Avatar />
                    </div>
                  </div>

                  {this.state.disable_btn == true ?

                    <button className="save_btn" disabled>
                      Save New Password
                    </button>
                    :
                    <button className="save_btn" onClick={this.matchPassword}>Save New Password</button>
                  }
                  {/* <button className="save_btn" onClick={() => {
                    console.log('tet')
                  }} disabled={this.state.disable_btn}>Save New Password</button> */}


                </div>

                {/* <div className="panel-group meal_section_left pull-left">
            
    	        <div className="profile_div_sec">
        		    <div className="pull-left my_profile_lft old_pwd_label"></div>
        		    <div className="pull-right my_profile_rht"><input type="password" maxLength="40" className="font15 color5" placeholder="Enter Password" id="old_password"/><input type="text" className="height0 width0 border0 padding0" />
                </div>
    		      </div>
          		<div className="profile_div_sec">
              		<div className="pull-left my_profile_lft new_pwd_label"></div>
              		<div className="pull-right my_profile_rht"><input type="password" maxLength="40"  value={this.state.new_password}  onBlur={this.validateNewPass}  onChange={this.changeNewPassword}  onKeyUp={this.changeNewPassword} className="font15 color5 new_pass" placeholder="Enter Password" id="new_password"/>
                  </div>
          		</div>
          		<div className="profile_div_sec">
              		<div className="pull-left my_profile_lft confirm_pwd_label"></div>
              		<div className="pull-right my_profile_rht"><input type="password" maxLength="40"  value={this.state.confirm_password}  onBlur={this.validateConfirmPass} onChange={this.confirmPassword} onKeyUp={this.confirmPassword} className="font15 color5 confirm_pass" placeholder="Enter Password" id="confirm_password"/>
                  </div>
          		</div>
              <p><span onClick={this.matchPassword}><button id="change_password" disabled={this.state.disable}  className={this.state.disable_btn}></button></span></p>
  		      </div>
            <Avatar/>
             */}

              </div>
              <Footer />
            </div>
            <ChechkOldpassword />
            <ValidatePassword />
            <MatchNewPassword />
          </div>
        </div>
      </div>
    )
  }
}

class ChechkOldpassword extends React.Component {
  render() {
    return (
      <div className="modal fade" id="check_old_password">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><img alt="" src="/assets/images/close_grey.png" /></button>
            </div>
            <div className="modal-body">
              <img alt="" src="/assets/images/warning_tri.png" />
              <p className="font26 color3 mt5 font700 old_password_incorrect_text"></p>
              <p><button data-dismiss="modal" className="old_password_incorrect_ok"></button></p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

class ValidatePassword extends React.Component {
  render() {
    return (
      <div className="modal fade" id="validate_password">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><img alt="" src="/assets/images/close_grey.png" /></button>
            </div>
            <div className="modal-body">
              <img alt="" src="/assets/images/warning_tri.png" />
              <p className="font26 color3 mt5 font700 password_incorrect_text"></p>
              <p><button data-dismiss="modal" className="password_incorrect_ok"></button></p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

class MatchNewPassword extends React.Component {
  render() {
    return (
      <div className="modal fade" id="match_password">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><img alt="" src="/assets/images/close_grey.png" /></button>
            </div>
            <div className="modal-body">
              <img alt="" src="/assets/images/warning_tri.png" />
              <p className="font26 color3 mt5 font700 password_notmatch_text"></p>
              <p><button data-dismiss="modal" className="password_notmatch_ok" ></button></p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ChangePassword;
