import React from 'react';
import { withRouter } from 'react-router-dom'
import Header from "../header.js";
import Footer from "../footer.js";
import DatePicker from "react-datepicker";
import { addDays, parse } from 'date-fns';
import axios from 'axios';
import moment from 'moment';
import ActivityQuestions from '../settings/activity_questions.js';
import { PersonalizePlanMode, Carousel } from './PersonalizePlanMode.js';
import { LogoutPopup } from '../journal/popup.js';
import $ from 'jquery';
import * as Sentry from "@sentry/react";

class NsPlan extends React.Component {
  constructor(props) {
    super(props)

    this.state = { startDate: addDays(new Date(parse(localStorage.getItem('turbo_start_date'))), window.localStorage.getItem("TURBO_TAKEOFF_DAYS")), gender_radio: '', disable_btn: 'btn disabled', disable: 'disabled', success_phase_radio: 'greater_than_60', checkplans: false, plan_name: '', get_id: '', nav1: null, nav2: null, slider1: null, slider2: null, categories: [], smart_week_setup_screen: false };
    this.radio_toggle = this.radio_toggle.bind(this);
    this.getPrograms = this.getPlans.bind(this);
    this.clickYes = this.clickYes.bind(this);
    this.clickNo = this.clickNo.bind(this);
    this.setLocation = this.setLocation.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  turboDate() {

    if (new Date(localStorage.getItem('plan_start_date')) > new Date()) {
      localStorage.setItem('turbo_start_date', new Date((localStorage.getItem('plan_start_date'))).toISOString().slice(0, 10))
    }
    else {
      localStorage.setItem('turbo_start_date', new Date().toISOString().slice(0, 10))
    }

    this.setState({
      startDate: addDays(new Date(parse(localStorage.getItem('turbo_start_date'))), window.localStorage.getItem("TURBO_TAKEOFF_DAYS"))
    });
  }



  handleChange(date) {
    this.setState({
      startDate: date
    });
    Header.changeDate(date);
  }
  clickYes(e) {
    this.setState({
      turbo_takeoff: true, disable_btn: '', disable: ''
    });
    localStorage.removeItem("smart_week_setup_screen")
  }

  clickNo(e) {
    this.setState({
      turbo_takeoff: false, disable_btn: '', disable: ''
    });

  }
  handleInputChange(e) {
    this.setState({ [e.target.id]: e.target.value });
  }

  radio_toggle(e) {
    this.setState({
      gender_radio: e.target.id,
      success_phase_radio: e.target.id,
      disable: ''
    });
  }
  getPlans() {


    Header.startLoader();
    const options = {
      url: window.API_URL + 'v1/plans?program_id=1',
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        Header.stopLoader();
        if (response && response.data) {
          this.setState({ plans: response.data })
          if (response.data.plans.length === 0) {
            alert(window.keystrings['com.numi.no_plan_available']);
            this.props.history.push('/select_program');
          }
          /*
            for(var i=0;i<response.data.plans.length;i++)
            {
              if(response.data.plans[i].personalization === false)
              {
                 $('<li class=plan_display_block cursor_pointer" id='+response.data.plans[i].key+'>'+ response.data.plans[i].name+'</li>').appendTo('.plans_list');
              }

            }
            if(response.data.nutrisystem_profile_linked === true && response.data.personalization_plan === null)
            {
              this.setState({
                check_actualplans: true,
                checkplans: false
              })
            }
            else if(response.data.nutrisystem_profile_linked === false)
            {
              this.setState({
                showChoose:true
              })
            }*/
          else {
            /*this.setState({
              plan_name: response.data.personalization_plan.name,
              get_id: response.data.personalization_plan.key,
            });*/
            $(".tap_choose_personalized_plan").click(function () {
              return window.GoogleTag.trigger('tap_choose_personalized_plan', {
                gaEventCategory: 'Onboarding',
                gaEventAction: 'Detected Personalized Plan',
                gaEventLabel: $('.tap_choose_personalized_plan').text().replace("your ", "")
              });
            });
            var evt = document.createEvent("CustomEvent");
            evt.initCustomEvent('PersonalizedPlanDetected', true, false, false);
            document.dispatchEvent(evt);
          }
          var numiLegacy = window.localStorage.getItem('numi_legacy');
          var profileStatus = window.localStorage.getItem('profile_status');
          var legacyPlan = window.localStorage.getItem('legacy_plan');

          if (numiLegacy === 'true' && profileStatus === 'PROFILE_COMPLETE' && (legacyPlan !== "null" || legacyPlan === null) && legacyPlan !== "NS_FREE") {
            var final_legacy = $('.plans_list').find('li#' + legacyPlan + '').text();
            $('.selected_plan').text(final_legacy);
            $('.current_plan,.plans_list,.hide_arrows').show();

            if ($('.selected_plan').text() === final_legacy) {
              $('.plans_list li#' + legacyPlan).hide();
            }
            else {
              $('.plans_list li#' + legacyPlan).show();
            }
          }
          else if (numiLegacy === 'true' && profileStatus === 'PROFILE_COMPLETE' && legacyPlan === "NS_FREE") {
            $('.free_plan,.plans_list,.hide_arrows').show();
            $('.plans_list #NS_SUCCESS,.plans_list #NS_FREE').hide();
          }
          else if (profileStatus === "PROFILE_COMPLETE" || profileStatus === "ONBOARDING") {
            $('.free_plan,.current_plan').hide();
            /*$('.plans_list,.hide_arrows').show();*/

          }
          else if (profileStatus === "PLAN_COMPLETE" && numiLegacy !== "true") {
            //this.props.history.push('/stay_on_track');
          }
          $('.ns_select_plan').show();
          $('.phase_option,.low_blood_sugar,.success_phase').hide();
          localStorage.removeItem('load_plans');
          localStorage.removeItem('turbo_phase_option');
          localStorage.removeItem('low_sugar');
          localStorage.removeItem('activity_level');
          localStorage.removeItem('stop_calldet');
          localStorage.removeItem('first_week_text')
          localStorage.removeItem('turbo_takeoff_ends_text')


          if (profileStatus === "ONBOARDING_COMPLETE") {
            window.localStorage.setItem('assigned_date', moment().format('DD-MM-YYYY'));
            this.props.history.push('/journal');
          }
        }
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });

  };


  /* set help location based on onboarding screen*/
  setLocation(value) {
    var numiLegacy = window.localStorage.getItem('numi_legacy');
    var profileStatus = window.localStorage.getItem('profile_status');
    var legacyPlan = window.localStorage.getItem('legacy_plan');
    if (numiLegacy === 'true' && profileStatus === 'PROFILE_COMPLETE' && legacyPlan === "NS_FREE") {
      window.localStorage.setItem('help_location', "ONBOARDING_PLAN_LIST_FREE");
    }
    else if (numiLegacy === 'true' && profileStatus === 'PROFILE_COMPLETE') {
      window.localStorage.setItem('help_location', "ONBOARDING_PLAN_LIST_CURRENT_PLAN");
    }
    else {
      window.localStorage.setItem('help_location', value);
    }
    this.child.refs.callHelp.callHelpAPI();
  }
  setPhase() {
    window.localStorage.setItem('turbo_phase_option', 'true');

    //localStorage.removeItem('turbo_start_date');
  }
  setSugar() {
    window.localStorage.setItem('low_sugar', 'true');
  }
  setActivitylevel() {
    window.localStorage.setItem('activity_level', 'true');
  }

  phaseBack() {
    $('.phase_option').hide();
    $('.phase_option label').css('opacity', '0.4');
    this.setState({
      turbo_takeoff: false, disable_btn: '', disable: ''
    });

    $('.ns_select_plan,.plans_list,.hide_arrows').show();
    if ($('.plans_list li').length === 0) {
      this.getPlans();
    }
    localStorage.removeItem('stop_calldet');
    localStorage.removeItem('set_key');
    localStorage.removeItem('first_week_text')
    localStorage.removeItem('turbo_takeoff_ends_text')
  }

  sugarBack() {
    $('.low_blood_sugar').hide();
    $('.low_blood_sugar label').css('opacity', '0.4');
    $('.low_blood_sugar button').addClass('btn disabled').attr('disabled', 'disabled');
    $('.ns_select_plan,.plans_list,.hide_arrows').show();
    if ($('.plans_list li').length === 0) {
      this.getPlans();
    }
    localStorage.removeItem('stop_calldet');
    localStorage.removeItem('set_key');
  }

  successBack() {
    $('.success_phase').hide();
    $('.success_phase label').css('opacity', '0.4');
    $('.greater_than_60 label').css('opacity', '1');
    $('.ns_select_plan,.plans_list,.hide_arrows').show();
    if ($('.plans_list li').length === 0) {
      this.getPlans();
    }
    localStorage.removeItem('stop_calldet');
    localStorage.removeItem('set_key');
  }


  componentDidUpdate() {
    Header.changeDate(this.state.startDate);
    // window.scrollTo({
    //   top: 0,
    //   behavior: 'smooth'
    // });
  }

  // call next determinant based on response
  mealDeterminantAPI = () => {
    Header.startLoader(); // start loader
    const options = {
      url: window.API_URL + "v1/meal_plan_determinants",
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        Header.stopLoader(); // stop loader
        if (response.data.meal_plans.length > 1) {
          var determinant = response.data.next_determinant.key;
          window.localStorage.setItem('set_key', determinant);
          $('.low_blood_sugar .next_determinant').attr('id', determinant);
          $('.success_phase .next_determinant').attr('id', determinant);
          $('.phase_option,.ns_select_plan').hide();

          if (determinant === "LOW_BLOOD_SUGAR") {
            $('.low_blood_sugar').show();
          }
          if (determinant === "ACTIVITY_LEVEL") {
            var evt = document.createEvent("CustomEvent");
            evt.initCustomEvent('ShowActivityLevel', true, false, false);
            document.dispatchEvent(evt);
            $('.success_phase').show();
            $('#less_than_60').val('LESS_THAN_60_MINUTES');
            $('#equal_60').val('60_MINUTES');
            $('#greater_than_60').val('GREATER_THAN_60_MINUTES');
          }
          else if (determinant === "CIPD_RANGE") {
            $('.text-green-darker').find('img').each(function () {
              if ($(this).parent().hasClass('active')) {
                $(this).attr('src', $(this).attr('active_image'));
                $(this).parent().css('opacity', 1).addClass('active');
              }
              else {
                $(this).attr('src', $(this).attr('normal_image'));
                $(this).parent().css('opacity', 0.3).removeClass('active');
              }
            });
            $('#activityGoal .row > div').hide();
            $('#activityGoal .row > div:eq(0)').show();
            $('#activity_questions').modal('show');
            setTimeout(function () {
              $('body').addClass('modal-open');
            }, 1200)
          }
        }
        else if (response.data.meal_plans.length <= 1) {
          $('.modal').modal('hide');
          $('.modal-backdrop').remove();
          window.localStorage.setItem('profile_status', "PLAN_COMPLETE");
          if (localStorage.getItem('smartAdapt') === 'true') {

            this.callSmartAdapt();
          }
          else {
            this.props.history.push('/stay_on_track');
          }

          localStorage.removeItem('stop_calldet');
        }
        else {
          alert(window.keystrings['com.numi.no_meal_plan_found']);
        }
      })
      .catch(error => {
        Header.stopLoader(); // stop loader
        Header.apiErrors(error);
        Sentry.captureException(error);
      });

  }

  callSmartAdapt = () => {
    Header.startLoader();
    const options = {
      url: window.API_URL + 'v1/nutrisystem/apply_smart_adapt',
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        Header.stopLoader();
        if (response && response.data) {
          if (response.data.smart_phase === true) {
            if (localStorage.getItem("smart_week_setup_screen") === "true" && localStorage.getItem("smartAdapt") === "true" && this.state.turbo_takeoff === false) {
              $('.ns_plan').css('padding', '0');
              $('.smart_week_setup_option_new').hide().css({ "padding-top": "25px", "padding-bottom": "10px" });
              $('.ques_buffer').show();
              $('.phase_option,#personalize_div').hide();
              localStorage.setItem('smartAdaptEnabled', 'new')
              var evt = document.createEvent("CustomEvent");
              evt.initCustomEvent('SmartAdaptEnabled', true, false, false);
              document.dispatchEvent(evt);
              setTimeout(() => {
                $('.ques_buffer').hide();
                $('.ques_calculation').hide();
                $('.smart_week_setup_option_new').show().css({ "padding-top": "25px", "padding-bottom": "10px" });
                $("#backToPrevStep1").removeClass("d-none")
                $("#backToPrevStep12").addClass("d-none")
                $("#backToPrevStep21").addClass("d-none")
                $("#backToPrevStep2").removeClass("d-none")
              }, 2000);
            } else {
              $('.ns_plan').css('padding', '0');
              $('.ques_buffer').show();
              $('.phase_option,#personalize_div').hide();
              localStorage.setItem('smartAdaptEnabled', 'new')
              var evt = document.createEvent("CustomEvent");
              evt.initCustomEvent('SmartAdaptEnabled', true, false, false);
              document.dispatchEvent(evt);
              setTimeout(() => {
                $('.ques_buffer').hide();
                $('.smart_week_setup_option_new').hide();
                $('.ques_calculation').show();
              }, 2000);
            }
          } else {
            this.props.history.push('/stay_on_track');
          }
        }
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });
  }

  //cheatdays

  callCheatDaysAPI = () => {
    const smart_week_new = {
      url: window.API_URL + "v1/nutrisystem/smart_week",
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };
    axios(smart_week_new)
      .then(response => {
        localStorage.setItem('smart_week_setup_screen', response.data.smart_week.show_smart_week);
      })
      .catch(error => {
        Header.stopLoader(); // stop loader
        Header.apiErrors(error);
        Sentry.captureException(error);
      });
  }


  //end cheatdays

  // Frist phase determinant call
  callDeterminantAPI = () => {
    Header.startLoader(); // start loader
    const options = {
      url: window.API_URL + "v1/phase_template_determinants",
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        Header.stopLoader(); // stop loader
        if (response.data.phase_template_determinants.phase_templates.length > 1) {

          var next_determinant = response.data.phase_template_determinants.next_determinant
          var determinant = next_determinant.key;
          window.localStorage.setItem('set_key', determinant);
          if (next_determinant.misc_data.is_qvc === false) {
            $('.week_one_txt').html(window.keystrings['com.numi.first_week_on_nutri']);
          } else if (next_determinant.misc_data.is_qvc === true) {
            $('.week_one_txt').html(window.keystrings['com.numi.first_week_qvc']);
          } else {
            $('.week_one_txt').html(window.keystrings['com.numi.first_week_qvc']);
          }

          $('.phase_option .next_determinant').attr('id', determinant);
          if (determinant !== "TURBO_TAKEOFF") {
            $('.phase_option .next_determinant').html(determinant);
          }
          this.setState({
            disable_btn: 'btn disabled', disable: 'disabled'
          });
          $('.phase_option').show();
          $('.ns_select_plan,#personalize_div').hide();
          if (determinant === "TURBO_TAKEOFF") {
            var freshstart_replace = next_determinant.misc_data.is_first_week_turbo.replace('FreshStart', '');
            $('.first_week_text').html(freshstart_replace)
            $('.turbo_takeoff_ends_text').text(next_determinant.misc_data.turbo_takeoff_ends);
            window.localStorage.setItem('first_week_text', freshstart_replace);
            window.localStorage.setItem('turbo_takeoff_ends_text', next_determinant.misc_data.turbo_takeoff_ends);

          }
        }
        else if (response.data.phase_template_determinants.phase_templates.length <= 1) {
          this.mealDeterminantAPI();
        }
        else {
          alert(window.keystrings['com.numi.no_phase_template_found']);
        }
      })
      .catch(error => {
        Header.stopLoader(); // stop loader
        Header.apiErrors(error);
        Sentry.captureException(error);
      });
  }

  componentDidMount() {

    /*  $(document).on('click','.nutri_plan .choose_a_new_plan span',function(){
        this.showList()
      }.bind(this))
      */
    localStorage.setItem('load', true);

    window.localStorage.setItem("gtmLinkSuccess", "Onboarding");
    Header.getTurboTakeoffDays();
    setTimeout(function () {
      this.turboDate();
    }.bind(this), 1200)
    $('.turbo_takeoff .form-control').attr('readOnly', true);

    Header.startLoader(); // start loader

    // window.scrollTo({
    //   top: 0,
    //   behavior: "auto",
    // });

    const profile_options = {
      url: window.API_URL + "v1/profile",
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };
    var _this = this;


    axios(profile_options)
      .then(response => {
        if (response && response.data && response.data.profile) {
          localStorage.setItem('nutrisystem_linked', response.data.profile.nutrisystem_profile_linked);
          var evt = document.createEvent("CustomEvent");
          evt.initCustomEvent('SelectPlanCategoryStep1', true, false, false);
          document.dispatchEvent(evt);
        }
        if (response.data.profile.status === null) {
          _this.props.history.push('/login');
        }
        else if ((response.data.profile.status === "ONBOARDING_COMPLETE") && (window.localStorage.getItem('help_location') !== "ONBOARDING_PLAN_LIST")) {
          window.localStorage.setItem('assigned_date', moment().format('YYYY-MM-DD'));

          _this.props.history.push('/journal');
        }


      })
      .catch(error => {
        Header.stopLoader(); // stop loader
        Header.apiErrors(error);
        Sentry.captureException(error);
      });


    if (window.localStorage.getItem('load_plans') === 'true') {
      if (window.localStorage.getItem('turbo_phase_option') === 'true') {
        $('.plans_list,.ns_select_plan,.success_phase,.low_blood_sugar').hide();
        $('.phase_option').show();
        $('.first_week_text').html(window.localStorage.getItem('first_week_text'))
        $('.turbo_takeoff_ends_text').text(window.localStorage.getItem('turbo_takeoff_ends_text'))
        localStorage.removeItem('load_plans');
        localStorage.removeItem('turbo_phase_option');
        localStorage.removeItem('low_sugar');
        localStorage.removeItem('activity_level');
      } else if (window.localStorage.getItem('low_sugar') === 'true') {
        $('.low_blood_sugar').show();
        $('.phase_option,.plans_list,.ns_select_plan,.success_phase').hide();
        localStorage.removeItem('load_plans');
        localStorage.removeItem('turbo_phase_option');
        localStorage.removeItem('low_sugar');
        localStorage.removeItem('activity_level');
      } else if (window.localStorage.getItem('activity_level') === 'true') {
        $('.success_phase').show();
        $('.low_blood_sugar,.phase_option,.plans_list,.ns_select_plan').hide();
        localStorage.removeItem('load_plans');
        localStorage.removeItem('turbo_phase_option');
        localStorage.removeItem('low_sugar');
        localStorage.removeItem('activity_level');
      } else {
        if (window.localStorage.getItem('stop_calldet') !== true)
          this.getPlans();
      }

    }
    else {
      this.getPlans();
    }






    // api call on meal plan determinant select
    $(document).on('click', '.get_radio button', function (e) {
      if (window.location.href.indexOf('/ns_plan') > -1) {
        e.stopImmediatePropagation();
        var key;
        var value;
        var evt = document.createEvent("CustomEvent");
        if ($('.low_blood_sugar').is(':visible')) {
          key = $('.low_blood_sugar .next_determinant').attr('id');
          if (key === undefined) {
            key = window.localStorage.getItem('set_key');
          }
          value = $('.low_blood_sugar').find('input[type="radio"]:checked').attr('class');
          evt.initCustomEvent('TapOver100Phase', true, false, false);
          document.dispatchEvent(evt);
        }
        else {
          if ($('.success_phase').is(':visible')) {
            key = $('.success_phase .next_determinant').attr('id');
            if (key === undefined) {
              key = window.localStorage.getItem('set_key');
            }
            value = $('.success_phase').find('input[type="radio"]:checked').val();

            evt.initCustomEvent('TapSuccessPhase', true, false, false);
            document.dispatchEvent(evt);
          }
        }

        var profile_properties_params = { "profile_properties": [{ "key": key, "value": value }] };
        Header.startLoader(); // start loader

        const options = {
          url: window.API_URL + "v1/profile_properties",
          method: 'POST',
          credentials: 'same-origin',
          headers: Header.setAuthHeaders(),
          data: profile_properties_params
        };

        axios(options)
          .then(response => {
            Header.stopLoader(); // stop loader
            this.mealDeterminantAPI(); // need to call mealDeterminantAPI(); based oncondition
          })
          .catch(error => {
            Header.stopLoader(); // stop loader
            Header.apiErrors(error);
            Sentry.captureException(error);
          });
      }
    }.bind(this));


    // api call on plan select or determinant select
    $(document).on('click', '.personal_plan,.plans_list .plan_display_block,#NS_SUCCESS,#NS_FREE,#NS_CORE,.phase_option button.continue_text,.get_radio button', function (e) {
      var _this = this;
      if (window.location.href.indexOf('/ns_plan') > -1) {
        var key;
        var value;;
        var profile_properties_array;
        e.stopImmediatePropagation();
        if ($('.ns_select_plan').is(':visible')) {
          key = "PLAN_KEY";
          value = e.target.getAttribute('id');
          profile_properties_array = [{ "key": key, "value": value }]
        }
        else {
          key = $('.phase_option .next_determinant').attr('id');
          if (key === undefined) {
            key = window.localStorage.getItem('set_key');
          }
          value = $('.toggle_options').find('input[type="radio"]:checked').attr('class');
          profile_properties_array = [{ "key": key, "value": value }]
          if (key === "TURBO_TAKEOFF") {
            var evt = document.createEvent("CustomEvent");
            evt.initCustomEvent('TapTurboTakeoff', true, false, false);
            document.dispatchEvent(evt);
          }
          if (key === "TURBO_TAKEOFF" && value === "true") {
            key = "END_DATE";
            value = moment(parse($('.turbo_takeoff').find('.date_pick input').val())).format('YYYY-MM-DD');
            profile_properties_array.push({ "key": key, "value": value })
          }

        }

        _this.child.activityApi();


        var profile_properties_params = { "profile_properties": profile_properties_array };
        Header.startLoader(); // start loader


        const options = {
          url: window.API_URL + "v1/profile_properties",
          method: 'POST',
          credentials: 'same-origin',
          headers: Header.setAuthHeaders(),
          data: profile_properties_params
        };

        axios(options)
          .then(response => {

            Header.stopLoader(); // stop loader
            this.callDeterminantAPI(); // need to call mealDeterminantAPI(); based oncondition
            this.callCheatDaysAPI();
          })
          .catch(error => {
            Header.stopLoader(); // stop loader
            Header.apiErrors(error);
            Sentry.captureException(error);
          });
      }
    }.bind(this));

    $('.phase_option label').click(function () {
      $('.phase_option label').css('opacity', '0.4');
      $(this).css('opacity', '1');
    });

    $('.low_blood_sugar label').click(function () {
      $('.low_blood_sugar label').css('opacity', '0.4');
      $(this).css('opacity', '1');
      $('.low_blood_sugar button').removeClass('btn disabled').removeAttr('disabled')
    });
    $('.success_phase label').click(function () {
      $('.success_phase label').css('opacity', '0.4');
      $('.success_phase input').removeAttr('checked');
      $(this).css('opacity', '1');
      var id = $(this).attr('for')
      $('#' + id).attr('checked', true)
    });


    /*keystrings*/
    $('.choose_plan_title_text').text(window.keystrings['com.numi.choose_plan_title'])
    $('.you_current_plan_text').text(window.keystrings['com.numi.you_current_plan'])
    $('.continue_whit_this_plan_text').text(window.keystrings['com.numi.continue_whit_this_plan'])
    $('.or_text').text(window.keystrings['com.numi.or'])
    $('.choose_current_plan_text').text(window.keystrings['com.numi.choose_current_plan'])
    $('.not_using_nutrisystem_program_text').text(window.keystrings['com.numi.not_using_nutrisystem_program'])
    $('.success_plan_description_text').text(window.keystrings['com.numi.success_plan_description'])
    $('.choose_success_plan_text').text(window.keystrings['com.numi.choose_success_plan'])
    $('.calorie_tracker_plan_description_text').text(window.keystrings['com.numi.calorie_tracker_plan_description'])
    $('.choose_calorie_tracker_plan_text').text(window.keystrings['com.numi.choose_calorie_tracker_plan'])
    $('.journal_stage_options_text').text(window.keystrings['com.numi.journal.stage_options'])
    $('.yes_text').text(window.keystrings['com.numi.yes'])
    $('.no_text').text(window.keystrings['com.numi.no'])
    $('.continue_text').text(window.keystrings['com.numi.continue'])
    $('.blood_sugar_label_text').text(window.keystrings['com.numi.blood_sugar_label'])
    $('.blood_sugar_recommendation_text').text(window.keystrings['com.numi.blood_sugar_recommendation'])
    $('.diabetic_query_desc_text').append(window.keystrings['com.numi.diabetic_query_desc'])
    $('.what_is_activity_level_text').text(window.keystrings['com.numi.what_is_activity_level'])
    $('.activity_level_1_text').text(window.keystrings['com.numi.activity_level_1'])
    $('.activity_level_2_text').text(window.keystrings['com.numi.activity_level_2'])
    $('.activity_level_3_text').text(window.keystrings['com.numi.activity_level_3'])
    $('.link_personalized_text').text(window.keystrings['com.numi.link_personalized_text']);
    $('.link_nutrisystem_account').text(window.keystrings['com.numi.link_nutrisystem_account']);
    $('.nutri_link .choose_a_new_plan').text(window.keystrings['com.numi.choose_a_new_plan']);
    $('.have_nutri_plan b').text(window.keystrings['com.numi.have_personal_plan_from_nutri']);
    $('.get_personal_plan b').text(window.keystrings['com.numi.personalized_plan_desc']);
    $('.nutri_plan .choose_a_new_plan').html(window.keystrings['com.numi.nutri_link_want_new_plan']);

    $('.plan_info').html(window.keystrings['com.numi.pmp_acc_desc']);
    $('.plan_details').html(window.keystrings['com.numi.pmp_acc_desc_details']);
    $('.follow_numi b').html(window.keystrings['com.numi.follow_numi_plan']);
    $('.tap_choose_personalized_plan').html(window.keystrings['com.numi.follow_numi_plan_text']);
    /*keystrings*/

    const options = {
      url: window.API_URL + "v1/plan_categories?version=v2",
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        if (response && response.data) {
          Header.stopLoader(); // stop loader
          this.setState({
            categories: response.data.plan_categories
          });
          setTimeout(() => {
            $('.slick-current').focus();
            let sliderbutton = $(".slick-arrow.slick-prev")[0];
            let sliderbuttondiv = $(".btn-sticky-prv")[0];
            let selectplannew = $(".select_plan_new .slick-slider.center.slick-initialized")[0]
            sliderbuttondiv.appendChild(sliderbutton);
            selectplannew.appendChild(sliderbuttondiv);

            let sliderbuttonnxt = $(".slick-arrow.slick-next")[0];
            let sliderbuttondivnxt = $(".btn-sticky-nxt")[0];
            sliderbuttondivnxt.appendChild(sliderbuttonnxt);
            selectplannew.appendChild(sliderbuttondivnxt);

            // sliderbutton.replaceChild(sliderbuttondiv, sliderbutton);
            // sliderbuttondiv.appendChild(sliderbutton);
          }, 200)
        }

      })
      .catch(error => {
        Header.stopLoader(); // stop loader
        Header.apiErrors(error);
        Sentry.captureException(error);
      });


  }

  goLogin = () => {
    localStorage.clear();
    sessionStorage.clear();
    this.props.history.push("/login");
  }

  render() {


    return (
      <div>

        <div className="forgot_password_hero">

          <div><img onClick={() => this.goLogin()} alt="" src="/assets/images/numi_small_logo.png" className="logo img-responsive cursor_pointer" /></div>
          <div className="ns_plan" id="ns_plan_main">
            <div className="ns_select_plan">

              <div className="text-center">
                {/* <h3 className="choose_hdr mt5 choose_plan_title_text"> </h3> */}
                <div className="current_plan">
                  <h6 className="you_current_plan_text"> </h6>
                  <h3 className="selected_plan">CORE</h3>
                  <button className="mt continue_whit_this_plan_text" id="NS_CORE"></button>
                  <p className="or or_text"></p>
                  <h6 className="choose_current_plan_text"> </h6>
                </div>
                <div className="free_plan width88">
                  <h6><b className="not_using_nutrisystem_program_text">  </b></h6>
                  <div>
                    <p className="success_plan_description_text"></p>
                    <button className="choose_success_plan choose_success_plan_text" id="NS_SUCCESS"></button>
                    <p className="calorie_tracker_plan_description_text"></p>
                    <button className="calorie_tracker choose_calorie_tracker_plan_text" id="NS_FREE"></button>
                    <p className="or or_text"></p>
                    <h6 className="choose_current_plan_text"> </h6>
                  </div>
                </div>




                {/*<div className={this.state.checkplans ? "nutri_plan padding4 relative" : "new_plan_hide nutri_plan padding4 relative"}>
                        <h3>{localStorage.getItem('FirstName')},</h3>
                        <h6 className="color6 pt0 spacing0 get_personal_plan"><b></b></h6>
                        <h6 className="plan_info font16"> </h6>
                        <h6 className='plan_details font16'> </h6>
                        <h6 className="follow_numi mt10"><b></b></h6>
                        <button className="personal_plan tap_choose_personalized_plan" id={this.state.get_id}></button>
                        <p  className="choose_a_new_plan margin0"></p>
                      </div>
                    */}

                {/* <ul className="plans_list"> </ul> */}
                <Carousel getcallDeterminantAPI={this.callDeterminantAPI} getActivityQuestions={this.child} categoryContent={this.state.categories} />


              </div>
            </div>
            <PersonalizePlanMode getcallDeterminantAPI={this.callDeterminantAPI} categoryContent={this.state.categories} />
            <div className="phase_option">
              { /*<img alt=""  onClick={this.phaseBack.bind(this)} src="/assets/images/back_white_icon.png" className="left_arrow phase_option_back cursor_pointer"/>*/}
              <div className="phase_inner">
                <h6 className="journal_stage_options_text"> </h6>
                <h3 className="next_determinant"><strong className="first_week_text"></strong></h3>
                <p className="mt10p toggle_options">
                  <span className="yes" onClick={this.clickYes}>
                    <input className="true" type="radio" id="yes" checked={this.state.gender_radio === "yes"} onChange={this.radio_toggle} />
                    <label htmlFor="yes" className="yes_text"></label>
                  </span>
                  <span className="no" onClick={this.clickNo.bind(this)}>
                    <input className="false" type="radio" id="no" checked={this.state.gender_radio === "no"} onChange={this.radio_toggle} />
                    <label htmlFor="no" className="no_text"></label>
                  </span>
                </p>
                <div className="turbo_takeoff mt20px">
                  <p className="week_one_txt font16"></p>
                  <div style={{ display: this.state.turbo_takeoff ? 'block' : 'none' }}>
                    <span className="turbo_take turbo_takeoff_ends_text"></span>

                    <div className="date_pick input-group date vlm width50" >

                      <DatePicker selected={this.state.startDate} onChange={this.handleChange} minDate={new Date()} maxDate={addDays(new Date(), 29)} />

                    </div>

                  </div>

                </div>
                <button onClick={() => this.setPhase()} disabled={this.state.disable} className={this.state.disable_btn + " mt10p turbo_takeoff_continue continue_text"}></button>

              </div>
            </div>

            <div className="low_blood_sugar get_radio">
              {/*<img alt="" onClick={this.sugarBack.bind(this)} src="/assets/images/back_white_icon.png" className="left_arrow low_blood_sugar_back"/>*/}
              <div className="text-center">
                <h6 className="text-center journal_stage_options_text"> </h6>
                <h3 className="next_determinant blood_sugar_label_text"> </h3>
                <h6 className="next_determinant_desc blood_sugar_recommendation_text"> </h6>
                <p className="meal_toggle_options">
                  <span className="blood_yes ">
                    <input className="true" type="radio" id="blood_yes" checked={this.state.gender_radio === "blood_yes"} onChange={this.radio_toggle} />
                    <label htmlFor="blood_yes" className="yes_text"></label>
                  </span>
                  <span className="blood_no">
                    <input className="false" type="radio" id="blood_no" checked={this.state.gender_radio === "blood_no"} onChange={this.radio_toggle} />
                    <label htmlFor="blood_no" className="no_text"></label>
                  </span>
                </p>

                <p className="mailto diabetic_query_desc_text"></p>
                <button onClick={this.setSugar.bind(this)} className={this.state.disable_btn + " tap_d_plan_over100_continue continue_text"}></button>

              </div>
            </div>


            <div className="success_phase get_radio">
              {/*<img alt="" onClick={this.successBack.bind(this)} src="/assets/images/back_white_icon.png" className="left_arrow success_phase_back"/>*/}
              <div className="text-center">
                <h6 className="journal_stage_options_text"> </h6>
                <h2 className="next_determinant what_is_activity_level_text"> </h2>
                <p className="less_than_60">
                  <input className="" type="radio" id="less_than_60" checked={this.state.success_phase_radio === "less_than_60"} onChange={this.radio_toggle} />
                  <label htmlFor="less_than_60" className="activity_level_1_text">  </label>
                </p>
                <p className="equal_60">
                  <input className="" type="radio" id="equal_60" checked={this.state.success_phase_radio === "equal_60"} onChange={this.radio_toggle} />
                  <label htmlFor="equal_60" className="activity_level_2_text"></label>
                </p>
                <p className="greater_than_60">
                  <input className="" type="radio" id="greater_than_60" checked={this.state.success_phase_radio === "greater_than_60"} onChange={this.radio_toggle} />
                  <label htmlFor="greater_than_60" className="activity_level_3_text"></label>
                </p>
                <button className="success_phase_button continue_text" onClick={this.setActivitylevel.bind(this)}></button>

              </div>
            </div>

          </div>
        </div>
        <ActivityQuestions callMealDeterminant={this.mealDeterminantAPI} callPlansAPI={this.getPlans} onRef={ref => (this.child = ref)} />
        <LogoutPopup />
        <Footer />

      </div>
    )
  }
}






export default withRouter(NsPlan);

