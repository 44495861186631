
import React from 'react';
import { Link } from 'react-router-dom'
import Footer from "../footer.js";
import Header from "../header.js";
import Navigation from "../nav.js";
import Adspeed from "../adspeed.js";
import axios from 'axios';
import $ from 'jquery';
import * as Sentry from "@sentry/react";

class AboutNutrisystem extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      help_accor_list: [], item_header: '', item_body: ''
    };
  }
  componentDidMount() {
    var phase_template;
    var location = 'LEARN_ABOUT';
    var phase_template_key = window.localStorage.getItem('phase_template_key');
    if (phase_template_key !== null && phase_template_key !== undefined) {
      phase_template = phase_template_key
    }
    else {
      phase_template = '';
    }
    Header.startLoader();

    const options = {
      url: window.API_URL + "v1/helps?location=" + location + "&phase_template_key=" + phase_template,
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        Header.stopLoader();
        if (response && response.data) {
          this.setState({
            help_accor_list: response.data.help.help_details, item_header: response.data.help.title, item_body: response.data.help.body
          })
        }
        $('[id^=about_help_accord]').removeClass('in')
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });
    /*keystrings*/
    $('.back_to_settings_text').append(window.keystrings['com.numi.setting.back_to_settings'])
    $('.learn_about_title_text').text(window.keystrings['com.numi.learn_about_title'])
    $('.app_tutorial_text').text(window.keystrings['com.numi.settings.app_tutorial'])
    /*keystrings*/
  }


  render() {
    var help_accor_list_option = this.state.help_accor_list.map(function (option, i) {
      if (option.help_deeplinks.length > 0) {
        return (
          <div className="panel panel-default" key={i}>
            <div className="panel-heading">
              <h4 className="panel-title help_accordion relative">
                <a className="accord_help" data-toggle="collapse" data-parent="#about_help_accord" href={"#about_help_accord" + i}>
                  {option.title}
                </a>
              </h4>
            </div>
            <div id={"about_help_accord" + i} className="panel-collapse  collapse">
              <div className="panel-body text-left">
                <p style={{ 'whiteSpace': 'pre-line' }}>{option.body}</p>
                <a className="tour_link" target="_blank" rel="noopener noreferrer" href={option.help_deeplinks[0].web_url}>{option.help_deeplinks.length > 0 ? option.help_deeplinks[0].title : ''}</a>

              </div>
            </div>
          </div>

        )
      }
      else {
        return (
          <div className="panel panel-default" key={i}>
            <div className="panel-heading">
              <h4 className="panel-title help_accordion relative">
                <a className="accord_help" data-toggle="collapse" data-parent="#about_help_accord" href={"#about_help_accord" + i}>
                  {option.title}
                </a>
              </h4>
            </div>
            <div id={"about_help_accord" + i} className="panel-collapse  collapse">
              <div className="panel-body text-left">
                <p style={{ 'whiteSpace': 'pre-line' }} dangerouslySetInnerHTML={{ __html: option.body }}></p>
              </div>
            </div>
          </div>

        )
      }

    });

    return (
      <div>

        <div className="table_display">
          <div className="journal_div settings" id="about_help_accord">
            <div className="section_left pull-left">
              <Navigation />
            </div>
            <div className="section_right pull-left">
              <figure><Adspeed /></figure>
              <div className="journal_rht communication about_help_accord">
                <p className="mb">
                  <Link to="/settings" className="back_to back_to_settings_text"><img alt="" src="/assets/images/back_white_icon.png" className="vlb" /></Link>
                </p>
                <h1 className="journal_hdr pull-left learn_about_title_text">Learn About Nutrisystem</h1>
                <div className="clear"></div>
                <hr />
                <div className="panel-group  border0 meal_section_left pull-left text-center">
                  {help_accor_list_option}
                </div>
                <div className="profile_div_sec text-left"><Link className="about_nutri app_tutorial_text" to="/tour"></Link></div>
              </div>

              <Footer />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AboutNutrisystem;
