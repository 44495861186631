import React, { Component } from "react";
import $ from "jquery";
import Navigation from "../nav.js";
import Header from "../header.js";
import Adspeed from "../adspeed.js";
import Footer from "../footer.js";
import * as Sentry from "@sentry/react";

class Newsfeed extends Component {
  componentDidMount() {
    var gender = window.localStorage.getItem('gender');
    var smartadapt = window.localStorage.getItem('USER_MODE');;
    var linkedaccount = window.localStorage.getItem('nutrisystem_linked');
    var segment = [];

    if (gender != "" && gender != null) {
      segment.push(gender == "male" ? "male" : "female")
    }
    if (smartadapt == "SMART_ADAPT") {
      segment.push("smartadapt")
    }
    if (linkedaccount == "true") {
      segment.push("linked_account")
    }
    var first_name = window.localStorage.getItem('FirstName');
    var last_name = window.localStorage.getItem('LastName');

    const userPropertyData = {
      "{FIRST_NAME}": first_name,
      "{LAST_NAME}": last_name
    };

    const storylyWeb = document.querySelector("storyly-web")
    storylyWeb.init({
      token: process.env.REACT_APP_FEED_STORYLY_KEY,
      segments: segment,
      userPropertyData: userPropertyData,
      props: {
        storyGroupBorderRadius: 100,
        storyGroupSize: "90x90",
        storyGroupIconBorderColorSeen: ["#D6D6D6"],
        storyGroupIconBorderColorNotSeen: ["#02A0A5", "#64BE84"],
        scrollIndicator: true,
        scrollIndicatorOptions: {
          indicatorWidth: 25,
          indicatorHeight: 25,
          indicatorBorderColor: "#FFFFFF",
          indicatorBoxBackgroundColor: "#FFFFFF",
          indicatorDefaultIconsColor: "#02A0A5",
          leftIndicatorMargin: -7,
          rightIndicatorMargin: 0,
        }
      },
      events: {
        noStoryGroup: () => {
          storylyWeb.style.display = "none";
          document.querySelector(".no_story_msg_wrapper").style.display = "flex";
        },
        openStoryGroup: (group) => {
          localStorage.setItem('tap_view_story', "News Feed-" + group.title + '-' + group.title);
          var evt = document.createEvent("CustomEvent");
          evt.initCustomEvent('tap_view_story', true, false, false);
          document.dispatchEvent(evt);
        }
      }
    })


    setTimeout(function () {
      Newsfeed.loadAppboy();
      $(".promo_banner").show();
      if ($(".news_rht").offset() && $(".news_rht").offset().top) {
        var top = $(".news_rht").offset().top;
        $(window).scroll(function () {
          Newsfeed.sticktothetop(top);
        });
      }
      localStorage.setItem('bestClick', true);
    }, 200);

    window.addEventListener('scroll', function () {
      if (window.braze) {
        if (window.braze.getCachedContentCards !== undefined &&
          window.braze.getCachedContentCards().cards !== undefined ? window.braze.getCachedContentCards().cards.length > 0 : false) {
          $.each(window.braze.getCachedContentCards().cards, function (i, card) {
            var elem = $("div[data-ab-card-id='" + card.id + "']");
            $("div[data-ab-card-id='" + card.id + "']").attr({ "viewed": card.viewed, 'value': i });

          })
        }
      }
      var element = document.querySelector('[viewed="false"]');
      var position = element && element.getBoundingClientRect();
      if (position) {
        if (position.top >= 0 && position.bottom <= window.innerHeight) {
          if (window.braze) {
            if (window.braze.getCachedContentCards !== undefined) {
              let cards = window.braze.getCachedContentCards().cards;
              cards[element.getAttribute('value')].viewed = true;
              if (window.braze.requestContentCardsRefresh !== undefined) {
                window.braze.requestContentCardsRefresh();
              }
              setTimeout(() => {
                Newsfeed.newsCounter();
                $(".news_rht li.newstab_active").click();
              }, 800)

            }
          }
        }
      }
    });
  }

  clickFeeds(e) {
    var selectedCategory = e.currentTarget.getAttribute("data-category");
    Newsfeed.setCategory(e.currentTarget, selectedCategory);
    if (selectedCategory === "CATEGORY_BEST_FOR_YOU") {
      $('.best_for_you_tab').addClass('active');
      $('.newsfeed_tab').removeClass('active');
    }
    else {
      $('.newsfeed_tab').addClass('active');
      $('.best_for_you_tab').removeClass('active');
    }
    window.location.hash = selectedCategory || "CATEGORY_ALL";
  }

  static sticktothetop(top) {
    var window_top =
      (document.documentElement.scrollTop || document.body.scrollTop) - 265;
    $(".news_rht").css("top", window_top > 0 ? window_top : 0);
  }

  static setCategory(elem, selectedCategory) {
    $(".news_rht li").removeClass("newstab_active");
    elem.className = "newstab_active";
    Newsfeed.categorizeNewsFeeds(selectedCategory);
  }

  static categorizeNewsFeeds(category) {
    $(document).trigger("NewsfeedClicked", category);
    if ($(".newsfeed .news_feed_section").length) {
      if (category) {
        Header.startLoader()
        setTimeout(function () {
          Header.stopLoader();
          if (window.braze) {
            if (window.braze.getCachedContentCards !== undefined) {
              $.each(window.braze.getCachedContentCards().cards, function (
                i,
                card
              ) {
                var elem = $("div[data-ab-card-id='" + card.id + "']");
                $("div[data-ab-card-id='" + card.id + "']").attr({ "viewed": card.viewed, 'value': i });
                $("div[data-ab-card-id='" + card.id + "']").attr("id", card.extras.ID); // Add IDs to everything
                Newsfeed.cardCategories(card).includes(category) ? elem.show() : elem.hide();
                if ($('.ab-feed-body .ab-card:visible').length === 0) {
                  $('.no_updates').remove();
                  $('<p class="no_updates">We have no updates please check again</p>').prependTo('.ab-feed-body');
                  $('.no_updates').text(window.keystrings['com.numi.newsfeeds_empty'])
                }
                else {
                  $('.no_updates').remove();
                }

              });
            }
          }
        }, 200);
      } else {
        $(".ab-feed").remove();
        if (window.braze) {
          if (window.braze.showContentCards() !== undefined) {
            window.braze.showContentCards();
          }
          setTimeout(function () {

            if (window.braze) {
              if (window.braze.getCachedContentCards !== undefined) {
                $.each(window.braze.getCachedContentCards().cards, function (i, card) {
                  $("div[data-ab-card-id='" + card.id + "']").attr({ "viewed": card.viewed, 'value': i });

                  $("div[data-ab-card-id='" + card.id + "']").attr("id", card.extras.ID); // Add IDs to everything
                });
              }
            }
          }, 200);
        }
        setTimeout(function () {
          if (document.URL.indexOf('#CATEGORY_') > -1) {
            if (document.URL.indexOf('#CATEGORY_BEST_FOR_YOU') > -1) {
              $('[data-category="CATEGORY_BEST_FOR_YOU"]').trigger('click');
            }
            else {
              if (window.location.hash) {
                let hash_value = window.location.hash.split('#')[1];
                $('[data-category=' + hash_value + ']').trigger('click');
              }
              else {
                $('[data-category="CATEGORY_ALL"]').trigger('click');
              }
            }
            localStorage.removeItem('bestClick');
          }
          else {
            $('.best_for_you_tab').removeClass('active');
            localStorage.removeItem('bestClick');
          }
        }, 500);
      }
      $(".ab-feed").appendTo(".news_feed_section");
      $(".ab-feed").css("width", "100%");
      $(".ab-feed").show();
      setTimeout(function () {
        if (window.location.href.indexOf('ID') > -1) {
          var deeplinkId = window.location.href.split('ID')[1].split('#')[0].split('-')[2];
          $("a:contains(" + deeplinkId + ")").parents('.ab-card').prop('id', deeplinkId);
          if ($('#' + deeplinkId).length) {
            $('html, body').animate({
              scrollTop: $('#' + deeplinkId).offset().top
            }, 'slow');
          }
        }
      }, 999);
    }
  }

  static loadAppboy() {
    setTimeout(function () {
      if (window.braze) {
        window.braze.disableSDK(); // Reset if user reloads the page
        window.braze.enableSDK();
        window.braze.initialize(process.env.REACT_APP_NEWS_FEED_KEY, {
          baseUrl: "rest.iad-01.braze.com"
        });
        /* window.braze.initialize(process.env.REACT_APP_NEWS_FEED_KEY,{manageServiceWorkerExternally: true});*/
        window.braze.changeUser(localStorage.getItem("user_id"));
        if (!window.localStorage.impersonation) {
          window.braze.getUser().setEmail(window.localStorage.userName);
        }
        window.braze.automaticallyShowInAppMessages();
        window.braze.openSession();
        window.braze.subscribeToInAppMessage();

        if (window.braze && window.braze.requestContentCardsRefresh !== undefined) {
          window.braze.requestContentCardsRefresh();
        }
        /* window.braze.registerAppboyPushMessages();*/
      }
      $(".ab-in-app-message").show();
      Newsfeed.newsCounter();
      Newsfeed.categorizeNewsFeeds();
      Newsfeed.enableClickableImages();
    }, 200);

  }

  static enableClickableImages() {
    setTimeout(function () {
      $(".ab-url-area a").each(function () {
        var clickArea = $(this);
        var imgArea = clickArea.closest(".ab-card").find(".ab-image-area");
        imgArea.click(function () { clickArea.click(); });
        imgArea.css('cursor', 'pointer');
      });
    }, 1000);
  }

  static cardCategories(card) {
    return $.map(card.extras, function (_, k) {
      if (k.match("CATEGORY_")) {
        return k;
      }
    });
  }

  static newsCounter() {
    try {
      if (window.braze) {
        /*console.log('unviewed cards'+ window.braze.getCachedContentCards().getUnviewedCardCount());*/

        if (window.braze.getCachedContentCards !== undefined) {

          var cards = window.braze.getCachedContentCards().cards;
          if (cards === undefined || cards.length === 0) {
            return;
          }
          $('.news_right_count b').text('0');
          /*var unread = 0;*/
          $.each(cards, function (i, card) {
            if (!card.viewed) {
              /*unread++;*/
              Object.entries(card.extras).forEach(([key, value]) => {
                var elm = $("li[data-category=" + key.toUpperCase() + "]").find("#feed-count");
                elm.html(parseInt(elm.html()) + 1);
                if (parseInt(elm.text()) > 0) {
                  elm.parent().show();
                } else {
                  elm.parent().hide();
                }
              }
              );
            }
          });
        }
      }
    }
    catch {
      Sentry.captureException("News Feeds");
    };
    /* $(".all-news-categories")
       .find("#feed-count")
       .html(unread);
     if (unread > 0) {
       $(".all-news-categories .news_right_count").show();
     } else {
       $(".all-news-categories .news_right_count").hide();
     }
     */
    $('.news_right_count:contains("0")').hide();
  }

  render() {
    return (
      <div>
        <div id="message"></div>
        <div className="table_display">
          <div className="journal_div newsfeed">
            <div className="section_left pull-left">
              <Navigation />
            </div>
            <div className="section_right pull-left">
              <figure>
                <Adspeed />
              </figure>
              <div className="journal_rht communication">
                <div className='storyly_div'>
                  <h3>Nutrisystem Stories</h3>
                  <div>
                    <storyly-web />
                  </div>
                  <div className="no_story_msg_wrapper" style={{ display: "none" }}>
                    <p className="no_story_msg">No stories to view at this time. Check back later to see new stories.</p>
                  </div>
                </div>
                <h1 className="journal_hdr pull-left">News Feed</h1>
                <div className="clear" />
                <hr />
                <div className="meal_section_left news_feed_section pull-left" />
                <div className="pull-right width34">
                  <ul className="news_rht" style={{ postion: 'relative' }}>
                    <li className="newstab_active all-news-categories" data-category="CATEGORY_ALL" onClick={this.clickFeeds}>
                      <img src="/assets/images/all.png" alt="" />{" "}
                      <span className="color6 font14 bold">All</span>
                      <span className="news_right_count">
                        <b id="feed-count">0</b>
                      </span>
                    </li>
                    <li data-category="CATEGORY_FEATURED" onClick={this.clickFeeds}>
                      <img src="/assets/images/featured.png" alt="" />{" "}
                      <span className="color6 font14 bold">Featured</span>
                      <span className="news_right_count">
                        <b id="feed-count">0</b>
                      </span>
                    </li>
                    <li data-category="CATEGORY_CHALLENGES" onClick={this.clickFeeds}>
                      <img src="/assets/images/challenges.png" alt="" />{" "}
                      <span className="color6 font14 bold">Challenges</span>
                      <span className="news_right_count">
                        <b id="feed-count">0</b>
                      </span>
                    </li>
                    <li data-category="CATEGORY_TIPS" onClick={this.clickFeeds}>
                      <img src="/assets/images/tips.png" alt="" />{" "}
                      <span className="color6 font14 bold">Tips & Recipes</span>
                      <span className="news_right_count">
                        <b id="feed-count">0</b>
                      </span>
                    </li>
                    <li data-category="CATEGORY_SPECIAL" onClick={this.clickFeeds}>
                      <img
                        src="/assets/images/special.png" alt=""
                        width="30"
                        height="30"
                      />{" "}
                      <span className="color6 font14 bold">Special Offers</span>
                      <span className="news_right_count">
                        <b id="feed-count">0</b>
                      </span>
                    </li>
                    <li data-category="CATEGORY_GUIDES" onClick={this.clickFeeds}>
                      <img
                        src="/assets/images/guides.png" alt=""
                        width="30"
                        height="30"
                      />{" "}
                      <span className="color6 font14 bold">Guides</span>
                      <span className="news_right_count">
                        <b id="feed-count">0</b>
                      </span>
                    </li>
                    <li data-category="CATEGORY_BEST_FOR_YOU" onClick={this.clickFeeds}>
                      < img
                        src="/assets/images/best_for_you.png" alt=""
                        width="30"
                        height="30"
                      />{"  "}
                      <span className="color6 font14 bold" >Best For You</span>
                      <span className="news_right_count" >
                        <b id="feed-count" >0</b>
                      </span >
                    </li >
                  </ul>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Newsfeed;
