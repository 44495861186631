import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Modal } from 'react-bootstrap';
import { addDays, subDays, parse } from 'date-fns';
import moment from 'moment';
import DatePicker from "react-datepicker";
import MealDropdown from "./meal_dropdown.js";
import Header from "../header.js";
import * as Sentry from "@sentry/react";
import axios from 'axios';

const VegetableSection = (props) => {

    var food_consumed = false;
    var gg = 0;
    const history = useHistory();
    const [data, setData] = useState();
    const [accordionShow, setAccordianShow] = useState(false);
    const [accordianArrow, setAccordianArrow] = useState("/assets/images/arrow_up_grey.png")
    const [copyNotValidModal, setCopyNotValidModal] = useState(false);
    const [copyNotValidText, setCopyNotValidText] = useState('');
    const [occasionName, setOccassionName] = useState("");
    const [copyToDate, setCopyToDate] = useState("");
    const [startDate, setStartDate] = useState(addDays(new Date(), 1));
    const [copyError, setCopyError] = useState("");
    const [foodError, setFoodError] = useState("");
    const [copyModal, setCopyModal] = useState(false);
    const [resetLogFoodPopup, setResetLogFoodPopup] = useState(false);
    const [currResetQuickLogId, setCurrResetQuickLogId] = useState(null);

    useEffect(() => {
        setData(props.veg_food_items);
    })


    function onHideCopyNotValidModal() {
        setCopyNotValidModal(false);
    }

    function onHideCopyModal() {
        setCopyModal(false);
    }

    function onHideResetLogFoodPopup() {
        setResetLogFoodPopup(false);
    }

    function onLogAdditionalFood(e) {
        window.localStorage.setItem('autoscroll', 'true');
        window.localStorage.setItem('scroll_id', window.pageYOffset);
        window.localStorage.setItem('meal_occasion_id', 7);
        window.localStorage.setItem('meal_occasion_name', e);
        localStorage.removeItem('food_categories');
        localStorage.removeItem('food_type');
        window.localStorage.setItem('log_food_search', "true");
        window.localStorage.setItem('from_plus', "true");
        window.localStorage.setItem('from_addintional_log', "true");
        history.push({ pathname: "/log_food" });
    }


    function copyYesterdayMeal(get_value) {
        var cpoy_data = {
            "diet_histories": [{
                "copy_by_occasion": {
                    "from_occasion": get_value,
                    "from_date": moment(window.localStorage.getItem('assigned_date')).subtract(1, 'days').format('YYYY-MM-DD'),
                    "assigned_date": window.localStorage.getItem('assigned_date'),
                    "occasion": get_value
                }
            }]
        }

        const options = {
            url: window.API_URL + 'v1/diet_histories/copy_by_occasion',
            method: 'POST',
            credentials: 'same-origin',
            headers: Header.setAuthHeaders(),
            data: cpoy_data
        };

        axios(options)
            .then(response => {
                props.resetAllState();
                props.getJournal();
            })
            .catch(error => {
                Header.stopLoader();
                setCopyNotValidModal(true);
                if (error && error.response && error.response.data && error.response.data.error) {
                    setCopyNotValidText(error.response.data.error);
                }
                else if (error && error.response && error.response.status > 400) {
                    setCopyNotValidText(error.response.statusText);
                }
                Sentry.captureException(error);
            });
    }


    function handleChange(date) {
        setStartDate(date)
        var complete_date = moment(date).format('MMM DD');
        var actual_date = moment().format('MMM DD');
        var pageload_selecetd_date = moment(date).format('MMM DD');
        var kk = moment(date).format('MMM DD');
        var actual_date_format = moment(date).format('YYYY-MM-DD');
        if (pageload_selecetd_date === actual_date) {
            setCopyToDate(kk);
        }
        else {
            setCopyToDate(complete_date);
        }
        Header.changeDate(date);
        const options = {
            url: window.API_URL + "v1/phase_templates/assigned_date/" + actual_date_format,
            method: 'GET',
            credentials: 'same-origin',
            headers: Header.setAuthHeaders(),
        };

        axios(options)
            .then(response => {
                if (response.data.key !== undefined) {
                    if (response.data.key === window.localStorage.getItem('phase_template_key')) {
                        setCopyError("");
                    }
                    else {
                        setCopyError(kk + window.keystrings['com.numi.copy_food_to_different_phase_warning']);
                    }
                }
            })
            .catch(error => {
                Header.stopLoader();
                Header.apiErrors(error);
                Sentry.captureException(error);
            });
    }


    function copyMeal() {
        window.click_copy_food = true;
        var copy_meal_data = {
            "diet_histories": [{
                "copy_by_occasion": {
                    "from_occasion": 'VEGETABLE',
                    "from_date": window.localStorage.getItem('assigned_date'),
                    "assigned_date": moment(startDate).format('YYYY-MM-DD'),
                    "occasion": window.localStorage.getItem('meal_occassion_name_copy_food'),
                }
            }]
        };

        const options = {
            url: window.API_URL + 'v1/diet_histories/copy_by_occasion',
            method: 'POST',
            credentials: 'same-origin',
            headers: Header.setAuthHeaders(),
            data: copy_meal_data
        };

        axios(options)
            .then(response => {
                setCopyModal(false);
                window.localStorage.setItem('assigned_date', moment(startDate).format('YYYY-MM-DD'))
                setStartDate(addDays(window.localStorage.getItem('assigned_date'), 1))
                props.resetAllState();
                props.getJournal();
            })
            .catch(error => {
                Header.stopLoader();
                if (error && error.response && error.response.data && error.response.data.error) {
                    setFoodError(<div className="food_error error_txt text-center font14">{error.response.data.error}</div>)
                }
                else if (error && error.response && error.response.status > 400) {
                    setFoodError(<div className="food_error error_txt text-center font14">{error.response.data.error}</div>)
                }
                Sentry.captureException(error);
            });

    };


    function handleQuicklogs(id, meal_occasion_id, serving_size, default_meal_occasion_id, food_state, consumable_type) {
        var quicklog_data = {};
        if (food_state == "UNUSED") {
            if (default_meal_occasion_id == null) {
                quicklog_data = {
                    "diet_histories": [{
                        "unused_food_category_id": id.toString(),
                        "consumable_type": "QuickLog",
                        "assigned_date": moment(window.localStorage.getItem('assigned_date')).format('YYYY-MM-DD'),
                        "meal_occasion_id": meal_occasion_id.toString(),
                        "serving_size": 1
                    }]
                };
            }
            else {
                quicklog_data = {
                    "diet_histories": [
                        {
                            "unused_food_category_id": id.toString(),
                            "consumable_type": "QuickLog",
                            "assigned_date": moment(window.localStorage.getItem('assigned_date')).format('YYYY-MM-DD'),
                            "meal_occasion_id": meal_occasion_id.toString(),
                            "serving_size": serving_size,
                            "default_meal_occasion_id": default_meal_occasion_id
                        }
                    ]
                }
            }
            const options = {
                url: window.API_URL + 'v1/diet_histories',
                method: 'POST',
                credentials: 'same-origin',
                headers: Header.setAuthHeaders(),
                data: quicklog_data
            };

            axios(options)
                .then(response => {
                    window.localStorage.setItem("autoscroll", false)
                    props.getJournal()

                })
                .catch(error => {
                    Sentry.captureException(error);
                });
        }
        else {
            if (consumable_type == "QuickLog") {
                const options = {
                    url: window.API_URL + `v1/diet_histories/${id}`,
                    method: 'DELETE',
                    credentials: 'same-origin',
                    headers: Header.setAuthHeaders(),
                };

                axios(options)
                    .then(response => {
                        props.resetAllState();
                        props.getJournal()

                    })
                    .catch(error => {
                        Sentry.captureException(error);
                    });
            }
            else {
                setCurrResetQuickLogId(id);
                setResetLogFoodPopup(true);
            }
        }

    }

    return (
        <>
            <div className="meal_right_con">
                <div className="meal_right_sec_gap header cursor_pointer" onClick={() => {
                    if (accordianArrow == "/assets/images/arrow_up_grey.png") {
                        setAccordianArrow("/assets/images/arrow_down_grey.png")
                    }
                    else {
                        setAccordianArrow("/assets/images/arrow_up_grey.png")
                    }
                    setAccordianShow(!accordionShow);

                }}>
                    <h5 className="pull-left">VEGETABLE</h5>
                    <span to="" className="pull-right">
                        <img alt="" src={accordianArrow}></img>
                    </span>
                </div>
                <div className='meal_right_sec_gap'>
                    <div className='pull-left meal_add_icon'>
                        <div className="horizontal_veggie" style={{ display: accordionShow === true ? "block" : "none" }}>
                            <ul className="veg_items">
                                {
                                    props.summary_food_items?.map((summary, i) => {

                                        return (

                                            <li key={i}><span className="relative"><img alt="" width="48" height="48" src={summary.url} data-toggle="dropdown" className="standard_img ml5px" /><img alt="" src="/assets/images/flex_meal_on.png" data-toggle="dropdown" className="flex1" /><img alt="" src="/assets/images/flex_meal_off.png" data-toggle="dropdown" className="flex2" /><img alt="" src="/assets/images/flex_meal_2on.png" data-toggle="dropdown" className="flex3" /></span></li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                        <div className="vertical_veggie" style={{ display: accordionShow === true ? "none" : "" }}>
                            <Droppable droppableId={`droppable-${props.veg_food_meal_occassion_id}--droppableallow`} type="meal_occasion">
                                {(provider) => (
                                    <div
                                        ref={provider.innerRef}
                                        {...provider.droppableProps}
                                    >
                                        {
                                            data?.map((veg, i) => {
                                                if (food_consumed === false && veg.unused_food_item === undefined) {
                                                    food_consumed = true;
                                                }
                                                gg++;

                                                var food_item = veg.unused_food_item || veg.food_item
                                                var custom_food = '';
                                                var over_plan = '';
                                                var display_name = '';
                                                var calories = '';
                                                var detail_display_multi = [];
                                                var unused = false;
                                                var quicklog = false;
                                                var id = null;
                                                var unused_food_category_id = null;
                                                var consumable_id = null;
                                                var ghosted = (food_item.detail_display.state === "GHOSTED" ? true : false);
                                                var consumable_type = null;
                                                var meal_occasion_id = props.veg_food_meal_occassion_id;
                                                var meal_occasion_name = props.veg_food_meal_occassion_id;
                                                var eaten_at = null;
                                                var food_categories = [food_item.detail_display !== null ? food_item.detail_display.food_category : []];
                                                if (veg.unused_food_item === undefined) {
                                                    over_plan = (food_item.over_plan === true) ? window.keystrings['com.numi.over_plan'] : "";
                                                    if (food_item.multiple_servings === true) {
                                                        custom_food = window.keystrings['com.numi.multiple_servings']
                                                    }
                                                    else {
                                                        custom_food = food_item.food_type_display || ''
                                                    }

                                                    display_name = (ghosted === true) ? "Vegetable" : food_item.consumable_name
                                                    calories = food_item.servings_calories
                                                    detail_display_multi = food_item.detail_display_multi
                                                    eaten_at = (ghosted === true) ? window.keystrings['com.numi.eaten_at'] + ' ' + food_item.eaten_at : null;
                                                    id = food_item.id;

                                                    if (food_item.consumable_type === "QuickLog") {
                                                        if (window.localStorage.getItem('journal_type') === "CALORIE_TRACKER") {
                                                            quicklog = false;
                                                        } else {
                                                            quicklog = true;
                                                        }

                                                        consumable_id = food_item.consumable_id;
                                                    }
                                                    else {
                                                        consumable_id = food_item.consumable_id;
                                                        consumable_type = food_item.consumable_type;
                                                    }
                                                }
                                                else {
                                                    display_name = food_item.display_name
                                                    unused = true;
                                                    unused_food_category_id = food_item.id;
                                                }
                                                var archive_icon = "";
                                                if (props.archive_view !== true) {
                                                    if (food_item.callout_icon === "CARET") {
                                                        archive_icon = 'caret_icon';
                                                    }
                                                    else {
                                                        if (food_item.callout_icon === "PLUS") {
                                                            archive_icon = 'plus_icon';
                                                        }
                                                        else {
                                                            archive_icon = 'search_small';
                                                        }
                                                    }
                                                }
                                                return (
                                                    <Draggable key={"" + id} draggableId={"" + (props.veg_food_meal_occassion_id + "-" + food_item.id + "-" + i + "-" + (food_item.default_meal_occasion_id ? food_item.default_meal_occasion_id : props.veg_food_meal_occassion_id) + "-" + food_item.detail_display.state + "-" + (food_item.detail_display?.food_category == "MULTI_CATEGORY" ? "multilog" : "singlelog"))} index={i} isDragDisabled={props.drag_drop_status === true ? ghosted === false ? false : ghosted === true ? true : false : true}>
                                                        {(provider, snapshot) => (
                                                            <div {...provider.draggableProps} value={i} ref={provider.innerRef} {...provider.dragHandleProps} id={i} key={i + 1}>
                                                                <div className={`full_width inline_block meal_data quicklog ${ghosted === true ? "ghosted_div" : ""} ${snapshot.isDragging && "dragging"}`} quicklog={quicklog} id={i}>
                                                                    <img src="/assets/images/drag_drop_icon.png" className='mr5px' id={i} style={{ display: props.drag_drop_status === true ? "block" : "none" }} />
                                                                    <div className="food_details pull-left font16 relative cursor_pointer" unused_food_category_id={unused_food_category_id} id={id} unused={unused} quicklog={quicklog} meal_occasion_id={meal_occasion_id}>
                                                                        <span className="ab_position" id={i}>{window.localStorage.getItem('journal_type') === "CALORIE_TRACKER" || props.all_meals_cheat_meal_status == true ? food_item.calories : ""}</span><img alt="" className="cursor_pointer" src={food_item.detail_display.url} width="48" height="48" onClick={() => {
                                                                            handleQuicklogs(
                                                                                food_item.id,
                                                                                meal_occasion_id,
                                                                                food_item.serving_size ? food_item.serving_size : 1,
                                                                                food_item.default_meal_occasion_id ? food_item.default_meal_occasion_id : null, food_item.detail_display.state, food_item.consumable_type ? food_item.consumable_type : "")
                                                                        }} />
                                                                    </div>
                                                                    <div id={i} className={`pull-left item_search ${ghosted === true ? "back_image_none" : ""} ${archive_icon}`} meal_occasion_id={meal_occasion_id} dh_id={id} food_categories={food_categories} meal_occasion_name={meal_occasion_name} consumable_id={consumable_id} consumable_type={consumable_type} onClick={() => {
                                                                        if (archive_icon === "plus_icon" || archive_icon === "search_small") {
                                                                            window.localStorage.setItem('autoscroll', 'true');
                                                                            window.localStorage.setItem('scroll_id', window.pageYOffset);
                                                                            window.localStorage.setItem('meal_occasion_id', meal_occasion_id);
                                                                            window.localStorage.setItem('meal_occasion_name', meal_occasion_name);
                                                                            window.localStorage.setItem('meal_occasion', meal_occasion_name);
                                                                            window.localStorage.setItem('quicklog_id', food_item.consumable_id === undefined ? "" : food_item.consumable_id);
                                                                            localStorage.removeItem('food_type');
                                                                            window.localStorage.setItem('log_food_search', "true");
                                                                            if (food_categories !== "CALORIE") {
                                                                                localStorage.removeItem('searched_term');
                                                                                window.localStorage.setItem('food_categories', food_categories);
                                                                            }
                                                                            else {
                                                                                localStorage.removeItem('food_categories');
                                                                                window.localStorage.setItem('from_all_foods', true);
                                                                            }
                                                                            if (window.localStorage.getItem("food_categories") === "NS_ENTREE" && (window.localStorage.getItem('meal_occasion') === "BREAKFAST" || window.localStorage.getItem('meal_occasion') === "LUNCH" || window.localStorage.getItem('meal_occasion') === "DINNER")) {
                                                                                window.localStorage.setItem('food_type', 'nutrisystem');
                                                                            }
                                                                            window.localStorage.setItem('log_food_search', "true");
                                                                            window.localStorage.setItem('from_addintional_log', "false");
                                                                            history.push({ pathname: "/log_food" });
                                                                        }
                                                                        else if (archive_icon == 'caret_icon') {
                                                                            window.localStorage.setItem('dh_id', food_item.id);
                                                                            window.localStorage.setItem('consumable_id', food_item.consumable_id);
                                                                            window.localStorage.setItem('consumable_type', food_item.consumable_type);
                                                                            window.localStorage.setItem('meal_occasion_id', props.veg_food_meal_occassion_id);
                                                                            window.localStorage.setItem('quicklog_id', food_item.consumable_id === undefined ? "" : food_item.consumable_id);
                                                                            localStorage.removeItem('food_categories');
                                                                            window.localStorage.setItem('state', food_item.detail_display.state);
                                                                            window.localStorage.setItem('food_category', food_item.detail_display.food_category);
                                                                            if (detail_display_multi.length != 0) {
                                                                                window.localStorage.setItem('singleLog', "false")
                                                                            }
                                                                            else {
                                                                                window.localStorage.setItem('singleLog', "true")
                                                                            }
                                                                            history.push('/edit_food');
                                                                        }
                                                                    }}>
                                                                        {/* <div className="font12 calorie_count_small" id={i}>{custom_food}<span className="red">{over_plan}</span></div> */}
                                                                        <div className="calorie_item font16">
                                                                            <div>{display_name}</div><span>{food_item && food_item.best_for_you === true ? <img alt="" src="/assets/images/best_for.png" style="margin-right:10px;" /> : <></>}</span>
                                                                            <div className="pill_images display_block">
                                                                                {

                                                                                    detail_display_multi.length !== 0 ?
                                                                                        detail_display_multi?.map((display, k) => {
                                                                                            return (
                                                                                                <img alt="" src={display.url} key={k}></img>
                                                                                            )
                                                                                        })
                                                                                        :
                                                                                        null
                                                                                }
                                                                                {ghosted === true ? "" : calories}</div></div>
                                                                        <span className="pill_images display_block">{ghosted === true ? eaten_at : food_item.moved_from ? food_item.moved_from : ""}</span>
                                                                    </div></div>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                )
                                            })
                                        }
                                    </div>
                                )}
                            </Droppable>
                        </div>
                        <div className="full_width inline_block meal_data_log dropdown log_food vegetable mb0" style={{ display: (props.archive_view ? 'none' : 'block') }}>
                            <ul className="dropdown-menu journal_options journal_empty_options">
                                <li onClick={(e) => { onLogAdditionalFood(e) }}><span className="search_foods" id="7" name="VEGETABLE">{window.keystrings['com.numi.journal.log_additional_foods']}</span></li>

                                <li onClick={() => { copyYesterdayMeal("VEGETABLE") }}><span className="log_yesterdays" name="VEGETABLE">{window.keystrings['com.numi.journal.log_yesterdays']}<span className="selected_txt"><p className="capitalize_text">VEGETABLE</p></span></span></li>
                                {
                                    props.veg_foods_summaries?.length > 0 ?
                                        <li className="food_last_li" style={{ display: ((localStorage.getItem('journal_type') === "CALORIE_TRACKER" && data.length === 0) || (localStorage.getItem('journal_type') !== "CALORIE_TRACKER" && props.veg_foods_summaries?.map(function (a) { return a.consumed; }).reduce((a, b) => a + b, 0) === 0)) ? 'none' : 'block' }} onClick={() => {
                                            setCopyModal(true)
                                            setStartDate(addDays(window.localStorage.getItem('assigned_date'), 1))
                                            setOccassionName(window.localStorage.getItem('meal_occassion_name_copy_food'))
                                            window.localStorage.setItem("meal_occasion_name", "VEGETABLE")
                                            window.localStorage.setItem('meal_occasion_id', 7)
                                            window.localStorage.setItem('meal_occassion_name_copy_food', "VEGETABLE")
                                        }}>
                                            <span className="not_copy" data-target="#copy_food" data-toggle="modal" id="7" name="VEGETABLE" data-display_name="VEGETABLE">Copy this <p className="capitalize_text">VEGETABLE</p></span></li>
                                        :
                                        null
                                }

                            </ul>
                            <img alt="" src="/assets/images/plus_icon.png" className="veg_plus_align cursor_pointer" data-toggle="dropdown" />
                            <span className="font15 meal_item_lft" data-toggle="dropdown"><span className="log_food_text" id="7" name="VEGETABLE">{window.keystrings['com.numi.more_title']}</span></span>
                        </div>
                    </div>
                </div>
            </div>


            <Modal show={copyNotValidModal} id="copy_not_valid_food" onHide={() => { onHideCopyNotValidModal() }}>
                <Modal.Header>
                    <button type="button" className="close" onClick={() => { setCopyNotValidModal(false) }}>
                        <img alt="" src="/assets/images/close_grey.png" />
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="copy_food_not_valid">
                        <img alt="" src="/assets/images/warning_tri.png" />

                        <p className="font26 color3 mt5 font700">NuMi</p>
                        <p className="font16 color6 copy_not_valid_error text-center font16">{copyNotValidText}</p>
                        <p><button onClick={() => { setCopyNotValidModal(false) }}>OK</button></p>
                    </div>
                </Modal.Body>
            </Modal>


            <Modal show={copyModal} id="copy_food" onHide={() => { onHideCopyModal() }}>
                <Modal.Header>
                    <button type="button" className="close" onClick={() => { setCopyModal(false) }}>
                        <img alt="" src="/assets/images/close_grey.png" />
                    </button>
                </Modal.Header>
                <Modal.Body>
                    {foodError}
                    <div className="copy_food_valid">
                        <h3 className="modal-title">{window.keystrings['com.numi.journal.copy_meal']}</h3>
                        <p className="font15">Your <span className="capitalize_text">{occasionName}</span> from <b>{moment(window.localStorage.getItem('assigned_date')).format('MMM DD')}</b> will be copied to <b className="copy_to_date">{copyToDate}</b></p>
                        <div className="relative date_pick">
                            <span className="left_sec font15">Date</span>
                            <DatePicker onChange={(date) => handleChange(date)} minDate={parse(window.localStorage.getItem('profile_start_date'))} maxDate={addDays(new Date(), 90)} selected={parse(startDate)} />
                        </div>
                        <div className="relative date_pick">
                            <span className="left_sec font15">Meal</span>
                            <MealDropdown />
                        </div>
                        <p className="error copy_error font14">{copyError}</p>
                        <input type="submit" className="copy_meal copy_meal_food mt6" value="Copy Meal" onClick={() => copyMeal()} />
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={resetLogFoodPopup} id="unlog_activity" onHide={() => { onHideResetLogFoodPopup() }}>
                <Modal.Header>
                    <button type="button" className="close" onClick={() => { setResetLogFoodPopup(false) }}>
                        <img alt="" src="/assets/images/close_grey.png" />
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <img alt="" src="/assets/images/warning_tri.png" />
                    <p className="font26 color3 mt5 font700 ">{window.keystrings['com.numi.journal.unlog_food']}</p>
                    <p className="font16 color6 ">{window.keystrings['com.numi.journal.unlog_food_body']}</p>
                    <p><button className="delete_acitivty" onClick={() => {
                        const options = {
                            url: window.API_URL + `v1/diet_histories/${currResetQuickLogId}`,
                            method: 'DELETE',
                            credentials: 'same-origin',
                            headers: Header.setAuthHeaders(),
                        };

                        axios(options)
                            .then(response => {
                                props.getJournal();
                                setResetLogFoodPopup(false);

                            })
                            .catch(error => {
                                Sentry.captureException(error);
                                setResetLogFoodPopup(false);
                            });
                    }}>Yes</button><button onClick={() => { setResetLogFoodPopup(false) }}>Cancel</button></p>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default VegetableSection;