import React from 'react';
import Header from "../header.js";
import moment from 'moment';
import axios from 'axios';
import $ from 'jquery';
import * as Sentry from "@sentry/react";

class MealDropdown extends React.Component {

  constructor(props) {
    super(props)
    this.state = { meal_occasions: [] };
  }

  callMealData() {
    function calculateBucketSequence(currentHour) {
      if (currentHour <= 10 && currentHour >= 0) {
        return '1';
      } else if (currentHour > 10 && currentHour < 12) {
        return '2';
      } else if (currentHour >= 12 && currentHour < 14) {
        return '3';
      } else if (currentHour >= 14 && currentHour < 17) {
        return '4';
      } else if (currentHour >= 17 && currentHour < 20) {
        return '5';
      } else if (currentHour >= 20 && currentHour <= 24) {
        return '6';
      }
      return null;
    }

    function updateMealOccasion() {
      $('.drop_txt .meal_list_img:not(.multi_select_dropdown .meal_list_img)').each(function () {
        var mealOccasionId = $(this).find('i').attr('id');
        var Meal_Occation_Id = window.localStorage.getItem('meal_occasion_id');
        var Bucket_Sequence = calculateBucketSequence(moment().format('HH'));

        if (mealOccasionId === (Meal_Occation_Id || Bucket_Sequence)) {
          $('.select_replace').html($(this).html());
          var mealOccassionName = $('.select_replace i').attr('name');
        }
      });
    }
    var assigned_date = moment(window.localStorage.getItem('assigned_date')).format('YYYY-MM-DD');
    const options = {
      url: window.API_URL + "v1/meal_occasions?assigned_date=" + assigned_date,
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        if (response && response.data) {
          this.setState({ meal_occasions: response.data.meal_occasions });
          updateMealOccasion();
        }
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });
    setTimeout(updateMealOccasion, 1200);
  }

  componentDidMount() {
    $(document).on('click', '.drop_txt li', function () {
      $('.select_replace').html($(this).html());
      window.localStorage.setItem('meal_occassion_name_copy_food', $('.select_replace i').attr('name'));
    });
    //Header.startLoader();
    if (localStorage.getItem('profile_status') === 'ONBOARDING_COMPLETE') {
      this.callMealData();
    }
  }


  render() {

    var food_list_images = {
      "BREAKFAST": { "color": "#308DC1", "image": '/assets/images/breakfast_icon.png' },
      "LUNCH_SNACK": { "color": "#666666", "image": '/assets/images/snack_icon.png' },
      "DINNER": { "color": "#EB5F5F", "image": '/assets/images/dinner_icon.png' },
      "DINNER_SNACK": { "color": "#666666", "image": '/assets/images/snack_icon.png' },
      "VEGETABLE": { "color": "#666666", "image": '/assets/images/snack_icon.png' },
      "BREAKFAST_SNACK": { "color": "#666666", "image": '/assets/images/snack_icon.png' },
      "LUNCH": { "color": "#FF6100", "image": '/assets/images/lunch_icon.png' },
      "EXTRAS": { "color": "#FF6100", "image": '/assets/images/snack_icon.png' }
    }
    if (this.state && this.state.meal_occasions) {
      var meal_hdr = this.state.meal_occasions.map(function (meal_option, i) {
        return (
          <li className="meal_list_img" key={i}>
            <i id={meal_option.id} name={meal_option.name} style={{ color: food_list_images[meal_option.name]["color"] }}><span><img alt="" src={food_list_images[meal_option.name]["image"]} /></span> <span className="drop_meal_name">{meal_option.display_name}</span></i>
          </li>
        )
      });
    }

    return (
      <span className="dropdown">
        <ul className="dropdown-menu drop_txt">
          {meal_hdr}
        </ul>
        <span className="select_replace" data-toggle="dropdown"><i id="1" name="BREAKFAST" style={{ color: '#3DA3DC' }}></i></span>
      </span>
    )
  }
}

export default MealDropdown;
