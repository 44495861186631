import React from 'react';
import { Link } from 'react-router-dom'
import Header from "../header.js";
import Footer from "../footer.js";
import axios from 'axios';
import $ from 'jquery';
import * as Sentry from "@sentry/react";

class NutriLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showNutriLink: false, nutri_link_email: '', nutri_link_password: '', congrats: false, showChoose: true, error_popup: false, errorCode: '', errorMessage: '' }
    this.showNutriLinkAccount = this.showNutriLinkAccount.bind(this);
    this.emailChange = this.emailChange.bind(this);
    this.passwordChange = this.passwordChange.bind(this);
  }
  showNutriLinkAccount() {
    this.setState({
      showNutriLink: true,
      showChoose: false
    });
    $('body').append('<div class="fade_link_popup"></div>');
  }
  showLinkAccount() {
    this.setState({
      showNutriLink: true,
      error_popup: false
    });
    $('.fade_link_popup').remove();
  }
  clickClose() {
    if (window.localStorage.link_page_from_plan_settings) {
      this.props.history.push("/plan_settings")
    }
    else {
      this.props.history.push("/ns_plan")
      $('.fade_link_popup').remove();
    }
  }
  clickLinkClose() {
    this.popUpNavigationOnContinue();
  }
  togglePassword(e) {
    if (e.target.previousSibling.getAttribute('type') === 'password') {
      e.target.previousSibling.setAttribute('type', 'text');
    }
    else {
      e.target.previousSibling.setAttribute('type', 'password');
    }

  }
  linkNutriAccount() {
    this.setState({
      congrats: true,
      showNutriLink: false
    });
    $('.fade_link_popup').remove();
    $('body').append('<div class="fade_link_popup"></div>');
  }

  emailChange(e) {
    this.setState({
      nutri_link_email: e.target.value
    })
  }
  passwordChange(e) {
    this.setState({
      nutri_link_password: e.target.value
    })
  }
  componentDidMount() {
    $('.choose_nutri_hdr b').text(window.keystrings['com.numi.choose_plan_title']);
    $('.could_not_find_txt').text(window.keystrings['com.numi.could_not_find_txt']);
    $('.could_not_locate_credentials_txt').html(window.keystrings['com.numi.could_not_locate_credentials_txt']);
    $('.link_your_accounts_txt').text(window.keystrings['com.numi.link_your_accounts_txt']);
    $('.nutrisystem_credentials_required_txt').text(window.keystrings['com.numi.nutrisystem_credentials_required_txt']);
    $('.dont_have_account_txt').text(window.keystrings['com.numi.ecom.splash_dont_have_account']);
    $('.dont_have_account_txt_caps').text(window.keystrings['com.numi.ecom.splash_dont_have_account_caps']);
    $('.forgot_link_pass').text(window.keystrings['com.numi.ecom.nutri_forgot_password']);
    $('.congratulation_link_txt').text(window.keystrings['com.numi.congratulation_link_txt']);
    $('.try_again_caps_text').text(window.keystrings['com.numi.try_again_caps_text']);
  }

  errorParagraph() {
    if (this.state.errorCode === 409) {
      return (
        <p className="font16 mt15 color6">{this.state.errorMessage}</p>
      )
    }
    else {
      return (
        <div>
          <p className="font26 mt10p could_not_find_txt"><b></b></p>
          <p className="font16 mt15 could_not_locate_credentials_txt" style={{ 'padding': '0 30px' }}></p>
        </div>
      )
    }
  }

  handleLinkSubmit(e) {
    e.preventDefault();
    var nutri_email = $('#nutri_email').val();
    var nutri_password = $('#nutri_password').val();
    var nutri_signup_params = { "nutrisystem": { "email": nutri_email, "password": nutri_password } };
    Header.startLoader();
    const options = {
      url: window.API_URL + "v1/nutrisystem/link.json",
      method: 'POST',
      headers: Header.authorization_headers(),
      data: nutri_signup_params
    };
    axios(options)
      .then(response => {
        Header.stopLoader();
        this.setState({
          congrats: true,
          error_popup: false
        });
        var profileData = response.data.user.profile;
        window.localStorage.setItem('nutrisystem_profile_linked', profileData.nutrisystem_profile_linked);
        window.localStorage.setItem('nutrisystem_email', profileData.nutrisystem_email);
        localStorage.setItem('nutrisystem_linked', profileData.nutrisystem_profile_linked);
        var evt = document.createEvent("CustomEvent");
        evt.initCustomEvent('LinkSuccess', true, false, false);
        document.dispatchEvent(evt);
      })
      .catch(error => {
        Header.stopLoader();
        this.setState({
          congrats: false,
          error_popup: true,
          errorCode: error.response.status,
          errorMessage: error.response.data.error
        });
        if (error.response.status === 401) {
          var evt = document.createEvent("CustomEvent");
          if (localStorage.link_page_from_plan_settings) {
            evt.initCustomEvent('LinkErrorSettings', true, false, false);
          }
          else {
            evt.initCustomEvent('LinkErrorOnboarding', true, false, false);
          }
          document.dispatchEvent(evt);
        }
        Sentry.captureException(error);
      });
  }

  linkContinueHandler() {
    this.popUpNavigationOnContinue();
  }

  popUpNavigationOnContinue() {
    if (window.localStorage.link_page_from_plan_settings) {
      this.props.history.push("/plan_settings");
    }
    else {
      this.props.history.push("/ns_plan");
    }
  }

  render() {
    return (
      <div className="main_container">

        <div className="forgot_password_hero">
          <div>
            <img alt="" src="/assets/images/numi_small_logo.png" className="logo img-responsive" />
          </div>
          <div className="nutri_link padding4 relative">


            <div className="nutri_account nutri_link_account" style={{ 'display': (this.state.congrats || this.state.error_popup) ? 'none' : 'block' }}>
              <div className="inner_link">
                <img src="/assets/images/close_grey.png" align="right" className="close_icon" alt="" onClick={this.clickClose.bind(this)} />
                <img src="/assets/images/intro_cart.png" alt="center" />&nbsp;&nbsp;&nbsp; <img src="/assets/images/arrows.png" alt="center" /> &nbsp;&nbsp;&nbsp;<img src="/assets/images/logo143x32.png" alt="center" />
                <p className="font26 color3 link_your_accounts_txt"><b></b></p>
                <p className="font16 mb10 color6 nutrisystem_credentials_required_txt"></p>
                <form onSubmit={this.handleLinkSubmit.bind(this)}>
                  <div>
                    <input type="text" id="nutri_email" placeholder="Nutrisystem Email" value={this.state.nutri_link_email} onChange={this.emailChange} />
                  </div>
                  <div className="pass_div relative">
                    <input type="password" id="nutri_password" placeholder="Nutrisystem Password" value={this.state.nutri_link_password} onChange={this.passwordChange} />
                    <i onClick={this.togglePassword.bind(this)}></i>
                  </div>
                  <button className={this.state.nutri_link_email === '' || this.state.nutri_link_password === '' ? 'btn disabled mt20px' : 'mt20px'} disabled={this.state.nutri_link_email === '' || this.state.nutri_link_password === '' ? true : false} >{window.keystrings['com.numi.ecom.link']}</button>
                </form>
                <Link to="/nutri_account"><button className="mt20px dont_have_account_txt"></button></Link>
                <a href={window.keystrings['com.numi.ecom.forgot_password_url']} target="_blank" rel="noopener noreferrer"><p className="mt20 font12 forgot_link_pass"></p></a>
              </div>
            </div>


            <div className="congrats_msg nutri_account nutri_link_account" style={{ 'display': this.state.congrats ? 'block' : 'none' }}>
              <div className="inner_link">
                <img src="/assets/images/close_grey.png" align="right" className="close_icon" alt="" onClick={this.clickLinkClose.bind(this)} />
                <img alt="" src="/assets/images/congrats.png" />
                <p className="font26 mt10p congratulation_link_txt"></p>
                <button className="mt10p" onClick={this.linkContinueHandler.bind(this)}>Continue</button>
              </div>
            </div>

            <div className="error_msg nutri_account nutri_link_account" style={{ 'display': this.state.error_popup ? 'block' : 'none' }}>
              <div className="inner_link">
                <img src="/assets/images/close_grey.png" align="right" className="close_icon" alt="" onClick={this.showLinkAccount.bind(this)} />
                <img alt="" src="/assets/images/congrats.png" />
                {this.errorParagraph()}
                <button className="mt10p try_again_caps_text" onClick={this.showLinkAccount.bind(this)}></button>
                <Link to="/nutri_account"><p className="mt20px font12 dont_have_account_txt_caps"></p></Link>
              </div>
            </div>

          </div>
        </div>
        <Footer />
      </div>

    );
  }
}
export default NutriLink;
