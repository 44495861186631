import React from 'react';
import {  Link } from 'react-router-dom'
import  Footer from  "../footer.js";
import $ from 'jquery';

class AllSet extends React.Component {
    

    
  componentDidMount() {
    /*keystrings*/
    $('.youre_all_set_text b').prepend(window.keystrings['com.numi.tour.youre_all_set'])
    $('.all_set_desc_text').text(window.keystrings['com.numi.all_set_desc'])
    $('.take_a_tour_text').text(window.keystrings['com.numi.tour.take_a_tour'])
    $('.not_now_device_text').text(window.keystrings['com.numi.not_now_device'])
    /*keystrings*/
    
}
   render() {
      

      return (
         <div className="main_container">
         
           <div className="forgot_password_hero">
           <div><img alt="" src="/assets/images/numi_small_logo.png" className="logo img-responsive cursor_pointer" onClick={()=>this.props.history.push('/journal')}/></div>
              <div className="signup_sec" id="profile_sec">
                <div className="profile_section">
                  <div className="connect_device all_set">
                    <div className="text-center">
                      <img alt="" src="/assets/images/leaf.png"/>
                      <h3 className="text-center journal_hdr lineheight45 youre_all_set_text"><b><br/> { window.localStorage.getItem('FirstName')}!</b></h3>
                      <h6 className="color6 all_set_desc_text"> </h6>
                      
                      <Link to="/tour"><button className="tap_tour_got_it take_a_tour_text"></button></Link>
                       <p><Link to="/journal" className="not_now not_now_device_text"></Link></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <Footer/>
         </div> 

      );
   }
}

export default AllSet;
