import React from 'react';
import {  Link } from 'react-router-dom';
import  Footer from  "../footer.js";
var $ = require("jquery");
window.jQuery = $;
class ResetPassword extends React.Component
{
constructor(props) {
super(props);
this.state = {reset_disable_btn:'btn disabled',reset_disable:'disabled',new_password:'',confirm_password:'',password_error:false};
}

passwordChange(e)
{
this.setState({[e.target.id]: e.target.value});
}

passwordMatch(e)
{
var password_regex = new RegExp("^(?=.*[a-z])(?=.*[0-9])(?=\\S+$)(?=.{6,})");
if((this.state.confirm_password !== this.state.new_password) || (this.state.confirm_password === "" || this.state.new_password === ""))
{
this.setState({
password_error:true
});
}
else
{
this.setState({
password_error:false
})
}

if (!password_regex.test(this.state.new_password) || !password_regex.test(this.state.confirm_password))
{
this.setState({
passwordError : window.keystrings['com.numi.invalid_password_error'],
pass_error : 'error_border',
txt_error: 'error'
});
}
else
{
this.setState({
passwordError : '',
pass_error : '',
txt_error : ''
});
}
}
componentDidMount()
{
$(document).on('keyup','#new_password,#confirm_password',function(){
if(this.state.confirm_password === "" || this.state.new_password === "")
{
this.setState({
reset_disable_btn:'btn disabled',reset_disable:'disabled'
});
}
else
{
this.setState({
reset_disable_btn:'',reset_disable:''
})
}
}.bind(this))
/*keystrings*/
$('.change_your_password_text').text(window.keystrings['com.numi.change_your_password'])
$('.password_do_not_match_text').text(window.keystrings['com.numi.password_do_not_match'])
$('.new_password_text').attr('placeholder', window.keystrings['com.numi.new_password'])
$('.confirm_new_password_text').attr('placeholder', window.keystrings['com.numi.confirm_new_password'])
$('.change_my_password_text').text(window.keystrings['com.numi.change_my_password'])
/*keystrings*/
}


render() {

return (
<div className="main_container">

<div className="reset_password">
<div><Link to="/journal"><img alt="" src="/assets/images/numi_small_logo.png" className="logo img-responsive"/></Link></div>
<div className="signup_sec reset_sec">
<h1 className="change_your_password_text"> </h1>
<p style={{display: this.state.password_error ? 'block' : 'none' }} className="error password_do_not_match_text">P</p>
<div className="error">{this.state.passwordError}</div>

<div className='email_box mt1'><input type="password" id="new_password" onChange={this.passwordChange.bind(this)} value={this.state.new_password} placeholder="" className="new_password_text"/></div>
<div className='email_box mt'><input type="password" id="confirm_password" onChange={this.passwordChange.bind(this)} value={this.state.confirm_password} placeholder="" className="confirm_new_password_text"/></div>

<button onClick={this.passwordMatch.bind(this)} disabled={this.state.reset_disable} className= {this.state.reset_disable_btn+" mt6 change_my_password_text"}></button>
</div>
</div>
 <Footer/>
</div>

);
}
}
export default ResetPassword;