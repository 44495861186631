import React from 'react';
import { Link } from 'react-router-dom';
import Header from "../header.js";
import Footer from "../footer.js";
import axios from 'axios';
import $ from 'jquery';
import * as Sentry from "@sentry/react";

class NutriAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showNutriLink: false, nutri_link_email: '', nutri_link_password: '', congrats: false, showChoose: true, error_popup: false, first_name: '', errorMessage: '' }
    this.showNutriLinkAccount = this.showNutriLinkAccount.bind(this);
    this.emailChange = this.emailChange.bind(this);
    this.passwordChange = this.passwordChange.bind(this);
  }
  showNutriLinkAccount() {
    this.setState({
      showNutriLink: true,
      showChoose: false
    });
    $('body').append('<div class="fade_link_popup"></div>');
  }
  showLinkAccount() {
    this.setState({
      showNutriLink: true,
      error_popup: false
    });
    $('.fade_link_popup').remove();
  }

  clickClose() {
    if (window.localStorage.link_page_from_plan_settings) {
      this.props.history.push("/plan_settings")
    }
    else {
      this.props.history.push("/nutri_link")
      $('.fade_link_popup').remove();
    }
  }

  clickLinkClose() {
    this.popUpNavigationOnContinue();
  }

  togglePassword(e) {
    if (e.target.previousSibling.getAttribute('type') === 'password') {
      e.target.previousSibling.setAttribute('type', 'text');
    }
    else {
      e.target.previousSibling.setAttribute('type', 'password');
    }

  }
  linkNutriAccount() {
    this.setState({
      congrats: true,
      showNutriLink: false
    });
    $('.fade_link_popup').remove();
    $('body').append('<div class="fade_link_popup"></div>');
  }

  emailChange(e) {
    this.setState({
      nutri_link_email: e.target.value
    })
  }

  passwordChange(e) {
    this.setState({
      nutri_link_password: e.target.value
    })
  }
  nameChange(e) {
    this.setState({
      first_name: e.target.value
    })
  }

  componentDidMount() {
    $('.choose_nutri_hdr b').text(window.keystrings['com.numi.choose_plan_title']);
    $('.ecom_create_account').text(window.keystrings['com.numi.ecom.create_account']);
    $('.sign_up_today_for_offers_txt').text(window.keystrings['com.numi.ecom.sign_up_today']);
    $('.profile_hdr').text(window.keystrings['com.numi.ecom.profile_info']);
    $('.congratulation_link_txt').text(window.keystrings['com.numi.congratulation_link_txt']);
    $('.link_continue').text(window.keystrings['com.numi.continue']);
  }

  handleNutriSignup(e) {
    e.preventDefault();
    var first_name = $('#first_name').val();
    var nutri_link_email = $('#nutri_link_email').val();
    var nutri_link_password = $('#nutri_link_password').val();
    var nutri_signup_params = { "nutrisystem_profile": { "email": nutri_link_email, "password": nutri_link_password, first_name: first_name } };
    Header.startLoader();
    const options = {
      url: window.API_URL + "v1/nutrisystem/profile",
      method: 'POST',
      headers: Header.authorization_headers(),
      data: nutri_signup_params
    };
    axios(options)
      .then(response => {
        Header.stopLoader();
        this.setState({
          congrats: true
        });
        window.localStorage.setItem('nutrisystem_profile_linked', true);
        window.localStorage.setItem('nutrisystem_email', response.data.nutrisystem_profile.email);
      })
      .catch(error => {
        Header.stopLoader();
        this.setState({
          errorMessage: error.response.data.error
        });
        $('#invalid_nutri_signup').modal('show');
        Sentry.captureException(error);
      });
  }

  linkContinueHandler() {
    this.popUpNavigationOnContinue();
  }

  popUpNavigationOnContinue() {
    if (window.localStorage.link_page_from_plan_settings) {
      this.props.history.push("/plan_settings");
    }
    else {
      this.props.history.push("/ns_plan");
    }
  }



  checkPassword() {
    var password = this.state.nutri_link_password;

    if (!password.match(/([a-zA-Z])/)) {
      $('.pass_alpha').removeClass('validate_pass');

    }
    else {
      $('.pass_alpha').addClass('validate_pass');


    }

    if (!password.match(/([0-9])/)) {
      $('.pass_number').removeClass('validate_pass');

    }
    else {
      $('.pass_number').addClass('validate_pass');

    }
    if (password.length >= 6) {
      $('.pass_length').addClass('validate_pass');
    }
    else {
      $('.pass_length').removeClass('validate_pass');
    }
    if (!password.match(/([/,", ])/)) {
      $('.pass_special_chars').addClass('validate_pass');
    }
    else {
      $('.pass_special_chars').removeClass('validate_pass');

    }





  }

  render() {

    return (
      <div className="main_container">

        <div className="forgot_password_hero">
          <div>
            <img alt="" src="/assets/images/numi_small_logo.png" className="logo img-responsive" />
          </div>
          <div className="nutri_link padding4 relative">

            <div className="nutri_account nutri_link_account create_nutri_account" style={{ 'display': this.state.congrats ? 'none' : 'block' }}>
              <Link to="/nutri_link"> <img alt="" src="/assets/images/back_white_icon.png" className="left_arrow" /></Link>
              <div className="inner_link">
                <img src="/assets/images/close_grey.png" align="right" className="close_icon" alt="" onClick={this.clickClose.bind(this)} />
                <img src="/assets/images/intro_cart.png" alt="center" />&nbsp;&nbsp;&nbsp; <img src="/assets/images/arrows.png" alt="center" /> &nbsp;&nbsp;&nbsp;<img src="/assets/images/logo143x32.png" alt="center" />
                <p className="font26 color3 ecom_create_account"><b></b></p>
                <p className="font16 mb10 color6 sign_up_today_for_offers_txt"></p>
                <form onSubmit={this.handleNutriSignup.bind(this)}>
                  <div className="width89">
                    <h4 className="text-left profile_hdr"> </h4>
                    <div><input type="text" id="first_name" className="width100" placeholder="First Name*" maxLength="40" value={this.state.first_name} onChange={this.nameChange.bind(this)} /></div>
                    <div><input type="email" id="nutri_link_email" className="width100" placeholder="Email*" maxLength="100" value={this.state.nutri_link_email} onChange={this.emailChange} /></div>
                    <div className="pass_div relative"><input type="password" onKeyUp={this.checkPassword.bind(this)} id="nutri_link_password" className="width100" placeholder="Password*" maxLength="40" value={this.state.nutri_link_password} onChange={this.passwordChange} /><i onClick={this.togglePassword.bind(this)}></i></div>
                    <div className="pass_validate">
                      <p className="text-left font18">Password must contain:</p>
                      <ul className="text-left pass_validate">
                        <li className="pass_number">At least one number (0-9)</li>
                        <li className="pass_alpha">At least one letter (a-z)</li>
                        <li className="pass_length">At least 6 characters</li>
                        <li className="pass_special_chars">No spaces, forward slashes (/) or double quote marks (")</li>
                      </ul>
                    </div>
                  </div>
                  <button className={this.state.nutri_link_email === '' || this.state.nutri_link_password === '' ? 'btn disabled mt20px width89' : 'mt20px width89'} disabled={this.state.nutri_link_email === '' || this.state.nutri_link_password === '' || this.state.first_name === '' ? true : false} >{window.keystrings['com.numi.ecom.create']}</button>
                </form>
              </div>
            </div>

            <div className="congrats_msg nutri_account nutri_link_account" style={{ 'display': this.state.congrats ? 'block' : 'none' }}>
              <div className="inner_link">
                <img src="/assets/images/close_grey.png" align="right" className="close_icon" alt="" onClick={this.clickLinkClose.bind(this)} />
                <img alt="" src="/assets/images/congrats.png" />
                <p className="font26 mt10p congratulation_link_txt"></p>
                <button className="mt10p link_continue" onClick={this.linkContinueHandler.bind(this)}></button>
              </div>
            </div>



          </div>
        </div>
        <Footer />
        <InvalidNutriSignup errorMessage={this.state.errorMessage} />
      </div>

    );
  }
}

class InvalidNutriSignup extends React.Component {
  componentDidMount() {
    $('.modal-backdrop').remove();
    $('body').removeClass('modal-open');
    $('.remove_fade').on('click', function () {
      $('.modal').modal('hide');
    });

    /*keystrings*/
    $('.try_again_caps_text').text(window.keystrings['com.numi.try_again_caps_text']);
  }

  errorParagraph() {
    return (
      <p className="font16 color6">{this.props.errorMessage}</p>
    )
  }

  render() {
    return (
      <div className="modal fade" id="invalid_nutri_signup">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><img alt="" src="/assets/images/close_grey.png" /></button>
            </div>
            <div className="invalidemail" style={{ display: 'block' }}>
              <div className="modal-body width77">
                <img alt="" src="/assets/images/warning_tri.png" />
                {this.errorParagraph()}
                <p><button className="buuton280 login_fail_ok try_again_caps_text" data-dismiss="modal"></button></p>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}

export default NutriAccount;
