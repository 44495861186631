import React from 'react';
import { Link } from 'react-router-dom'
import Header from "../header.js";
import Footer from "../footer.js";
import axios from 'axios';
import Login from "./login.js";
import moment from 'moment'
import $ from 'jquery';
import * as Sentry from "@sentry/react";

class Accountdetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data: 'password', text: window.keystrings['com.numi.show_all_caps'], emailError: '', Error_class: '', error_icon: '', passwordError: '', pass_error: '', txt_error: '' };
  }


  toggle_password = (event) => {
    var password_txt = document.getElementsByClassName('password_input')[0].value;
    if (password_txt.length > 0) {
      if (this.state.data === 'password') {
        this.setState({ data: 'text', text: window.keystrings['com.numi.hide_all_caps'] });
      }
      else {
        this.setState({ data: 'password', text: window.keystrings['com.numi.show_all_caps'] });
      }
    }
  }

  validateEmail = (e) => {
    var email_regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var password_regex = new RegExp("^(?=.*[a-zA-Z0-9])(?=.{6,})");
    if (!email_regex.test($('.email_box input').val())) {
      this.setState({
        emailError: window.keystrings['com.numi.wrong_email_format'],
        Error_class: 'error_border',
        error_icon: 'error_icon'
      });
    }
    else {
      this.setState({
        emailError: '',
        Error_class: '',
        error_icon: this.state.success_icon
      });
    }

    if (!password_regex.test($('.password_input').val())) {
      this.setState({
        passwordError: window.keystrings['com.numi.invalid_password_error'],
        pass_error: 'error_border',
        txt_error: 'error'
      });
    }
    else {
      this.setState({
        passwordError: '',
        pass_error: '',
        txt_error: ''
      });
    }

  }


  // To get the input value of email
  emailChange = (e) => {
    this.setState({ email: e.target.value });
  }

  // To get the input value of password
  passwordChange = (e) => {
    this.setState({ password: e.target.value });
  }

  doValidateSubmit = (e) => {
    e.preventDefault();
    this.validateEmail();
    this.handleSignUpClick();
  }

  // SignUp api call
  handleSignUpClick = (e) => {
    if (!$('#remember').is(':checked')) {
      $('.show_check_error').text(window.keystrings['com.numi.agree_terms_conditions']);
      return;
    }
    else {
      $('.show_check_error').text('');
    }
    var user_email = this.state.email;
    localStorage.setItem('current_user_email', user_email);
    window.localStorage.removeItem('user_access');
    if (this.state.emailError === "" && this.state.passwordError === "") {
      Header.startLoader();

      const options = {
        url: window.API_URL + "oauth/signup.json",
        method: 'POST',
        credentials: 'same-origin',
        headers: Header.setAuthHeaders(),
        data: {
          user: {
            email: this.state.email,
            password: this.state.password
          },
        }
      };

      axios(options)
        .then(response => {

          window.localStorage.setItem('user_id', response.data.user.user_id);
          window.localStorage.setItem('current_user_email', response.data.user.email);
          window.localStorage.setItem('user_access_token', response.data.user.access_token);
          window.localStorage.setItem('news_feed_key', response.data.user.news_feed_key);
          window.localStorage.setItem('numi_legacy', "false");
          window.localStorage.setItem('user_access', response.data.user.access);
          window.localStorage.setItem('user_refresh', response.data.user.refresh);
          window.localStorage.setItem('user_access_expires_at', response.data.user.access_expires_at);
          window.localStorage.setItem('user_access_expires_in', response.data.user.access_expires_in);
          var evt = document.createEvent("CustomEvent");
          evt.initCustomEvent('SignUpSuccess', true, false, false);
          document.dispatchEvent(evt);

          var evt1 = document.createEvent("CustomEvent");
          evt1.initCustomEvent('ProfileDetails', true, false, false);
          document.dispatchEvent(evt1);

          Header.stopLoader();
          if (response.data.user.profile) {
            Login.createSessionVariables(response.data);
            var profile_status = window.localStorage.getItem('profile_status');
            var numiLegacy = window.localStorage.getItem('numi_legacy');
            if (profile_status === null || profile_status === "NEW_USER") {
              this.props.history.push('/signup');
            }
            else if (profile_status === "ONBOARDING_COMPLETE") {
              window.localStorage.setItem('assigned_date', moment().format('YYYY-MM-DD'));
              this.props.history.push('/journal');
            }
            else if ((profile_status === "PROFILE_COMPLETE" || profile_status === "ONBOARDING") && numiLegacy === 'true') {
              this.props.history.push('/welcome');
            }
            else if (profile_status === "PROFILE_COMPLETE" || profile_status === "ONBOARDING") {
              this.props.history.push('/ns_plan');
            }
            else if (profile_status === "PLAN_COMPLETE") {
              this.props.history.push('/stay_on_track');
            }
            else {
              alert("Undifined profile status:" + profile_status);
            }
          }
          else {
            this.props.history.push('/profile');
          }

        })
        .catch(error => {
          Header.stopLoader();
          if (error && error.response && error.response.data && error.response.data.error) {
            $('.account_section,.left_arrow').hide();
            $('.numi_account').show();
            if (error.response.data.error.email) {
              $('.numi_account h3').text(error.response.data.error.email);
            }
            else if (error.response.data.error.password) {
              $('.numi_account h3').text(error.response.data.error.password);
            }
            else if (error.response.status === 409) {
              $('#fade_popup').remove();
              $('<div  id="fade_popup" style="background: #000;    width: 100%;    height: 100%;    z-index: 99;    top: 0;    position: fixed;    opacity: .3;"></div>').appendTo('body');
            }
            else {
              $('.numi_account h3').text(error.response.data.error);
            }
            $('#fade_popup').remove();
            $('<div  id="fade_popup" style="background: #000;    width: 100%;    height: 100%;    z-index: 99;    top: 0;    position: fixed;    opacity: .3;"></div>').appendTo('body');
          }
          Sentry.captureException(error);
        });

    }
  }
  // SignUp api call END

  componentDidMount() {
    if (window.localStorage.getItem('gender') === null) {
      this.props.history.push('/signup');
    }


    /*keystrings*/
    $('.account_details_label_text').text(window.keystrings['com.numi.account_details_label'])
    $('.email_text input').attr('placeholder', window.keystrings['com.numi.email'])
    $('.password_text input').attr('placeholder', window.keystrings['com.numi.password'])
    $('.account_details_footer_text').append(window.keystrings['com.numi.account_details_footer'])
    $('.account_details_button_text').attr('value', window.keystrings['com.numi.account_details_button'])
    $('.account_exists_text').text(window.keystrings['com.numi.account_exists'])
    $('.forgot_password_text').text(window.keystrings['com.numi.forgot_password'])
    $('.forgot_numi_password_text').text(window.keystrings['com.numi.forgot_numi_password']);
    $('.log_in_text,.log_into_numi_text').text(window.keystrings['com.numi.log_in'])
    $('.close_text').text(window.keystrings['com.numi.close']);
    $('.try_again_caps_text').text(window.keystrings['com.numi.try_again_caps_text']);
    /*$('.please_signin').text(window.keystrings['com.numi.link_numi_to_nutri']);*/
    /*keystrings*/
  }

  closeFade = () => {
    $('.account_section,.left_arrow').show();
    $('.numi_account').hide();
    $('#fade_popup').remove();
  }

  render() {

    return (
      <div className="main_container">

        <div className="forgot_password_hero">
          <div><img alt="" src="/assets/images/numi_small_logo.png" className="logo img-responsive" /></div>
          <div className="account_sec">
            <Link className="align_account_link" to="/signup"><img alt="" src="/assets/images/back_white_icon.png" className="left_arrow" /></Link>
            <div className="account_section">
              <h3 className="account_details_label_text"> </h3>
              <form onSubmit={this.doValidateSubmit} >
                <p className='email_box email_text'><input type="text" onChange={this.emailChange} className={this.state.Error_class} placeholder="" /><br /><span className="error">{this.state.emailError}</span></p>
                <p className="password_field password_text">
                  <input type={this.state.data} onChange={this.passwordChange} className="password_input mandatory" id={this.state.pass_error} placeholder="" /><span className="show_txt" id={this.state.txt_error} onClick={this.toggle_password}>{this.state.text}</span>
                  <span className="error">{this.state.passwordError}</span>
                </p>
                <p className="terms remember_me account_details_footer_text"><input id="remember" type="checkbox" checked={this.state.isChecked} onChange={this.rememberCheckbox} /><label htmlFor="remember">
                  <span className="bullet_icon">
                    <i></i>
                  </span>
                  <span>

                  </span>
                </label></p>
                <h6 className="show_check_error" id="error"> </h6>
                <p><input type="submit" className="account_submit account_details_button_text" value="Sign Up" /></p>
              </form>
            </div>

            <div className="numi_account">
              <img alt="" src="/assets/images/warning_tri.png" className="warning" />
              <h3 className="account_exists_text"> </h3>
              <h5 className="please_signin"> </h5>
              <button className="close_btn tap_signup_error_close try_again_caps_text account_details" onClick={this.closeFade}></button><br />
              <Link onClick={this.closeFade} to="/login" ><button className="close_btn tap_signup_error_login log_into_numi_text"></button></Link><br />
              <Link onClick={this.closeFade} to="/forgot_password"><button className="close_btn tap_signup_error_password forgot_numi_password_text"></button></Link>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default Accountdetails;
