import React from 'react';
import Navigation from "../nav.js";
import Footer from "../footer.js";
import Header from "../header.js";
import axios from 'axios';
import $ from 'jquery';
import * as Sentry from "@sentry/react";
const dragDrop = require('drag-drop')



class UploadPhotos extends React.Component {
  state = { isChecked: true, imagetag: [] }




  componentDidMount = () => {
    const options = {
      url: window.API_URL + 'v1/photo_captures/image_tags',
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };
    axios(options)
      .then(response => {
        this.setState({
          imagetag: response.data.image_tags
        })
      }).catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });;


    $('.alert-danger').text('');
    dragDrop('[for="upload_btn"]', (files, pos, fileList, directories) => {
      localStorage.setItem('dragDrop', true);
      readImageURL(fileList);

    })

    window.initial_array = [];
    //window.final_array = [];
    var divIterator = 0;
    var image_max_size = $('.show_error_msg.image_size').data('image-max-size') * 1024;
    //var obj = {};
    var image_upload_limit = window.keystrings['com.numi.max_photos_count'] ? window.keystrings['com.numi.max_photos_count'] : 9;
    $(document).on('change', "#upload_btn", function (e) {
      validateFields();
      readImageURL(this, e);
    });
    function readImageURL(input, event) {

      var total_file;
      if (localStorage.getItem('dragDrop') === 'true') {
        input.files = input;
        total_file = input.length;
      }
      else {
        total_file = document.getElementById("upload_btn").files.length;

      }
      if ($('.uploaded_img').length < image_upload_limit) {
        var extension, fileTypes, reader;
        fileTypes = ['jpg', 'jpeg', 'png', 'gif'];

        for (var i = 0; i < total_file; i++) {
          var image_size = (input.files[i].size / 1024);
          //var m = $('.uploaded_img').length;
          extension = input.files[i].name.split('.').pop().toLowerCase();

          var size_split = image_size.toString().split('.');
          if (input.files && input.files[i] && fileTypes.indexOf(extension) > -1 && image_size / image_max_size < 1) {
            reader = new FileReader();
            reader.readAsDataURL(input.files[i]);
            //var ulpoaded_image_length = $('.uploaded_img').length + 1;
            if ($('.uploaded_img').length >= (image_upload_limit - 1)) {
              $(".upload_file").hide();
              if ($('.uploaded_img').length === (image_upload_limit - 1)) {
                $('#image_preview').append("<li class='uploaded_img'><div id='uploaded_img_div_" + (++divIterator) + "'></div><div class='image_size'>" + size_split[0] + "kb</div><input type='file' accept='image/jpeg, image/jpg, image/png' id='refresh_btn" + divIterator + "' class='hidden refresh_btn" + divIterator + "' /><span class='mobile_edit'><i class='refresh' ></i></span><span class='desktop_edit'><label for='refresh_btn" + divIterator + "'><i class='refresh' set_id='" + divIterator + "'></i></label><i class='delete' set_id='" + input.files[i].name + "'></i></span></li>");
                createCanvas(input.files[i], "uploaded_img_div_" + divIterator)
                window.initial_array.push(input.files[i]);
              }
            } else {
              $(".upload_file").show();
              $('#image_preview').append("<li class='uploaded_img'><div id='uploaded_img_div_" + (++divIterator) + "'></div><div class='image_size'>" + size_split[0] + "kb</div><input type='file' accept='image/jpeg, image/jpg, image/png' id='refresh_btn" + divIterator + "' class='hidden refresh_btn" + divIterator + "' /><span class='mobile_edit'><i class='refresh' ></i></span><span class='desktop_edit'><label for='refresh_btn" + divIterator + "'><i class='refresh' set_id='" + divIterator + "'></i></label><i class='delete' set_id='" + input.files[i].name + "'></i></span></li>");
              createCanvas(input.files[i], "uploaded_img_div_" + divIterator)
              window.initial_array.push(input.files[i]);
            }
            reader.onload = function (e) {
              $('.photo_submit_btn').removeAttr('disabled').css('background-color', '#35B5AF').removeClass('cursor_not');
            };
          } else {
            if (image_size / image_max_size > 1) {
              $('.image_type').removeClass('hide').removeAttr('style').fadeOut(8000);
              $('.doc_type').addClass('hide');
              $('.photo_submit_btn').removeAttr('disabled').css('background-color', '#35B5AF').removeClass('cursor_not no_pointer');

            }
            if (fileTypes.indexOf(extension) < 0) {
              $('.doc_type').removeClass('hide').removeAttr('style').fadeOut(8000);
              $('.image_type').addClass('hide');
              $('.photo_submit_btn').removeAttr('disabled').css('background-color', '#35B5AF').removeClass('cursor_not no_pointer');
            }
            if (fileTypes.indexOf(extension) > -1 && image_size / image_max_size < 1) {
              $('#image_preview').append("<li class='uploaded_img'><div id='uploaded_img_div_" + ++divIterator + "'></div><div class='show_error_txt image_size'>" + size_split[0] + "kb</div><input type='file' accept='image/jpeg, image/jpg, image/png' id='refresh_btn" + divIterator + "' class='hidden refresh_btn" + divIterator + "' /><span class='mobile_edit'><i class='refresh' ></i></span><span class='desktop_edit'><label for='refresh_btn" + divIterator + "'><i class='refresh' set_id='" + divIterator + "'></i></label><i class='delete' set_id='" + input.files[i].name + "'></i></span></li>");
              createCanvas(input.files[i], "uploaded_img_div_" + divIterator);
              window.initial_array.push(input.files[i]);
            }


          }
          // if (fileTypes.indexOf(extension) > -1 && image_size / image_max_size < 1 && $('.uploaded_img').length < image_upload_limit) {
          //   obj = window.initial_array[m].name;
          //   window.final_array.push(obj);
          // }

          var c = $("#upload_count").val();
          $('#upload_btn').attr('id', 'upload_btn' + c + '');

          $('#upload_repeat').append('<input class="hidden" id="upload_btn" multiple="multiple" name="photo_capture[file][]" type="file" accept="image/jpeg, image/jpg, image/png">');
          $("#upload_count").val(parseInt(c) + 1);

        }
      }
      localStorage.removeItem('dragDrop');
    }


    $(document).on('change', "input[id*='refresh_btn']", function (event) {
      event.stopImmediatePropagation();
      var removeItem = $(this).parents('.uploaded_img').find('.delete').attr('set_id');
      deleteExisting(removeItem);
      var extension, fileTypes, reader;
      fileTypes = ['jpg', 'jpeg', 'png', 'gif'];
      var total_file = this.files.length;
      if ($('.uploaded_img').length === 1) {
        // window.final_array = [];
        window.initial_array = [];
      }
      for (var i = 0; i < total_file; i++) {
        var image_size = (this.files[i].size / 1024);
        extension = this.files[i].name.split('.').pop().toLowerCase();
        if (fileTypes.indexOf(extension) > -1 && image_size / image_max_size < 1) {
          window.initial_array.push(this.files[i]);
          // obj = this.files[i].name;
          // window.final_array.push(obj);
        }

        var size_split = image_size.toString().split('.');
        if (fileTypes.indexOf(extension) > -1) {
          $(this).parents('.uploaded_img').find('img').remove();
        }
        if (this.files && this.files[i] && fileTypes.indexOf(extension) > -1 && image_size / image_max_size < 1) {
          reader = new FileReader();
          reader.onload = function (e) {
            $('.image_type,.doc_type').addClass('hide');
            validateFields();
          };
          reader.readAsDataURL(this.files[i]);
          // var divIterator = $('.uploaded_img').length + 1;
          let divs = $(this).parents('.uploaded_img');
          // $(this).parents('.uploaded_img').find('input[type="image"]').remove();
          // $(this).parents('.uploaded_img').find('.image_size').remove();
          divs.empty()
          divs.prepend("<div id='uploaded_img_div_" + (++divIterator) + "'></div><div class='show_error_txt image_size'>" + size_split[0] + "kb</div><input type='file' accept='image/jpeg, image/jpg, image/png' id='refresh_btn" + divIterator + "' class='hidden refresh_btn" + divIterator + "' /><span class='mobile_edit'><i class='refresh' ></i></span><span class='desktop_edit'><label for='refresh_btn" + divIterator + "'><i class='refresh' set_id='" + divIterator + "'></i></label><i class='delete' set_id='" + this.files[i].name + "'></i></span>");
          createCanvas(event.target.files[i], "uploaded_img_div_" + divIterator)
        } else {
          let divs = $(this).parents('.uploaded_img');
          if (image_size / image_max_size > 1) {
            $('.image_type').removeClass('hide').removeAttr('style').hide(8000);
            $(this).parents('.uploaded_img').remove();
            $('.doc_type').addClass('hide');
          }
          if (fileTypes.indexOf(extension) < 0) {
            $('.doc_type').removeClass('hide').removeAttr('style').hide(8000);;
            $('.image_type').addClass('hide');
          }

          if (fileTypes.indexOf(extension) > -1 && image_size / image_max_size < 1) {
            // $(this).parents('.uploaded_img').find('input[type="image"]').remove();
            // $(this).parents('.uploaded_img').find('.image_size').remove();
            divs.empty();
            divs.prepend("<div id='uploaded_img_div_" + (++divIterator) + "'></div><div class='show_error_txt image_size'>" + size_split[0] + "kb</div><input type='file' accept='image/jpeg, image/jpg, image/png' id='refresh_btn" + divIterator + "' class='hidden refresh_btn" + divIterator + "' /><span class='mobile_edit'><i class='refresh' ></i></span><span class='desktop_edit'><label for='refresh_btn" + divIterator + "'><i class='refresh' set_id='" + divIterator + "'></i></label><i class='delete' set_id='" + this.files[i].name + "'></i></span>");
            createCanvas(event.target.files[i], "uploaded_img_div_" + divIterator)
          }

        }
      }
    });

    $('.choose_milestone').change(function () {
      validateFields();
    })

    $(document).on('click', '.delete', function (e) {
      e.stopImmediatePropagation();
      var removeItem = $(this).attr('set_id');
      deleteExisting(removeItem);
      $(this).parents('.uploaded_img').remove();
      $('.show_error_msg').hide();
      validateFields();

      $('.upload_file').removeClass('no_pointer');

      if ($('.uploaded_img').length > (image_upload_limit - 1)) {
        $(".upload_file").hide();
      } else {
        $(".upload_file").show();
      }
    });
    $(document).on('click', '.terms_check_box', function () {
      if ($(this).is(':checked')) {
        $('.continue_btn').removeClass('no_pointer');
      } else {
        $('.continue_btn').addClass('no_pointer');
      }
    });

    function deleteExisting(removeItem) {
      for (var i = window.initial_array.length - 1; i >= 0; i--) {
        if (window.initial_array[i].name === removeItem) {
          window.initial_array.splice(i, 1);
          break;

        }
      }


      /*for (var j = window.final_array.length - 1; j >= 0; j--) {
        if (window.final_array[j] === removeItem) {
          window.final_array.splice(j, 1);
        }
      }*/
    }


    function validateFields() {
      setTimeout(function () {
        if ($('.choose_milestone').val() === "" || $('.choose_milestone').val() === undefined || $('.uploaded_img').length === 0) {
          $('.photo_submit_btn').attr('disabled', true).css('background-color', '#cccccc').addClass('cursor_not no_pointer');
          return;
        } else {
          $('.photo_submit_btn').removeAttr('disabled').css('background-color', '#35B5AF').removeClass('cursor_not no_pointer');
        }
      }, 150);

    }


    function createCanvas(image, appendId) {

      if (image.name.indexOf(".gif") > -1) {
        document.getElementById(appendId).innerHTML = "<img src='" + URL.createObjectURL(image) + "'>"
      } else {
        window.loadImage(image, function (img) {
          if (img.type === "error") {
          } else {
            window.EXIF.getData(image, function () {
              var orientation = window.EXIF.getTag(this, "Orientation");
              var canvas = window.loadImage.scale(img,
                { orientation: orientation || 0, canvas: true, maxWidth: 200 });
              if (canvas && document.querySelector('#' + appendId) && !document.querySelector('#' + appendId).querySelector('canvas')) {
                document.getElementById(appendId).appendChild(canvas);
              }

              // or using jquery $("#container").append(canvas);
            });
          }
        });
      }
    };
  };

  uploadPhotos = (e) => {
    e.preventDefault();
    Header.startLoader();
    var _this = this;
    var formData = new FormData();
    formData.append("photo_capture[tags]", $('#photo_capture_tags').val());
    formData.append("photo_capture[notes]", $('#photo_capture_notes').val());
    formData.append("photo_capture[opt_in]", this.state.isChecked ? true : false);

    $.each(window.initial_array, function (index, value) {
      formData.append("photo_capture[file][]", window.initial_array[index]);
    });



    const options = {
      url: window.API_URL + 'v1/photo_captures/',
      method: 'POST',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
      data: formData
    };
    axios(options)
      .then(response => {

        window.initial_array = [];
        // window.final_array=[];
        Header.stopLoader();
        $('.alert-danger').text('');
        sessionStorage.setItem('weight_loss', $('#photo_capture_tags option:selected').attr('value'));
        _this.props.history.push('/photos');

      })
      .catch(error => {
        Header.stopLoader();
        if (error && error.response && error.response.data && error.response.data.error) {
          $('.alert-danger').text('');
          $('.alert-danger').text(error.response.data.error);
          $(".alert-danger").fadeTo(5000, 500).slideUp(500, function () {
            $(".alert-danger").slideUp(500);
          });
        }
        else {
          if (error && error.response && error.response.status && error.response.status === 413) {
            $('alert-danger').text('');
            $('.alert-danger').text(error.response.statusText);
            $(".alert-danger").fadeTo(5000, 500).slideUp(500, function () {
              $(".alert-danger").slideUp(500);
            });
          }
        }
        Sentry.captureException(error);
      });
  }

  toggleCheckbox = (e) => {
    this.setState({
      isChecked: !this.state.isChecked
    });
  }



  render() {
    return (
      <div>
        <div className="table_displa photos_page">
          <div className="journal_div  profile_page_sec">
            <div className="section_left pull-left">
              <Navigation />
            </div>
            <div className="section_right pull-left">
              <div className="journal_rht my_profile" style={{ padding: '0px' }}>
                <center id="title_bar">
                  <img alt="Photos" src="/assets/images/numi_logo_images.png" width="100" />
                </center>
                <div className="middle_container">
                  <div className="photo_capture">

                    <form id="upload_image_form" onSubmit={this.uploadPhotos}>
                      <p className="select_photo_hdr">1. Upload Your Photo</p>
                      <div className="show_error_msg image_type image_size hide" data-image-max-size="10">
                        Image size is greater than 10MB
                      </div>
                      <div className="show_error_msg doc_type hide">We accept JPG, GIF, or PNG files only</div>
                      <div id="upload_repeat">
                        <input multiple="multiple" className="upload_btn" id="upload_btn" accept="image/jpeg, image/jpg, image/png" type="file" name="photo_capture[file][]" />
                      </div>
                      <ul id="image_preview"></ul>
                      <div className="clearit"></div>
                      <div id="upload_file_btn">
                        <label className="upload_file" htmlFor="upload_btn">UPLOAD FROM FILE</label>
                      </div>
                      <p className="select_photo_hdr">2. Choose a Milestone</p>
                      <p>
                        <select className="choose_milestone" name="photo_capture[tags]" id="photo_capture_tags">
                          <option value="">Select a Milestone</option>
                          {
                            this.state.imagetag.map((name, i) =>
                              <option key={i} value={name.name.replace(/_/g, ' ')}> {name.label} </option>
                            )
                          }
                        </select>
                      </p>

                      <p className="select_photo_hdr">
                        3. Share Your Story
                        <span className="optional">(Optional)</span>
                      </p>
                      <p>
                        <textarea name="photo_capture[notes]" id="photo_capture_notes"></textarea>
                      </p>
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <label className="photo-switch">
                                <input type="checkbox" id="image_opt_in" name="photo_capture[opt_in]" checked={this.state.isChecked} onChange={this.toggleCheckbox} />
                                <span className="photo-slider round"></span>
                              </label>
                            </td>
                            <td>
                              <p className="font15">
                                <span data-target="#tellmemore" className="cursor_pointer" data-toggle="modal">Yes, I would like to share my photo</span> with the Nutrisystem team for a chance to be featured as a Success Story.
                                <span id="tell_me_more_href" data-target="#tellmemore" data-toggle="modal">Tell me more.</span>
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <input type="hidden" id="upload_count" value="1" />
                      <input value="9" type="hidden" name="photo_capture[upload_limit]" id="photo_capture_upload_limit" />
                      <input type="hidden" name="final_array" id="final_array" />
                      <div className="alert alert-danger">

                      </div>
                      <p className="mt15">
                        <button type="submit" className="photo_submit_btn btn disable cursor_not" disabled="disabled" id="image_upload">ADD PHOTO</button>
                      </p>
                    </form>
                  </div>
                </div>


                <div className="modal tellmemore" id="tellmemore" tabIndex="-1" role="dialog" aria-labelledby="tellmemore">
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-body">
                        <ul>
                          <li>By opting in, you may receive an email from the Nutrisystem Customer Experience team.</li>
                          <li>The Nutrisystem Customer Experience team will not use your uploaded photos without permission.</li>
                          <li>Your photos will still be uploaded to your NuMi profile if you turn the slider off.</li>
                        </ul>
                        <center><button type="button" data-dismiss="modal">CLOSE</button></center>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default UploadPhotos;
