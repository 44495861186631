import React from 'react';
import Header from "../header.js";
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import $ from 'jquery';
import * as Sentry from "@sentry/react";

class FoodFilter extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      initialItems: [],
      recent_items: [], show_search: false, toggle_seacrh_icon: true, close_white: false, list_group: false, search_display: false, search_icon: false, recent_search: false, seachValue: '', searchinputValue: '', search_results: {}, recent_foods: [], suggested_foods: [], suggested: false, doc_count: '', fc: '', buckets: [], valid_food_categories: []
    };
    this.filterfoodList = this.filterfoodList.bind(this);
    this.showSearch = this.showSearch.bind(this);
    this.closefoodWhite = this.closefoodWhite.bind(this);
    this.seacrhfoodFocus = this.seacrhfoodFocus.bind(this);
    this.recentfoodSearch = this.recentfoodSearch.bind(this);
    this.callreplacedInput = this.callreplacedInput.bind(this);
    this.getfoodRecommandation = this.getfoodRecommandation.bind(this);
    this.foodAutoComplete = this.foodAutoComplete.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.foodSearchIcon = this.foodSearchIcon.bind(this);
  }
  handleInputChange(event) {
    if (event.charCode === 13) {
      this.foodSearchIcon();
    }

    this.setState({
      searchinputValue: event.target.value
    });
  }

  foodSearchIcon = () => {
    if (window.localStorage.getItem("meal_occasion_id") === null || window.localStorage.getItem("meal_occasion_id") === "") {
      var bucket_sequence;
      var currentHour = moment().format('HH');

      if (currentHour <= 10 && currentHour >= 0) {
        bucket_sequence = '1';
      } else if (currentHour > 10 && currentHour < 12) {
        bucket_sequence = '2';
      } else if (currentHour >= 12 && currentHour < 14) {
        bucket_sequence = '3';
      } else if (currentHour >= 14 && currentHour < 17) {
        bucket_sequence = '4'
      } else if (currentHour >= 17 && currentHour < 20) {
        bucket_sequence = '5';
      } else if (currentHour >= 20 && currentHour <= 24) {
        bucket_sequence = '6';
      }
      window.localStorage.setItem("meal_occasion_id", bucket_sequence);
    }


    $(".list-group").hide();
    window.localStorage.setItem('page_number', 1);
    window.localStorage.setItem('search_from_header', true);
    window.localStorage.setItem('log_food_search', true);
    if ($('.journal_page').length) {
      this.props.history.push('/log_food');
    }
    if ($('[href="#recent"]').parent().hasClass('active')) {
      this.props.callParentRecentFood();
    } else if ($('[href="#favorite"]').parent().hasClass('active')) {
      this.props.callParentFavFood();
    } else if (this.props.callParentLogFood) {
      this.props.callParentLogFood();
    }


  }

  showSearch(e) {
    this.setState({
      show_search: true,
      toggle_seacrh_icon: false,
      close_white: true,
      search_display: !this.state.search_display,
      search_icon: true,
      searchinputValue: ''
    });
  }

  closefoodWhite() {
    this.setState({
      show_search: false,
      toggle_seacrh_icon: true,
      close_white: false,
      list_group: false,
      recent_search: false,
      suggested: false,
      search_display: !this.state.search_display,
      search_icon: false
    });
    document.getElementsByClassName('activity_empty')[0].style.display = "none";

    document.getElementsByClassName('food_recent_list_ul')[0].style.display = "none";
    $('.food_recent_list_ul').empty();

  }

  seacrhfoodFocus() {
    this.setState({ list_group: !this.state.list_group, recent_search: true, suggested: false })
  }


  filterfoodList(event) {
    $(".list-group").show();
    $(".list-group-item recent_search").show();
    var updatedList = this.state.initialItems;
    if (updatedList) {
      updatedList = updatedList.filter(function (item) {
        return item.toLowerCase().search(
          event.target.value.toLowerCase()) !== -1;
      });
    }

    this.setState({ items: updatedList, searchinputValue: event.target.value });
    this.recentfoodSearch();

  }

  foodAutoComplete() {
    Header.startLoader();
    $(".recent_search").show();
    window.localStorage.setItem('searched_term', $(".food_search_input").val().replace(/>/g, "&#60;").replace(/</g, "&#62;").replace(/[/]/g, "&#47;"));
    var suggested_url = window.API_URL + "v1/consumables/autocomplete?term=" + encodeURI(window.localStorage.getItem('searched_term')) + "&per_page=5"
    $(".list-group").show();
    $(".list-group-item recent_search").show();
    const options = {
      url: suggested_url,
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        Header.stopLoader();
        if (response.data.results.consumables.length > 0) {
          var suggested_array = $.map(response.data.results.consumables, function (value, index) {
            return [value];
          });


          this.setState({ suggested: true, recent_search: true, suggested_foods: suggested_array });
          $('.list-group').show();
        } else {
          $(".list-group").show();
          $(".suggested_list").hide()
          this.setState({ suggested: false, recent_search: true })
        }

      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });


    $('.food_not_found').find('b').text('"' + $('.food_search_input').val() + '"');
    $('.activity_empty').hide();
  }

  getfoodRecommandation() {
    Header.startLoader();
    $(".serach_on_keyup").hide();
    $(".show_more").hide();
    $(".list-group").hide();
    $(".recent_list_ul").hide();
    var food_images = {
      "All Foods": "",
      "Nutrisystem Entree": "/assets/images/ne_tag.png",
      "PowerFuel": "/assets/images/pf_tag.png",
      "SmartCarb": "/assets/images/sc_tag.png",
      "Vegetables": "/assets/images/v_tag.png",
      "Other": "/assets/images/oth_tag.png",
      "Extras": "/assets/images/ex_tag.png",
      "Purple": "/assets/images/pl_tag.png",
      "Pink": "/assets/images/pk_tag.png",
      "White": "/assets/images/w_tag.png",
      "Orange": "/assets/images/o_tag.png",
      "Green": "/assets/images/g_tag.png",
      "Free Food": "/assets/images/ff_tag.png",
      "Nutricrush": "/assets/images/nc_tag.png",
      "Turboshake": "/assets/images/ts_tag.png",
      "Nutrisystem Snack": "/assets/images/ns_tag.png"
    }
    var meal_occasion_name = window.localStorage.getItem('meal_occasion_name');
    if (window.localStorage.getItem('meal_occasion_name') === '' || window.localStorage.getItem('meal_occasion_name') === null || window.localStorage.getItem('meal_occasion_name') === undefined) {
      var bucket_sequence;
      var meal_occasion_map = { "1": "BREAKFAST", "2": "BREAKFAST_SNACK", "3": "LUNCH", "4": "LUNCH_SNACK", "5": "DINNER", "6": "DINNER_SNACK" };
      var currentHour = moment().format('HH');

      if (currentHour <= 10 && currentHour >= 0) {
        bucket_sequence = '1';
      } else if (currentHour > 10 && currentHour < 12) {
        bucket_sequence = '2';
      } else if (currentHour >= 12 && currentHour < 14) {
        bucket_sequence = '3';
      } else if (currentHour >= 14 && currentHour < 17) {
        bucket_sequence = '4'
      } else if (currentHour >= 17 && currentHour < 20) {
        bucket_sequence = '5';
      } else if (currentHour >= 20 && currentHour <= 24) {
        bucket_sequence = '6';
      }
      window.localStorage.setItem("meal_occasion_id", bucket_sequence);
      meal_occasion_name = window.localStorage.setItem("meal_occasion_name", meal_occasion_map[bucket_sequence]);
    }

    const options = {
      url: window.API_URL + "v1/food_recommendations?meal_occasion_name=" + meal_occasion_name,
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        Header.stopLoader();
        $('ul.food_options').empty();
        if (response.data.food_recommendations && response.data.food_recommendations.length > 0) {
          $('.multi_select .search_empty').hide();
          for (var k = 0; k < response.data.food_recommendations.length; k++) {
            $('ul.food_options').append('<li class="meal_txt added_food"><span class="color6 item_click first_span cursor_pointer">' + response.data.food_recommendations[k].food_name + ' <br/> <span class="leaf_image"><img src="/assets/images/ns_food_tag.png" /></span> &nbsp; <span class="font12 color8 vlm">' + response.data.food_recommendations[k].calories + ' Cals</span><input type="hidden" class="consumable_id" value=' + response.data.food_recommendations[k].consumable_id + '/><input type="hidden" value=' + response.data.food_recommendations[k].consumable_type + ' class="consumable_type"/><img src="/assets/images/arrow_right_grey.png"  class="pull-right mt-10 multi_gray_arrow"/></span></li>');
            $('.log_food_page .meal_txt:eq(' + k + ')').css('background', 'none');
            var b = document.getElementsByClassName("food_options");
            var c = b[0].lastElementChild;
            var last_element = c.getElementsByClassName('leaf_image');
            for (var pp3 = 0; pp3 < response.data.food_recommendations[k].food_categories.length; pp3++) {
              if ($.inArray(response.data.food_recommendations[k].food_categories[pp3].name, window.food_bucket_array) >= 0) {
                $('<img class="food_list_images_icons" src=' + food_images[response.data.food_recommendations[k].food_categories[pp3].display_name] + '>').insertAfter(last_element);
              }
            }
            if (response.data.food_recommendations[k].food_source !== "Nutrisystem" && response.data.food_recommendations[k].food_source !== "leaf") {
              $('.food_options li:last').find('.leaf_image').remove();
            }
          }
          $('.activity_empty,.serach_on_keyup').show()
          $('.multi_txt').hide();
          setTimeout(function () {
            $('.list-group,.recent_list_ul').hide();
          }, 900)


        } else {
          $('.multi_select .multi_txt').hide();
          $('.multi_select .search_empty').show();
        }
        if (window.localStorage.getItem("meal_occasion_name") === null || window.localStorage.getItem("meal_occasion_name") === undefined) {
          $(".serach_on_keyup").hide();
        }
        $('.food_recent_list_ul').empty();

      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });
  }

  /* load recent searches*/
  recentfoodSearch() {
    const options = {
      url: window.API_URL + "v1/consumables/recent_searches?per_page=5",
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        if (response && response.data && response.data.recent_searches) {
          response.data.recent_searches.forEach(function (item, index, object) {
            if (item === 'undefined') {
              object.splice(index, 1);
            }
          });

          if (!$('.serach_on_keyup').is(':visible') && $('.food_search_input').is(':visible') && ($(".food_search_input").val() !== '')) {

            this.setState({ recent_foods: response.data.recent_searches });

            $('.food_recent_list_ul').empty();
            $('.food_recent_list_ul').append('<li class="list-group-item recent_search food-recent-search-ks" style="display: block;"></li>');
            $('.food-recent-search-ks').text(window.keystrings['com.numi.recent_searches']);
            for (var pp = 0; pp < this.state.recent_foods.length; pp++) {
              $('.food_recent_list_ul').append('<li class="list-group-item" data-category=' + this.state.recent_foods[pp] + '  >' + this.state.recent_foods[pp] + '</li>');
            }
            if (this.state.recent_foods[0] === 'undefined') {
              $('.food_recent_list_ul').hide();
            }
            else {
              $('.food_recent_list_ul,.list-group').show();

            }
          }
        }
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });

    this.foodAutoComplete();
  }

  callreplacedInput(change_input) {
    this.setState({ searchinputValue: change_input });

  }

  handleKeyPressFood(target) {
    if (target.charCode === 13) {
      this.foodSearchIcon();
    }
  }

  componentDidMount() {

    if (window.localStorage.getItem('searched_term') !== '' && window.localStorage.getItem('searched_term') !== null && window.localStorage.getItem('searched_term') !== undefined) {
      $(".food_search_input").val(window.localStorage.getItem('searched_term'));
    }
    var assigned_date;
    window.food_bucket_array = [];
    if (localStorage.getItem('profile_status') === 'ONBOARDING_COMPLETE') {
      assigned_date = moment(window.localStorage.getItem('assigned_date')).format('YYYY-MM-DD');



      const options = {
        url: window.API_URL + "v1/phase_templates/phase_property/FOOD_CATEGORIES/assigned_date/" + assigned_date,
        method: 'GET',
        headers: Header.setAuthHeaders(),
      };

      axios(options)
        .then(response => {
          Header.stopLoader();
          if (response && response.data && response.data.phase_template && response.data.phase_template.food_categories) {
            for (var dd = 0; dd < response.data.phase_template.food_categories.length; dd++) {
              window.food_bucket_array.push(response.data.phase_template.food_categories[dd].name)
            }
          }
        })
        .catch(error => {
          Header.stopLoader();
          Header.apiErrors(error);
          Sentry.captureException(error);
        });
    }

    $(document).on('click', '.suggested_list .list-group-item', function (e) {
      e.stopImmediatePropagation();
      var currentHour = moment().format('HH');
      var bucket_sequence;

      if (currentHour <= 10 && currentHour >= 0) {
        bucket_sequence = '1';
      } else if (currentHour > 10 && currentHour < 12) {
        bucket_sequence = '2';
      } else if (currentHour >= 12 && currentHour < 14) {
        bucket_sequence = '3';
      } else if (currentHour >= 14 && currentHour < 17) {
        bucket_sequence = '4'
      } else if (currentHour >= 17 && currentHour < 20) {
        bucket_sequence = '5';
      } else if (currentHour >= 20 && currentHour <= 24) {
        bucket_sequence = '6';
      }
      window.localStorage.setItem("meal_occasion_id", bucket_sequence);
      window.localStorage.setItem('consumable_id', e.currentTarget.getAttribute('data-id'));
      window.localStorage.setItem('consumable_type', e.currentTarget.getAttribute('data-type'));

      if ($('.journal_page').length) {
        window.localStorage.setItem('item_name', "Journal");
        window.localStorage.setItem('journal_search', 'true');
      }
      else {
        if ($('.log_food_page').length) {
          window.localStorage.setItem('log_food_search', 'true');
        }
      }

      this.props.history.push('/food_details')
    }.bind(this));

    $(document).on('click', 'li.bucket_response', function () {
      window.localStorage.setItem('not_fire_dropdown', 'true');
      for (var gg = 0; gg < $('.food_category_type li').length; gg++) {

        if ($(this).find('.food_item_name').text() === $('.food_category_type li:eq(' + gg + ') span').attr('class')) {

          $('.food_category_type').find('li:eq(' + gg + ') a')[0].click()

        }
      }


    });

    $(document).on('click', '.bucket_foods', function (e) {
      e.stopPropagation();
      $(".activity_empty").hide();
      var food_images = {
        "All Foods": "",
        "Nutrisystem Entree": "/assets/images/ne_tag.png",
        "PowerFuel": "/assets/images/pf_tag.png",
        "SmartCarb": "/assets/images/sc_tag.png",
        "Vegetables": "/assets/images/v_tag.png",
        "Other": "/assets/images/oth_tag.png",
        "Extras": "/assets/images/ex_tag.png",
        "Purple": "/assets/images/pl_tag.png",
        "Pink": "/assets/images/pk_tag.png",
        "White": "/assets/images/w_tag.png",
        "Orange": "/assets/images/o_tag.png",
        "Green": "/assets/images/g_tag.png",
        "Free Food": "/assets/images/ff_tag.png",
        "Nutricrush": "/assets/images/nc_tag.png",
        "Turboshake": "/assets/images/ts_tag.png",
        "Nutrisystem Snack": "/assets/images/ns_tag.png"
      }

      var food_cats = window.bucket_food_cat
      var assigned_date = moment(window.localStorage.getItem('assigned_date')).format('YYYY-MM-DD');
      window.localStorage.setItem('food_categories', food_cats)
      Header.startLoader();
      const options = {
        url: window.API_URL + "v1/consumables?term=" + encodeURI(window.localStorage.getItem('searched_term')) + "&food_categories=[" + food_cats + "]&assigned_date=" + assigned_date,
        method: 'GET',
        credentials: 'same-origin',
        headers: Header.setAuthHeaders(),
      };

      axios(options)
        .then(response => {
          /* build new food list based on results*/
          $(".serach_on_click").hide();
          $('ul.food_options').text('');
          Header.stopLoader();
          if (response.data.results.consumables.length > 0) {
            $('.multi_select .search_empty').hide();
            for (var k = 0; k < response.data.results.consumables.length; k++) {
              $('ul.food_options').append('<li class="meal_txt added_food"><span class="color6 item_click first_span cursor_pointer">' + response.data.results.consumables[k].food_name + ' <br/> <span class="leaf_image"><img src="/assets/images/ns_food_tag.png" /></span> &nbsp; <span class="font12 color8 vlm">' + response.data.results.consumables[k].calories + ' Cals</span><input type="hidden" class="consumable_id" value=' + response.data.results.consumables[k].consumable_id + '/><input type="hidden" value=' + response.data.results.consumables[k].consumable_type + ' class="consumable_type"/><img src="/assets/images/arrow_right_grey.png"  class="pull-right mt-10 multi_gray_arrow"/></span><div class="checkbox_select pull-right mt-10" > <input class="checbox_normal" id="multi_check' + k + '" name="food_item"  type="checkbox"/> <label for="multi_check' + k + '"><span><i></i></span></label>    </div></li>');
              /* $('.log_food_page .meal_txt:eq('+ k +')').css('background','none');   */
              var b = document.getElementsByClassName("food_options");
              var c = b[0].lastElementChild;
              var last_element = c.getElementsByClassName('leaf_image');
              for (var pp3 = 0; pp3 < response.data.results.consumables[k].food_categories.length; pp3++) {
                $('<img class="food_list_images_icons" src=' + food_images[response.data.results.consumables[k].food_categories[pp3].display_name] + '>').insertAfter(last_element);
              }
              if (response.data.results.consumables[k].food_source !== "Nutrisystem" && response.data.results.consumables[k].food_source !== "leaf") {
                $('.food_options li:last').find('.leaf_image').remove();
              }

            }
          }
          setTimeout(function () {
            if ($('.multi_txt').is(':visible')) {
              $('.multi_gray_arrow').show();
              /*$('.multi_select_dropdown,.checkbox_select,.multi_cancel').show();*/
            }
            else {
              $('.multi_gray_arrow,.multi_txt').show();
              /*$('.multi_select_dropdown,.checkbox_select').hide();*/
            }
          }, 50)
          if (response.data.results.consumables.length < 20) {
            $('.show_more').hide();
          }
          else {
            $('.show_more').show();
          }
          $('.list-group').hide();
        })
        .catch(error => {
          Header.stopLoader();
          Header.apiErrors(error);
          Sentry.captureException(error);
        });
    });

    $(document).on('click', '.food_recent_list_ul .list-group-item', function (e) {
      e.stopImmediatePropagation();
      $('.list-group').hide();
      $('.food_search_input').val($(this).text());
      window.localStorage.setItem('recent_searched_term', $(this).text());
      $(".food_search_icon").click();
    });


    $(document).on('click', 'li.suggested_item,li.recent_item,.remove_elements .food_category_change', function (e) {
      e.stopImmediatePropagation();
      if ($('.meal_section_left .favorite_section li span').text() === "Favorites") {

      }
      else {
        if ($('.meal_section_left .favorite_section li span').text() === "Recents") {

        }
        else {
          if ($('.journal_page').length) {
            localStorage.removeItem('food_type');
            localStorage.removeItem('food_categories');
            window.localStorage.setItem('search_from_journal', true);
            window.localStorage.setItem('search_from_header', true);
            this.props.history.push('/log_food');
          } else {
            localStorage.removeItem('search_from_journal');
          }
          this.setState({
            list_group: false,
            suggested: false,
          });

          var food_images = {
            "All Foods": "",
            "Nutrisystem Entree": "/assets/images/ne_tag.png",
            "PowerFuel": "/assets/images/pf_tag.png",
            "SmartCarb": "/assets/images/sc_tag.png",
            "Vegetables": "/assets/images/v_tag.png",
            "Other": "/assets/images/oth_tag.png",
            "Extras": "/assets/images/ex_tag.png",
            "Purple": "/assets/images/pl_tag.png",
            "Pink": "/assets/images/pk_tag.png",
            "White": "/assets/images/w_tag.png",
            "Orange": "/assets/images/o_tag.png",
            "Green": "/assets/images/g_tag.png",
            "Free Food": "/assets/images/ff_tag.png",
            "Nutricrush": "/assets/images/nc_tag.png",
            "Turboshake": "/assets/images/ts_tag.png",
            "Nutrisystem Snack": "/assets/images/ns_tag.png"
          }
          var assigned_date = moment(window.localStorage.getItem('assigned_date')).format('YYYY-MM-DD');
          var search_term;
          if (window.localStorage.getItem('recent_searched_term') !== '' && window.localStorage.getItem('recent_searched_term') !== null && window.localStorage.getItem('recent_searched_term') !== undefined) {
            search_term = window.localStorage.getItem('recent_searched_term');
            $('.food_search_input').val(window.localStorage.getItem('recent_searched_term'));
            window.localStorage.setItem('searched_term', window.localStorage.getItem('recent_searched_term'));
            localStorage.removeItem('recent_searched_term');
          } else {
            if ($('.food_search_input').val() !== '') {
              search_term = $('.food_search_input').val();
              window.localStorage.setItem('searched_term', search_term);
            } else if (window.localStorage.getItem('searched_term') !== null && window.localStorage.getItem('searched_term') !== undefined && window.localStorage.getItem('searched_term') !== '') {
              search_term = window.localStorage.getItem('searched_term');
            } else {
              search_term = '';
            }


          }
          var selected_food_type = $('.select_dropdown_foodtype span.get_selected_food').text().split(" ")[0];
          var selected_food_category = $('.select_dropdown_foodcat span.get_selected_food').text();
          var search_url;
          if (selected_food_type === "All") {
            if (selected_food_category === "All Foods Categories") {
              search_url = window.API_URL + "v1/consumables?term=" + window.localStorage.getItem('searched_term') + "&assigned_date=" + assigned_date
            }
            else {
              search_url = window.API_URL + "v1/consumables?term=" + window.localStorage.getItem('searched_term') + "&food_categories=[" + selected_food_category + "]&assigned_date=" + assigned_date
            }
          }
          else {
            if (selected_food_category === "All Foods Categories") {
              search_url = window.API_URL + "v1/consumables?term=" + window.localStorage.getItem('searched_term') + "&food_type=" + selected_food_type + "&assigned_date=" + assigned_date
            }
            else {
              search_url = window.API_URL + "v1/consumables?term=" + window.localStorage.getItem('searched_term') + "&food_type=" + selected_food_type + "&food_categories=[" + selected_food_category + "]&assigned_date=" + assigned_date
            }
          }



          Header.startLoader();
          const options = {
            url: encodeURI(search_url),
            method: 'GET',
            credentials: 'same-origin',
            headers: Header.setAuthHeaders(),
          };

          axios(options)
            .then(response => {
              Header.stopLoader();
              if (window.localStorage.getItem('searched_term') !== '' && window.localStorage.getItem('searched_term') !== null && window.localStorage.getItem('searched_term') !== undefined) {
                $(".food_search_input").val(window.localStorage.getItem('searched_term'));
              }
              /* build new food list based on results*/
              $(".serach_on_click").hide();
              $('ul.food_options').text('');
              if (response.data.results.consumables.length > 0) {
                $('.multi_select .search_empty').hide();
                for (var k = 0; k < response.data.results.consumables.length; k++) {
                  $('ul.food_options').append('<li class="meal_txt added_food"><span class="color6 item_click first_span cursor_pointer">' + response.data.results.consumables[k].food_name + ' <br/> <span class="leaf_image"><img src="/assets/images/ns_food_tag.png" /></span> &nbsp; <span class="font12 color8 vlm">' + response.data.results.consumables[k].calories + ' Cals</span><input type="hidden" class="consumable_id" value=' + response.data.results.consumables[k].consumable_id + '/><input type="hidden" value=' + response.data.results.consumables[k].consumable_type + ' class="consumable_type"/><img src="/assets/images/arrow_right_grey.png"  class="pull-right mt-10 multi_gray_arrow"/></span><div class="checkbox_select pull-right mt-10" > <input class="checbox_normal" id="multi_check' + k + '" name="food_item"  type="checkbox"/> <label for="multi_check' + k + '"><span><i></i></span></label>    </div></li>');
                  /* $('.log_food_page .meal_txt:eq('+ k +')').css('background','none');   */
                  var b = document.getElementsByClassName("food_options");
                  var c = b[0].lastElementChild;
                  var last_element = c.getElementsByClassName('leaf_image');
                  for (var pp3 = 0; pp3 < response.data.results.consumables[k].food_categories.length; pp3++) {
                    $('<img class="food_list_images_icons" src=' + food_images[response.data.results.consumables[k].food_categories[pp3].display_name] + '>').insertAfter(last_element);
                  }
                  if (response.data.results.consumables[k].food_source !== "Nutrisystem" && response.data.results.consumables[k].food_source !== "leaf") {
                    $('.food_options li:last').find('.leaf_image').remove();
                  }
                }
                $('.multi_cancel,.multi_log').hide();
                $('.multi_gray_arrow,.multiselect_txt').show();
                if (response.data.results.consumables.length < 20) {
                  $('.show_more').hide();
                }
                $('.list-group').hide();
              }
              /* for emoty search results*/
              else if (response.data.results.aggregations.food_categories.doc_count > 0) {
                $('.food_search_input').empty();
                $(".search_empty").hide();
                $(".list-group").hide();
                $(".activity_empty").show();
                $(".serach_on_keyup").hide();
                $(".serach_on_click").show();
                if (response.data.results.aggregations.food_categories.doc_count > 0) {
                  var food_list_images = {
                    "All Foods": "",
                    "NS_ENTREE": "/assets/images/ne_tag.png",
                    "POWERFUEL": "/assets/images/pf_tag.png",
                    "SMARTCARB": "/assets/images/sc_tag.png",
                    "VEGETABLES": "/assets/images/v_tag.png",
                    "OTHER": "/assets/images/oth_tag.png",
                    "EXTRAS": "/assets/images/ex_tag.png",
                    "PURPLE": "/assets/images/pl_tag.png",
                    "PINK": "/assets/images/pk_tag.png",
                    "WHITE": "/assets/images/w_tag.png",
                    "ORANGE": "/assets/images/o_tag.png",
                    "GREEN": "/assets/images/g_tag.png",
                    "FREE_FOOD": "/assets/images/ff_tag.png",
                    "NUTRICRUSH": "/assets/images/nc_tag.png",
                    "TURBOSHAKE": "/assets/images/ts_tag.png",
                    "NS_SNACK": "/assets/images/ns_tag.png"
                  }
                  var food_list_empty_images = {
                    "All Foods": "/assets/images/search_empty_allfoods.png",
                    "Nutrisystem Entree": "/assets/images/search_empty_ne.png",
                    "PowerFuel": "/assets/images/search_empty_pf.png",
                    "SmartCarb": "/assets/images/search_empty_sc.png",
                    "Vegetables": "/assets/images/search_empty_v.png",
                    /*"OTHER": "/assets/images/search_empty_oth.png",
                    "EXTRAS": "/assets/images/search_empty_ex.png",
                    "PURPLE": "/assets/images/search_empty_pl.png",
                    "PINK": "/assets/images/search_empty_pk.png",
                    "WHITE": "/assets/images/search_empty_w.png",
                    "ORANGE": "/assets/images/search_empty_o.png",
                    "GREEN": "/assets/images/search_empty_g.png",
                    "FREE_FOOD": "/assets/images/search_empty_ff.png",
                    "NUTRICRUSH": "/assets/images/search_empty_nc.png",
                    "TURBOSHAKE": "/assets/images/search_empty_ts.png",
                    "NUTRISYSTEM_SNACK": "/assets/images/search_empty_ns.png"*/
                    "Other": "/assets/images/search_empty_allfoods.png",
                    "Extras": "/assets/images/search_empty_allfoods.png",
                    "Purple": "/assets/images/search_empty_allfoods.png",
                    "Pink": "/assets/images/search_empty_allfoods.png",
                    "White": "/assets/images/search_empty_allfoods.png",
                    "Orange": "/assets/images/search_empty_allfoods.png",
                    "Green": "/assets/images/search_empty_allfoods.png",
                    "Free Food": "/assets/images/search_empty_allfoods.png",
                    "Nutricrush": "/assets/images/search_empty_allfoods.png",
                    "Turboshake": "/assets/images/search_empty_allfoods.png",
                    "Nutrisystem Snack": "/assets/images/search_empty_allfoods.png"
                  }
                  var doc_count = response.data.results.aggregations.food_categories.doc_count;
                  var bucketss = response.data.results.aggregations.food_categories.food_categories.buckets;
                  this.setState({ doc_count: doc_count, fc: response.data.results.aggregations.food_categories.food_categories, buckets: response.data.results.aggregations.food_categories.food_categories.buckets });
                  $('ul.food_options').empty();
                  window.bucket_food_cat = [];
                  if (bucketss.length > 0) {
                    $('ul.food_options').append('<li class="food_category_change_all_food added_food"><span class="color6 item_click first_span cursor_pointer"><span class="bucket_foods first_span_all_food">All Foods</span>  <span class="font12 color8  pull-right">[' + doc_count + '] &nbsp;&nbsp; <img src="/assets/images/arrow_right_grey.png"  class="mt2px pull-right multi_gray_arrow"/></span></span></li>');

                    for (var kk = 0; kk < bucketss.length; kk++) {


                      if ($.inArray(bucketss[kk].key, window.food_bucket_array) > 0) {
                        $('ul.food_options').append('<li class="food_category_change added_food bucket_response"><span class="color6 item_click first_span cursor_pointer"><span class="capitalize food_item_name" id="' + bucketss[kk].key + '">' + bucketss[kk].key + '</span> <span class="font12 color8 pull-right">[' + bucketss[kk].doc_count + ']  &nbsp;&nbsp;  <img src="/assets/images/arrow_right_grey.png"  class="mt2px pull-right multi_gray_arrow"/></span></span><div><img src=' + food_list_images[bucketss[kk].key] + '></div></li>');
                        window.bucket_food_cat.push(bucketss[kk].key);
                      }


                      $(".display_doc_count").text(+doc_count + ' Results from other food categories');
                    }
                    $(".recom_icon").empty();
                    $(".recom_icon").html('<img src="' + food_list_empty_images[$('.select_dropdown_foodcat span.get_selected_food').text()] + '"/>');
                    $(".food_click_not_found").html('<br/> We couldn’t find "' + $('.food_search_input').val() + '" for ' + $('.select_dropdown_foodcat span.get_selected_food').text() + '');
                    $('.multi_txt').hide();
                  }
                }
                $('.multi_select .serach_on_click').show();
                $('.show_more').hide();
              } else {
                this.getfoodRecommandation();
                $(".list-group").hide();
                $(".activity_empty").show();
                $(".serach_on_keyup").show();
                $(".serach_on_click").hide();
                $('.show_more').hide();
                $(".food_not_found").html('<br/> We couldn’t find "' + window.localStorage.getItem('searched_term') + '"');
                $('.food_recent_list_ul').empty();

              }
            })
            .catch(error => {
              Header.stopLoader();
              Header.apiErrors(error);
              Sentry.captureException(error);
            });
        }
      }


      /*$('.food_click_not_found').find('b').text('"'+$('.food_search_input').val()+'"');
      $('.serach_on_keyup').hide();
      $('.serach_on_click').show(); */
    }.bind(this));

    /*key strings*/
    $('.food-search-input-placeholder-ks').attr('placeholder', window.keystrings['com.numi.search_food_drinks_etc']);
    $('.food-search-suggested-ks').text(window.keystrings['com.numi.suggested']);
    /*end key strings*/
  }

  render() {
    return (
      <div className="filter-list relative pull-left">
        <fieldset className="form-group">

          <input type="text" className="search_input food_search_input food-search-input-placeholder-ks" value={this.state.searchinputValue} placeholder="" onChange={this.filterfoodList} onKeyUp={this.handleInputChange} onKeyPress={this.handleKeyPressFood.bind(this)} />

          <img alt="" src="/assets/images/search-white.png" className="food_search_icon" id="food-search-icon" onClick={this.foodSearchIcon.bind(this)} />


        </fieldset>
        <DropdownList recent_items_list={this.state.recent_foods} replaceInput={this.callreplacedInput} list_group={this.state.list_group} recent={this.state.recent_search} suggested_li={this.state.suggested} suggested_items_list={this.state.suggested_foods} />
      </div>
    );
  }
};

class DropdownList extends React.Component {
  render() {

    return (
      <div className="list-group list_autocomplete">
        <ul ref="toggle_items" className="margin0 food_recent_list_ul" >

        </ul>
        <ul ref="toggle_items" className="margin0 suggested_list" style={{ display: this.props.suggested_li ? 'block' : 'none' }}>
          <li className="list-group-item recent_search food-search-suggested-ks"></li>
          {

            this.props.suggested_items_list.map(function (item_value) {
              return (

                <li className="list-group-item" data-id={item_value.consumable_id} data-type={item_value.consumable_type} onClick={() => this.props.replaceInput(item_value.food_name)} data-category={item_value.food_name} key={item_value.food_name} dangerouslySetInnerHTML={{ __html: (item_value.highlighted_name ? item_value.highlighted_name : item_value.food_name) }} />
              );
            }.bind(this))
          }

        </ul>
      </div>
    )
  }
};


export default withRouter(FoodFilter);
