
import React from 'react';
import Header from "../header.js";
import Footer from "../footer.js";
import MealOccasionSection from "./meal_occassion_section.js";
import VegetableSection from "./vegetable_section.js";
import FoodFilter from "../log_food/food_search.js";
import { addDays, subDays, parse } from 'date-fns';
import axios from 'axios';
import { OldNumiPopup, UnlogBorrowfood, AlertPopup, SetGoalWeightPopup, LowBloodSugarPopup, ActivityLevelPopup, TurboTakeoffPopup, FoodHelp } from './popup.js';
import Navigation from "../nav.js";
import Adspeed from "../adspeed.js";
import moment from 'moment';
import DatePicker from "react-datepicker";
import ActivityQuestions from '../settings/activity_questions.js';
import { PersonalizePlanMode } from '../onboarding/PersonalizePlanMode.js';
import Newsfeed from "../newsfeed/newsfeed.js";
import * as Sentry from "@sentry/react";
import ActivitySection from './activity_section.js';
import WaterGoalSection from './watergoal_section.js';
import { Modal } from 'react-bootstrap';
import ExtrasSection from './extras_section.js';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import $ from 'jquery';


class Journal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      date: moment(),
      journal_date: window.localStorage.getItem('assigned_date'),
      showVeggie: true,
      toggle_arrow: "/assets/images/arrow_up_grey.png",
      activity_histories: {},
      water_data: '',
      journal_data: '',
      archive_view: '',
      calorie_tracker: '',
      activity_data: '',
      activity_goal: '',
      food_data: [],
      journal_response: [],
      veg_food_items: [], profile_info: '', profile_properties: [],
      get_assign_date: window.localStorage.getItem('assigned_date'),
      copy_date: new Date(), copy_food_date: new Date(), alert_data: [], alert: '', alert_button_labels: [], alert_button_action_web: [], alert_id: '', determinants: {}, alert_link: '', personalization_goal_text: '', personalization_goal_link: '',
      display_treat_day_option: false,
      display_all_treat_day_option: false,
      display_calorie_goal_range: 0,
      display_extra_calories_per_day: 0,
      scheduled_cheat_day: false,
      journal_data_collection: [],
      extra_food_items: [],
      calories_remaining: 0,
      goal: 0,
      food: 0,
      activity: 0,
      cal_consume: 0,
      non_veg_foods: [],
      displayalltreatdayption: "",
      showSmartWeek: false,
      changeTreatDayPopup: false,
      displayCalorieRangeOptions: false,
      toggleOnOff: false,
      cheatDaylineShow: false,
      extrasDetails: [],
      veg_food_meal_occassion_id: "",
      extras_meal_occassion_id: "",
      sourceId: null,
      currFoodId: "",
      foodCatStatus: "",
      currMealOcassionId: "",
      defaultMealOcassionId: "",
      curentConsumableType: "",
      summary_food_items: [],
      summary_extras_items: [],
      extras_status: false,
      drag_drop_status: false,
      isSingleFc: false,
      cheat_day: false,
      toggleCheatDayPopup: "standardday",
      toggleAllCheatDay: false,
      calendar_assigned_date: "",
      calendar_profile_start_date: "",
      max_date_one: "",
      veg_foods_summaries: "",
      extras_foods_summaries: "",
      personalize_modal: false,
    }
    window.click_copy_food = false;
  }



  componentDidMount() {
    this.getJournal();
    this.smartWeek();
    this.profileJson();

    const options = {
      url: window.API_URL + "v1/profile",
      method: 'GET',
      headers: Header.authorization_headers(),
    };

    axios(options)
      .then(response => {
        if (response && response.data) {
          window.localStorage.setItem('my_pictures_path', response.data.profile.my_pictures_path);
          window.localStorage.setItem('new_upload_path', response.data.profile.new_upload_path);
          window.localStorage.setItem('plan_starting_weight', response.data.profile.weight.plan_starting);
          localStorage.setItem('nutrisystem_linked', response.data.profile.nutrisystem_profile_linked);
          this.setState({ profile_info: response.data })
          if (this.state.profile_info) {
            this.setState({
              profile_properties: this.state.profile_info.profile.profile_properties
            });
            this.state.profile_properties.forEach(function (option, i) {
              if (option.key === "PLAN_START_DATE") {
                window.localStorage.setItem('plan_start_date', moment(option.value).format('YYYY-MM-DD'));
              }
            })
            //stroryly

            var gender = window.localStorage.getItem('gender');
            var smartadapt = "";
            var linkedaccount = window.localStorage.getItem('nutrisystem_linked');
            var segment = [];

            this.state.profile_properties.forEach(function (option, i) {
              if (option.key === "USER_MODE") {
                smartadapt = option.value
                window.localStorage.setItem('USER_MODE', option.value);
              }
            })

            if (gender != "" && gender != null) {
              segment.push(gender == "male" ? "male" : "female")
            }
            if (smartadapt == "SMART_ADAPT") {
              segment.push("smartadapt")
            }
            if (linkedaccount == "true") {
              segment.push("linked_account")
            }

            var first_name = window.localStorage.getItem('FirstName');
            var last_name = window.localStorage.getItem('LastName');

            const userPropertyData = {
              "{FIRST_NAME}": first_name,
              "{LAST_NAME}": last_name
            };

            const storylyWeb = document.querySelector("storyly-web")
            storylyWeb.init({
              token: process.env.REACT_APP_JOURNAL_STORYLY_KEY,
              segments: segment,
              userPropertyData: userPropertyData,
              props: {
                storyGroupBorderRadius: 100,
                storyGroupSize: "90x90",
                storyGroupIconBorderColorSeen: ["#D6D6D6"],
                storyGroupIconBorderColorNotSeen: ["#02A0A5", "#64BE84"],
                scrollIndicator: true,
                scrollIndicatorOptions: {
                  indicatorWidth: 25,
                  indicatorHeight: 25,
                  indicatorBorderColor: "#FFFFFF",
                  indicatorBoxBackgroundColor: "#FFFFFF",
                  indicatorDefaultIconsColor: "#02A0A5",
                  leftIndicatorMargin: -7,
                  rightIndicatorMargin: 0,
                }
              },
              events: {
                noStoryGroup: () => {
                  storylyWeb.style.display = "none";
                  document.querySelector(".no_story_msg_wrapper").style.display = "flex";
                },
                openStoryGroup: (group) => {
                  localStorage.setItem('tap_view_story', "Journal-" + group.title + '-' + group.title);
                  var evt = document.createEvent("CustomEvent");
                  evt.initCustomEvent('tap_view_story', true, false, false);
                  document.dispatchEvent(evt);
                }
              }
            })

            //storyly end
          }
        }
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });
  }

  showJournalHelp(value) {
    window.localStorage.setItem('help_location', value);
    this.child.refs.callHelp.callHelpAPI();
  }


  profileJson = () => {
    const optionsProfile = {
      url: window.API_URL + "v1/profile",
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders()
    };

    axios(optionsProfile)
      .then(response => {
        if (response && response.data) {
          response.data.profile.profile_properties.forEach(function (entry) {
            if (entry.key === "START_DATE") {
              window.localStorage.setItem('profile_start_date', entry.value);
            }
          });
        }
      }).catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });
  }

  getJournal = () => {
    Header.startLoader();
    var assigned_date;
    if (window.localStorage.getItem('copy_act') === 'true') {
      assigned_date = moment(window.localStorage.getItem('assigned_date')).format('YYYY-MM-DD');

      window.localStorage.setItem('assigned_date', assigned_date);
      this.setState({
        journal_date: window.localStorage.getItem('assigned_date')
      });
      localStorage.removeItem('copy_act');
    }
    else {
      assigned_date = moment(window.localStorage.getItem('assigned_date')).format('YYYY-MM-DD');
      if (assigned_date === null || assigned_date === 'Invalid date' || assigned_date === undefined || assigned_date === 'undefined') {
        assigned_date = moment().format('YYYY-MM-DD');
      }
      window.localStorage.setItem('assigned_date', assigned_date);
      this.setState({
        journal_date: window.localStorage.getItem('assigned_date')
      });
    }

    if (moment(assigned_date).isValid()) {
      const options = {
        url: window.API_URL + 'v1/journal/assigned_date/' + assigned_date,
        method: 'GET',
        credentials: 'same-origin',
        headers: Header.setAuthHeaders(),
      };

      axios(options)
        .then(Header.handleJWTErrors)
        .then(response => {
          Header.stopLoader();
          if (response && response.data) {
            this.resetAllState();
            this.state.calendar_assigned_date = window.localStorage.getItem('assigned_date');
            this.state.calendar_profile_start_date = window.localStorage.getItem('profile_start_date');
            this.state.max_date_one = moment().add(90, 'days').calendar().split('/');
            this.state.max_date_one = this.state.max_date_one[2] + '-' + this.state.max_date_one[0] + '-' + this.state.max_date_one[1];
            Header.changeDate(window.localStorage.getItem('assigned_date'));
            var session_date = moment(parse(window.localStorage.getItem('assigned_date'))).format('DD/MM/YYYY');
            // all past days, set to date as today's date
            var split_date = session_date.split('/');
            window.localStorage.setItem('water_goal_new', response.data.journal.water.water_goal);
            window.localStorage.setItem('water_state', response.data.journal.water.is_dynamic_water_goal);
            window.localStorage.setItem('journal_type', response.data.journal.journal_type);
            window.localStorage.setItem('month', split_date[1]);
            window.localStorage.setItem('year', split_date[2]);
            window.localStorage.setItem('phase_template_key', response.data.journal.phase_template_key);
            window.localStorage.setItem('get_key', response.data.journal.plan_name);
            localStorage.setItem('personalization_2021_plan', response.data.journal.personalization_2021_plan);
            localStorage.setItem('display_scheduled_coaching', response.data.journal.display_scheduled_coaching);

            var veg_foods = response.data.journal.meal_plan_occasion_goals.filter(function (obj) { return obj.meal_occasion_name === "VEGETABLE"; });
            var extra_foods = response.data.journal.meal_plan_occasion_goals.filter(function (obj) { return obj.meal_occasion_name === "EXTRAS"; });
            var non_veg_foods = response.data.journal.meal_plan_occasion_goals.filter(function (obj) {
              return obj.meal_occasion_name !== "VEGETABLE" && obj.meal_occasion_name !== "EXTRAS"
            })

            this.setState({
              extrasDetails: response.data.journal.meal_plan_occasion_goals.filter(function (obj) {
                return obj.meal_occasion_name === "EXTRAS"
              }),
              archive_view: response.data.journal.archive_view,
              drag_drop_status: response.data.journal.drag_drop_enabled,
              activity_data: response.data.journal.activity.activity_details,
              water_data: response.data.journal.water,
              activity_goal: response.data.journal.activity.goal,
              non_veg_foods: non_veg_foods,
              extra_food_items: extra_foods,
              veg_food_items: veg_foods[0]?.food_details,
              summary_food_items: veg_foods[0]?.summary_displays,
              veg_foods_summaries: veg_foods[0]?.summaries,
              summary_extras_items: extra_foods[0]?.summary_displays,
              veg_food_meal_occassion_id: veg_foods[0]?.meal_occasion_id,
              extras_meal_occassion_id: extra_foods[0]?.meal_occasion_id,
              extras_foods_summaries: extra_foods[0]?.summaries,
              extras_status: response.data.journal.extras_enabled,
              scheduled_cheat_day: response.data.journal.scheduled_cheat_day,
              showSmartWeek: response.data.journal.show_smart_week_in_journal,
              toggleOnOff: response.data.journal.cheat_day_status,
              display_treat_day_option: response.data.journal.cheat_day_status,
              display_calorie_goal_range: response.data.journal.calorie_goal_range,
              display_extra_calories_per_day: response.data.journal.extra_calories_per_day,
              display_all_treat_day_option: response.data.journal.all_meals_cheat_meal_status,
              toggleAllCheatDay: response.data.journal.all_meals_cheat_meal_status,
              journal_response: response.data.journal,

            })
            this.setState({
              drag_drop_status: response.data.journal.drag_drop_enabled,
              archive_view: response.data.journal.archive_view,
              activity_data: response.data.journal.activity.activity_details,
              water_data: response.data.journal.water,
              activity_goal: response.data.journal.activity.goal,
              veg_food_items: veg_foods[0]?.food_details,
              summary_food_items: veg_foods[0]?.summary_displays,
              veg_foods_summaries: veg_foods[0]?.summaries,
              veg_food_meal_occassion_id: veg_foods[0]?.meal_occasion_id,
              summary_extras_items: extra_foods[0]?.summary_displays,
              extras_meal_occassion_id: extra_foods[0]?.meal_occasion_id,
              extras_foods_summaries: extra_foods[0]?.summaries,
              extras_status: response.data.journal.extras_enabled,
              scheduled_cheat_day: response.data.journal.scheduled_cheat_day,
              showSmartWeek: response.data.journal.show_smart_week_in_journal,
              toggleOnOff: response.data.journal.cheat_day_status,
              display_treat_day_option: response.data.journal.cheat_day_status,
              display_calorie_goal_range: response.data.journal.calorie_goal_range,
              display_extra_calories_per_day: response.data.journal.extra_calories_per_day,
              display_all_treat_day_option: response.data.journal.all_meals_cheat_meal_status,
              toggleAllCheatDay: response.data.journal.all_meals_cheat_meal_status,
              cheatDaylineShow: response.data.journal.show_smart_week_in_journa == true ? true : false,
              journal_response: response.data.journal

            });

            if (response.data.journal.all_meals_cheat_meal_status === true
              || response.data.journal.cheat_day_status === true || response.data.journal.scheduled_cheat_day === true) {
              this.setState({
                cheatDaylineShow: true
              })
            }
            else {
              this.setState({
                cheatDaylineShow: false
              })
            }



            if (response && response.data && response.data.journal && response.data.journal.personalized_calorie_goal_text && response.data.journal.personalized_calorie_goal_link) {
              this.setState({
                personalization_goal_text: response.data.journal.personalized_calorie_goal_text,
                personalization_goal_link: response.data.journal.personalized_calorie_goal_link
              })
            }
            else {
              this.setState({
                personalization_goal_text: '',
                personalization_goal_link: ''
              })
            }

            this.setState({
              journal_response: response.data.journal,
              calories_remaining: response.data.journal.calories_remaining != null ? response.data.journal.calories_remaining : 0,
              goal: response.data.journal.calories_goal != null ? response.data.journal.calories_goal : 0,
              food: response.data.journal.calories_consumed != null ? response.data.journal.calories_consumed : 0,
              activity: response.data.journal.calories_activity != null ? response.data.journal.calories_activity : 0
            })

            window.localStorage.setItem('archive_view', this.state.archive_view);

            try {
              // if (window.localStorage.getItem('autoscroll') === 'true') {
              //   window.scrollTo(0, window.localStorage.getItem('scroll_id'));
              // }
              // if (window.localStorage.getItem('autoscroll') === 'truedrag') {
              //   window.scrollTo(0, window.localStorage.getItem('scroll_id'));
              // }
              // if (window.localStorage.getItem('autoscroll') === 'quicklog') {
              //   window.scrollTo(0, window.localStorage.getItem('scroll_id'));
              // }

              const scrollId = window.localStorage.getItem('scroll_id');
              if (scrollId) {
                const element = document.querySelector(`[value="${scrollId}"]`);
                if (element) {
                  element.scrollIntoView();
                }
              }

            } catch {
              Header.stopLoader();
              Sentry.captureException("autoscroll journal page");
            }
          }
        })
        .catch((error) => {
          Header.stopLoader();
        });
    }
  }

  resetAllState = () => {
    this.setState({
      extrasDetails: [],
      archive_view: '',
      drag_drop_status: false,
      activity_data: '',
      water_data: '',
      activity_goal: '',
      non_veg_foods: [],
      extra_food_items: [],
      veg_food_items: [],
      summary_food_items: [],
      summary_extras_items: [],
      veg_food_meal_occassion_id: "",
      extras_meal_occassion_id: "",
      extras_status: false,
      personalization_goal_text: '',
      personalization_goal_link: '',
      journal_response: [],
      calories_remaining: 0,
      goal: 0,
      food: 0,
      activity: 0,
      showSmartWeek: false,
      scheduled_cheat_day: 0,

    })
  }

  callSmartAdapt = () => {
    Header.startLoader();
    const options = {
      url: window.API_URL + 'v1/nutrisystem/apply_smart_adapt',
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };
    axios(options)
      .then(responsesmart => {
        Header.stopLoader();
        if (responsesmart.data.smart_phase === true) {
          const smart_week_new = {
            url: window.API_URL + "v1/nutrisystem/smart_week",
            method: 'GET',
            credentials: 'same-origin',
            headers: Header.setAuthHeaders(),
          };
          axios(smart_week_new)
            .then(response => {
              Header.stopLoader();
              localStorage.setItem('smart_week_setup_screen', response.data.smart_week.show_smart_week);
              if (response.data.smart_week.show_smart_week === true && localStorage.getItem("smartAdapt") === "true") {
                $('.ques_buffer').show();
                $('.ques_calculation').hide();
                $('#personalize_div').hide();
                setTimeout(() => {
                  $('.modal-content').removeClass('buffer_bg');
                  $('.buffer').hide();
                  $('.ques_buffer').hide();
                  $('.calculation').hide();
                  $('#personalize_questions').modal('show');
                  $('.smart_week_setup_option_new').show().css({ "padding-top": "25px", "padding-bottom": "10px" });;
                  $('#activity_question_options').hide();
                  $("#newCalGoal").show();
                  $("#step0").hide();
                  $("#step1").hide();
                  $("#step2").hide();
                  $("#step3").hide();
                  $("#backToPrevStep1").removeClass("d-none")
                  $("#backToPrevStep12").addClass("d-none")
                  $("#backToPrevStep21").addClass("d-none")
                  $("#backToPrevStep2").removeClass("d-none")
                }, 5000);
              }
              else {
                document.querySelector('.final_cipd_value').innerHTML = responsesmart.data.cipd_display;
                $('#personalize_questions .modal-content').addClass('padding0');
                $('.ques_buffer').show();
                $('#personalize_questions').modal('show');
                $('#personalize_div').hide();
                setTimeout(() => {
                  $('.ques_buffer').hide();
                  $('.ques_calculation').show();
                }, 5000);
              }
            })
        }
        else {

          this.getJournal();
        }
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });
  }

  smartWeek = () => {
    const smart_week_new = {
      url: window.API_URL + "v1/nutrisystem/smart_week",
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };
    axios(smart_week_new)
      .then(response => {
        Header.stopLoader();
        localStorage.setItem('smartweek_type', response.data.smart_week.smartweek_type);

        if (response.data.smart_week.show_smart_week !== true) {
          $('#cheat_day_meal').addClass('d-none');
          $('#on_cheatday').addClass('d-none');
        } else {
          if (response.data.smart_week.smartweek_type !== 'SMARTWEEKS-EXITED') {
            $('#cheat_day_meal').removeClass('d-none');
            $('#on_cheatday').removeClass('d-none');
          }
        }
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });



    if (localStorage.getItem('user_id') !== null) {
      Newsfeed.loadAppboy();
    }
    var profile_status = window.localStorage.getItem('profile_status');
    var numiLegacy = window.localStorage.getItem('numi_legacy');
    if (profile_status === null) {
      this.props.history.push('/login');
      return;
    }
    else if (profile_status === "NEW_USER") {
      this.props.history.push('/signup');
      return;
    }
    else if (profile_status === "ONBOARDING_COMPLETE") {
      if (window.localStorage.getItem('assigned_date') == null) {
        window.localStorage.setItem('assigned_date', moment().format('YYYY-MM-DD'));
      }
      if (window.localStorage.getItem('impersonation') === "true") {
        window.location.href = "/journal";
      }
      else {
        this.props.history.push('/journal');
      }

    }
    else if ((profile_status === "PROFILE_COMPLETE" || profile_status === "ONBOARDING") && numiLegacy === 'true') {
      this.props.history.push('/welcome');
      return;
    }
    else if (profile_status === "PROFILE_COMPLETE" || profile_status === "ONBOARDING") {
      this.props.history.push('/ns_plan');
      return;
    }
    else if (profile_status === "PLAN_COMPLETE") {
      this.props.history.push('/stay_on_track');
      return;
    }
    else {
      alert("Undifined profile status:" + profile_status);
    }
    if (window.localStorage.getItem('zid') === null) {
      const phase_options = {
        url: window.API_URL + 'v1/phase_templates',
        method: 'GET',
        credentials: 'same-origin',
        headers: Header.setAuthHeaders(),
      };

      axios(phase_options)
        .then(response => {
          var adspeed_array = response.data.phase_properties.filter(function (obj) { return obj.key === "ADSPEED_WEB"; });
          if (adspeed_array.length !== 0) {
            window.localStorage.setItem('zid', adspeed_array[0].value[0].zone);
            if (window.localStorage.getItem('zid') !== null && window.localStorage.getItem('zid') !== undefined) {
              window.zid = window.localStorage.getItem('zid');
              var script = document.createElement('script');
              script.classList.add('adspeed_script');
              script.src = "https://g.adspeed.net/ad.php?do=js&zids=" + window.zid + "&wd=-1&ht=-1&target=_blank";
              document.getElementsByTagName('script')[0].parentNode.appendChild(script);
              if (window.AdSpeed_jQuery) {
                window.AdSpeed_jQuery('banner', window.localStorage.getItem('zid'));
                localStorage.setItem('show_banner', $('#banner').html());
              }
            }
            window.localStorage.setItem('getInterval', "true");
          } else {

            const options = {
              url: window.API_URL + 'v1/global_configs',
              method: 'GET',
              credentials: 'same-origin',
              headers: Header.setAuthHeaders(),
            };

            axios(options)
              .then(response => {
                var adspeed_array = response.data.global_configs.filter(function (obj) { return obj.key === "ADSPEED_WEB"; });
                if (adspeed_array.length !== 0) {
                  $('.adspeed_script').remove();
                  window.localStorage.setItem('zid', adspeed_array[0].value[0].zone);
                  window.localStorage.setItem('getInterval', "true");
                  localStorage.removeItem('adspeed');
                  if (window.localStorage.getItem('zid') !== null && window.localStorage.getItem('zid') !== undefined) {
                    window.zid = window.localStorage.getItem('zid');
                    var script = document.createElement('script');
                    script.classList.add('adspeed_script');
                    script.src = "https://g.adspeed.net/ad.php?do=js&zids=" + window.zid + "&wd=-1&ht=-1&target=_blank";
                    document.getElementsByTagName('body')[0].parentNode.appendChild(script);
                    setTimeout(function () {
                      if (window.AdSpeed_jQuery) {
                        window.AdSpeed_jQuery('banner', window.localStorage.getItem('zid'));
                        localStorage.setItem('show_banner', $('#banner').html());
                      }

                    }, 1800)
                  }
                }
              })
              .catch(error => {
                Header.stopLoader();
                Header.apiErrors(error);
                Sentry.captureException(error);
              });

          }
        })
        .catch(error => {
          Header.stopLoader();
          Header.apiErrors(error);
          Sentry.captureException(error);
        });
    }
    else {
      setTimeout(function () {
        if (window.localStorage.getItem('getInterval') === "true") {
          $('.adspeed_script').remove();
          window.zid = window.localStorage.getItem('zid');
          var script = document.createElement('script');
          script.classList.add('adspeed_script');
          script.src = "https://g.adspeed.net/ad.php?do=js&zids=" + window.zid + "&wd=-1&ht=-1&target=_blank";
          document.getElementsByTagName('body')[0].parentNode.appendChild(script);
          if (window.AdSpeed_jQuery) {
            window.AdSpeed_jQuery('banner', window.localStorage.getItem('zid'));
            localStorage.setItem('show_banner', $('#banner').html());
          }
        }
      }, 500)
    }
    $('.date_sec .react-datepicker__input-container input').attr('readonly', true);
    localStorage.removeItem('custom_food_consumable_id');
    localStorage.removeItem('custom_fav_food_consumable_id');
    localStorage.removeItem('custom_fav_food_consumable_type');
    localStorage.removeItem('log_food_from');
    localStorage.removeItem('new_assigned_date');
    localStorage.removeItem('journal_search');
    localStorage.removeItem('log_food_search');
    localStorage.removeItem('searched_term');
    localStorage.removeItem('back_to_recipe');
    localStorage.removeItem('meal_occasion_name');
    localStorage.removeItem('meal_occasion');
    localStorage.removeItem('meal_occasion_id');
    localStorage.removeItem('food_categories');
    localStorage.removeItem('food_type');
    localStorage.removeItem('item_name');
    window.localStorage.setItem('page_number', 1);

  }

  readAlert = () => {
    const options = {
      url: window.API_URL + 'v1/alerts/' + window.alertid + '/read',
      method: 'PUT',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        Header.stopLoader();
        $('#alert_content').modal('hide');
        sessionStorage.removeItem('check_cipd');
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });

  }


  journalDate(date) {
    window.localStorage.setItem('dateclick', 'true');

    this.setState({
      journal_date: date
    });

    var arr = [];
    for (var i = 0; i < localStorage.length; i++) {
      if (localStorage.key(i).substring(0, 3) === '#me') {
        arr.push(localStorage.key(i));
      }
    }

    for (var j = 0; j < arr.length; j++) {
      localStorage.removeItem(arr[j]);
    }

    window.localStorage.setItem('assigned_date', moment(date).format('YYYY-MM-DD'));

    setTimeout(function () {
      this.getJournal();
    }.bind(this), 10);
  }

  leftArrow() {
    window.localStorage.setItem('dateclick', 'true');
    var jj = subDays(window.localStorage.getItem('assigned_date'), 1);
    window.localStorage.setItem('assigned_date', moment(jj).format('YYYY-MM-DD'));
    this.getJournal();
    var arr = [];

    for (var i = 0; i < localStorage.length; i++) {
      if (localStorage.key(i).substring(0, 3) === '#me') {
        arr.push(localStorage.key(i));
      }
    }
    for (var j = 0; j < arr.length; j++) {
      localStorage.removeItem(arr[j]);
    }
    Header.startLoader();
    this.setState({
      journal_date: window.localStorage.getItem('assigned_date')
    });

    localStorage.removeItem('autoscroll')
    localStorage.removeItem('scroll_id');

    setTimeout(function () {
      Header.changeDate(window.localStorage.getItem('assigned_date'));
    }, 800);
  }

  rightArrow() {
    window.localStorage.setItem('dateclick', 'true');
    var jj = addDays(window.localStorage.getItem('assigned_date'), 1);
    localStorage.setItem('assigned_date', moment(jj).format('YYYY-MM-DD'));
    window.localStorage.setItem('assigned_date', moment(jj).format('YYYY-MM-DD'));
    this.getJournal();
    var arr = [];

    for (var i = 0; i < localStorage.length; i++) {
      if (localStorage.key(i).substring(0, 3) === '#me') {
        arr.push(localStorage.key(i));
      }
    }

    // Iterate over arr and remove the items by key
    for (var j = 0; j < arr.length; j++) {
      localStorage.removeItem(arr[j]);
    }

    Header.startLoader();

    this.setState({
      journal_date: window.localStorage.getItem('assigned_date')
    })
    localStorage.removeItem('autoscroll')
    localStorage.removeItem('scroll_id');
    setTimeout(function () {
      Header.changeDate(window.localStorage.getItem('assigned_date'));
    }, 800);
  }

  hideTreatDayPopup() {
    this.setState({ changeTreatDayPopup: false });
  }

  hidePersonalizeModal() {
    this.setState({ personalize_modal: false });
  }

  toggleCheatDay() {
    if (this.state.scheduled_cheat_day == true) {
      if (this.state.toggleOnOff == false) {

        var cheat_day_history_params = {
          "cheat_day_history": {
            "cheat_day_status": "true",
            "assigned_date": window.localStorage.getItem('assigned_date'),
          }
        }

        Header.startLoader();
        const options = {
          url: window.API_URL + "v1/nutrisystem/flex_cheat_day_history",
          method: 'POST',
          credentials: 'same-origin',
          headers: Header.setAuthHeaders(),
          data: cheat_day_history_params
        };

        axios(options)
          .then(response => {
            //this.resetAllState();
            this.setState({
              changeTreatDayPopup: false,
              display_treat_day_option: true,
              toggleCheatDayPopup: "treatday",
              toggleOnOff: true
            })
            this.getJournal();
            Header.stopLoader();
          })
          .catch(error => {
            Header.stopLoader();
            Sentry.captureException(error);
          });

      }
      else {
        this.setState({
          changeTreatDayPopup: true,
          toggleCheatDayPopup: "standardday",

        })
      }


    }

    else {

      if (this.state.toggleOnOff == false) {
        this.setState({
          changeTreatDayPopup: true,
          toggleCheatDayPopup: "treatday"
        })
      }
      else {
        this.setState({
          changeTreatDayPopup: true,
          toggleCheatDayPopup: "standardday"
        })
      }
    }
  }

  cancellCheatdayPopup() {
    this.setState({
      changeTreatDayPopup: false
    })
  }

  applyCheatDayAll() {
    if (this.state.display_all_treat_day_option == true) {
      var cheat_day_history_all_params = {
        "cheat_day_history": {
          "change_all_meals_to_cheat_meals": "false",
          "cheat_day_status": this.state.display_treat_day_option,
          "assigned_date": window.localStorage.getItem('assigned_date')
        }
      }

      Header.startLoader();

      const options = {
        url: window.API_URL + "v1/nutrisystem/flex_cheat_day_history",
        method: 'POST',
        credentials: 'same-origin',
        headers: Header.setAuthHeaders(),
        data: cheat_day_history_all_params
      };

      axios(options)
        .then(response => {
          Header.stopLoader();
          setTimeout(() => {

            this.getJournal();
          }, 100)
          if (response && response.data) {
            if (response.data.cheat_day_history.change_all_meals_to_cheat_meals == true) {
              this.setState({
                display_all_treat_day_option: 'false',
                toggleAllCheatDay: false
              })
            }
          }
        })

        .catch(error => {
          Header.stopLoader();
          Sentry.captureException(error);
        });

    }
    else {
      var cheat_day_history_all_params = {
        "cheat_day_history": {
          "change_all_meals_to_cheat_meals": "true",
          "cheat_day_status": this.state.display_treat_day_option,
          "assigned_date": window.localStorage.getItem('assigned_date')
        }
      }

      Header.startLoader();

      const options = {
        url: window.API_URL + "v1/nutrisystem/flex_cheat_day_history",
        method: 'POST',
        credentials: 'same-origin',
        headers: Header.setAuthHeaders(),
        data: cheat_day_history_all_params
      };

      axios(options)
        .then(response => {
          Header.stopLoader();
          setTimeout(() => {

            this.getJournal();
          }, 100)
          if (response && response.data) {
            if (response.data.cheat_day_history.change_all_meals_to_cheat_meals == false) {
              this.setState({
                display_all_treat_day_option: 'true',
                toggleAllCheatDay: true
              })
            }
          }
        })

        .catch(error => {
          Header.stopLoader();
          Sentry.captureException(error);
        });
    }
  }


  applyCheatDayPopup() {
    if (this.state.scheduled_cheat_day == true) {
      if (this.state.toggleCheatDayPopup == "standardday" && this.state.toggleOnOff == true) {


        var cheat_day_history_params = {
          "cheat_day_history": {
            "cheat_day_status": "false",
            "assigned_date": window.localStorage.getItem('assigned_date'),
          }
        }

        Header.startLoader();

        const options = {
          url: window.API_URL + "v1/nutrisystem/flex_cheat_day_history",
          method: 'POST',
          credentials: 'same-origin',
          headers: Header.setAuthHeaders(),
          data: cheat_day_history_params
        };

        axios(options)
          .then(response => {
            this.setState({
              changeTreatDayPopup: false,
              toggleOnOff: false
            })
            this.getJournal();
            Header.stopLoader();
          })
          .catch(error => {
            Header.stopLoader();
            Sentry.captureException(error);
          });
      }
      else {


        var cheat_day_history_params = {
          "cheat_day_history": {
            "cheat_day_status": "true",
            "assigned_date": window.localStorage.getItem('assigned_date'),
          }
        }

        Header.startLoader();

        const options = {
          url: window.API_URL + "v1/nutrisystem/flex_cheat_day_history",
          method: 'POST',
          credentials: 'same-origin',
          headers: Header.setAuthHeaders(),
          data: cheat_day_history_params
        };

        axios(options)
          .then(response => {
            this.setState({
              changeTreatDayPopup: false,
              toggleOnOff: true
            })
            this.getJournal();
            Header.stopLoader();
          })
          .catch(error => {
            Header.stopLoader();
            Sentry.captureException(error);
          });
      }
    }
    else {
      if (this.state.toggleCheatDayPopup == "treatday") {
        var cheat_day_history_params = {
          "cheat_day_history": {
            "cheat_day_status": "true",
            "assigned_date": window.localStorage.getItem('assigned_date'),
          }
        }
        Header.startLoader();

        const options = {
          url: window.API_URL + "v1/nutrisystem/flex_cheat_day_history",
          method: 'POST',
          credentials: 'same-origin',
          headers: Header.setAuthHeaders(),
          data: cheat_day_history_params
        };

        axios(options)
          .then(response => {
            this.setState({
              changeTreatDayPopup: false,
              toggleOnOff: true
            })
            this.getJournal();
            Header.stopLoader();
          })
          .catch(error => {
            Header.stopLoader();
            Sentry.captureException(error);
          });
      }
      else {
        var cheat_day_history_params = {
          "cheat_day_history": {
            "cheat_day_status": "false",
            "assigned_date": window.localStorage.getItem('assigned_date'),
          }
        }
        Header.startLoader();

        const options = {
          url: window.API_URL + "v1/nutrisystem/flex_cheat_day_history",
          method: 'POST',
          credentials: 'same-origin',
          headers: Header.setAuthHeaders(),
          data: cheat_day_history_params
        };

        axios(options)
          .then(response => {
            this.setState({
              changeTreatDayPopup: false,
              toggleOnOff: false
            })
            this.getJournal();
            Header.stopLoader();
          })
          .catch(error => {
            Header.stopLoader();
            Sentry.captureException(error);
          });
      }
    }

  }

  handleDragEnd(e) {
    window.localStorage.setItem('autoscroll', 'truedrag');
    window.localStorage.setItem('scroll_id', window.pageYOffset);
    if (e.source.droppableId != e.destination.droppableId && e.destination.droppableId != null && e.destination.droppableId.split("-")[2] == 'droppableallow') {
      var data = {};
      if (this.state.foodCatStatus == "UNUSED") {
        data = {
          "diet_histories": {
            "default_meal_occasion_id": this.state.defaultMealOcassionId,
            "meal_occasion_id_from": this.state.currMealOcassionId,
            "meal_occasion_id_to": parseInt(e.destination.droppableId.split("-")[1]),
            "assigned_date": moment((window.localStorage.getItem('assigned_date'))).format('YYYY-MM-DD'),
            "food_categories": [{
              "id": this.state.currFoodId
            }]
          }
        }
      }

      if (this.state.foodCatStatus != "UNUSED" && this.state.isSingleFc == false) {
        data = {
          "diet_histories": {
            "default_meal_occasion_id": this.state.defaultMealOcassionId,
            "meal_occasion_id_from": this.state.currMealOcassionId,
            "meal_occasion_id_to": parseInt(e.destination.droppableId.split("-")[1]),
            "assigned_date": moment((window.localStorage.getItem('assigned_date'))).format('YYYY-MM-DD'),
            "diet_history_id": this.state.currFoodId,
          }
        }
      }
      else if (this.state.foodCatStatus != "UNUSED" && this.state.isSingleFc == true) {
        data = {
          "diet_histories": {
            "default_meal_occasion_id": this.state.defaultMealOcassionId,
            "meal_occasion_id_from": this.state.currMealOcassionId,
            "meal_occasion_id_to": parseInt(e.destination.droppableId.split("-")[1]),
            "assigned_date": moment((window.localStorage.getItem('assigned_date'))).format('YYYY-MM-DD'),
            "diet_history_id": this.state.currFoodId,
            "state": this.state.foodCatStatus,
          }
        }
      }
    }

    if (this.state.currMealOcassionId != parseInt(e.destination.droppableId.split("-")[1]) && e.destination.droppableId.split("-")[2] == 'droppableallow') {
      Header.startLoader();
      const options = {
        url: window.API_URL + "v1/diet_histories",
        method: "POST",
        credentials: 'same-origin',
        headers: Header.setAuthHeaders(),
        data: data
      };

      axios(options)
        .then(response => {
          Header.stopLoader();
          if (response.data) {
            // window.localStorage.setItem("autoscroll", false)
            this.getJournal();
          }
        })

        .catch(error => {
          Header.stopLoader();
          Header.apiErrors(error);
          Sentry.captureException(error);
        });
    }
  }

  handleDragStart(e) {
    this.setState({
      sourceId: parseInt(e.draggableId),
      currFoodId: parseInt(e.draggableId.split("-")[1]),
      currMealOcassionId: parseInt(e.draggableId.split("-")[0]),
      defaultMealOcassionId: parseInt(e.draggableId.split("-")[3]),
      curentConsumableType: e.draggableId.split("-")[6]
    })
    if (e.draggableId.split("-")[4] == "UNUSED") {
      this.setState({ foodCatStatus: "UNUSED" })
    }
    else if (e.draggableId.split("-")[4] == "USED") {
      this.setState({ foodCatStatus: "USED" })
    }
    else if (e.draggableId.split("-")[4] == "OVER") {
      this.setState({ foodCatStatus: "OVER" })
    }

    if (e.draggableId.split("-")[5] == "singlelog") {
      this.setState({ isSingleFc: true })
    }
    else if (e.draggableId.split("-")[5] == "multilog") {
      this.setState({ isSingleFc: false })
    }
  }


  render() {

    return (
      <>
        <div className="table_display">
          <div className="journal_div journal journal_page">
            <div className="section_left pull-left">
              <Navigation />
            </div>
            <div className="section_right pull-left">
              <figure><Adspeed /></figure>
              <div className="journal_rht">
                <div className='storyly_div'>
                  <h3>Nutrisystem Stories</h3>
                  <div>
                    <storyly-web />
                  </div>
                  <div className="no_story_msg_wrapper" style={{ display: "none" }}>
                    <p className="no_story_msg">No stories to view at this time. Check back later to see new stories.</p>
                  </div>
                </div>
                <h1 className="journal_hdr pull-left">Journal<sup><img alt="" src="/assets/images/education.png" onClick={() => this.showJournalHelp('JOURNAL_HEADER')} className="cursor_pointer journal_help" /></sup></h1>
                <div className="pull-right journal_search_input mt10">
                  <FoodFilter />
                </div>
                <div className="clear"></div>
                <hr />
                <div className="show_date pull-left">
                  <span className={`left_arrow inline_block ${moment(this.state.calendar_assigned_date).isSame(this.state.calendar_profile_start_date) ? "disable_arrows" : ""}`} onClick={this.leftArrow.bind(this)}><img alt="" src="/assets/images/arrow_left_grey.png" /></span>
                  <span className="date_sec inline_block cursor_pointer" onClick={this.getCalendarStatus}><span className="selected_date">{moment(this.state.journal_date).format('MMM DD')}</span><DatePicker onChange={this.journalDate.bind(this)} selected={parse(this.state.journal_date)} minDate={parse(window.localStorage.getItem('profile_start_date'))} maxDate={addDays(new Date(), 90)} /></span>
                  <span className={`right_arrow inline_block ${moment(this.state.calendar_assigned_date).isSame(this.state.max_date_one) ? "disable_arrows" : ""}`} onClick={this.rightArrow.bind(this)}><img alt="" src="/assets/images/arrow_right_grey.png" /></span>
                </div>
                <div className="pull-right mt1 show_calorie">
                  <span className="calorie full_calorie_value" style={{ display: window.localStorage.getItem('get_key') === 'NS_FREE' ? "none" : "" }}><span className="calorie_value cal_remain">{this.state.calories_remaining}</span> <span className="calorie_txt">{window.keystrings['com.numi.calories_left']}</span> &nbsp;&nbsp; = &nbsp;&nbsp;<span className="calorie_value cal_goal">{this.state.goal}</span> <span className="calorie_txt">&nbsp;{window.keystrings['com.numi.goal']}</span>&nbsp; - &nbsp;<span className="calorie_value cal_consume">{this.state.food}</span> <span className="calorie_txt">&nbsp;{window.keystrings['com.numi.food']}</span></span>
                  <span className="calorie partial_calorie_value" style={{ display: window.localStorage.getItem('get_key') === 'NS_FREE' ? "" : "none" }}><span className="calorie_value cal_consume">{this.state.food}</span> <span className="calorie_txt">&nbsp;{window.keystrings['com.numi.calories_used']}</span></span>
                  <span className="calorie final_calorie">&nbsp;&nbsp;<span className="calorie_value cal_activity">{this.state.activity}</span> <span className="calorie_txt">&nbsp;{window.keystrings['com.numi.activity']}</span></span>
                  {this.state.personalization_goal_text && this.state.personalization_goal_link ? <div className="text-center">
                    <a href={this.state.personalization_goal_link} target="_blank" className="color7" style={{ 'textDecoration': 'underline' }} rel="noopener noreferrer">{this.state.personalization_goal_text}</a></div> : ''
                  }
                </div>
                <div className="clear"></div>
                <div className="cheat_day_meal meal_section_left pull-left" style={{ display: this.state.showSmartWeek === true ? "block" : "none" }} id="cheat_day_meal">
                  <div className="flex_between" id="toggle_treat_day_option">
                    <div className="pull-left my_profile_lft font-weight-bold display_journal_cheat_day_option_label">{localStorage.getItem("gender") === "male" ? window.keystrings['com.numi.cd.male_treat_day'] : window.keystrings['com.numi.cd.female_treat_day']}</div>
                    <div className="pull-right my_profile_rht" >
                      <div className={`toggle_switch round ${this.state.toggleOnOff === true ? "on" : "off"}`} onClick={this.toggleCheatDay.bind(this)}>
                        <div className="toggle_btn"></div>
                        <span className="on"></span>
                        <span className="off"></span>
                        <input type="hidden" value={this.state.display_treat_day_option} id="display_treat_day_option_id" />
                      </div>
                    </div>
                  </div>
                  <div id="display_journal_calorie_range_options" style={{ display: this.state.display_treat_day_option == true ? 'block' : 'none' }} className="mt-lg-3">
                    <div className="journal_treat_day_options border-top">
                      <div className="flex_between">
                        <div className="pull-left my_profile_lft display_journal_calorie_range_label">{localStorage.getItem("gender") === "male" ? window.keystrings['com.numi.cd.male_calorie_goal_range'] : window.keystrings['com.numi.cd.female_calorie_goal_range']}</div>
                        <div className="pull-right my_profile_rht">
                          <span>{this.state.display_calorie_goal_range === null ? "0 - 0" : this.state.display_calorie_goal_range}</span>
                        </div>
                      </div>
                      <div className="flex_between">
                        <div className="pull-left my_profile_lft display_journal_extra_calorie_label">{localStorage.getItem("gender") === "male" ? window.keystrings['com.numi.cd.male_extra_calories'] : window.keystrings['com.numi.cd.female_extra_calories']}</div>
                        <div className="pull-right my_profile_rht">
                          <span>{this.state.display_extra_calories_per_day === null ? "0" : this.state.display_extra_calories_per_day}</span> <span className="text-uppercase">calories</span>
                        </div>
                      </div>
                      <div className="flex_between" id="toggle_all_treat_day_option" >
                        <div className="pull-left my_profile_lft font-weight-bold display_journal_all_cheat_day_option_label">{localStorage.getItem("gender") === "male" ? window.keystrings['com.numi.cd.male_treat_meals'] : window.keystrings['com.numi.cd.female_treat_meals']}</div>
                        <div className="pull-right my_profile_rht">
                          <div className={`toggle_switch round ${this.state.toggleAllCheatDay == true ? 'on' : 'off'}`} onClick={this.applyCheatDayAll.bind(this)}>
                            <div className="toggle_btn"></div>
                            <span className="on"></span>
                            <span className="off"></span>
                            <input type="hidden" value={this.state.display_all_treat_day_option} id="display_all_treat_day_option_id" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <DragDropContext onDragEnd={this.handleDragEnd.bind(this)} onDragStart={this.handleDragStart.bind(this)}>
                  <Droppable droppableId="draggable-content" type="meal_occasion">
                    {(provider) => (
                      <div
                        ref={provider.innerRef}
                        {...provider.droppableProps}
                      >

                        <div className="panel-group meal_section_left pull-left" id="meal_accordion">
                          <MealOccasionSection
                            mealsectiondata={this.state.non_veg_foods}
                            displayalltreatdayption={this.state.displayalltreatdayption}
                            journalResponse={this.state.journal_response}
                            archive_view={this.state.archive_view}
                            getJournal={() => this.getJournal()}
                            resetAllState={() => this.resetAllState()}
                            showSmartWeek={this.state.showSmartWeek}
                            cheatDaylineShow={this.state.cheatDaylineShow}
                            drag_drop_status={this.state.drag_drop_status}
                            all_meals_cheat_meal_status={this.state.display_all_treat_day_option}
                          />
                        </div>
                        <div className="meal_section_right pull-right">
                          {
                            localStorage.getItem('journal_type') != "CALORIE_TRACKER" ?
                              <div style={{ display: this.state.summary_food_items?.length > 0 ? "block" : "none" }}>
                                <VegetableSection
                                  veg_food_items={this.state.veg_food_items}
                                  veg_foods_summaries={this.state.veg_foods_summaries}
                                  archive_view={this.state.archive_view}
                                  veg_food_meal_occassion_id={this.state.veg_food_meal_occassion_id}
                                  resetAllState={() => this.resetAllState()}
                                  summary_food_items={this.state.summary_food_items}
                                  drag_drop_status={this.state.drag_drop_status}
                                  all_meals_cheat_meal_status={this.state.display_all_treat_day_option}
                                  getJournal={() => this.getJournal()} />
                              </div>
                              :
                              ""

                          }

                          <div className="clear"></div>

                          {
                            this.state.extras_status == true ?
                              localStorage.getItem('journal_type') != "CALORIE_TRACKER" ?
                                <div style={{ display: this.state.summary_extras_items?.length > 0 ? "block" : "none" }}>
                                  <ExtrasSection
                                    extrasDetails={this.state.extrasDetails[0]}
                                    extras_foods_summaries={this.state.extras_foods_summaries}
                                    extras_meal_occassion_id={this.state.extras_meal_occassion_id}
                                    resetAllState={() => this.resetAllState()}
                                    summary_extras_items={this.state.summary_extras_items}
                                    drag_drop_status={this.state.drag_drop_status}
                                    all_meals_cheat_meal_status={this.state.display_all_treat_day_option}
                                    getJournal={() => this.getJournal()}
                                  />
                                </div>
                                :
                                ""
                              :
                              ""
                          }
                          <ActivitySection
                            activity_data={this.state.activity_data}
                            activity_goal={this.state.activity_goal}
                            getJournal={() => this.getJournal()} />
                          <div className="clear"></div>
                          <WaterGoalSection
                            water_data={this.state.water_data}
                            getJournal={() => this.getJournal()} />
                        </div>
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
              <Footer />
            </div>
            <OldNumiPopup />
            <LowBloodSugarPopup />
            <ActivityLevelPopup />
            <TurboTakeoffPopup callJournalAPIFromTurbo={this.getJournal} />
            <AlertPopup />
            <ActivityQuestions onRef={ref => (this.child = ref)} callReadAPI={this.readAlert} callJournalAPI={this.getJournal} />
            <SetGoalWeightPopup callJournalAPIFromSetGoal={this.getJournal} callSmartAdapt={this.callSmartAdapt} />
            <UnlogBorrowfood />
          </div>
          <Modal show={this.state.changeTreatDayPopup} id="change_treat_day_type_popup" onHide={this.hideTreatDayPopup}>
            <Modal.Header>
              <button type="button" className="close" onClick={() => { this.setState({ changeTreatDayPopup: false }); }}>
                <img alt="" src="/assets/images/close_grey_new_modal.png" />
              </button>
            </Modal.Header>
            <Modal.Body>
              <img alt="" src="/assets/images/change_cheat_standard_days_icon.png" />
              {
                this.state.toggleCheatDayPopup == "treatday" ?
                  <>
                    <p className="font22 color3 pd20 mt5 font700 mb-0 error_text display_change_treat_day_text">{this.state.showSmartWeek === true ? localStorage.getItem("gender") === "male" ? window.keystrings['com.numi.cd.male_treat_day_change'] : window.keystrings['com.numi.cd.female_treat_day_change'] : ""}</p>
                    <p className="font18 color3 mt1 mb5 font400 error_text display_change_treat_day_desc_text">{this.state.showSmartWeek === true ? localStorage.getItem("gender") === "male" ? window.keystrings['com.numi.cd.male_planned_treat_days'] : window.keystrings['com.numi.cd.female_planned_treat_days'] : ""}</p>
                    <p><button className="continue_button_txt 00" id="change_treat_day_type_popup_btn" onClick={this.applyCheatDayPopup.bind(this)}>{this.state.showSmartWeek === true ? localStorage.getItem("gender") === "male" ? window.keystrings['com.numi.cd.male_change_to_treat_day'] : window.keystrings['com.numi.cd.female_change_to_treat_day'] : ""}</button></p>
                  </>
                  :
                  <>
                    <p className="font22 color3 pd20 mt5 font700 mb-0 error_text display_change_treat_day_text">{this.state.showSmartWeek === true ? localStorage.getItem("gender") === "male" ? window.keystrings['com.numi.cd.male_standard_day_change'] : window.keystrings['com.numi.cd.female_treat_day_change'] : ""}</p>
                    <p className="font18 color3 mt1 mb5 font400 error_text display_change_treat_day_desc_text">{this.state.showSmartWeek === true ? localStorage.getItem("gender") === "male" ? window.keystrings['com.numi.cd.male_planned_treat_days'] : window.keystrings['com.numi.cd.female_planned_treat_days'] : ""}</p>
                    <p><button className="continue_button_txt 00" id="change_treat_day_type_popup_btn" onClick={this.applyCheatDayPopup.bind(this)}>{this.state.showSmartWeek === true ? localStorage.getItem("gender") === "male" ? window.keystrings['com.numi.cd.male_change_to_standard_day'] : window.keystrings['com.numi.cd.female_change_to_standard_day'] : ""}</button></p>
                  </>

              }
              <p><button className="cancel_button_txt" onClick={this.cancellCheatdayPopup.bind(this)}>{window.keystrings['com.numi.cancel']}</button></p>
            </Modal.Body>
          </Modal>

          <Modal show={this.state.personalize_modal} id="personalize_questions" backdrop="static" onHide={this.hidePersonalizeModal}>

            <Modal.Body>
              <div className="modal-content personalized">
                <div className="personalized_popup">
                  <div className="calc_hdr"><i></i> <span className="personal_hdr" dangerouslySetInnerHTML={{ __html: window.keystrings['com.numi.personalized_plan'] }}></span> <img className="quest_white  cursor_pointer" title="" alt="" src="/assets/images/question_white.jpg" data-target="#help_cipd" data-toggle="modal" /></div>
                  <img src="/assets/images/blaze.svg" className="blaze_img" alt="" title="" />
                  <h3 className="mt20px personal_meal_hdr" dangerouslySetInnerHTML={{ __html: window.keystrings['com.numi.personalized_meal_hdr'] }}></h3>
                  <div className="mt20px personalized_text font18" dangerouslySetInnerHTML={{ __html: window.keystrings['com.numi.personalized_text'] }}></div>
                  <button onClick={this.showPersonal}>Continue</button>
                </div>
                <PersonalizePlanMode callSmartAdapt={this.callSmartAdapt} callJournalAPI={this.getJournal} childRefJournal={ref => (this.childStartQuiz = ref)} />
              </div>
            </Modal.Body>
          </Modal>
          <FoodHelp ref="callHelp" />
        </div>
      </>
    )
  }
}

export default Journal;