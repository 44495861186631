import React from 'react';
import moment from 'moment-timezone';
import { withRouter } from 'react-router-dom';
import Newsfeed from "./newsfeed/newsfeed.js";
import axios from 'axios';
import * as Sentry from '@sentry/react';
import GoogleTagManager from "./gtm/google_tag_manager.js";

var $ = require('jquery');
window.jQuery = $;
window.tickerIntervalSeconds = 1; // every one second
window.tickerThresholdSeconds = 5; // five seconds

class Header extends React.Component {

  constructor(props) {
    super(props)
    this.state = { loading: false };
  }

  static connectDevice = (connect_server_url, authorizationHeaders, hrefLoc) => {
    Header.startLoader();
    $.getScript(
      connect_server_url + '/connect/assets/javascripts/connect.js'
    ).done(function (script, textStatus) {
      hrefLoc = typeof hrefLoc === 'undefined' ? document.location.href : hrefLoc;
      $(document).trigger('GtmConnectDeviceEvent');
      return $.ajax({
        url: window.API_URL + 'v1/connect',
        headers: authorizationHeaders,
        success: function (data) {
          $('.loader_img').remove();
          var connectOptions;
          connectOptions = {
            externalUserId: data.connect.external_user_id,
            applicationClientId: data.connect.application_client_id,
            publicToken: data.connect.public_token,
            serverUrl: connect_server_url,
            modal: 1,
            connect: function (err, sessionTokenObject) {
              $('.loader_img').remove();
              $.post(sessionTokenObject.authURL, {
                sessionToken: sessionTokenObject.sessionToken
              }).fail(function () {
                alert("error");
              }).done(function () {
                document.location.href = hrefLoc;
              });
            },
            close: function () {
              $('.loader_img').remove();
              document.location.href = hrefLoc;
            },
            error: function (err) {
              $('.loader_img').remove();
              alert(err);
            }
          };
          window.NtriConnect.open(connectOptions);
        }
      });
    });
  };

  static apiErrors(error) {
    $('#success-alert').remove();
    if (error && error.response && error.response.status === 401) {
      window.braze.disableSDK();
      localStorage.clear();
      sessionStorage.clear();
      this.props.history.push("/login");

    }
    else if (error && error.response && error.response.data && error.response.data.error) {
      $('<div id="success-alert" class="alert alert-danger alert-dismissible fade in"><span class="close cursor_pointer" data-dismiss="alert" aria-label="close">&times;</span>' + error.response.data.error + '</div>').prependTo('body');
    }
    else if (error && error.response && error.response.status > 400) {
      $('<div id="success-alert" class="alert alert-danger alert-dismissible fade in"><span class="close cursor_pointer" data-dismiss="alert" aria-label="close">&times;</span>' + error.response.statusText + '</div>').prependTo('body');
    }
    else if (error && error.message) {
      $('<div id="success-alert" class="alert alert-danger alert-dismissible fade in"><span class="close cursor_pointer" data-dismiss="alert" aria-label="close">&times;</span>' + error.message + '</div>').prependTo('body');
    }
    $("#success-alert").fadeTo(7000, 500).slideUp(500, function () {
      $("#success-alert").slideUp(500);
    });

  }

  static startLoader() {

    if ($('.loader_img').length) {
      $('.loader_img').remove(); //remove it before showing if already present.
    }
    $('<div class="loader_img"><div class="loader_gif"></div></div>').appendTo('body');
    $('.loader_img').show(); // show on any Ajax event.
    $('.loader_img').height($('body').height());
    $('.loader_gif').css({ position: 'fixed', top: $(window).scrollTop() + $(window).height() / 2 });
  }
  static stopLoader() {
    $('.loader_img').remove(); // remove it when it is done.
  }

  static authorization_headers() {
    var zone_name = moment.tz.guess();
    return {
      'Content-Type': 'application/json',
      'Authorization': (
        window.localStorage.getItem('user_access') !== null ? 'Bearer ' + window.localStorage.getItem('user_access') : 'Basic ' + process.env.REACT_APP_JWT_CLIENT_ID
      ),
      'User-Email': 'Email email=' + window.localStorage.getItem('current_user_email'),
      'X-CSRF-TOKEN': unescape(window.readCookie("CSRF-TOKEN")),
      'TIME-ZONE-NAME': zone_name,
      'DEVICE-IDENTIFIER': Header.deviceIdentifier(),
      'IMPERSONATION': window.localStorage.getItem('impersonation')
    };
  }

  static setAuthHeaders() {
    window.authorization_headers = Header.authorization_headers()
    return window.authorization_headers;
  }


  static handleJWTErrors(response) {
    if (response.status === 403) {
      Header.refresh_authorization_headers(true);
      return response;
    } else if (response.status === 401) {
      if (window.location.pathname !== "/signup" && window.location.pathname !== "/new_signup" && window.location.pathname !== "/login" && window.location.pathname !== "/account_details" && window.location.pathname !== "/forgot_password" && window.location.pathname !== "/unsubscribe") {
        this.props.history.push('/login');
      }

    } else {
      return response;
    }

  }

  static refresh_check_ticker() {
    var userAccessExpiry = window.localStorage.getItem('user_access_expires_in');
    if (userAccessExpiry && userAccessExpiry >= 0) {
      userAccessExpiry = userAccessExpiry - window.tickerIntervalSeconds;
      window.localStorage.setItem('user_access_expires_in', userAccessExpiry);
      Header.check_refresh_authorization_headers();
    }
  }

  static check_refresh_authorization_headers(force) {
    var userRefresh = window.localStorage.getItem('user_refresh');
    var userAccessExpiry = window.localStorage.getItem('user_access_expires_in');
    if (userRefresh && userAccessExpiry) {
      if (userAccessExpiry < window.tickerThresholdSeconds) {
        Header.refresh_authorization_headers(force);
      }
    }
  }

  static refresh_authorization_headers(force) {
    if (!window.localStorage.getItem('user_refresh')) return;
    if (!window.localStorage.getItem('refreshing') || force) {
      window.localStorage.setItem('refreshing', true);
      var zone_name = moment.tz.guess();

      const options = {
        url: window.API_URL + "oauth/refresh",
        method: 'POST',
        credentials: 'same-origin',
        cache: false,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + process.env.REACT_APP_JWT_CLIENT_ID,
          'TIME-ZONE-NAME': zone_name,
          'User-Email': 'Email email=' + window.localStorage.getItem('current_user_email'),
          'DEVICE-IDENTIFIER': Header.deviceIdentifier()
        },
        data: {
          'user': { 'refresh': window.localStorage.getItem('user_refresh') }
        }
      };

      axios(options)
        .then(response => {
          window.localStorage.setItem('user_access', response.data.user.access);
          window.localStorage.setItem('user_refresh', response.data.user.refresh);
          window.localStorage.setItem('user_access_expires_at', response.data.user.access_expires_at);
          window.localStorage.setItem('user_access_expires_in', response.data.user.access_expires_in);

          window.localStorage.removeItem('refreshing');
          if (force) window.location.reload();
        })
        .catch(error => {
          if (error && error.response && error.response.status === 401) {
            window.localStorage.removeItem('user_refresh');
            window.localStorage.removeItem('user_access');
            Sentry.captureException(error);
            window.location.href = "/login";

          }
          Sentry.captureException(error);
        });
    }
  }

  static deviceIdentifier() {
    var deviceId = window.localStorage.getItem('DEVICE-ID');
    if (!deviceId) {
      deviceId = window.randomString(64)
      window.localStorage.setItem('DEVICE-ID', deviceId)
    }
    return deviceId;
  }

  static onWindowFocused(event) {
    setTimeout(function () {
      Header.refresh_authorization_headers();
    }, 500);
  }

  sessionCheckOnLoad = () => {
    // redirect to login if not logged in.
    var user_access = window.localStorage.getItem('user_access');

    if (window.location.pathname !== "/signup" && window.location.pathname !== "/new_signup" && window.location.pathname !== "/account_details" && window.location.pathname !== "/forgot_password" && window.location.pathname !== "/unsubscribe") {
      if (user_access === null) {
        if (this.props.location.pathname !== '/' && this.props.location.pathname !== '/login') {
          localStorage.setItem('session_timeout', true);
        }
        this.props.history.push('/login');
        return false
      }
      else {
        if (this.props.location.pathname === "/login" || this.props.location.pathname === "/")
          if (localStorage.getItem('profile_status') === 'ONBOARDING_COMPLETE') {
            this.props.history.push('/journal');
          }
          else if (localStorage.getItem('profile_status') === 'PLAN_COMPLETE') {
            this.props.history.push('/stay_on_track');
          }
          else if (localStorage.getItem('profile_status') === 'PROFILE_COMPLETE') {
            this.props.history.push('/ns_plan');
          }

      }

    }


    // Check for 403 errors on axios response interceptor
    axios.interceptors.response.use(function (response) {
      $('#success-alert').remove();
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    }, function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      if (error.response.status === 403) {
        $('#success-alert').remove();
        Header.refresh_authorization_headers(true);
      } else {
        return Promise.reject(error);
      }
    });

    // check for Ajax errors on complete.
    $(document).ajaxComplete(function (event, request, settings) {
      Header.handleJWTErrors(request)
    });

    // check every second for invalid expiry.
    if (window.refreshTickerInterval !== undefined && window.refreshTickerInterval !== 'undefined') {
      window.clearInterval(window.refreshTickerInterval);
    }
    window.refreshTickerInterval = setInterval(Header.refresh_check_ticker, window.tickerIntervalSeconds * 1000);
    window.localStorage.removeItem('refreshing');

    // Set and remove window focus listener.
    window.removeEventListener("focus", Header.onWindowFocused, false);
    window.addEventListener("focus", Header.onWindowFocused, false);
  }

  static getTurboTakeoffDays() {

    const options = {
      url: window.API_URL + "v1/global_configs/key/DEFAULT_TURBO_TAKEOFF_DAYS",
      method: 'GET',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {

        Header.stopLoader();

        window.localStorage.setItem('TURBO_TAKEOFF_DAYS', response.data.global_config.value);

      })
      .catch(error => {
        Header.stopLoader();
        Sentry.captureException(error);
      });


  }

  static changeDate(d) {

    if ($('.today_date').length === 0) {
      $('.react-datepicker__input-container').prepend('<div class="today_date">Today</div>');
    }
    var today = moment();
    var tomorrow = moment().add(1, 'day');
    var yesterday = moment().subtract(1, 'day');

    var equal_dates = moment(d);
    if ($('.journal_page').length && !$('.modal').is(':visible') && $('.modal.fade.show').length == 0) {
      if (moment(equal_dates).isSame(tomorrow, 'day')) {
        $('.selected_date').text('Tomorrow');
      }
      else {
        if (moment(equal_dates).isSame(yesterday, 'day')) {

          $('.selected_date').text('Yesterday');
        }
        else {
          if (moment(equal_dates).isSame(today, 'day')) {
            $('.selected_date').text('Today');
          }
          else {
            $('.selected_date').text(moment(d).format('MMM DD'));
            $('.today_date').text(moment(d).format('MMM DD'));
          }

        }
      }

      if ($('.selected_date').text().length > 6) {
        $('.selected_date').addClass('date_adjust')
      }
      else {
        $('.selected_date').removeClass('date_adjust')
      }


    }
    else {
      if ($('.modal').hasClass('show')) {
        if (moment(equal_dates).isSame(tomorrow, 'day'))
          $('.modal.fade.show').find('.today_date').text('Tomorrow');
        else if (moment(equal_dates).isSame(yesterday, 'day'))
          $('.modal.fade.show').find('.today_date').text('Yesterday');
        else if (moment(equal_dates).isSame(today, 'day'))
          $('.modal.fade.show').find('.today_date').text('Today');
        else
          $('.modal.fade.show').find('.today_date').text(moment(d).format('MMM DD'));
      }
      else {
        if (moment(equal_dates).isSame(tomorrow, 'day'))
          $('.today_date').text('Tomorrow');
        else if (moment(equal_dates).isSame(yesterday, 'day'))
          $('.today_date').text('Yesterday');
        else if (moment(equal_dates).isSame(today, 'day'))
          $('.today_date').text('Today');
        else
          $('.today_date').text(moment(d).format('MMM DD'));
      }
    }
  }


  componentWillUnmount() {
    this.unlisten();

  }

  setUniqPage(location) {
    window['gtm_key'] = `app${location.pathname.replace(/\//g, '_')}`
    if (`${location.pathname}` === '/food_details' && `${location.state}` !== undefined) {
      window['gtm_key'] = `app_custom${location.pathname.replace(/\//g, '_')}`
    }
  }

  // Set global api url and headers for api calls.
  componentDidMount() {

    if (window.location.hash === '#connect_device') {
      Header.connectDevice();
    }

    this.sessionCheckOnLoad();
    $('.react-datepicker__input-container input').attr('readonly', true);
    if ($('.today_date').length === 0) {
      $('.react-datepicker__input-container').prepend('<div class="today_date">Today</div>');
    }
    /* InApp  click functions */
    window.inAppClicks = {
      clickNewsfeed: function () {
        window.location.href = '/newsfeed';
      },
      clickSettings: function () {
        window.location.href = '/settings';
      },
      clickLogfood: function () {
        window.localStorage.setItem('from_plus', 'true');
        window.location.href = '/log_food';
      },
      clickJournal: function () {
        window.location.href = '/journal';
      },
      clickLogRecipe: function () {
        window.location.href = '/recipe';
      },
      clickProgress: function () {
        window.location.href = '/progress';
      },
      clickLogActivity: function () {
        window.location.href = '/log_activity';
      },
      clickLogWater: function () {
        localStorage.setItem('popup_water', 'true');
        window.location.href = '/journal';

      },
      clickImages: function () {
        window.location.href = '/photos';
      },
      clickBadges: function () {
        window.location.href = '/badges';
      },
      switchToEating: function () {
        switch_phases('v1/transition_determinants?to_phase_id=1&to_plan_key=NS_RETAIL_INT_FASTING_5_2&transition_type=PHASE_CHANGE');
      },
      switchToFasting: function () {
        switch_phases('v1/transition_determinants?to_phase_id=2&to_plan_key=NS_RETAIL_INT_FASTING_5_2&transition_type=PHASE_CHANGE');
      }
    }
    /* InApp  click functions */

    this.setUniqPage(window.location);
    this.unlisten = this.props.history.listen(location => {
      this.setUniqPage(location);
      GoogleTagManager.setPageData();
    });

    var midnight = "12:00:00";
    var now = null;
    var arr = []; // Array to hold the keys

    setInterval(function () {
      now = moment().format("h:mm:ss");
      if (now === midnight) {
        for (var i = 0; i < localStorage.length; i++) {
          if (localStorage.key(i).substring(0, 3) === '#me') {
            arr.push(localStorage.key(i));
          }
        }

        // Iterate over arr and remove the items by key
        for (var j = 0; j < arr.length; j++) {
          localStorage.removeItem(arr[j]);
        }
      }
    }, 1000);

    const switch_phases = (url) => {
      fetch(url)
        .then(result => result.json())
        .then(
          (result) => {
            window.location.href = '/journal';
          })
        .catch(err => {
          console.log(err);
          Sentry.captureException(err);
        })
    };

    $(document).on('click', 'body,.dropdown_list', function () {
      $('.list-group').hide();
      if ($('.journal_page').length) {
        localStorage.removeItem('searched_term');
      }
    });

    $(document).on('click', '.help_accordion,#help_accord a,#help_accordion a,#help_accordion_option a', function (e) {
      e.stopImmediatePropagation();
      var _self = $(this);
      _self.parents('.panel-group').find('.panel-collapse').removeClass('in');
      if (_self.parents('.panel-default').find('.accord_help').hasClass('rotate90')) {

        _self.parents('.panel-default').find('.accord_help').removeClass('rotate90');
      }
      else {
        $('.accord_help').removeClass('rotate90');
        _self.parents('.panel-default').find('.accord_help').addClass('rotate90');
      }
    })

    $(document).on('click', '.next', function (e) {
      e.stopImmediatePropagation();
      if ($(this).parents('.table-condensed').find('td.new:last').hasClass('disabled') && $(this).parents('.table-condensed').find('td.new:eq(0)').hasClass('disabled')) {
        $(this).addClass('disable_arrows');
        $(this).parents('.table-condensed').find('.prev').removeClass('disable_arrows');
      }
      else {
        $(this).removeClass('disable_arrows');
        $(this).parents('.table-condensed').find('.prev').removeClass('disable_arrows');
      }
      if ($(this).parents('.table-condensed').find('td.active').next('td').hasClass('disabled')) {
        $('.right_arrow').addClass('disable_arrows');
      }
      else {
        $('.right_arrow').removeClass('disable_arrows');
      }

      if ($(this).parents('.table-condensed').find('td.active').prev('td').hasClass('disabled')) {
        $('.left_arrow').addClass('disable_arrows');
      }
      else {
        $('.left_arrow').removeClass('disable_arrows');
      }
    });
    $(document).on('click', '.prev', function (e) {
      e.stopImmediatePropagation();
      var k = $(this).parents('.table-condensed').find('td.old').length;
      for (var p = 0; p < k; p++) {
        if ($(this).parents('.table-condensed').find('td.old:eq(' + p + ')').hasClass('disabled')) {
          $(this).addClass('disable_arrows');
          $(this).parents('.table-condensed').find('.next').removeClass('disable_arrows');
        }
        else {
          $(this).removeClass('disable_arrows');
          $(this).parents('.table-condensed').find('.next').removeClass('disable_arrows');
        }
      }

      if ($(this).parents('.table-condensed').find('td.active').prev('td').hasClass('disabled')) {
        $('.left_arrow').addClass('disable_arrows');
      }
      else {
        $('.left_arrow').removeClass('disable_arrows');
      }

      if ($(this).parents('.table-condensed').find('td.active').next('td').hasClass('disabled')) {
        $('.right_arrow').addClass('disable_arrows');
      }
      else {
        $('.right_arrow').removeClass('disable_arrows');
      }

    });




    setTimeout(function () {
      if ($('td.old:first-child').hasClass('disabled') && $('td.old:last').hasClass('disabled')) {
        $('.prev').addClass('disable_arrows')
      }
      else {
        $('.prev').removeClass('disable_arrows')
      }
      if ($('td.new:last').hasClass('disabled') && $('td.new:first-child').hasClass('disabled')) {
        $('.next').addClass('disable_arrows')
      }
      else {
        $('.next').removeClass('disable_arrows')
      }

      if ($('.date_sec td.old:first-child').hasClass('disabled') && $('.date_sec td.old:last').hasClass('disabled')) {
        $('.date_sec .prev').addClass('disable_arrows')
      }
      else {
        $('.date_sec .prev').removeClass('disable_arrows')
      }
      if ($('.date_sec td.new:last').hasClass('disabled') && $('.date_sec td.new:first-child').hasClass('disabled')) {
        $('.date_sec .next').addClass('disable_arrows')
      }
      else {
        $('.date_sec .next').removeClass('disable_arrows')
      }
      if ($('.progress').length) {

        if ($('td.old:first-child:not(#turbo_takeoff_content td.old:first-child)').hasClass('disabled') && $('td.old:last-child:not(#turbo_takeoff_content td.old:first-child)').hasClass('disabled')) {
          $('.prev').addClass('disable_arrows')
        }
        else {
          $('.prev').removeClass('disable_arrows')
        }
        if ($('td.new:last-child:not(#turbo_takeoff_content td.new:last-child)').hasClass('disabled')) {
          $('.next').addClass('disable_arrows')
        }
        else {
          $('.next').removeClass('disable_arrows')
        }
      }

      if ($('.plan_settings_title_text').length) {
        if ($('.date_pick_turbo td.today').prev().hasClass('disabled')) {
          $('.date_pick_turbo .prev').addClass('disable_arrows')
        }
        else {
          $('.date_pick_turbo .prev').removeClass('disable_arrows')
        }
        if ($('.date_pick_turbo td.new:last').hasClass('disabled') && $('.date_pick_turbo td.new:first-child').hasClass('disabled')) {
          $('.date_pick_turbo .next').addClass('disable_arrows')
        }
        else {
          $('.date_pick_turbo .next').removeClass('disable_arrows')
        }
      }

    }, 1222)
    $(document).on('click', '.form-control,.today_date', function (e) {
      e.stopImmediatePropagation();
      if ($(this).hasClass('today_date')) {
        $(this).next().click();
      }
      else {
        $(this).parent('.input-group').find('.input-group-addon').click();
      }

    });
    $(document).on('click', '#revert-to-admin', function () {
      localStorage.clear();
      sessionStorage.clear();
    });


    // ENV variable API_URL value is not getting in server and so will take this to absolute url.
    window.API_URL = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '';

    // Sentry code to track react js errors
    // Sentry.init({
    //   dsn: process.env.REACT_APP_SENTRY_DSN,
    //   ignoreErrors: ['window.AdSpeed_jQuery is not a function']
    // });
    // Sentry.configureScope(scope => {
    //   scope.setUser({
    //     id: localStorage.getItem('user_id')
    //   });
    // });
    // Sentry.setTag("server_name", "React");

    if (localStorage.getItem('user_id') !== null && window.location.pathname === '/journal') {
      Newsfeed.loadAppboy();
    }

    Header.setAuthHeaders();
    if (localStorage.getItem('TURBO_TAKEOFF_DAYS') === null) {
      Header.getTurboTakeoffDays();
    }


    /*keystrings*/
    if (window.keystrings) {

      $('.header_quick_links_text').text(window.keystrings['com.numi.header.quick_links']);
      $('.header_nutrisystem_account_text').text(window.keystrings['com.numi.header.nutrisystem_account']);
      $('.header_track_with_numi_text').text(window.keystrings['com.numi.header.track_with_numi']);
      $('.header_grow_with_leaf_text').text(window.keystrings['com.numi.header.grow_with_leaf']);
      $('.header_nutrisystem_account_text').attr('href', window.keystrings['com.numi.header.nutrisystem_account_link']);
      $('.header_grow_with_leaf_text').attr('href', window.keystrings['com.numi.header.grow_with_leaf_link']);
      $('.nutri_logo_link').attr('href', window.keystrings['com.numi.header.nutri_logo_link']);
      $('.header_track_with_numi_text').attr('href', window.keystrings['com.numi.numi_link']);
    }

    /*keystrings*/
  }


  render(props) {
    return (
      <>
        <header>
          <nav className="navbar-expand-lg navbar-light nav">
            <ul className="nav navbar-nav mr-auto">
              <li className="nav-item">
                <span className="header_quick_links_text"></span>
              </li>
              <li className="nav-item">
                <a href="http://www.nutrisystem.com/numisupernavmyaccount" className="header_nutrisystem_account_text nav-link">

                </a>
              </li>
              <li className="nav-item">
                <a href="http://www.numi.com" className="header_track_with_numi_text nav-link">

                </a>
              </li>
              <li className="nav-item">
                <a href="https://leaf.nutrisystem.com/?utm_medium=leaf&utm_source=numisupernav&utm_campaign=numisupernav" className="header_grow_with_leaf_text nav-link">

                </a>
              </li>
              {/* <li className="pull-right">
                <a href="https://www.nutrisystem.com/numisupernavma" rel="noopener noreferrer" target="_blank" className="padding0 nutri_logo_link"> <img alt="" src="/assets/images/nutri_logo.png" />
                </a>
              </li> */}

            </ul>
            <a href="https://www.nutrisystem.com/numisupernavmyaccount21" rel="noopener noreferrer" target="_blank" className="nav-link form-inline web"> <img alt="" src="/assets/images/nutri_logo3.png" />
            </a>

            <a href="https://www.nutrisystem.com/numisupernavmyaccount21" rel="noopener noreferrer" target="_blank" className="nav-link form-inline mobile"> <img alt="" src="/assets/images/nutrisystem@2x.png" />
            </a>

          </nav>

          <GoogleTagManager
            gtmId={process.env.REACT_APP_GTM_ID}
            scriptId='gtm-script-container'
            dataLayerName='dataLayer'
            additionalEvents={this.event}
            previewVariables={`&gtm_auth=${process.env.REACT_APP_GTM_AUTH}&gtm_preview=${process.env.REACT_APP_GTM_PREVIEW}&gtm_cookies_win=x`}
          />

          {
            window.localStorage.getItem('impersonation') &&
            <div className="impersonation">
              <div>
                <a
                  id='revert-to-admin'
                  href={`${process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : ''}admin/users`}>
                  Revert to Admin
                </a>
              </div>
            </div>
          }

        </header>

      </>
    )
  }
}
export default withRouter(Header);
