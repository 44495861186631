
import React from 'react';
import Header from "../header.js";
import { Link } from 'react-router-dom'
import Footer from "../footer.js";
import UnassinedPopup from "../create_food/unassigned_food.js";
import MealDropdown from "./meal_dropdown.js";
import DatePicker from "react-datepicker";
import { FoodHelp, CountAsEditValidate } from './popup.js';
import moment from 'moment'
import Navigation from "../nav.js";
import Adspeed from "../adspeed.js";
import { addDays, parse } from 'date-fns';
import axios from 'axios';
import * as Sentry from "@sentry/react";

import $ from 'jquery';
class Food_details extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: moment(),
      serving_size: 1,
      serving_size_unit: null,
      food: [],
      food_serving_size: 1,
      nutrients: [],
      calories: '',
      favorite: false,
      add_to_fav: window.keystrings['com.numi.remove_from_favorites'],
      add_fav_off: "/assets/images/fav_on.png",
      edit_icon: '/assets/images/edit.png',
      food_icon_array: [],
      food_serving_size_id: '',
      display_leaf_icon: false,
      serving_list: [],
      serving_list1: [],
      serving_size_texts: "",
      serving_sizes: [],

      // fix for imagre
      s3_images_path: "",
      servings_per_container: "",
    };
    window.get_unassigned_popup = "";
    window.nutrients_array = [];
    window.changed_date = window.localStorage.getItem('assigned_date');
  }

  showFoodDetailsHelp(value) {
    window.localStorage.setItem('help_location', value);
    this.child.refs.callHelp.callHelpAPI();
  }
  servingChangeUnit = () => {
    window.localStorage.setItem("change_serving_size", true);
    this.validateDietHistory();
  }


  mealChange = () => {
    var bucket_sequence;
    var currentHour = moment().format('HH');

    if (currentHour <= 10 && currentHour >= 0) {
      bucket_sequence = '1';
    } else if (currentHour > 10 && currentHour < 12) {
      bucket_sequence = '2';
    } else if (currentHour >= 12 && currentHour < 14) {
      bucket_sequence = '3';
    } else if (currentHour >= 14 && currentHour < 17) {
      bucket_sequence = '4'
    } else if (currentHour >= 17 && currentHour < 20) {
      bucket_sequence = '5';
    } else if (currentHour >= 20 && currentHour <= 24) {
      bucket_sequence = '6';
    }
    window.localStorage.setItem('meal_occasion_id', bucket_sequence);
    setTimeout(function () {
      this.refs.get_meal_data.callMealData();
    }.bind(this), 155);
  }

  UnFavoriteCustomFood = (fav_param) => {
    /* Unfavorite Custom favorite food */
    if (window.localStorage.getItem('custom_fav_food_consumable_id') !== '' && window.localStorage.getItem('custom_fav_food_consumable_id') !== null && window.localStorage.getItem('custom_fav_food_consumable_id') !== undefined) {
      var unFav_data = { "favorite_food": { "consumable_id": window.localStorage.getItem('custom_fav_food_consumable_id'), "consumable_type": window.localStorage.getItem('custom_fav_food_consumable_type'), "favorite": fav_param } }

      const options = {
        url: window.API_URL + 'v1/favorite_foods',
        method: 'POST',
        credentials: 'same-origin',
        headers: Header.setAuthHeaders(),
        data: unFav_data
      };

      axios(options)
        .then(response => {

        })
        .catch(error => {
          Header.stopLoader();
          Header.apiErrors(error);
          Sentry.captureException(error);
        });

    }

  }

  CreateCustomFavFood = () => {
    Header.startLoader();
    var food_category_custom_foods = [];
    var fc_names = [];
    var all_img_name = []
    $('.un_assign img').each(function () {
      all_img_name.push($(this).attr('name'))
    });
    var occurrences = {};
    for (var i = 0, j = all_img_name.length; i < j; i++) {
      occurrences[all_img_name[i]] = (occurrences[all_img_name[i]] || 0) + 1;
    }
    var value;
    $('.un_assign img').each(function () {
      if (fc_names.indexOf($(this).attr('name')) < 0) {
        if ($(".weight_validate").val() <= 1) {
          value = occurrences[$(this).attr('name')];
        } else {
          value = occurrences[$(this).attr('name')] / Math.round($(".weight_validate").val());
        }
        food_category_custom_foods.push({ "name": $(this).attr('name'), "value": value + '.0' });
        fc_names.push($(this).attr('name'));
      }
    });

    var nutrients = [];
    window.nutrients_array.map(function (item) {
      return nutrients.push({ "name": item.name, "value": item.value })
    })
    var cc_food = { "custom_food": { "name": $(".log_food_name").text(), "calories": window.calorie, "serving_size_unit": $('#serving_option').find(":selected").text(), "serving_size": "1", "favorite": true, "source_consumable_id": window.localStorage.getItem('consumable_id'), "source_consumable_type": window.localStorage.getItem('consumable_type'), "food_category_custom_foods": food_category_custom_foods, "meal_occasion_id": $('.select_replace i').attr('id'), "custom_food_nutrients": nutrients, "brand": window.food_brand, "description": window.food_description } }
    const options = {
      url: window.API_URL + 'v1/custom_foods/custom_favorite_foods',
      method: 'POST',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
      data: cc_food
    };

    axios(options)
      .then(response => {
        Header.stopLoader();
        if (response && response.data) {
          window.localStorage.setItem('custom_food_consumable_id', response.data.custom_food.id);
          window.localStorage.setItem('custom_fav_food_consumable_id', response.data.custom_food.id);
          window.localStorage.setItem('custom_fav_food_consumable_type', 'CustomFood');
        }
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });

  }

  validateDietHistory = () => {
    Header.startLoader();
    var assigned_date;
    var term;
    if (window.localStorage.getItem('new_assigned_date') !== '' & window.localStorage.getItem('new_assigned_date') !== null && window.localStorage.getItem('new_assigned_date') !== undefined) {
      assigned_date = window.localStorage.getItem('new_assigned_date');
    } else {
      assigned_date = window.localStorage.getItem('assigned_date');
    }
    var consumable_id = window.localStorage.getItem('consumable_id');
    var consumable_type = window.localStorage.getItem('consumable_type');
    if (window.localStorage.getItem('searched_term') !== null && window.localStorage.getItem('searched_term') !== undefined && window.localStorage.getItem('searched_term') !== '') {
      term = window.localStorage.getItem('searched_term');
    }
    else {
      term = ''
    }


    if (window.localStorage.getItem('change_serving_size') === null || window.localStorage.getItem('change_serving_size') === undefined) {

      var diet_options = {
        url: window.API_URL + "v1/consumables/consumable_id/" + consumable_id + "/consumable_type/" + consumable_type + "?assigned_date=" + assigned_date + "&term=" + encodeURI(term),
        method: 'GET',
        credentials: 'same-origin',
        headers: Header.setAuthHeaders(),
      };

      axios(diet_options)
        .then(response => {

        })
        .catch(error => {
          Header.stopLoader();
          Header.apiErrors(error);
          Sentry.captureException(error);
        });

    }
    var kk = [];
    var final_array = [];
    if (window.localStorage.getItem('recents_food_details') === "true" && window.localStorage.getItem('recents_food_details') !== null && window.localStorage.getItem('recents_food_details') !== undefined) {
      /*when clicked on recents api call*/
      var recent_options = {
        url: window.API_URL + "v1/diet_histories/" + window.localStorage.getItem('dh_id'),
        method: 'GET',
        credentials: 'same-origin',
        headers: Header.setAuthHeaders(),
      };

      axios(recent_options)
        .then(response => {
          Header.stopLoader();
          setTimeout(() => {
            this.setState({ food: response.data.diet_history, calories: parseInt(this.state.food.calories) / parseInt(this.state.food.serving_size), nutrients: response.data.diet_history.nutrients, serving_size_unit: response.data.diet_history.serving_unit, serving_size: response.data.diet_history.serving_size, serving_sizes: response.data.diet_history.food_serving_sizes, favorite: response.data.diet_history.favorite_foods, s3_images_path: response.data.diet_history.s3_images_path });

            window.calorie = response.data.diet_history.calories;
            window.food_brand = response.data.diet_history.brand_name;
            window.food_description = response.data.diet_history.food_description;
            $('.food_details_page .add_fav').show();
            /*if(this.state.serving_sizes !== ''){
              $.each(this.state.serving_sizes, function( index, value ) {
                if( value.default_serving_size == true ){
                  $("#serving_option option[value='"+value.id+"']").attr("selected", "selected");
                }
              });
            }*/
            if (response.data.diet_history.food_serving_sizes) {
              /* this.setState({food_serving_size_id: response.data.diet_history.food_serving_sizes[0].id});*/
              if (response.data.diet_history.food_source === "Nutrisystem" || response.data.diet_history.food_source === "leaf") {
                this.setState({ display_leaf_icon: true });
              }
            }
            window.cals = this.state.calories;
            window.nutrients_array = response.data.diet_history.nutrients;
            $('.nutrients_div').empty();
            for (var jj = 0; jj < window.nutrients_array.length; jj++) {
              $('.nutrients_div').append('<div><div class="facts_sec" key={i}><div class="pull-left">' + response.data.diet_history.nutrients[jj].display_name + '</div> <div class="text-right ull-right">' + response.data.diet_history.nutrients[jj].value + ' ' + response.data.diet_history.nutrients[jj].units + '</div></div></div>');
            }
            if (this.state.display_leaf_icon === true) {
              $(".vlbase").html('<img alt="" src="/assets/images/leaf_small.png" /> ')
            }

            if (response.data.diet_history.food_serving_sizes !== undefined) {
              if (response.data.diet_history.food_serving_sizes !== null) {
                if (response.data.diet_history.food_serving_sizes.length > 0) {
                  this.setState({ servings_per_container: response.data.diet_history.food_serving_sizes[0].servings_per_container.toFixed(1) });
                }
                else {
                  this.setState({ servings_per_container: "1.0" });
                }
              } else {
                this.setState({ servings_per_container: "1.0" });
              }
            }


            if (response.data.diet_history.serving_size === undefined) {
              if (!!response.data.diet_history.serving_sizes && response.data.diet_history.serving_sizes.length > 0) {
                this.setState({ food_serving_size: response.data.diet_history.serving_sizes[0].coefficient });
              }
            } else {
              this.setState({ food_serving_size: response.data.diet_history.serving_size });
            }

            this.setState({ favorite: response.data.diet_history.favorite_foods, add_to_fav: this.state.favorite === true ? 'Remove from Favorites' : 'Add to Favorites', add_fav_off: this.state.favorite === true ? '/assets/images/fav_on.png' : '/assets/images/fav_off.png' });
            this.setCustomvalue();
          }, 200);
          if (response.data.diet_history.food_categories.length !== 0) {
            $('.un_assign').text('');
          }
          for (var i = 0; i < response.data.diet_history.food_categories.length; i++) {
            var name = response.data.diet_history.food_categories[i].name;
            // changes done against ticket 16382 --start
            var img_name = "/" + name + "_SMALL_USED.png";
            // changes done against ticket 16382 --end
            var img_type = name.toLowerCase() + "_unfill";
            var img_src = response.data.diet_history.s3_images_path + "/";
            var count = parseFloat(response.data.diet_history.food_categories[i].value).toFixed();
            for (var c = 0; c < count; c++) {
              $('.un_assign').append('<img alt="" name=' + response.data.diet_history.food_categories[i].name + '  name=' + response.data.diet_history.food_categories[i].name + ' class="filled_icon cursor_pointer ' + img_type + '" value=' + img_type + ' src=' + img_src + name + img_name + '>');
              kk.push('<img alt="" name=' + response.data.diet_history.food_categories[i].name + ' class="filled_icon cursor_pointer ' + img_type + '" value=' + img_type + ' src=' + img_src + name + img_name + '>');
            }

          }
          var final_arr_with_duplicates = [];
          $.each(kk, function (i, el) {
            if ($.inArray(el, final_array) === -1) final_array.push(el);
            final_arr_with_duplicates.push(el);
          });
          window.localStorage.setItem('total_pills', final_arr_with_duplicates);
          if (window.localStorage.getItem('item_name') !== "Recipes") {
            localStorage.removeItem('recents_food_details');
            localStorage.removeItem('dh_id');
          }
        })
        .catch(error => {
          Header.stopLoader();
          Header.apiErrors(error);
          Sentry.captureException(error);
        });

    } else {
      var validate_url;
      /*validate api call*/
      if (window.localStorage.getItem('change_serving_size') === "true" && window.localStorage.getItem('change_serving_size') !== null && window.localStorage.getItem('change_serving_size') !== undefined) {
        validate_url = window.API_URL + "v1/diet_histories/validate?consumable_id=" + window.localStorage.getItem('consumable_id') + "&consumable_type=" + window.localStorage.getItem('consumable_type') + "&assigned_date=" + window.localStorage.getItem('assigned_date') + "&serving_size=" + $(".weight_validate").val() + "&food_serving_size_id=" + $('#serving_option').find(":selected").val() + "&meal_occasion_id=" + window.localStorage.getItem('meal_occasion_id');
        localStorage.removeItem('change_serving_size');
        /* Enable Custom Favorite Food*/
        if (window.localStorage.getItem('consumable_type') === "Food") {
          $(".cff").show();
          localStorage.removeItem('custom_fav_food_consumable_id');
          localStorage.removeItem('custom_fav_food_consumable_type');
          localStorage.removeItem('custom_food_consumable_id');
          if ($(".custom_fav_img_icon").attr('src') === "/assets/images/fav_custom_filled.png") {
            $(".custom_fav_img_icon").attr('src', '/assets/images/fav_custom.png')
          }
        }
      } else {
        validate_url = window.API_URL + "v1/diet_histories/validate?consumable_id=" + consumable_id + "&consumable_type=" + consumable_type + "&assigned_date=" + assigned_date
      }
      Header.startLoader();

      var food_options = {
        url: validate_url,
        method: 'GET',
        credentials: 'same-origin',
        headers: Header.setAuthHeaders(),
      };

      axios(food_options)
        .then(response => {
          Header.stopLoader();
          window.nutrients_array = response.data.valid_food_details.diet_history.nutrients;
          if (response.data.valid_food_details.diet_history.food_serving_sizes != null && response.data.valid_food_details.diet_history.food_serving_sizes.length > 0) {
            if (window.localStorage.getItem("food_serving_size_id") != "undefined" && window.localStorage.getItem("food_serving_size_id") != undefined && window.localStorage.getItem("food_serving_size_id") != "null" && window.localStorage.getItem("food_serving_size_id") != null) {
              this.setState({
                serving_list: response.data.valid_food_details.diet_history.food_serving_sizes.map(function (options, i) {
                  return (
                    <option selected={localStorage.getItem("food_serving_size_id") == options.id ? true : false} className="pull-left" key={i} value={options.id}>{options.name}</option>
                  )
                })
              })
            }




            if (window.localStorage.getItem("food_serving_size_id") == "undefined" || window.localStorage.getItem("food_serving_size_id") == undefined || window.localStorage.getItem("food_serving_size_id") == "null" || window.localStorage.getItem("food_serving_size_id") == null) {
              this.setState({
                serving_list: response.data.valid_food_details.diet_history.food_serving_sizes.map(function (options, i) {
                  return (
                    <option className="pull-left" key={i} value={options.id}>{options.name}</option>
                  )
                })
              })
            }


          }


          $('.nutrients_div').empty();
          for (var jj = 0; jj < window.nutrients_array.length; jj++) {
            $('.nutrients_div').append('<div><div class="facts_sec" key={i}><div class="pull-left">' + response.data.valid_food_details.diet_history.nutrients[jj].display_name + '</div> <div class="text-right ull-right">' + response.data.valid_food_details.diet_history.nutrients[jj].value + ' ' + response.data.valid_food_details.diet_history.nutrients[jj].units + '</div></div></div>');
          }
          setTimeout(() => {
            this.setState({ food: response.data.valid_food_details.diet_history, calories: response.data.valid_food_details.diet_history.calories, nutrients: response.data.valid_food_details.diet_history.nutrients, serving_size_unit: response.data.valid_food_details.diet_history.serving_unit, serving_size: response.data.valid_food_details.diet_history.serving_size, serving_sizes: response.data.valid_food_details.diet_history.food_serving_sizes, favorite: response.data.valid_food_details.diet_history.favorite_foods, s3_images_path: response.data.valid_food_details.s3_images_path });
            this.setState({ serving_list1: <option className="pull-left">{this.state.serving_size_unit}</option> });
            this.setState({
              serving_size_texts: <span className="pull-right text-right"><span className="set_ser_size1 serving_size_text">{this.state.serving_size_unit} </span></span>

            })

            window.calorie = response.data.valid_food_details.diet_history.calories;
            window.food_brand = response.data.valid_food_details.diet_history.brand_name;
            window.food_description = response.data.valid_food_details.diet_history.food_description;
            $('.food_details_page .add_fav').show();
            /*if(this.state.serving_sizes !== ''){
              $.each(this.state.serving_sizes, function( index, value ) {
                if( value.default_serving_size == true ){
                  $("#serving_option option[value='"+value.id+"']").attr("selected", "selected");
                }
              });
            }*/
            if (response.data.valid_food_details.diet_history.food_serving_sizes) {
              /* this.setState({food_serving_size_id: response.data.valid_food_details.diet_history.food_serving_sizes[0].id});*/
              if (response.data.valid_food_details.diet_history.food_source === "Nutrisystem" || response.data.valid_food_details.diet_history.food_source === "leaf") {
                this.setState({ display_leaf_icon: true });
              }
            }
            window.cals = this.state.calories;
            if (this.state.display_leaf_icon === true) {
              $(".vlbase").html('<img alt="" src="/assets/images/leaf_small.png" /> ')
            }

            if (response.data.valid_food_details.diet_history.food_serving_sizes !== undefined) {
              if (response.data.valid_food_details.diet_history.food_serving_sizes !== null) {
                if (response.data.valid_food_details.diet_history.food_serving_sizes.length > 0) {
                  this.setState({ servings_per_container: response.data.valid_food_details.diet_history.food_serving_sizes[0].servings_per_container.toFixed(1) });
                } else {
                  this.setState({ servings_per_container: "1.0" });
                }
              }
              else {
                this.setState({ servings_per_container: "1.0" });
              }
            }


            if (response.data.valid_food_details.diet_history.serving_size === undefined) {
              if (!!response.data.valid_food_details.diet_history.serving_sizes && response.data.valid_food_details.diet_history.serving_sizes.length > 0) {
                this.setState({ food_serving_size: response.data.valid_food_details.diet_history.serving_sizes[0].coefficient });
              }
            } else {
              this.setState({ food_serving_size: response.data.valid_food_details.diet_history.serving_size });
            }


            this.setState({ favorite: response.data.valid_food_details.diet_history.favorite_foods, add_to_fav: this.state.favorite === true ? 'Remove from Favorites' : 'Add to Favorites', add_fav_off: this.state.favorite === true ? '/assets/images/fav_on.png' : '/assets/images/fav_off.png' });
            this.setCustomvalue();
          }, 200)
          if (response.data.valid_food_details.diet_history.food_categories.length !== 0) {
            $('.un_assign').text('');
          }
          for (var i = 0; i < response.data.valid_food_details.diet_history.food_categories.length; i++) {
            var name = response.data.valid_food_details.diet_history.food_categories[i].name;
            // changes done against ticket 16382 --start
            var img_name = "/" + name + "_SMALL_USED.png";

            // changes done against ticket 16382 --end
            var img_type = name.toLowerCase() + "_unfill";
            var img_src = response.data.valid_food_details.s3_images_path + "/";
            var count = parseFloat(response.data.valid_food_details.diet_history.food_categories[i].value).toFixed();
            for (var c = 0; c < count; c++) {
              if (count == 1 && response.data.valid_food_details.diet_history.food_categories[i].name == 'OTHER') {
                $('.un_assign').text('Unassigned');
              }
              else {
                $('.un_assign').append('<img alt="" name=' + response.data.valid_food_details.diet_history.food_categories[i].name + ' class="filled_icon cursor_pointer ' + img_type + '" value=' + img_type + ' src=' + img_src + name + img_name + '>');
                kk.push('<img alt="" name=' + response.data.valid_food_details.diet_history.food_categories[i].name + ' class="filled_icon cursor_pointer ' + img_type + '" value=' + img_type + ' src=' + img_src + name + img_name + '>');
              }

            }


          }
          var final_arr_with_duplicates = [];
          $.each(kk, function (i, el) {
            if ($.inArray(el, final_array) === -1) final_array.push(el);
            final_arr_with_duplicates.push(el);
          });

          var phase_options = {
            url: window.API_URL + "v1/phase_templates/phase_property/FOOD_CATEGORIES/assigned_date/" + assigned_date,
            method: 'GET',
            credentials: 'same-origin',
            headers: Header.setAuthHeaders(),
          };

          axios(phase_options)
            .then(response => {
              Header.stopLoader();
              window.localStorage.setItem('total_pills', final_arr_with_duplicates);
            })
            .catch(error => {
              Header.stopLoader();
              Header.apiErrors(error);
              Sentry.captureException(error);
            });


        })
        .catch(error => {
          Header.stopLoader();
          Header.apiErrors(error);
          Sentry.captureException(error);
        });

      var validate_options = {
        url: validate_url,
        method: 'GET',
        credentials: 'same-origin',
        headers: Header.setAuthHeaders(),
      };

      axios(validate_options)
        .then(response => {
          Header.stopLoader();
        })
        .catch(error => {
          Header.stopLoader();
          Header.apiErrors(error);
          Sentry.captureException(error);
        });

    }

  }



  async addFavorite() {
    if (this.state.add_to_fav === "Add to Favorites") {
      await this.setState({
        favorite: true,
        add_to_fav: window.keystrings['com.numi.remove_from_favorites'],
        add_fav_off: "/assets/images/fav_on.png",

      });
    }
    else {

      await this.setState({
        favorite: false,
        add_to_fav: window.keystrings['com.numi.add_to_favorites'],
        add_fav_off: "/assets/images/fav_off.png",
      });
    }


    /* favorite and unfavorite */
    var fav_data = { "favorite_food": { "consumable_id": this.state.food.consumable_id, "consumable_type": this.state.food.consumable_type, "favorite": this.state.favorite } }

    const options = {
      url: window.API_URL + 'v1/favorite_foods',
      method: 'POST',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
      data: fav_data
    };

    axios(options)
      .then(response => {

      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });



  }

  mobileArrowUp = () => {

    var oldValue = parseFloat($("input.serving_size").val());
    var newVal;

    if (oldValue === 9) {
      return
    }
    else {
      if (oldValue === 0.5) {
        newVal = 2;
        $(".weight_validate").val('1');
        window.localStorage.setItem('downClick', 'true');
        this.setState({ serving_size: 1 })
      }
      else {
        newVal = parseFloat(oldValue) + 0.5;
        $(".weight_validate").val(newVal);
        this.setState({ serving_size: newVal })
      }

    }



    this.handleFoodInputChange();

  }

  mobileArrowDown = () => {

    var oldValue = parseFloat($("input.serving_size").val());



    // Don't allow decrementing below zero
    if (oldValue === 0.5) {
      return
    } else {
      var newVal = parseFloat(oldValue) - 0.5;
      $(".weight_validate").val(newVal);
      this.setState({ serving_size: newVal })

    }


    this.handleFoodInputChange();

  }

  handleFoodInputChange = (event) => {
    if (event !== '' && event !== undefined) {
      this.setState({ serving_size: event.target.value });
    }
    var prev_val = Math.ceil(window.total_pill_icons);
    var gg = $('.un_assign').html();
    if (gg !== "Unassigned") {
      var val = parseFloat($(".weight_validate").val());
      var edit_pills_array = [];
      var final_pills = [];


      for (var dd = 0; dd < $('.un_assign img').length; dd++) {
        edit_pills_array.push($('.un_assign').find('img:eq(' + dd + ')').attr('name'));
      }

      final_pills = edit_pills_array.filter(function (item, index, inputArray) {
        return inputArray.indexOf(item) === index;
      });


      if ($(".weight_validate").val() > 0) {
        if (val > 1 && window.localStorage.getItem('downClick') === 'true') {
          var tst = $('.un_assign').html();
          $(tst).appendTo('.un_assign');
          localStorage.removeItem('downClick')
        }
        else {
          for (var qq = 0; qq < final_pills.length; qq++) {
            var tt = $('.un_assign').find('img:eq(0)');
            var pp = $('.un_assign').find('img:eq(0)').attr('name');
            var icon_length = Math.ceil($('.un_assign').find('img[name="' + pp + '"]').length / prev_val) * val;
            $('.un_assign').find('img[name="' + pp + '"]').remove();
            for (var cc = 0; cc < icon_length; cc++) {
              if (cc < 1) {
                tt.appendTo('.un_assign');
              }
              else {
                $('.un_assign').find('img:last').clone().appendTo('.un_assign');
              }
            }
            cc = 0;

          }
        }

      }
      var kk = $(".weight_validate").val().split('');
      if (parseInt($(".weight_validate").val()) > 9 || $(".weight_validate").val() === "" || $(".weight_validate").val() === "0" || kk[1] === "0" || (kk[0] === 9 && kk[1] === ".")) {

        if ($(".weight_validate").val() === "0") {
          /*this.setState({serving_size: ''});*/
          $('.un_assign').empty();
          $('#create-diet_history,#custom_without_log').addClass('pointer_none');
          $('#create-diet_history,#custom_without_log').find('button,input').addClass('btn disabled');
        }
        else {
          $(".weight_validate").val('');
          this.setState({ serving_size: '' });
          $('.un_assign').empty();
          $('#create-diet_history,#custom_without_log').addClass('pointer_none');
          $('#create-diet_history,#custom_without_log').find('button,input').addClass('btn disabled');
        }



      }
      else {
        $('#create-diet_history,#custom_without_log').removeClass('pointer_none');
        $('#create-diet_history,#custom_without_log').find('button,input').removeClass('btn disabled');
        if ($(".weight_validate").val().length > 4) {
          this.setState({ serving_size: $(".weight_validate").val().slice(0, 4) });
        }
      }

      var calories;
      if (localStorage.getItem('consumable_type') !== "CustomFood") {
        calories = ($(".weight_validate").val() * this.state.food.calories) / this.state.food_serving_size;
      }
      else {
        calories = $(".weight_validate").val() * this.state.food.calories
      }

      $(".d_block .cal_count").text(parseInt(calories));
      if (calories === 0) {
        $(".d_block .cal_count").text(parseInt(this.state.food.calories));
      }
    }
    window.total_pill_icons = $(".weight_validate").val();
  }

  withoutLogging = () => {
    localStorage.removeItem('create_custom');
    this.props.history.push('/log_food')
  }

  backPage = () => {
    localStorage.removeItem('create_custom');
    if (window.localStorage.getItem('journal_search') === "true") {
      this.props.history.push('/journal')
    }
    else {
      if (window.localStorage.getItem('log_food_search') === "true") {
        this.props.history.push('/log_food')
      }
      else {
        this.props.history.push('/recipe_item')
      }
    }
  }

  submitForm = () => {
    window.localStorage.setItem('autoscroll', 'true');
    window.localStorage.setItem('scroll_id', $('.food_details_page .select_replace i').attr('id'));


    var food_serving_size_id;
    if ($.isNumeric($('#serving_option option:selected').val())) {
      food_serving_size_id = $('#serving_option option:selected').val();
    }
    else {
      food_serving_size_id = null;
    }

    var serving_size = $(".weight_validate").val();
    var food_category_custom_foods = [];
    var fill_cats;
    if (window.localStorage.getItem('new_assigned_date') !== '' && window.localStorage.getItem('new_assigned_date') !== null && window.localStorage.getItem('new_assigned_date') !== undefined) {
      fill_cats = window.food_cat_array
      localStorage.removeItem('new_assigned_date');
    } else {
      fill_cats = this.refs.unassigned_child.state.food_icon_array
    }
    var filled_cat = fill_cats.map(function (item) { return item.name; })
    for (var i = 0; i < filled_cat.length; i++) {
      window.initial_food_val = filled_cat[i];
      var count = $('.un_assign img').length;
      for (var j = 0; j < count; j++) {
        if ($('.un_assign img:eq(' + j + ')').attr('src').indexOf(filled_cat[i]) > 0) {
          $('.un_assign img:eq(' + j + ')').addClass(filled_cat[i]);
          var kk = $('.' + filled_cat[i]).length;
          var veg_foods = food_category_custom_foods.filter(function (obj) { return obj.name === window.initial_food_val });
          if (kk > 1 && veg_foods !== '') {
            veg_foods[0].value = kk + ".0";
          }
          else {
            food_category_custom_foods.push({ "name": window.initial_food_val, "value": kk + '.0' });
          }

        }
      }
    }
    var diet_history_id = '';
    var quick_log_id = '';
    var consumable_id = '';
    var consumable_type = '';
    if (window.localStorage.getItem('custom_food_consumable_id') !== '' && window.localStorage.getItem('custom_food_consumable_id') !== null && window.localStorage.getItem('custom_food_consumable_id') !== undefined && $(".custom_fav_img_icon").attr('src') === "/assets/images/fav_custom_filled.png") {
      consumable_id = window.localStorage.getItem('custom_food_consumable_id');
      consumable_type = 'CustomFood';
    } else {
      consumable_id = window.localStorage.getItem('consumable_id');
      consumable_type = window.localStorage.getItem('consumable_type');
    }
    if (window.localStorage.getItem('quicklog_id') !== null && window.localStorage.getItem('quicklog_id') !== '') {
      quick_log_id = window.localStorage.getItem('quicklog_id');
      localStorage.removeItem('quicklog_id');
    }
    var data;
    if (consumable_type === "CustomFood") {
      if (window.localStorage.getItem('default_meal_occasion_id') != "null") {
        data = {
          "diet_histories": [{
            "consumable_id": consumable_id,
            "consumable_type": consumable_type,
            "assigned_date": moment(parse($('.food_details_date').find('.react-datepicker__input-container input').val())).format('YYYY-MM-DD'),
            "meal_occasion_id": $('.select_replace i').attr('id'),
            "default_meal_occasion_id": window.localStorage.getItem('default_meal_occasion_id'),
            "serving_size": serving_size,
            "food_categories": food_category_custom_foods
          }]
        }
      } else {
        data = {
          "diet_histories": [{
            "consumable_id": consumable_id,
            "consumable_type": consumable_type,
            "assigned_date": moment(parse($('.food_details_date').find('.react-datepicker__input-container input').val())).format('YYYY-MM-DD'),
            "meal_occasion_id": $('.select_replace i').attr('id'),
            "serving_size": serving_size,
            "food_categories": food_category_custom_foods
          }]
        }
      }
    }
    else if (diet_history_id !== '' && quick_log_id === '') {
      if (window.localStorage.getItem('default_meal_occasion_id') != "null") {
        data = {
          "diet_histories": [{
            "consumable_id": consumable_id,
            "consumable_type": consumable_type,
            "assigned_date": moment(parse($('.food_details_date').find('.react-datepicker__input-container input').val())).format('YYYY-MM-DD'),
            "meal_occasion_id": $('.select_replace i').attr('id'),
            "default_meal_occasion_id": window.localStorage.getItem('default_meal_occasion_id'),
            "serving_size": serving_size,
            "food_serving_size_id": food_serving_size_id,
            "food_categories": food_category_custom_foods
          }]
        }
      } else {
        data = {
          "diet_histories": [{
            "consumable_id": consumable_id,
            "consumable_type": consumable_type,
            "assigned_date": moment(parse($('.food_details_date').find('.react-datepicker__input-container input').val())).format('YYYY-MM-DD'),
            "meal_occasion_id": $('.select_replace i').attr('id'),
            "serving_size": serving_size,
            "food_serving_size_id": food_serving_size_id,
            "food_categories": food_category_custom_foods
          }]
        }
      }

    } else {
      if (window.localStorage.getItem('default_meal_occasion_id') != "null") {
        data = {
          "diet_histories": [{
            "consumable_id": consumable_id,
            "consumable_type": consumable_type,
            "assigned_date": moment(parse($('.food_details_date').find('.react-datepicker__input-container input').val())).format('YYYY-MM-DD'),
            "meal_occasion_id": $('.select_replace i').attr('id'),
            "default_meal_occasion_id": window.localStorage.getItem('default_meal_occasion_id'),
            "serving_size": serving_size,
            "food_serving_size_id": food_serving_size_id,
            "food_categories": food_category_custom_foods,
            "quick_log_id": quick_log_id
          }]
        }
      } else {
        data = {
          "diet_histories": [{
            "consumable_id": consumable_id,
            "consumable_type": consumable_type,
            "assigned_date": moment(parse($('.food_details_date').find('.react-datepicker__input-container input').val())).format('YYYY-MM-DD'),
            "meal_occasion_id": $('.select_replace i').attr('id'),
            "serving_size": serving_size,
            "food_serving_size_id": food_serving_size_id,
            "food_categories": food_category_custom_foods,
            "quick_log_id": quick_log_id
          }]
        }
      }

    }
    $('.serving_error').empty();
    window.localStorage.setItem('assigned_date', moment(parse($('.food_details_date').find('.react-datepicker__input-container input').val())).format('YYYY-MM-DD'));
    var api = window.API_URL + "v1/diet_histories";
    var method = "POST";
    if ($('.un_assign .filled_icon').length > 0) {
      Header.startLoader();

      const options = {
        url: api,
        method: method,
        credentials: 'same-origin',
        headers: Header.setAuthHeaders(),
        data: data
      };

      axios(options)
        .then(response => {
          Header.stopLoader();
          localStorage.removeItem('from_createfood');
          if (response.data && response.data.diet_histories) {
            localStorage.removeItem('create_custom');
            this.props.history.push('/journal');
          } else if (response.data && response.data.error) {
            $('.serving_error').text(response.data.error);
          }
        })
        .catch(error => {
          Header.stopLoader();
          Header.apiErrors(error);
          Sentry.captureException(error);
        });

    }
    else {
      $('#unassigned_popup').modal('show');
      $('.unassigned_popup').hide();
      $('.unassigned_food').show();
      /*key string*/
      $('.unassigned-food-ks').text(window.keystrings['com.numi.food.unassigned_food']);
      $('.food-unassigned-popup-msg-ks').text(window.keystrings['com.numi.food.unassigned_msg']);
      $('.food-assign-food-category-ks').text(window.keystrings['com.numi.assign_food_category']);
      $('.food-create-as-other-ks').text(window.keystrings['com.numi.food.create_as_other']);
      $('.unassigned-food-categories-done-ks').text(window.keystrings['com.numi.done']);
      /*End Key String*/
    }
  }
  setCustomvalue = () => {
    if (window.localStorage.getItem('consumable_type') === "CustomFood") {
      $('.custom_food_edit').show();
      $(".serving_size_dropdown").hide();
      $(".serving_size_text").show();
      if (window.localStorage.getItem('log_food_from') !== "Recent")
        this.setState({ serving_size: 1 });
    }
  }
  setEditFoodSession = () => {
    localStorage.removeItem('create_custom');
    window.localStorage.setItem('setEditfood', true);
  }

  componentDidMount() {

    $(document).on('click', '.custom_fav_img', function (e) {
      e.stopImmediatePropagation();
      if ($(".custom_fav_img_icon").attr('src') === "/assets/images/fav_custom_filled.png") {
        $(".custom_fav_img_icon").attr('src', '/assets/images/fav_custom.png');
        this.UnFavoriteCustomFood(false);
      } else {
        if (window.localStorage.getItem('custom_food_consumable_id') !== '' && window.localStorage.getItem('custom_food_consumable_id') !== null && window.localStorage.getItem('custom_food_consumable_id') !== undefined) {
          this.UnFavoriteCustomFood(true);
        }
        else {
          this.CreateCustomFavFood();
        }
        $(".custom_fav_img_icon").attr('src', '/assets/images/fav_custom_filled.png')
      }
    }.bind(this));

    localStorage.removeItem('new_assigned_date');
    localStorage.removeItem('custom_food_consumable_id');
    localStorage.removeItem('custom_fav_food_consumable_id');
    localStorage.removeItem('custom_fav_food_consumable_type');



    $('.food_details_page .add_fav').hide();



    $(document).on('FoodItemsLogged', function (event, food_name) {
      return window.GoogleTag.trigger('tap_log_food_items_logged', {
        FoodName: food_name
      });
    });


    if (window.localStorage.getItem('from_createfood') === "true") {
      $('#custom_without_log .without_log').show();
      $('.back_search').hide();
    }

    var fill_array = [];

    $(document).on("click", ".edit_txt_food_details", function (e) {
      if ($(".weight_validate").val() !== '1') {
        $('#count_as_validate').modal('show');
      } else {
        $('#unassigned_popup').modal('show');
      }
      $('.food_count_images').empty();
      $('#unassigned_popup .filled_icon').remove();
      $('.unassigned_popup').show();
      /*key strings*/
      $('.unassigned-food-categories-ks').text(window.keystrings['com.numi.edit_food_categories']);
      $('.unassigned-food-categories-msg-ks').text(window.keystrings['com.numi.edit_food_categories_description']);
      $('.unassigned-food-categories-done-ks').text(window.keystrings['com.numi.done']);
      $('.unassigned_food').hide();

      fill_array = [];

      for (var i = 0; i < $('.un_assign img').length; i++) {

        if ($('.un_assign img').length) {
          fill_array.push(document.getElementsByClassName("filled_icon")[i].getAttribute("value"));
          var dd = document.getElementsByClassName(fill_array[i])[0].cloneNode(true);
          var ff = $('li[value=' + fill_array[i] + ']');
          ff.prepend(dd);
          var kk = ff.attr('id');
          var jj = $('img.' + fill_array[i] + '').attr('src').replace('SMALL', 'LARGE');
          $('#unassigned_popup').find('img.' + fill_array[i] + '').attr('src', jj);
          var parend_li = ff.find('.filled_icon').length;
          $('.un_assign_txt').hide();
          if (kk !== undefined && $('.unassigned_popup').find('.' + kk + '').length > 0) {
            $('.unassigned_popup').find('.' + kk + '').find('.current_food_count span').text(parend_li);
          }
          else {
            if (kk !== undefined) {
              $('.food_count_img .food_count_images').append('<span class=' + kk + '><span class="current_food_count"> <span> ' + parend_li + '</span></span>' + kk + '<span class="comma">, </span> </span>');
            }
            else {
              $('#Oth img').click();
              $('.Oth').append('<span class="comma">, </span>');
            }


          }
          if ($('.unassigned_popup').find('.food_count_images span').length >= 1) {
            $('.selected_food_txt').remove();

            $('.food_count_img').append('<span class="selected_food_txt">selected</span>')

          }
          else {
            $('.selected_food_txt').remove();
          }

        }
      }
      if (($('.current_food_count:last-of-type').length > 0) && ($('.current_food_count:last-of-type').indexOf(',') > 0)) {
        $('.current_food_count:last-of-type').replace(',', '');
      }

      if ($('.comma').length === 1) {
        $('.comma').hide();
      }
      else {
        $('.comma:last').hide();
      }
    });

    setTimeout(function () {
      $("#food_help").modal("hide");

    }, 500);

    this.validateDietHistory();

    setTimeout(function () {
      window.total_pill_icons = $(".weight_validate").val();
    }, 1500);


    $(document).on(".input", ".numeric", function (e) {
      this.value = this.value.replace(/[^0-9]/g, '');


    });


    /* Key Strings*/
    $('.food-details-serving-size-ks').text(window.keystrings['com.numi.food_details_serving_size']);
    $('.food-details-number-of-servings-ks').text(window.keystrings['com.numi.food_details_number_of_servings']);
    $('.food-details-count-as-ks').text(window.keystrings['com.numi.food_details_counts_as']);
    $('.food-details-edit-ks').text(window.keystrings['com.numi.create_food_edit']);
    $('.food-details-meal-ks').text(window.keystrings['com.numi.food_details_meal']);
    $('.food-details-date-ks').text(window.keystrings['com.numi.food_details_date']);
    $('.food-details-section input.log-button').attr('value', window.keystrings['com.numi.log_it']);
    $('.food-nutrition-facts-ks').text(window.keystrings['com.numi.nutrition_facts']);
    $('.food-details-serving-per-container-ks').text(window.keystrings['com.numi.food.serving_per_container_new']);
    $('.food-details-amount-per-serving-ks').text(window.keystrings['com.numi.amount_per_serving']);
    $('.food-details-calories-ks').text(window.keystrings['com.numi.nutrition.calories']);
    $('.food-details-amount-ks').text(window.keystrings['com.numi.amount']);

    $('.food-details-back-to-ks').text(window.keystrings['com.numi.food.back_to'])
    $('.log_it_text').attr('value', window.keystrings['com.numi.log_it'])
    $('.continue_without_logging_text').text(window.keystrings['com.numi.continue_without_logging'])
    $('.food-details-unassigned-ks').text(window.keystrings['com.numi.unassigned']);

    /* End Key Strings*/

    if (window.localStorage.getItem("food_serving_size_id") != "undefined" && window.localStorage.getItem("food_serving_size_id") != undefined && window.localStorage.getItem("food_serving_size_id") != "null" && window.localStorage.getItem("food_serving_size_id") != null) {
      Header.startLoader();
      var assigned_date;
      var term;
      if (window.localStorage.getItem('new_assigned_date') !== '' & window.localStorage.getItem('new_assigned_date') !== null && window.localStorage.getItem('new_assigned_date') !== undefined) {
        assigned_date = window.localStorage.getItem('new_assigned_date');
      } else {
        assigned_date = window.localStorage.getItem('assigned_date');
      }
      var consumable_id = window.localStorage.getItem('consumable_id');
      var consumable_type = window.localStorage.getItem('consumable_type');
      if (window.localStorage.getItem('searched_term') !== null && window.localStorage.getItem('searched_term') !== undefined && window.localStorage.getItem('searched_term') !== '') {
        term = window.localStorage.getItem('searched_term');
      }
      else {
        term = ''
      }

      if (window.localStorage.getItem('searched_term') !== null && window.localStorage.getItem('searched_term') !== undefined && window.localStorage.getItem('searched_term') !== '') {
        window.localStorage.setItem('change_serving_size', 'true')


        if (window.localStorage.getItem("food_serving_size_id") != "undefined" && window.localStorage.getItem("food_serving_size_id") != undefined && window.localStorage.getItem("food_serving_size_id") != "null" && window.localStorage.getItem("food_serving_size_id") != null) {






          var validate_url = window.API_URL + "v1/diet_histories/validate?consumable_id=" + window.localStorage.getItem('consumable_id') + "&consumable_type=" + window.localStorage.getItem('consumable_type') + "&assigned_date=" + window.localStorage.getItem('assigned_date') + "&serving_size=" + "1" + "&food_serving_size_id=" + window.localStorage.getItem("food_serving_size_id") + "&meal_occasion_id=" + window.localStorage.getItem('meal_occasion_id');
          localStorage.removeItem('change_serving_size');
          /* Enable Custom Favorite Food*/
          if (window.localStorage.getItem('consumable_type') === "Food") {
            $(".cff").show();
            localStorage.removeItem('custom_fav_food_consumable_id');
            localStorage.removeItem('custom_fav_food_consumable_type');
            localStorage.removeItem('custom_food_consumable_id');
            if ($(".custom_fav_img_icon").attr('src') === "/assets/images/fav_custom_filled.png") {
              $(".custom_fav_img_icon").attr('src', '/assets/images/fav_custom.png')
            }
          }
        } else {
          validate_url = window.API_URL + "v1/diet_histories/validate?consumable_id=" + consumable_id + "&consumable_type=" + consumable_type + "&assigned_date=" + assigned_date
        }
        var kk = [];
        var final_array = [];
        Header.startLoader();

        var food_options = {
          url: validate_url,
          method: 'GET',
          credentials: 'same-origin',
          headers: Header.setAuthHeaders(),
        };

        axios(food_options)
          .then(response => {
            Header.stopLoader();
            window.nutrients_array = response.data.valid_food_details.diet_history.nutrients;
            $('.nutrients_div').empty();
            for (var jj = 0; jj < window.nutrients_array.length; jj++) {
              $('.nutrients_div').append('<div><div class="facts_sec" key={i}><div class="pull-left">' + response.data.valid_food_details.diet_history.nutrients[jj].display_name + '</div> <div class="text-right ull-right">' + response.data.valid_food_details.diet_history.nutrients[jj].value + ' ' + response.data.valid_food_details.diet_history.nutrients[jj].units + '</div></div></div>');
            }
            setTimeout(() => {
              this.setState({ food: response.data.valid_food_details.diet_history, calories: response.data.valid_food_details.diet_history.calories, nutrients: response.data.valid_food_details.diet_history.nutrients, serving_size_unit: response.data.valid_food_details.diet_history.serving_unit, serving_size: response.data.valid_food_details.diet_history.serving_size, serving_sizes: response.data.valid_food_details.diet_history.food_serving_sizes, favorite: response.data.valid_food_details.diet_history.favorite_foods, s3_images_path: response.data.valid_food_details.s3_images_path });


              window.calorie = response.data.valid_food_details.diet_history.calories;
              window.food_brand = response.data.valid_food_details.diet_history.brand_name;
              window.food_description = response.data.valid_food_details.diet_history.food_description;
              $('.food_details_page .add_fav').show();
              /*if(this.state.serving_sizes !== ''){
                $.each(this.state.serving_sizes, function( index, value ) {
                  if( value.default_serving_size == true ){
                    $("#serving_option option[value='"+value.id+"']").attr("selected", "selected");
                  }
                });
              }*/
              if (response.data.valid_food_details.diet_history.food_serving_sizes) {
                /* this.setState({food_serving_size_id: response.data.valid_food_details.diet_history.food_serving_sizes[0].id});*/
                if (response.data.valid_food_details.diet_history.food_source === "Nutrisystem" || response.data.valid_food_details.diet_history.food_source === "leaf") {
                  this.setState({ display_leaf_icon: true });
                }
              }
              window.cals = this.state.calories;
              if (this.state.display_leaf_icon === true) {
                $(".vlbase").html('<img alt="" src="/assets/images/leaf_small.png" /> ')
              }

              if (response.data.valid_food_details.diet_history.food_serving_sizes !== undefined) {
                if (response.data.valid_food_details.diet_history.food_serving_sizes !== null) {
                  if (response.data.valid_food_details.diet_history.food_serving_sizes.length > 0) {
                    this.setState({ servings_per_container: response.data.valid_food_details.diet_history.food_serving_sizes[0].servings_per_container.toFixed(1) });
                  } else {
                    this.setState({ servings_per_container: "1.0" });
                  }
                }
                else {
                  this.setState({ servings_per_container: "1.0" });
                }
              }


              if (response.data.valid_food_details.diet_history.serving_size === undefined) {
                if (!!response.data.valid_food_details.diet_history.serving_sizes && response.data.valid_food_details.diet_history.serving_sizes.length > 0) {
                  this.setState({ food_serving_size: response.data.valid_food_details.diet_history.serving_sizes[0].coefficient });
                }
              } else {
                this.setState({ food_serving_size: response.data.valid_food_details.diet_history.serving_size });
              }


              this.setState({ favorite: response.data.valid_food_details.diet_history.favorite_foods, add_to_fav: this.state.favorite === true ? 'Remove from Favorites' : 'Add to Favorites', add_fav_off: this.state.favorite === true ? '/assets/images/fav_on.png' : '/assets/images/fav_off.png' });
              this.setCustomvalue();
              if (response.data.valid_food_details.diet_history.food_serving_sizes.length > 0) {
                if (window.localStorage.getItem("food_serving_size_id") != "undefined" && window.localStorage.getItem("food_serving_size_id") != undefined && window.localStorage.getItem("food_serving_size_id") != "null" && window.localStorage.getItem("food_serving_size_id") != null) {
                  this.setState({
                    serving_list: response.data.valid_food_details.diet_history.food_serving_sizes.map(function (options, i) {
                      return (
                        <option selected={localStorage.getItem("food_serving_size_id") == options.id ? true : false} className="pull-left" key={i} value={options.id}>{options.name}</option>
                      )
                    })
                  })
                }




                if (window.localStorage.getItem("food_serving_size_id") == "undefined" || window.localStorage.getItem("food_serving_size_id") == undefined || window.localStorage.getItem("food_serving_size_id") == "null" || window.localStorage.getItem("food_serving_size_id") == null) {
                  this.setState({
                    serving_list: response.data.valid_food_details.diet_history.food_serving_sizes.map(function (options, i) {
                      return (
                        <option className="pull-left" key={i} value={options.id}>{options.name}</option>
                      )
                    })
                  })
                }


              }

              else {
                this.setState({ serving_list1: <option className="pull-left">{this.state.serving_size_unit}</option> });
                this.setState({
                  serving_size_texts: <span className="pull-right text-right"><span className="set_ser_size1 serving_size_text">{this.state.serving_size_unit} </span></span>

                })
              }
            }, 200)
            if (response.data.valid_food_details.diet_history.food_categories.length !== 0) {

            }
            for (var i = 0; i < response.data.valid_food_details.diet_history.food_categories.length; i++) {
              var name = response.data.valid_food_details.diet_history.food_categories[i].name;
              // changes done against ticket 16382 --start
              var img_name = "/" + name + "_SMALL_USED.png";

              // changes done against ticket 16382 --end
              var img_type = name.toLowerCase() + "_unfill";
              var img_src = response.data.valid_food_details.s3_images_path + "/";
              var count = parseFloat(response.data.valid_food_details.diet_history.food_categories[i].value).toFixed();


            }
            var final_arr_with_duplicates = [];
            $.each(kk, function (i, el) {
              if ($.inArray(el, final_array) === -1) final_array.push(el);
              final_arr_with_duplicates.push(el);
            });

            var phase_options = {
              url: window.API_URL + "v1/phase_templates/phase_property/FOOD_CATEGORIES/assigned_date/" + assigned_date,
              method: 'GET',
              credentials: 'same-origin',
              headers: Header.setAuthHeaders(),
            };

            axios(phase_options)
              .then(response => {
                Header.stopLoader();
                window.localStorage.setItem('total_pills', final_arr_with_duplicates);
              })
              .catch(error => {
                Header.stopLoader();
                Header.apiErrors(error);
                Sentry.captureException(error);
              });


          })
          .catch(error => {
            Header.stopLoader();
            Header.apiErrors(error);
            Sentry.captureException(error);
          });

        var validate_options = {
          url: validate_url,
          method: 'GET',
          credentials: 'same-origin',
          headers: Header.setAuthHeaders(),
        };

        axios(validate_options)
          .then(response => {
            Header.stopLoader();
          })
          .catch(error => {
            Header.stopLoader();
            Header.apiErrors(error);
            Sentry.captureException(error);
          });
      }

    }
  }



  render() {

    if (this.state.serving_sizes) {
      var serving_list_recent = this.state.serving_sizes.map(function (options, i) {
        return (
          <option className="pull-left" key={i} value={options.id}>{options.name}</option>
        )
      });
    }
    else {
      var serving_list1_recent = <option className="pull-left">{this.state.serving_size_unit}</option>;
      var serving_size_texts_recent = <span className="pull-right text-right"><span className="set_ser_size1 serving_size_text">{this.state.serving_size_unit} </span></span>;
    }

    return (
      <div>
        <div className="table_display">

          <div className="food_details_div food_details_page">

            <div className="section_left pull-left">
              <Navigation />
            </div>
            <div className="section_right pull-left">
              <figure><Adspeed /></figure>
              <div className="journal_rht">
                <p className="mb back_search">
                  <span className="back_to_log cursor_pointer" onClick={this.backPage}><img alt="" src="/assets/images/back_white_icon.png" className="vlb" /> <span className='food-details-back-to-ks'> </span> {window.localStorage.getItem('item_name')}</span>
                </p>
                <h1 className="journal_hdr pull-left width70"><span className="vlbase"> </span><span className="log_food_name">{this.state.food.food_name}</span><span className="font18 color5 d_block">{this.state.food.best_for_you === true ? <img alt="" src='/assets/images/best_for_big.png' style={{ marginRight: '10px' }} /> : ''}<span className="cal_count">{this.state.food.calories}</span> <span className='food-details-calories-ks'></span></span></h1>

                <div className="pull-right mt add_fav font15 color5 cursor_pointer" onClick={this.addFavorite.bind(this)}><img alt="" src={this.state.add_fav_off} /> {this.state.add_to_fav}</div>

                <div className="pull-right mt font15 color5 custom_food_edit"><Link to="/create_food" onClick={this.setEditFoodSession} ><img alt="" className="custom_food_edit_image" src={this.state.edit_icon} /></Link></div>

                <div className="clear"></div>
                <hr className="mt2" />

                <div className="clear"></div>
                <div className="panel-group meal_section_left food_details_ul pull-left food-details-section">
                  <p className="error_txt serving_error font14"></p>
                  <ul>
                    {this.state.serving_list.length === 0 && this.state.serving_list1.length === 0 && this.state.serving_size_texts === '' ?
                      <li><span className="food-details-serving-size-ks"></span>
                        <span className="text-right serving_size_dropdown">
                          <span className="select_back display_block">
                            <select onChange={this.servingChangeUnit} id="serving_option">
                              {serving_list_recent}
                              {serving_list1_recent}
                            </select>
                          </span>
                        </span>
                      </li>
                      :
                      <li><span className="food-details-serving-size-ks"></span>
                        <span className="text-right serving_size_dropdown">
                          <span className="select_back display_block">
                            <select onChange={this.servingChangeUnit} id="serving_option">
                              {this.state.serving_list}
                              {/* {this.state.serving_list1} */}
                            </select>
                          </span>
                        </span>
                      </li>
                    }
                    <li><span className="food-details-number-of-servings-ks"></span><span className="text-right mobile_relative"><input type="number" step="0.5" min="0.5" max="9" className="serving_size weight_validate" readOnly value={this.state.serving_size} maxLength="3" onChange={this.handleFoodInputChange} ref='serving_size' /><div className="mobile_number_arrows"><div className="mobile_arrows_font"><img alt="" onClick={this.mobileArrowUp} src="/assets/images/top_arrow.png" /></div><div className="mobile_arrows_font"><img alt="" src="/assets/images/bottom_arrow.png" onClick={this.mobileArrowDown} /></div></div></span></li>
                    <li><span ><span className="food-details-count-as-ks"></span><sup><img alt="" src="/assets/images/education.png" onClick={() => this.showFoodDetailsHelp('FOOD_DETAILS')} className="cursor_pointer food_details_help" /></sup></span><span className="text-right font14"><span className="text-right un_assign font14 "><span className='food-details-unassigned-ks'></span> &nbsp;&nbsp;</span><span className="text-right edit_txt_food_details font14 cursor_pointer vlt food-details-edit-ks" ></span>
                    </span></li>
                    <li><span className="food-details-meal-ks"></span><span className="text-right"><MealDropdown ref="get_meal_data" /></span></li>
                    <li><span className='food-details-date-ks'></span><span className="text-right food_details_date"> <DatepickerExample meal_change={this.mealChange} callValidate={this.validateDietHistory} /></span>
                    </li>
                    <li className="cff">
                      <span>Favorite this Customized Food</span>
                      <span className="text-right">
                        <img alt="" src="/assets/images/fav_custom.png" className="custom_fav_img custom_fav_img_icon cursor_pointer" />
                      </span>
                    </li>

                  </ul>
                  <p><span onClick={this.submitForm} id="create-diet_history" className="display_block mt5">
                    <input type="submit" value="Log It" className="log-button log_it_text" />
                  </span></p>

                  <p><span onClick={this.withoutLogging} id="custom_without_log">
                    <button className="font18 without_log continue_without_logging_text"></button>
                  </span></p>

                </div>
                <div className="meal_section_right pull-right">
                  <div className="nutrition-facts-wrapper" style={{ display: this.state.food.food_type !== 'recipe' ? 'block' : 'none' }}>
                    <div className="nutri_facts_hdr facts_sec food-nutrition-facts-ks"></div>
                    <div className="divider facts_sec">
                      <span className='food-details-serving-size-ks'></span><span> {this.state.food.serving_unit}</span>
                    </div>
                    <div className="ser_divider facts_sec">
                      <span className='food-details-serving-per-container-ks'></span>
                      <span> {this.state.servings_per_container}</span>
                    </div>
                    <div className="facts_sec">
                      <div className="amount food-details-amount-per-serving-ks"></div>
                    </div>
                    <div className="sm-divider facts_sec">
                      <span className="food-details-calories-ks"></span> {parseInt(this.state.food.calories)}
                    </div>

                    <div className="text-right facts_sec food-details-amount-ks">

                    </div>
                    <div className="nutrients_div" style={{ display: this.state.food.food_type !== 'recipe' ? 'block' : 'none' }}>

                    </div>


                  </div>
                </div>
              </div>
              <Footer />
              <FoodHelp ref="callHelp" />
              <CountAsEditValidate callParentHandleInput={this.handleFoodInputChange} />
              <UnassinedPopup ref="unassigned_child" get_s3_path={this.state.s3_images_path} />
            </div>

          </div>
        </div>
      </div>
    )
  }
}


class DatepickerExample extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      date: moment(),
      foodstartDate: window.localStorage.getItem('assigned_date')

    };
  }

  foodhandleChange = (date) => {
    this.setState({
      foodstartDate: date
    });
    $('.today_date').text(moment(date).format('MMMM DD, YYYY'));
    window.localStorage.setItem('assigned_date', moment(date).format('YYYY-MM-DD'));
    window.localStorage.setItem('new_assigned_date', moment(date).format('YYYY-MM-DD'));
    UnassinedPopup.unassigned_popup(window.localStorage.getItem('new_assigned_date'));
    window.changed_date = moment($('.food_details_date').find('.react-datepicker__input-container input').val()).format('MMMM DD, YYYY')
    Header.changeDate(date);
  }

  componentDidMount() {
    Header.changeDate(window.localStorage.getItem('assigned_date'));

  }
  render() {
    return <div className="relative"><DatePicker onChange={this.foodhandleChange} selected={parse(this.state.foodstartDate)} minDate={parse(window.localStorage.getItem('profile_start_date'))} maxDate={addDays(new Date(), 90)} /> </div>
  }
}


export default Food_details;