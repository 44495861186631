import React from 'react';
import Header from "../header.js";
import { Link } from 'react-router-dom'
import Footer from "../footer.js";
import axios from 'axios';
import $ from 'jquery';
import * as Sentry from "@sentry/react";

class SelectProgram extends React.Component {
  constructor(props) {
    super(props)
    this.getPrograms = this.getPrograms.bind(this);
  }

  getPrograms() {
    const options = {
      url: window.API_URL + "v1/programs",
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        if (response && response.data) {
          this.setState({ programs: response.data });
          for (var i = 0; i < response.data.programs.length; i++) {
            $('<li class="display_block cursor_pointer" id=' + response.data.programs[i].id + '>' + response.data.programs[i].name + '</li>').appendTo('.program_options');
          }
        }
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });

  };

  componentDidMount() {
    this.getPrograms();
    $(document).on('click', '.program_options .display_block', function () {
      window.localStorage.setItem('program_id', $(this).attr('id'));
      this.props.history.push('/ns_plan');
    });
    /*keystrings*/
    $('.select_program_header_text').text(window.keystrings['com.numi.select_program_header'])
    /*keystrings*/
  }

  render() {
    return (
      <div className="main_container">

        <div className="forgot_password_hero">
          <div><Link to="/journal"><img alt="" src="/assets/images/numi_small_logo.png" className="logo img-responsive" /></Link></div>
          <div className="select_program">
            <h1 className="mb select_program_header_text"> </h1>

            <ul className="program_options">
            </ul>

          </div>
        </div>
        <Footer />
      </div>

    );
  }
}

export default SelectProgram;
