import React from 'react';
import gtmParts from './gtm_parts';
import Header from "../header.js";
import PropTypes from 'prop-types';
import axios from 'axios';
import $ from 'jquery';
class GoogleTagManager extends React.Component {
  componentDidMount() {
    /*const dataLayerName = this.props.dataLayerName || 'dataLayer';
    const scriptId = this.props.scriptId || 'react-google-tag-manager-gtm';*/
    this.googleTags = localStorage.getObj('google_tags');
    this.googleTag = null;
    if (!this.googleTags) {
      this.getGoogleTags();
    }
    setTimeout(function () {
      GoogleTagManager.setPageData();
      GoogleTagManager.loadGtmScript();
    }, 3000);
  }
  static loadGtmScript() {
    $('head').append($('.header-no-script').html());
    $('head').append($('.header-script').html());
  }
  /*static evalGtmScript(){
    const gtmScriptNode = $('.google-tags-gtm');
    eval(gtmScriptNode.text());
  }*/

  getGoogleTags() {
    $.ajax({
      url: `${process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : ''}v1/google_tags`,
      dataType: 'json',
      credentials: 'same-origin',
      type: 'GET',
      success: function (data) {
        this.googleTags = data;
        localStorage.setObj('google_tags', data);
      }.bind(this)
    });
  }

  static setPageData() {
    if (!window.localStorage.getItem('user_access')) return;

    const options = {
      url: `${process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : ''}v1/google_tags/user_info`,
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        var evt = document.createEvent("CustomEvent");
        evt.initCustomEvent('HistoryChanged', true, false, false);
        window['profile'] = response.data['user_info'];
        document.dispatchEvent(evt);
      })
      .catch(error => {
        var evt = document.createEvent("CustomEvent");
        evt.initCustomEvent('HistoryChanged', true, false, false);
        document.dispatchEvent(evt);
      });

    /*$.ajax({
      url: `${ process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '' }v1/google_tags/user_info`,
      dataType: 'json',
      headers: Header.authorization_headers(),
      credentials: 'same-origin',
      type: 'GET',
      success: function(data){
        var evt = document.createEvent("CustomEvent");
        evt.initCustomEvent('HistoryChanged', true, false,false);
         window['profile'] = data['user_info'];
        document.dispatchEvent(evt);

      }.bind(this),
      error: function(data){

        var evt = document.createEvent("CustomEvent");
        evt.initCustomEvent('HistoryChanged', true, false,false);
        document.dispatchEvent(evt);
      }
    });*/
  }

  render() {
    const gtm = gtmParts({
      id: this.props.gtmId,
      dataLayerName: this.props.dataLayerName || 'dataLayer',
      additionalEvents: this.props.additionalEvents || {},
      previewVariables: this.props.previewVariables || false
    });
    return (
      <div>
        <div className='header-no-script' >{gtm.noScriptAsReact()}</div>
        <div className='header-script google-tags-gtm' id={this.props.scriptId || 'react-google-tag-manager-gtm'}>
          {gtm.scriptAsReact()}
        </div>
      </div>
    );
  }
}

GoogleTagManager.propTypes = {
  gtmId: PropTypes.string.isRequired,
  dataLayerName: PropTypes.string,
  additionalEvents: PropTypes.object,
  previewVariables: PropTypes.string,
  scriptId: PropTypes.string
};

export default GoogleTagManager;
