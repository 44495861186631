import React from 'react';
import { Link } from 'react-router-dom'
import Footer from "../footer.js";
import Header from "../header.js";
import Navigation from "../nav.js";
import Adspeed from "../adspeed.js";
import Avatar from "../profile/avatar.js";
import axios from 'axios';
import $ from 'jquery';
import * as Sentry from "@sentry/react";

class Measurements extends React.Component {
  constructor(props) {
    super(props);
    this.state = { measurement_data: '', chest: '', waist: '', hips: '', arm: '', thigh: '', neck: '', starting_chest_size: '', starting_waist_size: '', starting_hips_size: '', starting_arm_size: '', starting_thigh_size: '', starting_neck_size: '', user_name: window.localStorage.getItem('FirstName'), plan_start_date: window.localStorage.getItem('plan_start_date'), measurement_size: '' };
  }

  getMeasurents() {
    //fetch measurements of user
    /*keystrings*/
    Header.startLoader();
    $('.back_to_profile').text(window.keystrings['com.numi.profile.back_to_profile']);
    $('.my_measurements_title').text(window.keystrings['com.numi.measurements_title']);
    $('.measurement_current_key').text(window.keystrings['com.numi.measurements_current_title']);
    $('.measurement_starting_key').text(window.keystrings['com.numi.measurements_starting_title']);
    //$('.select_label').text(window.keystrings['com.numi.select']);
    $('.chest_label').text(window.keystrings['com.numi.chest']);
    $('.waist_label').text(window.keystrings['com.numi.waist']);
    $('.hips_label').text(window.keystrings['com.numi.hips']);
    $('.arms_label').text(window.keystrings['com.numi.arms']);
    $('.thigh_label').text(window.keystrings['com.numi.thighs']);
    $('.neck_label').text(window.keystrings['com.numi.neck']);
    $('.measurement_save').text(window.keystrings['com.numi.save_all_caps']);
    /*keystrings*/

    axios.get(window.API_URL + "v1/measurement", {
      credentials: 'same-origin',
      headers: Header.setAuthHeaders()
    })
      .then(response => {
        Header.stopLoader();
        this.setState({ measurement_data: response.data });
        this.setState({ chest: this.state.measurement_data.measurement.chest, waist: this.state.measurement_data.measurement.waist, hips: this.state.measurement_data.measurement.hips, arm: this.state.measurement_data.measurement.arm, thigh: this.state.measurement_data.measurement.thigh, neck: this.state.measurement_data.measurement.neck, starting_chest_size: this.state.measurement_data.measurement.starting_chest_size, starting_waist_size: this.state.measurement_data.measurement.starting_waist_size, starting_hips_size: this.state.measurement_data.measurement.starting_hips_size, starting_arm_size: this.state.measurement_data.measurement.starting_arm_size, starting_thigh_size: this.state.measurement_data.measurement.starting_thigh_size, starting_neck_size: this.state.measurement_data.measurement.starting_neck_size });
        // set current sizes 
        setTimeout(function () {
          if (this.state.chest.toString().split('.')[0] !== "0" && this.state.chest.toString().split('.')[1] !== undefined) {
            $('#chest_inch').find('option[value=' + this.state.chest.toString().split('.')[1] + ']').prop('selected', true);
          } else if (this.state.chest.toString().split('.')[0] !== "0" && this.state.chest.toString().split('.')[1] === undefined) {
            $('#chest_inch').find('option[value="0"]').prop('selected', true);
          }
          $('#chest').find('option[value=' + this.state.chest.toString().split('.')[0] + ']').prop('selected', true);
          $('#waist').find('option[value=' + this.state.waist.toString().split('.')[0] + ']').prop('selected', true);
          if (this.state.waist.toString().split('.')[0] !== "0" && this.state.waist.toString().split('.')[1] !== undefined) {
            $('#waist_inch').find('option[value=' + this.state.waist.toString().split('.')[1] + ']').prop('selected', true);
          } else if (this.state.waist.toString().split('.')[0] !== "0" && this.state.waist.toString().split('.')[1] === undefined) {
            $('#waist_inch').find('option[value="0"]').prop('selected', true);
          }
          $('#hips').find('option[value=' + this.state.hips.toString().split('.')[0] + ']').prop('selected', true);
          if (this.state.hips.toString().split('.')[0] !== "0" && this.state.hips.toString().split('.')[1] !== undefined) {
            $('#hips_inch').find('option[value=' + this.state.hips.toString().split('.')[1] + ']').prop('selected', true);
          } else if (this.state.hips.toString().split('.')[0] !== "0" && this.state.hips.toString().split('.')[1] === undefined) {
            $('#hips_inch').find('option[value="0"]').prop('selected', true);
          }
          $('#arm').find('option[value=' + this.state.arm.toString().split('.')[0] + ']').prop('selected', true);
          if (this.state.arm.toString().split('.')[0] !== "0" && this.state.arm.toString().split('.')[1] !== undefined) {
            $('#arm_inch').find('option[value=' + this.state.arm.toString().split('.')[1] + ']').prop('selected', true);
          } else if (this.state.arm.toString().split('.')[0] !== "0" && this.state.arm.toString().split('.')[1] === undefined) {
            $('#arm_inch').find('option[value="0"]').prop('selected', true);
          }
          $('#thigh').find('option[value=' + this.state.thigh.toString().split('.')[0] + ']').prop('selected', true);
          if (this.state.thigh.toString().split('.')[0] !== "0" && this.state.thigh.toString().split('.')[1] !== undefined) {
            $('#thigh_inch').find('option[value=' + this.state.thigh.toString().split('.')[1] + ']').prop('selected', true);
          } else if (this.state.thigh.toString().split('.')[0] !== "0" && this.state.thigh.toString().split('.')[1] === undefined) {
            $('#thigh_inch').find('option[value="0"]').prop('selected', true);
          }
          $('#neck').find('option[value=' + this.state.neck.toString().split('.')[0] + ']').prop('selected', true);
          if (this.state.neck.toString().split('.')[0] !== "0" && this.state.neck.toString().split('.')[1] !== undefined) {
            $('#neck_inch').find('option[value=' + this.state.neck.toString().split('.')[1] + ']').prop('selected', true);
          } else if (this.state.neck.toString().split('.')[0] !== "0" && this.state.neck.toString().split('.')[1] === undefined) {
            $('#neck_inch').find('option[value="0"]').prop('selected', true);
          }
          // set starting sizes 
          $('#starting_chest_size').find('option[value=' + this.state.starting_chest_size.toString().split('.')[0] + ']').prop('selected', true);
          if (this.state.starting_chest_size.toString().split('.')[0] !== "0" && this.state.starting_chest_size.toString().split('.')[1] !== undefined) {
            $('#starting_chest_size_inch').find('option[value=' + this.state.starting_chest_size.toString().split('.')[1] + ']').prop('selected', true);
          } else if (this.state.starting_chest_size.toString().split('.')[0] !== "0" && this.state.starting_chest_size.toString().split('.')[1] === undefined) {
            $('#starting_chest_size_inch').find('option[value="0"]').prop('selected', true);
          }
          $('#starting_waist_size').find('option[value=' + this.state.starting_waist_size.toString().split('.')[0] + ']').prop('selected', true);

          if (this.state.starting_waist_size.toString().split('.')[0] !== "0" && this.state.starting_waist_size.toString().split('.')[1] !== undefined) {
            $('#starting_waist_size_inch').find('option[value=' + this.state.starting_waist_size.toString().split('.')[1] + ']').prop('selected', true);
          } else if (this.state.starting_waist_size.toString().split('.')[0] !== "0" && this.state.starting_waist_size.toString().split('.')[1] === undefined) {
            $('#starting_waist_size_inch').find('option[value="0"]').prop('selected', true);
          }
          $('#starting_hips_size').find('option[value=' + this.state.starting_hips_size.toString().split('.')[0] + ']').prop('selected', true);
          if (this.state.starting_hips_size.toString().split('.')[0] !== "0" && this.state.starting_hips_size.toString().split('.')[1] !== undefined) {
            $('#starting_hips_size_inch').find('option[value=' + this.state.starting_hips_size.toString().split('.')[1] + ']').prop('selected', true);
          } else if (this.state.starting_hips_size.toString().split('.')[0] !== "0" && this.state.starting_hips_size.toString().split('.')[1] === undefined) {
            $('#starting_hips_size_inch').find('option[value="0"]').prop('selected', true);
          }
          $('#starting_arm_size').find('option[value=' + this.state.starting_arm_size.toString().split('.')[0] + ']').prop('selected', true);
          if (this.state.starting_arm_size.toString().split('.')[0] !== "0" && this.state.starting_arm_size.toString().split('.')[1] !== undefined) {
            $('#starting_arm_size_inch').find('option[value=' + this.state.starting_arm_size.toString().split('.')[1] + ']').prop('selected', true);
          } else if (this.state.starting_arm_size.toString().split('.')[0] !== "0" && this.state.starting_arm_size.toString().split('.')[1] === undefined) {
            $('#starting_arm_size_inch').find('option[value="0"').prop('selected', true);
          }
          $('#starting_thigh_size').find('option[value=' + this.state.starting_thigh_size.toString().split('.')[0] + ']').prop('selected', true);
          if (this.state.starting_thigh_size.toString().split('.')[0] !== "0" && this.state.starting_thigh_size.toString().split('.')[1] !== undefined) {
            $('#starting_thigh_size_inch').find('option[value=' + this.state.starting_thigh_size.toString().split('.')[1] + ']').prop('selected', true);
          } else if (this.state.starting_thigh_size.toString().split('.')[0] !== "0" && this.state.starting_thigh_size.toString().split('.')[1] === undefined) {
            $('#starting_thigh_size_inch').find('option[value="0"]').prop('selected', true);
          }
          $('#starting_neck_size').find('option[value=' + this.state.starting_neck_size.toString().split('.')[0] + ']').prop('selected', true);
          if (this.state.starting_neck_size.toString().split('.')[0] !== "0" && this.state.starting_neck_size.toString().split('.')[1] !== undefined) {
            $('#starting_neck_size_inch').find('option[value=' + this.state.starting_neck_size.toString().split('.')[1] + ']').prop('selected', true);
          } else if (this.state.starting_neck_size.toString().split('.')[0] !== "0" && this.state.starting_neck_size.toString().split('.')[1] === undefined) {
            $('#starting_neck_size_inch').find('option[value="0"]').prop('selected', true);
          }
        }.bind(this), 300);
      })
      .catch(error => {
        Header.stopLoader();
        Sentry.captureException(error);
      });

  }


  submitMeasurement(e) {
    var measurement_params = [];
    var c = $("#chest option:selected").val();
    var ci = $("#chest_inch option:selected").val()
    var chest = '';

    if (c !== 'Select' && ci !== 'Select') {
      chest = c + '.' + ci;
    } else if (c !== 'Select' && ci === "Select") {
      chest = c + '.' + 0;
    }

    if (chest !== '') {
      measurement_params.push({ "measurement": { "key": "chest", "value": chest } })
    }

    var w = $("#waist option:selected").val();
    var wi = $("#waist_inch option:selected").val()
    var waist = '';
    if (w !== 'Select' && wi !== 'Select') {
      waist = w + '.' + wi;
    } else if (w !== 'Select' && wi === "Select") {
      waist = w + '.' + 0;
    }
    if (waist !== '') {
      measurement_params.push({ "measurement": { "key": "waist", "value": waist } })
    }

    var h = $("#hips option:selected").val();
    var hi = $("#hips_inch option:selected").val()
    var hips = '';
    if (h !== 'Select' && hi !== "Select") {
      hips = h + '.' + hi;
    } else if (h !== 'Select' && hi === "Select") {
      hips = h + '.' + 0;
    }

    if (hips !== '') {
      measurement_params.push({ "measurement": { "key": "hips", "value": hips } })
    }

    var ar = $("#arm option:selected").val();
    var ari = $("#arm_inch option:selected").val()
    var arm = '';
    if (ar !== 'Select' && ari !== 'Select') {
      arm = ar + '.' + ari;
    } else if (ar !== 'Select' && ari === "Select") {
      arm = ar + '.' + 0;
    }

    if (arm !== '') {
      measurement_params.push({ "measurement": { "key": "arm", "value": arm } })
    }

    var th = $("#thigh option:selected").val();
    var thi = $("#thigh_inch option:selected").val()
    var thigh = '';
    if (th !== 'Select' && thi !== 'Select') {
      thigh = th + '.' + thi;
    } else if (th !== 'Select' && thi === "Select") {
      thigh = th + '.' + 0;
    }

    if (thigh !== '') {
      measurement_params.push({ "measurement": { "key": "thigh", "value": thigh } })
    }

    var n = $("#neck option:selected").val();
    var ni = $("#neck_inch option:selected").val()
    var neck = '';
    if (n !== 'Select' && ni !== 'Select') {
      neck = n + '.' + ni;
    } else if (n !== 'Select' && ni === "Select") {
      neck = n + '.' + 0;
    }

    if (neck !== '') {
      measurement_params.push({ "measurement": { "key": "neck", "value": neck } })
    }

    var s_c = $("#starting_chest_size option:selected").val();
    var s_ci = $("#starting_chest_size_inch option:selected").val()
    var starting_chest_size = '';
    if (s_c !== 'Select' && s_ci !== 'Select') {
      starting_chest_size = s_c + '.' + s_ci;
    } else if (s_c !== 'Select' && ci === "Select") {
      starting_chest_size = s_c + '.' + 0;
    }

    if (starting_chest_size !== '') {
      measurement_params.push({ "measurement": { "key": "starting_chest_size", "value": starting_chest_size } })
    }

    var s_w = $("#starting_waist_size option:selected").val();
    var s_wi = $("#starting_waist_size_inch option:selected").val()
    var starting_waist_size = '';
    if (s_w !== 'Select' && s_wi !== 'Select') {
      starting_waist_size = s_w + '.' + s_wi;
    } else if (s_w !== 'Select' && s_wi === "Select") {
      starting_waist_size = s_w + '.' + 0;
    }

    if (starting_waist_size !== '') {
      measurement_params.push({ "measurement": { "key": "starting_waist_size", "value": starting_waist_size } })
    }

    var s_h = $("#starting_hips_size option:selected").val();
    var s_hi = $("#starting_hips_size_inch option:selected").val()
    var starting_hips_size = '';
    if (s_h !== 'Select' && s_hi !== 'Select') {
      starting_hips_size = s_h + '.' + s_hi;
    } else if (s_h !== 'Select' && s_hi === "Select") {
      starting_hips_size = s_h + '.' + 0;
    }

    if (starting_hips_size !== '') {
      measurement_params.push({ "measurement": { "key": "starting_hips_size", "value": starting_hips_size } })
    }

    var s_ar = $("#starting_arm_size option:selected").val();
    var s_ari = $("#starting_arm_size_inch option:selected").val()
    var starting_arm_size = '';
    if (s_ar !== 'Select' && s_ari !== 'Select') {
      starting_arm_size = s_ar + '.' + s_ari;
    } else if (s_ar !== 'Select' && s_ari === "Select") {
      starting_arm_size = s_ar + '.' + 0;
    }

    if (starting_arm_size !== '') {
      measurement_params.push({ "measurement": { "key": "starting_arm_size", "value": starting_arm_size } })
    }

    var s_th = $("#starting_thigh_size option:selected").val();
    var s_thi = $("#starting_thigh_size_inch option:selected").val()
    var starting_thigh_size = '';
    if (s_th !== 'Select' && s_thi !== 'Select') {
      starting_thigh_size = s_th + '.' + s_thi;
    } else if (s_th !== 'Select' && s_thi === "Select") {
      starting_thigh_size = s_th + '.' + 0;
    }

    if (starting_thigh_size !== '') {
      measurement_params.push({ "measurement": { "key": "starting_thigh_size", "value": starting_thigh_size } })
    }

    var s_n = $("#starting_neck_size option:selected").val();
    var s_ni = $("#starting_neck_size_inch option:selected").val()
    var starting_neck_size = '';
    if (s_n !== 'Select' && s_ni !== 'Select') {
      starting_neck_size = s_n + '.' + s_ni;
    } else if (s_n !== 'Select' && s_ni === "Select") {
      starting_neck_size = s_n + '.' + 0;
    }

    if (starting_neck_size !== '') {
      measurement_params.push({ "measurement": { "key": "starting_neck_size", "value": starting_neck_size } })
    }

    var measurement_hash = {};
    for (var i = 0; i < measurement_params.length; i++) {
      measurement_hash[measurement_params[i].measurement.key] = measurement_params[i].measurement.value;
    }

    if (measurement_params.length > 0) {
      measurement_params = { "measurement": measurement_hash };
      // call loader
      Header.startLoader();
      // update measurement api

      const options = {
        url: window.API_URL + "v1/measurement",
        method: 'PUT',
        credentials: 'same-origin',
        headers: Header.setAuthHeaders(),
        data: measurement_params
      };

      axios(options)
        .then(response => {
          $('.measurement_error').hide();
          Header.stopLoader();
          this.props.history.push('/my_profile');
        })
        .catch(error => {
          Header.stopLoader();
          $('.measurement_error').text('');
          if (error && error.response && error.response.data && error.response.data.error) {
            $('.measurement_error').append(error.response.data.error);
          }
          else if (error && error.response && error.response.status > 400) {
            $('.measurement_error').append(error.response.statusText)
          }
          $('.measurement_error').show();
          Sentry.captureException(error);
        });
    }
  }

  componentDidMount() {

    axios.get(window.API_URL + "v1/global_configs/key/MEASUREMENT_SIZES", {
      credentials: 'same-origin',
      headers: Header.setAuthHeaders()
    })
      .then(response => {
        Header.stopLoader();
        this.setState({ measurement_size: response.data.global_config.value })
        window.measure_sizes = this.state.measurement_size
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });

    //OnLoad call get Measurements API
    this.getMeasurents();
  }

  render() {
    var height_list = [];
    for (var i = 1; i <= 150; i++) {
      height_list.push(i);
    }
    var height_list_options = height_list.map(function (option, i) {
      return (
        <option value={i + 1} key={i}>{option} </option>
      )
    });

    window.measure_list = [];
    $.each(this.state.measurement_size, function (k, v) {
      window.measure_list.push({ value: v, inch: k })
    });
    if (window.measure_list) {
      var measure_inch = window.measure_list.map(function (option, i) {
        return (
          <option value={option.value} key={i}>{option.inch}</option>
        )
      });
    }


    return (
      <div>

        <div className="table_display">
          <div className="journal_div settings">
            <div className="section_left pull-left">
              <Navigation />
            </div>
            <div className="section_right pull-left">
              <figure><Adspeed /></figure>
              <div className="journal_rht my_profile change_password">
                <p className="mb">
                  <Link to="/my_profile" className="back_to"><img alt="" src="/assets/images/back_white_icon.png" className="vlb" /> <span className="back_to_profile"></span></Link>
                </p>
                <h1 className="journal_hdr pull-left my_measurements_title"> </h1>
                <div className="clear"></div>
                <hr />
                <p className="measurement_error error_txt"></p>
                <div className="row clearfix">

                  <div className="col-md-8 col-12 measurements">
                    <div className="border1">
                      <ul className="measure_sec favorite_section nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item">
                          <a href="#current" id="current-tab" data-toggle="tab" role="tab" aria-selected="true" className="measurement_current_key active"> </a>
                        </li>
                        <li className="nav-item"><a href="#starting" id="starting-tab" data-toggle="tab" role="tab" aria-expanded="false" aria-selected="false" className="measurement_starting_key"> </a></li>
                      </ul>

                      <div className="tab-content text-center measure" id="myTabContent">
                        <div className="tab-pane show active" id="current" role="tabpanel" aria-labelledby="current-tab">
                          <div className="profile_div_sec">
                            <div className="pull-left my_profile_lft chest_label"></div>
                            <div className="pull-right my_profile_rht">
                              <span className="select_back">
                                <select id="chest">
                                  <option>Select</option>
                                  {height_list_options}
                                </select>
                              </span>
                              <span className="select_back">
                                <select id="chest_inch">
                                  <option>Select</option>
                                  {measure_inch}
                                </select>
                              </span> in.
                            </div>
                          </div>
                          <div className="profile_div_sec">
                            <div className="pull-left my_profile_lft waist_label"></div>
                            <div className="pull-right my_profile_rht">
                              <span className="select_back">
                                <select id="waist">
                                  <option>Select</option>
                                  {height_list_options}
                                </select>
                              </span>
                              <span className="select_back">
                                <select id="waist_inch">
                                  <option>Select</option>
                                  {measure_inch}
                                </select>
                              </span> in.
                            </div>
                          </div>
                          <div className="profile_div_sec">
                            <div className="pull-left my_profile_lft hips_label"></div>
                            <div className="pull-right my_profile_rht">
                              <span className="select_back">
                                <select id="hips">
                                  <option>Select</option>
                                  {height_list_options}
                                </select>
                              </span>
                              <span className="select_back">
                                <select id="hips_inch" >
                                  <option>Select</option>
                                  {measure_inch}
                                </select>
                              </span> in.
                            </div>
                          </div>
                          <div className="profile_div_sec">
                            <div className="pull-left my_profile_lft arms_label"></div>
                            <div className="pull-right my_profile_rht">
                              <span className="select_back">
                                <select id="arm">
                                  <option>Select</option>
                                  {height_list_options}
                                </select>
                              </span>
                              <span className="select_back">
                                <select id="arm_inch">
                                  <option>Select</option>
                                  {measure_inch}
                                </select>
                              </span> in.
                            </div>
                          </div>
                          <div className="profile_div_sec">
                            <div className="pull-left my_profile_lft thigh_label"></div>
                            <div className="pull-right my_profile_rht">
                              <span className="select_back">
                                <select id="thigh">
                                  <option>Select</option>
                                  {height_list_options}
                                </select>
                              </span>
                              <span className="select_back">
                                <select id="thigh_inch">
                                  <option>Select</option>
                                  {measure_inch}
                                </select>
                              </span> in.
                            </div>
                          </div>
                          <div className="profile_div_sec">
                            <div className="pull-left my_profile_lft neck_label"></div>
                            <div className="pull-right my_profile_rht">
                              <span className="select_back">
                                <select id="neck">
                                  <option>Select</option>
                                  {height_list_options}
                                </select>
                              </span>
                              <span className="select_back">
                                <select id="neck_inch">
                                  <option>Select</option>
                                  {measure_inch}
                                </select>
                              </span> in.
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="starting" role="tabpanel" aria-labelledby="starting-tab">
                          <div className="profile_div_sec">
                            <div className="pull-left my_profile_lft chest_label"></div>
                            <div className="pull-right my_profile_rht">
                              <span className="select_back">
                                <select id="starting_chest_size">
                                  <option>Select</option>
                                  {height_list_options}
                                </select>
                              </span>
                              <span className="select_back">
                                <select id="starting_chest_size_inch">
                                  <option>Select</option>
                                  {measure_inch}
                                </select>
                              </span> in.
                            </div>
                          </div>
                          <div className="profile_div_sec">
                            <div className="pull-left my_profile_lft waist_label"></div>
                            <div className="pull-right my_profile_rht">
                              <span className="select_back">
                                <select id="starting_waist_size">
                                  <option>Select</option>
                                  {height_list_options}
                                </select>
                              </span>
                              <span className="select_back">
                                <select id="starting_waist_size_inch">
                                  <option>Select</option>
                                  {measure_inch}
                                </select>
                              </span> in.
                            </div>
                          </div>
                          <div className="profile_div_sec">
                            <div className="pull-left my_profile_lft hips_label"></div>
                            <div className="pull-right my_profile_rht">
                              <span className="select_back">
                                <select id="starting_hips_size">
                                  <option>Select</option>
                                  {height_list_options}
                                </select>
                              </span>
                              <span className="select_back">
                                <select id="starting_hips_size_inch">
                                  <option>Select</option>
                                  {measure_inch}
                                </select>
                              </span> in.
                            </div>
                          </div>
                          <div className="profile_div_sec">
                            <div className="pull-left my_profile_lft arms_label"></div>
                            <div className="pull-right my_profile_rht">
                              <span className="select_back">
                                <select id="starting_arm_size">
                                  <option>Select</option>
                                  {height_list_options}
                                </select>
                              </span>
                              <span className="select_back">
                                <select id="starting_arm_size_inch" >
                                  <option>Select</option>
                                  {measure_inch}
                                </select>
                              </span> in.
                            </div>
                          </div>
                          <div className="profile_div_sec">
                            <div className="pull-left my_profile_lft thigh_label"></div>
                            <div className="pull-right my_profile_rht">
                              <span className="select_back">
                                <select id="starting_thigh_size">
                                  <option>Select</option>
                                  {height_list_options}
                                </select>
                              </span>
                              <span className="select_back">
                                <select id="starting_thigh_size_inch" >
                                  <option>Select</option>
                                  {measure_inch}
                                </select>
                              </span> in.
                            </div>
                          </div>
                          <div className="profile_div_sec">
                            <div className="pull-left my_profile_lft neck_label"></div>
                            <div className="pull-right my_profile_rht">
                              <span className="select_back">
                                <select id="starting_neck_size">
                                  <option>Select</option>
                                  {height_list_options}
                                </select>
                              </span>
                              <span className="select_back">
                                <select id="starting_neck_size_inch">
                                  <option>Select</option>
                                  {measure_inch}
                                </select>
                              </span> in.
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-12 color6">
                    <div className="border1">
                      <Avatar />
                    </div>
                  </div>
                </div>


                <p><button type="submit" className="profile_save_btn measurement_save" value="" onClick={this.submitMeasurement.bind(this)} ></button></p>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Measurements;

