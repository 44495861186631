import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import Footer from "../footer.js";
import Header from "../header.js";
import DatePicker from "react-datepicker";
import Navigation from "../nav.js";
import Adspeed from "../adspeed.js";
import moment from 'moment';
import { addDays, subDays, parse } from 'date-fns';
import ActivityQuestions from './activity_questions.js';
import ActivityQuestionOptions from './activity_question_options.js';
import { PersonalizePlanMode, Carousel } from '../onboarding/PersonalizePlanMode.js';
import axios from 'axios';
import $ from 'jquery';
import * as Sentry from "@sentry/react";
import SmartWeekCheatDay from '../cheat_days/smart_week_cheat_days_setup.js';

class PlanSettings extends React.Component {
  constructor(props) {
    super(props)
    this.state = { personalized_options: [], nutrisystem_profile_linked: '', personalization_plan: '', show_date: '', plan_start_date: '', phase_templates: [], phase_plan: [], plan_option_drop: [], plan_option_values: [], phase_option_values: [], phase_option_key: [], phase_option_values_selected: '', plan_option_values_selected: '', startDate: '', show_smart_week: null, smartweek_type: null, cheat_days: [], select_cheat_option: null, select_cheat_days: null, cheatDays: null, plansettings: this.props.from }

  }

  callCheatDaysAPI = () => {
    Header.startLoader();
    const smart_week_new = {
      url: window.API_URL + "v1/nutrisystem/smart_week",
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };
    axios(smart_week_new)
      .then(response => {
        Header.stopLoader();
        localStorage.setItem('smart_week_setup_screen', response.data.smart_week.show_smart_week);
        this.setState({
          cheatDays: (response.data.smart_week.allowed_number_of_cheat_days)
        })
        if (localStorage.getItem("gender") === "male") {
          /*keystrings*/
          $('.cd_week_type_title_txt').text(window.keystrings['com.numi.cd.male_one_day_week_type_title']);
          $('.cd_week_type_title_txt').text(window.keystrings['com.numi.cd.male_two_days_week_type_title']);
          /*keystrings*/
        } else {
          /*keystrings*/
          $('.cd_week_type_title_txt').text(window.keystrings['com.numi.cd.female_one_day_week_type_title']);
          $('.cd_week_type_title_txt').text(window.keystrings['com.numi.cd.female_two_days_week_type_title']);
          /*keystrings*/
        }
        if (response.data.smart_week.allowed_number_of_cheat_days === 0 && localStorage.getItem("gender") === "male") {
          $('.smartweek_setup_type').text(window.keystrings['com.numi.cd.male_steady_title'])
        } if (response.data.smart_week.allowed_number_of_cheat_days === 1 && localStorage.getItem("gender") === "male") {
          $('.smartweek_setup_type').text(window.keystrings['com.numi.cd.male_one_cheat_day_type2'])
        } if (response.data.smart_week.allowed_number_of_cheat_days === 2 && localStorage.getItem("gender") === "male") {
          $('.smartweek_setup_type').text(window.keystrings['com.numi.cd.male_two_cheat_days_type2'])
        } if (response.data.smart_week.allowed_number_of_cheat_days === 0 && localStorage.getItem("gender") === "female") {
          $('.smartweek_setup_type').text(window.keystrings['com.numi.cd.female_steady_title'])
        } if (response.data.smart_week.allowed_number_of_cheat_days === 1 && localStorage.getItem("gender") === "female") {
          $('.smartweek_setup_type').text(window.keystrings['com.numi.cd.female_one_break_day_type2'])
        } if (response.data.smart_week.allowed_number_of_cheat_days === 2 && localStorage.getItem("gender") === "female") {
          $('.smartweek_setup_type').text(window.keystrings['com.numi.cd.female_two_break_days_type2'])
        }

      })
      .catch(error => {
        Header.stopLoader(); // stop loader
        Sentry.captureException(error);
      });
  }
  handleChange = (date) => {

    this.setState({
      startDate: date
    });
    this.turboClick(date);
    Header.changeDate(this.state.startDate);
  }

  stayOnPlan = () => {
    if (window.localStorage.getItem('plan_option_value') === "true") {
      $('#plan_option_values option').removeAttr('selected');
      $('#plan_option_values option[value="true"]').prop('selected', true);
      localStorage.removeItem('plan_option_value');
    }
    else if (window.localStorage.getItem('phase_option_value') === "true") {
      $('#phase_option option').removeAttr('selected');
      var kk = $.trim(window.localStorage.getItem('select_curr_phase_value'));
      if (kk !== "") {
        $('#phase_option option[name="' + kk + '"]').prop('selected', true);
        localStorage.removeItem('phase_option_value');
      }

    }
    $('.toggle_date').hide();
    $('.toggle_options label').css('opacity', '0.4');
    $('#turbo_button').addClass('btn disable').prop('disabled', true);

  }


  setSmartWeekPopup = () => {
    $("#newCalGoal").hide();
    $("#step0").hide();
    $("#step1").show();
    $("#step2").hide();
    $("#step3").hide();
    $("#smartWeekCheatDaySubmitBtn").show();
    $("#smartWeekCheatDaySaveBtn").hide();
    $("#backToPrevStep12").removeClass("d-none")
    $("#backToPrevStep1").addClass("d-none");
    $("#backToPrevStep21").addClass("d-none")
    $("#backToPrevStep2").removeClass("d-none")
    this.setState({
      select_cheat_option: "Yes",
      select_cheat_days: "No"
    })
    Header.startLoader();
    const cheatDaysCal = {
      url: window.API_URL + "v1/nutrisystem/smart_week",
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(cheatDaysCal)
      .then(response => {
        Header.stopLoader();
        if (response && response.data) {

          if (response.data.smart_week.cheat_days != null) {

            $(".week-cheat-day-card").removeClass("selected");
            if (response.data.smart_week.cheat_days.includes("Sunday")) {
              $('#week-cheat-day-card-sunday').addClass("selected");
            } if (response.data.smart_week.cheat_days.includes("Monday")) {
              $('#week-cheat-day-card-monday').addClass("selected");
            } if (response.data.smart_week.cheat_days.includes("Tuesday")) {
              $('#week-cheat-day-card-tuesday').addClass("selected");
            } if (response.data.smart_week.cheat_days.includes("Wednesday")) {
              $('#week-cheat-day-card-wednesday').addClass("selected");
            } if (response.data.smart_week.cheat_days.includes("Thursday")) {
              $('#week-cheat-day-card-thursday').addClass("selected");
            } if (response.data.smart_week.cheat_days.includes("Friday")) {
              $('#week-cheat-day-card-friday').addClass("selected");
            } if (response.data.smart_week.cheat_days.includes("Saturday")) {
              $('#week-cheat-day-card-saturday').addClass("selected");
            }


          }
          this.setState({
            select_cheat_days: "Yes",
            select_cheat_option: "No"

          })

        }
      })
  }

  setCheatDaysPopup = () => {
    $("#newCalGoal").hide();
    $("#step0").hide();
    $("#step1").hide();
    $("#step2").show();
    $("#step3").hide();
    $("#smartWeekCheatDaySubmitBtn").hide();
    $("#smartWeekCheatDaySaveBtn").show();
    $(document.getElementById("smartWeekCheatDaySaveBtn")).removeClass("option_disabled");
    $("#backToPrevStep2").addClass("d-none")
    $("#backToPrevStep21").removeClass("d-none")
    Header.startLoader();
    const cheatDaysCal = {
      url: window.API_URL + "v1/nutrisystem/smart_week",
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(cheatDaysCal)
      .then(response => {
        Header.stopLoader();
        if (response && response.data) {

          if (response.data.smart_week.cheat_days != null) {

            $(".week-cheat-day-card").removeClass("selected");
            if (response.data.smart_week.cheat_days.includes("Sunday")) {
              $('#week-cheat-day-card-sunday').addClass("selected");
            } if (response.data.smart_week.cheat_days.includes("Monday")) {
              $('#week-cheat-day-card-monday').addClass("selected");
            } if (response.data.smart_week.cheat_days.includes("Tuesday")) {
              $('#week-cheat-day-card-tuesday').addClass("selected");
            } if (response.data.smart_week.cheat_days.includes("Wednesday")) {
              $('#week-cheat-day-card-wednesday').addClass("selected");
            } if (response.data.smart_week.cheat_days.includes("Thursday")) {
              $('#week-cheat-day-card-thursday').addClass("selected");
            } if (response.data.smart_week.cheat_days.includes("Friday")) {
              $('#week-cheat-day-card-friday').addClass("selected");
            } if (response.data.smart_week.cheat_days.includes("Saturday")) {
              $('#week-cheat-day-card-saturday').addClass("selected");
            }
            if (localStorage.getItem("gender") === "male") {
              /*keystrings*/

              if ((response.data.smart_week.smartweek_type === 'steady' ? 0 : response.data.smart_week.smartweek_type === '1cd' ? 1 : 2) === 1) {
                $('.cd_week_type_title_txt').text(window.keystrings['com.numi.cd.male_one_day_week_type_title']);

              } else {
                $('.cd_week_type_title_txt').text(window.keystrings['com.numi.cd.male_two_days_week_type_title']);
              }

              /*keystrings*/
            } else {
              /*keystrings*/

              if ((response.data.smart_week.smartweek_type === 'steady' ? 0 : response.data.smart_week.smartweek_type === '1cd' ? 1 : 2) === 1) {
                $('.cd_week_type_title_txt').text(window.keystrings['com.numi.cd.female_one_day_week_type_title']);
              } else {
                $('.cd_week_type_title_txt').text(window.keystrings['com.numi.cd.female_two_day_week_type_title']);
              }
              /*keystrings*/
            }
          }
          this.setState({
            select_cheat_days: "Yes",
            select_cheat_option: "No"

          })

        }
      })
  }

  /* get all settings for user*/
  getPlanSettings = () => {
    Header.startLoader();
    const options = {
      url: window.API_URL + "v1/plans/settings",
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        Header.stopLoader();
        if (response && response.data) {

          var plateau_enabled = response.data.options && response.data.options.find(obj => obj.key === "PLATEAU_ENABLED")
          var recalculate_enabled = response.data.options && response.data.options.find(obj => obj.key === "CIPD_RECALCULATE")
          this.setState({ recalculate_value: (recalculate_enabled ? recalculate_enabled.value : ''), plateau_value: (plateau_enabled ? plateau_enabled.value : ''), personalized_options: response.data.options, nutrisystem_profile_linked: response.data.nutrisystem_profile_linked, personalization_plan: response.data.personalization_plan, phase_templates: response.data.current_phase_template.phase_templates, phase_plan: response.data.current_plan.plans, plan_option_drop: response.data.plan_options, show_date: moment(response.data.profile_start_date).format('MM/DD/YYYY'), plan_start_date: moment(response.data.current_plan.start_date).format('MM/DD/YYYY') });
          var show_questionnaire_for_phase_2 = response.data.current_phase_template.phase_templates.find(obj => obj.phase_id === 2)
          if (show_questionnaire_for_phase_2 !== undefined) {
            window.localStorage.setItem('show_questionnaire_for_phase_2', show_questionnaire_for_phase_2['show_questionnaire'])
          }
          if (this.state.plateau_value === "Disabled") {
            $('.important_plateau_warning').text(window.keystrings['com.numi.settings.important_plateau_warning']);
            $('.important_plateau_warning_txt').html(window.keystrings['com.numi.settings.important_plateau_warning_txt']);
            $('.plateau_manage_btn').text(window.keystrings['com.numi.settings.enable_plateau_mode']);
          }
          else {
            $('.important_plateau_warning').text(window.keystrings['com.numi.settings.turn_off_plateau_mode']);
            $('.important_plateau_warning_txt').html(window.keystrings['com.numi.settings.turn_off_plateau_mode_txt']);
            $('.plateau_manage_btn').text(window.keystrings['com.numi.settings.yes_turn_it_off']);
          }
          var personalization_plan = this.state.personalization_plan;
          var nutrisystem_profile_linked = this.state.nutrisystem_profile_linked;
          if (response.data.plan_options.length === 0) {
            this.setState({ plan_option_values: response.data.plan_options });
          }
          else {
            this.setState({ plan_option_values: response.data.plan_options[0].values, plan_option_values_selected: response.data.plan_options[0].selected });
            window.localStorage.setItem("current_activity_level", response.data.plan_options[0].selected)
          }

          this.setState({
            cheat_days: response.data.cheat_days,
            show_smart_week: response.data.show_smart_week,
            smartweek_type: response.data.smartweek_type
          })

          $('#phase_plan option[value="true"]').attr('selected', 'selected');
          $('.plan_select_value span').text($('#phase_plan option[value="true"]').text());

          if (response.data.plan_options.length === 0) {
            $('.plan_option_div').hide()
          }
          else {
            $('.plan_option_div').show()
          }

          if (response.data.phase_options.length === 0) {
            this.setState({ phase_option_values: response.data.phase_options });
          }
          else {
            if (response.data.phase_options[0].key === "TURBO_TAKEOFF" && response.data.phase_options[1].key === "END_DATE") {
              var end_date_array = [];
              end_date_array.push(response.data.phase_options[1].selected);
              window.localStorage.setItem('week_1_ends_on', response.data.phase_options[1].selected)
              localStorage.setItem("turbo_takeoff", response.data.phase_options[0].key)
              this.setState({ phase_option_values: end_date_array, phase_option_key: response.data.phase_options, phase_option_values_selected: "turbo_takeoff_end_date" });

            }
            else {
              this.setState({ phase_option_values: response.data.phase_options[0].values, phase_option_key: response.data.phase_options, phase_option_values_selected: response.data.phase_options[0].selected });
            }
          }


          if (response.data.phase_options.length === 0 || (response.data.options && response.data.options.length > 0)) {
            $('.phase_option_div').hide()
          }
          else {
            $('.phase_option_div').show()
          }


          $('#phase_option option[value="true"]').attr('selected', 'selected');
          $('#plan_option_values option[value="true"]').attr('selected', 'selected');
          $('#phase_option_values option[value="true"]').attr('selected', 'selected');


          $('.left_phase_option').each(function () {
            $(this).attr('id', $.trim($(this).text()));
            $.trim($(this).text($(this).text().replace(/_/g, ' ')));
          });

          if (response.data.phase_options.length !== 0) {
            if (response.data.phase_options[0].key === "GOAL_WEIGHT") {
              $(".phase_option_div").hide();
            }
          }
          if (!$(".phase_option_div").is(':visible') && !$(".plan_option_div").is(':visible') && response.data.options === null) {
            $(".options_display").hide();
          }
          $('#turbo_takeoff_datepicker .react-datepicker__input-container input').attr('readonly', true);
          if (window.localStorage.link_page_from_plan_settings) {
            $('#change_plan').modal('show');
            $('.change_plan').hide();
            $('.confirm_goal_weight,.plan_popup,.change_plan_link,.success_phase,.turbo_phase_option,.low_blood_sugar,.smart-week-setup').hide();
            $('.modal-body').removeClass('calender_fix');
            if (personalization_plan !== null && (nutrisystem_profile_linked === true || nutrisystem_profile_linked === 'true')) {
              var plan_name = personalization_plan.name;
              var plan_key = personalization_plan.key;
              var plan_id = personalization_plan.id;
              $('.change_plan_link').show();
              $('.personalized_plan_btn').show();
              $('.link_account_btn').hide();
              $('.personalized_plan_btn').text('Choose Your ' + plan_name);
              $('.personalized_plan_btn').attr("id", plan_id);
              $('.personalized_plan_btn').attr("name", plan_key);
              $('.change_plan_message_text_for_link').html(window.keystrings['com.numi.change_plan_message']);
              $('.change_other_plan_txt').html(window.keystrings['com.numi.choose_different_plan']);
              if (localStorage.help_location === "RESTART") {
                $('.change_plan_title_text').text(window.keystrings['com.numi.restart_plan_title']);
                $('.change_plan_image').attr('src', '/assets/images/restart_plan.png');
              }
              else {
                $('.change_plan_title_text').text(window.keystrings['com.numi.change_plan_title'])
              }
            }
            else if (nutrisystem_profile_linked === false || nutrisystem_profile_linked === 'false') {
              $('.change_plan_link').show();
              $('.personalized_plan_btn').hide();
              $('.link_account_btn').show();
              $('.link_account_btn').text(window.keystrings['com.numi.link_nutrisystem_account_for_personalized_plan']);
              $('.change_plan_message_text_for_link').html(window.keystrings['com.numi.change_plan_message_text_for_link']);
              $(".change_other_plan_txt").text(window.keystrings['com.numi.change_other_plan_txt']);
              if (localStorage.help_location === "RESTART") {
                $('.change_plan_title_text').text(window.keystrings['com.numi.restart_plan_title']);
                $('.change_plan_image').attr('src', '/assets/images/restart_plan.png');
              }
              else {
                $('.change_plan_title_text').text(window.keystrings['com.numi.change_plan_title'])
              }
            }
            else {
              $('.plan_popup').show();
            }
            window.localStorage.removeItem("link_page_from_plan_settings");
          }
        }

        if (localStorage.getItem("gender") === "male") {
          $('.settings_options_smart_week_setup_txt').text(window.keystrings['com.numi.cd.male_settings.options.setup']);
          $('.settings_options_set_cheat_days_txt').text(window.keystrings['com.numi.cd.settings.options.male_set_cheat_days']);
          $('.settings_options_set_cheat_days_value_txt').text(window.keystrings['com.numi.cd.male_settings_change']);
        } else {
          $('.settings_options_smart_week_setup_txt').text(window.keystrings['com.numi.cd.female_settings.options.setup']);
          $('.settings_options_set_cheat_days_txt').text(window.keystrings['com.numi.cd.settings.options.female_set_break_days']);
          $('.settings_options_set_cheat_days_value_txt').text(window.keystrings['com.numi.cd.female_settings_change']);
        }
        if (localStorage.getItem("gender") === "male") {
          /*keystrings*/
          $('.cd_smartweek_male_steady_title_txt').text(window.keystrings['com.numi.cd.male_steady_title']);
          $('.cd_smartweek_male_one_cheat_day_type2_txt').text(window.keystrings['com.numi.cd.male_one_cheat_day_type2']);
          $('.cd_smartweek_male_two_cheat_days_type2_txt').text(window.keystrings['com.numi.cd.male_two_cheat_days_type2']);
          /*keystrings*/
        } else {
          /*keystrings*/
          $('.cd_smartweek_female_steady_title_txt').text(window.keystrings['com.numi.cd.female_steady_title']);
          $('.cd_smartweek_female_one_break_day_type2_txt').text(window.keystrings['com.numi.cd.female_one_break_day_type2']);
          $('.cd_smartweek_female_two_break_days_type2_txt').text(window.keystrings['com.numi.cd.female_two_break_days_type2']);
          /*keystrings*/
        }
        if (response.data.show_smart_week != true) {
          $(".smartWeekEnabled").hide();
        } else if (response.data.options === null) {
          $(".smartWeekEnabled").hide();
        } else {
          $(".smartWeekEnabled").show();
        }

        if (response.data.smartweek_type != "steady" && response.data.smartweek_type != null) {
          $(".cheat_day_option").show();
        } else {
          $(".cheat_day_option").hide();
        }





      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });

  }

  turboClick = (date) => {
    var value = moment(date).format('YYYY-MM-DD');
    var profile_properties = { "profile_properties": [{ "key": "end_date", "value": value }] };
    const options = {
      url: window.API_URL + "v1/profile_properties",
      method: 'POST',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
      data: profile_properties
    };

    axios(options)
      .then(response => {
        localStorage.removeItem('turbo_start_date');
        this.props.history.push('/journal');
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });
  };





  componentDidMount() {
    this.getPlanSettings();
    this.callCheatDaysAPI();

    /*Disable select if only one option available*/
    $("select#phase_option_values.phase_option_values_class").each(function () {
      this.disabled = $('option', this).length < 2;
    });


    if (window.localStorage.getItem('show_restart_popup') !== null && window.localStorage.getItem('show_restart_popup') !== undefined && window.localStorage.getItem('show_restart_popup') === "true") {
      $('#restart_plan').modal('show');
      localStorage.removeItem('show_restart_popup');
    }

    $('.restart_plan').click(function () {
      localStorage.removeItem('nutrisystem_stage');

      $('#change_plan .modal-header').show();
      if ($('#change_plan .nutri_link').is(':visible')) {
        $('#change_plan .modal-content,#change_plan .modal-body').addClass('padding0')
      }
      else {
        $('#change_plan .modal-content,#change_plan .modal-body').removeClass('padding0')
      }
      $('.weight_error_msg').text('');
      $('.change_image').attr('src', '/assets/images/restart_plan.png');
      $('.change_plan_image').attr('src', '/assets/images/restart_plan.png');
      $('.change_hdr').text(window.keystrings['com.numi.restart_plan_title']);
      $('.change_txt').text(window.keystrings['com.numi.restart_plan_message']);
      $('.choose_plan_btn').text(window.keystrings['com.numi.restart_plan_title']);
      $('.change_plan_link .change_plan_title_text').text(window.keystrings['com.numi.restart_plan_title']);
      $('#change_plan').modal('show');
      $('.change_plan').show();
      $('.confirm_goal_weight,.plan_popup,.change_plan_link,.success_phase,.turbo_phase_option,.low_blood_sugar,.smart-week-setup').hide();
      $('.modal-body').removeClass('calender_fix');
    });

    Header.getTurboTakeoffDays();
    /*Key Strings*/
    $('.settings-current-stage-key-strings').text(window.keystrings['com.numi.settings.current_stage'])
    $('.settings-stage-key-strings').text(window.keystrings['com.numi.settings.stage'])
    $('.back_to_settings_text').append(window.keystrings['com.numi.setting.back_to_settings'])
    $('.plan_settings_title_text').text(window.keystrings['com.numi.plan_settings_title'])
    $('.settings_numi_text').text(window.keystrings['com.numi.settings_numi'])
    $('.numi_start_date_text').text(window.keystrings['com.numi.numi_start_date'])
    $('.current_plan_text').text(window.keystrings['com.numi.current_plan'])
    $('.plan_text').text(window.keystrings['com.numi.plan'])
    $('.plan_start_date_text').text(window.keystrings['com.numi.plan_start_date'])
    $('.restart_plan_text').text(window.keystrings['com.numi.restart_plan_title'])
    $('.options_text').text(window.keystrings['com.numi.options'])
    $('.restart_plan_message_text').text(window.keystrings['com.numi.restart_plan_message'])
    $('.cancel_text').text(window.keystrings['com.numi.cancel'])
    $('.profile_form_option_current_weight_text').text(window.keystrings['com.numi.profile_form_option_current_weight'])
    $('.profile_form_option_goal_weight_text').text(window.keystrings['com.numi.profile_form_option_goal_weight'])
    $('.restart_button_text').text(window.keystrings['com.numi.restart_button'])
    $('.choose_plan_title_text').text(window.keystrings['com.numi.choose_plan_title'])
    $('.what_is_activity_level_text').text(window.keystrings['com.numi.what_is_activity_level'])
    $('.activity_level_1_text').text(window.keystrings['com.numi.activity_level_1'])
    $('.activity_level_2_text').text(window.keystrings['com.numi.activity_level_2'])
    $('.activity_level_3_text').text(window.keystrings['com.numi.activity_level_3'])
    $('.continue_text').text(window.keystrings['com.numi.continue'])
    $('.save_text').text(window.keystrings['com.numi.save'])
    $('.blood_sugar_label_text').text(window.keystrings['com.numi.blood_sugar_label'])
    $('.blood_sugar_recommendation_text').text(window.keystrings['com.numi.blood_sugar_recommendation'])
    $('.diabetic_query_desc_text').append(window.keystrings['com.numi.diabetic_query_desc'])
    $('.setting_plan_restart_text').prepend(window.keystrings['com.numi.restart_title'])
    $('.lets_do_this_text').text(window.keystrings['com.numi.setting.lets_do_this'])
    $('.stage-options-keys-string').each(function () {
      $(this).text(window.keystrings['com.numi.journal.stage_options'])
    })
    $('.change_plan_title_text').text(window.keystrings['com.numi.change_plan_title'])
    $('.yes_text').text(window.keystrings['com.numi.yes'])
    $('.no_text').text(window.keystrings['com.numi.no'])
    $('.change_plan_message_text').html(window.keystrings['com.numi.change_plan_popup_desc']);
    $('.change_plan_message_text_for_link').html(window.keystrings['com.numi.change_plan_message_text_for_link']);
    /*keystrings*/


    // $('.smart_week_setup_modal').click(function () {
    //   $('.smart-week-setup').show();
    //   $('.change_plan').hide();
    //   $('.change_plan_link').hide();
    // });





  }

  openRecalculateHelp = () => {
    $("#recalculate_calorie_goal_every_10lbs_help").modal('show');
  }

  handlePersonalizedOptions = (key, type, value, title) => {
    $(".calculation").hide();
    $('.personal_hdr').text(window.keystrings['com.numi.personalized_plan']);
    if (key !== "CIPD_RECALCULATE") {
      $('.personalized_hdr').html(window.keystrings['com.numi.personalized_hdr_plateau_mode']);
      if (key !== "PLATEAU_ENABLED") {
        $('.calorie_range_txt').html(window.keystrings['com.numi.activity_level_info_text']);
      }
    }
    if (key === "CIPD_RECALCULATE") {
      var cipd_value = $('select#recalculate_calorie_goal option:selected').val();
      var nutrisystem_user_properties = { "nutrisystem_user_property": { "key": key, "value": cipd_value } };
      const options = {
        url: window.API_URL + "v1/nutrisystem/user_properties",
        method: 'PUT',
        credentials: 'same-origin',
        headers: Header.setAuthHeaders(),
        data: nutrisystem_user_properties
      };
      axios(options)
        .then(response => {
          localStorage.setItem('recalculateValue', (response.data.nutrisystem_user_property.value === 'TRUE' ? 'Enabled' : 'Disabled'));
          var evt = document.createEvent("CustomEvent");
          evt.initCustomEvent('RecalculateEnabled', true, false, false);
          document.dispatchEvent(evt);
          this.getPlanSettings();
        })
        .catch(error => {
          Header.stopLoader();
          Header.apiErrors(error);
          Sentry.captureException(error);
        });
    }
    else if (key === "PLATEAU_ENABLED") {
      localStorage.setItem('check_if_plateau', key);
      $("#plateau_calorie_goal_setting_warning").modal('show');
    }
    else if (type === "questionnaire") {
      if (title === "Exercise Habits" || 'ACTIVITY' || 'NEAT' || 'Daily Lifestyle') {
        $('.personal_hdr').text(window.keystrings['com.numi.numi_smartadapt_header']);
        $('.personal_meal_hdr').text(window.keystrings['com.numi.updating_activity_level']);
        $('.personalized_text').html(window.keystrings['com.numi.activity_level_text']);
      }
      $('.text-green-darker').find('img').each(function () {
        if ($(this).parent().hasClass('active')) {
          $(this).attr('src', $(this).attr('active_image'));
          $(this).parent().css('opacity', 1).addClass('active');
        }
        else {
          $(this).attr('src', $(this).attr('normal_image'));
          $(this).parent().css('opacity', 0.3).removeClass('active');
        }
      });
      $('#activityGoalOptions .row > div').hide();
      $('#activityGoalOptions .row > div:eq(0)').show();
      $('#activity_question_options').modal('show');
      setTimeout(function () {
        $('body').addClass('modal-open');
      }, 1200)
    }
  }

  render() {

    var phase_templates_list = this.state.phase_templates.map(function (option, i) {
      setTimeout(() => {
        localStorage.setItem('select_curr_phase_value', $.trim($('#phase_option option:selected').text()));
        if ($.trim($('#phase_option option:selected').text()) === 'SUCCESS' || $.trim($('#phase_option option:selected').text()) === 'Success') {
          $(".smartWeekEnabled").hide();
        }
      }, 999)

      return (
        <option className="meal_txt cursor_pointer" key={i} value={option.selected} show_questionnaire={option.show_questionnaire ? 'true' : 'false'} id={option.phase_id} name={option.name}> {option.name}</option>
      )

    });

    var phase_plan_list = this.state.phase_plan.map((option, i) => {

      return (
        <option className="meal_txt cursor_pointer display_none" key={i} value={option.selected} name={option.name}> {option.name}</option>
      )
    });
    var plan_option_list = this.state.plan_option_drop.map((option, i) => {

      return (
        <span key={i} value={option.key} name={option.key}> {option.key}</span>

      )
    });
    var plan_option_values = this.state.plan_option_values.map((option, i) => {
      if (option === this.state.plan_option_values_selected) {
        return (
          <option value="true" key={i}>{option}</option>
        )
      } else {
        return (
          <option key={i}>{option}</option>
        )
      }
    });


    var phase_option_list = this.state.phase_option_key.map((option, i) => {
      if (option.key !== "TURBO_TAKEOFF") {
        if (option.key === "END_DATE") {
          return (

            <span key={i} value={option.key} name={option.key} className="turbotakeoff_text">
              {this.state.phase_option_key[0].misc_data.turbo_takeoff_ends}
            </span>

          )
        } else {
          return (
            <span key={i} value={option.key} name={option.key}> {option.key}</span>

          )
        }
      }
    });

    var phase_option_values = this.state.phase_option_values.map((option, i) => {
      if (option === this.state.phase_option_values_selected) {
        return (
          <option value="true" key={i}>{option}</option>
        )
      } else if (this.state.phase_option_values_selected === "turbo_takeoff_end_date") {
        return (
          <div key={i} className="date_pick_turbo input-group pull-right vlm width50" id="turbo_takeoff_datepicker" >
            <DatePicker onChange={this.handleChange} selected={new Date(parse(window.localStorage.getItem("week_1_ends_on")))} minDate={new Date(parse(window.localStorage.getItem("turbo_start_date")))} maxDate={addDays(new Date(parse(window.localStorage.getItem("week_1_ends_on"))), 29)} />
          </div>
        )
      } else {
        return (
          <option key={i}>{option}</option>
        )
      }
    });

    var personalized_options = (this.state.personalized_options ? this.state.personalized_options : []).map((option, i) => {
      return (
        <div className="profile_div_sec" key={i}>
          {option.key === 'CIPD_RECALCULATE' ?
            <>
              <div className="pull-left my_profile_lft">{option.title} <sup><img alt="" src="/assets/images/education.png" className="cursor_pointer" onClick={() => this.openRecalculateHelp()} /></sup></div>
              <div className="pull-right my_profile_rht recalculate_calorie_goal">
                <span className="select_back font16 light_green">
                  <select id="recalculate_calorie_goal" onChange={() => this.handlePersonalizedOptions(option.key, option.type, option.value, option.title)}>
                    <option key="true" value="true" selected={option.value === "enabled"}>{option.value === "enabled" ? option.label : 'Enabled'}</option>
                    <option key="false" value="false" selected={option.value === "disabled"}>Disabled</option>
                  </select>
                </span>
              </div>
            </>
            :
            <>
              <div className="pull-left my_profile_lft">{option.title}</div>
              <div className="pull-right my_profile_rht cursor_pointer" onClick={() => this.handlePersonalizedOptions(option.key, option.type, option.value, option.title)}>
                <span className="font16 pull-left light_green ml15">{option.value}</span>
                <img alt="" src="/assets/images/arrow_right_grey.png" className="cursor_pointer" />
              </div>
            </>
          }
        </div>
      )
    });




    return (

      <div>
        <div className="table_display">
          <div className="journal_div settings">
            <div className="section_left pull-left">
              <Navigation />
            </div>
            <div className="section_right pull-left">
              <figure><Adspeed /></figure>
              <div className="journal_rht communication">
                <p className="mb">
                  <Link to="/settings" className="back_to back_to_settings_text"><img alt="" src="/assets/images/back_white_icon.png" className="vlb" /> </Link>
                </p>
                <h1 className="journal_hdr pull-left plan_settings_title_text">Plan Settings</h1>
                <div className="clear"></div>
                <hr />
                <div className="panel-group meal_section_left pull-left text-center">
                  <div className="plan_setting">
                    <div className="profile_div_sec">
                      <div className="my_profile_lft plan_lft_hdr settings_numi_text"></div>
                    </div>
                    <div className="profile_div_sec">
                      <div className="pull-left my_profile_lft numi_start_date_text"></div>
                      <div className="pull-right my_profile_rht light_green">{this.state.show_date}</div>
                    </div>
                  </div>
                  <div className="plan_setting">
                    <div className="profile_div_sec">
                      <div className="my_profile_lft plan_lft_hdr current_plan_text"></div>
                    </div>
                    <div className="profile_div_sec">
                      <div className="pull-left my_profile_lft plan_text"> </div>
                      <div className="pull-right my_profile_rht">
                        <div className="select_back change_plan_div cursor_pointer" data-target="#change_plan" data-toggle="modal">
                          <div className="plan_select_value">
                            <span className="light_green"></span>
                            <select id="phase_plan" className='display_none'>
                              {phase_plan_list}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="profile_div_sec">
                      <div className="pull-left my_profile_lft plan_start_date_text"></div>
                      <div className="pull-right my_profile_rht light_green">{this.state.plan_start_date}</div>
                    </div>
                    <div className="profile_div_sec restart_plan cursor_pointer">
                      <div className="pull-left my_profile_lft restart_plan_text"></div>
                      <div className="pull-right my_profile_rht"><img alt="" src="/assets/images/arrow_right_grey.png" className="cursor_pointer" /></div>
                    </div>
                  </div>

                  <div className="plan_setting">
                    <div className="profile_div_sec">
                      <div className="my_profile_lft plan_lft_hdr settings-current-stage-key-strings"></div>
                    </div>
                    <div className="profile_div_sec">
                      <div className="pull-left my_profile_lft settings-stage-key-strings"> </div>
                      <div className="pull-right my_profile_rht">
                        <span className="select_back">
                          <select id="phase_option" className="">
                            {phase_templates_list}
                          </select>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="profile_div_sec options_display">
                    <div className="my_profile_lft plan_lft_hdr options_text"> </div>
                  </div>
                  <div className="plan_setting phase_option_div">
                    <div className="profile_div_sec">
                      <div className="pull-left my_profile_lft left_phase_option phase_option_values">{phase_option_list} </div>
                      <div className="pull-right my_profile_rht">
                        <span id="phase_option_values" className="phase_option_values_class">
                          {phase_option_values}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="plan_setting plan_option_div">
                    <div className="profile_div_sec">
                      <div className="pull-left my_profile_lft left_phase_option plan_option_values">{plan_option_list}</div>
                      <div className="pull-right my_profile_rht">
                        <span className="select_back">
                          <select id="plan_option_values" className="">
                            {plan_option_values}
                          </select>
                        </span>
                      </div>
                    </div>

                  </div>
                  {personalized_options}
                  <div className="smartWeekEnabled" style={{ display: 'none' }}>
                    <div className="plan_options">
                      <div className="profile_div_sec smart_week_type">
                        <div className="pull-left my_profile_lft settings_options_smart_week_setup_txt"> </div>
                        <div className="my_profile_rht rht_new cursor_pointer smart_week_setup_modal" data-target="#change_smartweek_setup"
                          onClick={() => {
                            this.setSmartWeekPopup();

                          }} data-toggle="modal">
                          {this.state.cheatDays === 0 && this.state.cheatDays != null && localStorage.getItem("gender") === "male"
                            ? <span className="light_green pull-left ml15 smartweek_setup_type cd_smartweek_male_steady_title_txt"></span> :
                            this.state.cheatDays === 1 && this.state.cheatDays != null && localStorage.getItem("gender") === "male"
                              ? <span className="light_green pull-left ml15 smartweek_setup_type cd_smartweek_male_one_cheat_day_type2_txt"></span> :
                              this.state.cheatDays === 2 && this.state.cheatDays != null && localStorage.getItem("gender") === "male"
                                ? <span className="light_green pull-left ml15 smartweek_setup_type cd_smartweek_male_two_cheat_days_type2_txt"></span> :
                                this.state.cheatDays === 0 && this.state.cheatDays != null && localStorage.getItem("gender") === "female"
                                  ? <span className="light_green pull-left ml15 smartweek_setup_type cd_smartweek_female_steady_title_txt"></span> :
                                  this.state.cheatDays === 1 && this.state.cheatDays != null && localStorage.getItem("gender") === "female"
                                    ? <span className="light_green pull-left ml15 smartweek_setup_type cd_smartweek_female_one_break_day_type2_txt"></span> :
                                    this.state.cheatDays === 2 && this.state.cheatDays != null && localStorage.getItem("gender") === "female"
                                      ? <span className="light_green pull-left ml15 smartweek_setup_type cd_smartweek_female_two_break_days_type2_txt"></span> : <span className="light_green pull-left ml15 smartweek_setup_type" />
                          }
                          <img alt="" src="/assets/images/arrow_right_grey.png" className="cursor_pointer" />
                        </div>
                      </div>
                      <div className="profile_div_sec cheat_day_option">
                        <div className="pull-left my_profile_lft settings_options_set_cheat_days_txt"> </div>
                        <div className="pull-right my_profile_rht rht_new cursor_pointer smart_week_setup_modal" data-target="#change_smartweek_setup"
                          onClick={() => {
                            this.setCheatDaysPopup();
                          }} data-toggle="modal">
                          <span className="light_green pull-left ml15 settings_options_set_cheat_days_value_txt"></span>
                          <img alt="" src="/assets/images/arrow_right_grey.png" className="cursor_pointer" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
            <RestartPlan />
            <RecalculateCalorieGoalHelp />
            <PlateauCalorieGoalSettingWarning plateauValue={this.state.plateau_value} />
            <ChangePlan show_plan_list={this.state.phase_plan} showPreviousPlan={this.stayOnPlan} nutrisystem_profile_linked={this.state.nutrisystem_profile_linked} personalization_plan={this.state.personalization_plan} {...this.props} />
            <ChangePhase showPreviousPlan={this.stayOnPlan} />
          </div>
        </div>
      </div>
    )
  }
}

class RecalculateCalorieGoalHelp extends React.Component {
  componentDidMount() {
    $('.modal-backdrop').remove();
    $('body').removeClass('modal-open');
    $('.remove_fade').on('click', function () {
      $('.modal').modal('hide');
    });

    /*keystrings*/
    $('.recalculate_calorie_goal_help_txt').html(window.keystrings['com.numi.settings.recalculate_calorie_goal_help_txt']);
    $('.recalculate_calorie_goal_help_header').html(window.keystrings['com.numi.settings.recalculate_calorie_goal_help_header']);
    /*keystrings*/
  }

  render() {
    return (
      <div className="modal fade" id="recalculate_calorie_goal_every_10lbs_help">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><img src="/assets/images/close_grey.png" alt="" /></button>
            </div>
            <div className="recalculate_calorie_goal_help width80">
              <b><p className="font26 color3 mt5 recalculate_calorie_goal_help_header"></p></b><hr />
              <p className="font16 mb5 mt5 color6 recalculate_calorie_goal_help_txt text-left"></p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class PlateauCalorieGoalSettingWarning extends React.Component {
  componentDidMount() {
    $('.modal-backdrop').remove();
    $('body').removeClass('modal-open');
    $('.remove_fade').on('click', function () {
      $('.modal').modal('hide');
    });

  }
  togglePlateau = () => {
    var value = $(".plateau_value_to_update").html();
    $('.calorie_range_txt').html(window.keystrings['com.numi.plateau_goal_text']);
    if (value === "true") {
      $('.personalized_text').html(window.keystrings['com.numi.personalized_text_plateau_mode']);
    }
    else {
      $('.personalized_text').html(window.keystrings['com.numi.personalized_text_plateau_disabled']);
    }
    $('.modal-backdrop').remove();
    $('body').removeClass('modal-open');
    $('.remove_fade').on('click', function () {
      $('.modal').modal('hide');
    });
    $("#plateau_calorie_goal_setting_warning").modal('hide');
    $('.text-green-darker').find('img').each(function () {
      if ($(this).parent().hasClass('active')) {
        $(this).attr('src', $(this).attr('active_image'));
        $(this).parent().css('opacity', 1).addClass('active');
      }
      else {
        $(this).attr('src', $(this).attr('normal_image'));
        $(this).parent().css('opacity', 0.3).removeClass('active');
      }
    });

    $('#activityGoalOptions .row > div').hide();

    setTimeout(function () {

      $('#activityGoalOptions .row > div:eq(0)').show();
      $('#activity_question_options').modal('show');
      $('body').addClass('modal-open');
    }, 1200)

  }



  render() {
    return (
      <div className="modal fade" id="plateau_calorie_goal_setting_warning">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
            </div>
            <div className="plateau_calorie_goal_setting width77 color6 font18">
              <img alt="" src="/assets/images/warning_tri.png" /><br></br><br></br>
              <h3><b className="important_plateau_warning"></b></h3><br></br>
              <div className="important_plateau_warning_txt"></div>
              <p className="plateau_value_to_update display_none">{this.props.plateauValue === "Disabled" ? 'true' : 'false'}</p>
              <button className="plateau_manage_btn width77" onClick={this.togglePlateau}></button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class RestartPlan extends React.Component {

  render() {
    return (

      <div className="modal fade" id="restart_plan">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <img alt="" src="/assets/images/close_grey.png" />
              </button>
            </div>
            <div className="modal-body">
              <img src="/assets/images/restart_plan.png" alt="" />
              <p className="font26 color3 mt5 font700 restart_plan_text"></p>
              <h6 className="mt restart_plan_message_text">Restart Plan</h6>
              <p className="mt6"><button className="restart_plan_popup restart_plan_text"></button></p>
              <p className="mt6" data-dismiss="modal"><button className="cancel_text"></button></p>
            </div>
          </div>
        </div>
      </div>

    )
  }
}


class ChangePlan extends React.Component {
  constructor(props) {
    super(props)

    this.state = { plan_start_value: window.localStorage.getItem('plan_starting_weight'), restartDate: new Date(), gender_radio: '', success_phase_radio: 'greater_than_60', disable_btn: 'btn disabled', disable: 'disabled', curr_value: window.localStorage.getItem('progres_current_weight'), goal_value: window.localStorage.getItem('progres_goal'), startDate: addDays(new Date(), window.localStorage.getItem("TURBO_TAKEOFF_DAYS")), categories: [], cpid_value: '', transData: '', final_cipd_value: '', reloadcheatday: true }



  }
  callCheatDaysAPI = () => {
    Header.startLoader();
    const smart_week_new = {
      url: window.API_URL + "v1/nutrisystem/smart_week",
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };
    axios(smart_week_new)
      .then(response => {
        Header.stopLoader();
        localStorage.setItem('smart_week_setup_screen', response.data.smart_week.show_smart_week);
        this.setState({
          cheatDays: (response.data.smart_week.allowed_number_of_cheat_days)
        })

        if (localStorage.getItem("gender") === "male") {
          /*keystrings*/
          $('.cd_smartweek_male_steady_title_txt').text(window.keystrings['com.numi.cd.male_steady_title']);
          $('.cd_smartweek_male_one_cheat_day_type2_txt').text(window.keystrings['com.numi.cd.male_one_cheat_day_type2']);
          $('.cd_smartweek_male_two_cheat_days_type2_txt').text(window.keystrings['com.numi.cd.male_two_cheat_days_type2']);
          /*keystrings*/
        } else {
          /*keystrings*/
          $('.cd_smartweek_female_steady_title_txt').text(window.keystrings['com.numi.cd.female_steady_title']);
          $('.cd_smartweek_female_one_break_day_type2_txt').text(window.keystrings['com.numi.cd.female_one_break_day_type2']);
          $('.cd_smartweek_female_two_break_days_type2_txt').text(window.keystrings['com.numi.cd.female_two_break_days_type2']);
          /*keystrings*/
        }

        if (response.data.smart_week.allowed_number_of_cheat_days === 0 && localStorage.getItem("gender") === "male") {
          $('.smartweek_setup_type').text(window.keystrings['com.numi.cd.male_steady_title'])

        } if (response.data.smart_week.allowed_number_of_cheat_days === 1 && localStorage.getItem("gender") === "male") {
          $('.smartweek_setup_type').text(window.keystrings['com.numi.cd.male_one_cheat_day_type2'])
        } if (response.data.smart_week.allowed_number_of_cheat_days === 2 && localStorage.getItem("gender") === "male") {
          $('.smartweek_setup_type').text(window.keystrings['com.numi.cd.male_two_cheat_days_type2'])
        } if (response.data.smart_week.allowed_number_of_cheat_days === 0 && localStorage.getItem("gender") === "female") {
          $('.smartweek_setup_type').text(window.keystrings['com.numi.cd.female_steady_title'])
        } if (response.data.smart_week.allowed_number_of_cheat_days === 1 && localStorage.getItem("gender") === "female") {
          $('.smartweek_setup_type').text(window.keystrings['com.numi.cd.female_one_break_day_type2'])
        } if (response.data.smart_week.allowed_number_of_cheat_days === 2 && localStorage.getItem("gender") === "female") {
          $('.smartweek_setup_type').text(window.keystrings['com.numi.cd.female_two_break_days_type2'])
        }

      })
      .catch(error => {
        Header.stopLoader(); // stop loader
        Sentry.captureException(error);
      });
  }
  handleChange = (date) => {
    this.setState({
      startDate: date
    });
    Header.changeDate(date);
  }

  restartChange = (date) => {

    this.setState({
      restartDate: date
    });
    var today = moment();
    var tomorrow = moment().add(1, 'day');
    var yesterday = moment().subtract(1, 'day');
    var equal_dates = moment(date);

    if (moment(equal_dates).isSame(tomorrow, 'day')) {
      $('.restart_date_change').find('.today_date').text('Tomorrow');
    }
    else if (moment(equal_dates).isSame(yesterday, 'day')) {
      $('.restart_date_change').find('.today_date').text('Yesterday');
    }
    else if (moment(equal_dates).isSame(today, 'day')) {
      $('.restart_date_change').find('.today_date').text('Today');
    }
    else {
      $('.restart_date_change').find('.today_date').text(moment(date).format('MMM DD'));
    }

    var actual_date = moment().format('MMMM DD, YYYY');
    var pageload_selecetd_date = moment(date).format('MMMM DD, YYYY');


    if (new Date(pageload_selecetd_date) >= new Date(actual_date)) {
      $('.show_start_weight').empty();
    }
    else {
      $('.show_start_weight').html('<span class="left_sec">Starting Weight</span> <span class="goal_weight"><input type="text" maxLength="6" id="start_weight" /> lbs.</span>');
      $('#start_weight').val(this.state.plan_start_value);
    }

  }


  inputCurrChange = (e) => {
    var letters = /^[A-Za-z]+$/;
    if (e.target.value.match(letters)) {
      return true;
    }
    else {
      var _this = this;
      _this.setState({ [e.target.name]: e.target.value });
      if (e.target.value.length >= 4) {
        if (e.target.value.indexOf('.') < 0) {
          var curr_input_value = e.target.value + '.';
          curr_input_value.split('');
        }
        else {
          curr_input_value = e.target.value;
        }

        if (e.target.value.length === 4) {
          if (e.target.value.indexOf('.') < 0) {
            _this.setState({ [e.target.name]: curr_input_value[0] + curr_input_value[1] + curr_input_value[2] + curr_input_value[4] + curr_input_value[3] });
          }
          else {
            _this.setState({ [e.target.name]: curr_input_value });
          }
        }
        else {
          if (e.target.value.length === 5) {
            if (e.target.value.indexOf('.') < 0) {
              _this.setState({ [e.target.name]: curr_input_value[0] + curr_input_value[1] + curr_input_value[2] + curr_input_value[5] + curr_input_value[3] + curr_input_value[4] });
            }
            else {
              _this.setState({ [e.target.name]: curr_input_value });
            }
          }
        }
      }
    }
  }
  radio_toggle = (e) => {
    this.setState({
      gender_radio: e.target.id,
      success_phase_radio: e.target.id,
    });
    /*if($('.turbo_phase_option').is(':visible'))
    {
      this.callTurboDate();
    }*/
  }
  callTurboDate = () => {
    if (window.localStorage.link_page_from_plan_settings) {
      var turbo_start_date = addDays(new Date(parse(localStorage.getItem('turbo_start_date'))), window.localStorage.getItem("TURBO_TAKEOFF_DAYS"));
      this.setState({
        startDate: turbo_start_date
      });
      Header.changeDate(turbo_start_date);
    }
    else {
      this.setState({
        startDate: addDays(new Date(), window.localStorage.getItem("TURBO_TAKEOFF_DAYS"))
      });
      Header.changeDate(addDays(new Date(), window.localStorage.getItem("TURBO_TAKEOFF_DAYS")))
    }
  }
  componentDidMount() {
    $('#details_popup,#select_plan,#help_plan,#help_questions').appendTo('body');
    const options = {
      url: window.API_URL + "v1/plan_categories?version=v2",
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        Header.stopLoader(); // stop loader
        this.setState({
          categories: response.data.plan_categories
        });
        setTimeout(() => {
          $('.slick-current').focus();
          let sliderbutton = $(".slick-arrow.slick-prev")[0];
          let sliderbuttondiv = $(".btn-sticky-prv")[0];
          let selectplannew = $(".select_plan_new .slick-slider.center.slick-initialized")[0]
          sliderbuttondiv.appendChild(sliderbutton);
          selectplannew.appendChild(sliderbuttondiv);

          let sliderbuttonnxt = $(".slick-arrow.slick-next")[0];
          let sliderbuttondivnxt = $(".btn-sticky-nxt")[0];
          sliderbuttondivnxt.appendChild(sliderbuttonnxt);
          selectplannew.appendChild(sliderbuttondivnxt);
        }, 200)
      })
      .catch(error => {
        Header.stopLoader(); // stop loader
        Header.apiErrors(error);
        Sentry.captureException(error);
      });




    this.callTurboDate();
    $('.restart_date_change .today_date').text('Today');
    $(document).on("input", ".confirm_goal_weight input[type='text']", function (e) {
      this.value = this.value.replace(/[^0-9]/g, '');
      if (this.value.length >= 4) {
        if (this.value.indexOf('.') < 0) {
          var curr_input_value = this.value + '.';
          curr_input_value.split('');
        }
        else {
          curr_input_value = this.value;
        }

        if (this.value.length === 4) {
          if (this.value.indexOf('.') < 0) {
            this.value = curr_input_value[0] + curr_input_value[1] + curr_input_value[2] + curr_input_value[4] + curr_input_value[3];
          }
          else {
            this.value = curr_input_value;
          }
        }
        else {
          if (this.value.length === 5) {
            if (this.value.indexOf('.') < 0) {
              this.value = curr_input_value[0] + curr_input_value[1] + curr_input_value[2] + curr_input_value[5] + curr_input_value[3] + curr_input_value[4];
            }
            else {
              this.value = curr_input_value;
            }
          }
        }
      }
    });
    $('.phase_option label').click(function () {
      $('.phase_option label').css('opacity', '0.4');
      $(this).css('opacity', '1');
      if ($(this).hasClass("no_text") && (localStorage.getItem('transition_type') === "PHASE_CHANGE")) {
        window.localStorage.setItem('no_selected', 'true');
        if ((localStorage.getItem('show_questionnaire_for_phase_2') === "true") && (document.URL.indexOf('/plan_settings') > -1)) {
          window.localStorage.setItem('first_week_turbo_no', 'true');
        }
      }
      else {
        window.localStorage.removeItem('no_selected');
        window.localStorage.removeItem('first_week_turbo_no');
      }
    });

    $(document).on('click', '.success_phase label', function () {
      $('.success_phase label').css('opacity', '0.4');
      $(this).css('opacity', '1');
      if ($('.success_phase').is(':visible')) {
        $('.turbo_phase_option').hide();
        localStorage.setItem('first_week_turbo_no', 'false')
        localStorage.removeItem('no_selected');
      }
    });
    $(document).on('click', '.choose_plan_btn', function () {
      $('.hide_restart_date').show();
      $('.change_plan').hide();
      $('.weight_error_msg').empty();
      if ($('.choose_plan_btn').text() === "Restart Plan" || $('.choose_plan_btn').text() === "Restart Your Plan" || $('.choose_plan_btn').text() === "Change Plan" || $('.choose_plan_btn').text() === "Change Your Plan") {
        window.localStorage.setItem('restart_plan_phase_id', 1);
      }
      var curr_val = window.localStorage.getItem('progres_current_weight');
      var final_cur_val;
      if (curr_val % 1 === 0) {
        final_cur_val = curr_val;
      } else {
        final_cur_val = parseFloat(curr_val).toFixed(2);
      }
      $("#curr_weight").val(final_cur_val);

      var goal_val = window.localStorage.getItem('progres_goal');
      var final_goal_val;
      if (goal_val % 1 === 0) {
        final_goal_val = goal_val;
      } else {
        final_goal_val = parseFloat(goal_val).toFixed(2);
      }
      $("#goal_weight").val(final_goal_val);
      $('.confirm_goal_weight').show();
      $('.modal-body').addClass('calender_fix');
      if ($(this).text() === "Change Plan" || $('.choose_plan_btn').text() === "Change Your Plan") {
        window.localStorage.setItem('help_location', 'CHANGE_PLAN')
      }
      else {
        window.localStorage.setItem('help_location', 'RESTART')
      }
      $('.restart_date_change').find('.today_date').text('Today');

    });
    $(document).on('click', '.change_plan_div', function () {
      $('#change_plan .modal-header').show();
      if ($('#change_plan .nutri_link').is(':visible')) {
        $('#change_plan .modal-content,#change_plan .modal-body').addClass('padding0')
      }
      else {
        $('#change_plan .modal-content,#change_plan .modal-body').removeClass('padding0')
      }
      localStorage.removeItem('nutrisystem_stage');
      $('.change_image').attr('src', '/assets/images/change_plan.png');
      $('.change_plan_image').attr('src', '/assets/images/change_plan.png');
      $('.change_hdr,.change_plan_link .change_plan_title_text').text(window.keystrings['com.numi.change_plan_title']);
      $('.change_plan_message_text').html(window.keystrings['com.numi.change_plan_popup_desc']);
      $('.choose_plan_btn').text(window.keystrings['com.numi.change_plan_title']);
      $('.change_plan').show();
      $('.change_plan_link,.confirm_goal_weight,.plan_popup,.success_phase,.turbo_phase_option,.low_blood_sugar').hide();
      $('.modal-body').removeClass('calender_fix');
      this.setState({
        restartDate: new Date()
      });
      $('.show_start_weight').empty();
      $('.restart_date_change').find('.today_date').text('Today');
    }.bind(this));
    $('.low_blood_sugar label').click(function () {
      $('.low_blood_sugar label').css('opacity', '0.4');
      $(this).css('opacity', '1');
      $('.low_blood_sugar button').removeClass('btn disabled').removeAttr('disabled')
    });

    $(document).on('change', '#phase_option', function () {
      $('#change_plan .modal-content,#change_plan .modal-body').removeClass('padding0');
      localStorage.removeItem('restart_plan_phase_id');
      $('.hide_restart_date').hide();
      $('.turbo_phase_option').hide();
      $('.modal-body').removeClass('calender_fix');
      localStorage.setItem('first_week_turbo_no', 'false')
      localStorage.removeItem('no_selected');
      localStorage.removeItem('isSmartAdaptPhase');
      $('#change_phase').modal('show');
      $('#change_plan .modal-header').show();
      window.localStorage.setItem('phase_option_value', true);
      $('.current_value').text(window.localStorage.getItem('select_curr_phase_value'))
      $('.new_value').text($('#phase_option option:selected').text());
      window.localStorage.setItem('nutrisystem_stage', $('select#phase_option option:selected').attr('name'));
    });

    $(document).on('click', '.change_phase_option', function (e) {
      e.stopImmediatePropagation();
      var isSmartAdaptPhase = ($('select#phase_option option:selected').attr('show_questionnaire') === 'true');
      if (isSmartAdaptPhase) {
        $('#change_phase').modal('hide');
        localStorage.setItem('isSmartAdaptPhase', isSmartAdaptPhase);
        $('.text-green-darker').find('img').each(function () {
          if ($(this).parent().hasClass('active')) {
            $(this).attr('src', $(this).attr('active_image'));
            $(this).parent().css('opacity', 1).addClass('active');
          }
          else {
            $(this).attr('src', $(this).attr('normal_image'));
            $(this).parent().css('opacity', 0.3).removeClass('active');
          }
        });
        $('#activityGoalOptions .row > div').hide();
        $('#activityGoalOptions .row > div:eq(0)').show();
        $('#activity_question_options').modal('show');
        setTimeout(function () {
          $('body').addClass('modal-open');
        }, 1200)
      }
      else {

        localStorage.removeItem('isSmartAdaptPhase');
        $('#change_phase').modal('hide');

        this.getTransitionDeterminant(window.localStorage.getItem('get_key'), window.localStorage.getItem('get_id'))
      }

    }.bind(this));

    $(document).on('change', '#plan_option_values', function (e) {
      e.stopImmediatePropagation();
      $('.turbo_phase_option').hide();
      $('.modal-body').removeClass('calender_fix');
      localStorage.setItem('first_week_turbo_no', 'false')
      localStorage.removeItem('no_selected');
      localStorage.removeItem('isSmartAdaptPhase');
      window.localStorage.setItem('plan_option_value', true);
      $('#change_phase').modal('hide');
      window.localStorage.setItem('option_key', $('.plan_option_values').attr('id'));
      window.localStorage.setItem('option_value', $('#plan_option_values option:selected').text());
      this.getTransitionDeterminant(window.localStorage.getItem('get_key'), window.localStorage.getItem('get_id'))

    }.bind(this))

    $(document).on('change', '#phase_option_values', function (e) {
      e.stopImmediatePropagation();
      $('#change_phase').modal('hide');
      window.localStorage.setItem('option_key', $('.phase_option_values').attr('id'));
      window.localStorage.setItem('option_value', $('#phase_option_values option:selected').text());
      this.getTransitionDeterminant(window.localStorage.getItem('get_key'), window.localStorage.getItem('get_id'))
    }.bind(this))

    /*keystrings*/
    /*$(".about_nutri_personalized_plan_text").html(window.keystrings['com.numi.ecom.about_nutri_personalized_plan_text']);*/
    $(".change_other_plan_txt").text(window.keystrings['com.numi.change_other_plan_txt']);
    $('.link_account_btn').text(window.keystrings['com.numi.link_nutrisystem_account_for_personalized_plan']);
  }

  clickYes = (e) => {
    this.setState({
      turbo_takeoff: true, disable_btn: '', disable: ''
    });
    $('.toggle_date').show();
    $('#turbo_button').removeClass('btn disable').removeAttr('disabled');
  }

  clickNo = (e) => {
    this.setState({
      turbo_takeoff: false, disable_btn: '', disable: ''
    });

    $('#turbo_button').removeClass('btn disable').removeAttr('disabled');
  }

  applyCipdApi = () => {
    $("#change_plan").modal('hide');
    $("#activity_question_options").modal('show');
    $('.buffer').show();
    const options = {
      url: window.API_URL + 'v1/nutrisystem/apply_cipd',
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };
    axios(options)
      .then(response => {
        sessionStorage.setItem('cipd_range', response.data.CIPD_RANGE);
        this.setState({
          final_cipd_value: response.data.cipd_display
        });
        window.screenLength = 0;
        window.activityArray = [];
        const smart_week_new = {
          url: window.API_URL + "v1/nutrisystem/smart_week",
          method: 'GET',
          credentials: 'same-origin',
          headers: Header.setAuthHeaders(),
        };
        axios(smart_week_new)
          .then(response => {
            Header.stopLoader();
            localStorage.setItem('smart_week_setup_screen', response.data.smart_week.show_smart_week);
            if (response.data.smart_week.show_smart_week === true && localStorage.getItem("smartAdapt") === "true") {
              setTimeout(() => {
                $('.modal-content').removeClass('buffer_bg');
                $('.buffer').hide();
                $('.calculation').hide();
                $('#change_smartweek_setup').modal('show');
                $('#activity_question_options').hide();
                $("#newCalGoal").hide();
                $("#step0").show();
                $("#step1").hide();
                $("#step2").hide();
                $("#step3").hide();
                $("#backToPrevStep1").removeClass("d-none")
                $("#backToPrevStep12").addClass("d-none")
              }, 5000);
            } else {
              setTimeout(() => {
                $('.modal-content').removeClass('buffer_bg');
                $('.buffer').hide();
                $('.calculation').show();
              }, 5000);
            }
          }).catch(error => {
            Header.stopLoader();
            Header.apiErrors(error);
            Sentry.captureException(error);
          });


      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });
  }

  callSmartAdapt = () => {
    Header.startLoader();
    const options = {
      url: window.API_URL + 'v1/nutrisystem/apply_smart_adapt',
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };
    axios(options)
      .then(response => {
        Header.stopLoader();
        if (response.data.smart_phase === true) {
          document.querySelector('.final_cipd_value').innerHTML = response.data.cipd_display;
          $('#change_plan .modal-header').hide();
          $('.ques_buffer').show();
          $('.phase_option,#personalize_div').hide();
          if (localStorage.getItem('help_location') === "RESTART") {
            localStorage.setItem('smartAdaptEnabled', 'Restart')
          }
          else if (localStorage.getItem('help_location') === "CHANGE_PLAN") {
            localStorage.setItem('smartAdaptEnabled', 'Change Plan')
          }
          var evt = document.createEvent("CustomEvent");
          evt.initCustomEvent('SmartAdaptEnabled', true, false, false);
          document.dispatchEvent(evt);
          const smart_week_new = {
            url: window.API_URL + "v1/nutrisystem/smart_week",
            method: 'GET',
            credentials: 'same-origin',
            headers: Header.setAuthHeaders(),
          };
          axios(smart_week_new)
            .then(response => {
              Header.stopLoader();
              localStorage.setItem('smart_week_setup_screen', response.data.smart_week.show_smart_week);
              if (response.data.smart_week.show_smart_week === true && localStorage.getItem("smartAdapt") === "true" && window.localStorage.getItem('transition_type') === 'PLAN_CHANGE' && (document.URL.indexOf('/plan_settings') > -1)) {
                setTimeout(() => {
                  $('#change_plan').hide();
                  $('.ques_buffer').hide();
                  $('.calculation').hide();
                  $('#change_smartweek_setup').modal('show');
                  $('#activity_question_options').hide();
                  $("#newCalGoal").hide();
                  $("#step0").show();
                  $("#step1").hide();
                  $("#step2").hide();
                  $("#step3").hide();
                  $("#backToPrevStep4").removeClass("d-none")
                  $("#backToPrevStep12").addClass("d-none")
                }, 5000);
              } else {
                setTimeout(() => {
                  $('.ques_buffer').hide();
                  $('.ques_calculation').show();
                }, 5000);
              }
            })
        }
        else {
          $('.modal').modal('hide');
          $('body').removeClass('modal-open');
          $('.modal-backdrop').remove();
          localStorage.removeItem('option_key');
          localStorage.removeItem('option_value');
          this.props.history.push('/journal');
        }
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });
  }

  getTransitionDeterminant = (get_key, get_id) => {
    Header.startLoader();
    if ($('.plan_popup, .change_plan_link').is(':visible')) {
      window.localStorage.setItem('transition_type', 'PLAN_CHANGE');
    }
    else {
      if (localStorage.getItem('transition_type_nothanks') === 'true') {
        window.localStorage.setItem('transition_type', 'PLAN_CHANGE');
        localStorage.setItem('transition_type_nothanks', false);
      }
      else {
        window.localStorage.setItem('transition_type', 'PHASE_CHANGE');
      }
    }

    var to_option_key = window.localStorage.getItem('option_key');
    var to_option_value = window.localStorage.getItem('option_value');

    var current_option_value = localStorage.getItem("current_activity_level");

    window.localStorage.setItem('get_key', get_key);
    window.localStorage.setItem('get_id', get_id);

    var evt = document.createEvent("CustomEvent");
    evt.initCustomEvent('PlanStatusChanged', true, false, false);
    document.dispatchEvent(evt);

    var transition_type = window.localStorage.getItem('transition_type');
    var to_phase_id = window.localStorage.getItem('restart_plan_phase_id') || $('#phase_option option:selected').attr('id');

    const options = {
      url: window.API_URL + "v1/transition_determinants?to_plan_key=" + get_key + "&to_phase_id=" + to_phase_id + "&transition_type=" + transition_type + "&to_option_key=" + to_option_key + "&to_option_value=" + to_option_value,
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        Header.stopLoader();
        if (response && response.data) {
          localStorage.removeItem('restart_plan_phase_id');
          $('.change_plan,.change_plan_link,.confirm_goal_weight,.plan_popup,.success_phase,.turbo_phase_option,.low_blood_sugar').hide();
          $('.modal-body').removeClass('calender_fix');
          if (response.data.transition_determinant.next_determinant !== null) {
            setTimeout(function () {
              if (!$('#change_plan').is(':visible') && determinant !== "CIPD_RANGE") {
                $('#change_plan').modal('show');
              }
            }, 700);
            var determinant = response.data.transition_determinant.next_determinant;
            if (response.data.transition_determinant.misc_data.is_qvc === false) {
              $('.week_one_txt').html(window.keystrings['com.numi.first_week_on_nutri']);
            } else if (response.data.transition_determinant.misc_data.is_qvc === true) {
              $('.week_one_txt').html(window.keystrings['com.numi.first_week_qvc']);
            } else {
              $('.week_one_txt').html(window.keystrings['com.numi.first_week_qvc']);
            }
            this.setState({
              transData: response.data
            })


            localStorage.setItem('next_determinant', determinant);
            if ((localStorage.getItem('smartAdapt') === 'true') && (transition_type === 'PLAN_CHANGE')) {
              $('.category_list').hide();
              $('#personalize_div,.change_plan_link').show();
            }
            else {


              $('#change_plan .modal-header').show();
              if (determinant === "LOW_BLOOD_SUGAR") {
                $('.confirm_goal_weight,.plan_popup,.success_phase,.turbo_phase_option').hide();
                $('.modal-body').removeClass('calender_fix');
                $('.low_blood_sugar').show();
              }



              else if (determinant === "GOAL_WEIGHT") {
                $('.confirm_goal_weight,.plan_popup,.success_phase,.turbo_phase_option,.low_blood_sugar').hide();
                $('.modal-body').removeClass('calender_fix');
                $('.confirm_goal_weight').show();
                $('.modal-body').addClass('calender_fix');
                $('.confirm_goal_weight h3').text(window.keystrings['com.numi.goal_weight_label']);
                //$('.confirm_goal_weight h6').text('Phase Options');
                $('.confirm_goal_weight h6').text(window.keystrings['com.numi.journal.stage_options']);
                $('.confirm_goal_weight button').text(window.keystrings['com.numi.continue']);
              }

              else if (determinant === "ACTIVITY_LEVEL") {
                $('.plan_popup').hide();
                $('.success_phase').show();
                $('#change_plan .success_phase').attr('id', determinant);
                $('#less_than_60').val('LESS_THAN_60_MINUTES');
                $('#equal_60').val('60_MINUTES');
                $('#greater_than_60').val('GREATER_THAN_60_MINUTES');

                // $('.turbo_phase_option').hide();
                // localStorage.setItem('first_week_turbo_no','false')
                // localStorage.removeItem('no_selected');

                $('#change_plan #' + determinant).find('input').each(function () {
                  if (response.data.transition_determinant.default !== '') {
                    if (current_option_value != '') {
                      if ($(this).val() === current_option_value) {
                        $(this).next().css('opacity', 1);
                        $(this).prop('checked', true);
                      }
                      else {
                        $(this).next().css('opacity', '0.4');
                        $(this).prop('checked', false);
                      }
                    } else {
                      if ($(this).val() === response.data.transition_determinant.default) {
                        $(this).next().css('opacity', 1);
                        $(this).prop('checked', true);
                      }
                      else {
                        $(this).next().css('opacity', '0.4');
                        $(this).prop('checked', false);
                      }
                    }
                  } else {
                    if (current_option_value != '') {
                      if ($(this).val() === current_option_value) {
                        $(this).next().css('opacity', 1);
                        $(this).prop('checked', true);
                      }
                      else {
                        $(this).next().css('opacity', '0.4');
                        $(this).prop('checked', false);
                      }
                    }
                  }
                });

              }
              else if (determinant === "TURBO_TAKEOFF") {
                $('.confirm_goal_weight,.plan_popup,.success_phase').hide();
                $('.modal-body').removeClass('calender_fix');
                $('.turbo_phase_option').attr('id', determinant);
                $('.turbo_phase_option').show();
                $('.modal-body').addClass('calender_fix');
                $('.first_week_text').html(response.data.transition_determinant.misc_data.is_first_week_turbo.replace('FreshStart', ''));
                $('.turbo_takeoff_ends_text').text(response.data.transition_determinant.misc_data.turbo_takeoff_ends);

              }
              else if (determinant === "CIPD_RANGE") {
                setTimeout(function () {
                  $('#change_plan').modal('hide');
                  $('#activity_questions .modal-header').show();
                  $('.text-green-darker').find('img').each(function () {
                    if ($(this).parent().hasClass('active')) {
                      $(this).attr('src', $(this).attr('active_image'));
                      $(this).parent().css('opacity', 1).addClass('active');
                    }
                    else {
                      $(this).attr('src', $(this).attr('normal_image'));
                      $(this).parent().css('opacity', 0.3).removeClass('active');
                    }
                  });
                  $('#activityGoal .row > div').hide();
                  $('#activityGoal .row > div:eq(0)').show();
                  $('.calculation,.buffer').hide();
                  $('#activity_questions').modal('show');
                  localStorage.removeItem('nutrisystem_stage');
                }, 800);
                setTimeout(function () {
                  $('body').addClass('modal-open');
                }, 1200)
              }

            }

          }
          else {
            this.setState({
              transData: response.data
            })
            if (localStorage.getItem('smartAdapt') === 'true' && transition_type === 'PLAN_CHANGE') {
              $('.category_list').hide();
              $('#personalize_div,.change_plan_link').show();
            }
            else if (localStorage.getItem('isSmartAdaptPhase') === "true") {
              localStorage.removeItem('isSmartAdaptPhase');
              this.callCheatDaysAPI()
              this.applyCipdApi();
            }
            else {
              $('.modal').modal('hide');
              $('body').removeClass('modal-open');
              $('.modal-backdrop').remove();
              localStorage.removeItem('option_key');
              localStorage.removeItem('option_value');
              this.props.history.push('/journal');
            }
          }
        }
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });
  }

  updateTransition = () => {
    if ((localStorage.getItem('no_selected') === "true") && (localStorage.getItem('show_questionnaire_for_phase_2') === "true") && (document.URL.indexOf('/plan_settings') > -1)) {
      $('#change_plan').modal('hide');
      $('#activity_question_options .text-green-darker').find('img').each(function () {
        if ($(this).parent().hasClass('active')) {
          $(this).attr('src', $(this).attr('active_image'));
          $(this).parent().css('opacity', 1).addClass('active');
        }
        else {
          $(this).attr('src', $(this).attr('normal_image'));
          $(this).parent().css('opacity', 0.3).removeClass('active');
        }
      });
      $('#activityGoalOptions .row > div').hide();
      $('#activityGoalOptions .row > div:eq(0)').show();
      $('#activity_question_options').modal('show');
      setTimeout(function () {
        $('body').addClass('modal-open');
      }, 1200)
    }
    else {
      var _this = this;
      var value;
      var transition_determinant_params;
      if ($('.turbo_phase_option').is(':visible') || (localStorage.getItem('first_week_turbo_no') === "true")) {
        value = $('.turbo_phase_option').find('input[type="radio"]:checked').attr('class');
        transition_determinant_params = { "transition_determinant": { "json_detail": { "TURBO_TAKEOFF": value, "END_DATE": moment($('.turbo_phase_option .react-datepicker__input-container input').val()).format('YYYY-MM-DD') } } };
      }
      else {
        if ($('.success_phase').is(':visible')) {
          value = $('.success_phase').find('input[type="radio"]:checked').val();
          transition_determinant_params = { "transition_determinant": { "json_detail": { "ACTIVITY_LEVEL": value } } };
        }
        else {
          if ($('.low_blood_sugar').is(':visible')) {
            value = $('.low_blood_sugar').find('input[type="radio"]:checked').attr('class');
            transition_determinant_params = { "transition_determinant": { "json_detail": { "LOW_BLOOD_SUGAR": value } } };
          }
          else if ($('#activity_questions').is(':visible')) {
            transition_determinant_params = { "transition_determinant": { "json_detail": { "CIPD_RANGE": sessionStorage.getItem('cipd_range'), "GOAL_WEIGHT": this.state.goal_value } } };
          }

          if ($('.confirm_goal_weight').is(':visible')) {

            transition_determinant_params = { "transition_determinant": { "json_detail": { "GOAL_WEIGHT": this.state.goal_value, "CURRENT_WEIGHT": this.state.curr_value } } };
          }
        }
      }



      Header.startLoader();

      const options = {
        url: window.API_URL + "v1/transition_determinants",
        method: 'POST',
        credentials: 'same-origin',
        headers: Header.setAuthHeaders(),
        data: transition_determinant_params
      };
      axios(options)
        .then(response => {
          Header.stopLoader();
          window.localStorage.setItem('progres_current_weight', this.state.curr_value);
          window.localStorage.setItem('progres_goal', this.state.goal_value);
          if (response && response.data) {
            if ((localStorage.getItem('smartAdapt') === 'true') && (localStorage.getItem('transition_type') === 'PLAN_CHANGE')) {
              this.callCheatDaysAPI();
            }
            if (response.data.transition_determinant.next_determinant !== null) {
              // this.getTransitionDeterminant(window.localStorage.getItem('get_key'),window.localStorage.getItem('get_id'))
              setTimeout(function () {
                if (!$('#change_plan').is(':visible') && determinant !== "CIPD_RANGE") {
                  $('#change_plan').modal('show');
                }
              }, 700);

              var determinant = response.data.transition_determinant.next_determinant;
              if (response.data.transition_determinant.misc_data.is_qvc === false) {
                $('.week_one_txt').html(window.keystrings['com.numi.first_week_on_nutri']);
              } else if (response.data.transition_determinant.misc_data.is_qvc === true) {
                $('.week_one_txt').html(window.keystrings['com.numi.first_week_qvc']);
              } else {
                $('.week_one_txt').html(window.keystrings['com.numi.first_week_qvc']);
              }
              if (determinant === "LOW_BLOOD_SUGAR") {
                $('.confirm_goal_weight,.plan_popup,.success_phase,.turbo_phase_option').hide();
                $('.modal-body').removeClass('calender_fix');
                $('.low_blood_sugar').show();
              }

              else if (determinant === "GOAL_WEIGHT") {
                $('.change_plan_link,.confirm_goal_weight,.plan_popup,.success_phase,.turbo_phase_option,.low_blood_sugar').hide();
                $('.modal-body').removeClass('calender_fix');
                $('.confirm_goal_weight').show();
                $('.modal-body').addClass('calender_fix');
                $('.confirm_goal_weight h3').text(window.keystrings['com.numi.goal_weight_label']);
                //$('.confirm_goal_weight h6').text('Phase Options');
                $('.confirm_goal_weight h6').text(window.keystrings['com.numi.journal.stage_options']);
                $('.confirm_goal_weight button').text(window.keystrings['com.numi.continue']);
              }

              else if (determinant === "ACTIVITY_LEVEL") {
                $('.change_plan_link,.plan_popup').hide();
                $('.success_phase').show();
                $('#change_plan .success_phase').attr('id', determinant);
                $('#less_than_60').val(response.data.transition_determinant.values[0]);
                $('#equal_60').val(response.data.transition_determinant.values[1]);
                $('#greater_than_60').val(response.data.transition_determinant.values[2]);
                $('#change_plan #' + determinant).find('input').each(function () {
                  if (response.data.transition_determinant.default !== '') {
                    if ($(this).val() === response.data.transition_determinant.default) {
                      $(this).next().css('opacity', 1);
                      $(this).prop('checked', true);
                    }
                    else {
                      $(this).next().css('opacity', '0.4');
                      $(this).prop('checked', false);
                    }
                  }
                });

              }
              else if (determinant === "TURBO_TAKEOFF") {
                $('.change_plan_link,.confirm_goal_weight,.plan_popup,.success_phase').hide();
                $('.modal-body').removeClass('calender_fix');
                $('.turbo_phase_option').attr('id', determinant);
                $('.turbo_phase_option').show();
                $('.modal-body').addClass('calender_fix');
                $('.first_week_text').html(response.data.transition_determinant.misc_data.is_first_week_turbo.replace('FreshStart', ''));
                $('.turbo_takeoff_ends_text').text(response.data.transition_determinant.misc_data.turbo_takeoff_ends);
              }
              else if (determinant === "CIPD_RANGE") {
                _this.child.activityApi();
                setTimeout(function () {
                  $('#change_plan,.modal').modal('hide');
                  $('#activity_questions .modal-header').show();
                  $('.text-green-darker').find('img').each(function () {
                    if ($(this).parent().hasClass('active')) {
                      $(this).attr('src', $(this).attr('active_image'));
                      $(this).parent().css('opacity', 1).addClass('active');
                    }
                    else {
                      $(this).attr('src', $(this).attr('normal_image'));
                      $(this).parent().css('opacity', 0.3).removeClass('active');
                    }
                  });
                  $('#activityGoal .row > div').hide();
                  $('#activityGoal .row > div:eq(0)').show();
                  $('.calculation,.buffer').hide();
                  $('#activity_questions').modal('show');

                  localStorage.removeItem('nutrisystem_stage');
                }, 800);
                setTimeout(function () {
                  $('body').addClass('modal-open');
                }, 1200)
              }
            }

            else {
              if ((localStorage.getItem('smartAdapt') === 'true') && (localStorage.getItem('transition_type') !== 'PHASE_CHANGE')) {
                this.callSmartAdapt();
              }
              else if ((localStorage.getItem('isSmartAdaptPhase') === "true" || localStorage.getItem('first_week_turbo_no') === "true") && (localStorage.getItem('show_questionnaire_for_phase_2') === "true" && document.URL.indexOf('/plan_settings') > -1)) {
                localStorage.removeItem('isSmartAdaptPhase');
                localStorage.removeItem('first_week_turbo_no');
                this.applyCipdApi();
              }
              else {
                $('.modal').modal('hide');
                $('body').removeClass('modal-open');
                $('.modal-backdrop').remove();
                localStorage.removeItem('option_key');
                localStorage.removeItem('option_value');
                this.props.history.push('/journal');
              }
            }
          }
        })
        .catch(error => {
          Header.stopLoader();
          $('.weight_error_msg').text('');
          if (error && error.response && error.response.data && error.response.data.error) {
            $('.weight_error_msg').text(error.response.data.error);
          }
          else if (error && error.response && error.response.status > 400) {
            $('.weight_error_msg').text(error.response.statusText);
          }
          else {
            alert(error.message ? error.message : 'Something went wrong!');
          }
          Sentry.captureException(error);
        });
    }
  }

  choosePlan = () => {


    if ($('.confirm_goal_weight button').text() !== "Continue") {

      var goal_weight = $("#goal_weight").val();
      var curr_weight = $('#curr_weight').val();
      var start_weight = $('#start_weight').val();
      var assigned_date = moment(parse($(".confirm_goal_weight .react-datepicker__input-container input").val())).format('YYYY-MM-DD');
      var confirm_weight_params = { "weight": { "plan_starting": start_weight, "current": curr_weight, "goal": goal_weight, "assigned_date": assigned_date, "plan_restart_weight": true } };
      Header.startLoader();

      const options = {
        url: window.API_URL + "v1/weights",
        method: 'POST',
        credentials: 'same-origin',
        headers: Header.setAuthHeaders(),
        data: confirm_weight_params
      };

      axios(options)
        .then(response => {
          localStorage.setItem('progres_current_weight', curr_weight);
          localStorage.setItem('progres_goal', goal_weight);
          if (start_weight !== undefined) {
            window.localStorage.setItem('plan_starting_weight', start_weight);
          }
          localStorage.setItem('plan_start_date', assigned_date);
          this.turboDate(assigned_date);
          Header.stopLoader();
          /* var personalization_plan = this.props.personalization_plan
          var nutrisystem_profile_linked = this.props.nutrisystem_profile_linked
         if(personalization_plan !== null && nutrisystem_profile_linked === true)
          {
            var plan_name = personalization_plan.name;
            var plan_key = personalization_plan.key;
            var plan_id = personalization_plan.id;
            $('.change_plan_link').show();
            $('.personalized_plan_btn').show();
            $('.link_account_btn').hide();
            $('.personalized_plan_btn').text('Choose Your '+plan_name);
            $('.personalized_plan_btn').attr("id", plan_id);
            $('.personalized_plan_btn').attr("name", plan_key);
            $('.change_plan_message_text_for_link').html(window.keystrings['com.numi.change_plan_message']);
            $('.change_other_plan_txt').html(window.keystrings['com.numi.choose_different_plan']);
          }
          else if(nutrisystem_profile_linked === false){
            $('.change_plan_link').show()
            $('.personalized_plan_btn').hide();
            $('.link_account_btn').show();
            $('.change_plan_message_text_for_link').html(window.keystrings['com.numi.change_plan_message_text_for_link']);
            $(".change_other_plan_txt").text(window.keystrings['com.numi.change_other_plan_txt']);
            
             if($('#change_plan .nutri_link').is(':visible'))
              {
                $('#change_plan .modal-content,#change_plan .modal-body').addClass('padding0')
              }
              else
              {
                $('#change_plan .modal-content,#change_plan .modal-body').removeClass('padding0')
              }
              setTimeout(()=>
                {
                  $('.slick-current').focus()
                },200)
          }
          else {
            $('.plan_popup').show();
          }
          */
          $('#change_plan .modal-header').hide();
          $('.change_plan_link').show();
          if ($('#change_plan .nutri_link').is(':visible')) {
            $('#change_plan .modal-content,#change_plan .modal-body').addClass('padding0')
          }
          else {
            $('#change_plan .modal-content,#change_plan .modal-body').removeClass('padding0')
          }

          var evt = document.createEvent("CustomEvent");
          evt.initCustomEvent('ChoosePlanPopup', true, false, false);
          document.dispatchEvent(evt);
          $('.confirm_goal_weight,.change_plan').hide();
          $('.modal-body').removeClass('calender_fix');
          setTimeout(() => {
            $('.slick-current').focus();
          }, 999);
        })
        .catch(error => {
          Header.stopLoader();
          $('.weight_error_msg').text('');
          if (error && error.response && error.response.data && error.response.data.error) {
            $('.weight_error_msg').text(error.response.data.error);
          }
          else if (error && error.response && error.response.status > 400) {
            $('.weight_error_msg').text(error.response.statusText);
          }
          else {
            alert(error.message ? error.message : 'Something went wrong!');
          }
          Sentry.captureException(error);
        });

    }
    else {
      this.updateTransition();
    }


  }


  turboDate = (restart_date) => {

    if (new Date(restart_date) > new Date()) {
      localStorage.setItem('turbo_start_date', new Date(restart_date).toISOString().slice(0, 10));
      this.setState({
        startDate: addDays(new Date(localStorage.getItem('turbo_start_date')), window.localStorage.getItem("TURBO_TAKEOFF_DAYS"))
      });
    }
    else {
      localStorage.setItem('turbo_start_date', new Date().toISOString().slice(0, 10));
      this.setState({
        startDate: addDays(new Date(), window.localStorage.getItem("TURBO_TAKEOFF_DAYS"))
      });
    }


    Header.changeDate(this.state.startDate);
  }

  submitPersonalizedPlan = () => {
    var plan_id = $(".personalized_plan_btn").attr("id");
    var plan_key = $(".personalized_plan_btn").attr("name");
    this.getTransitionDeterminant(plan_key, plan_id);
  }

  changeOtherPlanHandler = () => {
    $('.change_plan, .change_plan_link,.confirm_goal_weight,.success_phase,.turbo_phase_option,.low_blood_sugar').hide();
    $('.modal-body').removeClass('calender_fix');
    $(".plan_popup").show();
  }

  linkNutriAccountHandler = () => {
    var gtmLinkSuccess;
    window.localStorage.setItem("link_page_from_plan_settings", true);
    var choose_plan_btn_txt = $('.choose_plan_btn').text();
    if (choose_plan_btn_txt === "Change Plan") {
      gtmLinkSuccess = "Change Plan";
    }
    else if (choose_plan_btn_txt === "Restart Your Plan") {
      gtmLinkSuccess = "Restart Plan";
    }
    window.localStorage.setItem("gtmLinkSuccess", gtmLinkSuccess);
    $('.modal-backdrop').remove();
    $('body').removeClass('modal-open');
    this.props.history.push("/nutri_link");
  }
  showPlanHelp(value) {
    this.child.refs.callHelp.callHelpAPI();
  }
  cancelNutriLinkHandler = () => {
    $(".change_plan_link").hide();
    $(".confirm_goal_weight").show();
    $('.modal-body').addClass('calender_fix');
  }

  reloadChangePlan = () => {
    window.location.reload();
  }


  render() {
    var phase_plan_list = this.props.show_plan_list.map(function (option, i) {
      if (!option.personalization && !option.inactive) {
        return (
          <li className="cursor_pointer" onClick={() => this.getTransitionDeterminant(option.key, option.id)} key={i} value={option.selected} name={option.key} id={option.id}> {option.name}</li>
        )
      }
    }.bind(this));
    return (
      <div>
        <div className="modal fade" id="change_smartweek_setup" data-backdrop="static">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body pt-lg-5">
                <SmartWeekCheatDay from="plan_settings" reload={this.state.reloadcheatday} />
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="change_plan" data-backdrop="static">
          <input type="hidden" className="current_key" />
          <div className="modal-dialog">
            <div className="modal-content changeplanmodel">
              <div className="modal-header">
                <button type="button" className="close" onClick={this.reloadChangePlan} data-dismiss="modal" aria-label="Close"><img alt="" src="/assets/images/close_grey.png" /></button>
              </div>
              <div className="modal-body">


                <div className="change_plan">
                  <img alt="" src="/assets/images/change_plan.png" className="change_image" />
                  <p className="font26 color3 mt5 font700 change_hdr change_plan_title_text"></p>
                  <h6 className="mt change_txt change_plan_message_text">Change paln</h6>
                  <p className="mt6"><button className="choose_plan_btn change_plan_title_text"></button></p>
                  <p className="mt6" data-dismiss="modal"><button className="cancel_text"></button></p>
                </div>

                <div className="turbo_phase_option phase_option">

                  <div className="phase_inner">
                    <h6 className="stage-options-keys-string">Stage Options</h6>
                    <h3 className="next_determinant"><strong className="first_week_text"></strong></h3>
                    <p className="mt10p toggle_options">
                      <span className="yes" onClick={this.clickYes}>
                        <input className="true" type="radio" id="yes" checked={this.state.gender_radio === "yes"} onClick={this.radio_toggle} onChange={this.radio_toggle} />
                        <label htmlFor="yes" className="yes_text"></label>
                      </span>
                      <span className="no" onClick={this.clickNo}>
                        <input className="false" type="radio" id="no" checked={this.state.gender_radio === "no"} onClick={this.radio_toggle} onChange={this.radio_toggle} />
                        <label htmlFor="no" className="no_text"></label>
                      </span>
                    </p>
                    <div className="turbo_takeoff mt20px" >
                      <p className="week_one_txt font16"></p>
                      <div className="toggle_date" style={{ display: this.state.turbo_takeoff ? 'block' : 'none' }}>
                        <span className="turbo_take turbo_takeoff_ends_text"></span>
                        <div className="date_pick input-group date vlm width50" >
                          <DatePicker selected={parse(this.state.startDate)} onChange={this.handleChange} minDate={new Date()} maxDate={addDays(new Date(), 29)} />

                        </div>
                      </div>
                    </div>
                    <button disabled={this.state.disable} id="turbo_button" className={this.state.disable_btn + " mt10p"} onClick={this.updateTransition}>Continue</button>
                  </div>
                </div>


                <div className="confirm_goal_weight">
                  <div className="text-center">

                    <h6 className="setting_plan_restart_text"> &nbsp; <img alt="" src="/assets/images/education.png" onClick={() => this.showPlanHelp(localStorage.getItem('help_location'))} className="cursor_pointer" /></h6>
                    <h3 className="lets_do_this_text"> </h3>
                    <p className="weight_error_msg error text-center font14"></p>
                    <p className="show_start_weight"></p>
                    <p className="mt10">
                      <span className="left_sec profile_form_option_current_weight_text"></span> <span className="curr_weight"><input className="numeric" type="text" name="curr_value" id="curr_weight" value={this.state.curr_value} maxLength="6" onChange={this.inputCurrChange} /> lbs.</span>
                    </p>
                    <p>
                      <span className="left_sec profile_form_option_goal_weight_text"></span> <span className="goal_weight"><input className="numeric" type="text" name="goal_value" id="goal_weight" value={this.state.goal_value} maxLength="6" onChange={this.inputCurrChange} /> lbs.</span>
                    </p>

                    <p className="hide_restart_date planchangedate">
                      <span className="left_sec">Start Date</span> <span className="goal_weight"><div className="date_pick input-group date vlm restart_date_change" >
                        <DatePicker selected={this.state.restartDate} onChange={this.restartChange} minDate={subDays(new Date(), 29)} maxDate={addDays(new Date(), 29)} />
                      </div></span>
                    </p>
                    <button onClick={this.choosePlan} className="restart_button_text mt20px mb5"></button>
                  </div>
                </div>

                <div className="plan_popup">
                  <h3 className="choose_hdr mt5 choose_plan_title_text">Choose Nutrisystem Plan</h3>
                  <ul className="plan_setting_popup">
                    {phase_plan_list}
                  </ul>
                </div>

                <div className="change_plan_link">
                  <PersonalizePlanMode callTrans={() => this.updateTransition()} callSmartAdapt={() => this.callSmartAdapt()} gettransData={this.state.transData} callTransitionDeterminant={() => this.getTransitionDeterminant(localStorage.getItem('plan_name'), localStorage.getItem('plan_id'))} />
                  <Carousel categoryContent={this.state.categories} callDeterminant={() => this.getTransitionDeterminant(localStorage.getItem('plan_name'), localStorage.getItem('plan_id'))} />
                </div>

                <div className="success_phase get_radio">

                  <div className="text-center">
                    <h6 className="stage-options-keys-string"> </h6>
                    <h2 className="next_determinant what_is_activity_level_text"> </h2>
                    <p className="less_than_60">
                      <input className="" type="radio" id="less_than_60" checked={this.state.success_phase_radio === "less_than_60"} onChange={this.radio_toggle} />
                      <label htmlFor="less_than_60" className="activity_level_1_text">  </label>
                    </p>
                    <p className="equal_60">
                      <input className="" type="radio" id="equal_60" checked={this.state.success_phase_radio === "equal_60"} onChange={this.radio_toggle} />
                      <label htmlFor="equal_60" className="activity_level_2_text"></label>
                    </p>
                    <p className="greater_than_60">
                      <input className="" type="radio" id="greater_than_60" checked={this.state.success_phase_radio === "greater_than_60"} onChange={this.radio_toggle} />
                      <label htmlFor="greater_than_60" className="activity_level_3_text"></label>
                    </p>
                    <button onClick={this.updateTransition} className="continue_text"></button>
                  </div>
                </div>

                <div className="low_blood_sugar">

                  <div className="text-center">
                    <h6 className="text-center stage-options-keys-string"> </h6>
                    <h3 className="next_determinant blood_sugar_label_text"> </h3>
                    <h6 className="next_determinant_desc blood_sugar_recommendation_text"> </h6>
                    <p className="meal_toggle_options">
                      <span className="blood_yes ">
                        <input className="true" type="radio" id="blood_yes" checked={this.state.gender_radio === "blood_yes"} onChange={this.radio_toggle} />
                        <label htmlFor="blood_yes" className="yes_text"></label>
                      </span>
                      <span className="blood_no">
                        <input className="false" type="radio" id="blood_no" checked={this.state.gender_radio === "blood_no"} onChange={this.radio_toggle} />
                        <label htmlFor="blood_no" className="no_text"></label>
                      </span>
                    </p>
                    <p className="mailto diabetic_query_desc_text"></p>
                    <p onClick={this.updateTransition}><button disabled={this.state.disable} className={this.state.disable_btn + " mt10p continue_text"}></button></p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <ActivityQuestions callUpdateTransition={this.updateTransition} callPreviousState={this.props.showPreviousPlan} onRef={ref => (this.child = ref)} />
        <ActivityQuestionOptions finalCipdValue={this.state.final_cipd_value} callUpdateTransition={this.updateTransition} getTransitionDeterminant={this.getTransitionDeterminant} callPreviousState={this.props.showPreviousPlan} onRef={ref => (this.child = ref)} />
      </div>

    )
  }
}


class ChangePhase extends React.Component {
  componentDidMount() {


    /*key strings*/
    $('.settings-current-stage-upcase-key-strings').each(function () {
      $(this).text(window.keystrings['com.numi.settings.upcase_current_stage'])
    });
    $('.settings-new-stage-upcase-key-strings').each(function () {
      $(this).text(window.keystrings['com.numi.settings.upcase_new_stage'])
    })
    $('.are_you_sure_text').text(window.keystrings['com.numi.are_you_sure'])
    $('.change_phase_message_1_text').prepend(window.keystrings['com.numi.setting.change_phase_message_1'])
    // $('.change_phase_message_1_text span:last').prepend(window.keystrings['com.numi.setting.change_phase_message_2'])
    $('.change_to_text').prepend(window.keystrings['com.numi.setting.change_to'])
    $('.stay_on_text').prepend(window.keystrings['com.numi.setting.stay_on'])
    /*key strings*/
  }

  render() {
    return (

      <div className="modal fade" id="change_phase" data-backdrop="static">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" onClick={this.props.showPreviousPlan} className="close change_phase_close" data-dismiss="modal" aria-label="Close"><img alt="" src="/assets/images/close_grey.png" /></button>
            </div>
            <div className="modal-body width80">
              <img src="/assets/images/transtion_manual.png" alt="" />
              <p className="font26 color3 mt5 font700 are_you_sure_text"></p>
              <h6 className="mt color6 change_phase_message_1_text"> <span className="current_value"></span>. Are you sure you want to change to <span className="new_value"></span>?</h6>
              <p className="mt6"><button className="width100 change_phase_option change_to_text"><span className="new_value"></span></button></p>
              <p className="mt2" onClick={this.props.showPreviousPlan} data-dismiss="modal"><button className="width100 change_phase_btn stay_on_text"><span className="current_value"></span></button></p>
            </div>
          </div>
        </div>
      </div>

    )
  }
}

export default withRouter(PlanSettings);