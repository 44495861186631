import React from 'react';
import moment from 'moment';
import Header from "../header.js";
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import $ from 'jquery';
import * as Sentry from "@sentry/react";

class FoodRecipeFilter extends React.Component {

	constructor(props) {
		super(props)

		this.state = {
			initialItems: [],
			recent_items: [], show_search: false, toggle_seacrh_icon: true, close_white: false, list_group: false, search_display: false, search_icon: false, recent_search: false, seachValue: '', searchinputValue: '', search_results: {}, recent_foods: [], suggested_foods: [], suggested: false
		};
		this.filterList = this.filterList.bind(this);
		this.showSearchRecipe = this.showSearchRecipe.bind(this);
		this.closeWhite = this.closeWhite.bind(this);
		this.seacrhFocus = this.seacrhFocus.bind(this);
		this.recentSearch = this.recentSearch.bind(this);
		this.callreplacedInput = this.callreplacedInput.bind(this);
		this.autoComplete = this.autoComplete.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);
		window.clickOnRecipesearch = false;
	}
	handleInputChange(event) {

		this.setState({
			searchinputValue: event.target.value
		});
	}

	showSearchRecipe(e) {
		this.setState({
			show_search: true,
			toggle_seacrh_icon: false,
			close_white: true,
			search_display: !this.state.search_display,
			search_icon: true,
			searchinputValue: ''
		});
	}

	closeWhite(e) {
		e.preventDefault();
		$('.search_empty').hide();
		this.setState({
			show_search: false,
			toggle_seacrh_icon: true,
			close_white: false,
			list_group: false,
			suggested: false,
			search_display: !this.state.search_display,
			search_icon: false
		});
		document.getElementsByClassName('activity_empty')[0].style.display = "none";
		$('.list-group').hide();
		if ($('.food_options.recipe_list li').length > 0) {
			$('.food_options.recipe_list').show();
			$('.recipe_container').hide();
		} else {
			$('.food_options.recipe_list').hide();
			$('.recipe_container').show();
		}

	}

	seacrhFocus() {
		this.setState({ list_group: !this.state.list_group, recent_search: true, suggested: false })
	}


	filterList(event) {

		var updatedList = this.state.initialItems;
		if (updatedList) {
			updatedList = updatedList.filter(function (item) {
				return item.toLowerCase().search(
					event.target.value.toLowerCase()) !== -1;
			});
		}

		this.setState({ items: updatedList, searchinputValue: event.target.value });
		this.recentSearch();

	}

	autoComplete() {

		var term = $(".food_search_input").val();
		window.localStorage.setItem('searched_term', term);
		Header.startLoader()
		var suggested_url = window.API_URL + "v1/consumables/autocomplete?term=" + encodeURI(term) + "&per_page=5"
		const options = {
			url: suggested_url,
			method: 'GET',
			credentials: 'same-origin',
			headers: Header.setAuthHeaders(),
		};

		axios(options)
			.then(response => {
				Header.stopLoader();
				if (response && response.data) {
					if (response.data.results.consumables.length > 0) {
						var suggested_array = $.map(response.data.results.consumables, function (value, index) {
							return [value];
						});


						this.setState({ suggested: true, recent_search: true, suggested_foods: suggested_array });
						$('.search_empty').hide();
						$('.list-group').show();
						var evt = document.createEvent("CustomEvent");
						evt.initCustomEvent('RecipeRecentSearch', true, false, false);
						document.dispatchEvent(evt);
					} else {
						$('.activity_empty,.serach_on_keyup').show();
						$('.search_empty').hide();
						if ($('.search_empty').is(':visible') || $('.serach_on_keyup').is(':visible')) {
							$('.food_options.recipe_list,.recipe_container,.list-group').hide();
						} else {
							$('.food_options.recipe_list,.recipe_container,.list-group').show();

						}
						$('.show_more').hide();
					}
				}
			})
			.catch(error => {
				Header.stopLoader();
				Header.apiErrors(error);
				Sentry.captureException(error);
			});

		if (window.localStorage.getItem('searched_term') !== '' && window.localStorage.getItem('searched_term') !== undefined && window.localStorage.getItem('searched_term') !== null) {
			$('.food_not_found b').text('"' + window.localStorage.getItem('searched_term') + '"');
		} else {
			$('.food_not_found b').text('');
		}
		$('.activity_empty').hide();

	}


	/* load recent searches*/
	recentSearch() {
		Header.startLoader();

		const options = {
			url: window.API_URL + "v1/consumables/recent_searches?per_page=5",
			method: 'GET',
			credentials: 'same-origin',
			headers: Header.setAuthHeaders(),
		};

		axios(options)
			.then(response => {
				Header.stopLoader();
				if (response && response.data) {
					response.data.recent_searches.forEach(function (item, index, object) {
						if (item === 'undefined') {
							object.splice(index, 1);
						}
					});
					if ($('.food_search_input').is(':visible') && ($(".food_search_input").val() !== '')) {

						$('.recent_list_ul').empty();
						$('.recent_list_ul').append('<li class="list-group-item recent_search recipe-recent-search-ks" style="display: block;">Recent Searches</li>');
						$('.recipe-recent-search-ks').text(window.keystrings['com.numi.recent_searches']);
						this.setState({ recent_foods: response.data.recent_searches });
						for (var pp = 0; pp < this.state.recent_foods.length; pp++) {
							$('.recent_list_ul').append('<li class="list-group-item" data-category=' + this.state.recent_foods[pp] + '  >' + this.state.recent_foods[pp] + '</li>');
						}
						if (this.state.recent_foods[0] === 'undefined') {
							$('.recent_list_ul').hide();
						}
						else {
							$('.recent_list_ul,.list-group').show();
						}
					}
				}
			})
			.catch(error => {
				Header.stopLoader();
				Header.apiErrors(error);
				Sentry.captureException(error);
			});

		this.autoComplete();
	}

	callreplacedInput(change_input) {
		this.setState({ searchinputValue: change_input });
		// window.localStorage.setItem("searched_term", change_input)
	}

	handleKeyPress(target) {
		if (target.charCode === 13) {
			this.searchClick();
			$('img#recipe-search-icon').click();
			[1000, 3000, 5000].forEach(function (sleep_time) {
				setTimeout(function () {
					$('.list-group').hide();
					if ($('.search_empty').is(':visible') && $('.activity_empty').is(':visible')) {
						$('.activity_empty').hide();
					}
				}, sleep_time)
			})
		}
	}

	searchClick() {
		window.clickOnRecipesearch = true;
	}

	componentDidMount() {

		$(document).on('click', '.recipe_page .suggested_list .list-group-item', function (e) {
			var _this = this;
			window.localStorage.setItem('consumable_id', e.target.getAttribute('data-id'));
			window.localStorage.setItem('consumable_type', e.target.getAttribute('data-type'));
			if ($('.first_drop .dropdown_list').html() !== undefined) {
				window.localStorage.setItem('first_drop', $('.first_drop .dropdown_list').html());
				window.localStorage.setItem('second_drop', $('.second_drop .dropdown_list').html());
			}
			_this.props.history.push('/recipe_item')
		}.bind(this));


		$(document).on('click', '.recipe_page .search_icon,li.suggested_item,li.recent_item,.recent_list_ul li', function () {
			if ($('.food_search_input').val() === "") {
				this.setState({
					searchinputValue: ''

				});
			}

			this.setState({
				list_group: false,
				suggested: false

			});
			/* reset multiselect from top search */
			$('.recipe_multi_select_dropdown,.meal_txt_recipe .checkbox_select').hide();
			$('.multi_gray_arrow,.recipe_multiselect_txt,.select_meal_drop').show();
			var assigned_date = moment(window.localStorage.getItem('assigned_date')).format('YYYY-MM-DD');
			var search_term = $('.food_search_input').val();

			/*filter for auto complete serach*/
			var food_category_txt = $.trim($('.foodCategorySelected').text());
			var recipe_group_txt = $.trim($('.recipe_droplist').text());
			var food_category, recipe_group, calorie_min, calorie_max, page_number;
			if (food_category_txt !== "All Foods Categories") {
				food_category = $('.foodCategorySelected').attr('id');
			}
			else {
				food_category = "";
			}

			if (recipe_group_txt !== "All Recipes") {
				recipe_group = $('.recipe_droplist').attr('id');
			}
			else {
				recipe_group = "";
			}


			if ($('input[type="radio"][name="cal_min_range"]:checked ').length) {
				calorie_min = $('.cal_range_lft').find('input[type="radio"]:checked').val();

			}

			if ($('input[type="radio"][name="cal_max_range"]:checked ').length) {
				calorie_max = $('.cal_range_rht').find('input[type="radio"]:checked').val()

			}

			if (calorie_min === null || calorie_min === undefined || calorie_min === 'No Min') {
				calorie_min = "";
			}

			if (calorie_max === null || calorie_max === undefined || calorie_max === 'No Max') {
				calorie_max = "";
			}
			if (window.clickOnRecipesearch === true) {
				page_number = 1;
				window.localStorage.setItem('page_number', 1)
				window.clickOnRecipesearch = false;
			}
			else {
				page_number = parseInt(window.localStorage.getItem('page_number'));
			}
			/*End filter for auto complete serach*/

			//var search_url = API_URL+"v1/consumables?term="+search_term+"&show_recipe_only=true&recipe_groups="+ recipe_group +"&food_categories="+ food_category +"&assigned_date=" + assigned_date + "&calories_min="+calorie_min + "&calories_max="+calorie_max + "&page=" + page_number;
			var search_url = window.API_URL + "v1/consumables?show_recipe_only=true&recipe_groups=" + recipe_group + "&food_categories=" + food_category + "&assigned_date=" + assigned_date + "&calories_min=" + calorie_min + "&calories_max=" + calorie_max + "&page=" + page_number + "&term=" + encodeURI(search_term);

			Header.startLoader();
			const options = {
				url: search_url,
				method: 'GET',
				credentials: 'same-origin',
				headers: Header.setAuthHeaders(),
			};

			axios(options)
				.then(response => {
					Header.stopLoader();
					/* build new food list based on results*/
					if (response && response.data) {
						$('ul.food_options').empty();
						if (response.data.results.consumables.length > 0) {

							$('.search_empty,.recipe_list.recipe_group_list').hide();
							$('.food_options.recipe_list,.recipe_multi_select,.show_more').show();
							$('div.activity_empty').hide();

							var ns_food_tag;
							for (var k = 0; k < response.data.results.consumables.length; k++) {

								if (response.data.results.consumables[k].food_source === "Nutrisystem" || response.data.results.consumables[k].food_source === "leaf") {
									ns_food_tag = '<img src="/assets/images/ns_food_tag.png" class="leaf_image" />';
								} else {
									ns_food_tag = '<span class="leaf_image"></span>';
								}
								$('ul.food_options').append('<li class="meal_txt_recipe added_food"><span class="color6 item_click cursor_pointer first_span">' + response.data.results.consumables[k].food_name + ' <br/>' + ns_food_tag + ' &nbsp; <span class="font12 color8 vlm">' + response.data.results.consumables[k].calories + ' Cals</span><input type="hidden" class="consumable_id" value=' + response.data.results.consumables[k].consumable_id + '/><input type="hidden" value=' + response.data.results.consumables[k].consumable_type + ' class="consumable_type"/><img src="/assets/images/arrow_right_grey.png" class="pull-right mt-10 multi_gray_arrow"/></span><div class="checkbox_select pull-right" > <input class="checbox_normal" id="multi_check' + page_number + k + '" name="food_item"  type="checkbox"/> <label for="multi_check' + page_number + k + '"><span><i></i></span></label>    </div></li>');
								var b = document.getElementsByClassName("food_options");
								var c = b[0].lastElementChild;
								var last_element = c.getElementsByClassName('leaf_image');
								for (var pp3 = 0; pp3 < response.data.results.consumables[k].pills.length; pp3++) {

									$('<img width="25" height="13" class="food_list_images_icons" src=' + response.data.results.consumables[k].pills[pp3].url + '>').insertAfter(last_element);
								}


								if (response.data.results.next_page === null) {
									$(".show_more").hide();
								}
								else {
									$(".show_more").show();
								}
							}



						}
						/* for emoty search results*/
						else {
							$('.recipe_list.recipe_group_list').hide()
							$('.search_empty').show();
							$('.show_more,.recipe_multi_select,.show_more').hide();

						}
					}
				})
				.catch(error => {
					Header.stopLoader();
					Header.apiErrors(error);
					Sentry.captureException(error);
				});

			$('.food_click_not_found').find('b').text('"' + $('.food_search_input').val() + '"');
			/* type some text and select from recents then previously search txt appears issue*/
			$('.search_empty').find('b').text('"' + $('.food_search_input').val() + '"');
			$('.serach_on_keyup,.food_options.recipe_list').hide();


		}.bind(this));

		//remove recent item once click on recent item
		$(document).on('click', '.recent_list_ul .list-group-item', function () {
			$('.list-group').hide();
			$('.food_search_input').val($(this).text());
			/* first drop  second drop for back search */
			// window.localStorage.setItem('first_drop',$('.first_drop .dropdown_list').html());
			// window.localStorage.setItem('second_drop',$('.second_drop .dropdown_list').html());
		});
		// after clicking search icon closing recent and suggested list
		$(document).on('click', '#recipe-search-icon', function () {
			$('.list-group').hide();
		});

		/* key strings*/
		$('.recipe-search-placeholder-ks').attr('placeholder', window.keystrings['com.numi.search_food_drinks_etc']);
		$('.recipe-search-suggested-ks').text(window.keystrings['com.numi.suggested'])
		/*End key strings*/
	}

	render() {
		return (
			<div className="filter-list relative">
				<fieldset className="form-group">

					<input type="text" className="search_input food_search_input recipe-search-placeholder-ks" value={this.state.searchinputValue} onChange={this.filterList} onKeyUp={this.handleInputChange} onKeyPress={this.handleKeyPress} />
					<img alt="" src="/assets/images/search-white.png" className="search_icon" id="recipe-search-icon" onClick={this.searchClick.bind(this)} />


				</fieldset>
				<DropdownList recent_items_list={this.state.recent_foods} replaceInput={this.callreplacedInput} list_group={this.state.list_group} recent={this.state.recent_search} suggested_li={this.state.suggested} suggested_items_list={this.state.suggested_foods} />
			</div>
		);
	}
};

class DropdownList extends React.Component {
	render() {
		return (
			<div className="list-group">
				<ul ref="toggle_items" className="margin0 recent_list_ul" >

				</ul>

				<ul ref="toggle_items" className="margin0 suggested_list" style={{ display: this.props.suggested_li ? 'block' : 'none' }}>
					<li className="list-group-item recent_search recipe-search-suggested-ks"></li>
					{

						this.props.suggested_items_list.map(function (item_value) {
							return (

								<li className="list-group-item" data-id={item_value.consumable_id} data-type={item_value.consumable_type} onClick={() => this.props.replaceInput(item_value.food_name)} data-category={item_value.food_name} key={item_value.food_name} dangerouslySetInnerHTML={{ __html: (item_value.highlighted_name ? item_value.highlighted_name : item_value.food_name) }} />
							);
						}.bind(this))
					}

				</ul>
			</div>
		)
	}
};

export default withRouter(FoodRecipeFilter);

