
import React from 'react';
import Header from "../header.js";
import axios from 'axios';
import $ from 'jquery';
import * as Sentry from "@sentry/react";

class FilterSearch extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedTabId: '2', food_category_items: [], food_type_items: [], food_list_images: {}, food_list_images_icons: {}
    }

    this.callFoodtypeApi = this.callFoodtypeApi.bind(this);
    this.getFoodCategoryIcons = this.getFoodCategoryIcons.bind(this);

  }


  selectFoodType(item_txt, e) {
    window.localStorage.setItem('page_number', 1);
    localStorage.removeItem('search_from_journal');
    if (item_txt.food_category !== '' && item_txt.food_category !== null && item_txt.food_category !== undefined) {
      window.localStorage.setItem('food_categories', item_txt.food_category)
    } else {
      localStorage.removeItem('food_categories');
    }
    if (item_txt.food_type !== '' && item_txt.food_type !== null && item_txt.food_type !== undefined) {
      window.localStorage.setItem('food_type', item_txt.food_type)
    } else {
      localStorage.removeItem('food_type');
    }
    if (item_txt.meal_occasion_name !== '' && item_txt.meal_occasion_name !== null && item_txt.meal_occasion_name !== undefined) {
      window.localStorage.setItem('meal_occasion', item_txt.meal_occasion_name)
    } else {
      localStorage.removeItem('meal_occasion');
    }
    if ($('[href="#Nutrisystem"]').parent().hasClass('active') || $('[href="#All"]').parent().hasClass('active')) {
      this.props.callParentLogFood();
    } else {
      this.props.callParentFavFood();
    }
    if ($('.multiselect_page').length && !$('.multiselect_txt').is('visible')) {
      $('.multi_cancel,.multi_log').hide();
      $('.multiselect_txt').show();
    }
  }


  callFoodtypeApi() {
    Header.startLoader();
    const options = {
      url: window.API_URL + "v1/phase_templates/search_filters/assigned_date/" + window.localStorage.getItem('assigned_date'),
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        Header.stopLoader();
        if (window.localStorage.getItem('searched_term') !== '' && window.localStorage.getItem('searched_term') !== undefined && window.localStorage.getItem('searched_term') !== null) {
          $(".food_search_input").val(window.localStorage.getItem('searched_term'));
        }
        this.setState({ food_type_items: response.data.search_filters });

        if (window.localStorage.getItem('food_categories') !== '' && window.localStorage.getItem('food_categories') !== undefined && window.localStorage.getItem('food_categories') !== null) {
          for (var gg = 0; gg < $('.meal_names').length; gg++) {
            if (window.localStorage.getItem('meal_occasion_name') !== '' && window.localStorage.getItem('meal_occasion_name') !== undefined && window.localStorage.getItem('meal_occasion_name') !== null && window.localStorage.getItem('food_categories') === "NS_ENTREE") {
              if ($('.food_type_drop').find('li.meal_names:eq(' + gg + ')').find('.food_categories').val() === window.localStorage.getItem('food_categories') && $('.food_type_drop').find('li.meal_names:eq(' + gg + ')').find('.meal_occasion').val() === window.localStorage.getItem('meal_occasion_name')) {
                $('.food_type_drop').find('li.meal_names:eq(' + gg + ')').find('.fa-check').removeClass('hide');
                $('.food_type_drop').find('li.meal_names:eq(' + gg + ')').find('a').addClass('blue_color');
                break;
              }
            } else {
              $('.food_type_drop li').find('.fa-check').addClass('hide');
              if ($('.food_type_drop').find('li.meal_names:eq(' + gg + ')').find('.food_categories').val() === window.localStorage.getItem('food_categories')) {
                $('.food_type_drop').find('li.meal_names:eq(' + gg + ')').find('.fa-check').removeClass('hide');
                $('.food_type_drop').find('li.meal_names:eq(' + gg + ')').find('a').addClass('blue_color');
                break;
              }
            }

          }
          $('.food_type_drop').find('li.not_set').find('a').removeClass('blue_color');
          $('.food_type_drop').find('li.not_set').find('span').removeClass('fa-check');
        }
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });

  }

  getFoodCategoryIcons() {
    Header.startLoader();

    const options = {
      url: window.API_URL + "v1/phase_templates/food_categories/list_icons",
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        if (response && response.data) {
          window.food_list_images_icons = response.data
          this.setState({ food_list_images_icons: response.data });
        }
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });
  }


  componentDidMount() {
    this.getFoodCategoryIcons();
    this.callFoodtypeApi();

    $('.food_type_drop li:eq(0) a').addClass('recipe_drop_selected');
    $('.food_category_type li:eq(0) a').addClass('recipe_drop_selected');
    /* key strings*/
    $('.food-create-food-button-ks').text(window.keystrings['com.numi.create_food_title']);
    $('.food-filter-lable-ks').text(window.keystrings['com.numi.recipe.filter']);
    $('.food-log-food-title-ks').text(window.keystrings['com.numi.log_food_title']);

    $('.back_to span').text(window.keystrings['com.numi.back_to_journal']);
    //$('#create-diet_history input').attr('value',"Log It")
    $('.food-favorites-ks').text(window.keystrings['com.numi.favorites_title']);
    $('.food-recents-ks').text(window.keystrings['com.numi.recents_title']);
    $('.food-favorite-your-meal-ks').text(window.keystrings['com.numi.food.favorite_your_meal']);
    $('.food-favorite-msg-ks').text(window.keystrings['com.numi.food.favorite_msg']);
    $('.food-recent-msg-ks').text(window.keystrings['com.numi.recipe.recent_message'])
    $('.log-food-all-ks').text(window.keystrings['com.numi.log_food_all'])
    $('.nutrisystem-title-ks').text(window.keystrings['com.numi.nutrisystem_title'])
    /* End key strings*/


    $(document).on('click', '.not_set_class', function (e) {
      e.stopImmediatePropagation();
      $('.food_type_drop').find('li.meal_names').find('.fa-check').addClass('hide');
      $('.food_type_drop').find('li.meal_names').find('a').removeClass('blue_color');
      $('.food_type_drop').find('li.not_set').find('a').addClass('blue_color');
      $('.food_type_drop').find('li.not_set').find('span').removeClass('hide');
      $('.food_type_drop').find('li.not_set').find('span').addClass('fa-check');
      localStorage.removeItem('food_categories');
      localStorage.removeItem('food_type');
      localStorage.removeItem('meal_occasion');
      window.localStorage.setItem('page_number', 1);
      if ($('[href="#Nutrisystem"]').parent().hasClass('active') || $('[href="#All"]').parent().hasClass('active')) {
        this.props.callParentLogFood();
      } else if ($('[href="#favorite"]').parent().hasClass('active')) {
        this.props.callParentFavFood();
      }
    }.bind(this));

    $(document).on('click', '.meal_section_right .food_type_drop li', function () {
      $('.food_type_drop li .fa-check').addClass('hide');
      $(this).find('.fa-check').removeClass('hide');
    });

  }

  render() {
    var food_list_images_icons = this.state.food_list_images_icons;
    if (this.state.food_type_items !== undefined) {
      var food_type_options = this.state.food_type_items.map(function (option, i) {
        if (food_list_images_icons[option.food_category] !== undefined) {
          if (option.food_type == 'generic' && window.localStorage.getItem('from_addintional_log') == "true") {
            window.localStorage.setItem('page_number', 1);
            localStorage.removeItem('search_from_journal');
            if (option.food_category !== '' && option.food_category !== null && option.food_category !== undefined) {
              window.localStorage.setItem('food_categories', option.food_category)
            } else {
              localStorage.removeItem('food_categories');
            }
            if (option.food_type !== '' && option.food_type !== null && option.food_type !== undefined) {
              window.localStorage.setItem('food_type', option.food_type)
            } else {
              localStorage.removeItem('food_type');
            }
            if (option.meal_occasion_name !== '' && option.meal_occasion_name !== null && option.meal_occasion_name !== undefined) {
              window.localStorage.setItem('meal_occasion', option.meal_occasion_name)
            } else {
              localStorage.removeItem('meal_occasion');
            }
            if ($('[href="#Nutrisystem"]').parent().hasClass('active') || $('[href="#All"]').parent().hasClass('active')) {
              // this.props.callParentLogFood();
              return (

                <li key={i} className="meal_names" onClick={(e) => this.selectFoodType(option, e)}><span className="capitalize blue_color cursor_pointer"><span className={option.name} id={option.name.split(" ").join("").toUpperCase()}>{option.name}</span>&nbsp;<img alt="" width="25" height="13" src={food_list_images_icons[option.food_category]} className="log_pills_icons" /><img alt="" src="/assets/images/arrow_right_grey.png" className="pull-right right_arrow_img" /></span><span className="fa fa-check"></span><input type="hidden" className="food_type" value={option.food_type == null ? '0' : option.food_type} /><input type="hidden" className="food_categories" value={option.food_category == null ? '0' : option.food_category} /><input type="hidden" className="meal_occasion" value={option.meal_occasion_name == null ? '0' : option.meal_occasion_name} /></li>
              )
            } else {
              // this.props.callParentFavFood();
              return (

                <li key={i} className="meal_names" onClick={(e) => this.selectFoodType(option, e)}><span className="capitalize blue_color cursor_pointer"><span className={option.name} id={option.name.split(" ").join("").toUpperCase()}>{option.name}</span>&nbsp;<img alt="" width="25" height="13" src={food_list_images_icons[option.food_category]} className="log_pills_icons" /><img alt="" src="/assets/images/arrow_right_grey.png" className="pull-right right_arrow_img" /></span><span className="fa fa-check"></span><input type="hidden" className="food_type" value={option.food_type == null ? '0' : option.food_type} /><input type="hidden" className="food_categories" value={option.food_category == null ? '0' : option.food_category} /><input type="hidden" className="meal_occasion" value={option.meal_occasion_name == null ? '0' : option.meal_occasion_name} /></li>
              )
            }



          }
          else {
            return (
              <li key={i} className="meal_names" onClick={(e) => this.selectFoodType(option, e)}><span className="capitalize cursor_pointer"><span className={option.name} id={option.name.split(" ").join("").toUpperCase()}>{option.name}</span>&nbsp;<img alt="" width="25" height="13" src={food_list_images_icons[option.food_category]} className="log_pills_icons" /><img alt="" src="/assets/images/arrow_right_grey.png" className="pull-right right_arrow_img" /></span><span className="fa fa-check hide"></span><input type="hidden" className="food_type" value={option.food_type == null ? '0' : option.food_type} /><input type="hidden" className="food_categories" value={option.food_category == null ? '0' : option.food_category} /><input type="hidden" className="meal_occasion" value={option.meal_occasion_name == null ? '0' : option.meal_occasion_name} /></li>
            )
          }
        } else {

          if (option.food_type == 'generic' && window.localStorage.getItem('from_addintional_log') == "true") {
            window.localStorage.setItem('page_number', 1);
            localStorage.removeItem('search_from_journal');
            if (option.food_category !== '' && option.food_category !== null && option.food_category !== undefined) {
              window.localStorage.setItem('food_categories', option.food_category)
            } else {
              localStorage.removeItem('food_categories');
            }
            if (option.food_type !== '' && option.food_type !== null && option.food_type !== undefined) {
              window.localStorage.setItem('food_type', option.food_type)
            } else {
              localStorage.removeItem('food_type');
            }
            if (option.meal_occasion_name !== '' && option.meal_occasion_name !== null && option.meal_occasion_name !== undefined) {
              window.localStorage.setItem('meal_occasion', option.meal_occasion_name)
            } else {
              localStorage.removeItem('meal_occasion');
            }
            if ($('[href="#Nutrisystem"]').parent().hasClass('active') || $('[href="#All"]').parent().hasClass('active')) {
              // this.props.callParentLogFood();
              return (
                <li key={i} className="meal_names" onClick={(e) => this.selectFoodType(option, e)}><span className="capitalize blue_color cursor_pointer"><span className={option.name} id={option.name.split(" ").join("").toUpperCase()}>{option.name}</span>&nbsp;<img alt="" width="25" height="13" src={food_list_images_icons[option.food_category]} className="log_pills_icons" /><img alt="" src="/assets/images/arrow_right_grey.png" className="pull-right right_arrow_img" /></span><span className="fa fa-check"></span><input type="hidden" className="food_type" value={option.food_type == null ? '0' : option.food_type} /><input type="hidden" className="food_categories" value={option.food_category == null ? '0' : option.food_category} /><input type="hidden" className="meal_occasion" value={option.meal_occasion_name == null ? '0' : option.meal_occasion_name} /></li>
              )
            } else {
              // this.props.callParentFavFood();
              return (
                <li key={i} className="meal_names" onClick={(e) => this.selectFoodType(option, e)}><span className="capitalize blue_color cursor_pointer"><span className={option.name} id={option.name.split(" ").join("").toUpperCase()}>{option.name}</span>&nbsp;<img alt="" width="25" height="13" src={food_list_images_icons[option.food_category]} className="log_pills_icons" /><img alt="" src="/assets/images/arrow_right_grey.png" className="pull-right right_arrow_img" /></span><span className="fa fa-check"></span><input type="hidden" className="food_type" value={option.food_type == null ? '0' : option.food_type} /><input type="hidden" className="food_categories" value={option.food_category == null ? '0' : option.food_category} /><input type="hidden" className="meal_occasion" value={option.meal_occasion_name == null ? '0' : option.meal_occasion_name} /></li>
              )
            }

          } else {
            return (
              <li key={i} className="meal_names" onClick={(e) => this.selectFoodType(option, e)}><span className="capitalize cursor_pointer"><span className={option.name} id={option.name.split(" ").join("").toUpperCase()}>{option.name}</span>&nbsp;<img alt="" src="/assets/images/arrow_right_grey.png" className="pull-right right_arrow_img" /></span><span className="fa fa-check hide"></span><input type="hidden" className="food_type" value={option.food_type == null ? '0' : option.food_type} /><input type="hidden" className="food_categories" value={option.food_category == null ? '0' : option.food_category} /><input type="hidden" className="meal_occasion" value={option.meal_occasion_name == null ? '0' : option.meal_occasion_name} /></li>
            )
          }
        }

      }.bind(this));
    }






    return (

      <div className="log_food_sec">


        <div className="pull-left width100">
          <div className="select_dropdown_foodtype">
            <span className="color6 font14 filter_food" ><img alt="" className="vlt mr3" src="/assets/images/filter.png" />Search Filter <span className="blue_color not_avail pull-right mr3">Not Available</span></span>

            <ul className="food_type_drop">
              <li className="not_set"><i className="capitalize not_set_class cursor_pointer blue_color">Not set </i><span className="fa fa-check"></span></li>
              {food_type_options}
            </ul>

          </div>
        </div>




      </div>

    )
  }
}




export default FilterSearch;
