import React from 'react';
import moment from 'moment';
import Header from "../header.js";
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import $ from 'jquery';
import * as Sentry from "@sentry/react";

var buttonshow = false;
class Avatar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      avatar_images: [], avatar_data: '', avatar_url: '', plan_start_date: moment(window.localStorage.getItem('plan_start_date')).format('MMMM DD, YYYY'),
      toogleUploadAvatar: false
    };
    this.getAvatar = this.getAvatar.bind(this);
    this.handleFile = this.handleFile.bind(this);
    this.submitAvatar = this.submitAvatar.bind(this);
  }


  submitAvatar(e) {

    if ($('#browse_img')[0].files[0] !== undefined) {
      if ($('.loader_img').length) {
        $('.loader_img').remove(); //remove it before showing if already present.
      }
      $('<div class="loader_img"><div class="loader_gif"></div></div>').appendTo('body');
      $('.loader_img').show(); // show on any Ajax event.
      $('.loader_img').height($('body').height());
      $('.loader_gif').css({ position: 'fixed', top: $(window).scrollTop() + $(window).height() / 2 });
      window.localStorage.setItem('check_avatar', "true");
      var img = new FormData();
      img.append('image[file]', $('#browse_img')[0].files[0]);
      var authData = Header.authorization_headers();
      delete authData['Content-Type']

      const options = {
        url: window.API_URL + "v1/images/avatar",
        method: 'POST',
        credentials: 'same-origin',
        headers: authData,
        data: img,
      };

      axios(options)
        .then(response => {
          this.setState({
            toogleUploadAvatar: false
          })
          // $('#upload_photo').modal('hide');
          window.localStorage.setItem('avatar_uploaded', "true");
          this.getAvatar();
          $('.photo_submit').attr('disabled', true).css('background-color', '#78CA87').addClass('btn disabled');
          $('.loader_img').remove();
        })
        .catch(error => {
          $('.loader_img').remove();
          Header.apiErrors(error);
          Sentry.captureException(error);
        });
    }
  }

  handleFile(e) {
    const reader = new FileReader();
    const file = e.target.files[0];

    reader.onload = (upload) => {
      this.setState({
        data_uri: upload.target.result,
        filename: file.name,
        filetype: file.type
      });
    };
    reader.readAsDataURL(file);
  }

  getAvatar() {

    const options = {
      url: window.API_URL + "v1/images/avatar",
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders()
    };

    axios(options)
      .then(response => {
        this.setState({ avatar_data: response.data });

        if (this.state.avatar_data) {
          this.setState({ avatar_url: this.state.avatar_data.image.versions.thumb });
          if (this.state.avatar_data.image.url !== '') {
            $(".profile_avatar img").attr('src', this.state.avatar_url);
            $(".profile_avatar img").css('border-radius', '50%');
            $(".profile_img").html('<img class="profile_pic" src="' + this.state.avatar_url + '"/>');
            $(".profile_pic").css({ 'border-radius': '50%', 'width': '40px', 'height': '40px' });
          }
          else {
            $(".profile_avatar img").attr('src', '/assets/images/avatar.png');
            $(".profile_avatar img").css('border-radius', '');
          }
        }
      })
      .catch(error => {
        if (error && error.response && error.response.data && error.response.data.error) {
          $(".profile_img").html('<img alt="" class="profile_pic" src="/assets/images/profile_large.png"/>');
        }
        else if (error && error.response && error.response.status > 400) {
          $(".profile_img").html('<img alt="" class="profile_pic" src="/assets/images/profile_large.png"/>');
        }
        Sentry.captureException(error);
      });

  }

  componentDidMount() {

    this.getAvatar();


    /*    this.props.getProfile_upload;*/
    /*keystrings*/
    $('.avatar_upload_photo').text(window.keystrings['com.numi.profile.upload_photo']);
    $('.upload_diff_pic').text(window.keystrings['com.numi.profile.upload_different_photo']);
    // $('.upload_pic_format_content').text(window.keystrings['com.numi.profile.upload_photo_format_content']);
    $('.photo_upload_submit').text(window.keystrings['com.numi.profile.submit']);
    $('.plan_start_key').text(window.keystrings['com.numi.profile_start_date']);
    $('.doc_type').text(window.keystrings['com.numi.image_type_error']);

    /*keystrings*/
  }

  showAvatar = () => {
    this.setState({
      toogleUploadAvatar: true
    })
  }

  hideUploadAvatar = () => {
    this.setState({
      toogleUploadAvatar: false
    })
  }


  render() {
    return (
      <>
        <div className="meal_section_right pull-right">
          <label htmlFor="file_upload" className="d_block">
            <div id="profile_avatar" className="profile_avatar" onClick={this.showAvatar}><img alt="" src="/assets/images/avatar.png" />
            </div>
          </label>
          <div className="p_user"><p className="font20 color6 first_name">{window.localStorage.getItem('FirstName')}</p><p className="font14 color7 plan_start_date"><span className="plan_start_key"></span> {this.state.plan_start_date}</p></div>
        </div>
        <div className="clear"></div>
        <AvatarUpload submitAvatarProfile={this.submitAvatar} handle={this.handleFile} toogleUploadAvatar={this.state.toogleUploadAvatar} hideUploadAvatar={this.hideUploadAvatar} />
      </>
    )
  }
}


class AvatarUpload extends React.Component {


  componentDidMount() {

    $(document).on('click', ".profile_avatar", function (event) {
      $('#browse_img').val('');
    });
    $(document).on('change', "#browse_img", function (e) {
      var extension, fileTypes;
      fileTypes = ['jpg', 'jpeg', 'png', 'gif'];
      var img_id = document.getElementById("browse_img");


      extension = img_id.files[0].name.split('.').pop().toLowerCase();
      var image_size_limit = parseInt(process.env.REACT_APP_IMAGE_SIZE_LIMIT || 10) * 1024
      var image_size = ($("#browse_img")[0].files[0].size / 1024);
      if (img_id.files && img_id.files[0] && fileTypes.indexOf(extension) > -1 && image_size / image_size_limit < 1) {


        buttonshow = true;

        // $('#upload_avatar').find('.enableButton').replace('<div class="today_date">Hello</div>')
        $('.photo_submit').removeAttr('disabled').css('background-color', '#78CA87').removeClass('btn disabled');
        $('.image_type,.doc_type').addClass('hide');
      }
      else {
        if (image_size / image_size_limit > 1) {
          $('.image_type').removeClass('hide');
          $('.doc_type').addClass('hide');
        }
        if (fileTypes.indexOf(extension) < 0) {
          $('.doc_type').removeClass('hide');
          $('.image_type').addClass('hide');
        }
        buttonshow = false;
        $('.photo_submit').attr('disabled', true).css('background-color', '#78CA87').addClass('btn disabled');
      }
    })

  }
  stopUpload = () => {

  }
  render() {

    return (
      <>
        <Modal show={this.props.toogleUploadAvatar} onHide={this.props.hideUploadAvatar} id="upload_avatar">
          <Modal.Header>
            <img alt="" src="/assets/images/close_grey.png" className="cursor_pointer" onClick={this.props.hideUploadAvatar} />
          </Modal.Header>
          <Modal.Body>
            <h2 className="modal-title">{window.keystrings['com.numi.profile.upload_photo']} </h2>
            <span className="upload_img"><img alt="" src="/assets/images/profile_avatar_large.png" /></span>
            <span className="img_browse">
              <span className="show_error_msg image_type hide">Image size is greater than {process.env.REACT_APP_IMAGE_SIZE_LIMIT || 10}MB</span>
              <span className="show_error_msg doc_type hide">We accept JPG, GIF, or PNG files only</span>
              <span className="upload_diff_pic"></span>
              <span className="uploaded_img"><input type="file" id="browse_img" onChange={this.props.handle} className="browse_img" accept=".jpg,.png" /></span>
              <span className="upload_pic_format_content">We accept JPG, GIF, or PNG files only, {process.env.REACT_APP_IMAGE_SIZE_LIMIT || 10} MB maximum size.</span>
              <button type="submit" value="" class="photo_submit btn disabled" onClick={buttonshow == true ? this.props.submitAvatarProfile : this.stopUpload}>{window.keystrings['com.numi.profile.submit']}</button>
            </span>

          </Modal.Body>
        </Modal>
      </>


      // <div className="modal fade alert_popup in color3" id="upload_photo" tabIndex="-1" role="dialog" aria-labelledby="upload_photo" >
      //   <div className="modal-dialog">
      //     <div className="modal-content">
      //       <div className="modal-header">
      //         <button type="button" className="close" data-dismiss="modal" aria-label="Close"><img alt="" src="/assets/images/close_grey.png" /></button>

      //       </div>
      //       <div className="modal-body">
      //         <h2 className="modal-title avatar_upload_photo"> </h2>
      //         <span className="upload_img"><img alt="" src="/assets/images/profile_avatar_large.png" /></span>
      //         <span className="img_browse">
      //           <span className="show_error_msg image_type hide">Image size is greater than {process.env.REACT_APP_IMAGE_SIZE_LIMIT || 10}MB</span>
      //           <span className="show_error_msg doc_type hide">We accept JPG, GIF, or PNG files only</span>
      //           <span className="upload_diff_pic"></span>
      //           <span className="uploaded_img"><input type="file" id="browse_img" onChange={this.props.handle} className="browse_img" accept="image/jpeg, image/jpg, image/png" /></span>
      //           <span className="upload_pic_format_content">We accept JPG, GIF, or PNG files only, {process.env.REACT_APP_IMAGE_SIZE_LIMIT || 10} MB maximum size.</span>

      //           <button type="submit" value="" onClick={this.props.submitAvatarProfile} className="photo_submit btn disabled photo_upload_submit"></button>
      //         </span>
      //       </div>
      //     </div>
      //   </div>
      // </div>
    )
  }
}
export default Avatar;
