import React from 'react';
import Header from "../header.js";
import Login from "./login.js";
import axios from 'axios';
import moment from 'moment';
import * as Sentry from "@sentry/react";

var $ = require("jquery");
window.jQuery = $;
class Impersonate extends React.Component {
  constructor(props) {
    super(props);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    this.state = {
      email: urlParams.get('email'),
      access_token: urlParams.get('access_token'),
      redir: urlParams.get('redir')
    };
  }

  // Login Submit
  handleSignInClick() {
    // Login api call
    var user_params = {
      "user": {
        "email": this.state.email,
        "access_token": this.state.access_token
      }
    };

    window.localStorage.removeItem('user_access');
    window.localStorage.removeItem('user_refresh');
    window.localStorage.removeItem('current_user_email');
    window.localStorage.removeItem('profile_status');
    window.localStorage.setItem('current_user_email', this.state.email);
    window.localStorage.setItem('userName', this.state.email);
    window.localStorage.setItem('impersonation', this.state.access_token);
    Header.startLoader();

    const options = {
      url: `${process.env.API_URL ? process.env.API_URL : ''}api/oauth/login.json`,
      method: 'POST',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
      data: user_params,
      cache: false,
    };
    axios(options)
      .then(response => {
        Header.stopLoader(); // stop loader
        Login.createSessionVariables(response.data);
        var profile_status = window.localStorage.getItem('profile_status');
        var numiLegacy = window.localStorage.getItem('numi_legacy');
        if (profile_status === null || profile_status === "NEW_USER") {
          this.props.history.push('/signup');
        }
        else if (profile_status === "ONBOARDING_COMPLETE") {
          window.localStorage.setItem('assigned_date', moment().format('YYYY-MM-DD'));
          if (window.localStorage.getItem('impersonation') === "true") {
            if (this.state.redir) {
              window.location.href = this.state.redir;
            } else {
              window.location.href = "/journal";
            }
          }
          else {
            if (this.state.redir) {
              this.props.history.push(this.state.redir);
            } else {
              this.props.history.push('/journal');
            }
          }

        }
        else if ((profile_status === "PROFILE_COMPLETE" || profile_status === "ONBOARDING") && numiLegacy === 'true') {
          this.props.history.push('/welcome');
        }
        else if (profile_status === "PROFILE_COMPLETE" || profile_status === "ONBOARDING") {
          this.props.history.push('/ns_plan');
        }
        else if (profile_status === "PLAN_COMPLETE") {
          this.props.history.push('/stay_on_track');
        }
        else {
          alert("Undifined profile status:" + profile_status);
        }
      })
      .catch(error => {
        Header.stopLoader();

        if (error.response && error.response.data) {
          $('.main_container').html(error.response.data['error'])
        }
        else {
          $('.main_container').html(error);
        }

        Sentry.captureException(error);

      });
  }

  componentDidMount() {
    this.handleSignInClick()
  }

  render() {
    return (
      <div className="main_container error">
        Checking In User!
      </div>
    );
  }
}

export default Impersonate;
