import React from 'react';
import axios from 'axios';
import Header from "../header.js";
import { FoodHelp } from '../journal/popup.js';
import { withRouter } from 'react-router-dom';
import $ from 'jquery';
import * as Sentry from "@sentry/react";

class ActivityQuestions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {


      final_cipd_value: '',
      activity_questions_length: '',
    };
    window.activityArray = [];
    window.screenLength = 0;
    window.neat_hdr = [];
  }

  componentDidMount() {
    var _this = this;
    _this.props.onRef(_this);
    $('.personal_hdr').text(window.keystrings['com.numi.personalized_plan']);
    $('.buffer h3').text(window.keystrings['com.numi.calculate_result']);
    $('.buffer h5').text(window.keystrings['com.numi.determine_text']);
    $('.personal_meal_hdr').text(window.keystrings['com.numi.personalized_meal_hdr']);
    $('.personalized_text').html(window.keystrings['com.numi.personalized_text']);
    $('.determine_text').html(window.keystrings['com.numi.determine_text']);
    $('.calculate_result').html(window.keystrings['com.numi.calculate_result']);
    $('.log_your_progress').html(window.keystrings['com.numi.remember_log_your_progress']);
    $('.calorie_range_txt').html(window.keystrings['com.numi.calorie_goal_text']);
    $('.personalized_hdr').html(window.keystrings['com.numi.personalized_hdr']);

    this.activityApi();
    $(document).on('click', '.text-green-darker', function () {

      $(this).parent().find('.text-green-darker').find('img').each(function () {
        $(this).attr('src', $(this).attr('normal_image')).removeClass('active');
      });
      $(this).parent().find('.text-green-darker').css('opacity', 0.3).removeClass('active');
      $(this).addClass('active');
      $(this).find('img').attr('src', $(this).find('img').attr('active_image'));

    });

    $(document).on('click', '.left_back_arrow,.questions_close', function () {

      $('.text-green-darker').find('img').each(function () {
        $(this).attr('src', $(this).attr('normal_image'));
        $(this).parent().removeClass('active').css('opacity', 0.3);
        if ($(this).parent().attr('tab_active') === "true") {
          $(this).parent().addClass('active').css('opacity', 1);
          $(this).attr('src', $(this).attr('active_image'));
        }
      });

    });

    $(document).on('mouseover', '.text-green-darker', function () {
      if (!$(this).hasClass('active')) {
        $('.text-green-darker:not(".active")').css('opacity', 0.3);
        $(this).css('opacity', 1).find('img').attr('src', $(this).find('img').attr('active_image'));
      }

    });

    $(document).on('mouseout', '.text-green-darker', function () {
      if (!$(this).hasClass('active')) {
        $('.text-green-darker:not(".active")').css('opacity', 0.3);
        $(this).css('opacity', 0.3).find('img').attr('src', $(this).find('img').attr('normal_image'));
      }

    });
  }

  showPersonalizeHelp(value) {
    window.localStorage.setItem('help_location', value);
    this.refs.callHelp.callHelpAPI(value);
  }
  applyCipdApi = () => {
    const options = {
      url: window.API_URL + 'v1/nutrisystem/apply_cipd',
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => { })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });
  }

  calculateActivity = (e) => {
    var _this = this;
    if (!$('.personalized_popup').is(':visible')) {
      window.activityArray.push({
        "key": e.target.parentElement.getAttribute('id'),
        "value": e.target.parentElement.querySelectorAll('button.active')[0].getAttribute('value'),
        "data_type": "STRING"
      })

      window.screenLength++;
      var activity_params = {
        "nutrisystem_user_properties": window.activityArray
      }
    }

    e.target.parentNode.style.display = 'none';
    if (_this.state.activity_questions_length !== window.screenLength) {
      e.target.parentNode.nextElementSibling.style.display = 'block';
    }
    else {
      $('#activity_questions .modal-content').addClass('buffer_bg');
      $('#activity_questions .modal-header').hide();
      $('.buffer').show();

      const options = {
        url: window.API_URL + 'v1/nutrisystem/user_properties',
        method: 'POST',
        credentials: 'same-origin',
        headers: Header.setAuthHeaders(),
        data: activity_params
      };

      axios(options)
        .then(response => {
          sessionStorage.setItem('cipd_range', response.data.CIPD_RANGE);
          _this.setState({
            final_cipd_value: response.data.CIPD_DISPLAY,
          });
          window.screenLength = 0;
          window.activityArray = [];
          if (document.URL.indexOf('/progress') > -1 || document.URL.indexOf('/journal') > -1) {
            _this.props.callReadAPI();
            if (localStorage.getItem('check_transition_type') === 'CIPD_TRANSITION_ALERT') {
              $('.calorie_range_txt').html(window.keystrings['com.numi.calorie_goal_text_alert']);
              _this.applyCipdApi();
            }
            else {
              _this.callTransitionDetminant();
            }


          }
          const smart_week_new = {
            url: window.API_URL + "v1/nutrisystem/smart_week",
            method: 'GET',
            credentials: 'same-origin',
            headers: Header.setAuthHeaders(),
          };
          axios(smart_week_new)
            .then(response => {
              Header.stopLoader();
              localStorage.setItem('smart_week_setup_screen', response.data.smart_week.show_smart_week);
              if (response.data.smart_week.show_smart_week === true && localStorage.getItem("smartAdapt") === "true" && window.screenLength === 0 && localStorage.getItem('show_alert_or_boost') === 'true') {
                $('.ques_buffer').show();
                $('.ques_calculation').hide();
                $('#personalize_div').hide();
                setTimeout(() => {
                  $('.personalized_popup').hide();
                  $('.modal-content').removeClass('buffer_bg');
                  $('.buffer').hide();
                  $('.ques_buffer').hide();
                  $('.calculation').hide();
                  $('#personalize_questions').modal('show');
                  $('.smart_week_setup_option_new').show().css({ "padding-top": "25px", "padding-bottom": "10px" });;
                  $('#activity_question_options').hide();
                  $('.activitymodel').hide();
                  $("#newCalGoal").show();
                  $("#step0").hide();
                  $("#step1").hide();
                  $("#step2").hide();
                  $("#step3").hide();
                  $("#backToPrevStep1").removeClass("d-none")
                  $("#backToPrevStep12").addClass("d-none")
                  $("#backToPrevStep21").addClass("d-none")
                  $("#backToPrevStep2").removeClass("d-none")
                }, 5000);
              }
              else {
                setTimeout(() => {
                  $('.modal-content').removeClass('buffer_bg');
                  $('.buffer').hide();
                  $('.calculation').show();
                }, 2000);
              }
            })

        })
        .catch(error => {
          Header.apiErrors(error);
          Sentry.captureException(error);
        });

    }

  }

  activityApi = () => {

    const options = {
      url: window.API_URL + 'v1/nutrisystem/activity_questions',
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        window.neat_hdr = response.data.nutrisystem_activity_questions;
        this.setState({
          activity_questions_length: response.data.nutrisystem_activity_questions.length
        });


      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });


  }
  backNspaln = () => {

    var _this = this;
    if (document.URL.indexOf('/ns_plan') > -1) {
      window.location.reload();
      /* _this.props.callPlansAPI();
       $('.toggle_options label').css('opacity','0.4');
     $('#activity_questions').modal('hide');
     $('.plans_list,.ns_select_plan').show();
     $('.phase_option,.low_blood_sugar,.success_phase').hide();*/
    }
    else if (document.URL.indexOf('/plan_settings') > -1) {
      _this.activityApi();
      _this.props.callPreviousState();
      $('.toggle_options label').css('opacity', '0.4');
      $('.modal').modal('hide');
    } else if (document.URL.indexOf('/progress') > -1) {
      $('.modal').modal('hide');
      _this.activityApi();
      _this.props.history.push('/journal');
    }
    else {
      Header.startLoader();
      setTimeout(() => {
        Header.stopLoader();
        _this.props.callJournalAPI();
        $('.modal').modal('hide');
      }, 1999)
    }

  }
  showNspaln = () => {
    window.screenLength = 0;
    window.activityArray = [];
    $('.ns_plan > div').hide();
    $('.ns_select_plan').show();
  }
  callBack = (e) => {
    var _this = this;
    //_this.showNspaln();
    if (window.activityArray.length > 0) {
      var current_element = e.target.parentElement.previousElementSibling.getAttribute('id');
      window.activityArray.forEach(function (element, i) {
        if (window.activityArray[i].key === current_element) {
          window.activityArray.splice(i, 1);
        }
      });

    }
    if (window.screenLength === 0 && localStorage.getItem('show_alert_or_boost') === 'true') {
      localStorage.setItem('show_alert_or_boost', 'false');
      $('#activity_questions').modal('hide');
      $('#alert_content').modal('show');
    }
    else {
      e.target.parentElement.style.display = 'none';
      e.target.parentElement.previousElementSibling.style.display = 'block';
    }

    window.screenLength !== 0 ? window.screenLength-- : window.screenLength = 0;


    $('.toggle_options label').css('opacity', '0.4');
    if (document.URL.indexOf('/plan_settings') > -1) {
      _this.props.callPreviousState();
    }

  }
  callTransitionDetminant = () => {
    var transition_determinant_params = { "transition_determinant": { "json_detail": { "CIPD_RANGE": sessionStorage.getItem('cipd_range'), "GOAL_WEIGHT": localStorage.getItem('progres_goal') } } };

    const options = {
      url: window.API_URL + "v1/transition_determinants",
      method: 'POST',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
      data: transition_determinant_params
    };

    axios(options)
      .then(response => {
        Header.stopLoader();
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });
  }

  callTransition = () => {
    let _this = this;
    if (document.URL.indexOf('/ns_plan') > -1) {
      _this.props.callMealDeterminant();
    }
    else if (document.URL.indexOf('/plan_settings') > -1) {
      _this.props.callUpdateTransition();
    } else if (document.URL.indexOf('/progress') > -1) {
      $('.modal').modal('hide');
      _this.props.history.push('/journal');
    }
    else {
      Header.startLoader();
      setTimeout(() => {
        Header.stopLoader();
        _this.props.callJournalAPI();
        $('.modal').modal('hide');
      }, 1999)

    }
    localStorage.removeItem('check_transition_type');
  }
  render() {
    if (window.neat_hdr.length > 0) {
      var list = window.neat_hdr.map((option, i) => {
        return (
          <div id={option.key} key={i} className={option.key === "NEAT" ? 'lifestyle' : 'habits'}>
            <img alt="" src="/assets/images/back_white_icon.png" className="left_back_arrow cursor_pointer" onClick={this.callBack} />{/*<button type="button" className="close questions_close" data-dismiss="modal" aria-label="Close"  onClick={this.callBack}><img alt="" src="/assets/images/close_grey.png"/></button>*/}
            <h3 className="text-center font26 mt10"> {option.description}</h3>
            <p className='font16 text-center ml18' style={{ display: (option.key === 'ACTIVITY') ? 'block' : 'none' }}>{window.keystrings['com.numi.cd.smartadapt.exercisehabit.note']}</p>
            <div className="questions_popup">


              {
                option.answers.map((answer_option, j) => {
                  return (
                    <button type="button" key={j} tab_active={answer_option.default ? 'true' : 'false'} className={answer_option.default ? "text-medium text-green-darker text-futura activity-type btn-block active" : 'text-medium text-green-darker text-futura activity-type btn-block'} name="activityType" value={answer_option.code} aria-pressed="false">
                      <img alt="" title="" src={answer_option.icon_svg_selected} active_image={answer_option.icon_svg_selected} normal_image={answer_option.icon_svg} />
                      <span className="text-left text-gray-darker body-shape-txt text-futura fw-300"> <strong className="shape-txt">{answer_option.header}</strong> <span className="hg-txt">{answer_option.body}</span></span>
                    </button>);
                })
              }
            </div>
            <button onClick={this.calculateActivity} className="mt10">Continue</button>
          </div>
        )
      })
    }


    return (
      <div>
        <div className='activitymodel'>
          <div className="modal fade" id="activity_questions" data-backdrop="static">
            <div className="modal-dialog">
              <div className="modal-content">
                {/*<div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close"><img alt="" src="/images/close_grey.png"/></button>
                </div>*/}
                <div className="modal-body">
                  <div id="activityGoal">
                    <div className="row margin0">
                      <div className="personalized_popup">
                        <div className="calc_hdr"><i className="fa fa-arrow-left cursor_pointer" onClick={this.backNspaln} aria-hidden="true"></i> <span className="personal_hdr"></span> <img className="quest_white  cursor_pointer" title="" alt="" src="/assets/images/question_white.jpg" data-target="#help_cipd" data-toggle="modal" /></div>
                        <img src="/assets/images/blaze.svg" className="blaze_img" alt="" title="" />
                        <h3 className="mt20px personal_meal_hdr"> </h3>
                        <div className="mt20px personalized_text font18"></div>
                        <button onClick={this.calculateActivity}>Continue</button>
                      </div>
                      {list}


                    </div>

                  </div>


                  <div className="copy-wrapper buffer colorff">

                    <div className="copy-container text-center">
                      <img title="" src="/assets/images/calculation_loading.gif" className="img-responsive center-block" alt="loading" />
                      <h3 className="text-uppercase mt20px calculate_result"> </h3>
                      <h5 className="determine_text"> </h5>

                    </div>
                  </div>

                  <div className="calculation buffer_bg text-center colorff">
                    <div className="calc_hdr"><i />{/*<i onClick={this.backNspaln} className="fa fa-arrow-left colorff cursor_pointer" aria-hidden="true"></i>*/} <span className="personal_hdr"></span> <span className="cursor_pointer white_border" data-target="#help_cipd" data-toggle="modal">?</span></div>
                    <div className="width80">
                      <img src="/assets/images/mealplan.svg" alt="" className="plb10" />
                      <h5 className="personalized_hdr"> </h5>
                      <h1 className="ptb3"><b>{this.state.final_cipd_value}</b></h1>
                      <hr className="cal_divider" />
                      <p className="font15 calorie_range_txt"></p>
                      <button type="button" className="mt15" onClick={this.callTransition}>Continue</button>
                      <p className="font16 mt20px log_your_progress">Remember: Log your progress in NuMi to enjoy smart updates to your calorie goal and meal plan!</p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <FoodHelp ref="callHelp" />
      </div>

    )
  }
}

export default withRouter(ActivityQuestions);