import React from 'react';
import moment from 'moment';
import $ from 'jquery';



class Tour extends React.Component {



   componentDidMount() {
      $('.footer_body_rights_reserved_text').text(window.keystrings['com.numi.footer.body_rights_reserved'].replace('{year}', new Date().getFullYear()));
      $('.footer_body_address_text').text(window.keystrings['com.numi.footer.body_address'])
      setTimeout(function () {
         window.introJs().start();
         if ($('[data-step="1"]').hasClass('introjs-showElement')) {
            $('.introjs-tooltip').css({ 'top': '20px', 'left': '260px' });
            $('.introjs-arrow').removeClass('left').addClass('left-bottom');
         }
         else {
            $('.introjs-arrow').removeClass('left-bottom');
         }
         if ($('.journal_tab').hasClass('active')) {
            $('.introjs-tooltipReferenceLayer').css('left', '70px');
            $('.introjs-prevbutton').hide();
         }
         else {
            $('.introjs-prevbutton').show();
         }
         $('.introjs-helperLayer').css({ 'left': '70px' });
         $('.tour_title_one').text(window.keystrings['com.numi.tour.title.one']);
         $('.numi_web_tour_detail_one').text(window.keystrings['com.numi.web.tour.detail.one']);
         $('.tour_title_six').text(window.keystrings['com.numi.tour.title.six']);
         $('.numi_web_tour_detail_six').text(window.keystrings['com.numi.web.tour.detail.six']);
         $('.tour_title_eight').text(window.keystrings['com.numi.tour.title.eight']);
         $('.numi_web_tour_detail_eight').text(window.keystrings['com.numi.web.tour.detail.eight']);
         $('.tour_title_seven').text(window.keystrings['com.numi.tour.title.seven']);
         $('.numi_web_tour_detail_seven').text(window.keystrings['com.numi.web.tour.detail.seven']);
         $('.tour_title_ten').text(window.keystrings['com.numi.tour.title.ten']);
         $('.numi_web_tour_detail_ten').text(window.keystrings['com.numi.web.tour.detail.ten']);
         $('.tour_title_eleven').text(window.keystrings['com.numi.tour.title.eleven']);
         $('.numi_web_tour_detail_eleven').text(window.keystrings['com.numi.web.tour.detail.eleven']);
         $('.tour_title_two').text(window.keystrings['com.numi.tour.title.two']);
         $('.numi_web_tour_detail_two').text(window.keystrings['com.numi.web.tour.detail.two']);
         $('.tour_title_three').text(window.keystrings['com.numi.tour.title.three']);
         $('.numi_web_tour_detail_three').text(window.keystrings['com.numi.web.tour.detail.three']);
         $('.tour_title_four').text(window.keystrings['com.numi.tour.title.four']);
         $('.numi_web_tour_detail_four').text(window.keystrings['com.numi.web.tour.detail.four']);
         $('.tour_title_five').text(window.keystrings['com.numi.tour.title.five']);
         $('.numi_web_tour_detail_five').text(window.keystrings['com.numi.web.tour.detail.five']);

         $('.introjs-overlay,.introjs-helperLayer,.introjs-tooltipReferenceLayer').appendTo('#container');
         $('.introjs-overlay,.introjs-helperLayer,.introjs-tooltipReferenceLayer').wrapAll('<div class="tour_divs"><div class="tour_align"></div></div>')
         $('.introjs-tooltip').prepend('<img alt="" src="/assets/images/close_grey.png" class="pull-right mb cursor_pointer close_img"><div class="clear"></div>');

      }, 525);
      $('.modal-backdrop').remove();
      $('body').removeClass('modal-open');


      $(document).on('click', '.introjs-prevbutton,.introjs-nextbutton', function () {
         if (!$('[data-step="2"]').hasClass('introjs-showElement') && !$('[data-step="3"]').hasClass('introjs-showElement') && !$('[data-step="4"]').hasClass('introjs-showElement') && !$('[data-step="5"]').hasClass('introjs-showElement')) {
            $('.introjs-helperLayer').addClass('tour_border');
         }
         else {
            $('.introjs-helperLayer').removeClass('tour_border');
         }


         var kk = parseInt($('.introjs-helperLayer').css('left')) - 35;
         $('.introjs-helperLayer').css('left', kk);

         if ($('[data-step="5"]').hasClass('introjs-showElement')) {
            $('.meal_data .journal_empty_options').show();
         }
         else {
            $('.journal_empty_options').hide();
         }
         if ($('[data-step="1"]').hasClass('introjs-showElement')) {
            $('.introjs-prevbutton').hide();
         }
         else {
            $('.introjs-prevbutton').show();
         }
         setTimeout(function () {


            $('.navlist li').removeClass('active').css('background-color', '');
            $('.introjs-showElement').parent('.navlist li').css('background-color', '#30AAA1')





            $('.introjs-donebutton').show().addClass('pull-right').blur();
         }, 525);
      })

      $(document).on('click', '.close_img,.introjs-donebutton', function () {
         $('[class^="introjs"],.tour_divs').remove();
         this.props.history.push('/journal')

      }.bind(this))
      /*key strings*/
      $('.tour-change-to-new-stage-key-strings').text(window.keystrings['com.numi.journal.change_to_new_stage']);

   }
   render() {
      var plan_start_date = moment(window.localStorage.getItem('plan_start_date')).format('MMMM DD, YYYY');

      return (
         <div id="container">
            <div data-reactroot="">
               <div>
                  <div className="table_display">
                     <div className="journal_div journal tour_page">
                        <div className="section_left pull-left">
                           <div>
                              <a href="/journal"><span className="jour_numi_logo"></span></a>
                              <div className="profile_sec">
                                 <div className="profile_img"><img alt="" src="/assets/images/profile_large.png" /></div>
                                 <div className="user_txt dropdown">
                                    <div className="user_dropdown_tour">
                                       {window.localStorage.getItem('FirstName')}
                                       <span className="relative">
                                          <img alt="" src="/assets/images/arrow_white_down.png" /><br />
                                          <ul className="dropdown-menu edit_profile">
                                             <li><a href="/profile">Edit Profile</a></li>
                                             <li><a href="/login">Log Out</a></li>
                                          </ul>
                                       </span>
                                    </div>
                                    <span className="white_color start_date">Plan start date: {plan_start_date}</span>
                                 </div>
                              </div>
                              <hr className="white_divider" />
                              <p className="profile_sec logit_txt">
                                 <span className="colorff">
                                    <img alt="" src="/assets/images/log_plus.png" /> {/*<!-- react-text: 43 -->*/}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {/*<!-- /react-text -->*/}<span className="nav_logit_txt">Log It</span>
                                 </span>
                              </p>

                              <hr className="white_divider" />
                              <div className="tabs_section">
                                 <ul className="navlist">
                                    <li className="journal_tab active" ><a style={{ background: 'url("/assets/images/journal.png") no-repeat 5px center' }} data-step="1" data-intro="<p class='tour_title_one'></p> <p class='tooltip_dec numi_web_tour_detail_one'></p>" href="/journal">Journal</a></li>
                                    <li className="leaf_tab"><a target="_blank" rel="noopener noreferrer" data-position='bottom' data-step="6" data-intro="<p class='tour_title_six'></p> <p class='tooltip_dec numi_web_tour_detail_six'></p>" style={{ background: 'url("/assets/images/leaf_nav.png")  no-repeat  5px center' }} href="https://leaf.nutrisystem.com/">The Leaf</a></li>
                                    <li className="recipe_tab " >
                                       <a href="/recipe" data-step="8" data-intro="<p class='tour_title_eight'></p> <p class='tooltip_dec numi_web_tour_detail_eight'></p>" style={{ background: 'url("/assets/images/recipes.png") no-repeat 5px center' }}>Recipes</a></li>
                                    <li className="progress_tab " ><a href="/progress" data-step="7" data-intro="<p class='tour_title_seven'></p> <p class='tooltip_dec numi_web_tour_detail_seven'></p>" style={{ background: 'url("/assets/images/progress.png") no-repeat 5px center' }}>Progress</a></li>
                                    <li className="newsfeed_tab " ><a href="/PlanSettings" data-step="9" data-intro="<p class='tour_title_ten'></p> <p class='tooltip_dec numi_web_tour_detail_ten'></p>" style={{ background: 'url("/assets/images/newsfeed.png") no-repeat 5px center' }}>News Feed</a></li>
                                    <li className="settings_tab "><a style={{ background: 'url("/assets/images/settings.png") no-repeat 5px center' }} href="/my_profile">Settings</a></li>
                                    <li className="shop_tab"><a href="https://www.nutrisystem.com/numiwebshopalc" style={{ background: 'url("/assets/images/shop.png") no-repeat 5px center' }}>Shop</a></li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                        <div className="section_right pull-left">
                           <figure><img alt="" src="/assets/images/promo_banner.jpg" /></figure>
                           <div className="journal_rht">
                              <h1 className="journal_hdr pull-left">
                                 {/*<!-- react-text: 67 -->*/}Journal {/*<!-- /react-text -->*/}<sup><img alt="" src="/assets/images/education.png" /></sup>
                              </h1>
                              <div className="pull-right mt10"><img alt="" src="/assets/images/search-white.png" /></div>
                              <div className="clear"></div>
                              <hr />
                              <div className="show_date pull-left">
                                 <span className="left_arrow inline_block"><img alt="" src="/assets/images/arrow_left_grey.png" /></span>
                                 <span className="date_sec inline_block">
                                    <span className="selected_date">Today</span>
                                 </span>
                                 <span className="right_arrow inline_block"><img alt="" src="/assets/images/arrow_right_grey.png" /></span>
                              </div>
                              <div className="pull-right mt1 show_calorie">
                                 <span className="calorie">
                                    <span className="calorie_value">1498</span> {/*<!-- react-text: 168 -->*/}  {/*<!-- /react-text -->*/}<span className="calorie_txt">Cals left</span> {/*<!-- react-text: 170 -->*/} &nbsp;&nbsp; = &nbsp;&nbsp; {/*<!-- /react-text -->*/}<span className="calorie_value">1800</span> {/*<!-- react-text: 172 -->*/}  {/*<!-- /react-text -->*/}<span className="calorie_txt">&nbsp;Goal</span> {/*<!-- react-text: 174 -->*/}&nbsp; - &nbsp; {/*<!-- /react-text -->*/}<span className="calorie_value">622</span> {/*<!-- react-text: 176 -->*/}  {/*<!-- /react-text -->*/}<span className="calorie_txt">&nbsp;Food</span>
                                 </span>
                                 <span className="calorie final_calorie">
                                    {/*<!-- react-text: 179 -->*/}&nbsp;&nbsp; {/*<!-- /react-text -->*/}<span className="calorie_value">600</span> {/*<!-- react-text: 181 -->*/}  {/*<!-- /react-text -->*/}<span className="calorie_txt">&nbsp;Activity</span>
                                 </span>
                              </div>
                              <div className="clear"></div>
                              <div className="" style={{ 'white-space': 'pre-line' }} data-step="10" data-scrollTo='tooltip' data-intro="<h3 class='bold tour_title_eleven'></h3>
                     <p id='tooltip_dec' class='numi_web_tour_detail_eleven'></p>">

                              </div>
                              <div className="panel-group meal_section_left pull-left" id="meal_accordion">
                                 <div className="panel panel-default" >

                                    <div id="meal1" className="panel-collapse collapse in">
                                       <div >
                                          <div style={{ 'position': 'absolute', 'width': '237px', 'height': '238px' }} data-step="2" data-position='right' data-intro="<p class='tour_title_two'></p> <p class='tooltip_dec numi_web_tour_detail_two'></p>"></div>
                                          <div style={{ "width": "546px" }}>
                                             <div className="panel-heading">
                                                <h4 className="panel-title ptb1"><span className="meal_plan_options show_icons"><a className="meal_name accordion-toggle" data-toggle="collapse" data-parent="#meal_accordion" href="#meal1">Breakfast</a><span className="pull-right mt5 mr3 arrows relative" data-toggle="collapse" data-parent="#meal_accordion" href="#meal1"></span></span></h4>
                                             </div>
                                             <div className="panel-body">


                                                <div className="food_items">
                                                   <div>
                                                      <div className="full_width inline_block meal_data">
                                                         <div className="food_details pull-left font16"><img alt="" src="/assets/images/NE-small-empty.png" width="48" height="48" /></div>
                                                         <div className="pull-left item_search">
                                                            <div className="font12 calorie_count_small"> <span className="red"></span></div>
                                                            <div className="calorie_item font16 mt2">Nutrisystem Entree</div>
                                                         </div>
                                                         <div className="pull-right mt2" data-step="3" data-position='bottom' data-intro="<p class='tour_title_three'></p> <p class='tooltip_dec numi_web_tour_detail_three'> </p>"><img alt="" src="/assets/images/plus_icon.png" /></div>
                                                      </div>
                                                   </div>
                                                   <div>
                                                      <div className="full_width inline_block meal_data">
                                                         <div className="food_details pull-left font16"><img alt="" width="48" height="48" src="/assets/images/PF-small-empty.png" /></div>
                                                         <div className="pull-left item_search">
                                                            <div className="font12 calorie_count_small"> <span className="red"></span></div>
                                                            <div className="calorie_item font16 mt2">PowerFuel</div>
                                                         </div>
                                                         <div className="pull-right mt2"><img alt="" src="/assets/images/plus_icon.png" /></div>
                                                      </div>
                                                   </div>
                                                   <div>
                                                      <div className="full_width inline_block meal_data">
                                                         <div style={{ 'position': 'absolute', 'width': '579px', 'height': '85px', 'top': '135px' }} data-step="4" data-position='top' data-intro="<p class='tour_title_four'></p> <p class='tooltip_dec numi_web_tour_detail_four'></p>"></div>
                                                         <div className="food_details pull-left font16"><img alt="" src="/assets/images/pf_full.png" /></div>
                                                         <div className="pull-left item_search">
                                                            <div className="font12 calorie_count_small"> <span className="red"></span></div>
                                                            <div className="calorie_item font12">Quick Logged</div>
                                                            <div className="calorie_item font16">PowerFuel</div>
                                                            <div className="font12 calorie_count_small">52 Cals</div>
                                                         </div>
                                                         <div className="pull-right mt2"><img alt="" src="/assets/images/search_small_grey.png" /></div>
                                                      </div>
                                                   </div>
                                                </div>
                                                <div className="full_width inline_block meal_data dropdown" >
                                                   <div style={{ 'position': 'absolute', 'width': '248px', 'height': '220px', 'top': '-170px', 'left': '-12px' }} data-step="5" data-position='top' data-intro="<p class='tour_title_five'></p> <p class='tooltip_dec numi_web_tour_detail_five'></p>"></div>


                                                   <ul className="dropdown-menu journal_empty_options">
                                                      <li><span>Log Additional Foods</span></li>
                                                      <li><span>Log Yesterday’s <span className="selected_txt">Breakfast</span></span></li>
                                                      <li><span>Copy this <span className="replace_selected_txt">Breakfast</span></span></li>
                                                   </ul>
                                                   <img alt="" src="/assets/images/plus_icon.png" className="more_plus plus_align" data-toggle="dropdown" aria-expanded="false" /><span className="font15 meal_item_lft"><a href="/log_food">More</a></span>
                                                </div>
                                             </div>
                                          </div>

                                       </div>
                                    </div>
                                 </div>
                                 <div className="clear"></div>
                                 <div className="panel panel-default">
                                    <div className="panel-heading">
                                       <h4 className="panel-title ptb1"><span className="meal_plan_options show_icons"><a className="meal_name accordion-toggle" data-toggle="collapse" data-parent="#meal_accordion" href="#meal2" >Snack</a><span className="pull-right mt5 mr3 arrows relative" data-toggle="collapse" data-parent="#meal_accordion" href="#meal2" ></span></span></h4>
                                    </div>
                                    <div id="meal2" className="panel-collapse collapse">
                                       <div className="panel-body">
                                          <div className="food_items"></div>
                                          <div className="full_width inline_block meal_data"><span><img alt="" src="/assets/images/cals48.png" /></span><span className="font15 meal_item_lft">Log Calories</span></div>
                                          <div className="full_width inline_block meal_data dropdown">
                                             <ul className="dropdown-menu journal_empty_options">
                                                <li><span>Add Addtional Foods</span></li>
                                                <li>
                                                   <span>
                                                      {/*<!-- react-text: 232 -->*/}Log Yesterday’s  {/*<!-- /react-text -->*/}<span className="selected_txt">Snack</span>
                                                   </span>
                                                </li>
                                                <li>
                                                   <span>
                                                      {/*<!-- react-text: 236 -->*/}Copy this  {/*<!-- /react-text -->*/}<span className="replace_selected_txt"></span>
                                                   </span>
                                                </li>
                                             </ul>
                                             <img alt="" src="/assets/images/plus_icon.png" /> <span className="font15 meal_item_lft"><a href="/log_food">Log Food</a></span>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="panel panel-default">
                                    <div className="panel-heading">
                                       <h4 className="panel-title ptb1"><span className="meal_plan_options show_icons"><span className="meal_name accordion-toggle" data-toggle="collapse" data-parent="#meal_accordion" href="#meal3">Lunch</span><span className="pull-right mt5 mr3 arrows relative" data-toggle="collapse" data-parent="#meal_accordion" href="#meal3"></span></span></h4>
                                    </div>
                                    <div id="meal3" className="panel-collapse collapse">
                                       <div className="panel-body">
                                          <div className="food_items"></div>
                                          <div className="full_width inline_block meal_data"><span><img alt="" src="/assets/images/cals48.png" /></span><span className="font15 meal_item_lft">Log Calories</span></div>
                                          <div className="full_width inline_block meal_data dropdown">
                                             <ul className="dropdown-menu journal_empty_options">
                                                <li><span>Add Addtional Foods</span></li>
                                                <li>
                                                   <span>
                                                      {/*<!-- react-text: 260 -->*/}Log Yesterday’s  {/*<!-- /react-text -->*/}<span className="selected_txt">Lunch</span>
                                                   </span>
                                                </li>
                                                <li>
                                                   <span>
                                                      {/*<!-- react-text: 264 -->*/}Copy this  {/*<!-- /react-text -->*/}<span className="replace_selected_txt"></span>
                                                   </span>
                                                </li>
                                             </ul>
                                             <img alt="" src="/assets/images/plus_icon.png" /> <span className="font15 meal_item_lft"><a href="/log_food">Log Food</a></span>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="panel panel-default">
                                    <div className="panel-heading">
                                       <h4 className="panel-title ptb1"><span className="meal_plan_options show_icons"><span className="meal_name accordion-toggle" data-toggle="collapse" data-parent="#meal_accordion" href="#meal4" >Snack</span><span className="pull-right mt5 mr3 arrows relative" data-toggle="collapse" data-parent="#meal_accordion" href="#meal4" ></span></span></h4>
                                    </div>
                                    <div id="meal4" className="panel-collapse collapse">
                                       <div className="panel-body">
                                          <div className="food_items"></div>
                                          <div className="full_width inline_block meal_data"><span><img alt="" src="/assets/images/cals48.png" /></span><span className="font15 meal_item_lft">Log Calories</span></div>
                                          <div className="full_width inline_block meal_data dropdown">
                                             <ul className="dropdown-menu journal_empty_options">
                                                <li><span>Add Addtional Foods</span></li>
                                                <li>
                                                   <span>
                                                      {/*<!-- react-text: 288 -->*/}Log Yesterday’s  {/*<!-- /react-text -->*/}<span className="selected_txt">Snack</span>
                                                   </span>
                                                </li>
                                                <li>
                                                   <span>
                                                      {/*<!-- react-text: 292 -->*/}Copy this  {/*<!-- /react-text -->*/}<span className="replace_selected_txt"></span>
                                                   </span>
                                                </li>
                                             </ul>
                                             <img alt="" src="/assets/images/plus_icon.png" /> <span className="font15 meal_item_lft"><a href="/log_food">Log Food</a></span>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="panel panel-default">
                                    <div className="panel-heading">
                                       <h4 className="panel-title ptb1"><span className="meal_plan_options show_icons"><span className="meal_name accordion-toggle" data-toggle="collapse" data-parent="#meal_accordion" href="#meal5">Dinner</span><span className="pull-right mt5 mr3 arrows relative" data-toggle="collapse" data-parent="#meal_accordion" href="#meal5"></span></span></h4>
                                    </div>
                                    <div id="meal5" className="panel-collapse collapse">
                                       <div className="panel-body">
                                          <div className="food_items"></div>
                                          <div className="full_width inline_block meal_data"><span><img alt="" src="/assets/images/cals48.png" /></span><span className="font15 meal_item_lft">Log Calories</span></div>
                                          <div className="full_width inline_block meal_data dropdown">
                                             <ul className="dropdown-menu journal_empty_options">
                                                <li><span>Add Addtional Foods</span></li>
                                                <li>
                                                   <span>
                                                      {/*<!-- react-text: 316 -->*/}Log Yesterday’s  {/*<!-- /react-text -->*/}<span className="selected_txt">Dinner</span>
                                                   </span>
                                                </li>
                                                <li>
                                                   <span>
                                                      {/*<!-- react-text: 320 -->*/}Copy this  {/*<!-- /react-text -->*/}<span className="replace_selected_txt"></span>
                                                   </span>
                                                </li>
                                             </ul>
                                             <img alt="" src="/assets/images/plus_icon.png" /> <span className="font15 meal_item_lft"><a href="/log_food">Log Food</a></span>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="panel panel-default">
                                    <div className="panel-heading">
                                       <h4 className="panel-title ptb1"><span className="meal_plan_options show_icons"><span className="meal_name accordion-toggle" data-toggle="collapse" data-parent="#meal_accordion" href="#meal6" >Snack</span><span className="pull-right mt5 mr3 arrows relative" data-toggle="collapse" data-parent="#meal_accordion" href="#meal6" ></span></span></h4>
                                    </div>
                                    <div id="meal6" className="panel-collapse collapse">
                                       <div className="panel-body">
                                          <div className="food_items"></div>
                                          <div className="full_width inline_block meal_data"><span><img alt="" src="/assets/images/cals48.png" /></span><span className="font15 meal_item_lft">Log Calories</span></div>
                                          <div className="full_width inline_block meal_data dropdown">
                                             <ul className="dropdown-menu journal_empty_options">
                                                <li><span>Add Addtional Foods</span></li>
                                                <li>
                                                   <span>
                                                      {/*<!-- react-text: 344 -->*/}Log Yesterday’s  {/*<!-- /react-text -->*/}<span className="selected_txt">Snack</span>
                                                   </span>
                                                </li>
                                                <li>
                                                   <span>
                                                      {/*<!-- react-text: 348 -->*/}Copy this  {/*<!-- /react-text -->*/}<span className="replace_selected_txt"></span>
                                                   </span>
                                                </li>
                                             </ul>
                                             <img alt="" src="/assets/images/plus_icon.png" /> <span className="font15 meal_item_lft"><a href="/log_food">Log Food</a></span>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className="meal_section_right pull-right">
                                 <div className="meal_right_con">
                                    <div className="meal_right_sec_gap cursor_pointer">
                                       <h5 className="pull-left">VEGETABLE </h5>
                                       <span className="pull-right"><img alt="" src="/assets/images/arrow_up_grey.png" /></span>
                                    </div>
                                    <div className="meal_right_sec_gap">
                                       <div className="pull-left meal_add_icon">
                                          <div className="horizontal_veggie" >
                                             <ul>
                                                <li><img alt="" src="/assets/images/v_off.png" /></li>
                                                <li><img alt="" src="/assets/images/v_off.png" /></li>
                                                <li><img alt="" src="/assets/images/v_off.png" /></li>
                                                <li><img alt="" src="/assets/images/v_off.png" /></li>
                                             </ul>
                                          </div>
                                          <div className="vertical_veggie" >
                                             <ul className="margin0">
                                                <li>
                                                   <span>
                                                      <img alt="" src="/assets/images/v_off.png" /> {/*<!-- react-text: 376 -->*/} Vegetable {/*<!-- /react-text -->*/}
                                                   </span>
                                                   <span><img alt="" src="/assets/images/plus_icon.png" /></span>
                                                </li>
                                                <li>
                                                   <span>
                                                      <img alt="" src="/assets/images/v_off.png" /> {/*<!-- react-text: 382 -->*/} Vegetable {/*<!-- /react-text -->*/}
                                                   </span>
                                                   <span><img alt="" src="/assets/images/plus_icon.png" /></span>
                                                </li>
                                                <li>
                                                   <span>
                                                      <img alt="" src="/assets/images/v_off.png" /> {/*<!-- react-text: 388 -->*/} Vegetable {/*<!-- /react-text -->*/}
                                                   </span>
                                                   <span><img alt="" src="/assets/images/plus_icon.png" /></span>
                                                </li>
                                                <li>
                                                   <span>
                                                      <img alt="" src="/assets/images/v_off.png" /> {/*<!-- react-text: 394 -->*/} Vegetable {/*<!-- /react-text -->*/}
                                                   </span>
                                                   <span><img alt="" src="/assets/images/plus_icon.png" /></span>
                                                </li>
                                             </ul>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="clear"></div>
                                 <div className="meal_right_con">
                                    <div className="meal_right_sec_gap">
                                       <h5 className="pull-left">
                                          {/*<!-- react-text: 401 -->*/}ACTIVITY  {/*<!-- /react-text -->*/}<span className="daily_goal">&nbsp;&nbsp;Daily Goal: 30 min</span>
                                       </h5>
                                       <span className="pull-right dropdown">
                                          <img alt="" src="/assets/images/plus_icon.png" />
                                          <ul className="dropdown-menu journal_empty_options align_indicator">
                                             <li><span>Log Additional Food</span></li>
                                             <li><span>Log Yesterday’s Activity</span></li>
                                             <li><span>Copy All Activities</span></li>
                                          </ul>
                                       </span>
                                    </div>
                                    <div className="meal_right_sec_gap">
                                       <div className="pull-left add_activity"><img alt="" src="/assets/images/activity_off.png" /></div>
                                       <div className="pull-left mt2">
                                          <div className="font15">Add Activity</div>
                                          <div className="font12">30 min left</div>
                                       </div>
                                       <div className="pull-right mt5"><img alt="" src="/assets/images/search_small_grey.png" /></div>
                                    </div>
                                 </div>
                                 <div className="clear"></div>
                                 <div className="meal_right_con">
                                    <div className="meal_right_sec_gap">
                                       <h5 className="pull-left">
                                          {/*<!-- react-text: 424 -->*/}WATER  {/*<!-- /react-text -->*/}
                                          <span className="daily_goal">
                                             {/*<!-- react-text: 426 -->*/}&nbsp;&nbsp; {/*<!-- /react-text -->*/}<span className="water_level">0</span> {/*<!-- react-text: 428 -->*/} oz. each {/*<!-- /react-text -->*/}
                                          </span>
                                       </h5>
                                    </div>
                                    <div className="meal_right_sec_gap">
                                       <div className="pull-left">
                                          <div><span className="add_glass"></span><span className="empty_glass"></span><span className="empty_glass"></span><span className="empty_glass"></span><span className="empty_glass"></span><span className="empty_glass"></span><span className="empty_glass"></span><span className="empty_glass"></span></div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <footer>
                              <p className="nutri_foot_hdr pull-left foot_lft_hdr font16">NuMi by Nutrisystem</p>
                              <ul className="foot_right_hournal text-right text-lft">
                                 <li><a href="https://leaf.nutrisystem.com/faqs">FAQ</a></li>
                                 <li><a href="http://leaf.nutrisystem.com/privacy-policy/">Privacy Policy</a></li>
                                 <li><a href="http://leaf.nutrisystem.com/terms-and-conditions/">Terms &amp; Conditions</a></li>
                                 <li><a href="https://itunes.apple.com/app/apple-store/id826749176?pt=328600&ct=mobilegate&mt=8"><img alt="" src="/assets/images/app_store.png" /></a></li>
                                 <li><a href="https://play.google.com/store/apps/details?id=com.nutrisystem.numi"><img alt="" src="/assets/images/google_play.png" /></a></li>
                              </ul>
                              <p>NuMi and NUTRISYSTEM and related trademarks are registered trademarks and trademarks in the United States and/or other countries, used under license from Nutri/System IPHC, Inc. All other trademarks are trademarks or registered trademarks of their respective owners.</p>
                              <p className="pull-left footer_body_rights_reserved_text">© 2017 Nutrisystem, Inc. All Rights Reserved.</p>
                              <p className="pull-right footer_body_address_text">Nutrisystem, Inc., 1100 Virginia Drive Suite 175, Fort Washington, PA 19034</p>
                           </footer>
                        </div>
                        <div className="modal fade" id="old_numi_content">
                           <div className="modal-dialog">
                              <div className="modal-content">
                                 <div className="modal-header"><button type="button" className="close" data-dismiss="modal" aria-label="Close"><img alt="" src="/assets/images/close_grey.png" /></button></div>
                                 <div className="modal-body">
                                    <img alt="" src="/assets/images/view_only.png" />
                                    <p className="font26 color3 mt5 font700">View Only mode on your journal from old NuMi</p>
                                    <p className="font16 color6">Please note that your journal from old NuMi is not editable since you upgraded to current version.</p>
                                    <p><input type="submit" className="mt6" value="OK" /></p>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="modal fade" id="turbo_takeoff">
                           <div className="modal-dialog">
                              <div className="modal-content">
                                 <div className="modal-header"><button type="button" className="close" data-dismiss="modal" aria-label="Close"><img alt="" src="/assets/images/close_grey.png" /></button></div>
                                 <div className="modal-body width80">
                                    <img alt="" src="/assets/images/transition_fireworks.png" />
                                    <p className="font26 color3 mt5 font700">
                                       {/*<!-- react-text: 481 -->*/}Congratulations for  {/*<!-- /react-text -->*/}<br /> {/*<!-- react-text: 483 -->*/} finishing Turbo Take Off! {/*<!-- /react-text -->*/}
                                    </p>
                                    <h6 className="mt color6">
                                       {/*<!-- react-text: 485 -->*/}You have completed the Turbo Takeoff week. You can now use NuMi to log all your food including Nutrisystem Entrees, Snacks, SmartCarbs and PowerFuels! {/*<!-- /react-text -->*/}<br /> {/*<!-- react-text: 487 -->*/}  {/*<!-- /react-text -->*/}<span className="phase_color">Find out what SC/PF are.</span>
                                    </h6>
                                    <p className="mt6"><button className="buuton280 tour-change-to-new-stage-key-strings"></button></p>
                                    <p className="mt2" data-dismiss="modal"><button className="buuton280 change_phase_btn">Stay on Turbo Take Off</button></p>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="modal fade" id="goal_reached">
                           <div className="modal-dialog">
                              <div className="modal-content">
                                 <div className="modal-header"><button type="button" className="close" data-dismiss="modal" aria-label="Close"><img alt="" src="/assets/images/close_grey.png" /></button></div>
                                 <div className="modal-body width80">
                                    <img alt="" src="/assets/images/transition_fireworks.png" />
                                    <p className="font26 color3 mt5 font700">
                                       {/*<!-- react-text: 502 -->*/}Congratulations for  {/*<!-- /react-text -->*/}<br /> {/*<!-- react-text: 504 -->*/}hitting your goal weight! {/*<!-- /react-text -->*/}
                                    </p>
                                    <h6 className="mt color6">
                                       {/*<!-- react-text: 506 -->*/}You have hit your goal weight! {/*<!-- /react-text -->*/}<br /> {/*<!-- react-text: 508 -->*/} Continue your journey by setting a new goal or change to Success!    {/*<!-- /react-text -->*/}<span className="phase_color">Learn More about Success</span>
                                    </h6>
                                    <p className="mt6"><button className="buuton280">Change to Nutrisystem Success</button></p>
                                    <p className="mt2" data-dismiss="modal"><button className="buuton280">Set a New Goal</button></p>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="modal fade" id="finish_nutrikit">
                           <div className="modal-dialog">
                              <div className="modal-content">
                                 <div className="modal-header"><button type="button" className="close" data-dismiss="modal" aria-label="Close"><img alt="" src="/assets/images/close_grey.png" /></button></div>
                                 <div className="modal-body width77">
                                    <img alt="" src="/assets/images/transition_fireworks.png" />
                                    <p className="font26 color3 mt5 font700">
                                       {/*<!-- react-text: 523 -->*/}Congratulations for  {/*<!-- /react-text -->*/}<br /> {/*<!-- react-text: 525 -->*/}finishing your Nutrisytem Kit! {/*<!-- /react-text -->*/}
                                    </p>
                                    <h6 className="mt color6">
                                       {/*<!-- react-text: 527 -->*/}Please select an option for how you would like to continue to use NuMi. Please note that Calorie Tracker is a basic calorie counter that you will diet on your own.  {/*<!-- /react-text -->*/}<span className="phase_color">Learn More about Nutrisystem Plans</span>
                                    </h6>
                                    <p className="mt6"><button className="buuton280">Select New Plan</button></p>
                                    <p className="mt2" data-dismiss="modal"><button className="buuton280 change_phase_btn">Stay on CURRENT_PLAN</button></p>
                                    <p className="mt2" data-dismiss="modal"><button className="buuton280 change_phase_btn">Change to Calorie Tracker</button></p>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="modal fade" id="quick_log">
                           <div className="modal-dialog">
                              <div className="modal-content">
                                 <div className="modal-header"><button type="button" className="close" data-dismiss="modal" aria-label="Close"><img alt="" src="/assets/images/close_grey.png" /></button></div>
                                 <div className="modal-body width77">
                                    <img alt="" src="/assets/images/quick_log.png" />
                                    <p className="font26 color3 mt5 font700">Quick Log</p>
                                    <h6 className="mt color6">Quick Logging provides a simple and easy way to stay on-plan! Simply track your food category items instead of the exact foods you eat. If you’re eating the recommended foods you are on-plan!</h6>
                                    <p className="mt6"><button className="">OK</button></p>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="modal fade" id="unlog_borrow_food">
                           <div className="modal-dialog">
                              <div className="modal-content">
                                 <div className="modal-header"><button type="button" className="close" data-dismiss="modal" aria-label="Close"><img alt="" src="/assets/images/close_grey.png" /></button></div>
                                 <div className="modal-body">
                                    <img alt="" src="/assets/images/warning_tri.png" />
                                    <p className="font26 color3 mt5 font700">Do you want to unlog this borrowed food?</p>
                                    <p className="font16 color6">If you unlog, you will lose your speficied food item and this category will go back to original meal.</p>
                                    <p><button>Yes</button><button>Cancel</button></p>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="modal fade" id="manage_meals">
                           <div className="modal-dialog">
                              <div className="modal-content padding0">
                                 <div className="modal-header"><button type="button" className="close" data-dismiss="modal" aria-label="Close"><img alt="" src="/assets/images/close_grey.png" /></button></div>
                                 <div className="modal-body  padding0">
                                    <p className="font26 color3 mt5 font700">Manage Meals</p>
                                    <h6 className="mt color6 choose_category">
                                       {/*<!-- react-text: 569 -->*/}Choose your default meal type for  {/*<!-- /react-text -->*/}<b>Breakfast</b> {/*<!-- react-text: 571 -->*/}. {/*<!-- /react-text -->*/}
                                    </h6>
                                    <ul className="flex_meal_list mt6">
                                       <li className="width100 display_block">
                                          <span className="pull-left flex_span width50 display_block">
                                             <span className="font16 color6">Flex Meal: Off</span>
                                             <span>
                                                <img alt="" src="/assets/images/ne_tag.png" /> {/*<!-- react-text: 578 -->*/}&nbsp; {/*<!-- /react-text -->*/}<img alt="" src="/assets/images/ex_tag.png" />
                                             </span>
                                          </span>
                                          <span className="pull-right">
                                             <div className="radio_select mt-10 width50 display_block"><input type="radio" className="checbox_normal" id="multi_check1" name="food_item"
                                             /><label htmlFor="multi_check1" className="mt"><span><i></i></span></label></div>
                                          </span>
                                       </li>
                                       <li className="width100 display_block">
                                          <span className="pull-left flex_span width50 display_block">
                                             <span className="font16 color6">Flex Meal: On</span>
                                             <span>
                                                <img alt="" src="/assets/images/ne_tag.png" /> {/*<!-- react-text: 591 -->*/}&nbsp; {/*<!-- /react-text -->*/}<img alt="" src="/assets/images/ex_tag.png" />
                                             </span>
                                          </span>
                                          <span className="pull-right">
                                             <div className="radio_select mt-10 width50 display_block"><input type="radio" className="checbox_normal" id="multi_check2" name="food_item"
                                             /><label htmlFor="multi_check2"><span><i></i></span></label></div>
                                          </span>
                                       </li>
                                       <li className="width100 display_block">
                                          <span className="pull-left flex_span width50 display_block">
                                             <span className="font16 color6">Flex Meal: 2 On</span>
                                             <span>
                                                <img alt="" src="/assets/images/ne_tag.png" /> {/*<!-- react-text: 604 -->*/}&nbsp; {/*<!-- /react-text -->*/}<img alt="" src="/assets/images/ex_tag.png" />
                                             </span>
                                          </span>
                                          <span className="pull-right">
                                             <div className="radio_select mt-10 width50 display_block"><input type="radio" className="checbox_normal" id="multi_check3" name="food_item"
                                             /><label htmlFor="multi_check3"><span><i></i></span></label></div>
                                          </span>
                                       </li>
                                    </ul>
                                    <p className="ptb3"><button className="margin0">Save</button></p>
                                 </div>
                              </div>
                           </div>

                        </div>
                        <div className="modal fade" id="log_time">
                           <div className="modal-dialog">
                              <div className="modal-content">
                                 <div className="modal-header"><button type="button" className="close" data-dismiss="modal" aria-label="Close"><img alt="" src="/assets/images/close_grey.png" /></button></div>
                                 <div className="modal-body width77">
                                    <p className="font26 color3 mt5 font700">Log Time</p>
                                    <p>
                                       <input type="text"
                                          className="mt5 log_time_input" /> {/*<!-- react-text: 624 -->*/} min {/*<!-- /react-text -->*/}
                                    </p>
                                    <p className="mt5"><button className="">Save</button></p>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

         </div>




      )
   }
}



export default Tour;
