
import React from 'react';
import { Link } from 'react-router-dom'
import Footer from "../footer.js";
import Header from "../header.js";
import Navigation from "../nav.js";
import Adspeed from "../adspeed.js";
import axios from 'axios';
import $ from 'jquery';
import * as Sentry from "@sentry/react";

class Milestone extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      start_weight: window.localStorage.getItem('progres_starting'), current_weight: window.localStorage.getItem('progres_current_weight'), milestone_histories: '', goal: window.localStorage.getItem('progres_goal'), next_lbs: '', lbs_left: ''
    };
  }

  getMileStones = () => {
    const options = {
      url: window.API_URL + "v1/milestone_histories",
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        this.setState({ milestone_histories: response.data })
        if (this.state.milestone_histories) {
          this.setState({ next_lbs: this.state.milestone_histories.next_milestone, lbs_left: this.state.milestone_histories.lbs_left_for_next_milestone });
          if (this.state.next_lbs === window.keystrings['com.numi.k_none_next_mile_stone']) {
            $(".milestone_lbs").hide();
          }
        }
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });

  }

  componentDidMount() {
    // On load calling MileStones API
    /*key string*/
    $('.back_to_progress_key').html(window.keystrings['com.numi.milestone.back_to_progress']);
    $('.my_milestone_title').text(window.keystrings['com.numi.mile_stone_title']);
    $('.milestone_lbs_left').text(window.keystrings['com.numi.lbs_left']);
    $('.milestone_to_reach_next').text(window.keystrings['com.numi.milestone.to_reach_next_milestone']);
    $('.next_milestone_text').text(window.keystrings['com.numi.next_milestone']);
    $('.milestone_all_time').text(window.keystrings['com.numi.all_time_starting_weight']);
    $('.milestones_current_weight').text(window.keystrings['com.numi.progress_current_weight']);
    $('.milestone_lbs').text(window.keystrings['com.numi.lbs']);
    $('.milestone_lbs1').text(window.keystrings['com.numi.lbs']);
    $('.milestone_lbs2').text(window.keystrings['com.numi.lbs']);
    $('.milestone a').prop('href', window.API_URL + window.localStorage.getItem('new_upload_path') + '&view=images_view&browser=true');
    /*key string*/
    this.getMileStones();
  }

  render() {
    if (this.state.milestone_histories !== '') {
      var milestone_histories = this.state.milestone_histories.milestone_histories.map(function (option, i) {

        return (
          <li className="color6 font15 weight_li" key={i}><p style={{ backgroundImage: 'url("/assets/images/trophy_earned.png")' }}>{option.milestone_lbs}</p>
            <p className="color64 font14"><span className="milestone_you_lost"></span> {option.milestone_lbs} <span className="milestone_lbs_a"></span></p></li>
        )
      });
      $('.milestone_you_lost').text(window.keystrings['com.numi.milestone.you_lost']);
      $('.milestone_lbs_a').text(window.keystrings['com.numi.milestone.lbs']);
    }


    return (
      <div>

        <div className="table_display">
          <div className="journal_div progress" id="progress_page">
            <div className="section_left pull-left">
              <Navigation />
            </div>
            <div className="section_right pull-left">
              <figure><Adspeed /></figure>
              <div className="journal_rht">
                <div className="pull-right mt2 my_mile_rht">
                  <span className="milestone font15">
                    <Link to="/photos" className="color5"><img alt="" src="/assets/images/add_photo_green.png" className="vlb" /> &nbsp; Add Photos</Link>
                  </span>
                </div>
                <p className="mb">
                  <Link to="/progress" className="back_to"><img alt="" src="/assets/images/back_white_icon.png" className="vlb" /> Back to Progress</Link>
                </p>
                <h1 className="journal_hdr pull-left my_milestone_title"><sup><img alt="" src="/assets/images/education.png" /></sup></h1>

                <div className="clear"></div>
                <hr />
                <div className="row clearfix">
                  <div className="col-md-12 col-12 flex padding0">
                    <div className="col-md-8 col-12">
                      <div className="border1">
                        <div className="padding20">
                          <p className="color6 font22 "><b>{this.state.lbs_left} <span className="milestone_lbs_left"></span></b> <span className="milestone_to_reach_next"></span></p>
                          <p className="color_80 font16"><span className="next_milestone_text"></span> <b> {this.state.next_lbs} <span className="milestone_lbs"></span></b></p>
                        </div>
                      </div>
                      <ul className="trophy padding20" >
                        {milestone_histories}
                      </ul>
                    </div>

                    <div className="col-md-4 col-12">
                      <div className="border1 flex_between rightmilestone">
                        <div className="color6">
                          <p className="font14 color8f margin0 milestone_all_time"></p>
                          <p className="font20 font64"><b>{this.state.start_weight} <span className="milestone_lbs1"></span></b></p>

                        </div>
                        <div className="color6">
                          <p className="font14 color8f margin0 milestones_current_weight"></p>
                          <p className="font20 font64"><b>{this.state.current_weight} <span className="milestone_lbs2"></span></b></p>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div >
    )
  }
}

export default Milestone;
