import React from 'react';
import ReactDOM from 'react-dom';
import DatePicker from "react-datepicker";
import Header from "../header.js";
import MealDropdown from "./meal_dropdown.js";
import moment from 'moment';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { addDays } from 'date-fns';
import $ from 'jquery';
import * as Sentry from "@sentry/react";


export class CountAsEditValidate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      date: moment(),
      startDate: new Date()
    };
  }

  componentDidMount() {
    $(".serving_size_title").text(window.keystrings['com.numi.food_details.serving_size_to_one']);
    $(".serving_size_desc").text(window.keystrings['com.numi.food_details.set_serving_size']);
    $('.set_serving_size').click(function (e) {
      e.stopImmediatePropagation();
      $(".weight_validate").val("1");
      if ($(".food_details_page").length) {
        this.props.callParentHandleInput();
      } else if ($(".create_food_page").length) {
        /*this.props.callParentCreateHandleInput();*/
      } else {
        this.props.callParentEditHandleInput();
      }
      $('#count_as_validate').modal('hide');
      /*setTimeout(function(){
         if($(".food_details_page").length){
            $(".edit_txt_food_details").click();
         } else if($(".create_food_page").length){
            $(".click_edit").click();
         } else {
            $(".edit_food_edit_txt").click();
         }
       },500)*/
    }.bind(this));
  }

  render() {
    return (

      <div className="modal fade" id="count_as_validate">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><img alt="" src="/assets/images/close_grey.png" /></button>
            </div>
            <div className="modal-body">
              <p className="font26 color3 mt5 font700 serving_size_title"></p>
              <p className="font16 color6 serving_size_desc"></p>
              <p><button className="set_serving_size">Yes</button><button data-dismiss="modal" aria-label="Close">Cancel</button></p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


export class CopyFoodPopup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      date: moment(),
      startDate: moment()
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(date) {
    this.setState({ startDate: date });
  }

  componentDidMount() {
    $('.drop_txt li').click(function () {
      $('.select_replace').html($(this).html());
    });
    $('.copy_meal').click(function () {
      $('.copy_valid,#copy_food .close').hide();
      $('.copy_not_valid').show();
    })
    Header.getTurboTakeoffDays();
  }

  render() {
    return (

      <div className="modal fade">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><img alt="" src="/assets/images/close_grey.png" /></button>
            </div>
            <div className="modal-body">
              <div className="copy_valid">
                <h3 className="modal-title">Copy Breakfast</h3>
                <p className="font15">Your Breakfast from <b>Apr 05</b> will be copied to <b>Apr 06</b></p>
                <div className="relative date_pick">
                  <span className="left_sec font15">Date</span>
                  <DatePicker />
                </div>
                <div className="relative date_pick">
                  <span className="left_sec font15">Meal</span>
                  <MealDropdown />
                </div>
                <input type="submit" className="copy_meal mt6" value="Copy Meal" />
              </div>
            </div>
            <div className="copy_not_valid">
              <img alt="" src="/assets/images/warning_tri.png" />
              <p className="font26 color3 mt5 font700">Copying not vaild</p>
              <p className="font16 color6">You can’t log /copy meal since you had a different plan yesterday.</p>
              <p><button>OK</button></p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


var sampleModalId = 'copy_food';
export class Copyfood_popup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    };
    this.showCopyFoodModal = this.showCopyFoodModal.bind(this);
    this.hideCopyFoodModal = this.hideCopyFoodModal.bind(this);
  }
  componentDidMount() { }

  showCopyFoodModal() {
    var modalContainer = document.createElement('div');
    modalContainer.id = sampleModalId;
    document.body.appendChild(modalContainer);
    ReactDOM.render(<CopyFoodPopup />, modalContainer, function () {
      var modalObj = $('#' + sampleModalId).find('.modal');
      modalObj.modal('show');
      modalObj.on('hidden.bs.modal', this.hideCopyFoodModal);
    }.bind(this));
  }
  hideCopyFoodModal() {
    $('#' + sampleModalId).remove();
  }
  render() {
    return (
      <span onClick={this.showCopyFoodModal}>* Copy this <span className="replace_selected_txt"></span></span>
    )
  }
};

export class CaloriePopup extends React.Component {
  constructor(props) {
    super(props)
    this.state = { inputValue: '' };
    this.inputChange = this.inputChange.bind(this);
  }
  inputChange(e) {
    this.setState({
      inputValue: e.target.value
    });
  }

  render() {
    return (

      <div className="modal fade">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header calorie">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><img alt="" src="/assets/images/close_grey.png" /></button>
              <b><h3 className="modal-title">Log Calories</h3></b>
            </div>
            <div className="modal-body">
              <span className="font16">Calories</span><span className=""><input type="text" value={this.state.inputValue} onChange={this.inputChange} className="plft font15 calorie_input" /></span>
              <p><input type="submit" className="mt6 log_calories_save" data-dismiss="modal" value="Save" /></p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


var CalorieId = 'calorie_modal';
export class CalorieComp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    };
    this.showCaloriepoup = this.showCaloriepoup.bind(this);
    this.hideCaloriepoup = this.hideCaloriepoup.bind(this);
  }

  showCaloriepoup() {
    var modalContainer = document.createElement('div');
    modalContainer.id = CalorieId;
    document.body.appendChild(modalContainer);
    ReactDOM.render(<CaloriePopup />, modalContainer, function () {
      var modalObj = $('#' + CalorieId).find('.modal');
      modalObj.modal('show');
      modalObj.on('hidden.bs.modal', this.hideCaloriepoup);
    }.bind(this));
  }
  hideCaloriepoup() {
    $('#' + CalorieId).remove();
  }
  render() {
    return (
      <span className="cursor_pointer"><img alt="" src="/assets/images/cals48.png" /></span>
    )
  }
}

export class OldNumiPopup extends React.Component {
  render() {
    return (

      <div className="modal fade" id="old_numi_content">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><img alt="" src="/assets/images/close_grey.png" /></button>
            </div>
            <div className="modal-body">
              <img alt="" src="/assets/images/view_only.png" />
              <p className="font26 color3 mt5 font700">View Only mode on your journal from old NuMi</p>
              <p className="font16 color6">Please note that your journal from old NuMi is not editable since you upgraded to current version.</p>
              <p><input type="submit" data-dismiss="modal" className="mt6" value="OK" /></p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export class AlertPopup extends React.Component {
  render() {
    return (

      <div className="modal fade" id="alert_content">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close"><i className="fa fa-close close_alert colorff" data-dismiss="modal"></i></button>
            </div>
            <div className="alert_divs">
              <div className="modal-body">
                <p className="alert_icon"></p>
                <p className="font26  font700 alert_subject"></p>
                <p className="font16  alert_body"></p>
                <p className="font16 colorff alert_link"></p>
                <ul className="font16 colorff alert_button"></ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export class ActivityLevelPopup extends React.Component {
  constructor(props) {
    super(props)

    this.state = { gender_radio: '', success_phase_radio: 'greater_than_60', disable_btn: 'btn disabled', disable: 'disabled' }
    this.radio_toggle = this.radio_toggle.bind(this);
  }

  radio_toggle(e) {
    this.setState({
      success_phase_radio: e.target.id
    });
  }

  componentDidMount() {
    $(document).on('click', '.success_phase label', function () {
      $('.success_phase label').css('opacity', '0.4');
      $(this).css('opacity', '1');
    });
    $(document).on('click', '#activity_level_submit', function (e) {
      e.stopImmediatePropagation();

      var value = $('.success_phase').find('input[type="radio"]:checked').val();
      var transition_determinant_params = { "transition_determinant": { "json_detail": { "ACTIVITY_LEVEL": value } } };
      localStorage.removeItem('restart_plan_phase_id');
      const transition_options = {
        url: window.API_URL + "v1/transition_determinants",
        method: 'POST',
        credentials: 'same-origin',
        headers: Header.setAuthHeaders(),
        data: transition_determinant_params
      };

      axios(transition_options)
        .then(response => {
          Header.stopLoader();
          var next_determinant = response.data.transition_determinant;
          if (next_determinant.misc_data.is_qvc === false) {
            $('.week_one_txt').html(window.keystrings['com.numi.first_week_on_nutri']);
          } else if (next_determinant.misc_data.is_qvc === true) {
            $('.week_one_txt').html(window.keystrings['com.numi.first_week_qvc']);
          } else {
            $('.week_one_txt').html(window.keystrings['com.numi.first_week_qvc']);
          }


          localStorage.removeItem('restart_plan_phase_id');
        })
        .catch(error => {
          Header.stopLoader();
          Header.apiErrors(error);
          Sentry.captureException(error);
        });

      const options = {
        url: window.API_URL + window.localStorage.getItem('transition_url_goal_weight'),
        method: 'GET',
        credentials: 'same-origin',
        headers: Header.setAuthHeaders(),
      };

      axios(options)
        .then(response => {
          $('#activity_level_content').modal('hide');
          localStorage.removeItem("transition_url_goal_weight");
          window.location.reload();
        })
        .catch(error => {
          Header.stopLoader();
          Header.apiErrors(error);
          Sentry.captureException(error);
        });


    });
    /*key strings */
    $('.stage-options-keys-string').text(window.keystrings['com.numi.journal.stage_options']);
    $('.activity_level_key').text(window.keystrings['com.numi.what_is_activity_level']);
    $('.less_than_60_key').text(window.keystrings['com.numi.activity_level_1']);
    $('.equal_60_key').text(window.keystrings['com.numi.activity_level_2']);
    $('.more_than_60_key').text(window.keystrings['com.numi.activity_level_3']);
    $('.activity_level_continue').text(window.keystrings['com.numi.continue']);
  }
  render() {
    return (

      <div className="modal fade" id="activity_level_content">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">

            </div>
            <div className="modal-body success_phase get_radio">
              <div className="text-center">
                <h6 className="text-center stage-options-keys-string"> </h6>
                <h2 className="next_determinant activity_level_key"> </h2>
                <p className="less_than_60">
                  <input className="" type="radio" id="less_than_60" name="LESS_THAN_60_MINUTES" checked={this.state.success_phase_radio === "less_than_60"} onChange={this.radio_toggle} />
                  <label htmlFor="less_than_60" className="less_than_60_key"> </label>
                </p>
                <p className="equal_60">
                  <input className="" type="radio" id="equal_60" name="60_MINUTES" checked={this.state.success_phase_radio === "equal_60"} onChange={this.radio_toggle} />
                  <label htmlFor="equal_60" className="equal_60_key"></label>
                </p>
                <p className="greater_than_60">
                  <input className="" type="radio" id="greater_than_60" name="GREATER_THAN_60_MINUTES" checked={this.state.success_phase_radio === "greater_than_60"} onChange={this.radio_toggle} />
                  <label htmlFor="greater_than_60" className="more_than_60_key"></label>
                </p>
                <button id="activity_level_submit" className="activity_level_continue"></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export class TurboTakeoffPopup extends React.Component {
  constructor(props) {
    super(props)

    this.state = { gender_radio: '', turbo_takeoff: false, disable_btn: 'btn disabled', disable: 'disabled', turbostartDate: addDays(new Date(), localStorage.getItem("TURBO_TAKEOFF_DAYS")) }
    this.radio_toggle = this.radio_toggle.bind(this);
    this.clickYes = this.clickYes.bind(this);
    this.clickNo = this.clickNo.bind(this);
    this.handleChange = this.handleChange.bind(this);

  }
  clickYes(e) {
    this.setState({
      turbo_takeoff: true, disable_btn: '', disable: ''
    });
  }

  clickNo(e) {
    this.setState({
      turbo_takeoff: false, disable_btn: '', disable: ''
    });
  }
  handleChange(date) {
    this.setState({
      turbostartDate: date
    });
    Header.changeDate(date);
  }

  radio_toggle(e) {
    this.setState({
      gender_radio: e.target.id
    });
    if ($('#turbo_takeoff_content').is(':visible')) {
      this.setTurboDate()
    }
  }
  submitTurboTakeoff(e) {
    Header.startLoader();
    var value = $('.turbo_phase_option').find('input[type="radio"]:checked').attr('class');

    var transition_determinant_params = { "transition_determinant": { "json_detail": { "TURBO_TAKEOFF": value, "END_DATE": moment($('.turbo_phase_option .date_pick input').val()).format('YYYY-MM-DD') } } };

    const options = {
      url: window.API_URL + "v1/transition_determinants",
      method: 'POST',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
      data: transition_determinant_params
    };

    axios(options)
      .then(response => {
        var _this = this;
        Header.stopLoader();
        if (response && response.data) {
          $('#turbo_takeoff_content').modal('hide');
          localStorage.removeItem("transition_url_goal_weight");
          if (localStorage.getItem('turboNo') === 'true' && value === 'false') {
            setTimeout(() => {
              $('#personalize_questions .modal-content').addClass('padding0');
              $('#personalize_div').addClass('from_button');
              $('#personalize_questions').modal('show');
            }, 2000);
            localStorage.setItem('set_true', 'true');
            localStorage.setItem('set_button_true', 'true');
            localStorage.setItem('button_url_next_determinant', response.data.transition_determinant.next_determinant);
            localStorage.setItem('button_url_response', JSON.stringify(response.data.transition_determinant));
            document.querySelector('.quiz_back').style.display = 'none';
            localStorage.removeItem('turboNo');

          }
          else {
            $('#personalize_questions .modal-content').removeClass('padding0');
            $('#personalize_div').removeClass('from_button');
            $('#personalize_div').hide();
            if (response.data.transition_determinant.next_determinant === 'CIPD_RANGE') {
              $('#activity_questions .modal-header').show();
              $('.text-green-darker').find('img').each(function () {
                if ($(this).parent().hasClass('active')) {
                  $(this).attr('src', $(this).attr('active_image'));
                  $(this).parent().css('opacity', 1).addClass('active');
                }
                else {
                  $(this).attr('src', $(this).attr('normal_image'));
                  $(this).parent().css('opacity', 0.3).removeClass('active');
                }
              });
              $('#activityGoal .row > div').hide();
              if (localStorage.getItem('check_transition_type') === 'CIPD_TRANSITION_ALERT') {
                $('#activityGoal .row > div:eq(1)').show();
              }
              else {
                $('#activityGoal .row > div:eq(0)').show();
              }

              $('.calculation,.buffer').hide();
              $('#activity_questions').modal('show');
              setTimeout(function () {
                $('body').addClass('modal-open');
              }, 1200);
            }
            else {
              if (document.URL.indexOf('/journal') > -1) {
                _this.props.callJournalAPIFromTurbo();
              }
              else if (document.URL.indexOf('/progress') > -1) {
                _this.props.callProgressAPI();
              }

            }
          }

        }
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });

    /*
            axios.get(window.API_URL + window.localStorage.getItem('transition_url_goal_weight'),{
              credentials: 'same-origin',
                headers:Header.setAuthHeaders()
              })
              .then(response => {
                $('#turbo_takeoff_content').modal('hide');
                  localStorage.removeItem("transition_url_goal_weight");
                  this.props.callJournalAPIFromTurbo();
              })
              .catch(error => {
              });
              */

  }
  setTurboDate = () => {
    setTimeout(function () {
      this.setState({
        turbostartDate: addDays(new Date(), window.localStorage.getItem("TURBO_TAKEOFF_DAYS"))
      });
      var today = moment();
      var tomorrow = moment().add(1, 'day');
      var yesterday = moment().subtract(1, 'day');

      var equal_dates = moment(this.state.turbostartDate);
      if (moment(equal_dates).isSame(tomorrow, 'day'))
        $('.turbo_phase_option').find('.today_date').text('Tomorrow');
      else if (moment(equal_dates).isSame(yesterday, 'day'))
        $('.turbo_phase_option').find('.today_date').text('Yesterday');
      else if (moment(equal_dates).isSame(today, 'day'))
        $('.turbo_phase_option').find('.today_date').text('Today');
      else
        $('.turbo_phase_option').find('.today_date').text(moment(this.state.turbostartDate).format('MMM DD'));
    }.bind(this), 2000);
  }
  componentDidMount() {

    this.setTurboDate();

    $('.phase_option label').click(function () {
      $('.phase_option label').css('opacity', '0.4');
      $(this).css('opacity', '1');
    });



    /*key strings*/
    $('.stage-options-keys-string1').text(window.keystrings['com.numi.journal.stage_options']);
    $('.turbo_week_key').html(window.keystrings['com.numi.is_first_week_turbo']);
    $('.turbo_yes_key').text(window.keystrings['com.numi.yes']);
    $('.turbo_no_key').text(window.keystrings['com.numi.no']);
    $('.turbo_off_ends_key').text(window.keystrings['com.numi.turbo_takeoff_end']);
    $('.turbo_continue_key').text(window.keystrings['com.numi.continue']);
  }
  render() {
    return (

      <div className="modal fade" id="turbo_takeoff_content">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal"><img alt="" className="close_alert" src="/assets/images/close_grey.png" /></button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <div className="turbo_phase_option phase_option">

                  <div className="phase_inner">
                    <h6 className="stage-options-keys-string1"> </h6>
                    <h3 className="next_determinant turbo_week_key"> </h3>
                    <p className="mt10p toggle_options">
                      <span className="yes" onClick={this.clickYes}>
                        <input className="true" type="radio" id="yes" value={this.state.gender_radio === "yes"} checked={this.state.gender_radio === "yes"} onChange={this.radio_toggle} />
                        <label htmlFor="yes" className="turbo_yes_key"></label>
                      </span>
                      <span className="no" onClick={this.clickNo.bind(this)}>
                        <input className="false" type="radio" id="no" value={this.state.gender_radio === "no"} checked={this.state.gender_radio === "no"} onChange={this.radio_toggle} />
                        <label htmlFor="no" className="turbo_no_key"></label>
                      </span>
                    </p>
                    <div className="turbo_takeoff mt10p">
                      <p className="week_one_txt font16"></p>
                      <div style={{ display: this.state.turbo_takeoff ? 'block' : 'none' }}>
                        <span className="turbo_take turbo_off_ends_key"></span>
                        <div className="date_pick input-group date vlm width50" >
                          <DatePicker selected={this.state.turbostartDate} onChange={this.handleChange} minDate={new Date()} maxDate={addDays(new Date(), 29)} />
                        </div>
                      </div>
                    </div>
                    <button disabled={this.state.disable} onClick={this.submitTurboTakeoff.bind(this)} className={this.state.disable_btn + " mt10p turbo_continue_key"} id="turbo_takeoff_submit"></button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export class LowBloodSugarPopup extends React.Component {

  constructor(props) {
    super(props)

    this.state = { gender_radio: '', success_phase_radio: 'greater_than_60', disable_btn: 'btn disabled', disable: 'disabled', curr_value: window.localStorage.getItem('progres_current_weight'), goal_value: window.localStorage.getItem('progres_goal') }
    this.radio_toggle = this.radio_toggle.bind(this);
  }

  radio_toggle(e) {
    this.setState({
      gender_radio: e.target.id
    });
  }

  componentDidMount() {
    $('#low_blood_sugar label').click(function () {
      $('#low_blood_sugar label').css('opacity', '0.4');
      $(this).css('opacity', '1');
      $('#low_blood_sugar button').removeClass('btn disabled').removeAttr('disabled')
    });

    $(document).on('click', '#set_low_blood_sugar_submit', function (e) {
      e.stopImmediatePropagation();
      var value = $('#low_blood_sugar').find('input[type="radio"]:checked').attr('class');
      var transition_determinant_params = { "transition_determinant": { "json_detail": { "LOW_BLOOD_SUGAR": value } } };

      const transition_options = {
        url: window.API_URL + "v1/transition_determinants",
        method: 'POST',
        credentials: 'same-origin',
        headers: Header.setAuthHeaders(),
        data: transition_determinant_params
      };

      axios(transition_options)
        .then(response => {
          Header.stopLoader();
        })
        .catch(error => {
          Header.stopLoader();
          Header.apiErrors(error);
          Sentry.captureException(error);
        });

      const options = {
        url: window.API_URL + window.localStorage.getItem('transition_url_goal_weight'),
        method: 'GET',
        credentials: 'same-origin',
        headers: Header.setAuthHeaders(),
      };

      axios(options)
        .then(response => {
          $('#low_blood_sugar').modal('hide');
          localStorage.removeItem("transition_url_goal_weight");
          window.location.reload();
        })
        .catch(error => {
          Header.stopLoader();
          Header.apiErrors(error);
          Sentry.captureException(error);
        });

    });
    /*key strings*/
    $('.stage-options-keys-string2').text(window.keystrings['com.numi.journal.stage_options']);
    $('.low_blood_sugar_key').text(window.keystrings['com.numi.blood_sugar_label']);
    $('.low_blood_sugar_desc').text(window.keystrings['com.numi.blood_sugar_recommendation']);
    $('.blood_sugar_yes_key').text(window.keystrings['com.numi.yes']);
    $('.blood_sugar_no_key').text(window.keystrings['com.numi.no']);
    $('.mailto_key').html(window.keystrings['com.numi.diabetic_query_desc']);
    $('.blood_suage_continue').text(window.keystrings['com.numi.continue']);
  }
  render() {
    return (

      <div className="modal fade " id="low_blood_sugar">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal"><img alt="" className="close_alert" src="/assets/images/close_grey.png" /></button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <h6 className="text-center stage-options-keys-string2"> </h6>
                <h3 className="next_determinant low_blood_sugar_key"> </h3>
                <h6 className="next_determinant_desc low_blood_sugar_desc"> </h6>
                <p className="meal_toggle_options">
                  <span className="blood_yes">
                    <input className="true" type="radio" id="blood_yes" checked={this.state.gender_radio === "blood_yes"} onChange={this.radio_toggle} />
                    <label htmlFor="blood_yes" className="blood_sugar_yes_key"></label>
                  </span>&nbsp;&nbsp;
                  <span className="blood_no">
                    <input className="false" type="radio" id="blood_no" checked={this.state.gender_radio === "blood_no"} onChange={this.radio_toggle} />
                    <label htmlFor="blood_no" className="blood_sugar_no_key"></label>
                  </span>
                </p>
                <p className="mailto mailto_key"></p>
                <p><button className="mt4 blood_suage_continue" id="set_low_blood_sugar_submit"></button></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


export class SetGoalWeightPopup extends React.Component {

  componentDidMount() {

    $('.plan_restart_key').text(window.keystrings['com.numi.restart_title']);
    $('.lets_do_key').text(window.keystrings['com.numi.setting.lets_do_this']);
    $('.get_started_key').text(window.keystrings['com.numi.restart_button']);
    $('.lbs_current_key').text(window.keystrings['com.numi.lbs_current']);
    $('.curre_weight_key').text(window.keystrings['com.numi.progress.current_weight']);
    $('.goal_weight_key').text(window.keystrings['com.numi.progress.goal_weight']);
    $('.lbs_goal_key').text(window.keystrings['com.numi.lbs_current']);

  }

  callGaolPopup = () => {
    var goal_weight = $(".set_goal").val();
    var curr_weight = $('#curr_weight').val();
    var assigned_date = moment().format('YYYY-MM-DD');
    var confirm_weight_params = { "weight": { "current": curr_weight, "goal": goal_weight, "assigned_date": assigned_date } };
    Header.startLoader();

    const options = {
      url: window.API_URL + "v1/weights",
      method: 'POST',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
      data: confirm_weight_params
    };
    var _this = this;
    axios(options)
      .then(response => {
        window.localStorage.setItem('progres_current_weight', curr_weight);
        window.localStorage.setItem('progres_goal', goal_weight);
        $('#set_goal_weight_popup').modal('hide');
        if (window.localStorage.getItem('transition_url_goal_weight') !== null && window.localStorage.getItem('transition_url_goal_weight') !== undefined && window.localStorage.getItem('transition_url_goal_weight') !== '') {

          var transition_determinant_params = { "transition_determinant": { "json_detail": { "GOAL_WEIGHT": goal_weight, "CURRENT_WEIGHT": curr_weight } } };
          const transition_determinant_options = {
            url: window.API_URL + "v1/transition_determinants",
            method: 'POST',
            credentials: 'same-origin',
            headers: Header.setAuthHeaders(),
            data: transition_determinant_params
          };

          axios(transition_determinant_options)
            .then(response => {
              if (response && response.data) {
                $('#set_goal_weight_popup .error_txt').text('');
                if (response.data.transition_determinant.next_determinant === "CIPD_RANGE") {
                  Header.stopLoader();
                  setTimeout(function () {
                    $('.modal').modal('hide');
                    $('#activity_questions .modal-header').show();
                    $('.text-green-darker').find('img').each(function () {
                      if ($(this).parent().hasClass('active')) {
                        $(this).attr('src', $(this).attr('active_image'));
                        $(this).parent().css('opacity', 1).addClass('active');
                      }
                      else {
                        $(this).attr('src', $(this).attr('normal_image'));
                        $(this).parent().css('opacity', 0.3).removeClass('active');
                      }
                    });
                    $('#activityGoal .row > div').hide();
                    $('#activityGoal .row > div:eq(0)').show();
                    $('.calculation,.buffer').hide();
                    $('#activity_questions').modal('show');
                    localStorage.removeItem('nutrisystem_stage');
                  }, 800);
                  setTimeout(function () {
                    $('body').addClass('modal-open');
                  }, 1200)
                }
                else {
                  setTimeout(() => {
                    if (document.URL.indexOf('/journal') > -1) {
                      if (localStorage.getItem('turboNo') === 'true') {
                        localStorage.removeItem('turboNo');
                        _this.props.callSmartAdapt();
                      }
                      _this.props.callJournalAPIFromSetGoal();
                    }
                    else if (document.URL.indexOf('/progress') > -1) {
                      if (localStorage.getItem('turboNo') === 'true') {
                        localStorage.removeItem('turboNo');
                        _this.props.callSmartAdapt();
                      }
                      _this.props.callProgressAPI();
                    }
                  }, 10);

                }
              }
            })
            .catch(error => {

              Header.stopLoader();
              $('#set_goal_weight_popup .error_txt').text('');
              if (error && error.response && error.response.data && error.response.data.error) {
                $('#set_goal_weight_popup .error_txt').text(error.response.data.error);
              }
              else if (error && error.response && error.response.status > 400) {
                $('#set_goal_weight_popup .error_txt').text(error.response.statusText);
              }
              else {
                alert(error.message ? error.message : 'Something went wrong!');
              }
              Sentry.captureException(error);
            });
        }
        else {
          setTimeout(() => {
            if (document.URL.indexOf('/journal') > -1) {
              if (localStorage.getItem('turboNo') === 'true') {
                localStorage.removeItem('turboNo');
                _this.props.callSmartAdapt();
              }
              _this.props.callJournalAPIFromSetGoal();
            }
            else if (document.URL.indexOf('/progress') > -1) {
              if (localStorage.getItem('turboNo') === 'true') {
                localStorage.removeItem('turboNo');
                _this.props.callSmartAdapt();
              }
              _this.props.callProgressAPI();
            }
          }, 10);
        }


      })
      .catch(error => {

        Header.stopLoader();
        $('#set_goal_weight_popup .error_txt').text('');
        if (error && error.response && error.response.data && error.response.data.error) {
          $('#set_goal_weight_popup .error_txt').text(error.response.data.error);
        }
        else if (error && error.response && error.response.status > 400) {
          $('#set_goal_weight_popup .error_txt').text(error.response.statusText);
        }
        else {
          alert(error.message ? error.message : 'Something went wrong!');
        }
        Sentry.captureException(error);
      });


  }

  render() {
    return (

      <div className="modal fade" id="set_goal_weight_popup">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal"><img alt="" className="" src="/assets/images/close_grey.png" /></button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <h6 className="confirm_goal_h6 plan_restart_key"> &nbsp; <img alt="" src="/assets/images/education.png" className="cursor_pointer restart_plan_help" /></h6>
                <h3 className="confirm_goal_h3 lets_do_key"> </h3>
                <p className="error_txt"></p>
                <p className="mt6">
                  <span className="left_sec set_goal_left_sec curre_weight_key"></span> <input type="text" name="curr_value" id="curr_weight" className="set_current numeric" maxLength="6" />
                  <span className="curr_weight lbs_current_key"></span>
                </p>
                <p>
                  <span className="left_sec set_goal_left_sec goal_weight_key"></span> <input className="set_goal numeric" type="text" name="goal_value" maxLength="6" id="goal_weight" /><span className="goal_weight lbs_goal_key"></span>
                </p>
                <button className="mt4 get_started_key" id="set_goal_weight_submit" onClick={this.callGaolPopup}></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


export class Meal_borrow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      disable_btn: 'btn disabled', disable: 'disabled'

    };
    this.enable_btn = this.enable_btn.bind(this);
  }
  enable_btn(e) {
    this.setState({
      disable_btn: "",
      disable: ""
    });
  };
  render() {
    return (
      <div className="modal fade" id="meal_borrow">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><img alt="" src="/assets/images/close_grey.png" /></button>
            </div>
            <div className="modal-body">
              <p className="font16 color6" style={{ 'padding': '0 15%' }}>You have exceeded your recommendation for Breakfast. You may borrow 2 PowerFuel from another meal.</p>
              <h3>Which meal will you borrow from?</h3>
              <p className="font15 color7"><b>2 PF</b> remaning</p>
              <div className="borrow_meal_sec">
                <div className="pull-left font18 upper_case bb_hdr">Breakfast</div>
                <div className="pull-right"><img alt="" src="/assets/images/pf_redoff_medium.png" onClick={this.enable_btn} /><img alt="" src="/assets/images/pf_redoff_medium.png" /></div>
              </div>
              <div className="borrow_meal_sec">
                <div className="pull-left font18 upper_case color6">AM Snack</div>
                <div className="pull-right"><img alt="" src="/assets/images/pf_off_small.png" /></div>
              </div>
              <div className="borrow_meal_sec">
                <div className="pull-left font18 upper_case bb_hdr lunch">Lunch</div>
                <div className="pull-right"><img alt="" src="/assets/images/pf_off_small.png" /></div>
              </div>
              <div className="borrow_meal_sec">
                <div className="pull-left font18 upper_case color6">PM Snack</div>
                <div className="pull-right"><img alt="" src="/assets/images/pf_off_small.png" /></div>
              </div>
              <div className="borrow_meal_sec">
                <div className="pull-left font18 upper_case bb_hdr dinner">Dinner</div>
                <div className="pull-right"><img alt="" src="/assets/images/pf_off_small.png" /></div>
              </div>
              <div className="borrow_meal_sec">
                <div className="pull-left font18 upper_case color6">night Snack</div>
                <div className="pull-right"><img alt="" src="/assets/images/pf_off_small.png" /></div>
              </div>
              <p className="mt6"><input type="submit" value="Done" disabled={this.state.disable} className={this.state.disable_btn} /></p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}



export class UnlogBorrowfood extends React.Component {
  render() {
    return (

      <div className="modal fade" id="unlog_borrow_food">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><img alt="" src="/assets/images/close_grey.png" /></button>
            </div>
            <div className="modal-body">
              <img alt="" src="/assets/images/warning_tri.png" />
              <p className="font26 color3 mt5 font700">Do you want to unlog this
                borrowed food?</p>
              <p className="font16 color6">If you unlog, you will lose your speficied food item and this category will go back to original meal.</p>
              <p><button>Yes</button><button>Cancel</button></p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export class LogoutPopup extends React.Component {

  logout() {
    localStorage.clear();
    sessionStorage.clear();
  }
  render() {
    return (


      <div className="modal fade" id="logout_modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><img alt="" src="/assets/images/close_grey.png" /></button>
            </div>
            <div className="modal-body">
              <img alt="" src="/assets/images/warning_tri.png" />
              <p className="font20 color6 mt20px">You will be logged out. You can complete your profile next time you login</p>
              <p><Link onClick={this.logout} to="/login"><button>Ok</button></Link><button data-dismiss='modal'>Cancel</button></p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


export class FoodHelp extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      help_accor_list: [], item_header: '', item_body: ''
    };
  }


  callHelpAPI = () => {
    var phase_template;
    var location = window.localStorage.getItem('help_location');
    var phase_template_key = window.localStorage.getItem('phase_template_key');
    if (phase_template_key !== null && phase_template_key !== undefined) {
      phase_template = phase_template_key
    }
    else {
      phase_template = '';
    }
    Header.startLoader();

    const options = {
      url: window.API_URL + "v1/helps?location=" + location + "&phase_template_key=" + phase_template,
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        Header.stopLoader();
        if (response && response.data) {
          if (response.data.help && response.data.help.help_details) {
            $('#food_help').modal('show');
          }

          this.setState({
            help_accor_list: response.data.help.help_details, item_header: response.data.help.title, item_body: response.data.help.body
          })
          $('#food_help').find('[id^=help_accord]').removeClass('in')
        }
      })
      .catch(error => {
        Header.stopLoader();
        if (error && error.request && error.request.status && error.request.status === 422) {
          alert(JSON.parse(error.request.response).error);
        }
        else {
          alert(error.message ? error.message : 'Something went wrong!');
        }
        Sentry.captureException(error);
      });
  }

  componentDidMount() {
    $(document).on('click', ' .personalize_help', function (e) {
      e.stopImmediatePropagation();
      localStorage.setItem('help_location', 'CIPD_PERSONALIZATION_OVERVIEW');
      this.callHelpAPI();

    }.bind(this))
    if ($('.journal_help, .food_details_help, .edit_food_details_help').length) {
      $('.tour_link').show();
    }
    else {
      $('.tour_link').hide();
    }
  }

  render() {

    if (this.state.help_accor_list.length > 0) {
      var help_accor_list_option = this.state.help_accor_list.map(function (option, i) {
        return (
          <div className="panel panel-default" key={i}>
            <div className="panel-heading">
              <h4 className="panel-title relative">
                <a className="accord_help" data-toggle="collapse" data-parent="#help_accord" href={"#help_accord" + i}>
                  {option.title}
                </a>
              </h4>
            </div>
            <div id={"help_accord" + i} className="panel-collapse collapse">
              <div className="panel-body text-left">
                <p className="help_accor_list_body" style={{ 'whiteSpace': 'pre-line' }} dangerouslySetInnerHTML={{ __html: option.body }}></p>
                {option.help_deeplinks.length > 0 ? <a className="tour_link cursor_pointer" href={option.help_deeplinks[0].web_url} rel="noopener noreferrer" target="_blank">{option.help_deeplinks[0].title}</a> : ''}
              </div>
            </div>
          </div>

        )
      });

    }

    return (

      <div className="modal fade" id="food_help">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><img alt="" src="/assets/images/close_grey.png" /></button>
            </div>
            <div className="modal-body">
              <img alt="" src="/assets/images/education_icon.png" />
              <p className="font26 color3 mt5 font700">{this.state.item_header}</p>
              <p className="font15 color6 text-left mt5">{this.state.item_body}</p>
              <div id="help_accord">
                <div className="panel-group mt6 border0">
                  {help_accor_list_option}
                </div>
              </div>
              <p><Link to="/tour" className="tour_link">App Tutorial/Tour</Link></p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


export class HelpQuestions extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      help_accor_list: [], item_header: '', item_body: ''
    };
  }


  HelpAPI = () => {
    var phase_template;
    var location = window.localStorage.getItem('help_location');
    var phase_template_key = window.localStorage.getItem('phase_template_key');
    if (phase_template_key !== null && phase_template_key !== undefined) {
      phase_template = phase_template_key
    }
    else {
      phase_template = '';
    }
    Header.startLoader();

    const options = {
      url: window.API_URL + "v1/helps?location=" + location + "&phase_template_key=" + phase_template,
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        Header.stopLoader();
        if (response && response.data) {
          if (response.data.help && response.data.help.help_details) {
            $('#help_questions').modal('show');
          }


          this.setState({
            help_accor_list: response.data.help.help_details, item_header: response.data.help.title, item_body: response.data.help.body
          })
          $('#help_questions').find('[id^=help_accordion]').removeClass('in')
        }
      })
      .catch(error => {
        Header.stopLoader();
        if (error && error.request && error.request.status && error.request.status === 422) {
          alert(JSON.parse(error.request.response).error);
        }
        else {
          alert(error.message ? error.message : 'Something went wrong!');
        }
        Sentry.captureException(error);
      });
  }

  componentDidMount() {
    $(document).on('click', ' .personalize_help', function (e) {
      e.stopImmediatePropagation();
      localStorage.setItem('help_location', 'CIPD_PERSONALIZATION_OVERVIEW');
      this.HelpAPI();

    }.bind(this))
    if ($('.journal_help, .food_details_help, .edit_food_details_help').length) {
      $('.tour_link').show();
    }
    else {
      $('.tour_link').hide();
    }
  }

  render() {

    if (this.state.help_accor_list.length > 0) {
      var help_accor_list_option = this.state.help_accor_list.map(function (option, i) {
        return (
          <div className="panel panel-default" key={i}>
            <div className="panel-heading">
              <h4 className="panel-title relative">
                <a className="accord_help" data-toggle="collapse" data-parent="#help_accordion" href={"#help_accordion" + i}>
                  {option.title}
                </a>
              </h4>
            </div>
            <div id={"help_accordion" + i} className="panel-collapse collapse">
              <div className="panel-body text-left">
                <p className="help_accor_list_body" style={{ 'whiteSpace': 'pre-line' }} dangerouslySetInnerHTML={{ __html: option.body }}></p>
                {option.help_deeplinks.length > 0 ? <a className="tour_link cursor_pointer" href={option.help_deeplinks[0].web_url} rel="noopener noreferrer" target="_blank">{option.help_deeplinks[0].title}</a> : ''}
              </div>
            </div>
          </div>

        )
      });

    }

    return (

      <div className="modal fade" id="help_questions">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><img alt="" src="/assets/images/close_grey.png" /></button>
            </div>
            <div className="modal-body">
              <img alt="" src="/assets/images/education_icon.png" />
              <p className="font26 color3 mt5 font700">{this.state.item_header}</p>
              <p className="font15 color6 text-left mt5">{this.state.item_body}</p>
              <div id="help_accordion">
                <div className="panel-group mt6 border0">
                  {help_accor_list_option}
                </div>
              </div>
              <p><Link to="/tour" className="tour_link">App Tutorial/Tour</Link></p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}



export class HelpQuestionsOptions extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      help_accor_list: [], item_header: '', item_body: ''
    };
  }


  callHelpAPI = () => {
    var phase_template;
    var location = window.localStorage.getItem('help_location');
    var phase_template_key = window.localStorage.getItem('phase_template_key');
    if (phase_template_key !== null && phase_template_key !== undefined) {
      phase_template = phase_template_key
    }
    else {
      phase_template = '';
    }
    Header.startLoader();

    const options = {
      url: window.API_URL + "v1/helps?location=" + location + "&phase_template_key=" + phase_template,
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        Header.stopLoader();
        if (response && response.data) {
          if (response.data.help && response.data.help.help_details) {
            $('#help_questions_options').modal('show');
          }

          this.setState({
            help_accor_list: response.data.help.help_details, item_header: response.data.help.title, item_body: response.data.help.body
          })
          $('#help_questions_options').find('[id^=help_accor]').removeClass('in')
        }
      })
      .catch(error => {
        Header.stopLoader();
        if (error && error.request && error.request.status && error.request.status === 422) {
          alert(JSON.parse(error.request.response).error);
        }
        else {
          alert(error.message ? error.message : 'Something went wrong!');
        }
        Sentry.captureException(error);
      });
  }

  componentDidMount() {
    $(document).on('click', ' .personalize_help', function (e) {
      e.stopImmediatePropagation();
      localStorage.setItem('help_location', 'CIPD_PERSONALIZATION_OVERVIEW');
      this.callHelpAPI();

    }.bind(this))
    if ($('.journal_help, .food_details_help, .edit_food_details_help').length) {
      $('.tour_link').show();
    }
    else {
      $('.tour_link').hide();
    }
  }

  render() {

    if (this.state.help_accor_list.length > 0) {
      var help_accor_list_option = this.state.help_accor_list.map(function (option, i) {
        return (
          <div className="panel panel-default" key={i}>
            <div className="panel-heading">
              <h4 className="panel-title relative">
                <a className="accord_help" data-toggle="collapse" data-parent="#help_accor" href={"#help_accor" + i}>
                  {option.title}
                </a>
              </h4>
            </div>
            <div id={"help_accor" + i} className="panel-collapse collapse">
              <div className="panel-body text-left">
                <p className="help_accor_list_body" style={{ 'whiteSpace': 'pre-line' }} dangerouslySetInnerHTML={{ __html: option.body }}></p>
                {option.help_deeplinks.length > 0 ? <a className="tour_link cursor_pointer" href={option.help_deeplinks[0].web_url} rel="noopener noreferrer" target="_blank">{option.help_deeplinks[0].title}</a> : ''}
              </div>
            </div>
          </div>

        )
      });

    }

    return (

      <div className="modal fade" id="help_questions_options">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><img alt="" src="/assets/images/close_grey.png" /></button>
            </div>
            <div className="modal-body">
              <img alt="" src="/assets/images/education_icon.png" />
              <p className="font26 color3 mt5 font700">{this.state.item_header}</p>
              <p className="font15 color6 text-left mt5">{this.state.item_body}</p>
              <div id="help_accor">
                <div className="panel-group mt6 border0">
                  {help_accor_list_option}
                </div>
              </div>
              <p><Link to="/tour" className="tour_link">App Tutorial/Tour</Link></p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


export class CopyActivty extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      date: moment(),
      startDate: '', isChecked: true, copy_valid: false, copy_invalid: true, update_checkbox: true, from_date: window.localStorage.getItem('from_date')
    };
    this.handleChange = this.handleChange.bind(this);
    this.rememberCheckbox = this.rememberCheckbox.bind(this);
    this.checkCopyvalid = this.checkCopyvalid.bind(this);
  }

  handleChange(date) {
    this.setState({ startDate: date });
  }
  rememberCheckbox(e) {
    this.setState({
      isChecked: !this.state.isChecked
    });

  }

  checkCopyvalid() {
    this.setState({
      copy_valid: false,
      copy_invalid: true, update_checkbox: true
    });
    var activity_histories = { "activity_history": { "assigned_date": moment($('#copy_activity  .form-control').val()).format('YYYY-MM-DD'), "from_date": moment(this.state.from_date).format('YYYY-MM-DD') } };
    const options = {
      url: window.API_URL + 'v1/activity_histories/copy_by_date',
      method: 'POST',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
      data: activity_histories
    };

    axios(options)
      .then(response => {
        if (response && response.data) {
          this.setState({ "activity_histories": response.data.activity_histories });
        }
        if (localStorage.getItem('hideLogAlert') === "true") {
          this.props.callJournalAPI();
          $('.copy_activity').modal('hide');
        }
      })
      .catch(error => {
        Header.stopLoader();
        $('.copy_all_activity_error').html('');
        if (error && error.response && error.response.data && error.response.data.error) {
          $('.copy_all_activity_error').html(error.response.data.error)
        }
        else if (error && error.response && error.response.status > 400) {
          $('.copy_all_activity_error').html(error.response.statusText)
        }
        else {
          alert(error.message ? error.message : 'Something went wrong!');
        }
        Sentry.captureException(error);
      });

  }

  closeLogAlert() {

    if ($('#copy_activity_check').is(':checked')) {
      localStorage.setItem('hideLogAlert', true);
      $('.copy_valid').show();
      $('.copy_not_valid').hide();
    }
    else {
      $('.copy_valid').show();
      $('.copy_not_valid').hide();
    }

  }

  componentDidMount() {

    $('.copy_activity_title').text(window.keystrings['com.numi.copy_activity_title']);

    if ($('#copy_activity .today_date').length === 0) {
      $('#copy_activity .input-group.date').prepend('<div class="today_date"></div>');
      setTimeout(function () {
        $('#copy_activity td.active').click();
      }, 50)
    }
    $(document).on('click', '#copy_activity td.day', function () {
      var actual_date = moment().format('MMM DD');
      var pageload_selecetd_date = moment($('#copy_activity  .form-control').val()).format('MMM DD');
      var kk = moment($('#copy_activity  .form-control').val()).format('MMMM DD, YYYY');
      $('#to-date').text(kk);
      if (pageload_selecetd_date === actual_date) {
        $('#copy_activity .today_date').text('Today');

      }
      else {
        $('#copy_activity .today_date').text(pageload_selecetd_date);
      }
    })
  }

  render() {

    return (

      <div className="modal fade copy_activity" >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><img alt="" src="/assets/images/close_grey.png" /></button>
            </div>
            <div className="modal-body">
              <div className="copy_valid">
                <h3 className="modal-title">Copy Activities</h3>
                <p className="font15">Your Activities from <b>{this.state.from_date}</b> will be copied to <b id="to-date"></b></p>
                <span className="copy_all_activity_error error_txt"></span>
                <div className="relative date_pick">
                  <span className="left_sec font15">Date</span>
                  <DatePicker selected={addDays(new Date(), 1)} dateFormat="MM/DD/YYYY" />
                </div>

                <input type="submit" className="copy_meal mt6" onClick={this.checkCopyvalid.bind(this)} value="Copy Activities" />
              </div>
            </div>
            <div className="copy_not_valid">
              <img alt="" src="/assets/images/warning_tri.png" />
              <p className="font26 color3 mt5 font700">Log / Copy Activities</p>
              <p className="font16 color6">Please note that “Connected Device” activities will not copy - they are reported directly from your device.</p>
              <p className="remember_me">
                <input id="copy_activity_check" type="checkbox" checked={this.state.isChecked} onChange={this.rememberCheckbox} />
                <label htmlFor="copy_activity_check">
                  <span className="bullet_icon">
                    <i></i>
                  </span>
                  <span className="font15 color7">
                    Don’t show this message again
                  </span>
                </label>
              </p>
              <p><button onClick={this.closeLogAlert.bind(this)}>OK</button></p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
