import React from 'react';
import Header from "../header.js";
import axios from 'axios';
import $ from 'jquery';
import * as Sentry from "@sentry/react";

class UnsubscribeUser extends React.Component {

  constructor(props) {
    super(props);
    this.state = { email: '', account_error: '', emailError: '', Error_class: '', hdr_txt: window.keystrings['com.numi.unsubscribe.desc'], unsubscribe_account_not_found: true, nutricustomer: false };
    this.verifyAccount = this.verifyAccount.bind(this);
    this.accountNotFound = this.accountNotFound.bind(this);
  }

  accountNotFound(e) {
    this.setState({
      unsubscribe_account_not_found: !this.state.unsubscribe_account_not_found
    });
  }

  verifyAccount(success_msg) {
    this.setState({
      emailError: '',
      Error_class: '',
      hdr_txt: success_msg
    });
    $('#verifyAccount,.email_box').hide();
    $('#verify_ok').show();
    $('#unsubscribe_account_not_found').modal('hide');
  }

  //To get the input value of email
  inputEmailChange(e) {
    this.setState({ email: e.target.value });
  }

  ClickOk(e) {
    this.setState({
      emailError: '',
      Error_class: '',
      hdr_txt: window.keystrings['com.numi.unsubscribe.desc'],
      email: ''
    });
    $('#verifyAccount,.email_box').show();
    $('#verify_ok').hide();
  }

  // Forgot Password api call
  handleUnsubscribeEmailClick(e) {
    var email_regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!email_regex.test($('#verify_email').val())) {
      this.setState({
        emailError: window.keystrings['com.numi.invalid_email_error'],
        Error_class: 'error_border'
      });
    }
    else {
      this.setState({
        emailError: '',
        Error_class: ''
      });
      Header.startLoader(); // start loader
      const options = {
        url: window.API_URL + "unsubscribe/unsubscribe_mail",
        method: 'POST',
        credentials: 'same-origin',
        headers: Header.setAuthHeaders(),
        data: {
          email: this.state.email
        }
      };

      axios(options)
        .then(response => {
          Header.stopLoader(); // stop loader
          this.verifyAccount(response.data["success"]);
        })
        .catch(error => {
          Header.stopLoader(); // stop loader
          if (error && error.response && error.response.data && error.response.data.error && error.response.data.error) {
            $('#unsubscribe_account_not_found').modal('show');
            $('#unsubscribe_account_not_found .forgot_password_alert_title_text').text(error.response.data.error);
          }
          Sentry.captureException(error);
        });

    }
  }

  componentDidMount() {
    /*keystrings*/
    $('.unsubscribe_email_text').text(window.keystrings['com.numi.unsubscribe.unsubscribe_email'])
    $('.submit_text').text(window.keystrings['com.numi.unsubscribe.submit'])
    $('.ok_text').text(window.keystrings['com.numi.ok_all_caps'])
  }

  render() {
    return (
      <div className="main_container">

        <div className="forgot_password_hero">
          <div><img alt="" src="/assets/images/numi_small_logo.png" className="logo img-responsive" /></div>
          <div className="forgot_sec unsubscribe_sec">
            <h1 className="unsubscribe_email_text"> </h1>
            <h6 className="hdr_txt">{this.state.hdr_txt}</h6>
            <div className='email_box'>
              <input type="text" onChange={this.inputEmailChange.bind(this)} onKeyUp={this.inputEmailChange.bind(this)} value={this.state.email} className={this.state.Error_class} placeholder="Email" id="verify_email" />
              <p className="error text-left">{this.state.emailError}</p>
            </div>
            <button id="verifyAccount" onClick={this.handleUnsubscribeEmailClick.bind(this)} onKeyUp={this.handleUnsubscribeEmailClick.bind(this)} className="submit_text"></button>
            <button id="verify_ok" onClick={this.ClickOk.bind(this)} onKeyUp={this.ClickOk.bind(this)} className="ok_text"></button>
          </div>
        </div>

        <AccountNotFound accountNotFound={this.accountNotFound} unsubscribe_account_not_found={this.state.unsubscribe_account_not_found} />
      </div>
    )
  }
}

class AccountNotFound extends React.Component {

  componentDidMount() {
    /*keystrings*/
    $('.forgot_password_alert_title_text').text(window.keystrings['com.numi.forgot_password_alert_title'])
    $('.ok_all_caps_text').text(window.keystrings['com.numi.ok_all_caps'])
    /*keystrings*/
  }

  render() {
    return (

      <div className="modal fade" id="unsubscribe_account_not_found">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><img alt="" src="/assets/images/close_grey.png" /></button>
            </div>
            <div className="unsubscribe_account_not_found" style={{ display: this.props.unsubscribe_account_not_found ? 'block' : 'none' }} onClick={this.showSearch}>
              <div className="modal-body width77">
                <img alt="" src="/assets/images/warning_tri.png" />
                <p className="font26 color3 mt5 font700 forgot_password_alert_title_text"></p>
                <p><button className="buuton280 tap_password_failed_ok ok_all_caps_text" data-dismiss="modal"></button></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default UnsubscribeUser;
