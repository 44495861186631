import React from 'react';
import Header from "../header.js";
import Navigation from "../nav.js";
import Select from 'react-select';
import baguetteBox from 'baguettebox.js';
import Footer from "../footer.js";
import download from 'downloadjs';
import axios from 'axios';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactTimeAgo from 'react-time-ago';
import en from 'javascript-time-ago/locale/en';
import JavascriptTimeAgo from 'javascript-time-ago';
import { EmailShareButton, FacebookShareButton, TwitterShareButton, FacebookIcon, TwitterIcon, PinterestShareButton, PinterestIcon, EmailIcon } from "react-share";
import $ from 'jquery';
import * as Sentry from "@sentry/react";

JavascriptTimeAgo.locale(en);

class Photos extends React.Component {
  state = {
    selectedDropdownOption: null,
    dropdownOptions: [],
    images: [],
    current_view: ''
  };


  handleSelectionChange = selectedDropdownOption => {
    var _this = this;
    if (typeof (selectedDropdownOption) === 'string')
      for (var i = 0; i < _this.state.dropdownOptions.length; i++) {
        if (selectedDropdownOption.toLowerCase() === _this.state.dropdownOptions[i].label.toLowerCase()) {
          selectedDropdownOption = _this.state.dropdownOptions[i];
          break;
        }
      }
    if (!selectedDropdownOption) {
      if (window.location.hash) {
        let hash_value = window.location.hash.split('#')[1];
        let hash_replace = hash_value.replaceAll('_', ' ');
        let final_hash = hash_replace.charAt(0).toUpperCase() + hash_replace.slice(1);
        let check_lost_lbs = _this.state.dropdownOptions.find(function (lost_lbs) {
          return lost_lbs.label === final_hash
        });
        if (check_lost_lbs === undefined) {
          check_lost_lbs = { label: "All milestones", value: 0 }
          selectedDropdownOption = check_lost_lbs;
        }
        else {

          selectedDropdownOption = check_lost_lbs;
        }
      }
      else {
        selectedDropdownOption = _this.state.dropdownOptions[0];
      }

    }
    else {
      const check_deleted_lbs = _this.state.dropdownOptions.find(function (check_lbs) {
        return check_lbs.value === selectedDropdownOption.value

      });

      if (check_deleted_lbs === undefined) {

        selectedDropdownOption = _this.state.dropdownOptions[0];
      }
      else {
        selectedDropdownOption = _this.state.dropdownOptions[selectedDropdownOption.value];

      }
    }
    _this.setState(
      { selectedDropdownOption }
    );
    if (selectedDropdownOption && (selectedDropdownOption.label.toLowerCase() === "all milestones" || selectedDropdownOption.label.toLowerCase() === "all badges")) {
      window.location.hash = selectedDropdownOption.label.replaceAll(' ', '_').toLowerCase();
      $('.gallery > div').fadeIn();
    }
    else {
      $('.gallery > div').hide();
      if (selectedDropdownOption.label.toLowerCase() === undefined) {
        $('.gallery > div[weight_text="' + selectedDropdownOption.toLowerCase() + '"]').fadeIn();
      }
      else {
        $('.gallery > div[weight_text="' + selectedDropdownOption.label.toLowerCase() + '"]').fadeIn();
        window.location.hash = selectedDropdownOption.label.replaceAll(' ', '_').toLowerCase();
      }
    }
  };


  forceDownload = (e) => {
    Header.startLoader();
    var host = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ":" + window.location.port : "");
    var downloadURL = host + "/photo_captures/download_my_pictures?uuid=" + e.target.getAttribute('id');
    var arr = e.target.getAttribute('data-media').split("?")[0].split("/")
    var fileName = arr[arr.length - 1];
    var x = new XMLHttpRequest();
    x.open("GET", downloadURL, true);
    x.responseType = 'blob';
    x.onload = function (e) {
      Header.stopLoader();
      download(x.response, fileName, "image/" + fileName.split('.')[1]);
    }
    x.send();
  }

  copyToClipboard = (e) => {
    var value = e.target.getAttribute('data-shorturl');
    var input_temp = document.createElement("input");
    input_temp.value = value;
    document.body.appendChild(input_temp);
    input_temp.select();
    document.execCommand("copy");
    document.body.removeChild(input_temp);
    $('<p>Copied!</p>').appendTo('.copy_text');
    $('.copy_text').slideDown();
    setTimeout(() => {
      $('.copy_text  p').remove();
    }, 5000)
  }

  DeleteImage = (e) => {

    var _this = this;
    var image_id = sessionStorage.getItem('delete_photo_id');
    sessionStorage.setItem('deleteItem', true);
    const options = {
      url: window.API_URL + 'v1/photo_captures/' + image_id,
      method: 'DELETE',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders()
    };
    Header.startLoader();
    axios(options)
      .then(response => {
        Header.stopLoader();
        _this.loadPhotos(_this.state.selectedDropdownOption);
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });
  }

  loadPhotos = (get_label) => {
    var url;
    if (document.URL.indexOf('/photos') > -1)
      url = "v1/photo_captures";
    else
      url = "v1/photo_captures?&view=badges_view";
    Header.startLoader();
    var _this = this;
    const options = {
      url: window.API_URL + url,
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders()
    };
    axios(options)
      .then(response => {
        Header.stopLoader();
        if (response && response.data && response.data.photo_captures) {
          if (response.data.photo_captures.images && response.data.photo_captures.images.length === 0) {
            $('.blank-slate').show();
            $('.gallery-menu').hide();
          }
          else {
            $('.gallery-menu').show();
            $('.blank-slate').hide();
          }
          if (response.data.photo_captures.current_view === "badges") {
            $('.add_photo_btn').hide();
            $('#badges_page').addClass('badges_page');
            $('#photos_page').removeClass('photos_page');
          }
          else {
            $('.add_photo_btn').show();
            $('#badges_page').removeClass('badges_page');
            $('#photos_page').addClass('photos_page');
          }
          _this.setState(
            {
              current_view: response.data.photo_captures.current_view,
              dropdownOptions: response.data.photo_captures.tags.map(
                (label, tag) => (
                  {
                    label: label.charAt(0).toUpperCase() + label.substr(1).replace(/_/gi, ' ').toLowerCase(), value: tag
                  }
                )
              ),
              images: response.data.photo_captures.images
            }
          );
          baguetteBox.run('.gallery');
          _this.handleSelectionChange(get_label);
          $('.more_text').hide();
          for (var i = 0; i < $('.notes').length; i++) {
            if ($('.notes:eq(' + i + ')').text().length > 88) {
              $('.notes:eq(' + i + ')').addClass('ellipse').next('.more_text').show();
            }
            else {
              $('.notes:eq(' + i + ')').removeClass('ellipse').next('.more_text').hide();
            }
          }
        }
        sessionStorage.removeItem('weight_loss');
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });
  }

  setPhotoId = (e) => {
    sessionStorage.setItem('delete_photo_id', e.target.getAttribute('id'));
  }

  showNotes = (e) => {
    e.target.innerText = (e.target.innerText === 'more' ? 'less' : 'more');
    e.target.previousElementSibling.classList.toggle("ellipse");
    var scroll_pos = (0);
    if (e.target.innerText === 'more') {
      $('.notes').animate({ scrollTop: (scroll_pos) }, 10);
    }
    e.target.previousElementSibling.classList.toggle("scroll_bar");



  }


  componentDidMount = () => {
    document.querySelector(".gallery-menu-select").addEventListener("click", function () {
      setTimeout(() => {

      })
    });
    $('.delete_photo_txt').text(window.keystrings['com.numi.delete_photo_text']);
    this.loadPhotos(sessionStorage.getItem('weight_loss') !== null ? sessionStorage.getItem('weight_loss') : undefined);


  };

  render() {
    return (
      <div className="photos_page" id="photos_page">
        <div className="table_display">
          <div className="journal_div  profile_page_sec">
            <div className="section_left pull-left">
              <Navigation />
            </div>
            <div className="section_right pull-left">
              <div className="journal_rht my_profile" style={{ padding: '0px' }}>
                <center id="title_bar">
                  {this.state.current_view === 'badges' ? <img alt="Photos" src="/assets/images/numi_logo_badges.png" width="100" /> : <img alt="Photos" src="/assets/images/numi_logo_images.png" width="90" />}
                </center>
              </div>
              <div className="blank-slate">
                {
                  this.state.current_view === 'badges' ? <div className="blank-slate-description">Visit Challenges in your News Feed to earn awesome prizes and rewards!</div> : <div><div className="blank-slate-title"><b>Track your progress!</b></div> <div className="blank-slate-description">Tap <img alt="" title="" src="/assets/images/add_photo_green.png" /> to capture your starting point – add your “before” pic now! As you track and hit Weight Loss Milestones, you’ll be able to add progress pics and unlock Weight Loss Milestone badges.</div><Link to="/upload_photos"><button className="photo_submit_btn cursor_not"><img alt="" title="" src="/assets/images/add_photo.png" /> <span className="add_photos_text"><small>Add Photos</small></span></button></Link></div>
                }
              </div>
              <div className="gallery-menu text-center">
                <Select className="gallery-menu-select"
                  value={this.state.selectedDropdownOption}
                  onChange={this.handleSelectionChange}
                  options={this.state.dropdownOptions}
                /><Link to="/upload_photos"><button className="color5 add_photo_btn"><img alt="" src="/assets/images/add_photo.png" /><span className="vlm"> &nbsp; Add Photos</span></button></Link>
              </div>
              <div className="gallery">
                {this.state.images.map((item, key) =>
                  <div key={key} weight_text={item.title.toLowerCase()} weight_lose_text={item.tags[0]}>
                    <div className="thumbnail">
                      <a href={item.url} data-caption={item.title}>
                        <img src={item.url} alt={item.title} width="100%" />
                      </a>
                      <div className="social_icons text-center mt10">
                        <FacebookShareButton title="Facebook" alt="Facebook" url={item.shorturl} quote={item.title} description={item.notes} >
                          <FacebookIcon size={30} />
                        </FacebookShareButton>
                        <TwitterShareButton url={item.shorturl} title={item.title} description={item.notes}>
                          <span title="Twitter" alt="Twitter"><TwitterIcon size={30} /></span>
                        </TwitterShareButton>
                        <PinterestShareButton url={item.shorturl} media={item.url} description={item.notes} quote={item.title} title="Pinterest" alt="Pinterest">
                          <PinterestIcon size={30} />
                        </PinterestShareButton>
                        <EmailShareButton url={item.shorturl} subject={item.title} body={item.notes} title="Email" alt="Email">
                          <EmailIcon size={30} />
                        </EmailShareButton>
                        <i className="fas fa-share-square" title="Copy" alt="Copy" onClick={this.copyToClipboard} data-shorturl={item.shorturl}></i>
                        <i className="fas fa-download" onClick={this.forceDownload} id={item.id} data-shorturl={item.url} data-media={item.url} data-description={item.notes} title="Download" alt="Download"></i>
                        {item.section && item.section === "user_images" ? <i className="fa fa-trash fa-2" aria-hidden="true" id={item.id} data-toggle="modal" data-target="#photo_delete" onClick={this.setPhotoId} title="Delete" alt="Delete"></i> : ''}
                      </div>
                      <div className="caption text-center">
                        <h3 id="expanded">{item.title}</h3>
                        <div className="time_format">
                          {item.image_created_at !== null ? <ReactTimeAgo date={new Date(item.image_created_at)} locale="en" /> : ''}
                        </div>
                        <p className="notes">{item.notes}</p>
                        <span className="cursor_pointer more_text" onClick={this.showNotes}>more</span>
                      </div>

                    </div>
                  </div>
                )}
              </div>

              <Footer />
            </div>
          </div>
        </div>
        <div className="copy_text"></div>
        <div className="modal fade" id="photo_delete">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><img alt="" src="/assets/images/close_grey.png" /></button>
              </div>
              <div className="modal-body">
                <p className="font22 delete_photo_txt"></p>
                <button onClick={this.DeleteImage} data-dismiss="modal">Yes</button><button data-dismiss="modal">No</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Photos;
ReactTimeAgo.propTypes = {
  date: PropTypes.string
}