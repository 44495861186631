
import React from 'react';
import { Link } from 'react-router-dom'
import Footer from "../footer.js";
import UnassinedPopup from "../create_food/unassigned_food.js";
import moment from 'moment';
import Header from "../header.js";
import Navigation from "../nav.js";
import Adspeed from "../adspeed.js";
import axios from 'axios';
import $ from 'jquery';
import * as Sentry from "@sentry/react";

class RecipeItem extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			serving_size: window.localStorage.getItem('serving_size'),
			count_as: window.localStorage.getItem('food_categories'),
			consumable_type: 'Food',
			assigned_date: moment().format("YYYY-MM-DD"),
			food_recipe_details: [],
			diet_history_id: '',
			diet_history: {},

			food_categories: [], image_count: [], nutrients: [],
			favorite: true, add_fav_sec: true, add_to_fav: 'Remove from Favorites', add_fav_off: "/assets/images/fav_on.png", remove_fav_sec: false,

			date: moment(),
			recipeType: 'overview',
			startDate: moment(),
			serving_unit: '',
			recipe_id: (window.localStorage.getItem('recipe_id')),
			recipe_name: '',
			calories: '',
			meal_occasion_id: (window.localStorage.getItem('meal_occasion_id')),
			number_of_servings: (window.localStorage.getItem('number_of_servings')),
			nutrients_response: [],
			consumable: {},
			diet_histories: [],
			consumable_id: (window.localStorage.getItem('consumable_id')),
			show_recipe_only: true,
			food_serving_size_id: (window.localStorage.getItem('food_serving_size_id')),
			food_name: '',
			phase_template: [],
			show_leaf_icon: false,
			overview_read_more: "https://leaf.nutrisystem.com/"
		};

		this.loadIngredientsData = this.loadIngredientsData.bind(this);
		this.createDietHistory = this.createDietHistory.bind(this);
		this.sendToRecipeDetails = this.sendToRecipeDetails.bind(this);
		this.getValidFoodCategories = this.getValidFoodCategories.bind(this);
	}
	sendToRecipeDetails() {
		window.localStorage.setItem('recipe_food', this.state.food_name);
		window.localStorage.setItem('recipe_item_calories', this.state.calories)
		window.localStorage.setItem('item_name', "Recipes")
		this.props.history.push("/food_details")
	}

	createDietHistory() {
		Header.startLoader()
		// var action_type = "create";
		var serving_size = this.state.serving_size;

		var food_category_custom_foods = [];

		var filled_cat = this.refs.unassigned_child.state.food_icon_array.map(function (item) { return item.name.toLocaleLowerCase() + "_unfill" })
		window.localStorage.setItem({ food_categories: filled_cat })
		for (var i = 0; i < filled_cat.length; i++) {
			var count = document.getElementsByClassName('un_assign')[0];
			var final_count = count.getElementsByClassName(filled_cat[i]).length;
			if (final_count > 0)
				food_category_custom_foods.push({ "name": filled_cat[i].toUpperCase().slice(0, -7), "value": final_count });
		}

		var diet_histories = { "diet_histories": [{ "food_serving_size_id": this.state.food_serving_size_id, "consumable_id": this.state.consumable_id, consumable_type: this.state.consumable_type, "serving_size": serving_size, meal_occasion_id: this.state.meal_occasion_id, assigned_date: moment(this.state.startDate).format("YYYY-MM-DD"), food_categories: food_category_custom_foods }] };

		const options = {
			url: window.API_URL + "v1/diet_histories",
			method: 'POST',
			credentials: 'same-origin',
			headers: Header.setAuthHeaders(),
			data: diet_histories
		};

		axios(options)
			.then(response => {
				Header.stopLoader();
				if (response && response.data) {
					this.setState({ diet_histories: response.data.diet_histories });
					if (!!response.data.diet_histories) {
						window.localStorage.setItem("serving_size", response.data["diet_histories"][0].serving_size)
						window.localStorage.setItem("food_categories", this.state.food_categories)
						window.localStorage.setItem("diet_history_id", response.data["diet_histories"][0].id)
					}
					if (this.state.diet_histories) {
						this.props.history.push('/food_details');
					}
				}

			})
			.catch(error => {
				Header.stopLoader();
				Header.apiErrors(error);
				Sentry.captureException(error);
			});
	};

	loadIngredientsData() {

		var assigned_date = moment(window.localStorage.getItem('assigned_date')).format('YYYY-MM-DD');
		var consumable_id = window.localStorage.getItem('consumable_id');
		var consumable_type = window.localStorage.getItem('consumable_type');
		var term;
		if (window.localStorage.getItem('searched_term') != null) {
			term = window.localStorage.getItem('searched_term');
		}
		else {
			term = "";
		}
		/* TODO: Need to check why commented */
		// if(term == undefined || term == "undefined"){
		// 	term=''
		// }
		Header.startLoader();
		const options = {
			url: window.API_URL + "v1/consumables/consumable_id/" + consumable_id + "/consumable_type/" + consumable_type + "?assigned_date=" + assigned_date + "&term=" + encodeURI(term),
			method: 'GET',
			credentials: 'same-origin',
			headers: Header.setAuthHeaders(),
		};

		axios(options)
			.then(response => {
				Header.stopLoader();
				if (response && response.data) {
					if (response.data.consumable.best_for_you !== null) {
						sessionStorage.setItem('recipe_best_for_you', response.data.consumable.best_for_you);
					}

					this.setState({ food: response.data.consumable, nutrients: response.data.consumable.nutrients, serving_size_unit: response.data.consumable.serving_unit, calories: response.data.consumable.calories, food_name: response.data.consumable.food_name, show_leaf_icon: (response.data.consumable.food_source === "Nutrisystem" || response.data.consumable.food_source === "leaf" ? true : false) })


					if (!!response.data.consumable.serving_sizes && response.data.consumable.serving_sizes.length > 0) {
						this.setState({ serving_size: response.data.consumable.serving_sizes[0].name });
					}
					if (response.data.consumable.serving_sizes === undefined) {
						this.setState({ food_serving_size: response.data.consumable.serving_sizes[0].coefficient });
					} else {
						this.setState({ food_serving_size: response.data.consumable.serving_size });
					}
					if (!!response.data.consumable.food_recipe_details && response.data.consumable.food_recipe_details.length > 0) {
						this.setState({ food_recipe_details: response.data.consumable.food_recipe_details })
						var parseOverView = this.state.food_recipe_details[0].overview;
						$('.about_recipe').append(parseOverView);
						var parseIngredients = this.state.food_recipe_details[0].ingredients;
						$('.ingredients_details').append(parseIngredients);
						var parseDirections = this.state.food_recipe_details[0].prep_steps;
						$('.directions_details').append(parseDirections);

						if (!!this.state.food_recipe_details[0].url) {
							this.setState({ overview_read_more: this.state.food_recipe_details[0].url });

						}
					}
					this.setState({ favorite: response.data.consumable.favorite_foods, add_to_fav: this.state.favorite === true ? 'Remove from Favorites' : 'Add to Favorites', add_fav_off: this.state.favorite === true ? '/assets/images/fav_on.png' : '/assets/images/fav_off.png' });
				}
			})
			.catch(error => {
				Header.stopLoader();
				Header.apiErrors(error);
				Sentry.captureException(error);
			});

	}
	//to valid food categories for recipe_item page
	//
	getValidFoodCategories() {
		var assigned_date = moment(window.localStorage.getItem('assigned_date')).format('YYYY-MM-DD');
		var consumable_id = window.localStorage.getItem('consumable_id');
		var consumable_type = window.localStorage.getItem('consumable_type');
		//get valid food categories
		const options = {
			url: window.API_URL + "v1/diet_histories/validate?consumable_id=" + consumable_id + "&consumable_type=" + consumable_type + "&assigned_date=" + assigned_date,
			method: 'GET',
			credentials: 'same-origin',
			headers: Header.setAuthHeaders(),
		};

		axios(options)
			.then(response => {
				Header.stopLoader();
				if (response && response.data) {
					this.setState({ diet_history: response.data.valid_food_details.diet_history })
					if (response.data.valid_food_details.diet_history.food_source === "Nutrisystem" || response.data.valid_food_details.diet_history.food_source === "leaf") {
						this.setState({ display_leaf_icon: true });
					}
					if (response.data.valid_food_details.diet_history.food_categories.length !== 0) {
						$('.un_assign').text('');
					}

					if (!!response.data.valid_food_details.diet_history.food_categories && response.data.valid_food_details.diet_history.food_categories.length === 0) {
						if (!($('.un_assign').text().includes('Unassigned'))) {
							$('.un_assign').append('<span class="text-right un_assign font14">Unassigned &nbsp;&nbsp;</span>');
						}
					}
					for (var i = 0; i < response.data.valid_food_details.diet_history.food_categories.length; i++) {
						var name = response.data.valid_food_details.diet_history.food_categories[i].name;
						var img_name = "/" + name + "_SMALL_USED.png";
						var img_type = name.toLowerCase() + "_unfill"
						var img_src = response.data.valid_food_details.s3_images_path + "/";
						var count = parseFloat(response.data.valid_food_details.diet_history.food_categories[i].value).toFixed();

						for (var c = 0; c < count; c++) {

							$('.un_assign').append('<img name=' + response.data.valid_food_details.diet_history.food_categories[i].name + ' class="filled_icon cursor_pointer ' + img_type + '" value=' + img_type + ' src=' + img_src + name + img_name + '>');

						}

					}
					$('.recipe .add_fav').show();
				}
			})
			.catch(error => {
				Header.stopLoader();
				Header.apiErrors(error);
				Sentry.captureException(error);
			});
	}


	componentDidMount() {
		$('.recipe .add_fav').hide();
		try {
			if (window.localStorage.getItem('recents_food_details') === "true" && window.localStorage.getItem('recents_food_details') !== null && window.localStorage.getItem('recents_food_details') !== undefined) {
				/*when clicked on recents api call*/
				const options = {
					url: window.API_URL + "v1/diet_histories/" + window.localStorage.getItem('dh_id'),
					method: 'GET',
					credentials: 'same-origin',
					headers: Header.setAuthHeaders(),
				};

				axios(options)
					.then(response => {
						Header.stopLoader();
						if (response && response.data) {
							this.setState({ diet_history: response.data.diet_history });
							if (response.data.diet_history.food_source === "Nutrisystem" || response.data.diet_history.food_source === "leaf") {
								this.setState({ display_leaf_icon: true });
							}
							if (response.data.diet_history.food_categories.length !== 0) {
								$('.un_assign').text('');
							}

							if (!!response.data.diet_history.food_categories && response.data.diet_history.food_categories.length === 0) {
								if (!($('.un_assign').text().includes('Unassigned'))) {
									$('.un_assign').append('<span class="text-right un_assign font14">Unassigned &nbsp;&nbsp;</span>');
								}
							}
							for (var i = 0; i < response.data.diet_history.food_categories.length; i++) {
								var name = response.data.diet_history.food_categories[i].name;
								var img_name = "/" + name + "_SMALL_USED.png";
								var img_type = name.toLowerCase() + "_unfill"
								var img_src = response.data.diet_history.s3_images_path + "/";
								var count = parseFloat(response.data.diet_history.food_categories[i].value).toFixed();

								for (var c = 0; c < count; c++) {

									$('.un_assign').append('<img name=' + response.data.diet_history.food_categories[i].name + ' class="filled_icon cursor_pointer ' + img_type + '" value=' + img_type + ' src=' + img_src + name + img_name + '>');

								}

							}
							$('.recipe .add_fav').show();
						}
					})
					.catch(error => {
						Header.stopLoader();
						Header.apiErrors(error);
						Sentry.captureException(error);
					});
			}
			else {
				this.getValidFoodCategories();
			}
			this.loadIngredientsData();
		}
		catch (error) {
			Sentry.captureException(error);
		}
		$(document).on('click', '.back_to_recipe', function () {
			window.localStorage.setItem('back_to_recipe', 'true')
		});

		/* Key Strings*/
		$('.recipe-detail-overview-ks').text(window.keystrings['com.numi.recipe_overview']);
		$('.recipe-details-ingredient-ks').text(window.keystrings['com.numi.recipe_ingredients']);
		$('.recipe-detail-directions-ks').text(window.keystrings['com.numi.recipe_directions']);
		$('.recipe-detail-read-more-ks').text(window.keystrings['com.numi.read_more_leaf']);
		$('.recipe-detail-view-on-leaf-ks').each(function () {
			$(this).text(window.keystrings['com.numi.view_on_leaf'])
		});
		$('.recipe-item-serving-size-ks').text(window.keystrings['com.numi.food_details_serving_size']);
		$('.recipe-item-count-as-ks').text(window.keystrings['com.numi.food_details_counts_as']);
		$('.recipe_item-about-this-ks').text(window.keystrings['com.numi.about_recipe']);
		//$('.recipe-detail-log-recipe-txt-ks').val(window.keystrings['com.numi.log_recipe'])
		$('.recipe-back-to-recipes-ks').text(window.keystrings['com.numi.recipe.back_to_recipes']);
		$('.recipe-log-recipe input').val(window.keystrings['com.numi.log_recipe']);
		/* End Key Strings*/
	}
	render() {
		// var nutrients_list = this.state.nutrients.map(function(data,i){
		// 	return(
		// 	<div className="facts_sec" key={i}>
		// 		<div className="pull-left">{data.display_name}</div>
		// 	 	<div className="text-right pull-right">{data.value}{data.units}</div>
		// 	</div>

		// 	)
		// })
		var leaf_icon;
		if (this.state.show_leaf_icon) {
			leaf_icon = <img src="/assets/images/leaf_small.png" alt="" className="vlbase" />;
		} else {
			leaf_icon = <span className="vlbase" />;
		}


		return (
			<div>

				<div className="table_display">

					<div className="food_details_div recipe">

						<div className="section_left pull-left">
							<Navigation />
						</div>
						<div className="section_right pull-left">
							<figure><Adspeed /></figure>
							<div className="journal_rht">
								<p className="mb">
									<Link to="/recipe" className="back_to_recipe"><img alt="" src="/assets/images/back_white_icon.png" className="vlb" /> <span className="recipe-back-to-recipes-ks"></span></Link>
								</p>
								<h1 className="journal_hdr margin0 width100">{leaf_icon}&nbsp;<b>{this.state.food_name}</b></h1>
								<div className="pull-left">{sessionStorage.getItem('recipe_best_for_you') === "true" ? <img alt="" src='/assets/images/best_for_big.png' style={{ marginRight: '10px', marginTop: '20px' }} className="mt15" /> : ''}</div>
								<div className="clear"></div>
								<div className="clear"></div>
								<hr />

								<div className="clear"></div>
								<div className="meal_section_left pull-left recipe-log-recipe">
									<div className="panel-group">
										<ul className="favorite_section recipe_tabs">
											<li className={this.state.recipeType == 'overview' ? "active" : ''}
												onClick={() => {
													this.setState({
														recipeType: 'overview'
													})
												}}><a href="#overview" data-toggle="tab" className="recipe-detail-overview-ks"> </a>
											</li>
											<li className={this.state.recipeType == 'ingredients' ? "active" : ''}
												onClick={() => {
													this.setState({
														recipeType: 'ingredients'
													})
												}}><a href="#ingredients" data-toggle="tab" className='recipe-details-ingredient-ks'> </a></li>
											<li className={this.state.recipeType == 'directions' ? "active" : ''}
												onClick={() => {
													this.setState({
														recipeType: 'directions'
													})
												}}><a href="#directions" data-toggle="tab" className='recipe-detail-directions-ks'> </a></li>
										</ul>

										<div className="tab-content text-left mb">
											<div id="overview"
												className={this.state.recipeType == 'overview' ? "tab-pane active" : 'tab-pane'}
												onClick={() => {
													this.setState({
														recipeType: 'overview'
													})
												}}
											>
												<div className="profile_div_sec">
													<div className="pull-left my_profile_lft recipe-item-serving-size-ks"></div>
													<div className="pull-right my_profile_rht"><span className="recipe-item-serving-size">{this.state.serving_size} {this.state.serving_unit}</span> 	</div>
												</div>
												<div className="profile_div_sec">
													<div className="pull-left my_profile_lft recipe-item-count-as-ks"></div>

													<div className="pull-right my_profile_rht">
														<span className="text-right un_assign font14 margin0"></span>

													</div>
												</div>
												<div className="profile_div_sec">
													<h6 className="color6"><b><span className="recipe_item-about-this-ks"></span></b></h6>
													<p className="font14 color6 about_recipe"></p>
												</div>
												<a href={this.state.overview_read_more} target="_blank" rel="noopener noreferrer" className="font14 read_more recipe-detail-read-more-ks"> </a>
											</div>
											<div id="ingredients"
												className={this.state.recipeType == 'ingredients' ? "tab-pane active" : 'tab-pane'}
												onClick={() => {
													this.setState({
														recipeType: 'ingredients'
													})
												}}
											>

												<p className="font14 color6 ingredients_details"></p>
												<p><a href={this.state.overview_read_more} rel="noopener noreferrer" target="_blank" className="font14 read_more padding0 recipe-detail-view-on-leaf-ks"> </a></p>
											</div>
											<div id="directions"
												className={this.state.recipeType == 'directions' ? "tab-pane active" : 'tab-pane'}
												onClick={() => {
													this.setState({
														recipeType: 'directions'
													})
												}}
											>
												<p className="font14 color6 directions_details"></p>

												<p><a href={this.state.overview_read_more} rel="noopener noreferrer" target="_blank" className="font14 read_more padding0 recipe-detail-view-on-leaf-ks"> </a></p>
											</div>
										</div>
									</div>
									<input type="submit" onClick={this.sendToRecipeDetails} value="Log Recipe" />
								</div>

								<div className="meal_section_right pull-right">
									{/*
            <div className="nutrition-facts-wrapper" >
				      <div className="nutri_facts_hdr facts_sec">Nutrition Facts</div>
				        <div className="divider facts_sec">
				          Serving Size {this.state.diet_history.serving_unit}

				        </div>
				        <div className="ser_divider facts_sec">
				          Serving Per Container 1.0
				        </div>
				      <div className="facts_sec">
				        <div className="amount">Amount Per Serving</div>
				      </div>
				      <div className="sm-divider facts_sec">
				          Calories {this.state.calories}
				      </div>

                                        <div className="text-right facts_sec">
                                        Amount
                                        </div>
                                        {nutrients_list}

		  		</div>
          */}
								</div>


							</div>
							<Footer />
							<UnassinedPopup ref="unassigned_child" />

						</div>

					</div>
				</div>
			</div>

		)
	}
}

export default RecipeItem;

