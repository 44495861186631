import React from 'react';
import { Link } from 'react-router-dom';
import Footer from "../footer.js";
import Header from "../header.js";
import DatePicker from "react-datepicker";
import Navigation from "../nav.js";
import Adspeed from "../adspeed.js";
import { AlertPopup, LowBloodSugarPopup, ActivityLevelPopup, TurboTakeoffPopup, SetGoalWeightPopup } from '../journal/popup.js';
import moment from 'moment';
import { addDays, parse } from 'date-fns';
import axios from 'axios';
import ActivityQuestions from '../settings/activity_questions.js';
import { PersonalizePlanMode } from '../onboarding/PersonalizePlanMode.js';
import $ from 'jquery';
import * as Sentry from "@sentry/react";

class Progress extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      profile_start_date: '', time_unit: 'all_time', progress_data: '', chart_data: '', logDate: '', editImg: '', logWeight: '', current_weight: '', start_weight: '', available_time_units: '', goal_weight: '', log_weight_array: [], edit_Weight: '', disable_pointer: 'disable_pointer', disable_cursor: 'disable_cursor', enableClick: false, hidePlanPhase: true, width16: '', showProgressLeft: true, assigned_date: '', showProgressRight: false, message: '', move_from_date: '', move_to_date: '', show_log_btn: false, show_log_btn_btm: true, profile_info: '', profile_properties: '', plan_start_date: '', phase_start_date: '', alert_data: [], alert: '', alert_button_labels: [], alert_button_action_web: [], alert_id: '', set_goal: '', set_current: '', determinants: {}, alert_link: '',
      getSelectedDate: '',
      planStartType: 'all_time',

    }


  }

  LogWeight = (val) => {
    $(".log_weight_error error_txt").hide();
    var k = val;
    var select_date = moment(parse($('#log_weight').find('.react-datepicker__input-container input').val())).format('YYYY-MM-DD');
    var newArray = this.state.log_weight_array;
    newArray.push({ assigned_date: select_date, current: k, edit_img: '/assets/images/edit_small.png' });
    this.setState({ log_weight_array: newArray, logWeight: val })
  }

  allTimeStartWeight = () => {
    this.setState({

      disable_pointer: 'disable_pointer',
      disable_cursor: 'disable_cursor'
    })
  }

  hidePlanPhase = () => {
    this.setState({
      hidePlanPhase: false, showProgressLeft: false,
      showProgressRight: true, show_log_btn: true, show_log_btn_btm: false
    });
  }

  enableStartWeight = () => {
    this.setState({

      disable_pointer: '', disable_cursor: ''
    })
  }

  callMultiple = () => {
    this.hidePlanPhase();
    this.enableStartWeight();
    this.setState({ width16: 'width16' })
  }

  changeEditWeight(val) {
    this.setState({ edit_Weight: val, logWeight: val })
  }

  changeCurrent = (val) => {
    this.setState({
      current_weight: val,
      assigned_date: this.state.progress_data.progress.profile.weight.assigned_date
    })
  };

  changeGoal = (val) => {
    this.setState({
      goal_weight: val
    })
  };

  changeTimeUnit = (get_text) => {
    this.setState({
      time_unit: get_text, hidePlanPhase: true, width16: '', showProgressLeft: true, showProgressRight: false, show_log_btn: false, show_log_btn_btm: true
    });
    this.progressAPI(get_text);
  }

  setLogWeight = () => {
    $('#log_weight').modal('show');
    $(".override_weight_content").hide();
    $(".log_weight_content").show();

    /*Key string log weight*/
    $('.log_weight_header').text(window.keystrings['com.numi.log_weight']);
    $('.log_weight_save').text(window.keystrings['com.numi.keypad.save']);
    /*Key string log weight */
  }

  getTimeUnitDate = (time_unit) => {
    var start_date = '';
    if (time_unit === "one_week") {
      start_date = moment().subtract(1, 'week').add(1, 'days');
    } else if (time_unit === "one_month") {
      start_date = moment().subtract(1, 'month').add(1, 'days');
    } else if (time_unit === "three_months") {
      start_date = moment().subtract(3, 'month').add(1, 'days');
    } else if (time_unit === "six_months") {
      start_date = moment().subtract(6, 'month').add(1, 'days');
    } else if (time_unit === "one_year") {
      start_date = moment().subtract(1, 'year').add(1, 'days');
    }
    this.weightEditIconDisplay(start_date);
  }

  weightEditIconDisplay = (date) => {
    if (date !== '') {
      var weight_list = [];
      $("ul#weight_ul").find("span.edit_selected_date").each(function (i) {
        weight_list.push($(this).text());
      });
      if ($.inArray(moment(date).format('MMMM DD, YYYY'), weight_list) === -1) {
        $(".start_edit_icon").hide();
      } else {
        $(".start_edit_icon").show();
      }
    }
  }

  getProfileStartDates = () => {
    const options = {
      url: window.API_URL + "v1/profile",
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        Header.stopLoader();
        if (response && response.data) {
          this.setState({ profile_info: response.data })
          if (this.state.profile_info) {
            this.setState({
              profile_properties: this.state.profile_info.profile.profile_properties
            });
            this.state.profile_properties.map(function (option, i) {
              if (option.key === "START_DATE") {
                this.setState({
                  profile_start_date: moment(parse(option.value)).format('YYYY-MM-DD')
                });
              } else if (option.key === "PLAN_START_DATE") {
                this.setState({
                  plan_start_date: moment(parse(option.value)).format('YYYY-MM-DD')
                });
              } else if (option.key === "PHASE_START_DATE") {
                this.setState({
                  phase_start_date: moment(parse(option.value)).format('YYYY-MM-DD')
                });
              }
            }.bind(this))
          }
        }
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });

  }

  getprogressAlerts = () => {
    const options = {
      url: window.API_URL + 'v1/alerts',
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        if (response && response.data) {
          localStorage.removeItem("transition_url_goal_weight");
          this.setState({ alert_data: response.data.alerts });
          if (this.state.alert_data && this.state.alert_data.length > 0) {
            this.setState({ alert: this.state.alert_data[0] });
            this.setState({ alert_button_labels: this.state.alert.trigger.button_label, alert_link: this.state.alert.trigger.links });
            this.setState({ alert_button_action_web: this.state.alert.trigger.button_action_web, alert_id: this.state.alert.id, determinants: this.state.alert.trigger.determinants });
            window.determinants = this.state.determinants
            window.alertid = this.state.alert_id;
            window.alert_button_action_web = this.state.alert.trigger.button_action_web;
            var alert_id = this.state.alert.id;
            var evt = document.createEvent("CustomEvent");
            if (this.state.alert.trigger.subject === "CONGRATULATIONS! You Reached Your Goal") {
              evt.initCustomEvent('ReachedYourGoal', true, false, false);
              document.dispatchEvent(evt);
            }
            if (response && response.data && response.data.alerts && response.data.alerts[0].trigger && response.data.alerts[0].trigger.show_questionnaire && response.data.alerts[0].trigger.show_questionnaire.TURBO_NO) {
              localStorage.setItem('turboNo', response.data.alerts[0].trigger.show_questionnaire.TURBO_NO)
            }
            if (response && response.data && response.data.alerts && response.data.alerts[0].trigger && response.data.alerts[0].trigger.alert_type) {
              localStorage.setItem('check_transition_type', response.data.alerts[0].trigger.alert_type)
            }

            if (response && response.data && response.data.alerts && response.data.alerts[0].trigger && response.data.alerts[0].trigger.alert_type === "CIPD_TRANSITION_ALERT") {
              localStorage.setItem('check_transition_type', response.data.alerts[0].trigger.alert_type)
              $('.personal_hdr_for_alert').remove();
              $('#alert_content .modal-body').prepend('<div class="personal_hdr_for_alert"><div class="calc_hdr"><i class="fa fa-arrow-left cursor_pointer"  data-dismiss="modal" aria-hidden="true"></i> <span class="personal_hdr">Your Personalized Plan</span><img   data-target="#help_cipd" data-toggle="modal" class="quest_white  cursor_pointer" title="" alt="" src="/assets/images/question_white.jpg"></div><img src="assets/images/blaze.svg" class="blaze_img"  alt="" title=""><h3 class="mt20px personal_meal_hdr"></h3></div>');
              $('#alert_content .modal-content').addClass('blue_bg');
              $('.alert_subject,.alert_body').addClass('colorff');
              $('#alert_content button.close').hide();
              sessionStorage.setItem('check_cipd', response.data.alerts[0].trigger.alert_type);
            }
            else {
              $('.personal_hdr_for_alert').remove();
              $('#alert_content .modal-content').removeClass('blue_bg');
              $('#alert_content button.close').show();
              $('.alert_subject,.alert_body').removeClass('colorff');
              sessionStorage.removeItem('check_cipd');

            }
            $("#alert_content").modal("show");
            $(".alert_subject").html(this.state.alert.trigger.subject);
            $(".alert_body").html(this.state.alert.trigger.body);
            if (this.state.alert.trigger.subject === "Congratulations! You have less than 100 lbs to lose!") {
              evt.initCustomEvent('LessThan100ToLose', true, false, false);
              document.dispatchEvent(evt);
            } else if (this.state.alert.trigger.subject === "You have more than 100 lbs to lose!") {
              evt.initCustomEvent('MoreThan100ToLose', true, false, false);
              document.dispatchEvent(evt);
            }
            if (this.state.alert.trigger.icon_type === "EXPLOSION") {
              $(".alert_icon").html('<img alt="" src="/assets/images/transition_fireworks.png"/>');
            } else if (this.state.alert.trigger.icon_type === "WARNING") {
              $(".alert_icon").html('<img alt="" src="/assets/images/warning_tri.png"/>');
            }
            if (this.state.alert_link !== '') {
              var link_href = '';
              var link_text = '';
              $.each(this.state.alert_link, function (key, value) {
                if (key === "HREF") {
                  link_href = value;
                } else if (key === "TEXT") {
                  link_text = value;
                }
              });
              $(".alert_link").html('<a target="_blank" href="' + link_href + '">' + link_text + '</a>');
            }
            $('ul.alert_button').empty();
            $.each(this.state.alert_button_labels, function (key, value) {
              $('ul.alert_button').append('<li class="alert_button_labels" data-category="' + key + '" data-id="' + alert_id + '">' + value + '</li>');
            });
            $.each(response.data.alerts[0].trigger.show_questionnaire, function (key, value) {
              $('[data-category="' + key + '"]').attr('button', value)
            });
          }
        }
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });

  }

  progressAPI = (get_text) => {
    Header.startLoader();

    if (get_text === undefined) {
      get_text = $('.progres_weight li.active').attr('id');
    }
    const progress_body = {
      url: window.API_URL + "v1/progress/time_units/" + get_text + "/viewpoint_height/400px/viewpoint_width/550px?for_web=true",
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(progress_body)
      .then(response => {
        Header.stopLoader();
        $('.progress_error').text('');
        if (response && response.data) {
          this.setState({ progress_data: response.data });

          this.setState({ chart_data: response.data.progress.chart.html, current_weight: response.data.progress.profile.weight.current, goal_weight: response.data.progress.profile.weight.goal, message: response.data.progress.chart.message, log_weight_array: response.data.progress.weights, available_time_units: response.data.progress.chart.available_time_units, assigned_date: response.data.progress.profile.weight.assigned_date, start_weight: response.data.progress.chart.starting_weight });
          window.localStorage.setItem('progres_current_weight', response.data.progress.profile.weight.current);
          window.localStorage.setItem('progres_starting', response.data.progress.profile.weight.profile_starting);
          window.localStorage.setItem('progres_goal', response.data.progress.profile.weight.goal);
          this.getProfileStartDates();
          var get_weight = $('.total_loss').html().split(' ');
          if (get_text === "all_time") {
            var pp = '<span>' + get_weight[5] + ' ' + get_weight[6] + '</span>';
            get_weight = get_weight[0] + ' ' + get_weight[1] + ' ' + get_weight[2] + ' ' + get_weight[3] + ' ' + get_weight[4] + ' ' + pp;
            $('.total_loss').html(get_weight);
            $(".start_edit_icon").show();
          }
          if (get_text === "plan_start") {
            var pp1 = '<span>' + get_weight[2] + ' ' + get_weight[3] + '</span>';
            get_weight = get_weight[0] + ' ' + get_weight[1] + ' ' + pp1 + ' ' + get_weight[4] + ' ' + get_weight[5] + ' ' + get_weight[6] + ' ' + get_weight[7];
            $('.total_loss').html(get_weight);
            $(".start_edit_icon").show();
          }
          else if (get_text === "phase_start") {
            var pp2 = '<span>' + get_weight[2] + ' ' + get_weight[3] + '</span>';
            get_weight = get_weight[0] + ' ' + get_weight[1] + ' ' + pp2 + ' ' + get_weight[4] + ' ' + get_weight[5] + ' ' + get_weight[6] + ' ' + get_weight[7];
            $('.total_loss').html(get_weight);
            $(".start_edit_icon").show();
          }
          if (get_text === "one_week" || get_text === "one_month" || get_text === "one_year") {
            var pp3 = '<span>' + get_weight[6] + ' ' + get_weight[7] + '</span>';
            get_weight = get_weight[0] + ' ' + get_weight[1] + ' ' + get_weight[2] + ' ' + get_weight[3] + ' ' + get_weight[4] + ' ' + get_weight[5] + ' ' + pp3;
            $('.total_loss').html(get_weight);
            $(".start_edit_icon").hide();
          } else if (get_text === "three_months" || get_text === "six_months") {
            var pp4 = '<span>' + get_weight[7] + ' ' + get_weight[8] + '</span>';
            get_weight = get_weight[0] + ' ' + get_weight[1] + ' ' + get_weight[2] + ' ' + get_weight[3] + ' ' + get_weight[4] + ' ' + get_weight[5] + ' ' + get_weight[6] + ' ' + pp4;
            $('.total_loss').html(get_weight);
            $(".start_edit_icon").hide();
          }
          var chart_data = this.state.chart_data;
          $('.progress_chart').empty();
          if (chart_data !== '') {
            $(chart_data).appendTo('.progress_chart');
          } else {
            $('.progress_chart').html('<div class="no_data_display">No data to display</div>');
          }
          this.state.available_time_units.map(function (option, i) {
            return $("#" + option + "").removeClass("disable_pointer");
          });

          $('.highcharts-grid-line,.highcharts-axis-line').attr({ 'stroke-dasharray': '4, 1', 'stroke': '#f2f2f2' }); $('.highcharts-area').attr({ 'fill': '#78CA87', 'fill-opacity': '0.1' });
        }

      })
      .catch(error => {

        Header.stopLoader();
        $('.progress_error').text('');
        if (error && error.response && error.response.data && error.response.data.error) {
          $('.progress_error').text(error.response.data.error);
        }
        else if (error && error.response && error.response.status > 400) {
          $('.progress_error').text(error.response.statusText);
        }
        else {
          alert(error.message ? error.message : 'Something went wrong!');
        }
        Sentry.captureException(error);
      });
  }

  displayDate = (d) => {
    return moment(d).format('MMMM DD, YYYY');
  }

  datepickerDate = (d) => {
    return moment(d).format('MMM DD');
  }
  readAlert = () => {
    const options = {
      url: window.API_URL + 'v1/alerts/' + window.alertid + '/read',
      method: 'PUT',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        $('#alert_content').modal('hide');
        sessionStorage.removeItem('check_cipd');
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });
  }
  showPersonal = () => {
    $('.personalized_popup').hide();
    $('#personalize_div').show();
  }

  callSmartAdapt = () => {
    Header.startLoader();
    const options = {
      url: window.API_URL + 'v1/nutrisystem/apply_smart_adapt',
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };
    axios(options)
      .then(responsesmart => {
        Header.stopLoader();
        if (responsesmart.data.smart_phase === true) {
          const smart_week_new = {
            url: window.API_URL + "v1/nutrisystem/smart_week",
            method: 'GET',
            credentials: 'same-origin',
            headers: Header.setAuthHeaders(),
          };
          axios(smart_week_new)
            .then(response => {
              Header.stopLoader();
              localStorage.setItem('smart_week_setup_screen', response.data.smart_week.show_smart_week);
              if (response.data.smart_week.show_smart_week === true && localStorage.getItem("smartAdapt") === "true") {
                $('.ques_buffer').show();
                $('.ques_calculation').hide();
                $('#personalize_div').hide();
                setTimeout(() => {
                  $('.modal-content').removeClass('buffer_bg');
                  $('.buffer').hide();
                  $('.ques_buffer').hide();
                  $('.calculation').hide();
                  $('#personalize_questions').modal('show');
                  $('.smart_week_setup_option_new').show().css({ "padding-top": "25px", "padding-bottom": "10px" });;
                  $('#activity_question_options').hide();
                  $("#newCalGoal").hide();
                  $("#step0").show();
                  $("#step1").hide();
                  $("#step2").hide();
                  $("#step3").hide();
                  $("#backToPrevStep1").removeClass("d-none")
                  $("#backToPrevStep12").addClass("d-none")
                  $("#backToPrevStep21").addClass("d-none")
                  $("#backToPrevStep2").removeClass("d-none")
                }, 5000);
              }
              else {
                document.querySelector('.final_cipd_value').innerHTML = responsesmart.data.cipd_display;
                $('#personalize_questions .modal-content').addClass('padding0');
                $('.ques_buffer').show();
                $('#personalize_questions').modal('show');
                $('#personalize_div').hide();
                setTimeout(() => {
                  $('.ques_buffer').hide();
                  $('.ques_calculation').show();
                }, 2000);
              }
            })

        }
        else {

          this.progressAPI();
        }
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });
  }

  componentDidMount() {

    /*Key strings*/
    $('.progress_title_key').text(window.keystrings['com.numi.progress_title']);
    $('.my_milestone_key').html(window.keystrings['com.numi.my_milestone_link']);
    $('.weight_log_history_key').html(window.keystrings['com.numi.progress_weight_log_history']);
    $('.log_weight_key').html(window.keystrings['com.numi.log_weight']);
    /*Key strings*/

    $(document).on('click', '.close_alert', function () {
      this.readAlert();
    }.bind(this));

    function readAlertAPI() {
      const options = {
        url: window.API_URL + 'v1/alerts/' + window.alertid + '/read',
        method: 'PUT',
        credentials: 'same-origin',
        headers: Header.setAuthHeaders(),
      };

      axios(options)
        .then(response => {
          $('#alert_content').modal('hide');
          sessionStorage.removeItem('check_cipd');
        })
        .catch(error => {
          Header.stopLoader();
          Header.apiErrors(error);
          Sentry.captureException(error);
        });
    }


    $(document).on('click', '.progress li.alert_button_labels', function (e) {

      e.stopImmediatePropagation();
      if (sessionStorage.getItem('check_cipd') !== "CIPD_TRANSITION_ALERT") {
        setTimeout(function () {
          readAlertAPI();
        }, 800)


      } else {
        setTimeout(function () {
          $('.modal').modal('hide');
          $('#activity_questions .modal-header').show();
          $('.text-green-darker').find('img').each(function () {
            if ($(this).parent().hasClass('active')) {
              $(this).attr('src', $(this).attr('active_image'));
              $(this).parent().css('opacity', 1).addClass('active');
            }
            else {
              $(this).attr('src', $(this).attr('normal_image'));
              $(this).parent().css('opacity', 0.3).removeClass('active');
            }
          });
          $('#activityGoal .row > div').hide();
          $('#activityGoal .row > div:eq(1)').show();
          $('.calculation,.buffer').hide();
          $('#activity_questions').modal('show');
          localStorage.setItem('show_alert_or_boost', 'true');
        }, 800);
        setTimeout(function () {
          $('body').addClass('modal-open');
        }, 1200)
        $('.calorie_range_txt').html(window.keystrings['com.numi.calorie_goal_text_alert']);
        $('.personalized_hdr').html(window.keystrings['com.numi.personalized_hdr_alert']);
        return;
      }


      var _this = this;
      var button = e.target.getAttribute('data-category');
      $.each(window.alert_button_action_web, function (key, value) {
        if (key === button) {
          var url = value;
          if (url === "RESTART") {
            $('#alert_content').modal('hide');
            window.localStorage.setItem('show_restart_popup', true);
            _this.props.history.push('/plan_settings');
          } else if (url === "SET_GOAL_WEIGHT") {
            $('#alert_content').modal('hide');
            $(".set_current").val(window.localStorage.getItem('progres_current_weight'));
            $(".set_goal").val(window.localStorage.getItem('progres_goal'));
            $('#set_goal_weight_popup').modal('show');
          }
          else if (url !== "CLOSE") {

            const options = {
              url: window.API_URL + url,
              method: 'GET',
              credentials: 'same-origin',
              headers: Header.setAuthHeaders(),
            };
            localStorage.setItem('set_url', url);
            axios(options)
              .then(response => {
                $('#alert_content').modal('hide');
                Header.stopLoader();
                if (response && response.data) {
                  var alert_next_determinant = response.data.transition_determinant.next_determinant;
                  if (e.target.getAttribute('button') === 'true') {
                    $('#personalize_questions .modal-content').addClass('padding0');
                    $('#personalize_div').addClass('from_button');
                    $('#personalize_questions').modal('show');
                    localStorage.setItem('set_true', 'true');
                    localStorage.setItem('set_button_true', 'true');
                    localStorage.setItem('button_url_next_determinant', alert_next_determinant);
                    localStorage.setItem('button_url_response', JSON.stringify(response.data.transition_determinant));
                    document.querySelector('.quiz_back').style.display = 'none';
                  }
                  else {
                    $('#personalize_questions .modal-content').removeClass('padding0');
                    $('#personalize_div').removeClass('from_button');
                    $('#personalize_div').hide();
                    if (alert_next_determinant !== null && alert_next_determinant !== '' && alert_next_determinant === 'GOAL_WEIGHT') {
                      $('#alert_content').modal('hide');
                      var curr_val = window.localStorage.getItem('progres_current_weight');
                      var final_curr_val;
                      if (curr_val % 1 === 0) {
                        final_curr_val = curr_val;
                      } else {
                        final_curr_val = parseFloat(curr_val).toFixed(2);
                      }
                      $(".set_current").val(final_curr_val);
                      var goal_val = window.localStorage.getItem('progres_goal');
                      var final_goal_val;
                      if (goal_val % 1 === 0) {
                        final_goal_val = goal_val;
                      } else {
                        final_goal_val = parseFloat(goal_val).toFixed(2);
                      }
                      $(".set_goal").val(final_goal_val);
                      $('#set_goal_weight_popup').modal('show');
                      $('.confirm_goal_h6').text(window.keystrings['com.numi.journal.stage_options'])
                      $('.confirm_goal_h3').text(window.keystrings['com.numi.goal_weight_label']);
                      $('#progress_set_goal_weight_submit').text(window.keystrings['com.numi.continue']);
                      window.localStorage.setItem('transition_url_goal_weight', url);

                    } else if (alert_next_determinant === "LOW_BLOOD_SUGAR") {
                      window.localStorage.setItem('transition_url_goal_weight', url);
                      $('#alert_content').modal('hide');
                      $('#low_blood_sugar').modal('show');
                    }
                    else if (alert_next_determinant === "ACTIVITY_LEVEL") {
                      window.localStorage.setItem('transition_url_goal_weight', url);
                      $('#alert_content').modal('hide');
                      $('#activity_level_content').modal('show');
                      $('.success_phase').attr('id', alert_next_determinant);
                      $('#less_than_60').val(response.data.transition_determinant.values[0]);
                      $('#equal_60').val(response.data.transition_determinant.values[1]);
                      $('#greater_than_60').val(response.data.transition_determinant.values[2]);
                      $('#' + alert_next_determinant).find('input').each(function () {
                        if (response.data.transition_determinant.default !== '') {
                          if ($(this).attr('name') === response.data.transition_determinant.default) {
                            $(this).next().css('opacity', 1);
                            $(this).prop('checked', true);
                          }
                          else {
                            $(this).next().css('opacity', '0.4');
                            $(this).prop('checked', false);
                          }
                        }
                      });
                    }
                    else if (alert_next_determinant === "CIPD_RANGE") {
                      $('#activity_questions .modal-header').show();
                      $('.text-green-darker').find('img').each(function () {
                        if ($(this).parent().hasClass('active')) {
                          $(this).attr('src', $(this).attr('active_image'));
                          $(this).parent().css('opacity', 1).addClass('active');
                        }
                        else {
                          $(this).attr('src', $(this).attr('normal_image'));
                          $(this).parent().css('opacity', 0.3).removeClass('active');
                        }
                      });
                      $('#activityGoal .row > div').hide();
                      $('#activityGoal .row > div:eq(0)').show();
                      $('.calculation,.buffer').hide();
                      $('#activity_questions').modal('show');
                      setTimeout(function () {
                        $('body').addClass('modal-open');
                      }, 1200);
                    }
                    else if (alert_next_determinant === "TURBO_TAKEOFF") {
                      $('.turbo_week_key').html(response.data.transition_determinant.misc_data.is_first_week_turbo);
                      window.localStorage.setItem('transition_url_goal_weight', url);
                      $('#alert_content').modal('hide');
                      $('.turbo_phase_option').attr('id', alert_next_determinant);
                      $('#turbo_takeoff_content').modal('show');
                    }
                    else {
                      $('#alert_content').modal('hide');
                    }
                  }
                }
              })
              .catch(error => {
                Header.stopLoader();
                Header.apiErrors(error);
                Sentry.captureException(error);
              });

          } else {
            $('#alert_content').modal('hide');
          }
        }
      });
    }.bind(this));

    $('.react-datepicker__input-container input').attr('readonly', true);

    $(document).on("input", ".numeric", function (e) {
      this.value = this.value.replace(/[^0-9.]/g, '');
      var curr_input_value = this.value + '.';
      if (this.value.length >= 4) {
        if (this.value.indexOf('.') < 0) {

          curr_input_value.split('');
        }
        else {
          curr_input_value = this.value;
        }

        if (this.value.length === 4) {
          if (this.value.indexOf('.') < 0) {
            this.value = curr_input_value[0] + curr_input_value[1] + curr_input_value[2] + curr_input_value[4] + curr_input_value[3];
          }
          else {
            this.value = curr_input_value;
          }
        }
        else {
          if (this.value.length === 5) {
            if (this.value.indexOf('.') < 0) {
              this.value = curr_input_value[0] + curr_input_value[1] + curr_input_value[2] + curr_input_value[5] + curr_input_value[3] + curr_input_value[4];
            }
            else {
              this.value = curr_input_value;
            }
          }
        }
      }
    });

    // On load calling progress API
    this.progressAPI("all_time");
    this.getprogressAlerts();

    // On click jquery events
    $(document).on('click', '.log_weight', function () {
      $('.log_weight_error').hide();
      if ($('.today_date').length <= 0) {
        $('#log_weight .react-datepicker__input-container').prepend('<div class="today_date">Today</div>');
      }

    });



    $(document).on('click', '.edit_click', function () {
      /*Key string move weight*/
      $('.edit_weight_header').text(window.keystrings['com.numi.edit_weight']);
      $('.edit_save_button').text(window.keystrings['com.numi.keypad.save']);
      /*Key string move weight*/
      $('.changed_edit_value').removeClass('current_edit');
      $(this).parent().find('.changed_edit_value').addClass('current_edit');
      $('.edit_selected_date').removeClass('get_selected_date');
      $(this).parents('li.weight_li').find('.edit_selected_date').addClass('get_selected_date');
    });



    $('.progres_weight li a[href="#all_time"]').click(function () {

      $('.change_txt').text(window.keystrings['com.numi.all_time_starting_weight']);
    });
    $('.progres_weight li a[href="#plan_start"]').click(function () {
      $('.change_txt').text(window.keystrings['com.numi.progress.detail.starting_weight.plan_start']);
    });
    $('.progres_weight li a[href="#phase_start"]').click(function () {
      //$('.change_txt').text('Weight at Phase Start');
      $('.change_txt').text(window.keystrings['com.numi.progress.detail.starting_weight.phase_start']);
    });
    $('.progres_weight li a[href="#one_week"]').click(function () {
      $('.change_txt').text(window.keystrings['com.numi.progress.detail.starting_weight.one_week']);
    });
    $('.progres_weight li a[href="#one_month"]').click(function () {
      $('.change_txt').text(window.keystrings['com.numi.progress.detail.starting_weight.one_month']);
    });
    $('.progres_weight li a[href="#three_month"]').click(function () {
      $('.change_txt').text(window.keystrings['com.numi.progress.detail.starting_weight.three_month']);
    });
    $('.progres_weight li a[href="#six_month"]').click(function () {
      $('.change_txt').text(window.keystrings['com.numi.progress.detail.starting_weight.six_month']);
    });
    $('.progres_weight li a[href="#one_year"]').click(function () {
      $('.change_txt').text(window.keystrings['com.numi.progress.detail.starting_weight.one_year']);
    });

    $(document).on('click', '.current_edit_value', function () {
      $('#edit_weight input[type="text"]').val($(this).parents('.dropdown').find('.changed_edit_value').text());
      $(".edit_weight_error").empty();
    })

    /* key strings*/
    $('.progress-stage-start-key-strings').html(window.keystrings['com.numi.progress.stage_start']);
    $('.progress-all-time-key-strings').text(window.keystrings['com.numi.progress_title_all_time']);
    $('.progress-plan-start-key-strings').html(window.keystrings['com.numi.progress.plan_start']);
    $('.progress-1week-key-strings').text(window.keystrings['com.numi.progress_title_one_week']);
    $('.progress-1month-key-strings').text(window.keystrings['com.numi.progress_title_one_month']);
    $('.progress-3month-key-strings').text(window.keystrings['com.numi.progress_title_three_month']);
    $('.progress-6month-key-strings').text(window.keystrings['com.numi.progress_title_six_month']);
    $('.progress-1year-key-strings').text(window.keystrings['com.numi.progress_title_one_year']);
    $('.all_time_starting_weight_key').text(window.keystrings['com.numi.all_time_starting_weight']);
    $('.lbs_key').text(window.keystrings['com.numi.lbs']);
    $('.current_weight_key').text(window.keystrings['com.numi.progress.current_weight']);
    $('.goal_weight_key').text(window.keystrings['com.numi.progress.goal_weight']);
    $('.milestone a').prop('href', window.localStorage.getItem('new_upload_path') + '&view=images_view&browser=true');
  }


  deletedata = () => {

    $('.dropdown-menu').removeClass('show')
    /*Key string move weight*/
    $('.delete_weight_header').text(window.keystrings['com.numi.progress_delete_weight_alert_title']);
    $('.delete_weight_yes').text(window.keystrings['com.numi.yes']);
    $('.delete_weight_cancel').text(window.keystrings['com.numi.cancel']);
    /*Key string move weight*/
    $('.delete_logged_weight').parents('li').removeClass('delete_record');
    $(this).parents('li').addClass('delete_record');
    $('.delete_record_li').empty();
    $('.delete_record_li').append($(this).parents('li').html());
    $('.delete_error').hide();
    var delete_date = moment(this.state.getSelectedDate).format('MMMM DD, YYYY');
    $('.delete_record_li').text(delete_date);
  }


  setEditdata = () => {
    $('.edit_weight_header').text(window.keystrings['com.numi.edit_weight']);
    $('.edit_save_button').text(window.keystrings['com.numi.keypad.save']);
    /*Key string move weight*/
    $('.changed_edit_value').removeClass('current_edit');
    $(this).parent().find('.changed_edit_value').addClass('current_edit');
  }

  movelogedclick = () => {

    // e.stopImmediatePropagation();
    /*Key string move weight*/
    // console.log(window.keystrings['com.numi.progress.move_weight_from'])
    $('.move_logged_weight_header').text(window.keystrings['com.numi.move_to_another_date']);
    var move_weight_from_content = "" + window.keystrings['com.numi.progress.move_weight_from'] + "";
    var move_weight_to_content = "" + window.keystrings['com.numi.progress.copied_to'] + "";
    $('.move_weight_text').text(window.keystrings['com.numi.weight']);
    $('.move_weight_date').text(window.keystrings['com.numi.progress.date']);
    $('.move_weight_done').text(window.keystrings['com.numi.done']);
    $('.progress_edit_weight_alert_title_front').text(window.keystrings['com.numi.progress_edit_weight_alert_title_front']);
    $('.progress_edit_weight_alert_title_end').text(window.keystrings['com.numi.progress_edit_weight_alert_title_end']);
    $('.move_weight_yes').text(window.keystrings['com.numi.yes']);
    $('.move_weight_cancel').text(window.keystrings['com.numi.cancel']);
    /*Key string move weight  */
    $('li.weight_li').removeClass('move_from_date');

    // e.currentTarget.parentElement.parentElement.parentElement.parentElement.classList.add('move_from_date');
    var move_from_date = moment(this.state.getSelectedDate).format('MMMM DD, YYYY');
    var move_to_date = moment().format('MMMM DD, YYYY');
    $("div.move_text").text('');
    $("div.move_text").html("" + move_weight_from_content + " <b>" + move_from_date + "</b> " + move_weight_to_content + " <b class='move_date'> " + move_to_date + " </b>");
    this.getWeightOn(move_from_date);
    $("#move_from").val(move_from_date);
    $(".move_weight_body").show();
    $(".duplicate_weight").hide();
    this.setState({
      startDate: new Date()
    });
    Header.changeDate(new Date());
  }

  getWeightOn(move_from_date) {
    var assigned_date = moment(move_from_date).format('YYYY-MM-DD');

    const options = {
      url: window.API_URL + "v1/weights/assigned_date/" + assigned_date,
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {

        $('#move_weight').modal('show');
        var move_from_date_lbs = this.state.getSelecectedWeight;
        $('#lbs_move_from_weight b').text('');
        $("div#lbs_move_from_weight b").text(move_from_date_lbs);
      })
      .catch(error => {
        $('#weight_ul span').removeClass('open');
        Header.apiErrors(error);
        Sentry.captureException(error);
      });

  }

  render() {
    var log_weight_list1 = this.state.log_weight_array
    if (log_weight_list1) {
      var log_weight_list = log_weight_list1.map(function (option, i) {
        if ((new Date(option.assigned_date) > new Date()) || (option.assigned_date === this.state.profile_start_date) || (option.assigned_date === this.state.plan_start_date) || (option.assigned_date === this.state.phase_start_date) || (option.is_current_inferred !== undefined && option.is_current_inferred.toString() === 'true')) {
          return (
            <li className="color6 font15 weight_li" key={i} onClick={() => {
              this.setState({
                getSelectedDate: option.assigned_date,
                getSelecectedWeight: option.current
              }, () => {
                this.setEditdata()
              })
            }}>
              <span className="pull-left edit_selected_date">{this.displayDate(option.assigned_date)}</span>
              <span className="pull-right dropdown"><span><b className="changed_edit_value">{option.current}</b></span> lbs &nbsp;<span className="relative">
                <ul className="dropdown-menu progress_empty_options align_indicator only_edit">
                  <li><span className='current_edit_value' data-toggle="modal" data-target="#edit_weight" >Edit Weight  </span></li>
                </ul>
                <img alt="" src="/assets/images/edit_small.png" data-toggle="dropdown" className="cursor_pointer" /></span></span>
            </li>
          )
        }
        else {
          return (
            <li className="color6 font15 weight_li" key={i} onClick={() => {
              this.setState({
                getSelectedDate: option.assigned_date,
                getSelecectedWeight: option.current
              }, () => {
                this.setEditdata()
              })
            }}>
              <span className="pull-left edit_selected_date">{this.displayDate(option.assigned_date)}</span>
              <span className="pull-right dropdown"><span><b className="changed_edit_value">{option.current}</b></span> lbs &nbsp;<span className="relative">
                <ul className="dropdown-menu progress_empty_options align_indicator"><li>
                  <span className='current_edit_value' data-toggle="modal" data-target="#edit_weight">Edit Weight</span></li>
                  <li className="move_logged_weight"
                    onClick={() => {
                      this.setState({
                        getSelectedDate: option.assigned_date,
                        getSelecectedWeight: option.current

                      }, () => {
                        this.movelogedclick()
                      })
                    }}
                  ><span>Move to Another Date</span></li>
                  <li className="delete_logged_weight" onClick={() => {
                    this.setState({
                      getSelectedDate: option.assigned_date,
                      getSelecectedWeight: option.current
                    }, () => {
                      this.deletedata()
                    })
                  }}><span data-toggle="modal" data-target="#delete_weight">Delete
                    </span></li>
                </ul><img alt="" src="/assets/images/edit_small.png" data-toggle="dropdown" className="cursor_pointer" /></span></span>
            </li>
          )
        }

      }.bind(this));
    }
    return (
      <div>

        <div className="table_display">
          <div className="journal_div progress">
            <div className="section_left pull-left">
              <Navigation />
            </div>
            <div className="section_right pull-left">
              <figure><Adspeed /></figure>
              <div className="journal_rht">
                {/* <h1 className="journal_hdr pull-left progress_title_key"> </h1> */}
                {/* <div className="pull-right mt2 mile_rht">
            <span className="milestone font15">
             <Link className="color5" to="/photos"><img alt="" src="/assets/images/add_photo_green.png" className="vlb"/> &nbsp; Add Photos</Link>
              </span>
              &nbsp;
              <span className="milestone font15">
                <Link className="color5" to="/milestone"><img alt="" src="/assets/images/trophey.png" className="vlb"/> &nbsp; My Milestones</Link>
              </span>
              </div>
              <div className="clear"></div> */}

                <div className="hdr_section mt30">
                  <h1 className="hdr_txt progress_title_key"></h1>
                  <div className="mile_rht">
                    <span className="milestone font15">
                      <Link className="color5" to="/photos"><img alt="" src="/assets/images/add_photo_green.png" className="vlb" /> &nbsp; Add Photos</Link>
                    </span>
                    &nbsp;
                    <span className="milestone font15">
                      <Link className="color5" to="/milestone"><img alt="" src="/assets/images/trophey.png" className="vlb" /> &nbsp; My Milestones</Link>
                    </span>
                  </div>
                </div>
                <hr />
                <p className="error_txt progress_error text-center"></p>
                <div className="panel-group meal_section_left pull-left">
                  <ul className="favorite_section progres_weight">
                    <li id="all_time" className={this.state.planStartType == 'all_time' ? "active disable_pointer" : "disable_pointer"} onClick={() => {
                      this.setState({
                        planStartType: 'all_time',
                      }, () => {
                        this.allTimeStartWeight()

                      })
                    }}><a onClick={() => this.changeTimeUnit('all_time')} href="#all_time" data-toggle="tab" aria-expanded="true" className="progress-all-time-key-strings"> </a></li>
                    <li id="plan_start" className={this.state.planStartType == 'plan_start' ? "active disable_pointer" : "disable_pointer"} onClick={() => {
                      this.setState({
                        planStartType: 'plan_start',
                      }, () => {
                        this.enableStartWeight()
                      })
                    }} ><a onClick={() => this.changeTimeUnit('plan_start')} href="#plan_start" data-toggle="tab" aria-expanded="false" className="progress-plan-start-key-strings"> </a></li>
                    <li id="phase_start" className={this.state.planStartType == 'phase_start' ? "active disable_pointer" : "disable_pointer"}
                      onClick={() => {
                        this.setState({
                          planStartType: 'phase_start',
                        }, () => {
                          this.enableStartWeight()
                        })
                      }}
                    ><a onClick={() => this.changeTimeUnit('phase_start')} href="#phase_start" data-toggle="tab" aria-expanded="false" className='progress-stage-start-key-strings'> </a></li>
                    <li id="one_week" className={this.state.planStartType == 'one_week' ? "active disable_pointer" : "disable_pointer"}
                      onClick={() => {
                        this.setState({
                          planStartType: 'one_week',
                        }, () => {
                          this.callMultiple()
                        })
                      }}
                    ><a onClick={() => this.changeTimeUnit('one_week')} href="#one_week" data-toggle="tab" aria-expanded="false" className="progress-1week-key-strings"> </a></li>
                    <li id="one_month" className={this.state.planStartType == 'one_month' ? "active disable_pointer" : "disable_pointer"}
                      onClick={() => {
                        this.setState({
                          planStartType: 'one_month',
                        }, () => {
                          this.callMultiple()
                        })
                      }}
                    ><a onClick={() => this.changeTimeUnit('one_month')} href="#one_month" data-toggle="tab" aria-expanded="false" className="progress-1month-key-strings"> </a></li>
                    <li id="three_months" className={this.state.planStartType == 'three_months' ? "active disable_pointer" : "disable_pointer"} onClick={() => {
                      this.setState({
                        planStartType: 'three_months',
                      }, () => {
                        this.callMultiple()
                      })
                    }} ><a
                      onClick={() => this.changeTimeUnit('three_months')} href="#three_month" data-toggle="tab" aria-expanded="false" className="progress-3month-key-strings"> </a></li>
                    <li id="six_months" className={this.state.planStartType == 'six_months' ? "active disable_pointer" : "disable_pointer"} onClick={() => {
                      this.setState({
                        planStartType: 'six_months',
                      }, () => {
                        this.callMultiple()
                      })
                    }} ><a onClick={() => this.changeTimeUnit('six_months')} href="#six_month" data-toggle="tab" aria-expanded="false" className="progress-6month-key-strings"> </a></li>
                    <li id="one_year" className={this.state.planStartType == 'one_year' ? "active disable_pointer" : "disable_pointer"} onClick={() => {
                      this.setState({
                        planStartType: 'one_year',
                      }, () => {
                        this.callMultiple()
                      })
                    }} ><a onClick={() => this.changeTimeUnit('one_year')} href="#one_year" data-toggle="tab" aria-expanded="false" className="progress-1year-key-strings"> </a></li>
                  </ul>
                  <div className="weight_progress" >
                    <div className="pull-left mt2">
                      <p className="font11 margin0 color8f"><span className="change_txt all_time_starting_weight_key"></span></p>
                      <p>
                        <span className="weight_input start_weight font26">{this.state.start_weight}</span>
                        <span className="display_block"> <img alt="" src="/assets/images/edit_small.png" data-toggle="modal" className="cursor_pointer start_edit_icon" data-target="#start_weight" /><br /><span className="font11 color8f lbs_key"></span></span>
                      </p>
                    </div>
                    <div className="pull-left">
                      <p className="font13 margin0 color8f current_weight_key"></p>
                      <p>
                        <span className="weight_input current_weight font46">{this.state.current_weight}</span>
                        <span className="display_block"> <img alt="" src="/assets/images/edit_small.png" data-toggle="modal" className="cursor_pointer current_weight_click" data-target="#current_weight" /><br /><span className="font11 color8f lbs_key"></span></span>
                      </p>
                    </div>
                    <div className="pull-left mt2">
                      <p className="font11  margin0 color8f goal_weight_key"></p>
                      <p>
                        <span className="weight_input font26 goal_weight goal_weight_final">{this.state.goal_weight}</span>
                        <span className="display_block"> <img alt="" src="/assets/images/edit_small.png" data-toggle="modal" className="cursor_pointer goal_weight_click" data-target="#goal_weight" /><br /><span className="font11 color8f lbs_key"></span></span>
                      </p>
                    </div>
                  </div>
                  <div className="clear"></div>
                  <div className="text-center font18 color8f border_top_bottom ptb10">
                    <img alt="" src="/assets/images/weight_log.png" /> <span className="total_loss">{this.state.message}</span>
                  </div>
                  <div className="tab-content text-center">
                    <div className="tab-pane active" id="all_time">

                    </div>
                    <div className="tab-pane" id="plan_start">

                    </div>
                    <div className="tab-pane" id="phase_start">

                    </div>
                    <div className="tab-pane" id="one_week">

                    </div>
                    <div className="tab-pane" id="one_month">

                    </div>
                    <div className="tab-pane" id="three_month">

                    </div>
                    <div className="tab-pane" id="six_month">

                    </div>
                    <div className="tab-pane" id="one_year">

                    </div>
                    <div className="progress_chart"></div>
                  </div>
                </div>
                <div className="meal_section_right weight_log_history pull-right">
                  <button className="log_weight log_weight_key" onClick={this.setLogWeight} ></button>
                  <div className="right_container width100">
                    <p className="color6 font18 weight_log_history_key"></p>
                    <ul id='weight_ul'>
                      {log_weight_list}
                    </ul>
                  </div>

                </div>
              </div>
              <Footer />
              <CurrentWeight callAlertAPI={this.getprogressAlerts} callProgressAPI={this.progressAPI} getAssignDate={this.state.assigned_date} submitCurrentWeight={this.changeCurrent} changeInput={this.inputChange} currentValue={this.state.current_weight} />
              <StartWeight callAlertAPI={this.getprogressAlerts} callProgressAPI={this.progressAPI} getAssignDate={this.state.assigned_date} submitStartWeight={this.state.start_weight} changeInput={this.inputChange} startValue={this.state.start_weight} currentValue={this.state.current_weight} />
              <GoalWeight callAlertAPI={this.getprogressAlerts} callProgressAPI={this.progressAPI} submitGoalWeight={this.changeGoal} changeInput={this.inputChange} goalValue={this.state.goal_weight} />
              <LogWeight callAlertAPI={this.getprogressAlerts} get_profile_date={this.state.profile_start_date} callProgressAPI={this.progressAPI} loggingWeight={this.LogWeight} changeInput={this.inputChange} logValue={this.state.logWeight} getCurrentVal={this.state.current_weight} calldatepickerDate={this.datepickerDate} />
              <EditWeight callAlertAPI={this.getprogressAlerts} callProgressAPI={this.progressAPI} editLogWeight={this.changeEditWeight} changeInput={this.inputChange} logValue={this.state.logWeight} logDate={this.state.getSelectedDate} logWeightdata={this.state.getSelecectedWeight} />
              <DeleteWeight callAlertAPI={this.getprogressAlerts} get_time={this.state.time_unit} callProgressAPI={this.progressAPI} logDate={this.state.getSelectedDate} />
              <MoveToNextDate callAlertAPI={this.getprogressAlerts} get_profile_date={this.state.profile_start_date} callProgressAPI={this.progressAPI} calldisplayDate={this.displayDate} calldatepickerDate={this.datepickerDate} MovelogDate={this.state.getSelectedDate} />
              <CongratsPopup />
              <WeightToLoss />
              <AlertPopup />
              <SetGoalWeightPopup callProgressAPI={this.progressAPI} callSmartAdapt={this.callSmartAdapt} />
              <LowBloodSugarPopup />
              <ActivityLevelPopup />
              <TurboTakeoffPopup callProgressAPI={this.progressAPI} />
              <div className="modal fade" id="personalize_questions" data-backdrop="static">
                <div className="modal-dialog">

                  <div className="modal-content personalized">
                    <div className="personalized_popup">
                      <div className="calc_hdr"><i></i> <span className="personal_hdr" dangerouslySetInnerHTML={{ __html: window.keystrings['com.numi.personalized_plan'] }}></span> <img className="quest_white  cursor_pointer" title="" alt="" src="/assets/images/question_white.jpg" data-target="#help_cipd" data-toggle="modal" /></div>
                      <img src="/assets/images/blaze.svg" className="blaze_img" alt="" title="" />
                      <h3 className="mt20px personal_meal_hdr" dangerouslySetInnerHTML={{ __html: window.keystrings['com.numi.personalized_meal_hdr'] }}></h3>
                      <div className="mt20px personalized_text font18" dangerouslySetInnerHTML={{ __html: window.keystrings['com.numi.personalized_text'] }}></div>
                      <button onClick={this.showPersonal}>Continue</button>
                    </div>
                    <PersonalizePlanMode callSmartAdapt={this.callSmartAdapt} callProgressAPI={this.progressAPI} childRefProgress={ref => (this.ProgressStartQuiz = ref)} />
                  </div>
                </div>
              </div>
              <ActivityQuestions onRef={ref => (this.child = ref)} callReadAPI={this.readAlert} callProgress={this.progressAPI} />
              <div className="modal fade" id="help_cipd">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-body width90 mt15">
                      <img src="/assets/images/personalize_icon.svg" alt="" title="" />
                      <h3 className="mt15" dangerouslySetInnerHTML={{ __html: window.keystrings['com.numi.smart_adapt_help_header'] }}></h3>
                      <h5 className="mt15" dangerouslySetInnerHTML={{ __html: window.keystrings['com.numi.smart_adapt_help_description_web'] }}></h5><br />
                      <p className="help_points_web" dangerouslySetInnerHTML={{ __html: window.keystrings['com.numi.smart_adapt_help_points_web'] }}></p>
                      <button className="width77" data-dismiss="modal">Close</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >)
  }
}

class CurrentWeight extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      current_weight: this.props.currentValue
    }
  }

  inputUpdate = (e) => {
    this.setState({ current_weight: e.target.value });
  };

  submitCurrent = (e) => {
    var weight_error = '';
    if ($('#current_weight [name="current_input"]').val() === '') {
      weight_error = window.keystrings['com.numi.your_bmi_unhealthy'] + " \n"
    }
    if ($('#current_weight [name="current_input"]').val() === '0') {
      weight_error = window.keystrings['com.numi.your_bmi_unhealthy'] + " \n "
    }
    if (weight_error !== '') {
      $('.current_error').text('');
      $('.current_error').append(weight_error);
      $('.current_error').show();
    }
    else {
      $('.current_error').text('');
      var _current_date = moment().format('YYYY-MM-DD') /*this.props.getAssignDate;*/
      var weight_params = { "weight": { "current": $('#current_weight [name="current_input"]').val(), "assigned_date": _current_date } }
      // Weight update API

      const options = {
        url: window.API_URL + "v1/weights",
        method: 'POST',
        credentials: 'same-origin',
        headers: Header.setAuthHeaders(),
        data: weight_params
      };

      axios(options)
        .then(response => {
          this.props.callProgressAPI();
          this.props.submitCurrentWeight(this.state.current_weight);
          this.setState({ current_weight: this.state.current_weight });
          $('#current_weight').modal('hide');
          this.props.callAlertAPI();
        })
        .catch(error => {
          $('.current_error').text('');
          $('.current_error').show();
          if (error && error.response && error.response.data && error.response.data.error) {
            $('.current_error').append(error.response.data.error);
          }
          else if (error && error.response && error.response.status > 400) {
            $('.current_error').append(error.response.statusText);
          }
          else if (error && error.message) {
            alert(error.message);
          }
          Sentry.captureException(error);
        });


    }
  };

  componentDidMount() {

    /* Key String */
    $('.current_weight_header').text(window.keystrings['com.numi.progress.current_weight']);
    $('.current_weight_save').text(window.keystrings['com.numi.keypad.save']);
    $('.continue_text').text(window.keystrings['com.numi.continue'])
    $('.save_text').text(window.keystrings['com.numi.save'])
    /* Key String */

    $(document).on("input", ".numeric", function (e) {
      this.value = this.value.replace(/[^0-9]/g, '');
    });

    $('[data-target="#current_weight"]').click(function () {
      $('#current_weight [name="current_input"]').val($('.current_weight').text());
      $('.current_error').hide();
    });
  }

  render() {
    return (
      <div className="modal fade" id="current_weight">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button data-dismiss="modal" type="button" className="close"><img alt="" src="/assets/images/close_grey.png" /></button>
            </div>
            <div className="modal-body">
              <p className="current_error error_txt"></p>
              <p className="font26 color3 mt5 font700 current_weight_header"></p>
              <p className="mt5"><input type="text" className="numeric color6 font16 bold" name="current_input" maxLength="6" onKeyUp={this.inputUpdate} onChange={this.inputUpdate} />&nbsp;&nbsp;lbs.</p>
              <p><button type="submit" className="mt6 current_weight_save" value="" onClick={this.submitCurrent}></button></p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

class StartWeight extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      start_weight: this.props.startValue,
      current_weight: this.props.currentValue

    }
  }

  inputUpdate = (e) => {
    this.setState({ start_weight: e.target.value });
  };

  submitStart = (e) => {
    var start_key;
    if ($('#all_time').hasClass("active")) {
      start_key = "profile_starting";
    } else if ($('#plan_start').hasClass("active")) {
      start_key = "plan_starting";
    } else if ($('#phase_start').hasClass("active")) {
      start_key = "phase_starting";
    }

    var weight_error = '';
    if ($('#start_weight [name="current_input"]').val() === '') {
      weight_error = window.keystrings['com.numi.your_bmi_unhealthy'] + " \n"
    }
    if ($('#start_weight [name="current_input"]').val() === '0') {
      weight_error = window.keystrings['com.numi.your_bmi_unhealthy'] + " \n "
    }
    if (weight_error !== '') {
      $('.start_weight_error').text('');
      $('.start_weight_error').append(weight_error);
      $('.start_weight_error').show();
    }
    else {
      $('.start_weight_error').text('');
      var _start_date = this.props.getAssignDate;
      var weight_params = { "weight": { [start_key]: $('#start_weight [name="current_input"]').val(), "assigned_date": _start_date } }
      // Weight update API


      const options = {
        url: window.API_URL + "v1/weights",
        method: 'POST',
        credentials: 'same-origin',
        headers: Header.setAuthHeaders(),
        data: weight_params
      };

      axios(options)
        .then(response => {
          if (response && response.data) {
            window.localStorage.setItem('plan_starting_weight', response.data.weight.plan_starting);
            this.props.callProgressAPI();
            this.setState({ current_weight: this.state.start_weight });
            $('#start_weight').modal('hide');
            this.props.callAlertAPI();
          }
        })
        .catch(error => {
          $('.start_weight_error').text('');
          $('.start_weight_error').show();
          if (error && error.response && error.response.data && error.response.data.error) {
            $('.start_weight_error').append(error.response.data.error);
          }
          else if (error && error.response && error.response.status > 400) {
            $('.start_weight_error').append(error.response.statusText);
          }
          else if (error && error.message) {
            alert(error.message);
          }
          Sentry.captureException(error);
        });

    }
  };

  componentDidMount() {
    /* Key String */
    $('.start_weight_header').text(window.keystrings['com.numi.progress.start_weight']);
    $('.start_weight_save').text(window.keystrings['com.numi.keypad.save']);
    /* Key String */
    $(document).on("input", ".numeric", function (e) {
      this.value = this.value.replace(/[^0-9]/g, '');
    });
    $(document).on('click', '[data-target="#start_weight"]', function () {
      $('#start_weight_value').val($('.start_weight').text());

      $('.start_weight_error').hide();
    });
  }

  render() {
    return (
      <div className="modal fade" id="start_weight">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button data-dismiss="modal" type="button" className="close"><img alt="" src="/assets/images/close_grey.png" /></button>
            </div>
            <div className="modal-body">
              <p className="start_weight_error error_txt"></p>
              <p className="font26 color3 mt5 font700 start_weight_header"></p>
              <p className="mt5"><input type="text" className="numeric color6 font16 bold" name="current_input" maxLength="6" id="start_weight_value"
              />&nbsp;&nbsp;lbs.</p>
              <p><button type="submit" className="mt6 start_weight_save" value="" onClick={this.submitStart}> </button></p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

class GoalWeight extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      goal_weight: this.props.goalValue
    }
  }

  inputUpdate = (e) => {
    this.setState({ goal_weight: e.target.value });
  };

  submitGoal = (e) => {
    Header.startLoader();
    $('.goal_error').hide();
    var weight_error = ''
    if ($('#goal_weight [name="current_input"]').val() === '') {
      weight_error = window.keystrings['com.numi.your_bmi_unhealthy'] + " \n"
    }
    if ($('#goal_weight [name="current_input"]').val() === '0') {
      weight_error = window.keystrings['com.numi.your_bmi_unhealthy'] + "\n "
    }
    if (weight_error !== '') {
      $('.goal_error').text('');
      $('.goal_error').append(weight_error);
      $('.goal_error').show();
    }
    else {
      var weight_params = { "weight": { "goal": $('#goal_weight [name="current_input"]').val() } }
      // Goal Weight update API

      const options = {
        url: window.API_URL + "v1/weights",
        method: 'POST',
        credentials: 'same-origin',
        headers: Header.setAuthHeaders(),
        data: weight_params
      };

      axios(options)
        .then(response => {


          Header.stopLoader();
          this.props.callProgressAPI();
          this.props.submitGoalWeight(this.state.goal_weight);
          this.setState({ goal_weight: this.state.goal_weight });
          $('#goal_weight').modal('hide');
          this.props.callAlertAPI();
        })
        .catch(error => {
          Header.stopLoader();
          $('.goal_error').text('');
          $('.goal_error').show();
          if (error && error.response && error.response.data && error.response.data.error) {
            $('.goal_error').append(error.response.data.error);
          }
          else if (error && error.response && error.response.status > 400) {
            $('.goal_error').append(error.response.statusText);
          }
          else if (error && error.message) {
            alert(error.message);
          }
          Sentry.captureException(error);
        });
    }
  };

  componentDidMount() {
    /* Key String */
    $('.goal_weight_header').text(window.keystrings['com.numi.progress.goal_weight']);
    $('.goal_weight_save').text(window.keystrings['com.numi.keypad.save']);
    /* Key String */
    $(document).on("input", ".numeric", function (e) {
      this.value = this.value.replace(/[^0-9]/g, '');
    });

    $('[data-target="#goal_weight"]').click(function () {
      $('#goal_weight [name="current_input"]').val($('.goal_weight_final').text());
      $('.goal_error').hide();
    });
  }

  render() {
    return (
      <div className="modal fade" id="goal_weight">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button data-dismiss="modal" type="button" className="close"><img alt="" src="/assets/images/close_grey.png" /></button>
            </div>
            <div className="modal-body">
              <p className="goal_error error_txt"></p>
              <p className="font26 color3 mt5 font700 goal_weight_header"></p>
              <p className="mt5"><input type="text" className="numeric color6 font16 bold" name="current_input" maxLength="6" onChange={this.inputUpdate} onKeyUp={this.inputUpdate} />&nbsp;&nbsp;lbs.</p>
              <p><button type="submit" className="mt6 goal_weight_save" value="" onClick={this.submitGoal}></button></p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

class LogWeight extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      logWeight: this.props.logValue, weights_data: '', startDate: new Date()
    }
  }

  handleChange = (date) => {
    this.setState({
      startDate: date
    });

    var actual_date = moment().format('MMM DD');
    var pageload_selecetd_date = moment(date).format('MMM DD');
    var log_selected_date = this.props.calldatepickerDate(moment(date));
    if (pageload_selecetd_date === actual_date) {
      $('#log_weight .today_date').show();
      $('#log_weight .today_date').text('Today');
    }
    else {
      $('.today_date').show();
      $('#log_weight .today_date').text(log_selected_date);
    }
    Header.changeDate(date);
  }


  getWeight = () => {
    const options = {
      url: window.API_URL + "v1/weights",
      method: 'GET',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
    };

    axios(options)
      .then(response => {
        if (response && response.data) {
          this.setState({
            weights_data: response.data
          });

          if (this.state.weights_data) {
            this.setState({
              logWeight: this.state.weights_data.weights[0].current,
              log_weight_array: this.state.weights_data.weights
            });
          }
        }
      })
      .catch(error => {
        Header.stopLoader();
        Header.apiErrors(error);
        Sentry.captureException(error);
      });

  }

  submitlogWeight = (e) => {
    Header.startLoader();
    $('.log_weight_error').hide();
    var logdate = $('#log_weight').find('.react-datepicker__input-container input').val();
    if (this.state.logWeight === '' || this.state.logWeight === '0' || logdate === '') {
      var weight_error = ''
      if (this.state.logWeight === '') {
        weight_error = window.keystrings['com.numi.your_bmi_unhealthy'] + " \n"
      }
      if (this.state.logWeight === '0') {
        weight_error = weight_error + window.keystrings['com.numi.your_bmi_unhealthy'] + "\n "
      }
      if (logdate === '') {
        weight_error = weight_error + window.keystrings['com.numi.weights.assigned_date_cant_be_blank'] + "\n "
      }
      $('.log_weight_error').text('');
      $('.log_weight_error').append(weight_error);
      $('.log_weight_error').show();
    }
    else {
      logdate = new Date(logdate);
      logdate = logdate.getFullYear() + '-' + (logdate.getMonth() + 1) + '-' + logdate.getDate();
      var weight_params = { "weight": { "current": this.state.logWeight, "assigned_date": logdate } }
      /* Weight create Api */

      const options = {
        url: window.API_URL + "v1/weights",
        method: 'POST',
        credentials: 'same-origin',
        headers: Header.setAuthHeaders(),
        data: weight_params
      };
      axios(options)
        .then(response => {


          Header.stopLoader();
          this.props.callProgressAPI();
          $('#log_weight').modal('hide');
          this.props.loggingWeight(this.state.logWeight);
          this.setState({ logWeight: this.state.logWeight });
          this.props.callAlertAPI();
        })
        .catch(error => {
          Header.stopLoader();
          $('.log_weight_error').text('');
          $('.log_weight_error').show();
          if (error && error.response && error.response.data && error.response.data.error) {
            $('.log_weight_error').append(error.response.data.error);
          }
          else if (error && error.response && error.response.status > 400) {
            $('.log_weight_error').append(error.response.statusText);
          }
          else if (error && error.message) {
            alert(error.message);
          }
          Sentry.captureException(error);
        });
    }
  };

  checkForWeightOveride = () => {
    var final_array = [];
    var select_date = moment(parse($('#log_weight').find('.react-datepicker__input-container input').val())).format('MMMM DD, YYYY');

    $("ul#weight_ul").find("span.edit_selected_date").each(function (i) {
      final_array.push($(this).text());

      if ($(this).text() === select_date) {
        window.over_lbs = $(this).next().find('.changed_edit_value').text();
      }
    });
    if (final_array.indexOf(select_date) < 0) {
      this.submitlogWeight();
    } else {
      $(".log_weight_content").hide();
      $('.progress_edit_weight_alert_title_front').text(window.keystrings['com.numi.progress_edit_weight_alert_title_front']);
      $('.progress_edit_weight_alert_title_end').text(window.keystrings['com.numi.progress_edit_weight_alert_title_end']);
      $('.move_weight_yes').text(window.keystrings['com.numi.yes']);
      $('.move_weight_cancel').text(window.keystrings['com.numi.cancel']);
      $("#duplicate_date_text_logw").text(select_date);
      $(".override_weight_content").show();
      var lbs_on_from_date = $("#weight_log").val();
      if (lbs_on_from_date === window.over_lbs) {
        $("#to_lbs_logw").text(lbs_on_from_date + " lbs");
        $(".duplicate_left_logw").hide();
        $('.duplicate_right_logw').addClass('text-center').removeClass('pull-right');
      } else {
        $(".duplicate_left_logw").show();
        $("#from_lbs_logw").text(window.over_lbs + " lbs");
        $("#to_lbs_logw").text(lbs_on_from_date + " lbs");
        $('.duplicate_right_logw').removeClass('text-center').addClass('pull-right');
      }
    }

  }

  inputUpdate = (e) => {
    this.setState({ logWeight: e.target.value });
  };

  componentDidMount() {


    $(document).on('click', '.log_weight', function () {
      $('#log_weight td.today').click();
      $('#log_weight .today_date').text('Today');
      this.setState({
        logWeight: this.props.getCurrentVal, startDate: new Date()
      })
    }.bind(this))

    $(document).on("input", ".numeric", function (e) {
      this.value = this.value.replace(/[^0-9]/g, '');
    });
    $('.log_weight_error').hide();
    this.getWeight();
  }
  render() {
    return (
      <div className="modal fade" id="log_weight">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal"><img alt="" src="/assets/images/close_grey.png" /></button>
            </div>
            <div className="modal-body log_weight_content">
              <p className="log_weight_error error_txt"></p>
              <p className="font26 color3 mt2 font700 log_weight_header"></p>
              <div className="mt5">
                <div className="pull-left log_weight_input"><input type="text" id="weight_log" className="numeric color6 font16 bold" name="" maxLength="6" onChange={this.inputUpdate} onKeyUp={this.inputUpdate} value={this.state.logWeight} />&nbsp;&nbsp;lbs.</div>
                <div className="pull-left log_weight_date"><div className="relative"><DatePicker maxDate={new Date()} selected={parse(this.state.startDate)} onChange={this.handleChange} minDate={addDays(new Date(parse(this.props.get_profile_date)), 1)} /> </div></div></div>
              <p><button type="submit" className="mt6 weight_log_gtm log_weight_save" value="" onClick={this.checkForWeightOveride} ></button>
              </p>
            </div>
            <div className="modal-body override_weight_content" >
              <p className="move_weight_error error_txt"></p>
              <img alt="" src="/assets/images/warning_tri.png" />
              <p className="font26 color3 mt2 font700">
                <span className="progress_edit_weight_alert_title_front"></span>
                <span id="duplicate_date_text_logw"></span><span className="progress_edit_weight_alert_title_end"></span>
              </p>
              <div className="align_div font16 mt6">
                <div className="pull-left duplicate_left_logw color6"><div>From</div>
                  <div className="bold" id="from_lbs_logw"> lbs.</div>
                </div>
                <div className="pull-right duplicate_right_logw color6"><div>To</div>
                  <div id="to_lbs_logw" className="bold"> lbs.</div>
                </div>
              </div>
              <div className="clear"></div>
              <p className="mt6"><button className="buuton280 move_weight_yes" onClick={this.submitlogWeight} value="Yes">Yes</button>
              </p>
              <p><button className="buuton280 move_weight_cancel" data-dismiss="modal">Cancel</button>
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

class EditWeight extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      edit_Weight: "value= " + $('#edit_weight input[type="text"]').val()
    }
  }

  changeLogWeight = (e) => {

    Header.startLoader();
    $('.edit_weight_error').hide();
    var weight_error = ''
    if ($('#edit_weight input[type="text"]').val() === '') {
      weight_error = window.keystrings['com.numi.your_bmi_unhealthy'] + " \n"
    }
    if ($('#edit_weight input[type="text"]').val() === '0') {
      weight_error = weight_error + window.keystrings['com.numi.your_bmi_unhealthy'] + " \n "
    }
    if (weight_error !== '') {
      $('.edit_weight_error').text('');
      $('.edit_weight_error').append(weight_error);
      $('.edit_weight_error').show();
    }
    else {

      var edited_date = moment(this.props.logDate).format('YYYY-MM-DD');
      var weight_params = { "weight": { "current": $('#edit_weight input[type="text"]').val(), "assigned_date": edited_date } }
      // Weight update Api

      const options = {
        url: window.API_URL + "v1/weights",
        method: 'POST',
        credentials: 'same-origin',
        headers: Header.setAuthHeaders(),
        data: weight_params
      };
      axios(options)
        .then(response => {


          Header.stopLoader();
          $('.current_edit').text(this.state.edit_Weight);
          window.localStorage.setItem('plan_starting_weight', response.data.weight.plan_starting);
          this.props.callProgressAPI();
          $('#edit_weight').modal('hide');
          this.props.callAlertAPI();
        })
        .catch(error => {
          Header.stopLoader();
          $('.edit_weight_error').text('');
          $('.edit_weight_error').show();
          if (error && error.response && error.response.data && error.response.data.error) {
            $('.edit_weight_error').append(error.response.data.error);
          }
          else if (error && error.response && error.response.status > 400) {
            $('.edit_weight_error').append(error.response.statusText);
          }
          else if (error && error.message) {
            alert(error.message);
          }
          Sentry.captureException(error);
        });
    }
  };

  inputUpdate = (e) => {
    this.setState({ edit_Weight: e.target.value });
  };

  componentDidMount() {
    $(document).on("input", ".numeric", function (e) {
      this.value = this.value.replace(/[^0-9]/g, '');
    });
    $('.edit_weight_error').hide();
  }

  render() {

    return (
      <div className="modal fade" id="edit_weight">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header edit_weightheadertext">
              <button type="button" className="close" data-dismiss="modal"><img alt="" src="/assets/images/close_grey.png" /></button>
            </div>
            <div className="modal-body">
              <p className="edit_weight_error error_txt"></p>
              <p className="font26 color3 mt2 font700 edit_weight_header"></p>
              <p className="mt5"><input type="text" className="numeric color6 font16 bold" name="" maxLength="6" onChange={this.inputUpdate} onKeyUp={this.inputUpdate}
                innerHTML={this.state.edit_Weight}
              />&nbsp;&nbsp;lbs.
              </p>
              <p><button type="submit" className="mt6 edit_save_button" value="" onClick={this.changeLogWeight} ></button></p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

class DeleteWeight extends React.Component {

  deleteLogWeight = (e) => {
    this.deleteWeight();
  };

  deleteWeight = (e) => {
    var delete_assigned_date = moment(this.props.logDate).format('YYYY-MM-DD');
    const options = {
      url: window.API_URL + "v1/weights/assigned_date/" + delete_assigned_date,
      method: 'DELETE',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
      data: { "assigned_date": delete_assigned_date }
    };
    axios(options)
      .then(response => {
        if (response.data.error) {
          $('.delete_error').text('');
          $('.delete_error').append(response.data.error);
          $('.delete_error').show();
        }
        else {
          this.props.callProgressAPI();
          $('#delete_weight').modal('hide');
          this.props.callAlertAPI();
        }
      })
      .catch(error => {
        if (error.response.status !== 200 && error.response.statusText) {
          $('.delete_error').text('');
          if (error && error.response && error.response.data && error.response.data.error) {
            $('.delete_error').append(error.response.data.error);
          }
          else if (error && error.response && error.response.status > 400) {
            $('.delete_error').append(error.response.statusText);
          }
          else if (error && error.message) {
            alert(error.message);
          }
          $('.delete_error').show();
        }
        else {
          this.props.callProgressAPI();
          $('#delete_weight').modal('hide');
          this.props.callAlertAPI();
        }
        Sentry.captureException(error);
      });
  }

  componentDidMount() {

  }

  render() {
    return (
      <div className="modal fade" id="delete_weight">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal"><img alt="" src="/assets/images/close_grey.png" /></button>
            </div>
            <div className="modal-body width80">
              <p className="delete_error error_txt"></p>
              <img alt="" src="/assets/images/warning_tri.png" />
              <p className="font26 color3 mt2 font700 delete_weight_header"></p>
              <div className="font16 color6"><ul className="delete_record_li"></ul></div>
              <div className="clear"></div>
              <p><button className="buuton280 delete_weight_yes" onClick={this.deleteLogWeight}>Yes</button></p>

              <p><button className="buuton280 delete_weight_cancel" data-dismiss="modal">Cancel</button></p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


class MoveToNextDate extends React.Component {
  constructor(props) {
    super(props)
    this.state = { move_weight: true, duplicate_weight: false, startDate: new Date() };
  }
  handleChange = (date) => {
    this.setState({
      startDate: date
    });

    var complete_date = moment(date).format('MMMM DD, YYYY');
    var actual_date = moment().format('MMM DD');
    var pageload_selecetd_date = moment(date).format('MMM DD');
    if (pageload_selecetd_date === actual_date) {
      $('#move_weight .today_date').show();
      $('#move_weight .today_date').text('Today');
    }
    else {
      $('#move_weight .today_date').show();
      $('#move_weight .today_date').text(complete_date);
    }
    var move_to_date = moment(date).format('MMMM DD, YYYY');
    $('.move_date').text(move_to_date);

    Header.changeDate(date);
  }

  moveWeightApi = () => {
    Header.startLoader();
    var delete_assigned_date = moment(this.props.MovelogDate).format('YYYY-MM-DD');
    var logdate = moment(parse($("#move_weight").find('.react-datepicker__input-container input').val())).format('YYYY-MM-DD');
    var weight_params = { "weight": { "old_assigned_date": delete_assigned_date, "assigned_date": logdate } };

    const options = {
      url: window.API_URL + "v1/weights",
      method: 'POST',
      credentials: 'same-origin',
      headers: Header.setAuthHeaders(),
      data: weight_params
    };

    axios(options)
      .then(response => {

        Header.stopLoader();
        this.props.callProgressAPI();
        $('#move_weight').modal('hide');
        this.props.callAlertAPI();
      })
      .catch(error => {
        Header.stopLoader();
        $('.move_weight_error').text('');
        $('.move_weight_error').show();
        if (error && error.response && error.response.data && error.response.data.error) {
          $('.move_weight_error').append(error.response.data.error);
        }
        else if (error && error.response && error.response.status > 400) {
          $('.move_weight_error').append(error.response.statusText);
        }
        else if (error && error.message) {
          alert(error.message);
        }
        Sentry.captureException(error);
      });
  }

  toggleDates = (e) => {
    var move_to_date = this.props.calldisplayDate(new Date($("#move_weight").find('.react-datepicker__input-container input').val()));
    var lbs_on_from_date = $('#move_weight').find('#lbs_move_from_weight b').text();
    var select_date = this.props.calldisplayDate($('#move_weight').find('.react-datepicker__input-container input').val());
    var final_array = [];
    $("ul#weight_ul").find("span.edit_selected_date").each(function (i) {
      final_array.push($(this).text());

      if ($(this).text() === select_date) {
        window.lbs = $(this).next().find('.changed_edit_value').text();
      }
    });
    if ($.inArray(move_to_date, final_array) !== -1) {
      $(".move_weight_body").hide();
      $(".duplicate_weight").show();
      var complate_date = this.props.calldisplayDate(new Date(move_to_date));
      $("#duplicate_date_text").text(complate_date);
      if (lbs_on_from_date === window.lbs) {
        $("#from_lbs").text(window.lbs + " lbs");
        $("#to_lbs").text(lbs_on_from_date + " lbs");

        $('.duplicate_right').addClass('text-center').removeClass('pull-right');
      } else {

        $("#from_lbs").text(window.lbs + " lbs");
        $("#to_lbs").text(lbs_on_from_date + " lbs");
        $('.duplicate_right').removeClass('text-center').addClass('pull-right');
      }
    }
    else {
      this.moveWeightApi();
    }
  }

  toggleDatesUpdate = (e) => {
    this.moveWeightApi();
  }

  componentDidMount() {


    // $(document).on('click', '.move_logged_weight', function (e) {
    //   debugger

    //   e.stopImmediatePropagation();
    //   /*Key string move weight*/
    //   // console.log(window.keystrings['com.numi.progress.move_weight_from'])
    //   $('.move_logged_weight_header').text(window.keystrings['com.numi.move_to_another_date']);
    //   var move_weight_from_content = "" + window.keystrings['com.numi.progress.move_weight_from'] + "";
    //   var move_weight_to_content = "" + window.keystrings['com.numi.progress.copied_to'] + "";
    //   $('.move_weight_text').text(window.keystrings['com.numi.weight']);
    //   $('.move_weight_date').text(window.keystrings['com.numi.progress.date']);
    //   $('.move_weight_done').text(window.keystrings['com.numi.done']);
    //   $('.progress_edit_weight_alert_title_front').text(window.keystrings['com.numi.progress_edit_weight_alert_title_front']);
    //   $('.progress_edit_weight_alert_title_end').text(window.keystrings['com.numi.progress_edit_weight_alert_title_end']);
    //   $('.move_weight_yes').text(window.keystrings['com.numi.yes']);
    //   $('.move_weight_cancel').text(window.keystrings['com.numi.cancel']);
    //   /*Key string move weight  */
    //   $('li.weight_li').removeClass('move_from_date');
    //   e.currentTarget.parentElement.parentElement.parentElement.parentElement.classList.add('move_from_date');
    //   var move_from_date = moment(this.props.MovelogDate).format('MMMM DD, YYYY');
    //   var move_to_date = moment().format('MMMM DD, YYYY');
    //   $("div.move_text").text('');
    //   $("div.move_text").html("" + move_weight_from_content + " <b>" + move_from_date + "</b> " + move_weight_to_content + " <b class='move_date'> " + move_to_date + " </b>");
    //   getWeightOn(move_from_date);
    //   $("#move_from").val(move_from_date);
    //   $(".move_weight_body").show();
    //   $(".duplicate_weight").hide();
    //   this.setState({
    //     startDate: new Date()
    //   });
    //   Header.changeDate(new Date());
    // }.bind(this));

    // function 

    // }




  }

  render() {

    return (
      <div className="modal fade" id="move_weight">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header moveweight_headertext">
              <button type="button" className="close" data-dismiss="modal"><img alt="" src="/assets/images/close_grey.png" /></button>
            </div>
            <div className="modal-body width86 move_weight_body" style={{ display: this.state.move_weight ? 'block' : 'none' }}>
              <p className="move_weight_error error_txt"></p>
              <p className="font26 color3 mt2 font700 move_logged_weight_header"></p>
              <div className="font14 color7 move_text"><p className="move_weight_from_content"></p> <b></b> will be copied to <b>{this.state.move_to_date}</b> </div>
              <div className="width80">
                <div className="mt10p">
                  <div className="width50 pull-left font16 color6 bold move_weight_text"></div>
                  <div id="lbs_move_from_weight" className="width50 pull-right color5 font16 text-left"><b></b> lbs.</div>
                </div>
                <div className="clear"></div>
                <div className="relative date_pick mt5">
                  <div className="left_sec font15 width46 pull-left font16 color6 bold move_weight_date"></div>
                  <div className="width50 pull-right text-left"> <div className="relative"><DatePicker onChange={this.handleChange} selected={parse(this.state.startDate)} maxDate={new Date()} minDate={addDays(new Date(parse(this.props.get_profile_date)), 1)} /> </div></div>
                </div>
                <div className="clear"></div>
                <p><button type="submit" className="mt10p move_weight_done" onClick={this.toggleDates} value="" ></button></p>
              </div>
            </div>
            <div className="modal-body width93 duplicate_weight">
              <p className="move_weight_error error_txt"></p>
              <img alt="" src="/assets/images/warning_tri.png" />
              <p className="font26 color3 mt2 font700"><span className="progress_edit_weight_alert_title_front"></span><span id="duplicate_date_text"></span><span className="progress_edit_weight_alert_title_end"></span> </p>
              <div className="align_div font16 mt6">
                <div className="pull-left duplicate_left color6"><div>From</div><div className="bold" id="from_lbs"> lbs.</div></div> <div className="pull-right duplicate_right color6"><div>To</div><div id="to_lbs" className="bold"> lbs.</div></div>
              </div>
              <div className="clear"></div>
              <p className="mt6"><button className="buuton280 move_weight_yes" onClick={this.toggleDatesUpdate} value="Yes">Yes</button></p>
              <p><button className="buuton280 move_weight_cancel" data-dismiss="modal">Cancel</button></p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

class CongratsPopup extends React.Component {
  componentDidMount() { }
  render() {
    return (
      <div className="modal fade" id="congrats_popup">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal"><img alt="" src="/assets/images/close_grey.png" /></button>
            </div>
            <div className="modal-body width80">
              <img alt="" src="/assets/images/transition_fireworks.png" />
              <p className="font26 color3 mt2 font700">Congratulations! <br />You have less than 100 lbs to lose!</p>
              <p className="font16 color6">You have less than 100 lbs to lose. We have modified your meal plan to help achieve your goals.</p>
              <p className="mt10p"><button className="buuton280" data-dismiss="modal">Ok</button></p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

class WeightToLoss extends React.Component {
  componentDidMount() { }
  render() {
    return (
      <div className="modal fade" id="weight_loss">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal"><img alt="" src="/assets/images/close_grey.png" /></button>
            </div>
            <div className="modal-body width80">
              <img alt="" src="/assets/images/warning_tri.png" />
              <p className="font26 color3 mt2 font700">You have more than 100 lbs to lose.</p>
              <p className="font16 color6">You have more than 100 lbs to lose. We have modified your meal plan to help achieve your goals.</p>
              <p className="mt10p"><button className="buuton280" data-dismiss="modal">Ok</button></p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Progress;
