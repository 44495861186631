import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import "react-datepicker/dist/react-datepicker.css";
import 'baguettebox.js/dist/baguetteBox.min.css';
import './index.css';
import 'react-rangeslider/lib/index.css';
import './App.css';
//import './style.scss';
import App from './App';

import { BrowserRouter } from "react-router-dom";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";


if (process.env.REACT_APP_NODE_ENV !== 'development') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    ignoreErrors: ['window.AdSpeed_jQuery is not a function', 'e.update is not a function'],
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_NODE_ENV
  });
  Sentry.configureScope(scope => {
    scope.setUser({ id: localStorage.getItem('user_id') });
  });
  Sentry.setTag("server_name", "React");
}

ReactDOM.render(
  <BrowserRouter>
    <div id="container">
      <App />
    </div>
  </BrowserRouter>,
  document.getElementById('root')
);
